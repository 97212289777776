/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountSoldHistoryView_user$key } from "src/types/__generated__/SellerAccountSoldHistoryView_user.graphql";
import type { SellerAccountSoldHistoryViewPaginationQuery } from "src/types/__generated__/SellerAccountSoldHistoryViewPaginationQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRef } from "react";
import { usePaginationFragment } from "react-relay";

import AccountOrderCard from "src/app/components/account/AccountOrderCard";
import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator } from "src/sbxui";

type Props = Readonly<{
  queryKey: SellerAccountSoldHistoryView_user$key;
}>;

const SellerAccountSoldHistoryView = ({ queryKey }: Props): React.ReactNode => {
  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment<
    SellerAccountSoldHistoryViewPaginationQuery,
    SellerAccountSoldHistoryView_user$key
  >(
    graphql`
      fragment SellerAccountSoldHistoryView_user on User
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 20 }
        orderBy: {
          type: SellerOrdersOrderByInput
          defaultValue: { createdAt: DESC }
        }
      )
      @refetchable(queryName: "SellerAccountSoldHistoryViewPaginationQuery") {
        sellerOrders(after: $cursor, first: $count, orderBy: $orderBy)
          @connection(key: "SellerAccountSoldHistoryView_sellerOrders") {
          __id
          edges {
            node {
              ...AccountOrderCard_order
              id
            }
          }
        }
      }
    `,
    queryKey,
  );

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  return (
    <>
      {data?.sellerOrders?.edges
        ?.filter(({ node }) => node != null)
        .map(({ node }) => (
          <AccountOrderCard key={node.id} queryKey={node} source="SELLER" />
        ))}
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
});

export default SellerAccountSoldHistoryView;
