/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  Currency,
  GradingAuthority,
  SalesChannel,
} from "src/types/__generated__/SearchResultCard_typesenseSearch.graphql";

import * as stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { UserContext } from "src/app/context/user";
import {
  AUCTION_LOT_PATH,
  PRODUCT_DETAIL_PATH,
  SHOP_PATH,
} from "src/app/router/Router";
import {
  HeadingLevel,
  SBFavoriteButton,
  SBFmvBadge,
  SBHeading,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney, formatTitle } from "src/utils";

type Props = Readonly<{
  auctionLotId?: string | null | undefined;
  currency: Currency | null | undefined;
  currentBidAmount?: number | null | undefined;
  favorite?: boolean;
  favoriteLoading?: boolean;
  fmvMaxValue?: number | null | undefined;
  fmvMinValue?: number | null | undefined;
  fmvRank?: number | null | undefined;
  grade: number;
  gradingAuthority: GradingAuthority;
  imageUrl: string;
  number: string;
  onClickFavorite?: (event: React.SyntheticEvent) => void;
  price: number | null | undefined;
  productId: string;
  salesChannel: SalesChannel | null | undefined;
  title: string;
}>;

export const SBProductTile = React.memo(
  ({
    auctionLotId,
    currency,
    currentBidAmount,
    favorite,
    favoriteLoading,
    fmvMaxValue,
    fmvMinValue,
    fmvRank,
    grade,
    gradingAuthority,
    imageUrl,
    number,
    onClickFavorite,
    price,
    productId,
    salesChannel,
    title,
  }: Props) => {
    const { i18n, t } = useTranslation();

    const [opacity, setOpacity] = useState(0);

    const userContext = useContext(UserContext);

    let destination = SHOP_PATH;
    if (salesChannel === "SHOP") {
      destination = PRODUCT_DETAIL_PATH.replace(":id", productId);
    }
    if (salesChannel === "AUCTION" && auctionLotId != null) {
      destination = AUCTION_LOT_PATH.replace(":id", auctionLotId);
    }

    useEffect(() => {
      const img = new Image();
      img.onload = () => {
        setOpacity(1);
      };
      img.src = imageUrl;
    }, [imageUrl]);

    const productTitle = formatTitle(t, title, number);

    const gradeValue = grade === 0 ? t("rawGrades.0-0") : grade.toFixed(1);

    const showFavoriteButton = userContext.user != null;

    let priceLine;
    if (salesChannel === "SHOP" && price != null && currency != null) {
      priceLine = (
        <>
          <span
            {...stylex.props(styles.price)}
            aria-label={t("product.priceAlt", {
              price: formatMoney(price, currency, i18n.language),
            })}
          >
            {formatMoney(price, currency, i18n.language)}
          </span>
          {fmvRank ? (
            <SBFmvBadge fmvRank={fmvRank} style={styles.fmvBadge} />
          ) : null}
        </>
      );
    }
    if (
      salesChannel === "AUCTION" &&
      currentBidAmount != null &&
      currency != null
    ) {
      priceLine = (
        <span {...stylex.props(styles.price)}>
          {currentBidAmount === 0
            ? t("auction.lot.no-bids")
            : t("auction.lot.current-bid", {
                currentBid: formatMoney(
                  currentBidAmount,
                  currency,
                  i18n.language,
                ),
              })}
        </span>
      );
    }

    return (
      <li {...stylex.props(auto, styles.tile)}>
        <Link
          {...stylex.props(auto, styles.link)}
          rel="noopener noreferrer"
          target="_blank"
          to={destination}
        >
          <div
            {...stylex.props(auto, styles.image(opacity))}
            aria-hidden="true"
          >
            <img
              {...stylex.props(styles.imageFile)}
              height="100%"
              src={imageUrl}
              width="auto"
            />
            <div {...stylex.props(auto, styles.imageSpacer)}></div>
          </div>
          <div {...stylex.props(styles.meta)}>
            <SBHeading
              aria-label={t("product.titleAlt", {
                authority: t(`gradingAuthority.${gradingAuthority}`),
                number,
                title,
                value:
                  gradingAuthority === "RAW"
                    ? t(`rawGrades.${kebabCase(gradeValue)}`)
                    : gradeValue,
              })}
              level={HeadingLevel.H3}
            >
              {productTitle}
            </SBHeading>
            <p {...stylex.props(styles.grade)} aria-hidden="true">
              {t("product.gradeNumber", {
                gradingAuthority: t(`gradingAuthority.${gradingAuthority}`),
                value:
                  gradingAuthority === "RAW"
                    ? t(`rawGrades.${kebabCase(gradeValue)}`)
                    : gradeValue,
              })}
            </p>
            {fmvMaxValue != null &&
              fmvMaxValue > 0 &&
              fmvMinValue != null &&
              fmvMinValue > 0 &&
              currency != null && (
                <p
                  {...stylex.props(styles.fmv)}
                  aria-label={t("product.fmvRangeAlt", {
                    max: formatMoney(fmvMaxValue, currency, i18n.language),
                    min: formatMoney(fmvMinValue, currency, i18n.language),
                  })}
                >
                  {t("product.fmvRange", {
                    max: formatMoney(fmvMaxValue, currency, i18n.language),
                    min: formatMoney(fmvMinValue, currency, i18n.language),
                  })}
                </p>
              )}
            <p {...stylex.props(styles.cost)}>{priceLine}</p>
          </div>
        </Link>
        {showFavoriteButton &&
        onClickFavorite != null &&
        favorite != null &&
        favoriteLoading != null ? (
          <SBFavoriteButton
            favorite={favorite}
            loading={favoriteLoading}
            number={number}
            style={styles.favorite}
            title={title}
            onClick={onClickFavorite}
          />
        ) : null}
      </li>
    );
  },
);

const styles = stylex.create({
  cost: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    margin: 0,
    marginTop: 10,
  },
  favorite: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  fmv: {
    color: colors.colorMuted,
    fontSize: 14,
    margin: 0,
    marginTop: 2,
  },
  fmvBadge: {
    marginLeft: 12,
  },
  fmvRank: {
    backgroundColor: colors.fmvRankDefaultBackgroundColor,
    borderRadius: 12,
    color: colors.fmvRankDefaultColor,
    fontSize: 12,
    height: 24,
    lineHeight: "24px",
    marginLeft: 12,
    paddingInline: 12,
    textAlign: "center",
  },
  fmvRankGood: {
    backgroundColor: colors.fmvRankGoodBackgroundColor,
    color: colors.fmvRankGoodColor,
  },
  fmvRankGreat: {
    backgroundColor: colors.fmvRankGreatBackgroundColor,
    color: colors.fmvRankGreatColor,
  },
  fmvRankPoor: {
    backgroundColor: colors.fmvRankPoorBackgroundColor,
    color: colors.fmvRankPoorColor,
  },
  grade: {
    color: colors.colorMuted,
    fontSize: 14,
    margin: 0,
    marginTop: 5,
  },
  image: (opacity: number) => ({
    alignItems: "center",
    backgroundColor: colors.backgroundPlaceholderColor,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    display: "flex",
    justifyContent: "center",
    opacity,
    overflow: "hidden",
    position: "relative",
    transition: "opacity 250ms",
  }),
  imageFile: {
    display: "block",
    height: "100%",
    position: "absolute",
  },
  imageSpacer: {
    paddingBottom: "100%",
  },
  link: {
    borderRadius: 8,
    display: "block",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  meta: {
    color: colors.color,
    padding: 16,
  },
  price: {
    fontSize: 20,
  },
  tile: {
    backgroundColor: colors.backgroundEmphasisColor,
    backgroundSize: "contain",
    borderRadius: 8,
    listStyleType: "none",
    margin: 0,
    padding: 0,
    position: "relative",
  },
});
