/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesArticleDetailQuery } from "src/types/__generated__/QueriesArticleDetailQuery.graphql";

import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePreloadedQuery } from "react-relay";

import { ArticleDetailQuery } from "src/app/router/Queries";
import { SBErrorPage } from "src/sbxui";

import ArticleDetailView from "./ArticleDetailView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesArticleDetailQuery>;
}>;

const ArticleDetailContent = ({ queryRef }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const queryKey = usePreloadedQuery(ArticleDetailQuery, queryRef);

  if (queryKey.articleBySlug == null) {
    return (
      <SBErrorPage
        description={t("not-found.description")}
        errorCode={404}
        title={t("not-found.page-title")}
      />
    );
  }

  return (
    <ArticleDetailView article={queryKey.articleBySlug} queryKey={queryKey} />
  );
};

export default ArticleDetailContent;
