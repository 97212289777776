/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import { useMemo } from "react";
import * as React from "react";

import {
  HeadingLevel,
  SBHeading,
  SBImage,
  SBLink,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  children?: React.ReactNode;
}>;

export const useMarkdownComponents = () => {
  const markdownComponents = useMemo(
    () => ({
      // eslint-disable-next-line id-length
      a({
        children,
        href,
      }: Readonly<{ children?: React.ReactNode; href?: string }>) {
        return (
          <SBLink
            external={!isShortboxedLink("https://shortboxed.com")}
            href={href}
          >
            {children}
          </SBLink>
        );
      },
      blockquote({ children }: Props) {
        return (
          <blockquote {...stylex.props(styles.blockquote)}>
            {children}
          </blockquote>
        );
      },
      h1({ children }: Props) {
        return (
          <SBHeading level={HeadingLevel.H1} style={styles.heading}>
            {children}
          </SBHeading>
        );
      },
      h2({ children }: Props) {
        return (
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {children}
          </SBHeading>
        );
      },
      h3({ children }: Props) {
        return (
          <SBHeading level={HeadingLevel.H3} style={styles.heading}>
            {children}
          </SBHeading>
        );
      },
      h4({ children }: Props) {
        return (
          <SBHeading level={HeadingLevel.H4} style={styles.heading}>
            {children}
          </SBHeading>
        );
      },
      h5({ children }: Props) {
        return (
          <SBHeading level={HeadingLevel.H5} style={styles.heading}>
            {children}
          </SBHeading>
        );
      },
      h6({ children }: Props) {
        return (
          <SBHeading level={HeadingLevel.H6} style={styles.heading}>
            {children}
          </SBHeading>
        );
      },
      hr() {
        return <hr {...stylex.props(auto, styles.hr)} />;
      },
      img({
        alt,
        src,
      }: Readonly<{ alt?: string; children?: React.ReactNode; src?: string }>) {
        return <SBImage alt={alt ?? ""} src={src ?? ""} width="100%" />;
      },
      li({ children }: Props) {
        return <li {...stylex.props(auto, styles.li)}>{children}</li>;
      },
      // eslint-disable-next-line id-length
      p({ children }: Props) {
        return <SBParagraph style={styles.p}>{children}</SBParagraph>;
      },
      table({ children }: Props) {
        return <table {...stylex.props(styles.table)}>{children}</table>;
      },
      td({ children }: Props) {
        return <th {...stylex.props(auto, styles.td)}>{children}</th>;
      },
      th({ children }: Props) {
        return <th {...stylex.props(auto, styles.th)}>{children}</th>;
      },
    }),
    [],
  );

  return markdownComponents;
};

const styles = stylex.create({
  blockquote: {
    borderBottomWidth: 0,
    borderColor: colors.horizontalRuleColor,
    borderLeftWidth: 4,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 0,
    marginInline: 24,
    paddingLeft: 16,
  },
  heading: {
    marginBottom: 16,
  },
  hr: {
    borderBottomWidth: 1,
    borderColor: colors.horizontalRuleColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 0,
    marginBlock: 16,
  },
  li: {
    color: colors.color,
  },
  // eslint-disable-next-line id-length
  p: {
    color: colors.color,
    margin: 0,
    marginBottom: 16,
  },
  table: {
    width: "100%",
  },
  td: {
    color: colors.color,
    fontWeight: 400,
    textAlign: "left",
  },
  th: {
    color: colors.color,
    textAlign: "left",
  },
});

const isShortboxedLink = (href: string | null) =>
  href?.startsWith("https://shortboxed.com") ||
  href?.startsWith("https://shortboxed.page.link");
