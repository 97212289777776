/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesContactUsQuery } from "src/types/__generated__/QueriesContactUsQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import DocumentView from "src/app/components/document/DocumentView";
import { ContactUsQuery } from "src/app/router/Queries";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesContactUsQuery>;
}>;

const ContactUsContent = ({
  queryRef,
}: Props): React.ReactNode | null | undefined => {
  const queryKey = usePreloadedQuery(ContactUsQuery, queryRef);

  if (queryKey.node == null) {
    return null;
  }

  return <DocumentView queryKey={queryKey.node} />;
};

export default ContactUsContent;
