/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountProductOfferCard_product$key } from "src/types/__generated__/SellerAccountProductOfferCard_product.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";

import AccountInfoCard from "src/app/components/account/AccountInfoCard";
import { PRODUCT_DETAIL_PATH } from "src/app/router/Router";
import { SBImage } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatGrade, formatMoney } from "src/utils";

type Props = Readonly<{
  queryKey: SellerAccountProductOfferCard_product$key;
}>;

const SellerAccountProductOfferCard = ({
  queryKey,
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const product = useFragment(
    graphql`
      fragment SellerAccountProductOfferCard_product on Product {
        id
        price
        currency
        offerCounts {
          received
        }
        images(first: 1) {
          edges {
            node {
              url(quality: 100, width: 300, webp: true)
            }
          }
        }
        comicDetails {
          title
          number
          gradingAuthority
          grade
        }
      }
    `,
    queryKey,
  );

  const { images, comicDetails, price, currency, offerCounts } = product ?? {};
  const { title, number, gradingAuthority, grade } = comicDetails ?? {};

  const receivedOfferCount = offerCounts?.received ?? 0;

  return (
    <Link
      {...stylex.props(auto, styles.link)}
      to={PRODUCT_DETAIL_PATH.replace(":id", product.id)}
    >
      <AccountInfoCard
        content={
          <>
            <div>{formatMoney(price, currency, i18n.language)}</div>
            <div {...stylex.props(styles.offerCount)}>
              {t("seller-account.offers.offer-count", {
                count: receivedOfferCount,
              })}
            </div>
          </>
        }
        heading={t("account.offers.comic-details1", { number, title })}
        image={
          <SBImage
            alt=""
            src={images?.edges[0]?.node?.url ?? ""}
            width="100%"
          />
        }
        subHeading={formatGrade({ grade, gradingAuthority, t })}
      />
    </Link>
  );
};

const styles = stylex.create({
  link: {
    borderRadius: 8,
    display: "block",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  offerCount: {
    marginTop: 8,
  },
});

export default SellerAccountProductOfferCard;
