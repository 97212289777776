/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import { snakeCase } from "lodash";
import * as React from "react";
import { useState } from "react";

import { SBIcon, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

interface Option {
  caption?: string | null | undefined;
  disabled?: boolean;
  icon?: number | null | undefined;
  label: React.ReactNode;
  tintIcon?: boolean;
  value: string;
}

type Props = Readonly<{
  id: string;
  indeterminate?: boolean;
  onChange: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  options: Option[];
  style?: StyleXStyles;
  value: string;
}>;

export const SBRadioButtons = ({
  id,
  onChange,
  options,
  style,
  value,
}: Props): React.ReactNode => {
  const [focused, setFocused] = useState("");

  const handleChange =
    (val: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(val, event);
    };

  const handleFocus =
    (val: string) => (_event: React.FocusEvent<HTMLInputElement>) => {
      setFocused(val);
    };

  const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
    setFocused("");
  };

  return (
    <div {...stylex.props(auto, styles.options, style)}>
      {options.map((option, index) => (
        <div key={option.value}>
          <label
            {...stylex.props(
              auto,
              styles.label,
              index === 0 && styles.labelFirst,
              option.disabled && styles.labelDisabled,
              option.value === focused && styles.labelFocused,
              style,
            )}
            htmlFor={`${id.toUpperCase()}_${snakeCase(option.value?.toUpperCase())}`}
          >
            <input
              {...stylex.props(styles.radioButton)}
              checked={option.value === value}
              disabled={option.disabled}
              id={`${id.toUpperCase()}_${snakeCase(option.value?.toUpperCase())}`}
              type="radio"
              onBlur={handleBlur}
              onChange={handleChange(option.value)}
              onFocus={handleFocus(option.value)}
            />
            <SBIcon
              fill={false}
              icon={
                option.value === value
                  ? "radio_button_checked"
                  : "radio_button_unchecked"
              }
              style={[styles.radioButtonIcon]}
            />
            <div {...stylex.props(auto, styles.radioButtonLabel)}>
              {typeof option.label === "string" ? (
                <SBParagraph>{option.label}</SBParagraph>
              ) : (
                option.label
              )}
            </div>
          </label>
          {option.caption ? (
            <span {...stylex.props(auto, styles.caption)}>
              {option.caption}
            </span>
          ) : null}
        </div>
      ))}
    </div>
  );
};

const styles = stylex.create({
  caption: {
    fontSize: 12,
  },
  label: {
    alignItems: "flex-start",
    borderRadius: 2,
    display: "flex",
    justifyContent: "flex-start",
    marginTop: 16,
  },
  labelDisabled: {
    opacity: 0.4,
  },
  labelFirst: {
    marginTop: 0,
  },
  labelFocused: {
    outline: colors.outline,
  },
  options: {
    display: "block",
    width: "100%",
  },
  radioButton: {
    display: "block",
    height: 0,
    overflow: "hidden",
    width: 0,
  },
  radioButtonIcon: {
    color: colors.color,
    marginRight: 8,
  },
  radioButtonLabel: {
    marginLeft: 8,
    marginTop: 2,
  },
});
