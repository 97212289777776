/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesTermsQuery } from "src/types/__generated__/QueriesTermsQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import TermsContent from "./TermsContent";

const Terms = (): React.ReactNode => {
  const queryRef = useLoaderData() as PreloadedQuery<QueriesTermsQuery>;

  if (queryRef == null) {
    return null;
  }

  return (
    <div aria-live="polite">
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <TermsContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

export default Terms;
