/**
 * @generated SignedSource<<84aad8a336e6c0745b159e0f44795a2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BidNowView_auctionLot$data = {
  readonly auction: {
    readonly isTest: boolean;
  };
  readonly bidCount: number;
  readonly currentBidAmount: number;
  readonly currentBidCurrency: Currency;
  readonly id: string;
  readonly isClosed: boolean;
  readonly isViewerBidding: boolean;
  readonly isViewerHighBidder: boolean;
  readonly maximumBidAmount: number | null | undefined;
  readonly minimumBidAmountRequired: number | null | undefined;
  readonly product: {
    readonly comicDetails: {
      readonly barcode: string | null | undefined;
      readonly grade: number | null | undefined;
      readonly gradingAuthority: GradingAuthority;
      readonly label: {
        readonly id: string;
      };
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"BidNowTimer_auctionLot">;
  readonly " $fragmentType": "BidNowView_auctionLot";
};
export type BidNowView_auctionLot$key = {
  readonly " $data"?: BidNowView_auctionLot$data;
  readonly " $fragmentSpreads": FragmentRefs<"BidNowView_auctionLot">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BidNowView_auctionLot",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "BidNowTimer_auctionLot"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentBidAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentBidCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bidCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isClosed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minimumBidAmountRequired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maximumBidAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerBidding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerHighBidder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Auction",
      "kind": "LinkedField",
      "name": "auction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTest",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ComicDetail",
          "kind": "LinkedField",
          "name": "comicDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "barcode",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Label",
              "kind": "LinkedField",
              "name": "label",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grade",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gradingAuthority",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuctionLot",
  "abstractKey": null
};
})();

(node as any).hash = "7b8a597be0a822e8925c36e3a682d12f";

export default node;
