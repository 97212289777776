/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

const ProductDetailActionsBuyerProcessingPurchase = (): React.ReactNode => {
  const { t } = useTranslation();
  return (
    <>
      <SBHeading level={HeadingLevel.H3} style={styles.processingPurchase}>
        {t("product.status.processing-purchase")}
      </SBHeading>
      <SBParagraph style={styles.processingPurchaseSubtext}>
        {t("product.status.processing-purchase.subtext")}
      </SBParagraph>
    </>
  );
};

const styles = stylex.create({
  processingPurchase: {
    color: colors.colorMuted,
    marginTop: 16,
  },
  processingPurchaseSubtext: {
    color: colors.colorMuted,
    fontSize: 14,
    marginTop: 16,
  },
});

export default ProductDetailActionsBuyerProcessingPurchase;
