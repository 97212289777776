/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SelectInputOption } from "src/sbxui";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  ACCOUNT_ADDRESSES_PATH,
  ACCOUNT_AUCTION_BIDS_PATH,
  ACCOUNT_FAVORITES_PATH,
  ACCOUNT_INVOICES_PATH,
  ACCOUNT_OFFERS_PATH,
  ACCOUNT_ORDER_DETAILS_PATH,
  ACCOUNT_ORDERS_PATH,
  ACCOUNT_PASSWORD_PATH,
  ACCOUNT_PATH,
  ACCOUNT_WATCHED_LOTS_PATH,
} from "src/app/router/Router";
import { SBSelectInput } from "src/sbxui";
import { auto } from "src/themes";

import AccountSideNavSection from "./AccountSideNavSection";

const MOBILE = "@media (max-width: 767px)";

export interface INavItem {
  id: string;
  label: string;
  path: string;
  selected: boolean;
}

const AccountSideNav = (): React.ReactNode => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [_isPending, startTransition] = useTransition();

  const navItemsGeneral: INavItem[] = [
    {
      id: "PERSONAL_INFORMATION",
      label: t(`account.nav.personal-information`),
      path: ACCOUNT_PATH,
      selected: pathname === ACCOUNT_PATH,
    },
    {
      id: "PASSWORD",
      label: t(`account.nav.password`),
      path: ACCOUNT_PASSWORD_PATH,
      selected: pathname === ACCOUNT_PASSWORD_PATH,
    },
    {
      id: "ADDRESSES",
      label: t(`account.nav.addresses`),
      path: ACCOUNT_ADDRESSES_PATH,
      selected: pathname === ACCOUNT_ADDRESSES_PATH,
    },
    {
      id: "ORDERS",
      label: t(`account.nav.orders`),
      path: ACCOUNT_ORDERS_PATH,
      selected:
        pathname === ACCOUNT_ORDERS_PATH ||
        pathname.startsWith(ACCOUNT_ORDER_DETAILS_PATH.replace(":id", "")),
    },
    {
      id: "INVOICES",
      label: t(`account.nav.invoices`),
      path: ACCOUNT_INVOICES_PATH,
      selected:
        pathname === ACCOUNT_INVOICES_PATH ||
        pathname.startsWith(ACCOUNT_INVOICES_PATH.replace(":id", "")),
    },
  ];

  const navItemsMarketplace: INavItem[] = [
    {
      id: "FAVORITES",
      label: t(`account.nav.favorites`),
      path: ACCOUNT_FAVORITES_PATH,
      selected: pathname === ACCOUNT_FAVORITES_PATH,
    },
    {
      id: "OFFERS",
      label: t(`account.nav.offers`),
      path: ACCOUNT_OFFERS_PATH,
      selected: pathname === ACCOUNT_OFFERS_PATH,
    },
  ];

  const navItemsAuctions: INavItem[] = [
    {
      id: "WATCHED_LOTS",
      label: t(`account.nav.watched-lots`),
      path: ACCOUNT_WATCHED_LOTS_PATH,
      selected: pathname === ACCOUNT_WATCHED_LOTS_PATH,
    },
    {
      id: "AUCTION_BIDS",
      label: t(`account.nav.auction-bids`),
      path: ACCOUNT_AUCTION_BIDS_PATH,
      selected: pathname === ACCOUNT_AUCTION_BIDS_PATH,
    },
  ];

  const allItems = [
    ...navItemsGeneral,
    ...navItemsMarketplace,
    ...navItemsAuctions,
  ];

  const handleOptionChange = (selected: string) => {
    const selectedOption =
      allItems.find(({ label }) => label === selected) ?? null;

    if (selectedOption) {
      startTransition(() => {
        navigate(selectedOption.path);
      });
    }
  };

  return (
    <div>
      <div {...stylex.props(auto, styles.select)}>
        <SBSelectInput
          options={allItems.map(
            ({ id }) =>
              ({
                id: `nav-${id}`,
                label: t(`account.nav.${id.toLowerCase().replace("_", "-")}`),
              }) as SelectInputOption,
          )}
          value={
            allItems.find(({ path }) => path === pathname) ?? {
              id: "",
              label: "",
            }
          }
          onChange={handleOptionChange}
        />
      </div>
      <div {...stylex.props(styles.nav)}>
        <AccountSideNavSection
          heading={t("account.nav-section.buyer-general")}
          items={navItemsGeneral}
        />
        <AccountSideNavSection
          heading={t("account.nav-section.buyer-marketplace")}
          items={navItemsMarketplace}
        />
        <AccountSideNavSection
          heading={t("account.nav-section.buyer-auctions")}
          items={navItemsAuctions}
        />
      </div>
    </div>
  );
};

const styles = stylex.create({
  nav: {
    display: {
      [MOBILE]: "none",
      default: "block",
    },
    position: "absolute",
  },
  select: {
    display: {
      [MOBILE]: "block",
      default: "none",
    },
    marginBottom: 20,
  },
});

export default AccountSideNav;
