/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { InvoiceItems_invoice$key } from "src/types/__generated__/InvoiceItems_invoice.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import InvoiceItem from "./InvoiceItem";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: InvoiceItems_invoice$key;
}>;

const InvoiceItems = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment InvoiceItems_invoice on Invoice {
        type
        items {
          count
          edges {
            node {
              id
              ...InvoiceItem_product
            }
          }
        }
      }
    `,
    queryKey,
  );

  const invoiceType = data.type;

  const invoiceProductsCount = data.items?.count ?? 0;
  const invoiceProducts = useMemo(() => data.items.edges, [data.items.edges]);

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("invoice.items", {
          count: invoiceProductsCount,
        })}
      </SBHeading>
      <ul {...stylex.props(auto, styles.itemList)}>
        {invoiceProducts.map(({ node }) => (
          <InvoiceItem
            key={node.id}
            invoiceType={invoiceType}
            queryKey={node}
          />
        ))}
      </ul>
    </>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 28,
    marginBottom: 8,
  },
  item: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 16,
  },
  itemBid: {
    fontSize: 12,
    marginBottom: 0,
  },
  itemGrade: {
    color: colors.colorMuted,
    fontSize: 12,
    marginBottom: 4,
  },
  itemImage: {
    backgroundColor: colors.backgroundPlaceholderColor,
    borderRadius: 4,
    display: "flex",
    height: 56,
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    width: 56,
  },
  itemImageFile: {
    alignItems: "center",
    display: "block",
    height: "100%",
    position: "absolute",
  },
  itemList: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderColor: colors.borderColor,
    listStyle: "none",
    margin: 0,
    marginBottom: 8,
    padding: 0,
    paddingBottom: 16,
  },
  itemMeta: {
    marginLeft: 16,
  },
  title: {
    fontSize: {
      [MOBILE]: 32,
      [TABLET]: 48,
      default: 48,
    },
    marginBottom: 8,
  },
  value: {
    fontVariant: "tabular-nums",
    marginBottom: 8,
  },
});

export default InvoiceItems;
