/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TermsView_document$key } from "src/types/__generated__/TermsView_document.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { PageContext } from "src/app/context/page";
import { HeadingLevel, SBHeading, SBMarkdownRenderer } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: TermsView_document$key;
}>;

dayjs.extend(localizedFormat);

const TermsView = ({ queryKey }: Props): React.ReactNode => {
  const pageContext = useContext(PageContext);
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment TermsView_document on Document {
        id
        publishedAt
        title
        body
      }
    `,
    queryKey,
  );

  const { title, body, publishedAt } = data;

  useEffect(() => {
    pageContext?.setTitle(title);
  }, [title, pageContext]);

  const publishedAtDate =
    publishedAt == null
      ? publishedAt
      : new Date(
          Number.isNaN(Number(publishedAt)) ? publishedAt : Number(publishedAt),
        );

  const publishedOn =
    publishedAtDate == null
      ? null
      : publishedAtDate.toLocaleDateString(i18n.language, {
          day: "numeric",
          month: "short",
          year: "numeric",
        });

  return (
    <div {...stylex.props(styles.terms)}>
      <SBHeading level={HeadingLevel.H1} style={styles.heading}>
        {title}
      </SBHeading>
      {publishedOn != null && (
        <SBHeading level={HeadingLevel.H2} style={styles.date}>
          {t("terms.updatedAt", {
            date: publishedOn,
          })}
        </SBHeading>
      )}
      <div {...stylex.props(styles.outer)}>
        <div {...stylex.props(styles.inner)}>
          <SBMarkdownRenderer markdown={body} />
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  date: {
    fontSize: 28,
    marginBottom: 96,
    marginTop: 32,
    textAlign: "center",
  },
  heading: {
    marginTop: 96,
    textAlign: "center",
  },
  inner: {
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  outer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  terms: {
    marginBottom: 96,
  },
});

export default TermsView;
