/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

export enum MenuPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export interface MenuItemConfig {
  disabled?: boolean;
  id: string;
  label: string;
  onClick?: (event: React.SyntheticEvent) => void;
  to?: string;
}

type Props = Readonly<{
  button: React.ReactNode;
  buttonLabel?: string;
  buttonStyle?: StyleXStyles;
  items: MenuItemConfig[];
  position?: MenuPosition;
}>;

export const SBMenu = ({
  button,
  buttonLabel,
  buttonStyle,
  items,
  position = MenuPosition.LEFT,
}: Props): React.ReactNode => {
  return (
    <Menu {...stylex.props(styles.menu)} as="div">
      <MenuButton
        {...stylex.props(auto, styles.button, buttonStyle)}
        aria-label={buttonLabel}
      >
        {button}
      </MenuButton>
      <Transition
        as={Fragment}
        enter={stylex.props(styles.itemsEnter).className}
        enterFrom={stylex.props(styles.itemsEnterFrom).className}
        enterTo={stylex.props(styles.itemsEnterTo).className}
        leave={stylex.props(styles.itemsLeave).className}
        leaveFrom={stylex.props(styles.itemsLeaveFrom).className}
        leaveTo={stylex.props(styles.itemsLeaveTo).className}
      >
        <MenuItems
          {...stylex.props(
            auto,
            styles.items,
            position === MenuPosition.LEFT
              ? styles.positionRight
              : styles.positionLeft,
          )}
        >
          {items.map(({ disabled = false, id, label, onClick, to }, ii) => (
            <MenuItem {...stylex.props(auto, styles.item)} key={id}>
              {to == null ? (
                <button
                  {...stylex.props(
                    auto,
                    styles.itemLink,
                    ii === 0 && styles.itemLinkFirst,
                  )}
                  disabled={disabled}
                  type="button"
                  onClick={onClick}
                >
                  {label}
                </button>
              ) : (
                <Link
                  {...stylex.props(
                    auto,
                    styles.itemLink,
                    ii === 0 && styles.itemLinkFirst,
                  )}
                  to={to}
                  onClick={onClick}
                >
                  {label}
                </Link>
              )}
            </MenuItem>
          ))}
        </MenuItems>
      </Transition>
    </Menu>
  );
};

const styles = stylex.create({
  button: {
    backgroundColor: "transparent",
    borderWidth: 0,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  item: {
    borderBottomWidth: 0,
    borderColor: colors.topNavigationMenuBorderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 1,
    margin: 0,
    padding: 0,
  },
  itemLink: {
    backgroundColor: "transparent",
    borderWidth: 0,
    color: {
      ":hover": colors.topNavigationTabHoverColor,
      default: colors.topNavigationTabColor,
    },
    cursor: "pointer",
    display: "block",
    margin: 0,
    marginTop: 8,
    padding: 0,
    paddingTop: 8,
    textAlign: "left",
    textDecorationLine: "none",
    width: "100%",
  },
  itemLinkFirst: {
    borderTopWidth: 0,
    marginTop: 0,
    paddingTop: 0,
  },
  items: {
    backgroundColor: colors.topNavigationBackgroundColor,
    borderColor: colors.topNavigationMenuBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: colors.topNavigationMenuShadow,
    boxSizing: "border-box",
    marginTop: 8,
    minWidth: 200,
    paddingBottom: 12,
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 12,
    position: "absolute",
    transformOrigin: "top right",
  },
  itemsEnter: {
    transition: "transform 300ms, opacity 300ms",
    transitionTimingFunction: "ease-out",
  },
  itemsEnterFrom: {
    opacity: 0,
    transform: "scale(0.95)",
  },
  itemsEnterTo: {
    opacity: 1,
    transform: "scale(1)",
  },
  itemsLeave: {
    transition: "none",
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-out",
  },
  itemsLeaveFrom: {
    opacity: 1,
    transform: "scale(1)",
  },
  itemsLeaveTo: {
    opacity: 0,
    transform: "scale(0.95)",
  },
  menu: {
    position: "relative",
  },
  positionLeft: {
    left: 0,
  },
  positionRight: {
    right: 0,
  },
});
