/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ArticleCard_article$key } from "src/types/__generated__/ArticleCard_article.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { Link, useNavigate } from "react-router-dom";

import { ARTICLES_DETAIL_PATH } from "src/app/router/Router";
import {
  HeadingLevel,
  SBActivityIndicator,
  SBButton,
  SBHeading,
  SBImage,
  SBMarkdownRenderer,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  featured?: boolean;
  queryKey: ArticleCard_article$key;
  shouldShowSummary?: boolean;
}>;

const ArticleCard = ({
  queryKey,
  featured = false,
  shouldShowSummary = true,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();

  const data = useFragment(
    graphql`
      fragment ArticleCard_article on Article {
        id
        createdAt
        updatedAt
        publishedAt
        summary
        image
        title
        body
        slug
        imageAlt
      }
    `,
    queryKey,
  );

  const { image, imageAlt, publishedAt, slug, summary, title } = data;

  const publishedAtDate =
    publishedAt == null
      ? publishedAt
      : new Date(
          Number.isNaN(Number(publishedAt)) ? publishedAt : Number(publishedAt),
        );

  const publishedOn =
    publishedAtDate == null
      ? null
      : publishedAtDate.toLocaleDateString(i18n.language, {
          day: "numeric",
          month: "short",
          year: "numeric",
        });

  const articlePath = ARTICLES_DETAIL_PATH.replace(":slug", slug);

  const handleClickLink = (event: React.SyntheticEvent) => {
    event.preventDefault();
    startTransition(() => {
      navigate(articlePath);
    });
  };

  return (
    <article
      {...stylex.props(
        auto,
        featured && styles.featured,
        isPending && styles.isPending,
      )}
    >
      <Link
        {...stylex.props(styles.imageLink)}
        to={articlePath}
        onClick={handleClickLink}
      >
        <SBImage
          alt={imageAlt ?? title}
          aria-hidden={imageAlt == null}
          src={image}
          style={[styles.articleImage, featured && styles.featuredImage]}
        />
        {isPending ? (
          <SBActivityIndicator
            containerStyle={styles.spinnerContainer}
            style={styles.spinner}
          />
        ) : null}
      </Link>

      <div {...stylex.props(styles.content)}>
        <div>
          <SBHeading
            level={HeadingLevel.H2}
            style={[styles.heading, featured && styles.featuredHeading]}
          >
            <Link
              {...stylex.props(styles.headingLink)}
              to={articlePath}
              onClick={handleClickLink}
            >
              {title}
            </Link>
          </SBHeading>
          <div>
            <SBHeading
              aria-label={
                publishedOn == null
                  ? ""
                  : t("blog.publishedOn", {
                      date: publishedOn,
                    })
              }
              level={HeadingLevel.H3}
              style={[styles.date, featured && styles.featuredDate]}
            >
              {publishedOn != null && publishedOn}
            </SBHeading>
            {shouldShowSummary && summary ? (
              <div {...stylex.props(styles.summary)}>
                <SBMarkdownRenderer markdown={summary} />
              </div>
            ) : null}
          </div>
        </div>
        <div>
          <SBButton
            aria-label={t("blog.readMoreAlt", {
              title,
            })}
            title={t("blog.readMore")}
            to={articlePath}
          />
        </div>
      </div>
    </article>
  );
};

const styles = stylex.create({
  articleImage: {
    display: "block",
    marginBottom: 24,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  date: {
    marginBottom: 16,
    marginTop: 8,
  },
  featured: {
    display: "grid",
    gridGap: 24,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(2, 1fr)",
      default: "repeat(2, 1fr)",
    },
    margin: 0,
  },
  featuredDate: {
    marginBottom: 0,
  },
  featuredHeading: {
    fontSize: 72,
    marginBottom: 0,
  },
  featuredImage: {
    marginBottom: 0,
  },
  heading: {
    fontSize: 32,
    marginBottom: 8,
  },
  headingLink: {
    color: {
      ":hover": colors.color,
      default: colors.color,
    },
    textDecorationLine: "none",
  },
  imageLink: {
    display: "block",
    position: "relative",
  },
  isPending: {
    opacity: 0.4,
  },
  spinner: {
    fill: colors.color,
  },
  spinnerContainer: {
    left: "50%",
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  summary: {
    marginTop: 16,
  },
});

export default ArticleCard;
