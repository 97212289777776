/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesPrivacyQuery } from "src/types/__generated__/QueriesPrivacyQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import PrivacyContent from "./PrivacyContent";

const Privacy = (): React.ReactNode => {
  const queryRef = useLoaderData() as PreloadedQuery<QueriesPrivacyQuery>;

  if (queryRef == null) {
    return null;
  }
  return (
    <SBErrorBoundary>
      <Suspense fallback={<SBFallback />}>
        <PrivacyContent queryRef={queryRef} />
      </Suspense>
    </SBErrorBoundary>
  );
};

export default Privacy;
