/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  APP_STORE_IMG,
  APP_STORE_URL,
  GOOGLE_PLAY_IMG,
  GOOGLE_PLAY_URL,
  SHORTBOXED_CDN_BASE_URL,
} from "src/app/constants";
import { SBImage } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const ANDROID_RE = /android/iu;
const IPHONE_RE = /iphone/iu;

const CAROUSEL_IMG_A = `${SHORTBOXED_CDN_BASE_URL}/phone.webp`;
const BACKGROUND_PATTERN = `url("${SHORTBOXED_CDN_BASE_URL}/pattern.webp")`;

const HomeCarousel = (): React.ReactNode => {
  const { t } = useTranslation();

  const { userAgent } = navigator;

  const isAndroid = ANDROID_RE.test(userAgent);
  const isPhone = IPHONE_RE.test(userAgent);

  const isDesktop = !isAndroid && !isPhone;

  const showAndroid = isAndroid || isDesktop;
  const showIphone = isPhone || isDesktop;

  return (
    <div
      {...stylex.props(
        auto,
        styles.outer,
        styles.backgroundImage(BACKGROUND_PATTERN),
      )}
    >
      <div {...stylex.props(styles.inner)}>
        <div {...stylex.props(styles.description)}>
          <h1 {...stylex.props(styles.heading)}>{t("home.heroText")}</h1>
          <p {...stylex.props(styles.subHeading)}>{t("home.heroSubText")}</p>
          <div {...stylex.props(styles.downloadButtons)}>
            {showIphone ? (
              <a
                {...stylex.props(styles.link)}
                aria-label={t("home.downloadIos")}
                href={APP_STORE_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <SBImage
                  alt={t("home.appStore")}
                  height={64}
                  src={APP_STORE_IMG}
                  width={150}
                />
              </a>
            ) : null}
            {showAndroid ? (
              <a
                {...stylex.props(auto, styles.link)}
                aria-label={t("home.downloadAndroid")}
                href={GOOGLE_PLAY_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                <SBImage
                  alt={t("home.googlePlay")}
                  height={64}
                  src={GOOGLE_PLAY_IMG}
                  width={165}
                />
              </a>
            ) : null}
          </div>
        </div>
        <div {...stylex.props(styles.image)}>
          <SBImage
            {...stylex.props(styles.image)}
            alt={t("home.phonesAlt")}
            height={480}
            src={CAROUSEL_IMG_A}
            width={693}
          />
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  backgroundImage: (backgroundImage: string) => ({
    backgroundColor: colors.homeCarouselBackgroundColor,
    backgroundImage,
    backgroundPosition: "center",
    backgroundRepeat: "repeat",
  }),
  description: {
    marginBlock: {
      [MOBILE]: 0,
      [TABLET]: 40,
      default: 40,
    },
    marginLeft: {
      [MOBILE]: 24,
      [TABLET]: 72,
      default: 72,
    },
    marginRight: {
      [MOBILE]: 24,
      [TABLET]: 0,
      default: 0,
    },
  },
  downloadButtons: {
    alignItems: "center",
    display: "flex",
    marginLeft: -8,
    marginTop: 10,
  },
  heading: {
    MozOsxFontSmoothing: "grayscale",
    WebkitFontSmoothing: "antialiased",
    color: colors.homeCarouselColor,
    fontFamily: "'drukmedium', sans-serif",
    fontFeatureSettings: "liga",
    fontSize: 80,
    fontWeight: 400,
    lineHeight: 0.875,
    marginBottom: 20,
    marginTop: 20,
    textRendering: "optimizeLegibility",
    textTransform: "uppercase",
  },
  image: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "block",
    },
    height: 480,
    width: 693,
  },
  inner: {
    alignItems: {
      [MOBILE]: "center",
      [TABLET]: "end",
      default: "end",
    },
    display: "flex",
    height: 496,
    justifyContent: "space-between",
    width: {
      [MOBILE]: "95%",
      [TABLET]: "95%",
      default: 1200,
    },
  },
  link: {
    borderRadius: 8,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  outer: {
    alignItems: "center",
    backgroundColor: "#000",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  subHeading: {
    MozOsxFontSmoothing: "grayscale",
    WebkitFontSmoothing: "antialiased",
    color: colors.homeCarouselColor,
    fontFamily: "'Supreme-Regular', sans-serif",
    fontFeatureSettings: "liga",
    fontSize: 16,
    lineHeight: 0.875,
    marginTop: 20,
    textRendering: "optimizeLegibility",
  },
});

export default React.memo(HomeCarousel);
