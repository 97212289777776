/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailActions_product$key } from "src/types/__generated__/ProductDetailActions_product.graphql";
import type { SellerStatus } from "src/types/__generated__/QueriesProductDetailQuery.graphql";

import * as React from "react";
import { useContext } from "react";
import { graphql, useFragment } from "react-relay";

import { UserContext } from "src/app/context/user";

import ProductDetailActionsBrowser from "./ProductDetailActionsBrowser";
import ProductDetailActionsBuyer from "./ProductDetailActionsBuyer";
import ProductDetailActionsOwner from "./ProductDetailActionsOwner";
import ProductDetailActionsSeller from "./ProductDetailActionsSeller";

type Props = Readonly<{
  achMinimumValue: number;
  employee: boolean;
  queryKey: ProductDetailActions_product$key;
  sellerStatus: SellerStatus | null | undefined;
}>;

const ProductDetailActions = ({
  achMinimumValue,
  employee,
  queryKey,
  sellerStatus,
}: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment ProductDetailActions_product on Product {
        id
        isViewerOwner
        publicStatus
        ...ProductDetailActionsOwner_product
        ...ProductDetailActionsSeller_product
        ...ProductDetailActionsBuyer_product
      }
    `,
    queryKey,
  );

  const productId = data?.id;

  const publicStatus = data?.publicStatus ?? "UNAVAILABLE";
  const isViewerOwner = data?.isViewerOwner ?? false;

  const { user } = useContext(UserContext);

  if (data == null) {
    return null;
  }

  if (productId == null) {
    return null;
  }

  if (user == null) {
    return <ProductDetailActionsBrowser />;
  }

  let content = null;
  switch (publicStatus) {
    case "FOR_SALE":
      content = isViewerOwner ? (
        <ProductDetailActionsSeller employee={employee} queryKey={data} />
      ) : (
        <ProductDetailActionsBuyer
          achMinimumValue={achMinimumValue}
          queryKey={data}
        />
      );
      break;
    case "PENDING_PURCHASE":
      content = isViewerOwner ? null : (
        <ProductDetailActionsBuyer
          achMinimumValue={achMinimumValue}
          queryKey={data}
        />
      );
      break;
    case "UNAVAILABLE":
      content = isViewerOwner ? (
        <ProductDetailActionsOwner
          employee={employee}
          queryKey={data}
          sellerStatus={sellerStatus}
        />
      ) : null;
      break;
    case "SOLD":
    case "PROCESSING_PURCHASE":
    case "HIDDEN":
    default:
      content = null;
      break;
  }
  return content;
};

export default ProductDetailActions;
