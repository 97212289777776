/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesInvoiceQuery } from "src/types/__generated__/QueriesInvoiceQuery.graphql";

import * as React from "react";
import { useLoaderData } from "react-router-dom";

import InvoiceContent from "./InvoiceContent";

const Invoice = (): React.ReactNode => {
  const queryRef = useLoaderData() as PreloadedQuery<QueriesInvoiceQuery>;

  return <InvoiceContent queryRef={queryRef} />;
};

export default Invoice;
