/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  ConcreteBatch,
  RelayNetworkLayerOpts,
  Variables,
} from "react-relay-network-modern";

import { createClient } from "graphql-ws";
import {
  cacheMiddleware,
  errorMiddleware,
  loggerMiddleware,
  perfMiddleware,
  RelayNetworkLayer,
  urlMiddleware,
} from "react-relay-network-modern";
import {
  Environment as RelayModernEnvironment,
  Observable,
  RecordSource,
  Store,
} from "relay-runtime";

import { config as GraphConfig } from "src/api/constants";
import { StorageKey } from "src/app/constants";

import authMiddleware from "./authMiddleware";

const CACHE_SIZE = 100;
const CACHE_EXPIRATION_MS = 15 * 60 * 1000; // 15 minutes
const subscriptionsClient = createClient({
  connectionParams: () => {
    // Get the logged-in user's token, if any
    const userKey = StorageKey.User;
    const userData = localStorage.getItem(userKey);
    const user = userData == null ? null : JSON.parse(userData);

    // Get the anonymous token, if any
    const anonymousKey = StorageKey.Anonymous;
    const anonymousData = localStorage.getItem(anonymousKey);
    const anonymous = anonymousData == null ? null : JSON.parse(anonymousData);

    const accessToken = user?.accessToken ?? anonymous?.anonymousToken ?? "";

    if (accessToken === "") {
      return {};
    }
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  },
  url: GraphConfig.WS_URL,
});

function subscribeFn(operation: ConcreteBatch, variables: Variables) {
  return Observable.create<unknown>((sink) => {
    if (operation.text == null) {
      // $FlowFixMe[incompatible-use]
      return sink.error(new Error("Operation text cannot be empty"));
    }
    return subscriptionsClient.subscribe(
      {
        operationName: operation.name,
        query: operation.text,
        variables,
      },
      sink,
    );
  });
}

const middlewares = [
  cacheMiddleware({
    size: CACHE_SIZE,
    ttl: CACHE_EXPIRATION_MS,
  }),
  urlMiddleware({
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    },
    url: (_req) => Promise.resolve(GraphConfig.API_URL),
  }),
  authMiddleware({
    token: async () => {
      // Get the logged-in user's token, if any
      const userKey = StorageKey.User;
      const userData = localStorage.getItem(userKey);
      const user = userData == null ? null : JSON.parse(userData);
      const token: string = user?.accessToken;

      if (token != null) {
        return token;
      }

      // Get the anonymous token, if any
      const anonymousKey = StorageKey.Anonymous;
      const anonymousData = localStorage.getItem(anonymousKey);
      const anonymous =
        anonymousData == null ? null : JSON.parse(anonymousData);
      let anonymousToken: string = anonymous?.anonymousToken;

      // Get a new anonymous token
      if (anonymousToken == null || anonymousToken === "") {
        try {
          const result = await fetch(
            `${GraphConfig.API_PROTOCOL}://${GraphConfig.API_HOST}/auth/login/anonymous`,
            {
              headers: {
                "Content-Type": "application/json; charset=utf-8",
              },
              method: "POST",
            },
          );
          const json = await result.json();

          anonymousToken = json?.accessToken;

          localStorage.setItem(
            anonymousKey,
            JSON.stringify({ anonymousToken }),
          );
        } catch {
          // Nothing to do.
        }
      }

      return anonymousToken;
    },
    tokenRefreshPromise: async (_req) => {
      const userKey = StorageKey.User;
      const userData = localStorage.getItem(userKey);
      const user = userData == null ? null : JSON.parse(userData);

      const lastRefreshToken = user?.refreshToken;

      let accessToken: string | null = null;

      try {
        const result = await fetch(
          `${GraphConfig.API_PROTOCOL}://${GraphConfig.API_HOST}/auth/refresh`,
          {
            headers: {
              Authorization: `Bearer ${lastRefreshToken ?? ""}`,
            },
            method: "POST",
          },
        );
        const json = await result.json();

        const refreshToken = json.refreshToken;
        accessToken = json.accessToken;

        const obj =
          user == null
            ? null
            : {
                accessToken,
                anonymousToken: null,
                hasAcceptedPrivacyPolicy: user.hasAcceptedPrivacyPolicy,
                hasAcceptedTermsOfService: user.hasAcceptedTermsOfService,
                refreshToken,
                userId: user.userId,
              };

        localStorage.setItem(userKey, JSON.stringify(obj));
      } catch (error: unknown) {
        localStorage.removeItem(StorageKey.User);
      }

      return accessToken ?? "";
    },
  }),
  __DEV__ && process.env.REACT_APP_LOG_RELAY_ERRORS === "true"
    ? errorMiddleware()
    : null,
  __DEV__ && process.env.REACT_APP_LOG_RELAY_QUERIES === "true"
    ? loggerMiddleware()
    : null,
  __DEV__ && process.env.REACT_APP_LOG_RELAY_PERFORMANCE === "true"
    ? perfMiddleware()
    : null,
];

const options: RelayNetworkLayerOpts = {
  // @ts-ignore No idea what TypeScript wants here.
  subscribeFn,
};

const createRelayEnvironment = () => {
  const network = new RelayNetworkLayer(middlewares, options);
  const source = new RecordSource();
  const store = new Store(source);
  return new RelayModernEnvironment({ network, store });
};

const RelayEnvironment = createRelayEnvironment();

export default RelayEnvironment;
