/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionLotMeta_product$key } from "src/types/__generated__/AuctionLotMeta_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SBProductDetailMeta } from "src/sbxui";
import { auto } from "src/themes";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AuctionLotMeta_product$key;
}>;

const AuctionLotMeta = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment AuctionLotMeta_product on Product {
        submissionNotes
        comicDetails {
          grade
          gradingAuthority
          year
          publisher
          keyComments
          artComments
          barcode
          categoryRollup
          gradeText
          publicGraderNotes
          pedigree
          variant
          pageQuality
          signatures
          specialCopy
        }
        gradedCopy {
          slabDefects
        }
      }
    `,
    queryKey,
  );

  const { comicDetails, gradedCopy, submissionNotes } = data;

  const {
    grade,
    gradingAuthority,
    year,
    publisher,
    keyComments,
    artComments,
    barcode,
    categoryRollup,
    gradeText,
    publicGraderNotes,
    pedigree,
    variant,
    pageQuality,
    signatures,
    specialCopy,
  } = comicDetails ?? {};

  const { slabDefects } = gradedCopy;
  const gradeValue = grade === 0 ? t("rawGrades.0-0") : grade?.toFixed(1);

  const items = [
    {
      translationKey: "product.publisherYear",
      value: `${publisher} (${year})`,
    },
    variant
      ? {
          translationKey: "product.variant",
          value: variant,
        }
      : {},
    {
      args: { gradingAuthority },
      translationKey: "product.grade",
      value:
        gradingAuthority === "RAW"
          ? t(`rawGrades.${kebabCase(gradeValue)}`)
          : gradeValue,
    },
    {
      args: { gradingAuthority },
      translationKey: "product.label",
      value: t(`categoryRollup.${categoryRollup}`),
    },
    gradingAuthority === "RAW"
      ? {}
      : {
          args: { gradingAuthority },
          translationKey: "product.barcode",
          value: barcode,
        },
    pageQuality
      ? {
          args: { gradingAuthority },
          translationKey: "product.pageQuality",
          value: pageQuality,
        }
      : {},
    keyComments
      ? {
          args: { gradingAuthority },
          translationKey: "product.keyComments",
          value: keyComments,
          valueAsHTML: true,
        }
      : {},
    artComments
      ? {
          args: { gradingAuthority },
          translationKey: "product.artComments",
          value: artComments,
          valueAsHTML: true,
        }
      : {},
    (slabDefects?.length ?? 0) > 0
      ? {
          translationKey: "product.slabDefects",
          value: slabDefects?.map((defect) => t(`product.defects.${defect}`)),
        }
      : {},
    specialCopy
      ? {
          translationKey: "product.specialCopy",
          value: t(`specialCopy.${specialCopy}`),
        }
      : {},
    pedigree
      ? {
          translationKey: "product.pedigree",
          value: pedigree,
        }
      : {},
    signatures
      ? {
          translationKey: "product.signatures",
          value: signatures,
          valueAsHTML: true,
        }
      : {},
    publicGraderNotes
      ? {
          translationKey: "product.publicGraderNotes",
          value: publicGraderNotes,
          valueAsHTML: true,
        }
      : {},
    gradeText
      ? {
          args: { gradingAuthority },
          translationKey: "product.gradeText",
          value: gradeText,
          valueAsHTML: true,
        }
      : {},
    submissionNotes
      ? {
          translationKey: "product.submissionNotes",
          value: submissionNotes,
        }
      : {},
  ].filter(({ translationKey }) => translationKey != null);

  return (
    <div {...stylex.props(styles.list)}>
      {items.map(
        ({ args, translationKey, value, valueAsHTML }, ii) =>
          translationKey && (
            <div
              key={translationKey}
              {...stylex.props(
                auto,
                styles.item,
                ii % 2 === 0 && styles.itemOdd,
              )}
            >
              <SBProductDetailMeta
                args={args}
                translationKey={translationKey}
                value={value}
                valueAsHTML={valueAsHTML}
              />
            </div>
          ),
      )}
    </div>
  );
};

const styles = stylex.create({
  item: {
    boxSizing: "border-box",
    paddingLeft: {
      [MOBILE]: 0,
      [TABLET]: 0,
      default: 40,
    },
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  itemOdd: {
    paddingLeft: 0,
    paddingRight: {
      [MOBILE]: 0,
      [TABLET]: 0,
      default: 40,
    },
  },
  list: {
    alignItems: "start",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 14,
    justifyContent: "flex-start",
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
});

export default AuctionLotMeta;
