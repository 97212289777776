/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { BidNowView_auctionLot$key } from "src/types/__generated__/BidNowView_auctionLot.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import {
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBParagraph,
  SBTextInput,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

import BidNowTimer from "./BidNowTimer";

const PRICE_MAX_LENGTH = 6;

const DEFAULT_MINIMUM_BID = 2;

type Props = Readonly<{
  employee: boolean;
  queryKey: BidNowView_auctionLot$key;
}>;

const BidNowView = ({ employee, queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const [commit, isInFlight] = useMutation(graphql`
    mutation BidNowViewMutation($input: CreateAuctionBidInput!) {
      createAuctionBid(createAuctionBidInput: $input) {
        lot {
          ...AuctionLotView_auctionLot
          ...BidNowView_auctionLot
        }
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment BidNowView_auctionLot on AuctionLot {
        ...BidNowTimer_auctionLot
        id
        currentBidAmount
        currentBidCurrency
        bidCount
        isClosed
        minimumBidAmountRequired
        maximumBidAmount
        isViewerBidding
        isViewerHighBidder
        auction {
          isTest
        }
        product {
          comicDetails {
            barcode
            label {
              id
            }
            grade
            gradingAuthority
          }
        }
      }
    `,
    queryKey,
  );

  const {
    auction,
    bidCount,
    currentBidAmount,
    currentBidCurrency,
    id: auctionLotId,
    isClosed,
    isViewerBidding,
    isViewerHighBidder,
    maximumBidAmount,
    minimumBidAmountRequired,
    product,
  } = data;

  const isTestAuction = auction.isTest ?? false;

  const comicDetails = product?.comicDetails;
  const gradingAuthority = comicDetails?.gradingAuthority;

  const [newMaxBid, setNewMaxBid] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const isButtonDisabled =
    newMaxBid === "" ||
    Number.parseInt(newMaxBid, 10) <
      (minimumBidAmountRequired ?? DEFAULT_MINIMUM_BID) ||
    isClosed;

  const handleBidChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewMaxBid(event.currentTarget.value);
  };

  const handleClickBid = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isButtonDisabled || isLoading || isInFlight) {
      return;
    }
    commit({
      onCompleted() {
        setIsLoading(false);
        setNewMaxBid("");
      },
      onError(error) {
        setIsLoading(false);
        setErrorMessage(
          // @ts-ignore This is the localized message from the error response.
          error?.res?.errors?.[0].message ??
            t("auction.lot.bidding-modal.errors.default"),
        );
      },
      variables: {
        input: {
          amount: Number.parseInt(newMaxBid, 10),
          auctionLotId,
          currency: currentBidCurrency,
        },
      },
    });
  };

  let caption = null;
  if (isViewerBidding && isViewerHighBidder && maximumBidAmount != null) {
    caption = (
      <SBParagraph style={styles.bidHelp}>
        {t("auction.lot.bidding-modal.current-max-bid", {
          price: formatMoney(
            maximumBidAmount,
            currentBidCurrency,
            i18n.language,
          ),
        })}
      </SBParagraph>
    );
  } else if (minimumBidAmountRequired != null) {
    caption = (
      <SBParagraph style={styles.bidHelp}>
        {t("auction.lot.bidding-modal.minimum-bid-required", {
          price: formatMoney(
            minimumBidAmountRequired,
            currentBidCurrency,
            i18n.language,
          ),
        })}
      </SBParagraph>
    );
  }

  const isRawBook = gradingAuthority === "RAW";
  let rawBookDisclaimer = null;
  if (isRawBook) {
    rawBookDisclaimer = (
      <SBParagraph style={styles.disclaimer}>
        {t("auction.lot.bidding-modal.raw-disclaimer")}
      </SBParagraph>
    );
  }

  let placeholder = "$";
  switch (currentBidCurrency) {
    case "USD":
      placeholder = "$";
      break;
    case "AUD":
    case "CAD":
    case "EUR":
    case "GBP":
    case "%future added value":
      placeholder = "USD";
      break;
  }

  return (
    <div {...stylex.props(styles.root)}>
      {isViewerBidding && isViewerHighBidder ? (
        <div {...stylex.props(auto, styles.banner(colors.bannerSuccess))}>
          <SBParagraph style={styles.bannerText}>
            {t("auction.lot.bidding-modal.banners.winning")}
          </SBParagraph>
        </div>
      ) : null}
      {isViewerBidding && !isViewerHighBidder ? (
        <div {...stylex.props(auto, styles.banner(colors.bannerError))}>
          <SBParagraph style={styles.bannerText}>
            {t("auction.lot.bidding-modal.banners.outbid")}
          </SBParagraph>
        </div>
      ) : null}
      {bidCount > 0 && (
        <SBParagraph style={styles.bidCount}>
          {t("auction.lot.bidding-modal.total-bid", {
            count: bidCount,
          })}
        </SBParagraph>
      )}
      <SBParagraph style={styles.currentBid}>
        {currentBidAmount > 0
          ? t("auction.lot.bidding-modal.current-bid", {
              price: formatMoney(
                currentBidAmount,
                currentBidCurrency,
                i18n.language,
              ),
            })
          : t("auction.lot.bidding-modal.minimum-bid", {
              price: formatMoney(
                minimumBidAmountRequired ?? DEFAULT_MINIMUM_BID,
                currentBidCurrency,
                i18n.language,
              ),
            })}
      </SBParagraph>
      <BidNowTimer queryKey={data} />
      <SBHeading level={HeadingLevel.H3} style={styles.increaseBid}>
        {t(
          isViewerBidding
            ? "auction.lot.bidding-modal.increase-max-bid"
            : "auction.lot.bidding-modal.max-bid",
        )}
      </SBHeading>
      <SBTextInput
        errorMessage={errorMessage}
        id="bid"
        inputStyle={styles.inputStyle}
        label={t(
          isViewerBidding
            ? "auction.lot.bidding-modal.increase-max-bid"
            : "auction.lot.bidding-modal.max-bid",
        )}
        maxLength={PRICE_MAX_LENGTH}
        placeholder={placeholder}
        type="number"
        value={newMaxBid}
        onChange={handleBidChange}
      />
      {caption}
      {employee && !isTestAuction ? (
        <SBParagraph style={[styles.disclaimer, styles.disclaimerEmphasis]}>
          {t("auction.lot.bidding-modal.employee")}
        </SBParagraph>
      ) : (
        <SBParagraph style={styles.disclaimer}>
          {t("auction.lot.bidding-modal.disclaimer")}
        </SBParagraph>
      )}
      {employee && !isTestAuction ? null : rawBookDisclaimer}
      <SBButton
        block={true}
        disabled={(employee && !isTestAuction) || isButtonDisabled}
        loading={isLoading || isInFlight}
        title={t(
          isViewerBidding
            ? "auction.lot.bidding-modal.buttons.increase-max-bid"
            : "auction.lot.bidding-modal.buttons.bid-now",
        )}
        variation={ButtonVariation.Emphasis}
        onClick={handleClickBid}
      />
    </div>
  );
};

const styles = stylex.create({
  banner: (backgroundColor: string) => ({
    backgroundColor,
    marginBottom: 16,
    marginInline: -16,
    marginTop: -16,
    paddingBlock: 8,
    paddingInline: 16,
  }),
  bannerText: {
    color: colors.bannerText,
    fontWeight: "bold",
    marginBottom: 0,
    textAlign: "center",
  },
  bidCount: {
    color: colors.colorEmphasis,
    paddingInline: 16,
    textAlign: "center",
  },
  bidHelp: {
    marginTop: 8,
    textAlign: "center",
  },
  currentBid: {
    fontWeight: "bold",
    textAlign: "center",
  },
  currentBidContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  disclaimer: {
    fontSize: 12,
    textAlign: "center",
  },
  disclaimerEmphasis: {
    color: colors.colorEmphasis,
  },
  errorMessage: {
    marginBottom: 16,
  },
  increaseBid: {
    marginBottom: 16,
    textAlign: "center",
  },
  inputStyle: {
    textAlign: "center",
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
});

export default BidNowView;
