/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TableConfig } from "src/sbxui";
import type { SellerAccountStatsFeedbackTable_sellerStats$key } from "src/types/__generated__/SellerAccountStatsFeedbackTable_sellerStats.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import { SBTable, SBTableRow } from "src/sbxui";

type Props = Readonly<{
  queryKey: SellerAccountStatsFeedbackTable_sellerStats$key;
}>;

const SellerAccountStatsFeedbackTable = ({
  queryKey,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountStatsFeedbackTable_sellerStats on UserSellerStats {
        gradedShippingScore
        gradingScore
        rawShippingScore
      }
    `,
    queryKey,
  );

  const gradedShippingScore = data?.gradedShippingScore;
  const gradingScore = data?.gradingScore;
  const rawShippingScore = data?.rawShippingScore;

  const columns = [
    {
      id: "feedback",
      label: t("seller-account.stats.feedback-table.column.feedback"),
    },
    {
      id: "score",
      label: t("seller-account.stats.feedback-table.column.score"),
    },
  ];

  const rows = [
    <SBTableRow
      key="GRADED_SHIPPING"
      id="GRADED_SHIPPING"
      td={[
        {
          id: "feedback",
          label: (
            <span {...stylex.props(styles.datum)}>
              {t("seller-account.stats.feedback-table.row.graded-shipping")}
            </span>
          ),
        },
        {
          id: "score",
          label:
            gradedShippingScore == null || gradedShippingScore === 0
              ? t("seller-account.stats.feedback-table.row.not-applicable")
              : t("seller-account.stats.feedback-table.row.score", {
                  percent: gradedShippingScore,
                }),
        },
      ]}
    />,
    <SBTableRow
      key="RAW_SHIPPING"
      id="RAW_SHIPPING"
      td={[
        {
          id: "feedback",
          label: (
            <span {...stylex.props(styles.datum)}>
              {t("seller-account.stats.feedback-table.row.raw-shipping")}
            </span>
          ),
        },
        {
          id: "score",
          label:
            rawShippingScore == null || rawShippingScore === 0
              ? t("seller-account.stats.feedback-table.row.not-applicable")
              : t("seller-account.stats.feedback-table.row.score", {
                  percent: rawShippingScore,
                }),
        },
      ]}
    />,
    <SBTableRow
      key="RAW_GRADING"
      id="RAW_GRADING"
      td={[
        {
          id: "feedback",
          label: (
            <span {...stylex.props(styles.datum)}>
              {t("seller-account.stats.feedback-table.row.raw-grading")}
            </span>
          ),
        },
        {
          id: "score",
          label:
            gradingScore == null || gradingScore === 0
              ? t("seller-account.stats.feedback-table.row.not-applicable")
              : t("seller-account.stats.feedback-table.row.score", {
                  percent: gradingScore,
                }),
        },
      ]}
    />,
  ];

  const tableConfig: TableConfig = {
    columns,
  };

  return (
    <div {...stylex.props(styles.root)}>
      <SBTable config={tableConfig} rows={rows} />
    </div>
  );
};

const styles = stylex.create({
  datum: {
    whiteSpace: "nowrap",
  },
  root: {
    marginTop: 24,
  },
});

export default SellerAccountStatsFeedbackTable;
