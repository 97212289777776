/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { FacebookShareButton, TwitterShareButton } from "react-share";

import { SBLink } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  translationKey: string;
  url: string;
}>;

const ShareButtons = ({ url, translationKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <div {...stylex.props(styles.shareButtons)}>
      <FacebookShareButton {...stylex.props(styles.shareButton)} url={url}>
        <span {...stylex.props(auto, styles.social, styles.socialFacebook)}>
          {t(translationKey, {
            platform: t("footer.links.facebook"),
          })}
        </span>
      </FacebookShareButton>
      <TwitterShareButton {...stylex.props(styles.shareButton)} url={url}>
        <span {...stylex.props(auto, styles.social, styles.socialX)}>
          {t(translationKey, {
            platform: t("footer.links.x"),
          })}
        </span>
      </TwitterShareButton>
      <SBLink
        external={true}
        href="https://www.instagram.com/shortboxed/"
        style={styles.shareButton}
      >
        <span {...stylex.props(auto, styles.social, styles.socialInstagram)}>
          {t(translationKey, {
            platform: t("footer.links.instagram"),
          })}
        </span>
      </SBLink>
    </div>
  );
};

const styles = stylex.create({
  shareButton: {
    display: "block",
    marginLeft: 32,
  },
  shareButtons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  social: {
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    display: "block",
    height: 20,
    overflow: "hidden",
    textIndent: -99999,
    width: 24,
  },
  socialFacebook: {
    backgroundImage: colors.socialFacebook,
  },
  socialInstagram: {
    backgroundImage: colors.socialInstagram,
  },
  socialX: {
    backgroundImage: colors.socialX,
  },
});

export default ShareButtons;
