/**
 * @generated SignedSource<<d8e77a6820aaa302928f95a365dc9f7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesAccountPasswordQuery$variables = Record<PropertyKey, never>;
export type QueriesAccountPasswordQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"AccountPasswordView_user">;
    } | null | undefined;
  };
};
export type QueriesAccountPasswordQuery = {
  response: QueriesAccountPasswordQuery$data;
  variables: QueriesAccountPasswordQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesAccountPasswordQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AccountPasswordView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesAccountPasswordQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3898e7a60a3ba5c44bf7c1a7d71758b",
    "id": null,
    "metadata": {},
    "name": "QueriesAccountPasswordQuery",
    "operationKind": "query",
    "text": "query QueriesAccountPasswordQuery {\n  viewer {\n    user {\n      ...AccountPasswordView_user\n      id\n    }\n  }\n}\n\nfragment AccountPasswordView_user on User {\n  id\n}\n"
  }
};

(node as any).hash = "72661e47b43835a2d3a2ef393f9b5407";

export default node;
