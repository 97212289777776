/**
 * @generated SignedSource<<aa0abf0914207228bd42517f28770bb8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountPersonalInfoView_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PersonalInfoForm_user">;
  readonly " $fragmentType": "AccountPersonalInfoView_user";
};
export type AccountPersonalInfoView_user$key = {
  readonly " $data"?: AccountPersonalInfoView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountPersonalInfoView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountPersonalInfoView_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PersonalInfoForm_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3af3419a2bedf6176c485b3ebd065d23";

export default node;
