/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HeadingLevel, SBHeading } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

const ProductDetailActionsBuyerSold = (): React.ReactNode => {
  const { t } = useTranslation();
  return (
    <SBHeading level={HeadingLevel.H3} style={styles.sold}>
      {t("product.status.sold")}
    </SBHeading>
  );
};

const styles = stylex.create({
  sold: {
    color: colors.colorMuted,
    marginTop: 16,
  },
});

export default ProductDetailActionsBuyerSold;
