/**
 * @generated SignedSource<<839a7db12a2a674ae141b264c402ca7e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesSellerAccountLabelSizeQuery$variables = Record<PropertyKey, never>;
export type QueriesSellerAccountLabelSizeQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"SellerAccountLabelSizeView_user">;
    } | null | undefined;
  };
};
export type QueriesSellerAccountLabelSizeQuery = {
  response: QueriesSellerAccountLabelSizeQuery$data;
  variables: QueriesSellerAccountLabelSizeQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSellerAccountLabelSizeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SellerAccountLabelSizeView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesSellerAccountLabelSizeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "labelSize",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c4a5de1ea21d8ec37075bd23e118cff7",
    "id": null,
    "metadata": {},
    "name": "QueriesSellerAccountLabelSizeQuery",
    "operationKind": "query",
    "text": "query QueriesSellerAccountLabelSizeQuery {\n  viewer {\n    user {\n      ...SellerAccountLabelSizeView_user\n      id\n    }\n  }\n}\n\nfragment SellerAccountLabelSizeView_user on User {\n  id\n  labelSize\n}\n"
  }
};

(node as any).hash = "0db338aab9dd1f3fd2bfdf83b5699eb4";

export default node;
