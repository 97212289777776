/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesPrivacyQuery } from "src/types/__generated__/QueriesPrivacyQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { PrivacyQuery } from "src/app/router/Queries";

import PrivacyView from "./PrivacyView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesPrivacyQuery>;
}>;

const PrivacyContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(PrivacyQuery, queryRef);

  if (queryKey.privacy == null) {
    return null;
  }

  return <PrivacyView queryKey={queryKey.privacy} />;
};

export default PrivacyContent;
