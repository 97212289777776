/**
 * @generated SignedSource<<ba6a13ee938e09e8b2290f1b69d465e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BidAcceptedSubscriptionInput = {
  auctionLotId: string;
};
export type AuctionItemBidSubscription$variables = {
  input: BidAcceptedSubscriptionInput;
};
export type AuctionItemBidSubscription$data = {
  readonly bidAcceptedSubscription: {
    readonly " $fragmentSpreads": FragmentRefs<"AuctionItem_auctionLot">;
  };
};
export type AuctionItemBidSubscription = {
  response: AuctionItemBidSubscription$data;
  variables: AuctionItemBidSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "bidAcceptedSubscriptionInput",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuctionItemBidSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuctionLot",
        "kind": "LinkedField",
        "name": "bidAcceptedSubscription",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuctionItem_auctionLot"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuctionItemBidSubscription",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuctionLot",
        "kind": "LinkedField",
        "name": "bidAcceptedSubscription",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isClosed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closesAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBidAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBidCurrency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isViewerBidding",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isViewerHighBidder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isViewerOwner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isViewerWatching",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startingAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Auction",
            "kind": "LinkedField",
            "name": "auction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "biddingStartsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ComicDetail",
                "kind": "LinkedField",
                "name": "comicDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FairMarketValueScore",
                "kind": "LinkedField",
                "name": "fmvScore",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImagesConnection",
                "kind": "LinkedField",
                "name": "images",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "quality",
                                "value": 100
                              },
                              {
                                "kind": "Literal",
                                "name": "webp",
                                "value": true
                              },
                              {
                                "kind": "Literal",
                                "name": "width",
                                "value": 1000
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": "url(quality:100,webp:true,width:1000)"
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4d9af7e85343f15ddcc90feba1499d5",
    "id": null,
    "metadata": {},
    "name": "AuctionItemBidSubscription",
    "operationKind": "subscription",
    "text": "subscription AuctionItemBidSubscription(\n  $input: BidAcceptedSubscriptionInput!\n) {\n  bidAcceptedSubscription(bidAcceptedSubscriptionInput: $input) {\n    ...AuctionItem_auctionLot\n    id\n  }\n}\n\nfragment AuctionItem_auctionLot on AuctionLot {\n  id\n  shortDescription\n  isClosed\n  closesAt\n  currentBidAmount\n  currentBidCurrency\n  isViewerBidding\n  isViewerHighBidder\n  isViewerOwner\n  isViewerWatching\n  startingAmount\n  auction {\n    biddingStartsAt\n    type\n    id\n  }\n  product {\n    comicDetails {\n      title\n      number\n      grade\n      gradingAuthority\n      id\n    }\n    fmvScore {\n      maxValue\n      minValue\n      currency\n    }\n    images {\n      edges {\n        node {\n          url(quality: 100, webp: true, width: 1000)\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "35913576513ee4ef2d2e015944002d5f";

export default node;
