/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { DeclineOfferContentQuery } from "src/types/__generated__/DeclineOfferContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import DeclineOfferView from "./DeclineOfferView";

type Props = Readonly<{
  offerId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const DeclineOfferContent = ({ offerId, setOpen }: Props): React.ReactNode => {
  const data = useLazyLoadQuery<DeclineOfferContentQuery>(
    graphql`
      query DeclineOfferContentQuery($id: ID!) {
        ...DeclineOfferView_offer
      }
    `,
    {
      id: offerId ?? "",
    },
  );

  return <DeclineOfferView offer={data} offerId={offerId} setOpen={setOpen} />;
};

export default DeclineOfferContent;
