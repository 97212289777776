/**
 * @generated SignedSource<<db1962251e9da76124555f4baf1d9918>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type OfferStatus = "ACCEPTED" | "DECLINED" | "EXPIRED" | "IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsBuyerFavorite_product$data = {
  readonly comicDetails: {
    readonly grade: number | null | undefined;
    readonly gradingAuthority: GradingAuthority;
    readonly number: string;
    readonly publisher: string;
    readonly title: string;
    readonly variant: string | null | undefined;
  } | null | undefined;
  readonly currency: Currency;
  readonly id: string;
  readonly offers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currency: Currency;
        readonly expiresAt: string;
        readonly id: string;
        readonly price: number;
        readonly status: OfferStatus;
      };
    }>;
  } | null | undefined;
  readonly price: number;
  readonly " $fragmentType": "ProductDetailActionsBuyerFavorite_product";
};
export type ProductDetailActionsBuyerFavorite_product$key = {
  readonly " $data"?: ProductDetailActionsBuyerFavorite_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsBuyerFavorite_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsBuyerFavorite_product",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variant",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publisher",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "updatedAt": "DESC"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "reason": [
              "FAVORITE"
            ],
            "status": "IN_REVIEW"
          }
        }
      ],
      "concreteType": "ProductOffersConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductOffersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Offer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expiresAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "offers(first:1,orderBy:{\"updatedAt\":\"DESC\"},where:{\"reason\":[\"FAVORITE\"],\"status\":\"IN_REVIEW\"})"
    },
    (v2/*: any*/)
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "c81a89913690bd28774640793c5f7d96";

export default node;
