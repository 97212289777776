/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { PageContext } from "src/app/context/page";
import { UserContext } from "src/app/context/user";

import DeletionNotLoggedInView from "./DeletionNotLoggedInView";
import DeletionView from "./DeletionView";

const Deletion = (): React.ReactNode => {
  const userContext = useContext(UserContext);
  const pageContext = useContext(PageContext);
  const { t } = useTranslation();

  useEffect(() => {
    pageContext?.setTitle(t("account-deletion.document-title"));
  }, [t, pageContext]);

  return (
    <div aria-live="polite">
      {userContext.user == null ? (
        <DeletionNotLoggedInView />
      ) : (
        <DeletionView />
      )}
    </div>
  );
};

export default React.memo(Deletion);
