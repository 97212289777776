/**
 * @generated SignedSource<<d8a6b8026512f4c9cc4660d679f85c1b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AuctionType = "COMMUNITY" | "CURATED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuctionSearchHeading_auction$data = {
  readonly biddingClosesAt: string;
  readonly biddingStartsAt: string;
  readonly id: string;
  readonly lastLotClosesAt: string | null | undefined;
  readonly title: string;
  readonly type: AuctionType;
  readonly " $fragmentType": "AuctionSearchHeading_auction";
};
export type AuctionSearchHeading_auction$key = {
  readonly " $data"?: AuctionSearchHeading_auction$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionSearchHeading_auction">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionSearchHeading_auction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "biddingStartsAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "biddingClosesAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastLotClosesAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    }
  ],
  "type": "Auction",
  "abstractKey": null
};

(node as any).hash = "5444c0f7dea368a06378ce85d3a238ba";

export default node;
