/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";
import type { ChangeEvent } from "react";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  className?: string;
  disabled?: boolean;
  id: string;
  label: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onClear?: (e: React.SyntheticEvent) => void;
  placeholder?: string;
  prefix?: string;
  readOnly?: boolean;
  rows?: number;
  showLabel?: boolean;
  style?: StyleXStyles;
  value: string;
}>;

export const SBTextArea = ({
  disabled = false,
  id,
  label,
  rows = 5,
  maxLength,
  onChange,
  placeholder,
  readOnly = false,
  showLabel = false,
  style,
  value,
}: Props): React.ReactNode => {
  return (
    <div {...stylex.props(styles.root, style)}>
      {Boolean(label) && (
        <label
          {...stylex.props(auto, showLabel ? null : styles.label)}
          aria-label={label}
          htmlFor={id}
        >
          <div
            {...stylex.props(
              styles.labelText,
              showLabel ? styles.labelShow : null,
            )}
          >
            {label}
          </div>
        </label>
      )}
      <div {...stylex.props(styles.container, Boolean(label) && styles.label)}>
        <textarea
          {...stylex.props(styles.textarea)}
          disabled={disabled}
          id={id}
          maxLength={maxLength}
          placeholder={placeholder}
          readOnly={readOnly}
          rows={rows}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

const styles = stylex.create({
  container: {
    backgroundColor: colors.inputTextBackgroundColor,
    borderColor: colors.inputTextBorderColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    position: "relative",
    width: "100%",
  },
  label: {
    color: colors.color,
    display: "flex",
  },
  labelShow: {
    fontWeight: 600,
    marginBottom: 8,
    textIndent: 0,
  },
  labelText: {
    textIndent: -99999,
    whiteSpace: "nowrap",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  textarea: {
    "::-webkit-inner-spin-button": {
      appearance: "none",
    },
    "::-webkit-outer-spin-button": {
      appearance: "none",
    },
    "::placeholder": {
      color: colors.inputTextPlaceholderColor,
    },
    appearance: "textfield",
    background: "transparent",
    borderRadius: 24,
    borderWidth: 0,
    color: colors.inputTextColor,
    minHeight: 32,
    // lineHeight: 24,
    outline: {
      ":focus-visible": colors.inputTextOutline,
    },
    padding: 8,
    resize: "vertical",
    width: "100%",
  },
});
