/**
 * @generated SignedSource<<f2b895d1aa6305339eb4f179a80562b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HomeView_verticalSearch$data = {
  readonly __id: string;
  readonly verticalSearch: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"SearchResultCard_typesenseSearch">;
      };
    }>;
  };
  readonly " $fragmentType": "HomeView_verticalSearch";
};
export type HomeView_verticalSearch$key = {
  readonly " $data"?: HomeView_verticalSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"HomeView_verticalSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categoryRollups"
    },
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gradeMax"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gradeMin"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gradingAuthorities"
    },
    {
      "defaultValue": {
        "createdAt": "DESC"
      },
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceMax"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceMin"
    },
    {
      "defaultValue": [
        "AUCTION",
        "SHOP"
      ],
      "kind": "LocalArgument",
      "name": "salesChannel"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shopUrl"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "specialCopies"
    },
    {
      "defaultValue": "COMIC",
      "kind": "LocalArgument",
      "name": "vertical"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "yearMax"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "yearMin"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeView_verticalSearch",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "cursor"
        },
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "count"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "vertical",
          "variableName": "vertical"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "categoryRollups",
              "variableName": "categoryRollups"
            },
            {
              "kind": "Variable",
              "name": "gradeMax",
              "variableName": "gradeMax"
            },
            {
              "kind": "Variable",
              "name": "gradeMin",
              "variableName": "gradeMin"
            },
            {
              "kind": "Variable",
              "name": "gradingAuthorities",
              "variableName": "gradingAuthorities"
            },
            {
              "kind": "Variable",
              "name": "priceMax",
              "variableName": "priceMax"
            },
            {
              "kind": "Variable",
              "name": "priceMin",
              "variableName": "priceMin"
            },
            {
              "kind": "Variable",
              "name": "salesChannel",
              "variableName": "salesChannel"
            },
            {
              "kind": "Variable",
              "name": "searchTerm",
              "variableName": "searchTerm"
            },
            {
              "kind": "Variable",
              "name": "shopUrl",
              "variableName": "shopUrl"
            },
            {
              "kind": "Variable",
              "name": "specialCopies",
              "variableName": "specialCopies"
            },
            {
              "kind": "Variable",
              "name": "yearMax",
              "variableName": "yearMax"
            },
            {
              "kind": "Variable",
              "name": "yearMin",
              "variableName": "yearMin"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "VerticalSearchConnection",
      "kind": "LinkedField",
      "name": "verticalSearch",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VerticalSearchEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TypesenseSearch",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SearchResultCard_typesenseSearch"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "76b29e3bdd71242bc75c47787e18ff5a";

export default node;
