/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

import { SBActivityIndicator, SBModal } from "src/sbxui";

import MakeOfferContent from "./MakeOfferContent";

type Props = Readonly<{
  isOpen: boolean;
  productId: string | null | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const MakeOffer = ({ productId, isOpen, setOpen }: Props): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <SBModal
      headerText={t("make-offer.title", { productId })}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div {...stylex.props(styles.modal)}>
        <Suspense
          fallback={
            <div {...stylex.props(styles.fallback)}>
              <SBActivityIndicator />
            </div>
          }
        >
          <MakeOfferContent productId={productId} setOpen={setOpen} />
        </Suspense>
      </div>
    </SBModal>
  );
};

const styles = stylex.create({
  fallback: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  modal: {
    padding: 16,
  },
});

export default React.memo(MakeOffer);
