/**
 * @generated SignedSource<<41ebaef53f77a66456c6e7d52e7ff6b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountAuctionBidsView_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountAuctionBidsActive_user" | "AccountAuctionBidsEnded_user">;
  readonly " $fragmentType": "AccountAuctionBidsView_user";
};
export type AccountAuctionBidsView_user$key = {
  readonly " $data"?: AccountAuctionBidsView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountAuctionBidsView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountAuctionBidsView_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountAuctionBidsActive_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountAuctionBidsEnded_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "087036e7c6c4b516ceaf755a9d970111";

export default node;
