/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTransition } from "react";
import { Link, useNavigate } from "react-router-dom";

import { SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  label: string;
  onClick?: (event: React.SyntheticEvent) => void;
  style?: StyleXStyles;
  to?: string;
}>;

export const SBBackButton = React.memo(
  ({ label, onClick, style, to = "#", ...rest }: Props): React.ReactNode => {
    const navigate = useNavigate();
    const [isPending, startTransition] = useTransition();

    const handleClickLink = (event: React.SyntheticEvent) => {
      event.preventDefault();
      onClick?.(event);
      startTransition(() => {
        navigate(to);
      });
    };

    return (
      <Link
        {...stylex.props(
          auto,
          styles.link,
          isPending && styles.isPending,
          style,
        )}
        to={to}
        onClick={handleClickLink}
        {...rest}
      >
        <SBIcon icon="arrow_back" style={styles.icon} />
        <span {...stylex.props(auto, styles.label)}>{label}</span>
      </Link>
    );
  },
);

const styles = stylex.create({
  icon: {
    color: colors.color,
  },
  isPending: {
    opacity: 0.4,
  },
  label: {
    marginLeft: 8,
  },
  link: {
    alignItems: "center",
    borderRadius: 4,
    color: colors.color,
    display: "inline-flex",
    flexDirection: "row",
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 24,
    justifyContent: "flex-start",
    lineHeight: 0.875,
    margin: 0,
    outline: {
      ":focus-visible": colors.outline,
    },
    padding: 4,
    textDecorationLine: "none",
    textTransform: "uppercase",
  },
});
