/**
 * @generated SignedSource<<214343a175661b65e928010dc86690fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CategoryRollup = "QUALIFIED" | "REJECT" | "RESTORED" | "SIGNATURE" | "UNIVERSAL" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type OrderByDirection = "ASC" | "DESC" | "%future added value";
export type SpecialCopy = "DOUBLE_COVER" | "INTERNATIONAL" | "MARK_JEWELERS" | "NATIONAL_DIAMOND" | "NEWSSTAND" | "TRIPLE_COVER" | "%future added value";
export type Vertical = "COMIC" | "ORIGINAL_ART" | "SPORTS_CARD" | "TCG" | "VIDEO_GAME" | "%future added value";
export type VerticalSearchOrderByInput = {
  createdAt?: OrderByDirection | null | undefined;
  fmvDividend?: OrderByDirection | null | undefined;
  grade?: OrderByDirection | null | undefined;
  price?: OrderByDirection | null | undefined;
  title?: OrderByDirection | null | undefined;
};
export type ShopPaginationQuery$variables = {
  categoryRollups?: ReadonlyArray<CategoryRollup> | null | undefined;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  gradeMax?: number | null | undefined;
  gradeMin?: number | null | undefined;
  gradingAuthorities?: ReadonlyArray<GradingAuthority> | null | undefined;
  orderBy?: VerticalSearchOrderByInput | null | undefined;
  priceMax?: number | null | undefined;
  priceMin?: number | null | undefined;
  searchTerm?: string | null | undefined;
  shopUrl?: string | null | undefined;
  specialCopies?: ReadonlyArray<SpecialCopy> | null | undefined;
  vertical?: Vertical | null | undefined;
  yearMax?: number | null | undefined;
  yearMin?: number | null | undefined;
};
export type ShopPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ShopView_verticalSearch">;
};
export type ShopPaginationQuery = {
  response: ShopPaginationQuery$data;
  variables: ShopPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "categoryRollups"
  },
  {
    "defaultValue": 24,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gradeMax"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gradeMin"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gradingAuthorities"
  },
  {
    "defaultValue": {
      "createdAt": "DESC"
    },
    "kind": "LocalArgument",
    "name": "orderBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "priceMax"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "priceMin"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shopUrl"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "specialCopies"
  },
  {
    "defaultValue": "COMIC",
    "kind": "LocalArgument",
    "name": "vertical"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "yearMax"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "yearMin"
  }
],
v1 = {
  "kind": "Variable",
  "name": "categoryRollups",
  "variableName": "categoryRollups"
},
v2 = {
  "kind": "Variable",
  "name": "gradeMax",
  "variableName": "gradeMax"
},
v3 = {
  "kind": "Variable",
  "name": "gradeMin",
  "variableName": "gradeMin"
},
v4 = {
  "kind": "Variable",
  "name": "gradingAuthorities",
  "variableName": "gradingAuthorities"
},
v5 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v6 = {
  "kind": "Variable",
  "name": "priceMax",
  "variableName": "priceMax"
},
v7 = {
  "kind": "Variable",
  "name": "priceMin",
  "variableName": "priceMin"
},
v8 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v9 = {
  "kind": "Variable",
  "name": "shopUrl",
  "variableName": "shopUrl"
},
v10 = {
  "kind": "Variable",
  "name": "specialCopies",
  "variableName": "specialCopies"
},
v11 = {
  "kind": "Variable",
  "name": "vertical",
  "variableName": "vertical"
},
v12 = {
  "kind": "Variable",
  "name": "yearMax",
  "variableName": "yearMax"
},
v13 = {
  "kind": "Variable",
  "name": "yearMin",
  "variableName": "yearMin"
},
v14 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/),
  (v11/*: any*/),
  {
    "fields": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/),
      (v10/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ShopPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ShopView_verticalSearch"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ShopPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "VerticalSearchConnection",
        "kind": "LinkedField",
        "name": "verticalSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VerticalSearchEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TypesenseSearch",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasViewerFavorited",
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImagesConnection",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImagesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "quality",
                                    "value": 85
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "webp",
                                    "value": true
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "width",
                                    "value": 500
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": "url(quality:85,webp:true,width:500)"
                              },
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "auctionLotId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvRank",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvMinValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvMaxValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentBidAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salesChannel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "filters": [
          "vertical",
          "orderBy",
          "where"
        ],
        "handle": "connection",
        "key": "ShopView_verticalSearch",
        "kind": "LinkedHandle",
        "name": "verticalSearch"
      }
    ]
  },
  "params": {
    "cacheID": "e59639067ea9d28d18c38bc7cdf451d0",
    "id": null,
    "metadata": {},
    "name": "ShopPaginationQuery",
    "operationKind": "query",
    "text": "query ShopPaginationQuery(\n  $categoryRollups: [CategoryRollup!]\n  $count: Int = 24\n  $cursor: String = \"\"\n  $gradeMax: Float = null\n  $gradeMin: Float = null\n  $gradingAuthorities: [GradingAuthority!]\n  $orderBy: VerticalSearchOrderByInput = {createdAt: DESC}\n  $priceMax: Int = null\n  $priceMin: Int = null\n  $searchTerm: String = null\n  $shopUrl: String = null\n  $specialCopies: [SpecialCopy!]\n  $vertical: Vertical = COMIC\n  $yearMax: Int = null\n  $yearMin: Int = null\n) {\n  ...ShopView_verticalSearch_2kPul2\n}\n\nfragment SearchResultCard_product on Product {\n  hasViewerFavorited\n}\n\nfragment SearchResultCard_typesenseSearch on TypesenseSearch {\n  product {\n    ...SearchResultCard_product\n    id\n  }\n  id\n  createdAt\n  title\n  images {\n    edges {\n      node {\n        url(quality: 85, webp: true, width: 500)\n        id\n      }\n    }\n  }\n  number\n  grade\n  gradingAuthority\n  productId\n  auctionLotId\n  fmvRank\n  fmvMinValue\n  fmvMaxValue\n  currentBidAmount\n  price\n  currency\n  salesChannel\n}\n\nfragment ShopView_verticalSearch_2kPul2 on Query {\n  verticalSearch(vertical: $vertical, after: $cursor, first: $count, orderBy: $orderBy, where: {categoryRollups: $categoryRollups, gradeMax: $gradeMax, gradeMin: $gradeMin, gradingAuthorities: $gradingAuthorities, priceMax: $priceMax, priceMin: $priceMin, searchTerm: $searchTerm, shopUrl: $shopUrl, specialCopies: $specialCopies, yearMax: $yearMax, yearMin: $yearMin}) {\n    edges {\n      node {\n        id\n        ...SearchResultCard_typesenseSearch\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe98ddb6d13b14dc38156bde9de2e415";

export default node;
