/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesWhyUseShortboxedQuery } from "src/types/__generated__/QueriesWhyUseShortboxedQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import DocumentView from "src/app/components/document/DocumentView";
import { WhyUseShortboxedQuery } from "src/app/router/Queries";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesWhyUseShortboxedQuery>;
}>;

const WhyUseShortboxedContent = ({
  queryRef,
}: Props): React.ReactNode | null | undefined => {
  const queryKey = usePreloadedQuery(WhyUseShortboxedQuery, queryRef);

  if (queryKey.node == null) {
    return null;
  }

  return <DocumentView queryKey={queryKey.node} />;
};

export default WhyUseShortboxedContent;
