/**
 * @generated SignedSource<<e30bc1a3a84e4418256c2fe7835f7c6e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesArticleDetailQuery$variables = {
  slug: string;
};
export type QueriesArticleDetailQuery$data = {
  readonly articleBySlug: {
    readonly " $fragmentSpreads": FragmentRefs<"ArticleDetailView_article">;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ArticlesRecent_articles">;
};
export type QueriesArticleDetailQuery = {
  response: QueriesArticleDetailQuery$data;
  variables: QueriesArticleDetailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageAlt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesArticleDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleBySlug",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ArticleDetailView_article"
          }
        ],
        "storageKey": null
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ArticlesRecent_articles"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesArticleDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "articleBySlug",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "authorEnum",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "avatarUrl",
            "storageKey": null
          },
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 4
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": {
              "createdAt": "DESC"
            }
          },
          {
            "kind": "Literal",
            "name": "where",
            "value": {
              "isBlog": true
            }
          }
        ],
        "concreteType": "ArticlesConnection",
        "kind": "LinkedField",
        "name": "articles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticlesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v11/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v8/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "articles(first:4,orderBy:{\"createdAt\":\"DESC\"},where:{\"isBlog\":true})"
      }
    ]
  },
  "params": {
    "cacheID": "0a07d393c7d703fdc2fcabede1c9902a",
    "id": null,
    "metadata": {},
    "name": "QueriesArticleDetailQuery",
    "operationKind": "query",
    "text": "query QueriesArticleDetailQuery(\n  $slug: String!\n) {\n  articleBySlug(slug: $slug) {\n    ...ArticleDetailView_article\n    id\n  }\n  ...ArticlesRecent_articles\n}\n\nfragment ArticleCard_article on Article {\n  id\n  createdAt\n  updatedAt\n  publishedAt\n  summary\n  image\n  title\n  body\n  slug\n  imageAlt\n}\n\nfragment ArticleDetailView_article on Article {\n  id\n  createdAt\n  updatedAt\n  publishedAt\n  summary\n  authorEnum\n  avatarUrl\n  image\n  imageAlt\n  title\n  body\n  slug\n}\n\nfragment ArticlesRecent_articles on Query {\n  articles(first: 4, orderBy: {createdAt: DESC}, where: {isBlog: true}) {\n    edges {\n      node {\n        id\n        slug\n        ...ArticleCard_article\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6206aa52722db455a1b077445981ed08";

export default node;
