/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AnchorPropsWithSelection } from "@headlessui/react/dist/internal/floating";
import type { StyleXStyles } from "@stylexjs/stylex";
import type { SelectInputOption } from "src/sbxui";
import type { Address } from "src/types/Address";

import * as stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { defaultRules, useValidation } from "react-simple-form-validator";

import { useDefaultValidationErrorMessages } from "src/hooks";
import {
  ButtonVariation,
  SBButton,
  SBCheckboxInput,
  SBSelectInput,
  SBTextInput,
} from "src/sbxui";
import { isValidPostalCode } from "src/utils";

import localitiesUs from "./_data/localities-US.json";
import regionsCa from "./_data/regions-CA.json";
import regionsUs from "./_data/regions-US.json";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const NAME_RE = /^[A-Za-z\u00C0-\u017F\u1E00-\u1EFF '\u2019-]+(?:.)?$/u;

const POSTAL_CODE_MAX_LENGTH = 10;

const US_MILITARY_REGIONS = ["AA", "AE", "AP"];

type Props = Readonly<{
  anchor?: AnchorPropsWithSelection | undefined;
  buttonVariation?: ButtonVariation;
  canSelectCountry?: boolean;
  initialAddress?: Address;
  initialDefault?: boolean;
  isNameDisabled?: boolean;
  loading?: boolean;
  onCancel?: (event: React.SyntheticEvent) => void;
  onSubmit?: ({
    address1,
    address2,
    addressId,
    addressName,
    country,
    familyName,
    givenName,
    isDefault,
    locality,
    postalCode,
    region,
  }: {
    address1: Address["address1"];
    address2: Address["address2"];
    addressId: Address["addressId"];
    addressName: Address["addressName"];
    country: Address["country"];
    familyName: Address["familyName"];
    givenName: Address["givenName"];
    isDefault: Address["isDefault"];
    locality: Address["locality"];
    postalCode: Address["postalCode"];
    region: Address["region"];
    type: Address["type"];
  }) => void;
  style?: StyleXStyles;
}>;

const AddressForm = ({
  anchor,
  buttonVariation = ButtonVariation.Default,
  canSelectCountry = true,
  initialAddress,
  initialDefault = true,
  isNameDisabled = false,
  loading = false,
  onSubmit,
  style,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const allowedAddressCountries: SelectInputOption[] = [
    { id: "US", label: t("countries.us") },
    { id: "CA", label: t("countries.ca") },
    { id: "GB", label: t("countries.gb") },
  ];

  const [addressName, setAddressName] = useState(
    initialAddress?.addressName ?? "",
  );
  const [familyName, setFamilyName] = useState(
    initialAddress?.familyName ?? "",
  );
  const [givenName, setGivenName] = useState(initialAddress?.givenName ?? "");
  const [address1, setAddress1] = useState(initialAddress?.address1 ?? "");
  const [address2, setAddress2] = useState(initialAddress?.address2 ?? "");
  const [country, setCountry] = useState<SelectInputOption>(() => {
    for (const allowedCountry of allowedAddressCountries) {
      if (allowedCountry.id === initialAddress?.country) {
        return allowedCountry;
      }
    }
    return allowedAddressCountries[0];
  });

  let regionPlaceholder = t("account.addresses.fields.state");
  let regions = regionsUs.map((code) => ({
    id: code,
    label: t(`regions.us.${kebabCase(code)}`),
  }));
  if (country.id === "CA") {
    regionPlaceholder = t("account.addresses.fields.province");
    regions = regionsCa.map((code) => ({
      id: code,
      label: t(`regions.ca.${kebabCase(code)}`),
    }));
  } else if (country.id === "GB") {
    regionPlaceholder = t("account.addresses.fields.country");
    regions = [];
  }
  const defaultRegion = {
    id: "placeholder",
    label: regionPlaceholder,
  };
  const [region, setRegion] = useState(() => {
    for (const allowedRegion of regions) {
      if (allowedRegion.id === initialAddress?.region) {
        return allowedRegion;
      }
    }
    return defaultRegion;
  });

  const localities = localitiesUs.map((code) => ({ id: code, label: code }));

  const defaultLocality =
    country.id === "US" && US_MILITARY_REGIONS.includes(region.id)
      ? localities[0]
      : {
          id: initialAddress?.locality ?? "placeholder",
          label: t("account.addresses.fields.locality"),
        };
  const [locality, setLocality] = useState(() => {
    for (const allowedLocality of localities) {
      if (allowedLocality.id === initialAddress?.locality) {
        return allowedLocality;
      }
    }
    return defaultLocality;
  });

  let postalCodeError = t("account.addresses.errors.postal-code");
  let postalCodeErrorField = t("account.addresses.errors.labels.postal-code");
  let postalCodePlaceholder = t("account.addresses.fields.postal-code");
  if (country.id === "US") {
    postalCodeError = t("account.addresses.errors.zip-code");
    postalCodeErrorField = t("account.addresses.errors.labels.zip-code");
    postalCodePlaceholder = t("account.addresses.fields.zip-code");
  } else if (country.id === "CA") {
    postalCodeError = t("account.addresses.errors.postcode");
    postalCodeErrorField = t("account.addresses.errors.labels.postcode");
    postalCodePlaceholder = t("account.addresses.fields.postcode");
  }
  const [postalCode, setPostalCode] = useState(
    initialAddress?.postalCode ?? "",
  );

  const [isDefault, setIsDefault] = useState(
    initialAddress?.isDefault ?? initialDefault,
  );

  const [touchedFields, setTouchedFields] = useState({
    address1: false,
    addressName: false,
    familyName: false,
    givenName: false,
    locality: false,
    postalCode: false,
  });

  const defaultValidationErrorMessages = useDefaultValidationErrorMessages();

  const [isRegionValid, setIsRegionValid] = useState(true);
  const { getErrorsInField, isFieldInError, isFormValid } = useValidation({
    fieldsRules: {
      address1: { required: true },
      addressName: { required: true },
      familyName: { customNameRule: true, required: true },
      givenName: { customNameRule: true, required: true },
      locality: { required: true },
      postalCode: { customPostalCodeRule: country.id, required: true },
    },
    labels: {
      address1: t("account.addresses.errors.labels.address1"),
      addressName: t("account.addresses.errors.labels.address-name"),
      familyName: t("signup.errors.labels.family-name"),
      givenName: t("signup.errors.labels.given-name"),
      locality: t("account.addresses.errors.labels.locality"),
      postalCode: postalCodeErrorField,
    },
    messages: {
      // We have our own library for localization, so we just use en here.
      en: {
        ...defaultValidationErrorMessages,
        customNameRule: t("signup.errors.name"),
        customPostalCodeRule: postalCodeError,
      },
    },
    rules: {
      ...defaultRules,
      customNameRule: NAME_RE,
      customPostalCodeRule: isValidPostalCode,
    },
    state: {
      address1,
      addressName,
      familyName,
      givenName,
      locality,
      postalCode,
    },
  });

  const handleSubmit = () => {
    setIsRegionValid(region.id !== "placeholder");
    setTouchedFields({
      address1: !isFormValid,
      addressName: !isFormValid,
      familyName: !isFormValid,
      givenName: !isFormValid,
      locality: !isFormValid,
      postalCode: !isFormValid,
    });
    if (!isFormValid || !isRegionValid) {
      return;
    }
    onSubmit?.({
      address1,
      address2,
      addressId: initialAddress?.addressId,
      addressName,
      country: country.id as Address["country"],
      familyName,
      givenName,
      isDefault,
      locality: locality.id,
      postalCode,
      region: region.id,
      type: initialAddress?.type ?? "SHIPPING",
    });
  };

  const handleBlurField = (
    _event: React.FocusEvent<HTMLInputElement>,
    field: string,
  ) => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  const handleOnChangeIsDefault = () => {
    setIsDefault(!isDefault);
  };

  let regionErrorMessage = t("account.addresses.errors.region.select");
  if (country.id === "CA") {
    regionErrorMessage = t("account.addresses.errors.province.select");
  } else if (country.id === "GB") {
    regionErrorMessage = t("account.addresses.errors.country.select");
  } else if (country.id === "US") {
    regionErrorMessage = t("account.addresses.errors.state.select");
  }

  let regionInput = (
    <SBTextInput
      errorMessage={!isRegionValid && regionErrorMessage}
      id="region"
      label={regionPlaceholder}
      placeholder={regionPlaceholder}
      style={styles.formField}
      value={region.id === "placeholder" ? "" : region.id}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setRegion({
          id: event.currentTarget.value,
          label: event.currentTarget.value,
        });
      }}
    />
  );
  if (country.id === "CA" || country.id === "US") {
    regionInput = (
      <SBSelectInput
        anchor={anchor}
        errorMessage={!isRegionValid && regionErrorMessage}
        options={regions}
        placeholder={regionPlaceholder}
        style={styles.formField}
        value={region}
        onChange={(selected: string) => {
          const selectedOption = regions.find(
            ({ label }) => label === selected,
          );

          if (selectedOption) {
            if (
              country.id === "US" &&
              US_MILITARY_REGIONS.includes(selectedOption.id)
            ) {
              setLocality(localities[0]);
            }
            setRegion(selectedOption);
          }
        }}
      />
    );
  }

  let localityInput = (
    <SBTextInput
      errorMessage={
        touchedFields.locality
          ? isFieldInError("locality") && getErrorsInField("locality")[0]
          : null
      }
      id="locality"
      label={t("account.addresses.fields.locality")}
      placeholder={t("account.addresses.fields.locality")}
      style={styles.formField}
      value={locality.id === "placeholder" ? "" : locality.id}
      onBlur={(event) => {
        handleBlurField(event, "locality");
      }}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        setLocality({
          id: event.currentTarget.value,
          label: event.currentTarget.value,
        })
      }
    />
  );
  if (country.id === "US" && US_MILITARY_REGIONS.includes(region.id)) {
    localityInput = (
      <SBSelectInput
        anchor={anchor}
        errorMessage={!isRegionValid && regionErrorMessage}
        options={localities}
        style={styles.formField}
        value={locality}
        onChange={(selected: string) => {
          const selectedOption = localities.find(
            ({ label }) => label === selected,
          );

          if (selectedOption) {
            setLocality(selectedOption);
          }
        }}
      />
    );
  }

  return (
    <form method="post" {...stylex.props(style)} onSubmit={handleSubmit}>
      {}
      <SBTextInput
        disabled={isNameDisabled}
        errorMessage={
          touchedFields.addressName
            ? isFieldInError("addressName") &&
              getErrorsInField("addressName")[0]
            : null
        }
        id="addressName"
        label={t("account.addresses.fields.address-name")}
        placeholder={t("account.addresses.fields.address-name")}
        style={styles.formField}
        value={addressName}
        onBlur={(event) => {
          handleBlurField(event, "addressName");
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setAddressName(event.currentTarget.value)
        }
      />
      <SBTextInput
        errorMessage={
          touchedFields.givenName
            ? isFieldInError("givenName") && getErrorsInField("givenName")[0]
            : null
        }
        id="givenName"
        label={t("account.addresses.fields.given-name")}
        placeholder={t("account.addresses.fields.given-name")}
        style={styles.formField}
        value={givenName}
        onBlur={(event) => {
          handleBlurField(event, "givenName");
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setGivenName(event.currentTarget.value)
        }
      />
      <SBTextInput
        errorMessage={
          touchedFields.familyName
            ? isFieldInError("familyName") && getErrorsInField("familyName")[0]
            : null
        }
        id="familyName"
        label={t("account.addresses.fields.family-name")}
        placeholder={t("account.addresses.fields.family-name")}
        style={styles.formField}
        value={familyName}
        onBlur={(event) => {
          handleBlurField(event, "familyName");
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFamilyName(event.currentTarget.value)
        }
      />
      <SBSelectInput
        disabled={!canSelectCountry}
        options={allowedAddressCountries}
        style={styles.formField}
        value={country}
        onChange={(selected: string) => {
          setAddress1("");
          setAddress2("");
          setRegion(defaultRegion);
          setLocality(defaultLocality);
          setPostalCode("");

          const selectedOption = allowedAddressCountries.find(
            ({ label }) => label === selected,
          );

          if (selectedOption) {
            setCountry(selectedOption);
          }
        }}
      />
      <SBTextInput
        errorMessage={
          touchedFields.address1
            ? isFieldInError("address1") && getErrorsInField("address1")[0]
            : null
        }
        id="address1"
        label={t("account.addresses.fields.address1")}
        placeholder={t("account.addresses.fields.address1")}
        style={styles.formField}
        value={address1}
        onBlur={(event) => {
          handleBlurField(event, "address1");
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setAddress1(event.currentTarget.value)
        }
      />
      <SBTextInput
        id="address2"
        label={t("account.addresses.fields.address2")}
        placeholder={t("account.addresses.fields.address2")}
        style={styles.formField}
        value={address2}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setAddress2(event.currentTarget.value)
        }
      />
      {localityInput}
      <div {...stylex.props(styles.lastRow)}>
        {regionInput}
        <SBTextInput
          errorMessage={
            touchedFields.postalCode
              ? isFieldInError("postalCode") &&
                getErrorsInField("postalCode")[0]
              : null
          }
          id="postalCode"
          label={postalCodePlaceholder}
          maxLength={POSTAL_CODE_MAX_LENGTH}
          placeholder={postalCodePlaceholder}
          style={styles.formField}
          value={postalCode}
          onBlur={(event) => {
            handleBlurField(event, "postalCode");
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setPostalCode(event.currentTarget.value)
          }
        />
      </div>
      {initialDefault ? null : (
        <SBCheckboxInput
          checked={isDefault}
          id="default"
          style={styles.checkbox}
          onChange={handleOnChangeIsDefault}
        >
          {t("account.addresses.make-default")}
        </SBCheckboxInput>
      )}
      <SBButton
        block={true}
        loading={loading}
        title={t("account.addresses.buttons.save")}
        variation={buttonVariation}
        onClick={handleSubmit}
      />
    </form>
  );
};

const styles = stylex.create({
  checkbox: {
    marginBottom: 16,
  },
  formField: {
    marginBottom: 16,
  },
  header: {
    alignItems: "center",
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    justifyContent: "space-between",
    marginBottom: 12,
  },
  heading: {
    fontSize: 24,
    gridColumn: "span 3 / span 3",
  },
  lastRow: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyContent: "space-between",
  },
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default AddressForm;
