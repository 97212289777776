/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// https://www.twilio.com/docs/glossary/what-e164
export const PHONE_RE = /[^0-9]/gu;

export interface ValkyrieError {
  extensions: {
    code: string;
    message: string;
    statusCode: number;
  };
}
