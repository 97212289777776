/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesCareersQuery } from "src/types/__generated__/QueriesCareersQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import CareersContent from "./CareersContent";

const Careers = (): React.ReactNode => {
  const queryRef = useLoaderData() as PreloadedQuery<QueriesCareersQuery>;

  if (queryRef == null) {
    return null;
  }

  return (
    <div {...stylex.props(styles.root)} aria-live="polite">
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <CareersContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

const styles = stylex.create({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "100%",
  },
});

export default Careers;
