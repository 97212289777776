/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { CensusTable_comicDetail$key } from "src/types/__generated__/CensusTable_comicDetail.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SBCollapsibleTable } from "src/sbxui";
import { formatCensus } from "src/utils/_utils/formatCensus";

type CensusType = Record<string, number>;

type Props = Readonly<{
  queryKey: CensusTable_comicDetail$key;
}>;

const CensusTable = ({ queryKey }: Props): React.ReactNode | null => {
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment CensusTable_comicDetail on ComicDetail {
        census
      }
    `,
    queryKey,
  );

  const census = (data.census ?? []) as CensusType[];

  if (census.length === 0) {
    return null;
  }

  const content = census
    .filter((el) => el != null)
    .map((el) => {
      const key = Object.keys(el)[0];
      const value = el[key];
      return {
        description: key === "total" ? null : key.replace("_", "."),
        id: key,
        label: t(`census.${kebabCase(key)}`),
        value: formatCensus(value, i18n.language),
      };
    });

  return (
    <SBCollapsibleTable
      content={content}
      footer={t("census.cgc-copyright")}
      style={styles.table}
      title={t("census.title")}
    />
  );
};

const styles = stylex.create({
  table: {
    marginBottom: 24,
    marginTop: 24,
  },
});

export default CensusTable;
