/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

type Props = Readonly<
  React.PropsWithChildren<{
    id: string;
  }>
>;

export const SBAnchor = ({ children, id }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash.substring(1) === id && ref.current) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [id, hash, ref]);

  return (
    <>
      <div {...stylex.props(styles.anchor)} ref={ref} id={id}></div>
      {children}
    </>
  );
};

const styles = stylex.create({
  anchor: {
    position: "relative",
    top: -156,
  },
});
