/**
 * @generated SignedSource<<70bbce0f8fe83c778607903465fd7a53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type OfferState = "COUNTER_FROM_BUYER_ACCEPTED" | "COUNTER_FROM_BUYER_DECLINED" | "COUNTER_FROM_BUYER_EXPIRED" | "COUNTER_FROM_BUYER_IN_REVIEW" | "COUNTER_FROM_SELLER_ACCEPTED" | "COUNTER_FROM_SELLER_DECLINED" | "COUNTER_FROM_SELLER_EXPIRED" | "COUNTER_FROM_SELLER_IN_REVIEW" | "FAVORITE_ACCEPTED" | "FAVORITE_DECLINED" | "FAVORITE_EXPIRED" | "FAVORITE_IN_REVIEW" | "STANDARD_ACCEPTED" | "STANDARD_DECLINED" | "STANDARD_EXPIRED" | "STANDARD_IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsBuyerAccepted_product$data = {
  readonly acceptedOffer: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currency: Currency;
        readonly expiresAt: string;
        readonly id: string;
        readonly price: number;
        readonly state: OfferState;
      };
    }>;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "ProductDetailActionsBuyerAccepted_product";
};
export type ProductDetailActionsBuyerAccepted_product$key = {
  readonly " $data"?: ProductDetailActionsBuyerAccepted_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsBuyerAccepted_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsBuyerAccepted_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "acceptedOffer",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "expiresAt": "DESC"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "status": "ACCEPTED"
          }
        }
      ],
      "concreteType": "ProductOffersConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductOffersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Offer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expiresAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "offers(first:1,orderBy:{\"expiresAt\":\"DESC\"},where:{\"status\":\"ACCEPTED\"})"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "8ad4bca9685f54b1eb3705bbda9df111";

export default node;
