/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import * as React from "react";
import { useState } from "react";

import { SBTextInput, TextAutoCompleteType, TextInputType } from "src/sbxui";

type Props = Readonly<{
  current?: boolean;
  disabled?: boolean;
  errorMessage?: string | false | null;
  id: string;
  label: string;
  maxLength?: number;
  minLength?: number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  style?: StyleXStyles;
  value: string | number;
}>;

export const SBPasswordInput = React.memo(
  ({
    current = true,
    disabled = false,
    errorMessage,
    id,
    label,
    maxLength,
    minLength,
    onBlur,
    onChange,
    placeholder,
    readOnly = false,
    style,
    value,
  }: Props): React.ReactNode | null => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickPasswordIcon = (_event: React.SyntheticEvent) => {
      setShowPassword(!showPassword);
    };

    const passwordInputType = showPassword
      ? TextInputType.Text
      : TextInputType.Password;
    const passwordIcon = showPassword ? "visibility_off" : "visibility";
    const autoComplete = current
      ? TextAutoCompleteType.CurrentPassword
      : TextAutoCompleteType.NewPassword;

    return (
      <SBTextInput
        autoComplete={autoComplete}
        disabled={disabled}
        errorMessage={errorMessage}
        icon={passwordIcon}
        id={id}
        label={label}
        maxLength={maxLength}
        minLength={minLength}
        placeholder={placeholder}
        readOnly={readOnly}
        style={style}
        type={passwordInputType}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onClickIcon={handleClickPasswordIcon}
      />
    );
  },
);
