/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { LoginResponseType } from "src/app/context/user";
import type { ErrorResponse } from "src/app/types/RelayTypes";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { GoogleSSOButton } from "src/app/components/sso/GoogleSSOButton";
import { API_CLIENT_ID, SHORTBOXED_CDN_BASE_URL } from "src/app/constants";
import { UserContext } from "src/app/context/user";
import { RESET_PASSWORD_PATH, SIGNUP_PATH } from "src/app/router/Router";
import { useLogin } from "src/hooks/_hooks/useLogin";
import {
  ButtonType,
  ButtonVariation,
  SBButton,
  SBIcon,
  SBLink,
  SBParagraph,
  SBPasswordInput,
  SBTextInput,
  TextAutoCompleteType,
  TextInputType,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { AppleSSOButton } from "../sso/AppleSSOButton";
import { FacebookSSOButton } from "../sso/FacebookSSOButton";
import TermsFixUp from "../terms-accept/TermsFixUpForm";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  onLoginCancel: () => void;
  onLoginStart: () => void;
  onLoginSuccess: () => void;
}>;

const LoginForm = ({
  onLoginCancel,
  onLoginSuccess,
  onLoginStart,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { logIn } = useContext(UserContext);
  const [commitLogin, isInFlightLogin] = useLogin();

  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const [needsTermsFixUp, setNeedsTermsFixUp] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [tempLoginResponse, setTempLoginResponse] =
    useState<LoginResponseType | null>(null);

  const SHORTBOXED_LOGO = `${SHORTBOXED_CDN_BASE_URL}/sbx-logo-white.webp`;

  const onLogIn = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlightLogin) {
      return;
    }
    setIsLoggingIn(true);
    onLoginStart();
    commitLogin({
      onCompleted(data) {
        setIsLoggingIn(false);
        onLoginSuccess();
        logIn(data.login);
      },
      onError(error: unknown) {
        setIsLoggingIn(false);
        const errorResponse = error as ErrorResponse;
        setErrorMessage(
          errorResponse?.res?.errors?.[0]?.extensions?.exception?.status === 429
            ? t("login.errors.http429")
            : t("login.errors.default"),
        );
      },
      variables: {
        input: {
          clientId: API_CLIENT_ID,
          email: username,
          password,
        },
      },
    });
  };

  const handleClickResetPassword = (_event: React.SyntheticEvent) => {
    onLoginCancel();
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.currentTarget.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const handleSSOLoginSuccess = (
    loginResponse: LoginResponseType,
    needFixUp: boolean,
  ) => {
    setTempLoginResponse(loginResponse);
    setNeedsTermsFixUp(needFixUp);
    logIn(loginResponse);
    onLoginSuccess();
  };

  const handleTermsUpdated = () => {
    if (tempLoginResponse != null) {
      logIn(tempLoginResponse);
    }
    onLoginSuccess();
  };

  const loginForm = (
    <form method="post">
      <div aria-live="polite">
        {errorMessage ? (
          <div {...stylex.props(auto, styles.error)}>
            <SBIcon icon="error" style={styles.errorIcon} />
            <SBParagraph style={styles.errorMessage}>
              {errorMessage}
            </SBParagraph>
          </div>
        ) : null}
      </div>
      <fieldset {...stylex.props(styles.fieldset)}>
        <SBTextInput
          autoComplete={TextAutoCompleteType.Email}
          disabled={isLoggingIn}
          id="email"
          label={t("login.field.email-address")}
          placeholder={t("login.field.email-address")}
          style={styles.input}
          type={TextInputType.Email}
          value={username}
          onChange={handleChangeEmail}
        />
        <SBPasswordInput
          current={true}
          disabled={isLoggingIn}
          id="password"
          label={t("login.field.password")}
          placeholder={t("login.field.password")}
          style={styles.input}
          value={password}
          onChange={handleChangePassword}
        />
      </fieldset>
      <div {...stylex.props(styles.links)}>
        <SBLink style={styles.link} to={SIGNUP_PATH}>
          {t("login.signup")}
        </SBLink>
        <SBLink
          style={styles.link}
          to={RESET_PASSWORD_PATH}
          onClick={handleClickResetPassword}
        >
          {t("login.forgot-password")}
        </SBLink>
      </div>
      <SBButton
        block={true}
        loading={isLoggingIn}
        style={styles.loginButton}
        title={t("login.submit")}
        type={ButtonType.Submit}
        variation={ButtonVariation.Emphasis}
        onClick={onLogIn}
      />
      <div {...stylex.props(styles.separator)}>
        <hr {...stylex.props(auto, styles.hr)} aria-hidden={true} />
        <SBParagraph style={styles.text}>{t("login.or")}</SBParagraph>
        <hr {...stylex.props(auto, styles.hr)} aria-hidden={true} />
      </div>
      <GoogleSSOButton
        style={styles.loginButton}
        onLoginSuccess={handleSSOLoginSuccess}
      />
      <AppleSSOButton
        style={styles.loginButton}
        onLoginSuccess={handleSSOLoginSuccess}
      />
      <FacebookSSOButton onLoginSuccess={handleSSOLoginSuccess} />
    </form>
  );

  return (
    <div {...stylex.props(styles.form)}>
      <img
        {...stylex.props(styles.logo)}
        alt={t("shortboxed")}
        height={69}
        src={SHORTBOXED_LOGO}
        width={304}
      />
      {needsTermsFixUp ? (
        <TermsFixUp onTermsUpdated={handleTermsUpdated} />
      ) : (
        loginForm
      )}
    </div>
  );
};

const styles = stylex.create({
  buttonStyle: {
    alignItems: "center",
    backgroundColor: colors.topNavigationBackgroundColor,
    borderColor: colors.topNavigationMenuBorderColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    height: 48,
    justifyContent: "center",
    padding: 4,
    width: 96,
  },
  error: {
    alignItems: "flex-start",
    backgroundColor: colors.errorBackgroundColor,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 16,
    paddingBlock: 12,
    paddingInline: 16,
  },
  errorIcon: {
    color: colors.errorColor,
    marginRight: 8,
  },
  errorMessage: {
    color: colors.errorColor,
    fontSize: 16,
    lineHeight: 1.5,
    margin: 0,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  form: {
    boxSizing: {
      [MOBILE]: "border-box",
      [TABLET]: "border-box",
      default: "content-box",
    },
    padding: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 96,
    },
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: 448,
    },
  },
  hr: {
    borderBottomWidth: 1,
    borderColor: colors.takeoverBorderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 0,
    flexGrow: 1,
    margin: 0,
    padding: 0,
  },
  icon: {
    color: colors.topNavigationMenuColor,
  },
  input: {
    marginBottom: 16,
  },
  link: {
    color: {
      ":hover": colors.takeoverColor,
      default: colors.takeoverMutedColor,
    },
    textDecorationLine: "none",
  },
  links: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
  },
  loginButton: {
    marginBottom: 16,
  },
  logo: {
    display: "block",
    marginBottom: 24,
    marginInline: "auto",
  },
  separator: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBlock: 32,
  },
  text: {
    color: colors.takeoverColor,
    marginBlock: 0,
    marginInline: 16,
  },
});

export default React.memo(LoginForm);
