/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountVacationModeQuery } from "src/types/__generated__/QueriesSellerAccountVacationModeQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import SellerAccountPage from "../SellerAccountPage";
import SellerAccountVacationModeContent from "./SellerAccountVacationModeContent";

const SellerAccountVacationMode = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesSellerAccountVacationModeQuery>;

  return (
    <SellerAccountPage
      docTitle={t("seller-account.vacation-mode.title")}
      innerHeading={t("seller-account.nav.vacation-mode")}
    >
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <SellerAccountVacationModeContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </SellerAccountPage>
  );
};

export default SellerAccountVacationMode;
