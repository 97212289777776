/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { colors } from "src/themes/colors.stylex";

import { SBLink } from "./SBLink";

type Props = Readonly<{
  onTabSelected: (tabId: string) => void;
  tabs: { id: string; name: string; selected: boolean }[];
}>;

export const SBTabs = (props: Props): React.ReactNode => {
  const { tabs, onTabSelected } = props;

  const handleTabSelected = (tabId: string) => {
    onTabSelected(tabId);
  };

  return (
    <nav {...stylex.props(styles.nav)}>
      {tabs.map(({ selected, id, name }) => (
        <SBLink
          key={id}
          aria-current={selected ? "page" : undefined}
          {...stylex.props(styles.tab, selected && styles.selected)}
          onClick={() => handleTabSelected(id)}
        >
          {name}
        </SBLink>
      ))}
    </nav>
  );
};

const styles = stylex.create({
  nav: { display: "flex" },
  selected: {
    backgroundColor: colors.tabSelectedBackgroundColor,
    color: colors.tabSelectedColor,
  },
  tab: { color: colors.navLinkColor, padding: 10, textDecorationLine: "none" },
});
