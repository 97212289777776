/**
 * @generated SignedSource<<a4b1f733a79f6af0b781d127e1d3bc6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesContactUsQuery$variables = Record<PropertyKey, never>;
export type QueriesContactUsQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"DocumentView_document">;
  } | null | undefined;
};
export type QueriesContactUsQuery = {
  response: QueriesContactUsQuery$data;
  variables: QueriesContactUsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "id",
    "value": "RG9jdW1lbnQ6MjFhODNlMTUtYjczZi00ZDAyLTlkZWYtMzg0ZDg1Y2QwNDE0"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesContactUsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DocumentView_document"
              }
            ],
            "type": "Document",
            "abstractKey": null
          }
        ],
        "storageKey": "node(id:\"RG9jdW1lbnQ6MjFhODNlMTUtYjczZi00ZDAyLTlkZWYtMzg0ZDg1Y2QwNDE0\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesContactUsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              }
            ],
            "type": "Document",
            "abstractKey": null
          }
        ],
        "storageKey": "node(id:\"RG9jdW1lbnQ6MjFhODNlMTUtYjczZi00ZDAyLTlkZWYtMzg0ZDg1Y2QwNDE0\")"
      }
    ]
  },
  "params": {
    "cacheID": "dfdfccb09d1592f4fa3ff9cdba5b1789",
    "id": null,
    "metadata": {},
    "name": "QueriesContactUsQuery",
    "operationKind": "query",
    "text": "query QueriesContactUsQuery {\n  node(id: \"RG9jdW1lbnQ6MjFhODNlMTUtYjczZi00ZDAyLTlkZWYtMzg0ZDg1Y2QwNDE0\") {\n    __typename\n    ... on Document {\n      ...DocumentView_document\n    }\n    id\n  }\n}\n\nfragment DocumentView_document on Document {\n  id\n  title\n  body\n}\n"
  }
};
})();

(node as any).hash = "0ecb8379d7c3f48da642703275a5968e";

export default node;
