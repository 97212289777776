/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";

type Props = React.PropsWithChildren;

interface State {
  error: Error | null;
  errorInfo: {
    componentStack: string;
  } | null;
}

export default class ProfileLinkErrorBoundary extends React.Component<
  Props,
  State
> {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string;
    },
  ) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error == null) {
      return children;
    }

    return null;
  }
}
