/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import { t } from "i18next";
import * as React from "react";

import { FAQS_PATH, PRIVACY_PATH, TERMS_PATH } from "src/app/router/Router";
import { SBCheckboxInput, SBLink } from "src/sbxui";

type Props = Readonly<{
  isFaqsChecked: boolean;
  isMarketingChecked: boolean;
  isTermsChecked: boolean;
  onFaqsChecked: () => void;
  onMarketingChecked: () => void;
  onTermsChecked: () => void;
}>;

const TermsAcceptFields = ({
  isTermsChecked,
  isFaqsChecked,
  isMarketingChecked,
  onMarketingChecked,
  onTermsChecked,
  onFaqsChecked,
}: Props): React.ReactNode => {
  return (
    <fieldset {...stylex.props(styles.fieldset)}>
      <div {...stylex.props(styles.checkboxFields)}>
        <SBCheckboxInput
          checked={isTermsChecked}
          id="privacyAndTerms"
          style={styles.checkbox}
          onChange={onTermsChecked}
        >
          {t("signup.privacyAndTerms.start")}
          <SBLink to={PRIVACY_PATH}>
            {t("signup.privacyAndTerms.privacyLink")}
          </SBLink>
          {t("signup.privacyAndTerms.middle")}
          <SBLink to={TERMS_PATH}>
            {t("signup.privacyAndTerms.termsLink")}
          </SBLink>
          {t("signup.privacyAndTerms.end")}
        </SBCheckboxInput>
        <SBCheckboxInput
          checked={isFaqsChecked}
          id="faq"
          style={styles.checkbox}
          onChange={onFaqsChecked}
        >
          {t("signup.faq.start")}
          <SBLink to={FAQS_PATH}>{t("signup.faq.faqLink")}</SBLink>
          {t("signup.faq.end")}
        </SBCheckboxInput>
        <SBCheckboxInput
          checked={isMarketingChecked}
          id="marketingNotifs"
          style={styles.checkbox}
          onChange={onMarketingChecked}
        >
          {t("signup.marketing")}
        </SBCheckboxInput>
      </div>
    </fieldset>
  );
};

const styles = stylex.create({
  checkbox: {
    marginBottom: 8,
  },
  checkboxFields: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 8,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
});

export default React.memo(TermsAcceptFields);
