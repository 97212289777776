/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { SBActivityIndicator } from "src/sbxui";

export const SBFallback = React.memo((): React.ReactNode => {
  return (
    <div {...stylex.props(styles.root)}>
      <SBActivityIndicator />
    </div>
  );
});

const styles = stylex.create({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "100%",
  },
});
