/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesWhyUseShortboxedQuery } from "src/types/__generated__/QueriesWhyUseShortboxedQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import WhyUseShortboxedContent from "./WhyUseShortboxedContent";

const WhyUseShortboxed = (): React.ReactNode => {
  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesWhyUseShortboxedQuery>;

  if (queryRef == null) {
    return null;
  }

  return (
    <div aria-live="polite">
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <WhyUseShortboxedContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

export default WhyUseShortboxed;
