/**
 * @generated SignedSource<<b2cec8a745ff7a03d024314431f1d646>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesSellerAccountPayoutPreferencesQuery$variables = Record<PropertyKey, never>;
export type QueriesSellerAccountPayoutPreferencesQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"SellerAccountPayoutPreferencesView_user">;
    } | null | undefined;
  };
};
export type QueriesSellerAccountPayoutPreferencesQuery = {
  response: QueriesSellerAccountPayoutPreferencesQuery$data;
  variables: QueriesSellerAccountPayoutPreferencesQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSellerAccountPayoutPreferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SellerAccountPayoutPreferencesView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesSellerAccountPayoutPreferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentPreferencesUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3d37409e44fa5374170ef1cdfd0b69a8",
    "id": null,
    "metadata": {},
    "name": "QueriesSellerAccountPayoutPreferencesQuery",
    "operationKind": "query",
    "text": "query QueriesSellerAccountPayoutPreferencesQuery {\n  viewer {\n    user {\n      ...SellerAccountPayoutPreferencesView_user\n      id\n    }\n  }\n}\n\nfragment SellerAccountPayoutPreferencesView_user on User {\n  id\n  paymentPreferencesUrl\n}\n"
  }
};

(node as any).hash = "a7d42e14ed5ea9325526e936bd15bf8b";

export default node;
