/**
 * @generated SignedSource<<7d92f8f3f805d48b3c3da6424cd62530>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeletionViewDeleteUserMutation$variables = Record<PropertyKey, never>;
export type DeletionViewDeleteUserMutation$data = {
  readonly deleteUser: {
    readonly deletedAt: string | null | undefined;
    readonly userId: string | null | undefined;
  };
};
export type DeletionViewDeleteUserMutation = {
  response: DeletionViewDeleteUserMutation$data;
  variables: DeletionViewDeleteUserMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "DeleteUserOutput",
    "kind": "LinkedField",
    "name": "deleteUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeletionViewDeleteUserMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DeletionViewDeleteUserMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "47710cec81ca24dab8f66568bc3b4363",
    "id": null,
    "metadata": {},
    "name": "DeletionViewDeleteUserMutation",
    "operationKind": "mutation",
    "text": "mutation DeletionViewDeleteUserMutation {\n  deleteUser {\n    deletedAt\n    userId\n  }\n}\n"
  }
};
})();

(node as any).hash = "bc9ab30cb2205a36867d0e36c613335d";

export default node;
