/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useTranslation } from "react-i18next";

import { SBPageHeader, SBParagraph } from "src/sbxui";

const DeletionNotLoggedInView = (): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <div>
      <SBPageHeader title={t("account-deletion.request")} />
      <SBParagraph>{t("account-deletion.standard-copy")}</SBParagraph>
    </div>
  );
};

export default DeletionNotLoggedInView;
