/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useMemo, useState } from "react";

export enum Sort {
  BidsAsc = "bids-asc",
  BidsDesc = "bids-desc",
  ClosesAtAsc = "closesAt-asc",
  ClosesAtDesc = "closesAt-desc",
  CurrentBidAmountAsc = "currentBidAmount-asc",
  CurrentBidAmountDesc = "currentBidAmount-desc",
}

export const DEFAULT_SORT = Sort.ClosesAtAsc;

interface State {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  setSort: (sort: Sort) => void;
  sort: Sort;
}

const INITIAL_STATE: State = {
  searchTerm: "",
  setSearchTerm: () => {
    return;
  },
  setSort: () => {
    return;
  },
  sort: DEFAULT_SORT,
};

export const AuctionLotSearchContext: React.Context<State> =
  React.createContext(INITIAL_STATE);

type Props = React.PropsWithChildren;

export const AuctionLotSearchProvider = ({
  children,
}: Props): React.ReactNode => {
  // Sort type and direction
  const [sort, setSort] = useState<Sort>(INITIAL_STATE.sort);

  // Search term
  const [searchTerm, setSearchTerm] = useState<string>(
    INITIAL_STATE.searchTerm,
  );

  const currentAuctionLots = useMemo(
    () => ({
      searchTerm,
      setSearchTerm,
      setSort,
      sort,
    }),
    [sort, searchTerm],
  );

  return (
    <AuctionLotSearchContext.Provider value={currentAuctionLots}>
      {children}
    </AuctionLotSearchContext.Provider>
  );
};
