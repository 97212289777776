/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountOffersView_user$key } from "src/types/__generated__/AccountOffersView_user.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SBTabs } from "src/sbxui";

import AccountOffersActive from "./AccountOffersActive";
import AccountOffersEnded from "./AccountOffersEnded";

type Props = Readonly<{
  queryKey: AccountOffersView_user$key;
}>;

const AccountOffersView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState<string>("ACTIVE");

  const data = useFragment(
    graphql`
      fragment AccountOffersView_user on User {
        ...AccountOffersActive_user
        ...AccountOffersEnded_user
      }
    `,
    queryKey,
  );

  const TABS = [
    {
      id: "ACTIVE",
      name: t("account.offers.active"),
      selected: selectedTab === "ACTIVE",
    },
    {
      id: "ENDED",
      name: t("account.offers.ended"),
      selected: selectedTab === "ENDED",
    },
  ];

  const getTabBody = (): React.ReactNode => {
    switch (selectedTab) {
      case "ENDED":
        return <AccountOffersEnded queryKey={data} />;
      case "ACTIVE":
      default:
        return <AccountOffersActive queryKey={data} />;
    }
  };

  return (
    <>
      <SBTabs tabs={TABS} onTabSelected={(tabId) => setSelectedTab(tabId)} />
      {getTabBody()}
    </>
  );
};

export default AccountOffersView;
