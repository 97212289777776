/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { useCountdownTimer } from "src/hooks";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const SIX_HOURS_MS = 6 * 60 * 60 * 1000;

type Props = Readonly<{
  biddingStartsAt: string | null | undefined;
  lastLotClosesAt: string | null | undefined;
}>;

export const SBAuctionStatusTag = ({
  biddingStartsAt,
  lastLotClosesAt,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const closingTimestamp =
    lastLotClosesAt == null ? Date.now() : Number.parseInt(lastLotClosesAt, 10);

  const { hasExpired } = useCountdownTimer(closingTimestamp, {
    expired: t("auctions.tile.status.closed"),
    short: true,
  });

  const isPreview =
    biddingStartsAt == null
      ? true
      : Date.now() - Number.parseInt(biddingStartsAt, 10) <= 0;

  const now = Date.now();
  const difference = closingTimestamp - now;

  let status = isPreview
    ? t("auctions.tile.status.preview")
    : t("auctions.tile.status.open");

  let dotColor = colors.auctionTileStatusDotOpenColor;
  let showRing = true;
  if (!isPreview && difference <= SIX_HOURS_MS) {
    status = t("auctions.tile.status.closing");
  }
  if (isPreview) {
    dotColor = colors.auctionTileStatusDotPreviewColor;
    showRing = false;
  }
  if (!isPreview && hasExpired) {
    status = t("auctions.tile.status.closed");
    dotColor = colors.auctionTileStatusDotClosedColor;
    showRing = false;
  }

  return (
    <div {...stylex.props(auto, styles.background)}>
      <div {...stylex.props(styles.dotContainer)}>
        <div {...stylex.props(auto, styles.dot(dotColor))}></div>
        {showRing ? <div {...stylex.props(auto, styles.ring)}></div> : null}
      </div>
      <p {...stylex.props(auto, styles.status)}>{status}</p>
    </div>
  );
};

const pulse = stylex.keyframes({
  "0%, 25%": {
    opacity: 1,
    transform: "scale(0.9)",
  },
  "100%": {
    opacity: 0,
    transform: "scale(3)",
  },
});

const styles = stylex.create({
  background: {
    alignItems: "center",
    alignSelf: "flex-start",
    backgroundColor: colors.auctionTileStatusBackgroundColor,
    borderRadius: 12,
    display: "flex",
    flexDirection: "row",
    height: 24,
    justifyContent: "center",
    paddingInline: 8,
  },
  dot: (backgroundColor: string) => ({
    backgroundColor,
    borderRadius: 10,
    height: 10,
    width: 10,
  }),
  dotContainer: {
    alignItems: "center",
    display: "flex",
    height: 10,
    justifyContent: "center",
    marginRight: 4,
    position: "relative",
    width: 10,
  },
  ring: {
    alignItems: "center",
    animationDuration: "2s",
    animationIterationCount: "infinite",
    animationName: pulse,
    backgroundColor: colors.auctionTileStatusDotOpenColor,
    borderRadius: 100,
    borderWidth: 4,
    display: "flex",
    height: 10,
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: 10,
  },
  status: {
    MozOsxFontSmoothing: "grayscale",
    WebkitFontSmoothing: "antialiased",
    color: colors.auctionTileStatusColor,
    fontFamily: "'drukmedium', sans-serif",
    fontFeatureSettings: "liga",
    fontWeight: 400,
    letterSpacing: "0.6px",
    lineHeight: 0.875,
    margin: 0,
    textRendering: "optimizeLegibility",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
});
