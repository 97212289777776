/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  AccountFavoritesView_user$key,
  GradingAuthority,
} from "src/types/__generated__/AccountFavoritesView_user.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading, SBProductTile } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountFavoritesView_user$key;
}>;

const AccountFavoritesView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const user = useFragment(
    graphql`
      fragment AccountFavoritesView_user on User {
        favorites(orderBy: { createdAt: DESC }) {
          edges {
            node {
              id
              createdAt
              price
              currency
              images(first: 1) {
                edges {
                  node {
                    url(quality: 100, width: 500, webp: true)
                  }
                }
              }
              comicDetails {
                title
                number
                variant
                publisher
                year
                gradingAuthority
                grade
              }
            }
          }
        }
      }
    `,
    queryKey,
  );

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("account.FAVORITES")}
      </SBHeading>
      <ul {...stylex.props(styles.favorites)}>
        {user?.favorites?.edges?.map(({ node }) => {
          const { id, comicDetails, images, price, currency } = node;
          const { title, number, gradingAuthority, grade } = comicDetails ?? {};

          return (
            <SBProductTile
              key={id}
              currency={currency}
              grade={grade ?? 0}
              gradingAuthority={gradingAuthority as GradingAuthority}
              imageUrl={images?.edges[0]?.node?.url ?? ""}
              number={number ?? "nn"}
              price={price}
              productId={id}
              salesChannel="SHOP"
              title={title ?? ""}
            />
          );
        })}
      </ul>
    </>
  );
};

const styles = stylex.create({
  favorites: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(2, 1fr)",
      default: "repeat(4, 1fr)",
    },
    margin: 0,
    padding: 0,
  },
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
});

export default AccountFavoritesView;
