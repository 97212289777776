/**
 * @generated SignedSource<<cf2e672abc669af7986a1fa737ee2aaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsBuyerDefault_product$data = {
  readonly currency: Currency;
  readonly id: string;
  readonly minOfferPercentage: number;
  readonly minOfferPrice: number | null | undefined;
  readonly price: number;
  readonly " $fragmentType": "ProductDetailActionsBuyerDefault_product";
};
export type ProductDetailActionsBuyerDefault_product$key = {
  readonly " $data"?: ProductDetailActionsBuyerDefault_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsBuyerDefault_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsBuyerDefault_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPercentage",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "1c703f951318bb88bfff1f42164f2ea8";

export default node;
