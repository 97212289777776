/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TitlesForLetterQuery } from "src/types/__generated__/TitlesForLetterQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";
import { useLocation } from "react-router-dom";

import {
  COMIC_INDEX_BASE_PATH,
  COMIC_INDEX_TITLE_PATH,
} from "src/app/router/Router";
import { SBLink } from "src/sbxui";

type Props = Readonly<{
  letter: string;
}>;

export const COUNT = 100;

export const TitlesForLetter = React.memo(({ letter }: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const skip = Number.parseInt(queryParams.get("skip") ?? "0", 10);
  const after = btoa(`arrayconnection:${skip === 0 ? 0 : skip * COUNT}`);

  const { titles } = useLazyLoadQuery<TitlesForLetterQuery>(
    graphql`
      query TitlesForLetterQuery(
        $letter: String!
        $first: Int!
        $after: String!
      ) {
        titles(first: $first, after: $after, where: { firstLetter: $letter }) {
          pageInfo {
            startCursor
            endCursor
          }
          count
          edges {
            node {
              id
              name
              publisher {
                name
              }
            }
          }
        }
      }
    `,
    { after, first: COUNT, letter },
  );

  const numPages = Math.floor(titles?.count / COUNT);

  return (
    <>
      <div {...stylex.props(styles.titles)}>
        {titles?.edges?.map(({ node }) => {
          const { name, id, publisher } = node;

          return (
            <SBLink
              key={id}
              to={COMIC_INDEX_TITLE_PATH.replace(":id", id)}
              // FIXME Should be localized
              // eslint-disable-next-line react/jsx-no-literals
            >{`${name} (${publisher?.name})`}</SBLink>
          );
        })}
      </div>
      <div {...stylex.props(styles.pages, styles.pagesGridColumns(numPages))}>
        {new Array(numPages).fill(1).map((_char, idx) => {
          const page = idx + 1;
          const key = `page-${idx}`;
          return (
            <div
              {...stylex.props(
                styles.page,
                page === skip && styles.pageSelected,
              )}
              key={key}
            >
              <SBLink
                to={`${COMIC_INDEX_BASE_PATH}?letter=${letter}&skip=${page}`}
              >
                {page}
              </SBLink>
            </div>
          );
        })}
      </div>
    </>
  );
});

const styles = stylex.create({
  page: {
    backgroundColor: {
      ":hover": "#FFF",
    },
    textAlign: "center",
  },
  pageSelected: {
    backgroundColor: "#CDCDCD",
  },
  pages: {
    display: "grid",
    gridGap: 5,
    marginTop: 40,
  },
  pagesGridColumns: (columns) => ({
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  }),
  titles: {
    display: "flex",
    flexFlow: "column",
    flexWrap: "wrap",
    gridColumnGap: 20,
    marginTop: 20,
    maxHeight: 800,
  },
});
