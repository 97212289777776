/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountQuery } from "src/types/__generated__/QueriesAccountQuery.graphql";

import * as React from "react";
import { Suspense, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { UserContext } from "src/app/context/user";
import { SBActivityIndicator, SBErrorBoundary } from "src/sbxui";

import AccountContent from "./AccountContent";

const Account = (): React.ReactNode => {
  const { user } = useContext(UserContext);
  const pageContext = useContext(PageContext);
  const { t } = useTranslation();

  const queryRef = useLoaderData() as PreloadedQuery<QueriesAccountQuery>;

  React.useEffect(() => {
    pageContext?.setTitle(t("account.documentTitle"));
  }, [t, pageContext]);

  if (queryRef == null) {
    return null;
  }

  return (
    <SBErrorBoundary>
      <Suspense fallback={<SBActivityIndicator />}>
        {user == null ? null : <AccountContent queryRef={queryRef} />}
      </Suspense>
    </SBErrorBoundary>
  );
};

export default React.memo(Account);
