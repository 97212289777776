/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { FeaturedArticle_article$key } from "src/types/__generated__/FeaturedArticle_article.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import ArticleCard from "./ArticleCard";

type Props = Readonly<{
  queryKey: FeaturedArticle_article$key;
}>;

const FeaturedArticle = ({ queryKey }: Props): React.ReactNode | null => {
  const { featuredArticle } = useFragment(
    graphql`
      fragment FeaturedArticle_article on Query {
        featuredArticle {
          ...ArticleCard_article
        }
      }
    `,
    queryKey,
  );

  if (featuredArticle == null) {
    return null;
  }

  return (
    <div {...stylex.props(auto, styles.featured)}>
      <ArticleCard featured={true} queryKey={featuredArticle} />
    </div>
  );
};

export default FeaturedArticle;

const styles = stylex.create({
  featured: {
    borderBottomColor: colors.horizontalRuleColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    marginBottom: 48,
    paddingBottom: 48,
  },
});
