/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// @ts-nocheck

import type { Disposable, UseMutationConfig } from "react-relay";
import type { useWatchAuctionLotMutation } from "src/types/__generated__/useWatchAuctionLotMutation.graphql.ts";

import { graphql, useMutation } from "react-relay";

export const useWatchAuctionLot = (): [
  (config: UseMutationConfig<useWatchAuctionLotMutation>) => Disposable,

  boolean,
] => {
  const [commit, isInFlight] = useMutation(graphql`
    mutation useWatchAuctionLotMutation($input: AuctionLotIdInput!) {
      watchAuctionLot(watchAuctionLotInput: $input) {
        ... on AuctionLot {
          id
          ...AuctionLotView_auctionLot
          ...AuctionSearchItem_auctionLot
          ...AuctionItem_auctionLot
        }
      }
    }
  `);

  return [commit, isInFlight];
};
