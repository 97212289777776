/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

export enum DateFormatType {
  Short,
  Long,
}

export function dateShort(date: Date, language: string): string {
  return date.toLocaleDateString(language, {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
}

export function dateLong(date: Date, language: string): string {
  return date.toLocaleDateString(language, { hour12: true });
}
