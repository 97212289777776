/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountPersonalInfoView_user$key } from "src/types/__generated__/AccountPersonalInfoView_user.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading } from "src/sbxui";

import PersonalInfoForm from "./PersonalInfoForm";

type Props = Readonly<{
  queryKey: AccountPersonalInfoView_user$key;
}>;

const AccountPersonalInfoView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const user = useFragment(
    graphql`
      fragment AccountPersonalInfoView_user on User {
        ...PersonalInfoForm_user
      }
    `,
    queryKey,
  );

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("account.PERSONAL_INFORMATION")}
      </SBHeading>
      <PersonalInfoForm queryKey={user} />
    </>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
});

export default AccountPersonalInfoView;
