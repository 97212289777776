/**
 * @generated SignedSource<<665179356bce6759cf1f1596ebd29fcb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountFavoritesView_user$data = {
  readonly favorites: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly comicDetails: {
          readonly grade: number | null | undefined;
          readonly gradingAuthority: GradingAuthority;
          readonly number: string;
          readonly publisher: string;
          readonly title: string;
          readonly variant: string | null | undefined;
          readonly year: string | null | undefined;
        } | null | undefined;
        readonly createdAt: string;
        readonly currency: Currency;
        readonly hasViewerFavorited: boolean;
        readonly id: string;
        readonly images: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly url: string;
            };
          }>;
        } | null | undefined;
        readonly price: number;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "AccountFavoritesView_user";
};
export type AccountFavoritesView_user$key = {
  readonly " $data"?: AccountFavoritesView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountFavoritesView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountFavoritesView_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "createdAt": "DESC"
          }
        }
      ],
      "concreteType": "ProductsConnection",
      "kind": "LinkedField",
      "name": "favorites",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasViewerFavorited",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    }
                  ],
                  "concreteType": "ImagesConnection",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImagesEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Image",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "quality",
                                  "value": 100
                                },
                                {
                                  "kind": "Literal",
                                  "name": "webp",
                                  "value": true
                                },
                                {
                                  "kind": "Literal",
                                  "name": "width",
                                  "value": 500
                                }
                              ],
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": "url(quality:100,webp:true,width:500)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "images(first:1)"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ComicDetail",
                  "kind": "LinkedField",
                  "name": "comicDetails",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "number",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "variant",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "publisher",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "year",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "gradingAuthority",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "grade",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "favorites(orderBy:{\"createdAt\":\"DESC\"})"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b173905a1005f44ce494c541ceff36c4";

export default node;
