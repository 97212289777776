/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HeadingLevel, SBHeading } from "src/sbxui";

import PasswordChangeForm from "./PasswordChangeForm";

const AccountPasswordChangeView = (): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("account.PASSWORD")}
      </SBHeading>
      <PasswordChangeForm />
    </>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
});

export default AccountPasswordChangeView;
