/**
 * @generated SignedSource<<a9ca330f0e9727e49bfb2d7c6ca1d5ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProblemEnum = "APP_BUG" | "HISTORICAL_SALE" | "IMAGE_QUALITY" | "INACCURATE_GRADE" | "INVOICE_EXPIRED" | "LABEL_NOT_FOUND" | "OFFENSIVE_CONTENT" | "ORDER_ISSUE" | "PRODUCT_MISMATCH" | "%future added value";
export type CreateProblemInput = {
  context?: string | null | undefined;
  gradedCopyId?: string | null | undefined;
  orderId?: string | null | undefined;
  problem: ProblemEnum;
  productId?: string | null | undefined;
  userId?: string | null | undefined;
};
export type ReportProductIssueModalMutation$variables = {
  input: CreateProblemInput;
};
export type ReportProductIssueModalMutation$data = {
  readonly createProblem: {
    readonly problem: ProblemEnum;
    readonly productId: string | null | undefined;
  };
};
export type ReportProductIssueModalMutation = {
  response: ReportProductIssueModalMutation$data;
  variables: ReportProductIssueModalMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createProblemInput",
        "variableName": "input"
      }
    ],
    "concreteType": "Problem",
    "kind": "LinkedField",
    "name": "createProblem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problem",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportProductIssueModalMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportProductIssueModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f6978d98cc3c147085baadbc497fb6d6",
    "id": null,
    "metadata": {},
    "name": "ReportProductIssueModalMutation",
    "operationKind": "mutation",
    "text": "mutation ReportProductIssueModalMutation(\n  $input: CreateProblemInput!\n) {\n  createProblem(createProblemInput: $input) {\n    problem\n    productId\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ed89c9ead50800f71094da13d208d3b";

export default node;
