/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";

import { SBErrorMessage } from "src/sbxui";

type Props = React.PropsWithChildren;

interface State {
  error: Error | null;
  errorInfo: {
    componentStack: string;
  } | null;
}

export class SBErrorBoundary extends React.Component<Props, State> {
  state = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(
    error: Error,
    errorInfo: {
      componentStack: string;
    },
  ) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error == null) {
      return children;
    }

    // @ts-ignore This is fine; it’s a Relay error response.
    const text = error?.res?.text;
    const response = text == null ? null : JSON.parse(text);

    const message =
      // Relay error
      response?.errors?.[0]?.message ??
      // @ts-ignore This is fine; it’s a Relay error response.
      error?.message;

    return <SBErrorMessage message={message} />;
  }
}
