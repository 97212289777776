/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import {
  DateFormatType,
  dateLong,
  dateShort,
} from "src/utils/_utils/formatDate";

interface Options {
  readonly dateString: string | number;
  readonly format: DateFormatType;
  readonly language: string;
}

export const fixDate = (dateString: string | number): Date => {
  return new Date(
    Number.isNaN(Number(dateString)) ? dateString : Number(dateString),
  );
};

export const SBDate = ({
  dateString,
  format,
  language,
}: Options): string | null => {
  const fixedDate = fixDate(dateString);

  if (fixedDate == null) {
    return null;
  }

  switch (format) {
    case DateFormatType.Long:
      return dateLong(fixedDate, language);
    case DateFormatType.Short:
    default:
      return dateShort(fixedDate, language);
  }
};
