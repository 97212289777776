/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";

type Props = React.PropsWithChildren;

interface State {
  error: Error | null;
  hasError: boolean;
  info: React.ErrorInfo | null;
}

export default class HomeErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
    hasError: false,
    info: null,
  };

  static getDerivedStateFromError(_error: Error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, info.componentStack);
    this.setState({
      error,
      info,
    });
  }

  render() {
    const { children } = this.props;
    const { error, hasError } = this.state;

    if (!hasError) {
      return children;
    }

    // FIXME Implement this.
    return <p>{error?.message}</p>;
  }
}
