/**
 * @generated SignedSource<<64c4aceff98b5a60ef9a0bee7f6d7c57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UpdateUserTermsInput = {
  allowMarketingNotifs?: boolean | null | undefined;
  hasAcceptedPrivacyPolicy?: boolean | null | undefined;
  hasAcceptedTermsOfService?: boolean | null | undefined;
};
export type TermsFixUpFormUpdateTermsMutation$variables = {
  input: UpdateUserTermsInput;
};
export type TermsFixUpFormUpdateTermsMutation$data = {
  readonly updateTerms: {
    readonly hasAcceptedPrivacyPolicy: boolean;
    readonly hasAcceptedTermsOfService: boolean;
    readonly id: string;
  };
};
export type TermsFixUpFormUpdateTermsMutation = {
  response: TermsFixUpFormUpdateTermsMutation$data;
  variables: TermsFixUpFormUpdateTermsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "updateUserTermsInput",
        "variableName": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "updateTerms",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAcceptedPrivacyPolicy",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasAcceptedTermsOfService",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TermsFixUpFormUpdateTermsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TermsFixUpFormUpdateTermsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4396b12e548da5fbd94e75acc2307cfe",
    "id": null,
    "metadata": {},
    "name": "TermsFixUpFormUpdateTermsMutation",
    "operationKind": "mutation",
    "text": "mutation TermsFixUpFormUpdateTermsMutation(\n  $input: UpdateUserTermsInput!\n) {\n  updateTerms(updateUserTermsInput: $input) {\n    id\n    hasAcceptedPrivacyPolicy\n    hasAcceptedTermsOfService\n  }\n}\n"
  }
};
})();

(node as any).hash = "5b72909767ee5c94ebf512e2bd0b54d1";

export default node;
