/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TableConfig } from "src/sbxui";
import type {
  AuctionLotBidHistoryTableQuery_auctionLot$key,
  Currency,
} from "src/types/__generated__/AuctionLotBidHistoryTableQuery_auctionLot.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as React from "react";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator, SBTable, SBTableRow } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

dayjs.extend(localizedFormat);

type Props = Readonly<{
  isClosed: boolean | undefined;
  queryKey: AuctionLotBidHistoryTableQuery_auctionLot$key;
}>;

const AuctionLotBidHistoryTable = ({
  isClosed = true,
  queryKey,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(
    graphql`
      fragment AuctionLotBidHistoryTableQuery_auctionLot on AuctionLot
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 20 }
      )
      @refetchable(queryName: "AuctionLotBidHistoryTablePaginationQuery") {
        bidHistory(after: $cursor, first: $count)
          @connection(key: "AuctionLotBidHistoryTable_bidHistory") {
          edges {
            node {
              id
              bidAmount
              bidCurrency
              username
              createdAt
              viewerIsBidder
              viewerIsHighBidder
            }
          }
        }
      }
    `,
    queryKey,
  );

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  const shortDateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.languages, {
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        month: "numeric",
        year: "numeric",
      }),
    [i18n.languages],
  );

  const columns = [
    {
      id: "bid",
      label: "Bid/Bidder",
    },
    {
      id: "createdAt",
      label: "Time",
    },
  ];

  const rows = data?.bidHistory?.edges?.map(({ node }) => {
    const {
      bidAmount,
      bidCurrency,
      createdAt,
      username,
      viewerIsBidder,
      viewerIsHighBidder,
    } = node;

    let name = viewerIsBidder
      ? t("auction.lot.bid-history.viewer-outbid")
      : username;

    if (viewerIsHighBidder) {
      name = isClosed
        ? t("auction.lot.bid-history.viewer-won")
        : t("auction.lot.bid-history.viewer-winning");
    }
    return (
      <SBTableRow
        key={node.id}
        id={node.id}
        td={[
          {
            id: "bid",
            label: (
              <>
                <div {...stylex.props(styles.bid)}>
                  {formatMoney(
                    bidAmount,
                    bidCurrency as Currency,
                    i18n.language,
                  )}
                </div>
                <div {...stylex.props(styles.bidder)}>{name}</div>
              </>
            ),
          },
          {
            id: "createdAt",
            label: (
              <div {...stylex.props(styles.time)}>
                {shortDateFormatter.format(
                  Number.parseInt(createdAt ?? "", 10),
                )}
              </div>
            ),
          },
        ]}
      />
    );
  });

  const tableConfig: TableConfig = {
    columns,
    showHeader: false,
  };

  return (
    <>
      <SBTable config={tableConfig} rows={rows} />
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator small={true} /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  bid: {
    color: colors.color,
    fontWeight: 600,
    marginBottom: 4,
  },
  bidder: {
    color: colors.color,
    fontSize: 14,
    whiteSpace: "nowrap",
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  time: {
    color: colors.color,
    fontSize: 14,
  },
});

export default AuctionLotBidHistoryTable;
