/**
 * @generated SignedSource<<f456da8e8d6d0ddefbb99726115bfc10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RelatedProducts_relatedProducts$data = {
  readonly relatedProducts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly comicDetails: {
          readonly grade: number | null | undefined;
          readonly gradingAuthority: GradingAuthority;
          readonly number: string;
          readonly title: string;
        } | null | undefined;
        readonly currency: Currency;
        readonly fmvRaw: {
          readonly fmvMaxValue: number | null | undefined;
          readonly fmvMinValue: number | null | undefined;
        } | null | undefined;
        readonly fmvScore: {
          readonly rank: number | null | undefined;
        } | null | undefined;
        readonly hasViewerFavorited: boolean;
        readonly id: string;
        readonly images: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly url: string;
            };
          }>;
        } | null | undefined;
        readonly price: number;
      };
    }>;
  };
  readonly " $fragmentType": "RelatedProducts_relatedProducts";
};
export type RelatedProducts_relatedProducts$key = {
  readonly " $data"?: RelatedProducts_relatedProducts$data;
  readonly " $fragmentSpreads": FragmentRefs<"RelatedProducts_relatedProducts">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "productId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "RelatedProducts_relatedProducts",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 4
        },
        {
          "kind": "Variable",
          "name": "productId",
          "variableName": "productId"
        }
      ],
      "concreteType": "RelatedProductsConnection",
      "kind": "LinkedField",
      "name": "relatedProducts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RelatedProductsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasViewerFavorited",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FairMarketValue",
                  "kind": "LinkedField",
                  "name": "fmvRaw",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fmvMaxValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fmvMinValue",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FairMarketValueScore",
                  "kind": "LinkedField",
                  "name": "fmvScore",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rank",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ComicDetail",
                  "kind": "LinkedField",
                  "name": "comicDetails",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "number",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "grade",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "gradingAuthority",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ImagesConnection",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImagesEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Image",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "quality",
                                  "value": 100
                                },
                                {
                                  "kind": "Literal",
                                  "name": "webp",
                                  "value": true
                                },
                                {
                                  "kind": "Literal",
                                  "name": "width",
                                  "value": 500
                                }
                              ],
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": "url(quality:100,webp:true,width:500)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2d1aa4d055efa2b6f35a986f52b40722";

export default node;
