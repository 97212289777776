/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailActionsBuyerCounter_product$key } from "src/types/__generated__/ProductDetailActionsBuyerCounter_product.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import AcceptOffer from "src/app/components/accept-offer/AcceptOffer";
import CounterOffer from "src/app/components/counter-offer/CounterOffer";
import { CounterOfferFrom } from "src/app/components/counter-offer/CounterOfferFrom";
import DeclineOffer from "src/app/components/decline-offer/DeclineOffer";
import { useCountdownTimer } from "src/hooks";
import { ButtonType, ButtonVariation, SBButton, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

enum Modal {
  None,
  Counter,
  Accept,
  Decline,
}

type Props = Readonly<{
  queryKey: ProductDetailActionsBuyerCounter_product$key;
}>;

const ProductDetailActionsBuyerCounter = ({
  queryKey,
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProductDetailActionsBuyerCounter_product on Product {
        id
        currency
        comicDetails {
          title
          number
          variant
          publisher
          grade
          gradingAuthority
        }
        counterOffer: offers(
          first: 1
          orderBy: { updatedAt: DESC }
          where: { status: IN_REVIEW, reason: [COUNTER_FROM_SELLER] }
        ) {
          edges {
            node {
              id
              price
              currency
              expiresAt
              status
              previousOffer {
                price
                currency
              }
            }
          }
        }
        price
      }
    `,
    queryKey,
  );

  const offers = data.counterOffer?.edges ?? [];
  const node = offers?.[0]?.node;
  const offerId = node?.id;
  const counterOfferPrice = node.price;
  const currency = data.currency ?? "USD";
  const expiresAt = node?.expiresAt;
  const expiry = Number(expiresAt);
  const { expiresIn } = useCountdownTimer(expiry);

  const yourOfferPrice = node?.previousOffer?.price ?? 0;

  const [modal, setModal] = useState(Modal.None);
  const handleCloseModal = () => {
    setModal(Modal.None);
  };

  const handleClickCounterOffer = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setModal(Modal.Counter);
  };
  const handleClickDeclineCounterOffer = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setModal(Modal.Decline);
  };

  const handleClickAcceptCounterOffer = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setModal(Modal.Accept);
  };

  return (
    <>
      <div {...stylex.props(styles.offerInfo)}>
        <SBParagraph style={styles.title}>
          {t("product.status.counter.title")}
        </SBParagraph>
        <SBParagraph style={styles.offerPrice}>
          {formatMoney(counterOfferPrice, currency, i18n.language)}
        </SBParagraph>
        <SBParagraph style={styles.originalOfferPrice}>
          {t("product.status.counter.price.original.subtext", {
            value: formatMoney(yourOfferPrice, currency, i18n.language),
          })}
        </SBParagraph>
        <SBParagraph style={styles.expiry}>{expiresIn}</SBParagraph>{" "}
        <SBParagraph>{t("product.status.counter.subtext")}</SBParagraph>
      </div>
      <SBButton
        block={true}
        style={styles.button}
        title={t("product.header.buttons.accept-counter-offer")}
        type={ButtonType.Button}
        variation={ButtonVariation.Emphasis}
        onClick={handleClickAcceptCounterOffer}
      />
      <SBButton
        block={true}
        style={styles.button}
        title={t("product.header.buttons.decline-counter-offer")}
        type={ButtonType.Button}
        variation={ButtonVariation.Default}
        onClick={handleClickDeclineCounterOffer}
      />
      <SBButton
        block={true}
        style={styles.button}
        title={t("product.header.buttons.new.counter-offer")}
        type={ButtonType.Button}
        variation={ButtonVariation.Default}
        onClick={handleClickCounterOffer}
      />
      <AcceptOffer
        isOpen={modal === Modal.Accept}
        offerId={offerId}
        setOpen={handleCloseModal}
      />
      <DeclineOffer
        isOpen={modal === Modal.Decline}
        offerId={offerId}
        setOpen={handleCloseModal}
      />
      <CounterOffer
        isOpen={modal === Modal.Counter}
        offerFrom={CounterOfferFrom.Buyer}
        offerId={offerId}
        setOpen={handleCloseModal}
      />
    </>
  );
};

const styles = stylex.create({
  button: {
    marginBottom: 16,
  },
  expiry: {
    color: colors.colorEmphasis,
  },
  offerInfo: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 16,
  },
  offerPrice: {
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 72,
  },
  originalOfferPrice: {
    fontSize: 20,
  },
  title: {
    marginBottom: 0,
  },
});

export default ProductDetailActionsBuyerCounter;
