/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesOrderDetailQuery } from "src/types/__generated__/QueriesOrderDetailQuery.graphql";

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Fragment } from "react";

import OrderDetail from "src/app/pages/order-detail/OrderDetail";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<
  React.PropsWithChildren<{
    isOpen: boolean;
    queryRef: PreloadedQuery<QueriesOrderDetailQuery> | null;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }>
>;

export const SBOrderDetailTakeover = ({
  setOpen,
  isOpen,
  queryRef,
}: Props): React.ReactNode => {
  const handleCloseClick = () => {
    setOpen(false);
  };

  return (
    <Transition appear={true} as={Fragment} show={isOpen}>
      <Dialog
        {...stylex.props(styles.dialog)}
        as="div"
        static={true}
        onClose={handleCloseClick}
      >
        <TransitionChild
          enter={stylex.props(styles.backdropEnter).className}
          enterFrom={stylex.props(styles.backdropEnterFrom).className}
          enterTo={stylex.props(styles.backdropEnterTo).className}
        >
          <div {...stylex.props(auto, styles.backdrop)} aria-hidden="true" />
        </TransitionChild>
        <div {...stylex.props(styles.container)}>
          <TransitionChild
            enter={stylex.props(styles.containerEnter).className}
            enterFrom={stylex.props(styles.containerEnterFrom).className}
            enterTo={stylex.props(styles.containerEnterTo).className}
          >
            <DialogPanel {...stylex.props(styles.panel)}>
              <div {...stylex.props(auto, styles.inner)}>
                <OrderDetail queryRef={queryRef} onClose={handleCloseClick} />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

const styles = stylex.create({
  backdrop: {
    backgroundColor: colors.modalBackdropBackgroundColor,
    inset: 0,
    opacity: 0.75,
    position: "fixed",
  },
  backdropEnter: {
    transitionDuration: "300ms",
    transitionProperty: "opacity",
    transitionTimingFunction: "ease-out",
  },
  backdropEnterFrom: {
    opacity: 0,
  },
  backdropEnterTo: {
    opacity: 0.75,
  },
  container: {
    alignItems: "center",
    display: "grid",
    inset: 0,
    justifyContent: "center",
    overflowY: "auto",
    position: "fixed",
  },
  containerEnter: {
    transitionDelay: "200ms",
    transitionDuration: "300ms",
    transitionProperty: "opacity, transform",
    transitionTimingFunction: "ease-out",
  },
  containerEnterFrom: {
    opacity: 0,
    transform: "scale(0.95)",
  },
  containerEnterTo: {
    opacity: 1,
    transform: "scale(1)",
  },
  dialog: {
    position: "relative",
    zIndex: 10,
  },
  inner: {
    backgroundColor: colors.backgroundColor,
    borderRadius: 8,
    boxSizing: {
      [MOBILE]: "border-box",
      [TABLET]: "border-box",
      default: "content-box",
    },
    marginBlock: 96,
    paddingBlock: 40,
    paddingInline: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 40,
    },
    width: {
      [MOBILE]: "95vw",
      [TABLET]: "95vw",
      default: 600,
    },
  },
  panel: {
    marginInline: "auto",
  },
});
