/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountWatchedLotsQuery } from "src/types/__generated__/QueriesAccountWatchedLotsQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AccountWatchedLotsQuery } from "src/app/router/Queries";

import AccountWatchedLotsView from "./AccountWatchedLotsView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountWatchedLotsQuery>;
}>;

const AccountWatchedLotsContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AccountWatchedLotsQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <AccountWatchedLotsView queryKey={user} />;
};

export default AccountWatchedLotsContent;
