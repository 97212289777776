/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionView_auction$key } from "src/types/__generated__/AuctionView_auction.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { startTransition, useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import { AuctionLotSearchContext } from "src/app/context/auction-lot-search";
import { PageContext } from "src/app/context/page";
import { useCountdownTimer, useIntersectionObserver } from "src/hooks";
import {
  SBActivityIndicator,
  SBInfoMessage,
  SBMarkdownRenderer,
  SBPageHeader,
} from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

import AuctionItem from "./AuctionItem";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AuctionView_auction$key;
}>;

const AuctionView = ({ queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const { sort, searchTerm } = useContext(AuctionLotSearchContext);

  const { data, hasNext, isLoadingNext, loadNext, refetch } =
    usePaginationFragment(
      graphql`
        fragment AuctionView_auction on Auction
        @argumentDefinitions(
          count: { type: "Int", defaultValue: 12 }
          cursor: { type: "String" }
          orderBy: {
            type: "AuctionLotOrderByInput"
            defaultValue: { closesAt: ASC }
          }
          searchTerm: { type: "String", defaultValue: "" }
        )
        @refetchable(queryName: "AuctionViewPaginationQuery") {
          title
          description
          tagline
          biddingStartsAt
          lastLotClosesAt
          biddingClosesAt
          lots(
            first: $count
            after: $cursor
            orderBy: $orderBy
            where: { searchTerm: $searchTerm }
          ) @connection(key: "AuctionLotsView_lots") {
            edges {
              node {
                id
                ...AuctionItem_auctionLot
              }
            }
          }
        }
      `,
      queryKey,
    );

  const title = data.title;
  const description = data.description;
  const tagline = data.tagline;
  const biddingStartsAt = data.biddingStartsAt;
  const lastLotClosesAt = data.lastLotClosesAt;
  const biddingClosesAt = data.biddingClosesAt;

  const startingTimestamp =
    biddingStartsAt == null ? Date.now() : Number.parseInt(biddingStartsAt, 10);
  const closingTimestamp =
    lastLotClosesAt == null ? Date.now() : Number.parseInt(lastLotClosesAt, 10);
  const firstItemClosesAtTimestamp =
    biddingClosesAt == null ? Date.now() : Number.parseInt(biddingClosesAt, 10);

  const { hasExpired } = useCountdownTimer(closingTimestamp, {
    expired: t("auctions.tile.status.closed"),
    short: true,
  });

  const isPreview =
    biddingStartsAt == null
      ? true
      : Date.now() - Number.parseInt(biddingStartsAt, 10) <= 0;

  const pageContext = useContext(PageContext);

  const lots = data.lots?.edges ?? [];

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(12);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  useEffect(() => {
    const splitSort = sort.split("-");
    const orderBy = { [splitSort[0]]: splitSort[1].toUpperCase() };

    pageContext?.setTitle(
      t("auctions.auction.document-title", {
        title,
      }),
    );

    // setIsSearching(false);

    startTransition(() => {
      refetch(
        {
          orderBy,
          searchTerm,
        },
        {
          fetchPolicy: "network-only",
          onComplete: () => {
            // setIsSearching(false);
            window.scrollTo(0, 0);
          },
        },
      );
    });
  }, [searchTerm, sort, refetch, title, pageContext, t]);

  const shortDateFormatter = React.useMemo(
    () =>
      !isPreview && !hasExpired
        ? new Intl.DateTimeFormat(i18n.languages, {
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            month: "numeric",
          })
        : new Intl.DateTimeFormat(i18n.languages, {
            day: "numeric",
            month: "numeric",
          }),
    [hasExpired, i18n.languages, isPreview],
  );

  let subtitle = isPreview ? (
    <div {...stylex.props(styles.subtitle)}>
      {t("auctions.tile.subtitle.preview", {
        timestamp: shortDateFormatter.format(startingTimestamp),
      })}
    </div>
  ) : (
    <>
      <div {...stylex.props(styles.subtitle)}>
        {t("auctions.tile.subtitle.first-lots", {
          timestamp: shortDateFormatter.format(firstItemClosesAtTimestamp),
        })}
      </div>
      <div {...stylex.props(styles.subtitle)}>
        {t("auctions.tile.subtitle.open-lots", {
          timestamp: shortDateFormatter.format(closingTimestamp),
        })}
      </div>
    </>
  );
  if (!isPreview && hasExpired) {
    subtitle = (
      <div {...stylex.props(styles.subtitle)}>
        {t("auctions.tile.subtitle.closed", {
          timestamp: shortDateFormatter.format(closingTimestamp),
        })}
      </div>
    );
  }

  return (
    <div {...stylex.props(styles.auction)}>
      <div {...stylex.props(styles.header)}>
        <div {...stylex.props(styles.auctionHeader)}>
          <SBPageHeader
            title={t("auctions.auction.page-title", {
              title,
            })}
          />
          <SBMarkdownRenderer markdown={tagline ?? ""} />
        </div>
        <div {...stylex.props(styles.auctionHeaderDescription)}>
          <SBMarkdownRenderer markdown={description} />
        </div>
      </div>
      <div {...stylex.props(styles.times)}>{subtitle}</div>
      {lots.length > 0 ? (
        <ul {...stylex.props(styles.lots)}>
          {lots.map(({ node }) => (
            <AuctionItem key={node.id} queryKey={node} />
          ))}
        </ul>
      ) : (
        <SBInfoMessage
          message={t("auctions.search.no-items")}
          style={styles.infoMessage}
        />
      )}
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </div>
  );
};

const styles = stylex.create({
  auction: {
    marginBottom: 96,
  },
  auctionHeader: {
    margin: 20,
    width: {
      [MOBILE]: "90%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  auctionHeaderDescription: {
    margin: 20,
    width: {
      [MOBILE]: "90%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  header: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "flex",
      default: "flex",
    },
    margin: {
      [MOBILE]: 0,
      [TABLET]: 20,
      default: 20,
    },
  },
  infoMessage: {
    marginTop: 16,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  lots: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    margin: 0,
    marginBottom: 64,
    padding: 0,
  },
  subtitle: {
    color: colors.color,
    fontSize: 14,
    marginBottom: 8,
  },
  times: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default AuctionView;
