/**
 * @generated SignedSource<<e2f584b3dcbf5bd9fe3168fb460378e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesTermsQuery$variables = Record<PropertyKey, never>;
export type QueriesTermsQuery$data = {
  readonly terms: {
    readonly " $fragmentSpreads": FragmentRefs<"TermsView_document">;
  } | null | undefined;
};
export type QueriesTermsQuery = {
  response: QueriesTermsQuery$data;
  variables: QueriesTermsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesTermsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "terms",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TermsView_document"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesTermsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "terms",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f5832fbc03a266bb2d5f84cb1173247c",
    "id": null,
    "metadata": {},
    "name": "QueriesTermsQuery",
    "operationKind": "query",
    "text": "query QueriesTermsQuery {\n  terms {\n    ...TermsView_document\n    id\n  }\n}\n\nfragment TermsView_document on Document {\n  id\n  publishedAt\n  title\n  body\n}\n"
  }
};

(node as any).hash = "ec9c3455ba7aa3128ad72b94c9260b90";

export default node;
