/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  DEFAULT_SORT,
  PortfolioContext,
  Sort,
} from "src/app/context/portfolio";
import {
  ButtonType,
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBIcon,
  SBModal,
  SBToggleButton,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

enum Option {
  Date = "date",
  Favorites = "favorites",
  Price = "price",
  Title = "title",
}

const orderedOptions = [
  Option.Date,
  Option.Favorites,
  Option.Price,
  Option.Title,
];

type Props = Readonly<{
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const PortfolioSortModal = ({ isOpen, setOpen }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { canListForSale, setSort, sort } = useContext(PortfolioContext);

  const [selected, setSelected] = useState<Sort>(sort);

  const onApplySort = (_event: React.SyntheticEvent) => {
    setSort(selected);
    setOpen(false);
  };

  const onReset = (_event: React.SyntheticEvent) => {
    setSelected(DEFAULT_SORT);
  };

  const onSortPress =
    (value: Sort) => (_event: React.ChangeEvent<HTMLInputElement>) => {
      setSelected(value);
    };

  const options: React.ReactNode[] = [];

  let idx = 0;
  for (const key of orderedOptions) {
    const title = t(`portfolio.filters.sort.titles.${key}`);
    const buttonAsc = t(`portfolio.filters.sort.options.${kebabCase(key)}-asc`);
    const buttonDesc = t(
      `portfolio.filters.sort.options.${kebabCase(key)}-desc`,
    );

    let sortAsc: Sort;
    let sortDesc: Sort;

    switch (key) {
      default:
        sortAsc = Sort.DateAsc;
        sortDesc = Sort.DateDesc;
        break;
      case Option.Favorites:
        sortAsc = Sort.FavoritesAsc;
        sortDesc = Sort.FavoritesDesc;
        break;
      case Option.Price:
        sortAsc = Sort.PriceAsc;
        sortDesc = Sort.PriceDesc;
        break;
      case Option.Title:
        sortAsc = Sort.TitleAsc;
        sortDesc = Sort.TitleDesc;
        break;
    }

    if (!canListForSale && key === Option.Price) {
      continue;
    }

    options.push(
      <div
        {...stylex.props(styles.row, idx === 0 && styles.rowFirst)}
        key={key}
      >
        <SBHeading level={HeadingLevel.H2} style={styles.heading}>
          {title}
        </SBHeading>
        <div {...stylex.props(styles.range)}>
          <SBToggleButton
            block={true}
            id={`sort-option-a-${key}`}
            selected={selected === sortDesc}
            title={buttonDesc}
            onChange={onSortPress(sortDesc)}
          />
          <SBToggleButton
            block={true}
            id={`sort-option-b-${key}`}
            selected={selected === sortAsc}
            title={buttonAsc}
            onChange={onSortPress(sortAsc)}
          />
        </div>
      </div>,
    );
    idx++;
  }

  return (
    <SBModal
      footer={
        <div {...stylex.props(styles.footer)}>
          <div {...stylex.props(styles.reset)}>
            <button
              {...stylex.props(auto, styles.resetButton)}
              type="button"
              onClick={onReset}
            >
              <SBIcon
                fill={false}
                icon="restart_alt"
                style={styles.resetIcon}
              />
              {t("modal.sort.reset")}
            </button>
          </div>
          <SBButton
            style={styles.applyButton}
            title={t("modal.sort.apply")}
            type={ButtonType.Button}
            variation={ButtonVariation.Emphasis}
            onClick={onApplySort}
          />
        </div>
      }
      headerText={t("modal.sort.header")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <fieldset {...stylex.props(styles.fieldset)}>{options}</fieldset>
    </SBModal>
  );
};

const styles = stylex.create({
  applyButton: {
    flexGrow: 0,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 16,
  },
  fmvDot: {
    marginLeft: 10,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: 20,
    marginBottom: 12,
  },
  range: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 16,
    justifyContent: "space-between",
  },
  reset: {
    alignItems: "center",
    display: "flex",
  },
  resetButton: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginRight: 16,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  resetIcon: {
    color: colors.color,
    marginRight: 8,
  },
  row: {
    marginTop: 24,
  },
  rowFirst: {
    marginTop: 0,
  },
  toggles: {
    display: "grid",
    gridColumnGap: 24,
    gridRowGap: 12,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
});

export default PortfolioSortModal;
