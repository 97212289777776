/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesOrderDetailQuery } from "src/types/__generated__/QueriesOrderDetailQuery.graphql";

import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePreloadedQuery } from "react-relay";
import { useLocation } from "react-router-dom";

import { OrderDetailQuery } from "src/app/router/Queries";
import { SBErrorPage } from "src/sbxui";

import OrderDetailView from "./OrderDetailView";

type Props = Readonly<{
  onClose: () => void;
  queryRef: PreloadedQuery<QueriesOrderDetailQuery>;
}>;

const OrderDetailContent = ({ onClose, queryRef }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const queryKey = usePreloadedQuery(OrderDetailQuery, queryRef);

  if (queryKey.orderBySurrogateId == null) {
    return (
      <SBErrorPage
        buttonHref={pathname}
        buttonOnClick={onClose}
        buttonTitle={t("product.back-to-marketplace")}
        description={t("not-found.description")}
        errorCode={404}
        title={t("not-found.page-title")}
      />
    );
  }

  return (
    <OrderDetailView queryKey={queryKey.orderBySurrogateId} onClose={onClose} />
  );
};

export default OrderDetailContent;
