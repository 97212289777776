/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";
import type { LoginResponseType } from "src/app/context/user";
import type { FacebookSSOButtonFacebookLoginMutation } from "src/types/__generated__/FacebookSSOButtonFacebookLoginMutation.graphql";

import FacebookLogin from "@greatsumini/react-facebook-login";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMutation } from "react-relay";

import { API_CLIENT_ID } from "src/app/constants";
import { SBFacebookButton } from "src/sbxui";

const APP_ID = "207113257198614";

type Props = Readonly<{
  onLoginSuccess: (
    loginResponse: LoginResponseType,
    needsTermsFixUp: boolean,
  ) => void;
  signup?: boolean;
  style?: StyleXStyles;
}>;

export const FacebookSSOButton = ({
  signup,
  style,
  onLoginSuccess,
}: Props): React.ReactNode => {
  const [commit] = useMutation<FacebookSSOButtonFacebookLoginMutation>(graphql`
    mutation FacebookSSOButtonFacebookLoginMutation(
      $input: SocialOAuthTokenVerificationInput!
    ) {
      facebookLogin(socialOAuthTokenVerificationInput: $input) {
        accessToken
        refreshToken
        user {
          id
          sellerStatus
          hasAcceptedTermsOfService
          hasAcceptedPrivacyPolicy
        }
        permissions
      }
    }
  `);

  return (
    <FacebookLogin
      appId={APP_ID}
      render={({ onClick }) => (
        <SBFacebookButton signup={signup} style={style} onClick={onClick} />
      )}
      onFail={(error) => {
        // FIXME Need error handling here.
        // eslint-disable-next-line no-console
        console.error("Login Failed!", error);
      }}
      onSuccess={(response) => {
        const token = response.accessToken;
        if (token == null) {
          // FIXME Need error handling here.
          // eslint-disable-next-line no-console
          console.error("No token received");
        }
        commit({
          onCompleted(data) {
            const needsTermsFixUp =
              data.facebookLogin.user?.hasAcceptedPrivacyPolicy === false ||
              data.facebookLogin.user?.hasAcceptedTermsOfService === false;

            onLoginSuccess(data.facebookLogin, needsTermsFixUp);
          },
          variables: {
            input: {
              clientId: API_CLIENT_ID,
              token,
            },
          },
        });
      }}
    />
  );
};
