/**
 * @generated SignedSource<<e3c208188571a34ad9688692b51a8093>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CounterOfferContentQuery$variables = {
  id: string;
};
export type CounterOfferContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CounterOfferView_offer">;
};
export type CounterOfferContentQuery = {
  response: CounterOfferContentQuery$data;
  variables: CounterOfferContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CounterOfferContentQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CounterOfferView_offer"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CounterOfferContentQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ComicDetail",
                "kind": "LinkedField",
                "name": "comicDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variant",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publisher",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minOfferPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minOfferPercentage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Offer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5af7c631d32f2481e0ac721833702fd9",
    "id": null,
    "metadata": {},
    "name": "CounterOfferContentQuery",
    "operationKind": "query",
    "text": "query CounterOfferContentQuery(\n  $id: ID!\n) {\n  ...CounterOfferView_offer\n}\n\nfragment CounterOfferView_offer on Query {\n  node(id: $id) {\n    __typename\n    ... on Offer {\n      id\n      comicDetails {\n        grade\n        gradingAuthority\n        title\n        variant\n        publisher\n        number\n        id\n      }\n      price\n      currency\n      product {\n        id\n        price\n        minOfferPrice\n        minOfferPercentage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c4b64cb28cef30a01eb841e10ef76433";

export default node;
