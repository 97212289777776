/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";

// import { useLocation, useParams } from "react-router-dom";
import { AuctionLotSearchContext } from "src/app/context/auction-lot-search";
// import { AUCTION_PATH } from "src/app/router/Router";
import { SBActivityIndicator, SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import AuctionLotSearchSortModal from "./AuctionLotSearchSortModal";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const AuctionLotSearch = React.memo(() => {
  const { t } = useTranslation();
  // const { id = "" } = useParams();
  // const { pathname } = useLocation();
  const { searchTerm, setSearchTerm } = useContext(AuctionLotSearchContext);

  const [isPending, startTransition] = useTransition();

  const [term, setTerm] = useState(searchTerm);
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(event.currentTarget.value);
  };

  const handleSearchSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    startTransition(() => {
      setSearchTerm(term);
    });
  };

  const handleClickClear = (_e: React.SyntheticEvent) => {
    setTerm("");
    startTransition(() => {
      setSearchTerm("");
    });
  };

  const handleSortClick = (_e: React.SyntheticEvent) => {
    setIsSortModalOpen(true);
  };

  return (
    <div {...stylex.props(styles.root)}>
      <form
        {...stylex.props(auto, styles.form)}
        role="search"
        onSubmit={handleSearchSubmit}
      >
        <input
          {...stylex.props(auto, styles.input)}
          id="search"
          placeholder={t("auction-lot.search.placeholder")}
          type="text"
          value={term}
          onChange={handleSearchChange}
        />
        {term !== "" && (
          <button
            {...stylex.props(auto, styles.clear)}
            aria-label={t("search.clear-label")}
            type="button"
            onClick={handleClickClear}
          >
            <SBIcon fill={false} icon="cancel" style={styles.cancel} />
          </button>
        )}
        <button
          {...stylex.props(auto, styles.button)}
          aria-label={t("search.button-label")}
          disabled={isPending}
          type="submit"
        >
          {isPending ? (
            <SBActivityIndicator small={true} style={styles.spinner} />
          ) : (
            <SBIcon icon="search" style={styles.icon} />
          )}
        </button>
      </form>
      <div {...stylex.props(auto, styles.filterSort)}>
        <button
          {...stylex.props(auto, styles.sort)}
          aria-label={t("search.sort")}
          type="button"
          onClick={handleSortClick}
        >
          <span {...stylex.props(styles.sortLabel)}>{t("search.sort")}</span>
          <SBIcon
            aria-hidden="true"
            fill={false}
            icon="sort"
            style={styles.sortIcon}
          />
        </button>
      </div>
      <AuctionLotSearchSortModal
        isOpen={isSortModalOpen}
        setOpen={setIsSortModalOpen}
      />
    </div>
  );
});

const styles = stylex.create({
  button: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: colors.topNavigationSearchButtonBackgroundColor,
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  cancel: {
    color: colors.topNavigationSearchClearColor,
  },
  clear: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  filter: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  filterIcon: {
    color: colors.topNavigationSearchClearColor,
    marginLeft: 8,
  },
  filterLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  filterSort: {
    alignItems: "center",
    display: "flex",
  },
  form: {
    alignItems: "center",
    backgroundColor: colors.topNavigationSearchBackgroundColor,
    borderColor: colors.topNavigationSearchBorderColor,
    borderRadius: 48,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: colors.topNavigationSearchShadow,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: 16,
    padding: 4,
    paddingLeft: 16,
    width: "100%",
  },
  icon: {
    color: colors.topNavigationSearchButtonColor,
  },
  input: {
    borderWidth: 0,
    height: 40,
    outline: {
      ":hover": "none",
      default: "none",
    },
    width: "100%",
  },
  root: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  sort: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  sortIcon: {
    color: colors.topNavigationSearchClearColor,
    marginLeft: 8,
  },
  sortLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  spinner: {
    fill: colors.topNavigationSearchButtonColor,
  },
});

export default AuctionLotSearch;
