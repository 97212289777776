/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ChangeEvent } from "react";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  className?: string;
  disabled?: boolean;
  id: string;
  label?: string | React.ReactNode;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onClear?: (e: React.SyntheticEvent) => void;
  placeholder?: string;
  prefix?: string;
  readOnly?: boolean;
  rows?: number;
  value: string;
}>;

export const SBTextArea = (props: Props): React.ReactNode => {
  const {
    disabled = false,
    id,
    label,
    rows = 5,
    onChange,
    placeholder,
    readOnly = false,
    value,
  } = props;

  return (
    <>
      {Boolean(label) && <label htmlFor={id}>{label}</label>}
      <div {...stylex.props(styles.container, Boolean(label) && styles.label)}>
        <textarea
          {...stylex.props(styles.textarea)}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          readOnly={readOnly}
          rows={rows}
          value={value}
          onChange={onChange}
        />
      </div>
    </>
  );
};

const styles = stylex.create({
  container: {
    alignItems: "center",
    backgroundColor: colors.inputTextBackgroundColor,
    borderColor: colors.inputTextBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
  },
  label: {
    marginTop: 8,
  },
  textarea: {
    "::-webkit-inner-spin-button": {
      appearance: "none",
    },
    "::-webkit-outer-spin-button": {
      appearance: "none",
    },
    "::placeholder": {
      color: colors.inputTextPlaceholderColor,
    },
    appearance: "textfield",
    background: "transparent",
    borderRadius: 8,
    borderWidth: 0,
    color: colors.inputTextColor,
    // lineHeight: 24,
    outline: {
      ":focus-visible": colors.inputTextOutline,
    },
    paddingInline: 8,
    width: "100%",
  },
});
