/**
 * @generated SignedSource<<5d287ea238c752f703a45f8e634360e7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesSellerAccountStatsQuery$variables = Record<PropertyKey, never>;
export type QueriesSellerAccountStatsQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStatsView_user">;
    } | null | undefined;
  };
};
export type QueriesSellerAccountStatsQuery = {
  response: QueriesSellerAccountStatsQuery$data;
  variables: QueriesSellerAccountStatsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "interval",
    "value": "ONE_MONTH"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSellerAccountStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": (v0/*: any*/),
                "kind": "FragmentSpread",
                "name": "SellerAccountStatsView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesSellerAccountStatsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "UserSellerStats",
                "kind": "LinkedField",
                "name": "sellerStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numOrders",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salesTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradedShippingScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawShippingScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "averageTurnaroundTime",
                    "storageKey": null
                  }
                ],
                "storageKey": "sellerStats(interval:\"ONE_MONTH\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29c824e70907e86b4a3d9a4ab799ab4a",
    "id": null,
    "metadata": {},
    "name": "QueriesSellerAccountStatsQuery",
    "operationKind": "query",
    "text": "query QueriesSellerAccountStatsQuery {\n  viewer {\n    user {\n      ...SellerAccountStatsView_user_3sjQep\n      id\n    }\n  }\n}\n\nfragment SellerAccountStatsFeedbackTable_sellerStats on UserSellerStats {\n  gradedShippingScore\n  gradingScore\n  rawShippingScore\n}\n\nfragment SellerAccountStatsSummary_sellerStats on UserSellerStats {\n  numOrders\n  salesTotal\n}\n\nfragment SellerAccountStatsTurnaroundTimesTable_sellerStats on UserSellerStats {\n  averageTurnaroundTime\n}\n\nfragment SellerAccountStatsView_user_3sjQep on User {\n  sellerStats(interval: ONE_MONTH) {\n    ...SellerAccountStatsSummary_sellerStats\n    ...SellerAccountStatsFeedbackTable_sellerStats\n    ...SellerAccountStatsTurnaroundTimesTable_sellerStats\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "9e8dd868add1efe035bf7505c1d8f2ee";

export default node;
