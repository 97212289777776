/**
 * @generated SignedSource<<2a66ee53c85e90d7f673fb61ad5c6ea4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type OrderPublicStatusType = "CANCELED" | "DELIVERED" | "DELIVERY_EXCEPTION" | "FAILED" | "IN_TRANSIT" | "PROCESSING" | "QUEUED" | "REFUNDED" | "UNAVAILABLE" | "%future added value";
export type PayoutStatus = "FAILED" | "PAID" | "PENDING" | "PROCESSING" | "UNPAID" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusTracking_order$data = {
  readonly payoutStatus: PayoutStatus;
  readonly publicStatus: OrderPublicStatusType;
  readonly trackingId: string | null | undefined;
  readonly " $fragmentType": "OrderStatusTracking_order";
};
export type OrderStatusTracking_order$key = {
  readonly " $data"?: OrderStatusTracking_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderStatusTracking_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderStatusTracking_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "payoutStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trackingId",
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "5ee69262c2951aa0cad1677d0daa350d";

export default node;
