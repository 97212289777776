/**
 * @generated SignedSource<<f60efc6dc2dc4482ec1249f5497ba822>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountPasswordView_user$data = {
  readonly id: string;
  readonly " $fragmentType": "AccountPasswordView_user";
};
export type AccountPasswordView_user$key = {
  readonly " $data"?: AccountPasswordView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountPasswordView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountPasswordView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "8f5ee837753a1e39a179db2e9b96e3b1";

export default node;
