/**
 * @generated SignedSource<<3ee183d34480a7adb4941d3248f91123>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type SaleFormat = "AUCTION" | "BEST_OFFER" | "FIXED_PRICE" | "%future added value";
export type Source = "COMIC_CONNECT" | "COMIC_LINK" | "EBAY" | "HAKES" | "HERITAGE" | "MY_COMIC_SHOP" | "SHORTBOXED" | "THIRD_PARTY" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HistoricalSalesTable_product$data = {
  readonly comicDetails: {
    readonly grade: number | null | undefined;
    readonly gradingAuthority: GradingAuthority;
  } | null | undefined;
  readonly historicalSales: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currency: Currency;
        readonly id: string;
        readonly price: number;
        readonly saleDate: number;
        readonly saleFormat: SaleFormat;
        readonly source: Source;
      };
    }>;
  };
  readonly id: string;
  readonly " $fragmentType": "HistoricalSalesTable_product";
};
export type HistoricalSalesTable_product$key = {
  readonly " $data"?: HistoricalSalesTable_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"HistoricalSalesTable_product">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "historicalSales"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 5,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./HistoricalSalesTablePaginationQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "HistoricalSalesTable_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "historicalSales",
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "saleDate": "DESC"
          }
        }
      ],
      "concreteType": "HistoricalSalesConnection",
      "kind": "LinkedField",
      "name": "__HistoricalSalesTable_historicalSales_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HistoricalSalesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "HistoricalSale",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "source",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "saleDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "saleFormat",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "__HistoricalSalesTable_historicalSales_connection(orderBy:{\"saleDate\":\"DESC\"})"
    },
    (v1/*: any*/)
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "4c173c514e866919785cca7908982bdf";

export default node;
