/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountOfferSettingsQuery } from "src/types/__generated__/QueriesSellerAccountOfferSettingsQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import SellerAccountPage from "../SellerAccountPage";
import SellerAccountOfferSettingsContent from "./SellerAccountOfferSettingsContent";

const SellerAccountOfferSettings = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesSellerAccountOfferSettingsQuery>;

  return (
    <SellerAccountPage
      docTitle={t("seller-account.offer-settings.title")}
      innerHeading={t("seller-account.nav.offer-settings")}
    >
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <SellerAccountOfferSettingsContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </SellerAccountPage>
  );
};

export default SellerAccountOfferSettings;
