/**
 * @generated SignedSource<<9a7be63a63e3c8b19dd914092f110b07>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AuctionBidStatus = "APPROVED" | "INCOMPLETE" | "PENDING" | "SUSPENDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type VerifyBidderView_user$data = {
  readonly addresses: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"VerifyBidderView_address">;
      };
    }>;
  } | null | undefined;
  readonly auctionBidStatus: AuctionBidStatus;
  readonly paymentMethods: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly paymentSheetSetupIntent: {
    readonly setupIntent: string;
  };
  readonly " $fragmentType": "VerifyBidderView_user";
};
export type VerifyBidderView_user$key = {
  readonly " $data"?: VerifyBidderView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"VerifyBidderView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VerifyBidderView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auctionBidStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentSheet",
      "kind": "LinkedField",
      "name": "paymentSheetSetupIntent",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "setupIntent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethods",
      "kind": "LinkedField",
      "name": "paymentMethods",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "isDefault": "DESC"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "isDefault": true,
            "type": "SHIPPING"
          }
        }
      ],
      "concreteType": "AddressesConnection",
      "kind": "LinkedField",
      "name": "addresses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AddressesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "VerifyBidderView_address"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "addresses(first:1,orderBy:{\"isDefault\":\"DESC\"},where:{\"isDefault\":true,\"type\":\"SHIPPING\"})"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "c9513c3be045b78862f55ebc2e69a2b4";

export default node;
