/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ErrorResponse } from "src/app/types/RelayTypes";
import type { PersonalInfoForm_user$key } from "src/types/__generated__/PersonalInfoForm_user.graphql";
import type { PersonalInfoFormMutation } from "src/types/__generated__/PersonalInfoFormMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { defaultRules, useValidation } from "react-simple-form-validator";

import { useDefaultValidationErrorMessages } from "src/hooks";
import {
  ButtonType,
  SBButton,
  SBErrorMessage,
  SBSuccessMessage,
  SBTextInput,
  TextInputType,
} from "src/sbxui";

const NAME_RE = /^[A-Za-z\u00C0-\u017F\u1E00-\u1EFF '\u2019-]+(?:.)?$/u;

type Props = Readonly<{
  queryKey: PersonalInfoForm_user$key;
}>;

const PersonalInfoForm = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();
  const user = useFragment(
    graphql`
      fragment PersonalInfoForm_user on User {
        firstName
        lastName
        emailAddress
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] = useMutation<PersonalInfoFormMutation>(graphql`
    mutation PersonalInfoFormMutation($input: UpdateUserProfileInfoInput!) {
      updateUserProfileInfo(updateUserProfileInfoInput: $input) {
        ...PersonalInfoForm_user
        ...ProfileLinkView_user
      }
    }
  `);

  const { firstName, lastName, emailAddress } = user ?? {};

  const [newGivenName, setNewGivenName] = useState<string>(firstName ?? "");
  const [newFamilyName, setNewFamilyName] = useState<string>(lastName ?? "");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [touchedFields, setTouchedFields] = useState({
    newFamilyName: false,
    newGivenName: false,
  });

  const defaultValidationErrorMessages = useDefaultValidationErrorMessages();

  const { getErrorsInField, isFieldInError, isFormValid } = useValidation({
    fieldsRules: {
      email: { customEmailRule: true, required: true },
      newFamilyName: { customNameRule: true, required: true },
      newGivenName: { customNameRule: true, required: true },
    },
    labels: {
      email: t("signup.errors.labels.email"),
      newFamilyName: t("signup.errors.labels.newFamilyName"),
      newGivenName: t("signup.errors.labels.newGivenName"),
    },
    messages: {
      // We have our own library for localization, so we just use en here.
      en: {
        ...defaultValidationErrorMessages,
        customEmailRule: t("signup.errors.email"),
        customNameRule: t("signup.errors.name"),
      },
    },
    rules: {
      ...defaultRules,
      customNameRule: NAME_RE,
    },
    state: { newFamilyName, newGivenName },
  });

  const handleBlurField = (
    event: React.FocusEvent<HTMLInputElement>,
    field: string,
  ) => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  const handleChangeGivenName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewGivenName(event.currentTarget.value);
  };
  const handleChangeFamilyName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewFamilyName(event.currentTarget.value);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlight) {
      return;
    }
    setTouchedFields({
      newFamilyName: !isFormValid,
      newGivenName: !isFormValid,
    });

    if (isFormValid) {
      commit({
        onCompleted() {
          setSuccessMessage(t("account.success.PERSONAL_INFORMATION"));
        },
        onError(error: unknown) {
          const errorResponse = error as ErrorResponse;
          let message =
            errorResponse?.res?.errors?.[0].message[0] ??
            t("signup.errors.default");
          if (
            errorResponse?.res?.errors?.[0]?.extensions?.exception?.status ===
            429
          ) {
            message = t("signup.errors.http429");
          }
          setErrorMessage(message);
        },
        variables: {
          input: {
            firstName: newGivenName,
            lastName: newFamilyName,
          },
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <SBErrorMessage message={errorMessage} style={styles.message} />
      <SBSuccessMessage message={successMessage} style={styles.message} />
      <fieldset {...stylex.props(styles.formFields)}>
        <div {...stylex.props(styles.nameFields)}>
          <SBTextInput
            disabled={isInFlight}
            errorMessage={
              touchedFields.newGivenName
                ? isFieldInError("newGivenName") &&
                  getErrorsInField("newGivenName")[0]
                : null
            }
            id="newGivenName"
            label={t("signup.field.firstName")}
            placeholder={t("signup.field.firstName")}
            style={[styles.input, styles.inputFirst]}
            type={TextInputType.Text}
            value={newGivenName}
            onBlur={(event) => {
              handleBlurField(event, "newGivenName");
            }}
            onChange={handleChangeGivenName}
          />
          <SBTextInput
            disabled={isInFlight}
            errorMessage={
              touchedFields.newFamilyName
                ? isFieldInError("newFamilyName") &&
                  getErrorsInField("newFamilyName")[0]
                : null
            }
            id="newFamilyName"
            label={t("signup.field.lastName")}
            placeholder={t("signup.field.lastName")}
            style={styles.input}
            type={TextInputType.Text}
            value={newFamilyName}
            onBlur={(event) => {
              handleBlurField(event, "newFamilyName");
            }}
            onChange={handleChangeFamilyName}
          />
        </div>
        <div {...stylex.props(styles.emailField)}>
          <SBTextInput
            disabled={true}
            id="emailAddress"
            label={t("account.field.emailAddress")}
            type={TextInputType.Text}
            value={emailAddress ?? ""}
            onChange={() => {
              return;
            }}
          />
        </div>
        <div {...stylex.props(styles.button)}>
          <SBButton
            disabled={
              isInFlight ||
              !isFormValid ||
              (newFamilyName === lastName && newGivenName === firstName)
            }
            loading={isInFlight}
            title={t("account.buttons.PERSONAL_INFORMATION")}
            type={ButtonType.Submit}
          />
        </div>
      </fieldset>
    </form>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  emailField: {
    marginBottom: 16,
  },
  formFields: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  input: {
    marginBottom: 16,
  },
  inputFirst: {
    marginRight: 16,
  },
  message: {
    marginBottom: 16,
  },
  nameFields: {
    display: "flex",
    justifyContent: "space-between",
  },
});

export default PersonalInfoForm;
