/**
 * @generated SignedSource<<40dc2894aee76f32a2825743b5b39b1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesSellerAccountOffersQuery$variables = Record<PropertyKey, never>;
export type QueriesSellerAccountOffersQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"SellerAccountOffersView_user">;
    } | null | undefined;
  };
};
export type QueriesSellerAccountOffersQuery = {
  response: QueriesSellerAccountOffersQuery$data;
  variables: QueriesSellerAccountOffersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "updatedAt": "DESC"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "status": [
        "IN_REVIEW",
        "ACCEPTED"
      ]
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSellerAccountOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SellerAccountOffersView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesSellerAccountOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v0/*: any*/),
                "concreteType": "SellerProductsWithOffersConnection",
                "kind": "LinkedField",
                "name": "productsWithOffers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "currency",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ProductOfferCounts",
                            "kind": "LinkedField",
                            "name": "offerCounts",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "received",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "first",
                                "value": 1
                              }
                            ],
                            "concreteType": "ImagesConnection",
                            "kind": "LinkedField",
                            "name": "images",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ImagesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "quality",
                                            "value": 100
                                          },
                                          {
                                            "kind": "Literal",
                                            "name": "webp",
                                            "value": true
                                          },
                                          {
                                            "kind": "Literal",
                                            "name": "width",
                                            "value": 300
                                          }
                                        ],
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": "url(quality:100,webp:true,width:300)"
                                      },
                                      (v1/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": "images(first:1)"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ComicDetail",
                            "kind": "LinkedField",
                            "name": "comicDetails",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "number",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "gradingAuthority",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "grade",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": "productsWithOffers(first:20,orderBy:{\"updatedAt\":\"DESC\"},where:{\"status\":[\"IN_REVIEW\",\"ACCEPTED\"]})"
              },
              {
                "alias": null,
                "args": (v0/*: any*/),
                "filters": [
                  "orderBy",
                  "where"
                ],
                "handle": "connection",
                "key": "SellerAccountOffersView_productsWithOffers",
                "kind": "LinkedHandle",
                "name": "productsWithOffers"
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0345c51acd854b1aefcd4502fe17fb49",
    "id": null,
    "metadata": {},
    "name": "QueriesSellerAccountOffersQuery",
    "operationKind": "query",
    "text": "query QueriesSellerAccountOffersQuery {\n  viewer {\n    user {\n      ...SellerAccountOffersView_user\n      id\n    }\n  }\n}\n\nfragment SellerAccountOffersView_user on User {\n  productsWithOffers(first: 20, orderBy: {updatedAt: DESC}, where: {status: [IN_REVIEW, ACCEPTED]}) {\n    edges {\n      node {\n        id\n        ...SellerAccountProductOfferCard_product\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment SellerAccountProductOfferCard_product on Product {\n  id\n  price\n  currency\n  offerCounts {\n    received\n  }\n  images(first: 1) {\n    edges {\n      node {\n        url(quality: 100, width: 300, webp: true)\n        id\n      }\n    }\n  }\n  comicDetails {\n    title\n    number\n    gradingAuthority\n    grade\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "116ccd6a890b2eb828924302c150bedc";

export default node;
