/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

export enum CategoryRollupEnum {
  QUALIFIED = "QUALIFIED",
  RESTORED = "RESTORED",
  SIGNATURE = "SIGNATURE",
  UNIVERSAL = "UNIVERSAL",
}
