/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionSearchView_auctionSearch$key } from "src/types/__generated__/AuctionSearchView_auctionSearch.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRef } from "react";
import { usePaginationFragment } from "react-relay";

import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator } from "src/sbxui";

import AuctionSearchHeading from "./AuctionSearchHeading";
import AuctionSearchItem from "./AuctionSearchItem";
import AuctionSearchSeeMore from "./AuctionSearchSeeMore";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AuctionSearchView_auctionSearch$key;
}>;

const AuctionSearchView = ({ queryKey }: Props): React.ReactNode => {
  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(
    graphql`
      fragment AuctionSearchView_auctionSearch on Query
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 10 }
        cursor: { type: "String" }
        auctionType: { type: "[AuctionType!]", defaultValue: null }
      )
      @refetchable(queryName: "AuctionSearchViewPaginationQuery") {
        auctionSearch(
          first: $count
          after: $cursor
          where: { type: $auctionType }
        ) @connection(key: "AuctionSearchView_auctionSearch") {
          edges {
            node {
              ...AuctionSearchHeading_auction
              id
              title
              type
              biddingStartsAt
              lastLotClosesAt
              lots(first: 4, orderBy: { currentBidAmount: DESC }) {
                edges {
                  node {
                    id
                    ...AuctionSearchItem_auctionLot
                  }
                }
              }
            }
          }
        }
      }
    `,
    queryKey,
  );

  const auctions = data.auctionSearch?.edges ?? [];

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(10);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  return (
    <ul {...stylex.props(styles.auctions)}>
      {auctions.map(({ node }) => (
        <div key={node.id}>
          {node.lots.edges.length > 0 ? (
            <>
              <AuctionSearchHeading queryKey={node} />{" "}
              <ul {...stylex.props(styles.lots)}>
                {(node.lots.edges ?? []).map((edge) => (
                  <AuctionSearchItem key={edge.node.id} queryKey={edge.node} />
                ))}
              </ul>
              <div {...stylex.props(styles.seeMore)}>
                <AuctionSearchSeeMore auctionId={node.id} />
              </div>
            </>
          ) : null}
        </div>
      ))}
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </ul>
  );
};

const styles = stylex.create({
  auctions: {
    margin: 0,
    marginBottom: 96,
    padding: 0,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  lots: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(4, 1fr)",
      default: "repeat(4, 1fr)",
    },
    margin: 0,

    padding: 0,
  },
  seeMore: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 64,
    marginTop: 20,
  },
});

export default AuctionSearchView;
