/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesPortfolioQuery } from "src/types/__generated__/QueriesPortfolioQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { PortfolioQuery } from "src/app/router/Queries";

import PortfolioView from "./PortfolioView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesPortfolioQuery>;
}>;

const PortfolioContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(PortfolioQuery, queryRef);

  return <PortfolioView queryKey={queryKey.viewer.user} />;
};

export default PortfolioContent;
