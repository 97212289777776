/**
 * @generated SignedSource<<56893b11aa0f917f5442d75ba80b93ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSellerMinOfferPercentageInput = {
  minOfferPercentage: number;
  userId: string;
};
export type SellerAccountOfferSettingsViewMutation$variables = {
  input: UpdateSellerMinOfferPercentageInput;
};
export type SellerAccountOfferSettingsViewMutation$data = {
  readonly updateSellerMinOfferPercentage: {
    readonly minOfferPercentage: number;
    readonly " $fragmentSpreads": FragmentRefs<"SellerAccountOfferSettingsView_user">;
  };
};
export type SellerAccountOfferSettingsViewMutation = {
  response: SellerAccountOfferSettingsViewMutation$data;
  variables: SellerAccountOfferSettingsViewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "updateSellerMinOfferPercentageInput",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minOfferPercentage",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SellerAccountOfferSettingsViewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateSellerMinOfferPercentage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SellerAccountOfferSettingsView_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SellerAccountOfferSettingsViewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateSellerMinOfferPercentage",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "161ae4b63ad18f93edfe4cca7fcdd238",
    "id": null,
    "metadata": {},
    "name": "SellerAccountOfferSettingsViewMutation",
    "operationKind": "mutation",
    "text": "mutation SellerAccountOfferSettingsViewMutation(\n  $input: UpdateSellerMinOfferPercentageInput!\n) {\n  updateSellerMinOfferPercentage(updateSellerMinOfferPercentageInput: $input) {\n    minOfferPercentage\n    ...SellerAccountOfferSettingsView_user\n    id\n  }\n}\n\nfragment SellerAccountOfferSettingsView_user on User {\n  id\n  minOfferPercentage\n}\n"
  }
};
})();

(node as any).hash = "d011a51abf71c8af891c3df2bd23b51c";

export default node;
