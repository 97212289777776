/**
 * @generated SignedSource<<6d447812603642492d3d1c5866dfaccd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AuctionType = "COMMUNITY" | "CURATED" | "%future added value";
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuctionItem_auctionLot$data = {
  readonly auction: {
    readonly biddingStartsAt: string;
    readonly type: AuctionType;
  };
  readonly closesAt: string | null | undefined;
  readonly currentBidAmount: number;
  readonly currentBidCurrency: Currency;
  readonly id: string;
  readonly isClosed: boolean;
  readonly isViewerBidding: boolean;
  readonly isViewerHighBidder: boolean;
  readonly isViewerOwner: boolean;
  readonly isViewerWatching: boolean;
  readonly product: {
    readonly comicDetails: {
      readonly grade: number | null | undefined;
      readonly gradingAuthority: GradingAuthority;
      readonly number: string;
      readonly title: string;
    } | null | undefined;
    readonly fmvScore: {
      readonly currency: Currency | null | undefined;
      readonly maxValue: number | null | undefined;
      readonly minValue: number | null | undefined;
    } | null | undefined;
    readonly images: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly url: string;
        };
      }>;
    } | null | undefined;
  } | null | undefined;
  readonly shortDescription: string | null | undefined;
  readonly startingAmount: number;
  readonly " $fragmentType": "AuctionItem_auctionLot";
};
export type AuctionItem_auctionLot$key = {
  readonly " $data"?: AuctionItem_auctionLot$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionItem_auctionLot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionItem_auctionLot",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isClosed",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "closesAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentBidAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentBidCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerBidding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerHighBidder",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerWatching",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startingAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Auction",
      "kind": "LinkedField",
      "name": "auction",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "biddingStartsAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ComicDetail",
          "kind": "LinkedField",
          "name": "comicDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grade",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gradingAuthority",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FairMarketValueScore",
          "kind": "LinkedField",
          "name": "fmvScore",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minValue",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ImagesConnection",
          "kind": "LinkedField",
          "name": "images",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImagesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "quality",
                          "value": 100
                        },
                        {
                          "kind": "Literal",
                          "name": "webp",
                          "value": true
                        },
                        {
                          "kind": "Literal",
                          "name": "width",
                          "value": 1000
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": "url(quality:100,webp:true,width:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuctionLot",
  "abstractKey": null
};

(node as any).hash = "5440a0f8bb70d1610644a75dc447011e";

export default node;
