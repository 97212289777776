/**
 * @generated SignedSource<<f22c64fa98916e147c5962753bf4485f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountInvoicesView_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountInvoicesActive_user" | "AccountInvoicesClosed_user">;
  readonly " $fragmentType": "AccountInvoicesView_user";
};
export type AccountInvoicesView_user$key = {
  readonly " $data"?: AccountInvoicesView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountInvoicesView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountInvoicesView_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountInvoicesActive_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountInvoicesClosed_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "62a8edff2c54477f90971489f86504ee";

export default node;
