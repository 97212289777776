/**
 * @generated SignedSource<<4da2b75af8c351b78d28c95e271fad5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type OfferStatus = "ACCEPTED" | "DECLINED" | "EXPIRED" | "IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountOfferCard_offer$data = {
  readonly createdAt: string;
  readonly currency: Currency;
  readonly id: string;
  readonly price: number;
  readonly product: {
    readonly comicDetails: {
      readonly grade: number | null | undefined;
      readonly gradingAuthority: GradingAuthority;
      readonly number: string;
      readonly title: string;
    } | null | undefined;
    readonly images: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly url: string;
        };
      }>;
    } | null | undefined;
  };
  readonly status: OfferStatus;
  readonly " $fragmentType": "AccountOfferCard_offer";
};
export type AccountOfferCard_offer$key = {
  readonly " $data"?: AccountOfferCard_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountOfferCard_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountOfferCard_offer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "ImagesConnection",
          "kind": "LinkedField",
          "name": "images",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImagesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "quality",
                          "value": 100
                        },
                        {
                          "kind": "Literal",
                          "name": "webp",
                          "value": true
                        },
                        {
                          "kind": "Literal",
                          "name": "width",
                          "value": 300
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": "url(quality:100,webp:true,width:300)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "images(first:1)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ComicDetail",
          "kind": "LinkedField",
          "name": "comicDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gradingAuthority",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grade",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Offer",
  "abstractKey": null
};

(node as any).hash = "dd38120fa949da6b29f88212377d11c7";

export default node;
