/**
 * @generated SignedSource<<8e41aa74052f27547216896d32d70ff8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type OfferState = "COUNTER_FROM_BUYER_ACCEPTED" | "COUNTER_FROM_BUYER_DECLINED" | "COUNTER_FROM_BUYER_EXPIRED" | "COUNTER_FROM_BUYER_IN_REVIEW" | "COUNTER_FROM_SELLER_ACCEPTED" | "COUNTER_FROM_SELLER_DECLINED" | "COUNTER_FROM_SELLER_EXPIRED" | "COUNTER_FROM_SELLER_IN_REVIEW" | "FAVORITE_ACCEPTED" | "FAVORITE_DECLINED" | "FAVORITE_EXPIRED" | "FAVORITE_IN_REVIEW" | "STANDARD_ACCEPTED" | "STANDARD_DECLINED" | "STANDARD_EXPIRED" | "STANDARD_IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsBuyerInReview_product$data = {
  readonly currency: Currency;
  readonly id: string;
  readonly inReviewOffers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currency: Currency;
        readonly expiresAt: string;
        readonly id: string;
        readonly price: number;
        readonly state: OfferState;
      };
    }>;
  } | null | undefined;
  readonly minOfferPercentage: number;
  readonly minOfferPrice: number | null | undefined;
  readonly price: number;
  readonly " $fragmentType": "ProductDetailActionsBuyerInReview_product";
};
export type ProductDetailActionsBuyerInReview_product$key = {
  readonly " $data"?: ProductDetailActionsBuyerInReview_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsBuyerInReview_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsBuyerInReview_product",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPercentage",
      "storageKey": null
    },
    {
      "alias": "inReviewOffers",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "expiresAt": "DESC"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "status": "IN_REVIEW"
          }
        }
      ],
      "concreteType": "ProductOffersConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductOffersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Offer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expiresAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "state",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "offers(first:1,orderBy:{\"expiresAt\":\"DESC\"},where:{\"status\":\"IN_REVIEW\"})"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "f8751b73341cbe66918d0d93aec4581b";

export default node;
