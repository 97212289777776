/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { HomeView_verticalSearch$key } from "src/types/__generated__/HomeView_verticalSearch.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";

import SearchResultCard from "src/app/components/search-result-card/SearchResultCard";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: HomeView_verticalSearch$key;
}>;

const HomeView = ({ queryKey }: Props) => {
  const data = useFragment(
    graphql`
      fragment HomeView_verticalSearch on Query
      @argumentDefinitions(
        categoryRollups: { type: "[CategoryRollup!]" }
        count: { type: "Int", defaultValue: 24 }
        cursor: { type: "String", defaultValue: "" }
        gradeMax: { type: Float, defaultValue: null }
        gradeMin: { type: Float, defaultValue: null }
        gradingAuthorities: { type: "[GradingAuthority!]" }
        priceMax: { type: Int, defaultValue: null }
        priceMin: { type: Int, defaultValue: null }
        salesChannel: { type: "[SalesChannel!]", defaultValue: [AUCTION, SHOP] }
        searchTerm: { type: "String", defaultValue: null }
        shopUrl: { type: "String", defaultValue: null }
        specialCopies: { type: "[SpecialCopy!]" }
        vertical: { type: "Vertical", defaultValue: COMIC }
        yearMax: { type: Int, defaultValue: null }
        yearMin: { type: Int, defaultValue: null }
        orderBy: {
          type: VerticalSearchOrderByInput
          defaultValue: { createdAt: DESC }
        }
      ) {
        __id
        verticalSearch(
          vertical: $vertical
          after: $cursor
          first: $count
          orderBy: $orderBy
          where: {
            categoryRollups: $categoryRollups
            gradeMax: $gradeMax
            gradeMin: $gradeMin
            gradingAuthorities: $gradingAuthorities
            priceMax: $priceMax
            priceMin: $priceMin
            salesChannel: $salesChannel
            searchTerm: $searchTerm
            shopUrl: $shopUrl
            specialCopies: $specialCopies
            yearMax: $yearMax
            yearMin: $yearMin
          }
        ) {
          edges {
            node {
              id
              ...SearchResultCard_typesenseSearch
            }
          }
        }
      }
    `,
    queryKey,
  );

  const connectionId = data.__id;

  const edges = data?.verticalSearch?.edges ?? [];

  return (
    <ul {...stylex.props(styles.products)}>
      {edges.map(({ node }) => (
        <SearchResultCard
          key={node.id}
          connectionId={connectionId}
          queryKey={node}
        />
      ))}
    </ul>
  );
};

const styles = stylex.create({
  products: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    margin: 0,
    padding: 0,
  },
});

export default HomeView;
