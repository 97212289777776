/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

const getProductTitle = ({
  title,
  number,
}: {
  number: string;
  title: string;
}): string => {
  return `${title}\u00a0#${number}`;
};

const getProductGrade = ({
  grade,
  gradingAuthority,
}: {
  grade: number;
  gradingAuthority: string;
}): string => {
  return `${gradingAuthority} ${grade.toFixed(1)}`;
};

export { getProductGrade, getProductTitle };
