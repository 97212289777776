/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// TODO Re-enable this rule once the invoice page is complete.

import type { InvoiceAddress_invoice$key } from "src/types/__generated__/InvoiceAddress_invoice.graphql";
import type { InvoiceAddress_user$key } from "src/types/__generated__/InvoiceAddress_user.graphql";
import type { InvoiceAddressCreateAddressMutation } from "src/types/__generated__/InvoiceAddressCreateAddressMutation.graphql";
import type { Country } from "src/types/__generated__/InvoiceView_invoice.graphql";
import type { Address } from "src/types/Address";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ConnectionHandler,
  useFragment,
  useMutation,
  usePaginationFragment,
} from "react-relay";

import AddressForm from "src/app/components/address-form/AddressForm";
import {
  HeadingLevel,
  SBActivityIndicator,
  SBButton,
  SBErrorMessage,
  SBHeading,
  SBIcon,
  SBLink,
  SBParagraph,
  SBRadioButtons,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { countryFromLanguage } from "src/utils";

type Props = Readonly<{
  disabled: boolean;
  onChangeAddress: (id: string | null | undefined, country: Country) => void;
  onSelectingAddress: (selecting: boolean) => void;
  payable: boolean;
  queryKey: InvoiceAddress_invoice$key;
  queryKeyUser: InvoiceAddress_user$key;
  shipToCountry: Country;
  shipToId: string | null | undefined;
}>;

const InvoiceAddress = ({
  disabled,
  onChangeAddress,
  onSelectingAddress,
  payable,
  queryKey,
  queryKeyUser,
  shipToCountry,
  shipToId,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const country = countryFromLanguage(i18n.language);

  const [commitCreateAddress, isInFlightCreateAddress] =
    useMutation<InvoiceAddressCreateAddressMutation>(graphql`
      mutation InvoiceAddressCreateAddressMutation(
        $input: CreateAddressInput!
      ) {
        createAddress(createAddressInput: $input) {
          address1
          address2
          country
          id
          isDefault
          locality
          name
          postalCode
          region
          type
        }
      }
    `);

  const { data, loadNext, hasNext, isLoadingNext } = usePaginationFragment(
    graphql`
      fragment InvoiceAddress_user on User
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 5 }
      )
      @refetchable(queryName: "InvoiceAddressPaginationQuery") {
        __id
        addresses(
          after: $cursor
          first: $count
          orderBy: { isDefault: DESC }
          where: { type: SHIPPING }
        ) @connection(key: "InvoiceAddress_addresses") {
          edges {
            node {
              address1
              address2
              country
              id
              isDefault
              locality
              name
              postalCode
              region
              type
            }
          }
        }
        firstName
        id
        lastName
      }
    `,
    queryKeyUser,
  );

  const defaultAddress = data.addresses?.edges?.[0]?.node;
  const selectedAddress =
    (data.addresses?.edges ?? []).find(({ node }) => node.id === shipToId)
      ?.node ?? defaultAddress;

  const initialId = shipToId ?? defaultAddress?.id;
  const initialCountry = shipToCountry ?? defaultAddress?.country ?? country;

  const connectionId = data.__id;

  const userId = data.id;

  const addresses = data.addresses?.edges ?? [];

  const shippingAddress = useFragment(
    graphql`
      fragment InvoiceAddress_invoice on Invoice {
        address1
        address2
        country
        familyName
        givenName
        locality
        postalCode
        region
      }
    `,
    queryKey,
  );

  const [shouldShowAddresses, setShouldShowAddresses] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(initialCountry);
  const [selectedAddressId, setSelectedAddressId] = useState(initialId);

  const [addressErrorMessage, setAddressErrorMessage] = useState<string | null>(
    null,
  );

  const handleClickSelect = () => {
    if (disabled) {
      return;
    }
    setShouldShowAddresses(true);
    onSelectingAddress(true);
  };

  const handleClickConfirm = () => {
    setShouldShowAddresses(false);
    onSelectingAddress(false);
  };

  const handleClickMore = () => {
    if (!hasNext || isLoadingNext) {
      return;
    }
    loadNext(5);
  };

  const handleChange = (
    value: string,
    _event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newShipToCountry: Country =
      addresses.find(({ node }) => node.id === value)?.node.country ??
      initialCountry;
    setSelectedCountry(newShipToCountry);
    setSelectedAddressId(value);
  };

  const handleCreateAddress = (address: Address) => {
    if (connectionId == null) {
      return;
    }
    commitCreateAddress({
      onCompleted(response) {
        setSelectedCountry(response?.createAddress.country ?? initialCountry);
        setSelectedAddressId(response?.createAddress.id);
      },
      onError(error) {
        setAddressErrorMessage(error.message);
      },
      updater(proxyStore, response) {
        const record = proxyStore.get(connectionId);
        if (record == null) {
          return;
        }

        const addressBookConnection = ConnectionHandler.getConnection(
          record,
          "InvoiceAddress_addresses",
          {
            orderBy: {
              isDefault: "DESC",
            },
            where: {
              type: "SHIPPING",
            },
          },
        );

        if (addressBookConnection == null) {
          return;
        }

        const newAddressId = response?.createAddress.id;
        if (newAddressId == null) {
          return;
        }

        const newAddress = proxyStore.get(newAddressId);
        if (newAddress == null) {
          return;
        }

        const edge = ConnectionHandler.createEdge(
          proxyStore,
          addressBookConnection,
          newAddress,
          "AddressesEdge",
        );
        if (edge == null) {
          return;
        }

        ConnectionHandler.insertEdgeBefore(addressBookConnection, edge);
      },
      variables: {
        input: {
          address1: address.address1,
          address2: address.address2 === "" ? null : address.address2,
          country: address.country,
          familyName: address.familyName,
          givenName: address.givenName,
          isDefault: address.isDefault,
          locality: address.locality,
          name: address.addressName,
          postalCode: address.postalCode,
          region: address.region,
          type: address.type,
          userId: userId ?? "",
        },
      },
    });
  };

  useEffect(() => {
    if (!shouldShowAddresses) {
      onChangeAddress(selectedAddressId, selectedCountry);
    }
  }, [
    onChangeAddress,
    selectedAddressId,
    selectedCountry,
    shouldShowAddresses,
  ]);

  let displayAddress: Address | null = null;
  if (
    shippingAddress.familyName != null &&
    shippingAddress.givenName != null &&
    shippingAddress.address1 != null &&
    shippingAddress.locality != null &&
    shippingAddress.region != null &&
    shippingAddress.postalCode != null &&
    shippingAddress.country != null
  ) {
    displayAddress = {
      address1: shippingAddress.address1,
      address2: shippingAddress.address2,
      addressName: "",
      country: shippingAddress.country,
      familyName: shippingAddress.familyName,
      givenName: shippingAddress.givenName,
      isDefault: false,
      locality: shippingAddress.locality,
      postalCode: shippingAddress.postalCode,
      region: shippingAddress.region,
      type: "SHIPPING",
    };
  } else if (
    selectedAddress != null &&
    data.firstName != null &&
    data.lastName != null &&
    payable
  ) {
    displayAddress = {
      address1: selectedAddress.address1,
      address2: selectedAddress.address2,
      addressName: selectedAddress.name,
      country: selectedAddress.country,
      familyName: data.lastName,
      givenName: data.firstName,
      isDefault: selectedAddress.isDefault,
      locality: selectedAddress.locality,
      postalCode: selectedAddress.postalCode,
      region: selectedAddress.region,
      type: selectedAddress.type,
    };
  }

  let content = (
    <div {...stylex.props(auto, styles.content)}>
      {displayAddress != null && (
        <div>
          {displayAddress.addressName !== "" && (
            <SBParagraph style={styles.addressName}>
              {displayAddress.isDefault
                ? t("account.addresses.default", {
                    addressName: displayAddress.addressName,
                  })
                : displayAddress.addressName}
            </SBParagraph>
          )}
          <SBParagraph style={styles.address}>
            {displayAddress.address1}
          </SBParagraph>
          {displayAddress.address2 != null && (
            <SBParagraph style={styles.address}>
              {displayAddress.address2}
            </SBParagraph>
          )}
          <SBParagraph style={styles.address}>
            {t("invoice.ship-from.format", {
              locality: displayAddress.locality,
              postalCode: displayAddress.postalCode,
              region: displayAddress.region,
            })}
          </SBParagraph>
          {displayAddress.country != null &&
            displayAddress.country !== country && (
              <SBParagraph>
                {t(`countries.${kebabCase(displayAddress.country)}`)}
              </SBParagraph>
            )}
        </div>
      )}
      {payable && addresses.length > 1 ? (
        <SBParagraph style={styles.link}>
          <SBLink
            disabled={disabled}
            style={styles.linkText}
            onClick={handleClickSelect}
          >
            {t("invoice.address.edit")}
            <SBIcon
              containerStyle={styles.iconContainer}
              icon="edit"
              style={styles.icon}
            />
          </SBLink>
        </SBParagraph>
      ) : null}
    </div>
  );

  if (addresses.length < 1) {
    content = (
      <div {...stylex.props(auto, styles.content)}>
        <SBErrorMessage message={addressErrorMessage} style={styles.error} />
        <AddressForm
          initialDefault={true}
          loading={isInFlightCreateAddress}
          style={styles.form}
          onSubmit={handleCreateAddress}
        />
      </div>
    );
  }
  if (shouldShowAddresses) {
    content = (
      <div {...stylex.props(auto, styles.content)}>
        <div {...stylex.props(styles.radioButtons)}>
          <SBRadioButtons
            id="address"
            options={addresses.map(({ node }) => ({
              disabled: isLoadingNext,
              label: (
                <div>
                  {node.name != null && (
                    <SBParagraph style={styles.addressName}>
                      {node.isDefault
                        ? t("account.addresses.default", {
                            addressName: node.name,
                          })
                        : node.name}
                    </SBParagraph>
                  )}
                  <SBParagraph style={styles.address}>
                    {node.address1}
                  </SBParagraph>
                  {node.address2 != null && (
                    <SBParagraph style={styles.address}>
                      {node.address2}
                    </SBParagraph>
                  )}
                  <SBParagraph style={styles.address}>
                    {t("invoice.ship-from.format", {
                      locality: node.locality,
                      postalCode: node.postalCode,
                      region: node.region,
                    })}
                  </SBParagraph>
                  {node.country != null && node.country !== country && (
                    <SBParagraph>
                      {t(`countries.${kebabCase(node.country)}`)}
                    </SBParagraph>
                  )}
                </div>
              ),
              value: node.id,
            }))}
            value={selectedAddressId ?? ""}
            onChange={handleChange}
          />
          {hasNext ? (
            <SBParagraph style={styles.more}>
              <SBLink onClick={handleClickMore}>
                {t("invoice.address.more")}
              </SBLink>
              {isLoadingNext ? (
                <SBActivityIndicator
                  containerStyle={styles.loading}
                  small={true}
                />
              ) : null}
            </SBParagraph>
          ) : null}
          <SBButton
            style={styles.addressButton}
            title={t("invoice.address.confirm")}
            onClick={handleClickConfirm}
          />
        </div>
      </div>
    );
  }

  return (
    <div {...stylex.props(styles.root)}>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("invoice.address.title")}
      </SBHeading>
      {content}
    </div>
  );
};

const styles = stylex.create({
  addAddress: {
    height: 24,
    lineHeight: 1.5,
    marginBottom: 0,
  },
  address: {
    marginBottom: 0,
  },
  addressButton: {
    marginTop: 16,
  },
  addressName: {
    fontWeight: 600,
    marginBottom: 0,
  },
  button: {
    alignSelf: "flex-end",
    marginTop: 16,
  },
  content: {
    alignItems: "flex-start",
    borderColor: colors.tableBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    display: "flex",
    justifyContent: "space-between",
    padding: 16,
  },
  error: {
    marginBottom: 16,
  },
  form: {
    width: "100%",
  },
  heading: {
    fontSize: 28,
    marginBottom: 8,
  },
  icon: {
    color: colors.color,
  },
  iconContainer: {
    marginLeft: 8,
  },
  link: {
    alignSelf: "flex-start",
    display: "flex",
    height: 24,
    marginBottom: 0,
  },
  linkText: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    textDecoration: "none",
  },
  loading: {
    marginLeft: 12,
  },
  more: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-start",
    marginBottom: 0,
    marginTop: 16,
    minHeight: 24,
  },
  noMore: {
    opacity: 0.4,
  },
  radioButtons: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginRight: 16,
  },
  root: {
    marginBottom: 24,
    marginTop: 24,
  },
});

export default InvoiceAddress;
