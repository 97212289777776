/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesShopQuery } from "src/types/__generated__/QueriesShopQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useParams } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { VerticalSearchContext } from "src/app/context/vertical-search";
import ShopSearch from "src/app/pages/shop/ShopSearch";
import {
  SBErrorBoundary,
  SBErrorPage,
  SBFallback,
  SBPageHeader,
} from "src/sbxui";
import { formatShopName } from "src/utils/_utils/formatShopName";

import ShopContent from "../shop/ShopContent";

const Seller = (): React.ReactNode | null => {
  const { t } = useTranslation();

  const pageContext = useContext(PageContext);
  const { setSearchTerm, setShopUrl, shopName } = useContext(
    VerticalSearchContext,
  );

  const { shopUrl } = useParams();

  const queryRef = useLoaderData() as PreloadedQuery<QueriesShopQuery>;

  const formattedShopName = formatShopName(shopName, shopUrl);

  useEffect(() => {
    pageContext?.setTitle(
      t("shop.document-title-with-shop-url", {
        shopUrl: formattedShopName?.toLocaleUpperCase(),
      }),
    );
    if (shopUrl != null) {
      setShopUrl(shopUrl);
      setSearchTerm(`seller:${encodeURIComponent(shopUrl)}`);
    }
  }, [pageContext, setSearchTerm, setShopUrl, shopUrl, formattedShopName, t]);

  if (shopUrl == null) {
    return (
      <SBErrorPage
        description={t("not-found.description")}
        errorCode={404}
        title={t("not-found.page-title")}
      />
    );
  }

  if (queryRef == null) {
    return null;
  }

  return (
    <>
      <div {...stylex.props(styles.search)}>
        <ShopSearch />
      </div>
      <SBErrorBoundary>
        <SBPageHeader
          title={t("shop.page-title-with-shop-url", {
            shopUrl: formattedShopName,
          })}
        />
        <Suspense fallback={<SBFallback />}>
          <ShopContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </>
  );
};

const styles = stylex.create({
  search: {
    marginBottom: 40,
  },
});

export default Seller;
