/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { DocumentView_document$key } from "src/types/__generated__/DocumentView_document.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useFragment } from "react-relay";

import { PageContext } from "src/app/context/page";
import { HeadingLevel, SBHeading, SBMarkdownRenderer } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: DocumentView_document$key;
}>;

const DocumentView = ({ queryKey }: Props): React.ReactNode => {
  const pageContext = useContext(PageContext);

  const data = useFragment(
    graphql`
      fragment DocumentView_document on Document {
        id
        title
        body
      }
    `,
    queryKey,
  );

  const { title, body } = data;

  useEffect(() => {
    pageContext?.setTitle(title);
  }, [title, pageContext]);

  return (
    <div {...stylex.props(styles.root)}>
      <SBHeading level={HeadingLevel.H1} style={styles.heading}>
        {title}
      </SBHeading>
      <div {...stylex.props(styles.outer)}>
        <div {...stylex.props(styles.inner)}>
          <SBMarkdownRenderer markdown={body} />
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  date: {
    marginBottom: 96,
    marginTop: 32,
    textAlign: "center",
  },
  heading: {
    marginBlock: 96,
    textAlign: "center",
  },
  inner: {
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: "50%",
    },
  },
  outer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  root: {
    marginBottom: 96,
  },
});

export default DocumentView;
