/**
 * @generated SignedSource<<2785bf9c05afb50e6edacb288cbbbd0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateProductFeedbackInput = {
  isConditionAcceptable?: boolean | null | undefined;
  isWellPacked: boolean;
  productId?: string | null | undefined;
};
export type ProductFeedbackModalCreateProblemMutation$variables = {
  input: CreateProductFeedbackInput;
};
export type ProductFeedbackModalCreateProblemMutation$data = {
  readonly createProductFeedback: {
    readonly canLeaveFeedback: boolean;
    readonly id: string;
  };
};
export type ProductFeedbackModalCreateProblemMutation = {
  response: ProductFeedbackModalCreateProblemMutation$data;
  variables: ProductFeedbackModalCreateProblemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createProductFeedbackInput",
        "variableName": "input"
      }
    ],
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "createProductFeedback",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "canLeaveFeedback",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductFeedbackModalCreateProblemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductFeedbackModalCreateProblemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4f86ae906037ebeb56701f230a989f97",
    "id": null,
    "metadata": {},
    "name": "ProductFeedbackModalCreateProblemMutation",
    "operationKind": "mutation",
    "text": "mutation ProductFeedbackModalCreateProblemMutation(\n  $input: CreateProductFeedbackInput!\n) {\n  createProductFeedback(createProductFeedbackInput: $input) {\n    id\n    canLeaveFeedback\n  }\n}\n"
  }
};
})();

(node as any).hash = "a84839d2b1fdf1600dbabc048568a4f9";

export default node;
