/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesShopQuery } from "src/types/__generated__/QueriesShopQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { ShopQuery } from "src/app/router/Queries";

import ShopView from "./ShopView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesShopQuery>;
}>;

const ShopContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(ShopQuery, queryRef);

  return <ShopView queryKey={queryKey} />;
};

export default ShopContent;
