/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { MenuItemConfig } from "src/sbxui";
import type { SalesChannel } from "src/types/__generated__/ShopPaginationQuery.graphql";

import stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  DEFAULT_SALES_CHANNEL,
  DEFAULT_SORT,
  ShopOption,
  VerticalSearchContext,
} from "src/app/context/vertical-search";
import { SELLER_PATH } from "src/app/router/Router";
import { MenuPosition, SBActivityIndicator, SBIcon, SBMenu } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import ShopFilterModal from "./ShopFilterModal";
import ShopSortModal from "./ShopSortModal";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const ShopSearch = React.memo(() => {
  const { t } = useTranslation();

  const {
    categoryRollups,
    gradeMax,
    gradeMin,
    gradingAuthorities,
    isSearching,
    priceMax,
    priceMin,
    resetSearch,
    salesChannel,
    sort,
    specialCopies,
    setSalesChannel,
    setSearchTerm,
    searchTerm,
    yearMax,
    yearMin,
  } = useContext(VerticalSearchContext);

  const [isPending, startTransition] = useTransition();

  const [term, setTerm] = useState(searchTerm);
  const [isShopSortModalOpen, setIsShopSortModalOpen] = useState(false);
  const [isShopFilterModalOpen, setIsShopFilterModalOpen] = useState(false);
  const [selected, setSelected] = useState<ShopOption>(ShopOption.All);

  const { pathname } = useLocation();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(event.currentTarget.value);
  };

  const handleSearchSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    startTransition(() => {
      setSearchTerm(term);
    });
  };

  const handleClickClear = (_event: React.SyntheticEvent) => {
    setTerm("");
    startTransition(() => {
      setSearchTerm("");
    });
  };

  const handleSortClick = (_event: React.SyntheticEvent) => {
    setIsShopSortModalOpen(true);
    setIsShopFilterModalOpen(false);
  };

  const handleFilterClick = (_event: React.SyntheticEvent) => {
    setIsShopSortModalOpen(false);
    setIsShopFilterModalOpen(true);
  };

  const handleResetClick = (_event: React.SyntheticEvent) => {
    resetSearch(false);
  };

  const handleClickSalesChannel =
    (newSalesChannel: SalesChannel[]) => (_event: React.SyntheticEvent) => {
      setSalesChannel(newSalesChannel);
    };

  useEffect(() => {
    if (pathname.startsWith(SELLER_PATH.replace(":shopUrl", ""))) {
      setTerm(searchTerm);
      startTransition(() => {
        setSearchTerm(searchTerm);
      });
    }
  }, [pathname, searchTerm, setSearchTerm]);

  useEffect(() => {
    if (salesChannel.includes("AUCTION") && salesChannel.includes("SHOP")) {
      setSelected(ShopOption.All);
    } else if (
      salesChannel.includes("AUCTION") &&
      !salesChannel.includes("SHOP")
    ) {
      setSelected(ShopOption.Auction);
    } else if (
      !salesChannel.includes("AUCTION") &&
      salesChannel.includes("SHOP")
    ) {
      setSelected(ShopOption.Shop);
    }
  }, [salesChannel]);

  let count =
    categoryRollups.length + gradingAuthorities.length + specialCopies.length;
  if (gradeMax != null || gradeMin != null) {
    count += 1;
  }
  if (priceMax != null || priceMin != null) {
    count += 1;
  }
  if (yearMax != null || yearMin != null) {
    count += 1;
  }

  let filterKey = `${priceMax == null ? "" : priceMax}${
    priceMin == null ? "" : priceMin
  }${gradeMax == null ? "" : gradeMax}${gradeMin == null ? "" : gradeMin}${yearMax == null ? "" : yearMax}${yearMin == null ? "" : yearMin}`;
  if (categoryRollups != null && categoryRollups.length > 0) {
    filterKey = `${filterKey}${categoryRollups.join(",")}`;
  }
  if (specialCopies != null && specialCopies.length > 0) {
    filterKey = `${filterKey}${specialCopies.join(",")}`;
  }
  if (gradingAuthorities != null && gradingAuthorities.length > 0) {
    filterKey = `${filterKey}${gradingAuthorities.join(",")}`;
  }

  const items: MenuItemConfig[] = [
    {
      id: "all",
      label: t("search.sales-channel.all"),
      onClick: handleClickSalesChannel(DEFAULT_SALES_CHANNEL),
    },
    {
      id: "shop",
      label: t("search.sales-channel.shop"),
      onClick: handleClickSalesChannel(["SHOP"]),
    },
    {
      id: "auction",
      label: t("search.sales-channel.auction"),
      onClick: handleClickSalesChannel(["AUCTION"]),
    },
  ];

  return (
    <div {...stylex.props(styles.root)}>
      <SBMenu
        button={
          <div {...stylex.props(styles.salesChannel)}>
            <SBIcon
              aria-hidden="true"
              containerStyle={styles.salesChannelIconContainer}
              fill={false}
              icon="inventory_2"
              style={styles.salesChannelIcon}
            />
            <span {...stylex.props(auto, styles.salesChannelLabel)}>
              {t(`search.sales-channel.${kebabCase(selected)}`)}
            </span>
          </div>
        }
        buttonLabel={t(`search.sales-channel.${kebabCase(selected)}`)}
        buttonStyle={styles.buttonStyle}
        items={items}
        position={MenuPosition.RIGHT}
      />
      <form
        {...stylex.props(auto, styles.form)}
        role="search"
        onSubmit={handleSearchSubmit}
      >
        <input
          {...stylex.props(auto, styles.input)}
          id="search"
          placeholder={t("search.placeholder")}
          type="text"
          value={term}
          onChange={handleSearchChange}
        />
        {term !== "" && (
          <button
            {...stylex.props(auto, styles.clear)}
            aria-label={t("search.clear-label")}
            type="button"
            onClick={handleClickClear}
          >
            <SBIcon fill={false} icon="cancel" style={styles.cancel} />
          </button>
        )}
        <button
          {...stylex.props(auto, styles.button)}
          aria-label={t("search.button-label")}
          disabled={isSearching || isPending}
          type="submit"
        >
          {isSearching || isPending ? (
            <SBActivityIndicator small={true} style={styles.spinner} />
          ) : (
            <SBIcon icon="search" style={styles.icon} />
          )}
        </button>
      </form>
      <div {...stylex.props(auto, styles.filterSort)}>
        <button
          {...stylex.props(auto, styles.sort)}
          aria-label={t("search.sort")}
          type="button"
          onClick={handleSortClick}
        >
          <span {...stylex.props(styles.sortLabel)}>{t("search.sort")}</span>
          <SBIcon
            aria-hidden="true"
            fill={false}
            icon="sort"
            style={styles.sortIcon}
          />
        </button>
        <button
          {...stylex.props(auto, styles.filter)}
          aria-label={t("search.filter", {
            count,
          })}
          type="button"
          onClick={handleFilterClick}
        >
          <span {...stylex.props(styles.filterLabel)}>
            {t("search.filter", {
              count,
            })}
          </span>
          <SBIcon
            aria-hidden="true"
            containerStyle={styles.filterIconContainer}
            fill={false}
            icon="filter_list"
            style={styles.filterIcon}
          />
        </button>
        {(count > 0 || sort !== DEFAULT_SORT) && (
          <button
            {...stylex.props(auto, styles.reset)}
            aria-label={t("search.reset")}
            type="button"
            onClick={handleResetClick}
          >
            <span {...stylex.props(styles.resetLabel)}>
              {t("search.reset")}
            </span>
            <SBIcon
              aria-hidden="true"
              containerStyle={styles.resetIconContainer}
              fill={false}
              icon="restart_alt"
              style={styles.resetIcon}
            />
          </button>
        )}
      </div>
      <ShopSortModal
        key={filterKey}
        isOpen={isShopSortModalOpen}
        setOpen={setIsShopSortModalOpen}
      />
      <ShopFilterModal
        key={filterKey}
        isOpen={isShopFilterModalOpen}
        setOpen={setIsShopFilterModalOpen}
      />
    </div>
  );
});

const styles = stylex.create({
  button: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: colors.topNavigationSearchButtonBackgroundColor,
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  buttonStyle: {
    whiteSpace: "nowrap",
  },
  cancel: {
    color: colors.topNavigationSearchClearColor,
  },
  clear: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  filter: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: {
      [MOBILE]: 0,
      default: 4,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  filterIcon: {
    color: colors.topNavigationSearchClearColor,
  },
  filterIconContainer: {
    marginLeft: 8,
  },
  filterLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  filterSort: {
    alignItems: "center",
    display: "flex",
  },
  form: {
    alignItems: "center",
    backgroundColor: colors.topNavigationSearchBackgroundColor,
    borderColor: colors.topNavigationSearchBorderColor,
    borderRadius: 48,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: colors.topNavigationSearchShadow,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: {
      [MOBILE]: 0,
      default: 16,
    },
    padding: 4,
    paddingLeft: 16,
    width: "100%",
  },
  icon: {
    color: colors.topNavigationSearchButtonColor,
  },
  input: {
    borderWidth: 0,
    height: 40,
    outline: {
      ":hover": "none",
      default: "none",
    },
    width: "100%",
  },
  reset: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  resetIcon: {
    color: colors.topNavigationSearchClearColor,
  },
  resetIconContainer: {
    marginLeft: 8,
  },
  resetLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  root: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  salesChannel: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    marginRight: {
      [MOBILE]: 0,
      default: 16,
    },
  },
  salesChannelIcon: {
    color: colors.topNavigationSearchClearColor,
  },
  salesChannelIconContainer: {
    marginRight: 8,
  },
  salesChannelLabel: {
    color: colors.color,
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  sort: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: {
      [MOBILE]: 0,
      default: 4,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  sortIcon: {
    color: colors.topNavigationSearchClearColor,
    marginLeft: 8,
  },
  sortLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  spinner: {
    fill: colors.topNavigationSearchButtonColor,
  },
});

export default ShopSearch;
