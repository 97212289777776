/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountAddressesQuery } from "src/types/__generated__/QueriesAccountAddressesQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AccountAddressesQuery } from "src/app/router/Queries";

import AccountAddressesView from "./AccountAddressesView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountAddressesQuery>;
}>;

const AccountAddressesContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AccountAddressesQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <AccountAddressesView queryKey={user} />;
};

export default AccountAddressesContent;
