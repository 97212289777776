/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import {
  APP_STORE_IMG,
  APP_STORE_URL,
  GOOGLE_PLAY_IMG,
  GOOGLE_PLAY_URL,
  QR_CODE_IMG,
} from "src/app/constants";
import { usePlatform } from "src/hooks";
import { HeadingLevel, SBHeading, SBImage } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const Download = (): React.ReactNode => {
  const { t } = useTranslation();

  const platform = usePlatform();

  const showAndroid = platform === "android";
  const showIphone = platform === "ios";

  const showDesktop = platform === "macos" || platform === "windows";

  return (
    <div {...stylex.props(styles.container)}>
      <SBHeading level={HeadingLevel.H1} style={styles.heading}>
        {t("home.hero-sub-text")}
      </SBHeading>
      <div {...stylex.props(styles.downloadButtons)}>
        {showDesktop ? (
          <SBImage
            alt={t("home.qr-code")}
            height={156}
            src={QR_CODE_IMG}
            width={156}
          />
        ) : null}
        {showIphone ? (
          <a
            {...stylex.props(styles.link)}
            aria-label={t("home.download-ios")}
            href={APP_STORE_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <SBImage
              alt={t("home.app-store")}
              height={64}
              src={APP_STORE_IMG}
              width={150}
            />
          </a>
        ) : null}
        {showAndroid ? (
          <a
            {...stylex.props(auto, styles.link)}
            aria-label={t("home.download-android")}
            href={GOOGLE_PLAY_URL}
            rel="noopener noreferrer"
            target="_blank"
          >
            <SBImage
              alt={t("home.google-play")}
              height={64}
              src={GOOGLE_PLAY_IMG}
              width={165}
            />
          </a>
        ) : null}
      </div>
    </div>
  );
};

const styles = stylex.create({
  container: {
    width: "100%",
  },
  downloadButtons: {
    alignItems: "center",
    display: "flex",
    marginLeft: -8,
    marginTop: 10,
  },
  heading: {
    fontSize: {
      [MOBILE]: 32,
      [TABLET]: 48,
      default: 48,
    },
  },
  link: {
    borderRadius: 8,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
});

export default React.memo(Download);
