/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountStoreLogoField_user$key } from "src/types/__generated__/SellerAccountStoreLogoField_user.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { config as GraphConfig } from "src/api/constants";
import { UserContext } from "src/app/context/user";
import {
  ButtonType,
  SBButton,
  SBErrorMessage,
  SBFileInput,
  SBImage,
} from "src/sbxui";
import makeString from "src/utils/_utils/makeString";

const LOGO_DIMENSION = 100;
const HTTP_BAD_REQUEST = 400;

const cacheBust = (url: string): string => {
  return `${url}?cache_bust=${makeString(12)}`;
};

interface GraphResponse {
  imageUrl: string;
  message: string;
  statusCode: number;
}

type Props = Readonly<{
  queryKey: SellerAccountStoreLogoField_user$key;
}>;

const SellerAccountStoreLogoField = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const data = useFragment(
    graphql`
      fragment SellerAccountStoreLogoField_user on User {
        shopLogoUrl
      }
    `,
    queryKey,
  );

  const { shopLogoUrl } = data;

  const [isUploading, setIsUploading] = useState(false);
  const [fileUpload, setFileUpload] = useState<File | null>(null);
  const [logoUrl, setLogoUrl] = useState(
    shopLogoUrl ? cacheBust(shopLogoUrl) : "",
  );
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (fileUpload == null) {
      return;
    }

    setIsUploading(true);

    const formData = new FormData();
    const url = new URL(
      `${GraphConfig.API_PROTOCOL}://${GraphConfig.API_HOST}/images/seller-logo`,
    );
    const fetchOptions = {
      body: formData,
      headers: {
        Authorization: `Bearer: ${user?.accessToken}`,
        contentType: "",
      },
      method: "POST",
    };

    formData.append("Content-Type", "multipart/form-data");
    formData.append("image", fileUpload);

    const response = await fetch(url, fetchOptions);

    const json: GraphResponse = await response.json();

    if (json?.statusCode === HTTP_BAD_REQUEST) {
      setErrorMessage(json?.message);
    } else if (json?.imageUrl) {
      setFileUpload(null);
      setLogoUrl(cacheBust(json.imageUrl));
    }

    setIsUploading(false);
  };

  const handleUploadFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const img = new Image();
    const fileList = event.currentTarget.files;

    setErrorMessage("");

    if (fileList) {
      const file = Array.from(fileList)[0];

      img.src = URL.createObjectURL(file);

      if (img.width !== img.height) {
        setErrorMessage(t("seller-account.errors.logo-dimensions"));
        return;
      }

      setFileUpload(file);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SBErrorMessage message={errorMessage} style={styles.message} />
        <fieldset {...stylex.props(styles.formFields)}>
          <div {...stylex.props(styles.nameFields)}>
            <SBFileInput
              id="newShopUrl"
              label={t("seller-account.field.store-logo")}
              showLabel={true}
              onChange={handleUploadFiles}
            />
            {logoUrl != null && (
              <SBImage
                alt={t("seller-account.store-logo.alt")}
                src={logoUrl}
                style={styles.logo}
                width={LOGO_DIMENSION}
              />
            )}
          </div>
          {fileUpload ? (
            <SBImage
              alt=""
              src={URL.createObjectURL(fileUpload)}
              width={LOGO_DIMENSION}
            />
          ) : null}
          <div {...stylex.props(styles.button)}>
            <SBButton
              disabled={fileUpload == null || isUploading}
              loading={isUploading}
              title={t("seller-account.buttons.store-logo")}
              type={ButtonType.Submit}
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  emailField: {
    marginBottom: 16,
  },
  formFields: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  input: {
    marginBottom: 16,
  },
  inputFirst: {
    marginRight: 16,
  },
  logo: {
    marginLeft: 24,
  },
  message: {
    marginBottom: 16,
  },
  nameFields: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 24,
  },
});

export default SellerAccountStoreLogoField;
