/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountFavoritesQuery } from "src/types/__generated__/QueriesAccountFavoritesQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AccountFavoritesQuery } from "src/app/router/Queries";

import AccountFavoritesView from "./AccountFavoritesView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountFavoritesQuery>;
}>;

const AccountFavoritesContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AccountFavoritesQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <AccountFavoritesView queryKey={user} />;
};

export default AccountFavoritesContent;
