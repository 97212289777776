/**
 * @generated SignedSource<<0948cc18f9843b52a3e5403b71aacb4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VerifyBidderViewSubscription$variables = Record<PropertyKey, never>;
export type VerifyBidderViewSubscription$data = {
  readonly userUpdateSubscription: {
    readonly " $fragmentSpreads": FragmentRefs<"AuctionLotView_user" | "VerifyBidderView_user">;
  };
};
export type VerifyBidderViewSubscription = {
  response: VerifyBidderViewSubscription$data;
  variables: VerifyBidderViewSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyBidderViewSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userUpdateSubscription",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "VerifyBidderView_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuctionLotView_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "VerifyBidderViewSubscription",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "userUpdateSubscription",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "auctionBidStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentSheet",
            "kind": "LinkedField",
            "name": "paymentSheetSetupIntent",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "setupIntent",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PaymentMethods",
            "kind": "LinkedField",
            "name": "paymentMethods",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 1
              },
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": {
                  "isDefault": "DESC"
                }
              },
              {
                "kind": "Literal",
                "name": "where",
                "value": {
                  "isDefault": true,
                  "type": "SHIPPING"
                }
              }
            ],
            "concreteType": "AddressesConnection",
            "kind": "LinkedField",
            "name": "addresses",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AddressesEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Address",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "givenName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "familyName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address1",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "address2",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "locality",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "region",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "postalCode",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "country",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isDefault",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__id",
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": "addresses(first:1,orderBy:{\"isDefault\":\"DESC\"},where:{\"isDefault\":true,\"type\":\"SHIPPING\"})"
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "456aa414decaeb27b243f8e097782190",
    "id": null,
    "metadata": {},
    "name": "VerifyBidderViewSubscription",
    "operationKind": "subscription",
    "text": "subscription VerifyBidderViewSubscription {\n  userUpdateSubscription {\n    ...VerifyBidderView_user\n    ...AuctionLotView_user\n    id\n  }\n}\n\nfragment AuctionLotHeader_user on User {\n  auctionBidStatus\n}\n\nfragment AuctionLotView_user on User {\n  ...AuctionLotHeader_user\n}\n\nfragment VerifyBidderView_address on Address {\n  id\n  name\n  givenName\n  familyName\n  address1\n  address2\n  locality\n  region\n  postalCode\n  country\n  isDefault\n  type\n}\n\nfragment VerifyBidderView_user on User {\n  auctionBidStatus\n  paymentSheetSetupIntent {\n    setupIntent\n  }\n  paymentMethods {\n    id\n  }\n  addresses(first: 1, orderBy: {isDefault: DESC}, where: {type: SHIPPING, isDefault: true}) {\n    edges {\n      node {\n        ...VerifyBidderView_address\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dd2d38b557216368f110e705a9e51ce2";

export default node;
