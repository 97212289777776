/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";
import type { TableDataCell } from "./SBTable";

import stylex from "@stylexjs/stylex";
import { noop } from "lodash";
import * as React from "react";
import { Fragment } from "react";

import { classNames } from "src/utils";

import { TableCellAlignment } from "./SBTable";

type Props = Readonly<{
  bgColor?: StyleXStyles;
  compact?: boolean;
  hover?: boolean;
  id: string;
  rowAuxClick?: (id: string) => void;
  rowClick?: (id: string, event: React.MouseEvent) => void;
  td: TableDataCell[];
}>;

export const SBTableRow = ({
  bgColor: rowBgColor,
  id,
  td,
  rowAuxClick,
  rowClick,
  compact = false,
}: Props): React.ReactNode => {
  return (
    <tr key={id} {...stylex.props(rowBgColor)}>
      {td.map(
        (
          {
            id: rowId,
            primary,
            align,
            // hidden,
            numbers,
            mobile,
            label,
            noRowClick = false,
            bgColor: cellBgColor,
            // bold = false,
          },
          ii,
        ) => (
          <td
            key={rowId}
            {...stylex.props(
              styles.cell,
              cellBgColor,
              compact && styles.compact,
              align === TableCellAlignment.Middle
                ? styles.alignMiddle
                : TableCellAlignment.End
                  ? styles.alignRight
                  : styles.alignLeft,
              numbers && styles.numbers,
              primary && styles.primary,
              ii === 0 && styles.firstCell,
              ii + 1 === td.length && styles.lastCell,
            )}
            onAuxClick={
              rowAuxClick && noRowClick === false ? () => rowAuxClick(id) : noop
            }
            onClick={
              rowClick && noRowClick === false
                ? (event: React.MouseEvent) => {
                    rowClick(id, event);
                  }
                : noop
            }
          >
            <span
              className={classNames(
                align === TableCellAlignment.Middle
                  ? "flex items-center justify-center"
                  : "",
              )}
            >
              {label}
            </span>
            {mobile != null && mobile.length > 0 && (
              <dl className="font-normal lg:hidden">
                {mobile.map((item, jj) => (
                  <Fragment key={item.id}>
                    <dt
                      className={classNames(
                        "sr-only",
                        ii === 0 ? "" : "sm:hidden",
                      )}
                    >
                      {item.dt}
                    </dt>
                    <dd
                      className={classNames(
                        "mt-1",
                        jj === 0 ? "text-gray-700" : "text-gray-500",
                        item.numbers ? "tabular-nums" : "",
                      )}
                    >
                      {item.dd}
                    </dd>
                  </Fragment>
                ))}
              </dl>
            )}
          </td>
        ),
      )}
    </tr>
  );
};

const styles = stylex.create({
  alignLeft: {
    textAlign: "left",
  },
  alignMiddle: {
    textAlign: "center",
  },
  alignRight: {
    textAlign: "right",
  },
  cell: {
    color: "rgb(75 85 99)",
    padding: "0 3px",
  },
  compact: {
    padding: 2,
    // text-xs
  },
  firstCell: {
    maxWidth: 0,
    paddingLeft: 4,
    width: "100%",
  },
  lastCell: {
    paddingRight: 3,
  },
  numbers: {
    fontVariantNumeric: "tabular-nums",
  },
  primary: {
    color: "rgb(17 24 39)",
  },
});
