/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  ProductDetailHeader_product$key,
  ProductPublicStatus,
} from "src/types/__generated__/ProductDetailHeader_product.graphql";
import type { SellerStatus } from "src/types/__generated__/QueriesProductDetailQuery.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import MakeOffer from "src/app/components/make-offer/MakeOffer";
import { UserContext } from "src/app/context/user";
import {
  ButtonType,
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBFmvBadge,
  SBHeading,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney, formatTitle } from "src/utils";

import ProductDetailActions from "./ProductDetailActions";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

interface ProductUrlOptions {
  id: string;
  imageUrl: string | null;
  title: string;
}

const formatProductUrl = ({ id, imageUrl, title }: ProductUrlOptions) =>
  id == null
    ? "https://shortboxed.com/"
    : `https://shortboxed.page.link/?link=https://shortboxed.com/product/${encodeURIComponent(id)}&st=${encodeURIComponent(title)}&apn=com.shortboxed&ibi=com.shortboxed&isi=1497091986${imageUrl == null ? "" : `&si=${encodeURIComponent(imageUrl)}`}`;

enum Modal {
  None,
  Offer,
}

type Props = Readonly<{
  achMinimumValue: number;
  employee: boolean;
  queryKey: ProductDetailHeader_product$key;
  sellerStatus: SellerStatus | null | undefined;
}>;

const ProductDetailHeader = ({
  achMinimumValue,
  employee,
  queryKey,
  sellerStatus,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();
  const userContext = useContext(UserContext);

  const data = useFragment(
    graphql`
      fragment ProductDetailHeader_product on Product {
        id
        price
        currency
        publicStatus
        comicDetails {
          title
          number
        }
        images {
          edges {
            node {
              url(quality: 100, webp: true, width: 1000)
            }
          }
        }
        fmvScore {
          minValue
          maxValue
          currency
          rank
        }
        ...ProductDetailActions_product
      }
    `,
    queryKey,
  );

  const { comicDetails, id, price, currency, fmvScore, images } = data;
  const publicStatus: ProductPublicStatus = data.publicStatus ?? "UNAVAILABLE";
  const { title, number } = comicDetails ?? {};

  const fmvMinValue = fmvScore?.minValue ?? 0;
  const fmvMaxValue = fmvScore?.maxValue ?? 0;
  const fmvCurrency = fmvScore?.currency ?? "USD";
  const fmvRank = fmvScore?.rank;

  const productTitle = formatTitle({ number, t, title });

  const [modal, setModal] = useState(Modal.None);
  const handleCloseModal = () => {
    setModal(Modal.None);
  };

  const imageEdges = images?.edges ?? [];
  const imageUrls = imageEdges.map(({ node }) => node.url);
  const imageUrl = imageUrls[0];

  const handleClickViewInApp = (event: React.SyntheticEvent) => {
    event.preventDefault();
    window.open(
      formatProductUrl({
        id,
        imageUrl,
        title: productTitle,
      }),
      "_blank",
      "noopener,noreferrer",
    );
  };

  // TODO Open this up for everyone when it's complete.
  const shouldShowBuyNow = userContext != null;
  const shouldShowPrice = ["FOR_SALE"].includes(publicStatus);

  return (
    <>
      <SBHeading level={HeadingLevel.H1} style={styles.productTitle}>
        {productTitle}
      </SBHeading>
      <div {...stylex.props(auto, styles.container)}>
        {shouldShowPrice ? (
          <div {...stylex.props(auto, styles.columns)}>
            <div {...stylex.props(styles.columnLeft)}>
              <SBParagraph style={styles.askingPrice}>
                {t("product.header.asking-price")}
              </SBParagraph>
              <SBParagraph style={styles.productCost}>
                {formatMoney(price, currency, i18n.language)}
              </SBParagraph>
              {fmvMinValue > 0 && fmvMaxValue > 0 && (
                <SBParagraph style={styles.fmvRange}>
                  {t("product.fmv-range", {
                    max: formatMoney(fmvMaxValue, fmvCurrency, i18n.language),
                    min: formatMoney(fmvMinValue, fmvCurrency, i18n.language),
                  })}
                </SBParagraph>
              )}
            </div>
            <div {...stylex.props(styles.columnRight)}>
              <div {...stylex.props(styles.columnRight)}>
                <SBFmvBadge fmvRank={fmvRank} style={styles.fmvRank} />
              </div>
            </div>
          </div>
        ) : null}
        {shouldShowBuyNow ? (
          <div {...stylex.props(auto, styles.columnButtons)}>
            {data != null && (
              <ProductDetailActions
                key={data.id}
                achMinimumValue={achMinimumValue}
                employee={employee}
                queryKey={data}
                sellerStatus={sellerStatus}
              />
            )}
          </div>
        ) : (
          <>
            <SBParagraph style={styles.inApp}>
              {t("product.header.in-app")}
            </SBParagraph>
            <SBButton
              block={true}
              title={t("product.header.buttons.in-app")}
              type={ButtonType.Button}
              variation={ButtonVariation.Default}
              onClick={handleClickViewInApp}
            />
          </>
        )}
      </div>
      <MakeOffer
        isOpen={modal === Modal.Offer}
        productId={id}
        setOpen={handleCloseModal}
      />
    </>
  );
};

const styles = stylex.create({
  askingPrice: {
    fontSize: 12,
    margin: 0,
  },
  columnButtons: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  columnLeft: {
    marginRight: 16,
  },
  columnRight: {
    alignSelf: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  columns: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  container: {
    borderColor: colors.tableBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    marginBlock: 24,
    padding: 16,
  },
  fmvRange: {
    fontSize: 12,
    margin: 0,
  },
  fmvRank: {
    marginTop: 8,
  },
  inApp: {
    fontSize: 14,
    marginBottom: 8,
  },
  productCost: {
    color: colors.color,
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 48,
    lineHeight: 0.875,
    marginBlock: 8,
  },
  productTitle: {
    fontSize: {
      [MOBILE]: 48,
      [TABLET]: 72,
      default: 96,
    },
  },
});

export default ProductDetailHeader;
