/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountStatsView_user$key } from "src/types/__generated__/SellerAccountStatsView_user.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useRefetchableFragment } from "react-relay";

import { SellerStatsContext } from "src/app/context/seller-stats";

import SellerAccountStatsFeedbackTable from "./SellerAccountStatsFeedbackTable";
import SellerAccountStatsSummary from "./SellerAccountStatsSummary";
import SellerAccountStatsTurnaroundTimesTable from "./SellerAccountStatsTurnaroundTimesTable";

type Props = Readonly<{
  queryKey: SellerAccountStatsView_user$key;
}>;

const SellerAccountStatsView = ({ queryKey }: Props): React.ReactNode => {
  const { interval } = useContext(SellerStatsContext);

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment SellerAccountStatsView_user on User
      @argumentDefinitions(interval: { type: "SellerStatsIntervals!" })
      @refetchable(queryName: "SellerAccountStatsViewRefetchQuery") {
        sellerStats(interval: $interval) {
          ...SellerAccountStatsSummary_sellerStats
          ...SellerAccountStatsFeedbackTable_sellerStats
          ...SellerAccountStatsTurnaroundTimesTable_sellerStats
        }
      }
    `,
    queryKey,
  );

  useEffect(() => {
    refetch(
      {
        interval,
      },
      {
        fetchPolicy: "network-only",
      },
    );
  }, [interval, refetch]);

  return data?.sellerStats ? (
    <>
      <SellerAccountStatsSummary queryKey={data.sellerStats} />
      <SellerAccountStatsFeedbackTable queryKey={data.sellerStats} />
      <SellerAccountStatsTurnaroundTimesTable queryKey={data.sellerStats} />
    </>
  ) : null;
};

export default SellerAccountStatsView;
