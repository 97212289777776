/**
 * @generated SignedSource<<ce5ede91017d1a38507ba6602aaab0cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountPayoutPreferencesView_user$data = {
  readonly id: string;
  readonly paymentPreferencesUrl: string;
  readonly " $fragmentType": "SellerAccountPayoutPreferencesView_user";
};
export type SellerAccountPayoutPreferencesView_user$key = {
  readonly " $data"?: SellerAccountPayoutPreferencesView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountPayoutPreferencesView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountPayoutPreferencesView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentPreferencesUrl",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "2d65394885697c24e7ef012e6cd110fe";

export default node;
