/**
 * @generated SignedSource<<77ec3e8142afc5b20dc32db58170b4ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountStatsView_user$data = {
  readonly id: string;
  readonly sellerStats: {
    readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStatsFeedbackTable_sellerStats" | "SellerAccountStatsSummary_sellerStats" | "SellerAccountStatsTurnaroundTimesTable_sellerStats">;
  } | null | undefined;
  readonly " $fragmentType": "SellerAccountStatsView_user";
};
export type SellerAccountStatsView_user$key = {
  readonly " $data"?: SellerAccountStatsView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStatsView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "interval"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SellerAccountStatsViewRefetchQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "SellerAccountStatsView_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "interval",
          "variableName": "interval"
        }
      ],
      "concreteType": "UserSellerStats",
      "kind": "LinkedField",
      "name": "sellerStats",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SellerAccountStatsSummary_sellerStats"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SellerAccountStatsFeedbackTable_sellerStats"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SellerAccountStatsTurnaroundTimesTable_sellerStats"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "9ca2442836c3228b608590b43a9b0b09";

export default node;
