/**
 * @generated SignedSource<<452711f376ce1caf5ab4bbe2b2970dd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountOfferSettingsView_user$data = {
  readonly id: string;
  readonly minOfferPercentage: number;
  readonly " $fragmentType": "SellerAccountOfferSettingsView_user";
};
export type SellerAccountOfferSettingsView_user$key = {
  readonly " $data"?: SellerAccountOfferSettingsView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountOfferSettingsView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountOfferSettingsView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPercentage",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "eccdc62bea0b8c78ad9c6333e4942bee";

export default node;
