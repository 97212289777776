/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountShipFromAddressQuery } from "src/types/__generated__/QueriesSellerAccountShipFromAddressQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import SellerAccountPage from "../SellerAccountPage";
import SellerAccountShipFromAddressContent from "./SellerAccountShipFromAddressContent";

const SellerAccountShipFromAddress = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesSellerAccountShipFromAddressQuery>;

  return (
    <SellerAccountPage
      docTitle={t("seller-account.ship-from-address.title")}
      innerHeading={t("seller-account.nav.ship-from-address")}
    >
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <SellerAccountShipFromAddressContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </SellerAccountPage>
  );
};

export default SellerAccountShipFromAddress;
