/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

const NEWLINE_RE = /\\n/gmu;
const LINE_BREAK_RE = / &#8211;/gmu;
const INLINE_WHITESPACE_RE = /[\u00a0 ]+/gmu;
const DOUBLE_QUOTE_START_RE = /^""/gmu;
const DOUBLE_QUOTE_OPEN_RE = / ""/gmu;
const DOUBLE_QUOTE_CLOSE_RE = /""/gmu;
const SIGNATURE_RE = /<p>\u002d/gmu;
const SIGNATURE_DASH_RE = /<p>&#8211; /gmu;
const EN_DASH_RE = /(\w) - (\w)/gmu;
const APOSTROPHE_RE = /(\w)'(\w)/gmu;

export const formatHtml = (content: string): string => {
  return (
    content
      // Strip extraneous newlines.
      .replace(NEWLINE_RE, "")
      // Avoid awkward line breaks after an en dash.
      .replace(LINE_BREAK_RE, "&nbsp;&#8211;")
      // Trim inline whitespace.
      .replace(INLINE_WHITESPACE_RE, " ")
      // Replace double quotation marks
      .replace(DOUBLE_QUOTE_START_RE, "“")
      .replace(DOUBLE_QUOTE_OPEN_RE, " “")
      .replace(DOUBLE_QUOTE_CLOSE_RE, "”")
      // Use an em dash for signatures.
      .replace(SIGNATURE_RE, "<p>&#8212;")
      .replace(SIGNATURE_DASH_RE, "<p>&#8212;")
      // Use en dashes
      .replace(EN_DASH_RE, "$1&nbsp;\u2013 $2")
      // Use apostrophes
      .replace(APOSTROPHE_RE, "$1\u2019$2")
  );
};
