/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { useHotConfigsQuery } from "src/types/__generated__/useHotConfigsQuery.graphql";

import { graphql, useLazyLoadQuery } from "react-relay";

type HotConfigs = Readonly<{
  ACH_SHOP_MINIMUM_VALUE: string;
  CHECKOUT_EXPIRY_DAYS: string;
  GOOGLE_PAY_MERCHANT_ID: string;
  GOOGLE_WEB_CLIENT_ID: string;
  GRADED_BOOK_MINIMUM_VALUE: string;
  INVOICE_PAYMENT_DAYS: string;
  OFFER_EXPIRY_DAYS: string;
  ONE_SIGNAL_APP_ID: string;
  PUBLISHED_HOMEFEED_ID: string;
  RAW_BOOK_MINIMUM_VALUE: string;
  SHIPPING_COST: string;
  SHIPPING_COST_DIRECT_GRADED: string;
  SHIPPING_COST_DIRECT_RAW: string;
  SHIPPING_COST_VERIFIED_GRADED: string;
  SHIPPING_COST_VERIFIED_RAW: string;
  VALIDATE_AUCTIONS: string;
}>;

export const useHotConfigs = (): HotConfigs | null | undefined => {
  const data = useLazyLoadQuery<useHotConfigsQuery>(
    graphql`
      query useHotConfigsQuery {
        hotConfigs {
          data
        }
      }
    `,
    {},
    { fetchPolicy: "network-only" },
  );

  return data.hotConfigs.data;
};
