/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HeadingLevel, SBActivityIndicator, SBHeading } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const SignupProcessing = (): React.ReactNode | null => {
  const { t } = useTranslation();

  return (
    <div {...stylex.props(styles.root)}>
      <SBHeading level={HeadingLevel.H1} style={styles.processing}>
        {t("verify.processing")}
      </SBHeading>
      <div {...stylex.props(auto, styles.activityIndicator)}>
        <SBActivityIndicator small={true} />
      </div>
    </div>
  );
};

const styles = stylex.create({
  activityIndicator: {
    alignItems: "center",
    display: "flex",
    fill: colors.color,
    justifyContent: "center",
  },
  processing: {
    fontSize: 24,
    marginBottom: 16,
    marginTop: 0,
    textAlign: "center",
  },
  root: {
    padding: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 48,
    },
    position: "relative",
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: 448,
    },
  },
});

export default SignupProcessing;
