/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

const GRAPH_API_HOST =
  process.env.REACT_APP_GRAPH_API_HOST ?? "graph.shortboxed.com";
const GRAPH_API_PROTOCOL = process.env.REACT_APP_GRAPH_API_PROTOCOL ?? "https";
const WS_PROTOCOL = process.env.REACT_APP_WS_PROTOCOL ?? "wss";

const WEB_HOST = process.env.REACT_APP_WEB_HOST ?? "shortboxed.com";
const WEB_PROTOCOL = process.env.REACT_APP_WEB_PROTOCOL ?? "https";

export const ERROR_RE = /^(?!E9999)E[0-9]{4}$/gu;
export const ANONYMOUS_USER_ID = "";

export const config = {
  API_HOST: GRAPH_API_HOST,
  API_PROTOCOL: GRAPH_API_PROTOCOL,
  API_URL: `${GRAPH_API_PROTOCOL}://${GRAPH_API_HOST}/graphql`,
  WEB_URL: `${WEB_PROTOCOL}://${WEB_HOST}`,
  WS_URL: `${WS_PROTOCOL}://${GRAPH_API_HOST}/graphql`,
};

// cSpell:disable
export const STRIPE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ??
  "pk_live_5OeT2hzpV7KUTD2SP9pQLjcZ00csGMV9kn";
// cSpell:enable
