/**
 * @generated SignedSource<<73147e45ff8afc4b9dd982daa97e1696>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ShippoLabelFileType = "PDF" | "PDF_4x6" | "PDF_W_PSLIP_8x11" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SellerAccountLabelSizeView_user$data = {
  readonly id: string;
  readonly labelSize: ShippoLabelFileType;
  readonly " $fragmentType": "SellerAccountLabelSizeView_user";
};
export type SellerAccountLabelSizeView_user$key = {
  readonly " $data"?: SellerAccountLabelSizeView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountLabelSizeView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountLabelSizeView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "labelSize",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "428083964ec7f3956827b018babb5e08";

export default node;
