/**
 * @generated SignedSource<<2801125790cc7e07928290d4ca2b9d8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountStoreSettingsView_user$data = {
  readonly id: string;
  readonly shopLogoUrl: string | null | undefined;
  readonly shopUrl: string | null | undefined;
  readonly shopUrlQrCode: string | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStoreLogoField_user" | "SellerAccountStoreNameField_user" | "SellerAccountStoreUrlField_user">;
  readonly " $fragmentType": "SellerAccountStoreSettingsView_user";
};
export type SellerAccountStoreSettingsView_user$key = {
  readonly " $data"?: SellerAccountStoreSettingsView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStoreSettingsView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountStoreSettingsView_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SellerAccountStoreNameField_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SellerAccountStoreUrlField_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SellerAccountStoreLogoField_user"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shopLogoUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shopUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shopUrlQrCode",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "1429128201c33854eff2c388c52ee249";

export default node;
