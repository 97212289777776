/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { SBIcon } from "./SBIcon";
import { SBParagraph } from "./SBParagraph";

type Props = Readonly<{
  message: string;
  style?: StyleXStyles;
}>;

export const SBSuccessMessage = ({
  message,
  style,
}: Props): React.ReactNode => {
  return (
    <div aria-live="polite">
      {message ? (
        <div {...stylex.props(auto, styles.error, style)}>
          <SBIcon
            containerStyle={styles.errorIconContainer}
            icon="check_circle"
            style={styles.errorIcon}
          />
          <SBParagraph style={styles.errorMessage}>{message}</SBParagraph>
        </div>
      ) : null}
    </div>
  );
};

const styles = stylex.create({
  error: {
    alignItems: "flex-start",
    backgroundColor: colors.successBackgroundColor,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBlock: 12,
    paddingInline: 16,
  },
  errorIcon: {
    color: colors.successColor,
  },
  errorIconContainer: {
    marginRight: 8,
  },
  errorMessage: {
    color: colors.successColor,
    fontSize: 16,
    lineHeight: 1.5,
    margin: 0,
  },
});
