/**
 * @generated SignedSource<<e7a26d736c9fc015bb4c77e5255e2fea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ClientId = "API" | "APP" | "APP_LOCAL" | "SERVICE" | "WEB" | "WEB_LOCAL" | "WEB_STAGE" | "%future added value";
export type LogInInput = {
  clientId?: ClientId | null | undefined;
  email: string;
  password: string;
};
export type useLoginMutation$variables = {
  input: LogInInput;
};
export type useLoginMutation$data = {
  readonly login: {
    readonly accessToken: string;
    readonly permissions: ReadonlyArray<string>;
    readonly refreshToken: string | null | undefined;
    readonly user: {
      readonly id: string;
    } | null | undefined;
  };
};
export type useLoginMutation = {
  response: useLoginMutation$data;
  variables: useLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "logInInput",
        "variableName": "input"
      }
    ],
    "concreteType": "LoggedInResponse",
    "kind": "LinkedField",
    "name": "login",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "989e79b3e0830d73d4845425429f665f",
    "id": null,
    "metadata": {},
    "name": "useLoginMutation",
    "operationKind": "mutation",
    "text": "mutation useLoginMutation(\n  $input: LogInInput!\n) {\n  login(logInInput: $input) {\n    accessToken\n    refreshToken\n    permissions\n    user {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a2e311dff4e149b17a728d23d26930ff";

export default node;
