/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ErrorResponse } from "src/app/types/RelayTypes";
import type { PasswordChangeFormMutation } from "src/types/__generated__/PasswordChangeFormMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";
import { defaultRules, useValidation } from "react-simple-form-validator";

import { useDefaultValidationErrorMessages } from "src/hooks";
import {
  ButtonType,
  SBButton,
  SBErrorMessage,
  SBPasswordInput,
  SBSuccessMessage,
} from "src/sbxui";

const PASSWORD_MIN_LENGTH = 8;

const PasswordChangeForm = (): React.ReactNode => {
  const { t } = useTranslation();

  const [commit, isInFlight] = useMutation<PasswordChangeFormMutation>(graphql`
    mutation PasswordChangeFormMutation($input: ChangePasswordInput!) {
      changePassword(changePasswordInput: $input) {
        id
      }
    }
  `);

  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    useState<string>("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [touchedFields, setTouchedFields] = useState({
    currentPassword: false,
    newPassword: false,
    newPasswordConfirmation: false,
  });

  const defaultValidationErrorMessages = useDefaultValidationErrorMessages();

  const { getErrorsInField, isFieldInError, isFormValid } = useValidation({
    fieldsRules: {
      currentPassword: { minlength: PASSWORD_MIN_LENGTH, required: true },
      newPassword: { minlength: PASSWORD_MIN_LENGTH, required: true },
      newPasswordConfirmation: { equalPassword: newPassword, required: true },
    },
    labels: {
      currentPassword: t("signup.errors.labels.currentPassword"),
      newPassword: t("signup.errors.labels.newPassword"),
      newPasswordConfirmation: t(
        "signup.errors.labels.newPasswordConfirmation",
      ),
    },
    messages: {
      // We have our own library for localization, so we just use en here.
      en: {
        ...defaultValidationErrorMessages,
      },
    },
    rules: {
      ...defaultRules,
    },
    state: { currentPassword, newPassword, newPasswordConfirmation },
  });

  const handleBlurField = (
    event: React.FocusEvent<HTMLInputElement>,
    field: string,
  ) => {
    setTouchedFields((prevFields) => ({ ...prevFields, [field]: true }));
  };

  const handleChangeCurrentPassword = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCurrentPassword(event.currentTarget.value);
  };

  const handleChangeNewPassword = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewPassword(event.currentTarget.value);
  };

  const handleChangeConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNewPasswordConfirmation(event.currentTarget.value);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlight) {
      return;
    }
    setTouchedFields({
      currentPassword: !isFormValid,
      newPassword: !isFormValid,
      newPasswordConfirmation: !isFormValid,
    });

    if (isFormValid) {
      commit({
        onCompleted() {
          setSuccessMessage(t("account.success.PASSWORD"));
        },
        onError(error: unknown) {
          const errorResponse = error as ErrorResponse;
          let message =
            errorResponse?.res?.errors?.[0].message[0] ??
            t("signup.errors.default");
          if (
            errorResponse?.res?.errors?.[0]?.extensions?.exception?.status ===
            429
          ) {
            message = t("signup.errors.http429");
          }
          setErrorMessage(message);
        },
        variables: {
          input: {
            currentPassword,
            newPassword,
          },
        },
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <SBErrorMessage message={errorMessage} style={styles.message} />
      <SBSuccessMessage message={successMessage} style={styles.message} />
      <fieldset {...stylex.props(styles.formFields)}>
        <div {...stylex.props(styles.passwordField)}>
          <SBPasswordInput
            current={true}
            disabled={isInFlight}
            errorMessage={
              touchedFields.currentPassword
                ? isFieldInError("currentPassword") &&
                  getErrorsInField("currentPassword")[0]
                : null
            }
            id="currentPassword"
            label={t("signup.field.currentPassword")}
            placeholder={t("signup.field.currentPassword")}
            style={styles.input}
            value={currentPassword}
            onBlur={(event) => {
              handleBlurField(event, "currentPassword");
            }}
            onChange={handleChangeCurrentPassword}
          />
        </div>
        <div {...stylex.props(styles.passwordField)}>
          <SBPasswordInput
            current={true}
            disabled={isInFlight}
            errorMessage={
              touchedFields.newPassword
                ? isFieldInError("newPassword") &&
                  getErrorsInField("newPassword")[0]
                : null
            }
            id="newPassword"
            label={t("signup.field.newPassword")}
            placeholder={t("signup.field.newPassword")}
            style={styles.input}
            value={newPassword}
            onBlur={(event) => {
              handleBlurField(event, "newPassword");
            }}
            onChange={handleChangeNewPassword}
          />
        </div>
        <div {...stylex.props(styles.passwordField)}>
          <SBPasswordInput
            current={true}
            disabled={isInFlight}
            errorMessage={
              touchedFields.newPasswordConfirmation
                ? isFieldInError("newPasswordConfirmation") &&
                  getErrorsInField("newPasswordConfirmation")[0]
                : null
            }
            id="newPasswordConfirmation"
            label={t("signup.field.newPasswordConfirmation")}
            placeholder={t("signup.field.newPasswordConfirmation")}
            style={styles.input}
            value={newPasswordConfirmation}
            onBlur={(event) => {
              handleBlurField(event, "newPasswordConfirmation");
            }}
            onChange={handleChangeConfirmPassword}
          />
        </div>
        <div {...stylex.props(styles.button)}>
          <SBButton
            disabled={
              isInFlight ||
              !isFormValid ||
              currentPassword === "" ||
              newPassword === "" ||
              newPasswordConfirmation === ""
            }
            loading={isInFlight}
            title={t("account.buttons.PASSWORD")}
            type={ButtonType.Submit}
          />
        </div>
      </fieldset>
    </form>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  formFields: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  input: {
    marginBottom: 16,
  },
  message: {
    marginBottom: 16,
  },
  passwordField: {
    marginBottom: 16,
  },
});

export default PasswordChangeForm;
