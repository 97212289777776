/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailView_product$key } from "src/types/__generated__/ProductDetailView_product.graphql";
import type { SellerStatus } from "src/types/__generated__/QueriesProductDetailQuery.graphql";
import type { RelatedProducts_relatedProducts$key } from "src/types/__generated__/RelatedProducts_relatedProducts.graphql";
import type { SimilarProducts_similarProducts$key } from "src/types/__generated__/SimilarProducts_similarProducts.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useSubscription } from "react-relay";

import CensusTable from "src/app/components/census/CensusTable";
import HistoricalSalesTable from "src/app/components/historical-sales/HistoricalSalesTable";
import { PageContext } from "src/app/context/page";
import { formatGrade, formatTitle } from "src/utils";

import ProductDetailCarousel from "./ProductDetailCarousel";
import ProductDetailHeader from "./ProductDetailHeader";
import ProductDetailMeta from "./ProductDetailMeta";
import RelatedProducts from "./RelatedProducts";
import SimilarProducts from "./SimilarProducts";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const POLL_TIME_MS = 500;

const ProductDetailViewProductUpdateSubscription = graphql`
  subscription ProductDetailViewProductUpdateSubscription(
    $input: ProductIdInput!
  ) {
    productUpdateSubscription(productUpdateSubscriptionInput: $input) {
      ...ProductDetailView_product
    }
  }
`;

type Props = Readonly<{
  achMinimumValue: number;
  employee: boolean;
  product: ProductDetailView_product$key;
  queryKey: RelatedProducts_relatedProducts$key &
    SimilarProducts_similarProducts$key;
  sellerStatus: SellerStatus | null | undefined;
}>;

const ProductDetailView = ({
  achMinimumValue,
  employee,
  product,
  queryKey,
  sellerStatus,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProductDetailView_product on Product {
        ...ProductDetailHeader_product
        ...HistoricalSalesTable_product
        ...ProductDetailMeta_product
        ...ProductDetailCarousel_product
        id
        createdAt
        updatedAt
        comicDetails {
          ...CensusTable_comicDetail
          title
          number
          grade
          gradingAuthority
        }
      }
    `,
    product,
  );

  const { comicDetails, id: productId } = data;
  const { title, grade, gradingAuthority, number } = comicDetails ?? {};

  const updateConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: ProductDetailViewProductUpdateSubscription,
      variables: {
        input: {
          productId: productId ?? "",
        },
      },
    }),
    [productId],
  );

  useSubscription(updateConfig);

  const productTitle = `${formatTitle({ number, t, title })} ${formatGrade({ grade, gradingAuthority, t })}`;

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext?.setTitle(
      t("product.document-title", {
        product: productTitle,
      }),
    );
  }, [productTitle, pageContext, t]);

  return (
    <div {...stylex.props(styles.product)}>
      <div {...stylex.props(styles.columns)}>
        <div {...stylex.props(styles.columnLeftMobile)}>
          <ProductDetailHeader
            achMinimumValue={achMinimumValue}
            employee={employee}
            queryKey={data}
            sellerStatus={sellerStatus}
          />
          <ProductDetailCarousel queryKey={data} />
          <ProductDetailMeta queryKey={data} />
        </div>
        <div {...stylex.props(styles.columnRightMobile)}>
          {data ? <HistoricalSalesTable queryKey={data} /> : null}
          {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
        </div>
        <div {...stylex.props(styles.columnLeft)}>
          <ProductDetailCarousel queryKey={data} />
          <ProductDetailMeta queryKey={data} />
        </div>
        <div {...stylex.props(styles.columnRight)}>
          <ProductDetailHeader
            achMinimumValue={achMinimumValue}
            employee={employee}
            queryKey={data}
            sellerStatus={sellerStatus}
          />
          {data ? <HistoricalSalesTable queryKey={data} /> : null}
          {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
        </div>
      </div>
      <SimilarProducts queryKey={queryKey} />
      <RelatedProducts queryKey={queryKey} />
    </div>
  );
};

const styles = stylex.create({
  backButton: {
    marginBottom: 40,
  },
  census: {
    marginTop: 40,
  },
  censusTable: {
    marginTop: 10,
  },
  columnLeft: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "block",
      default: "block",
    },
    gridArea: {
      default: "1 / 1 / 2 / 6",
    },
    marginBottom: 24,
  },
  columnLeftMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columnRight: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "block",
      default: "block",
    },
    gridArea: "1 / 7 / 2 / 11",
    marginBottom: 24,
  },
  columnRightMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columns: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(10, 1fr)",
      default: "repeat(10, 1fr)",
    },
    margin: 0,
  },
  product: {
    marginBottom: 96,
  },
});

export default ProductDetailView;
