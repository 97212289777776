/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountInvoiceDetailView_invoice$key } from "src/types/__generated__/AccountInvoiceDetailView_invoice.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { PageContext } from "src/app/context/page";

type Props = Readonly<{
  invoice: AccountInvoiceDetailView_invoice$key;
}>;

const AccountInvoiceDetailView = ({ invoice }: Props): React.ReactNode => {
  const pageContext = useContext(PageContext);
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment AccountInvoiceDetailView_invoice on Invoice {
        id
        createdAt
        updatedAt
        surrogateId
      }
    `,
    invoice,
  );

  const { surrogateId } = data;

  useEffect(() => {
    pageContext?.setTitle(t("account.invoice-detail.title"));
  }, [t, pageContext]);

  return <div>{surrogateId}</div>;
};

export default AccountInvoiceDetailView;
