/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { OrderDetailView_order$key } from "src/types/__generated__/OrderDetailView_order.graphql";
import type { OrderDetailViewResendShippingLabelMutation } from "src/types/__generated__/OrderDetailViewResendShippingLabelMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import CopyButton from "src/app/components/sharing/CopyButton";
import { PageContext } from "src/app/context/page";
import {
  HeadingLevel,
  SBActivityIndicator,
  SBBackButton,
  SBDate,
  SBHeading,
  SBIcon,
  SBImage,
  SBImageCarousel,
  SBLink,
  SBModal,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import {
  DateFormatType,
  formatGrade,
  formatMoney,
  formatTitle,
  getTrackingLinkByCarrier,
} from "src/utils";

import OrderDetailSummaryCard from "./OrderDetailSummaryCard";
import OrderStatusTracking from "./OrderStatusTracking";

const US_MILITARY_REGIONS = ["APO", "FPO", "DPO"];

type Props = Readonly<{
  onClose: () => void;
  queryKey: OrderDetailView_order$key;
}>;

const OrderDetailView = ({ onClose, queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const data = useFragment(
    graphql`
      fragment OrderDetailView_order on Order {
        ...OrderStatusTracking_order
        id
        currency
        surrogateId
        shippingLabel {
          trackingId
          trackingIdHq
          shippingLabelQrCodeUrl
          shippingLabelURLHq
          shippingLabelURL
          carrier
          status
        }
        country
        address1
        address2
        locality
        region
        postalCode
        givenName
        familyName
        createdAt
        fees
        subTotal
        total
        tax
        shipping
        paymentMethod
        product {
          id
          salesChannel
          isViewerOwner
          images {
            edges {
              node {
                url(quality: 100, width: 1000, webp: true)
              }
            }
          }
          comicDetails {
            title
            grade
            gradingAuthority
            number
          }
        }
      }
    `,
    queryKey,
  );

  const [commitResendLabel, isInFlightResendLabel] =
    useMutation<OrderDetailViewResendShippingLabelMutation>(graphql`
      mutation OrderDetailViewResendShippingLabelMutation(
        $input: ResendShippingLabelInput!
      ) {
        resendShippingLabel(resendShippingLabelInput: $input) {
          ... on Order {
            id
          }
        }
      }
    `);

  const {
    id,
    product,
    surrogateId,
    shippingLabel,
    createdAt,
    tax,
    total,
    subTotal,
    fees,
    shipping,
    currency,
    country,
    locality,
    region,
    postalCode,
    familyName,
    givenName,
    address1,
    address2,
    paymentMethod,
  } = data;
  const { comicDetails, images, isViewerOwner } = product ?? {};
  const { title, grade, gradingAuthority, number } = comicDetails ?? {};

  const {
    trackingId,
    trackingIdHq,
    shippingLabelQrCodeUrl,
    shippingLabelURLHq,
    shippingLabelURL,
  } = shippingLabel ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrls = imageEdges.map(({ node }) => node.url);

  const productTitle = `${formatTitle({
    number,
    t,
    title,
  })} ${formatGrade({ grade, gradingAuthority, t })}`;

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext?.setTitle(
      t("product.document-title", {
        product: productTitle,
      }),
    );
  }, [productTitle, pageContext, t]);

  const handleQRClick = () => {
    setIsModalOpen(true);
  };

  const handleResendClick = () => {
    if (isInFlightResendLabel) {
      return;
    }

    commitResendLabel({ variables: { input: { orderId: id, toHq: true } } });
  };

  const hideCountry =
    (country === "US" && US_MILITARY_REGIONS.includes(region)) ||
    i18n.language.includes(country);

  const gradeValue = grade === 0 ? t("raw-grades.0-0") : grade?.toFixed(1);

  const labelUrl = trackingId ? shippingLabelURL : shippingLabelURLHq;

  return (
    <div>
      <SBBackButton
        label={t("common.buttons.back")}
        style={styles.backButton}
        onClick={onClose}
      />
      <div>
        <div {...stylex.props(styles.carousel)}>
          <SBImageCarousel imageUrls={imageUrls} />
        </div>
        <div>
          <SBHeading level={HeadingLevel.H2}>
            {t("order-details.order-number", { surrogateId })}
          </SBHeading>
        </div>
        {isViewerOwner && shippingLabel && product.salesChannel === "SHOP" ? (
          <div {...stylex.props(styles.shippingLabel, styles.borderTop)}>
            <SBHeading level={HeadingLevel.H5}>
              {t("order-details.shipping-label")}
            </SBHeading>
            <div {...stylex.props(styles.shippingLabelButtons)}>
              <button
                type="button"
                onClick={handleQRClick}
                {...stylex.props(styles.qrButton)}
              >
                <SBIcon icon="qr_code" style={styles.icon} />
              </button>
              <button
                type="button"
                onClick={handleResendClick}
                {...stylex.props(styles.qrButton)}
              >
                {isInFlightResendLabel ? (
                  <SBActivityIndicator small={true} />
                ) : (
                  <SBIcon icon="email" style={styles.icon} />
                )}
              </button>
              <SBLink
                external={true}
                href={labelUrl ?? ""}
                {...stylex.props(styles.qrButton)}
              >
                <SBIcon icon="link" style={styles.icon} />
              </SBLink>
            </div>
          </div>
        ) : null}
        {trackingIdHq ? (
          <>
            <div {...stylex.props(styles.heading, styles.borderTop)}>
              <SBHeading level={HeadingLevel.H3}>
                {t("order-details.tracking-hq")}
              </SBHeading>
            </div>
            {shippingLabel ? (
              <div {...stylex.props(styles.shippingLabel)}>
                <div {...stylex.props(styles.trackingLink)}>
                  <SBLink
                    external={true}
                    href={getTrackingLinkByCarrier(
                      shippingLabel.carrier,
                      shippingLabel.trackingIdHq ?? "",
                    )}
                  >
                    {shippingLabel?.trackingIdHq}
                  </SBLink>
                </div>
                <div {...stylex.props(styles.trackingButtons)}>
                  <CopyButton textToCopy={trackingIdHq} />
                  <SBLink
                    external={true}
                    href={labelUrl ?? ""}
                    {...stylex.props(styles.qrButton)}
                  >
                    <SBIcon icon="link" style={styles.icon} />
                  </SBLink>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {trackingId ? (
          <>
            <div {...stylex.props(styles.heading, styles.borderTop)}>
              <SBHeading level={HeadingLevel.H3}>
                {t("order-details.tracking")}
              </SBHeading>
            </div>
            {shippingLabel ? (
              <div {...stylex.props(styles.shippingLabel)}>
                <div {...stylex.props(styles.trackingLink)}>
                  <SBLink
                    external={true}
                    href={getTrackingLinkByCarrier(
                      shippingLabel.carrier,
                      shippingLabel.trackingId ?? "",
                    )}
                  >
                    {shippingLabel?.trackingId}
                  </SBLink>
                </div>
                <div {...stylex.props(styles.trackingButtons)}>
                  <CopyButton textToCopy={trackingId} />
                  <SBLink
                    external={true}
                    href={labelUrl ?? ""}
                    {...stylex.props(styles.qrButton)}
                  >
                    <SBIcon icon="link" style={styles.icon} />
                  </SBLink>
                </div>
              </div>
            ) : null}
          </>
        ) : null}

        {shippingLabel ? (
          <div>
            <div {...stylex.props(styles.heading)}>
              <SBHeading level={HeadingLevel.H5}>
                {t(
                  trackingId
                    ? "order-details.tracking-status"
                    : "order-details.tracking-status-hq",
                )}
              </SBHeading>
            </div>
            <OrderStatusTracking queryKey={data} />
          </div>
        ) : null}

        <div {...stylex.props(styles.heading, styles.borderTop)}>
          <SBHeading level={HeadingLevel.H3}>
            {t("order-details.details")}
          </SBHeading>
        </div>
        <OrderDetailSummaryCard
          leftContent={t("order-details.date")}
          rightContent={
            <SBDate
              dateString={createdAt}
              format={DateFormatType.Short}
              language={i18n.language}
            />
          }
        />
        <OrderDetailSummaryCard
          leftContent={t("order-details.subtotal")}
          rightContent={formatMoney(subTotal, currency, i18n.language, true)}
        />
        {tax > 0 && (
          <OrderDetailSummaryCard
            leftContent={t("order-details.tax")}
            rightContent={formatMoney(tax, currency, i18n.language, true)}
          />
        )}
        <OrderDetailSummaryCard
          leftContent={t("order-details.shipping")}
          rightContent={formatMoney(shipping, currency, i18n.language, true)}
        />
        {fees != null && fees > 0 && (
          <OrderDetailSummaryCard
            leftContent={t("order-details.fees")}
            rightContent={formatMoney(fees, currency, i18n.language, true)}
          />
        )}
        <OrderDetailSummaryCard
          leftContent={t("order-details.total")}
          rightContent={formatMoney(total, currency, i18n.language, true)}
        />
      </div>

      {!isViewerOwner && (
        <>
          <div {...stylex.props(styles.heading, styles.borderTop)}>
            <SBHeading level={HeadingLevel.H3}>
              {t("order-details.shipping-address")}
            </SBHeading>
          </div>
          <div {...stylex.props(auto, styles.value)}>
            {givenName} {familyName}
          </div>
          <div {...stylex.props(auto, styles.value)}>{address1}</div>
          {address2 ? (
            <div {...stylex.props(auto, styles.value)}>{address2}</div>
          ) : null}
          <div {...stylex.props(auto, styles.value)}>
            {t("order-details.address-formatted", {
              locality,
              postalCode,
              region,
            })}
          </div>
          {!hideCountry && (
            <div {...stylex.props(auto, styles.value)}>
              {t(`countries.${kebabCase(country)}`)}
            </div>
          )}
          <div {...stylex.props(styles.heading)}>
            <SBHeading level={HeadingLevel.H3}>
              {t("order-details.payment-information")}
            </SBHeading>
          </div>
          <div {...stylex.props(auto, styles.value)}>
            {t(`paymentMethod.${kebabCase(paymentMethod)}`)}
          </div>
        </>
      )}

      <div {...stylex.props(styles.heading, styles.borderTop)}>
        <SBHeading level={HeadingLevel.H3}>
          {t("order-details.purchase-details")}
        </SBHeading>
      </div>

      <OrderDetailSummaryCard
        leftContent={
          <SBImage
            alt=""
            height={200}
            src={images?.edges[0]?.node?.url ?? ""}
          />
        }
        rightContent={
          <div>
            <div>{t("order-details.comic-details1", { number, title })}</div>
            <div>
              {t("order-details.comic-details2", {
                grade:
                  gradingAuthority === "RAW"
                    ? t(`raw-grades.${kebabCase(gradeValue)}`)
                    : gradeValue,
                gradingAuthority: t(
                  `grading-authority.${kebabCase(gradingAuthority)}`,
                ),
              })}
            </div>
          </div>
        }
      />
      <SBModal
        headerText={t("order-details.modal.shop-url-qr-code.title")}
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
      >
        <div {...stylex.props(styles.modal)}>
          <SBParagraph>
            {t("order-details.modal.shop-url-qr-code.text")}
          </SBParagraph>
          <div {...stylex.props(styles.modalQr)}>
            <img src={shippingLabelQrCodeUrl ?? ""} />
          </div>
        </div>
      </SBModal>
    </div>
  );
};

const styles = stylex.create({
  backButton: {
    marginBottom: 40,
  },
  borderTop: {
    borderColor: colors.topNavigationMenuBorderColor,
    borderTopStyle: "solid",
    borderTopWidth: 1,
  },
  carousel: {
    marginBottom: 24,
    position: "relative",
  },
  copyButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    marginRight: 16,
    padding: 0,
  },
  heading: {
    marginBottom: 16,
    marginTop: 40,
    paddingTop: 16,
  },
  icon: {
    color: colors.color,
  },
  modal: {
    padding: 16,
  },
  modalQr: {
    display: "flex",
    justifyContent: "center",
  },
  qrButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  shippingLabel: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 24,
    paddingTop: 16,
  },
  shippingLabelButtons: {
    display: "grid",
    gridGap: 16,
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  trackingButtons: {
    display: "flex",
    gridGap: 16,
  },
  trackingLink: {
    marginBottom: 20,
  },
  value: {
    color: colors.color,
  },
});

export default OrderDetailView;
