/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProfileLinkView_user$key } from "src/types/__generated__/ProfileLinkView_user.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: ProfileLinkView_user$key;
}>;

const ProfileLinkView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProfileLinkView_user on User {
        id
        firstName
        lastName
        sellerStatus
      }
    `,
    queryKey,
  );

  const { firstName, lastName } = data ?? {};

  const abbr = firstName?.[0];

  return (
    <div
      {...stylex.props(auto, styles.profile)}
      aria-label={t("menu.name", {
        familyName: lastName,
        givenName: firstName,
      })}
    >
      {abbr == null ? (
        <SBIcon icon="account_circle" style={styles.avatar} />
      ) : (
        <div {...stylex.props(auto, styles.abbr)}>{abbr}</div>
      )}
    </div>
  );
};

const styles = stylex.create({
  abbr: {
    color: colors.topNavigationMenuProfileColor,
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 20,
    lineHeight: 0.875,
    margin: 0,
    textTransform: "uppercase",
  },
  avatar: {
    color: colors.topNavigationMenuProfileColor,
  },
  icon: {
    color: colors.topNavigationMenuColor,
  },
  profile: {
    alignItems: "center",
    backgroundColor: colors.topNavigationMenuProfileBackgroundColor,
    borderRadius: 40,
    display: "flex",
    height: 28,
    justifyContent: "center",
    textDecorationLine: "none",
    width: 28,
  },
});

export default ProfileLinkView;
