/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { unflatten } from "flat";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEnGb from "./locales/en-gb/translation.json";
import translationEnUs from "./locales/en-us/translation.json";

// The translations.
const resources = {
  "en-CA": {
    translation: unflatten<Record<string, string>, Record<string, unknown>>(
      translationEnGb,
    ),
  },
  "en-GB": {
    translation: unflatten<Record<string, string>, Record<string, unknown>>(
      translationEnGb,
    ),
  },
  "en-IE": {
    translation: unflatten<Record<string, string>, Record<string, unknown>>(
      translationEnGb,
    ),
  },
  "en-US": {
    translation: unflatten<Record<string, string>, Record<string, unknown>>(
      translationEnUs,
    ),
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: process.env.REACT_APP_I18N_DEBUG === "true",
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default.
    },
    resources,
  });

export default i18n;
