/**
 * @generated SignedSource<<2018cc75696a36c8ee5b22c87e470ad6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ChangePasswordInput = {
  currentPassword: string;
  newPassword: string;
};
export type PasswordChangeFormMutation$variables = {
  input: ChangePasswordInput;
};
export type PasswordChangeFormMutation$data = {
  readonly changePassword: {
    readonly id: string;
  };
};
export type PasswordChangeFormMutation = {
  response: PasswordChangeFormMutation$data;
  variables: PasswordChangeFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "changePasswordInput",
        "variableName": "input"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "changePassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordChangeFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordChangeFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "94772004035277be771219b5f0ef573d",
    "id": null,
    "metadata": {},
    "name": "PasswordChangeFormMutation",
    "operationKind": "mutation",
    "text": "mutation PasswordChangeFormMutation(\n  $input: ChangePasswordInput!\n) {\n  changePassword(changePasswordInput: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0bd96b39530a264e201b70fc804f8846";

export default node;
