/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountPayoutPreferencesQuery } from "src/types/__generated__/QueriesSellerAccountPayoutPreferencesQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { SellerAccountPayoutPreferencesQuery } from "src/app/router/Queries";

import SellerAccountPayoutPreferencesView from "./SellerAccountPayoutPreferencesView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesSellerAccountPayoutPreferencesQuery>;
}>;

const SellerAccountPayoutPreferencesContent = ({
  queryRef,
}: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(
    SellerAccountPayoutPreferencesQuery,
    queryRef,
  );

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <SellerAccountPayoutPreferencesView queryKey={user} />;
};

export default SellerAccountPayoutPreferencesContent;
