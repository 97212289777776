/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { SBActivityIndicator } from "src/sbxui";

export enum TableCellAlignment {
  End,
  Start,
  Middle,
}

export enum TableSortOrder {
  Ascending = "ASC",
  Descending = "DESC",
}

export interface TableHeader {
  align?: TableCellAlignment;
  hidden?: boolean;
  // Whether or not the column is hidden on mobile
  id: string;
  isSortedBy?: boolean;
  label: React.ReactNode;
  onClickSort?: (event: React.SyntheticEvent) => void;
  sort?: TableSortOrder;
}

export interface TableDataCell {
  align?: TableCellAlignment;
  bgColor?: StyleXStyles;
  bold?: boolean;
  hidden?: boolean;
  id: string;
  label: React.ReactNode;
  mobile?: DataList[];
  noRowClick?: boolean;
  // Alternate presentation for the mobile table
  numbers?: boolean;
  primary?: boolean;
}

interface DataList {
  dd: React.ReactNode;
  dt: React.ReactNode;
  id: string;
  numbers?: boolean;
}

export interface TableConfig {
  columns: TableHeader[];
  rowClick?: (id: string) => void;
}

type Props = Readonly<{
  compact?: boolean;
  config: TableConfig;
  loading?: boolean;
  rows: (React.ReactNode | null)[];
  style?: StyleXStyles;
}>;

export const SBTable = React.forwardRef<HTMLDivElement | null, Props>(
  (
    { style, config, loading = false, rows, compact = false },
    ref,
  ): React.ReactNode => {
    const { columns } = config;

    return (
      <>
        <div {...stylex.props(styles.root, style)}>
          <table {...stylex.props(styles.table)}>
            <thead {...stylex.props(styles.thead)}>
              <tr>
                {columns.map((th, ii) => (
                  <th
                    key={th.id}
                    {...stylex.props(
                      styles.th,
                      compact && styles.compact,
                      th.hidden && styles.thHidden,
                      th.align === TableCellAlignment.Middle
                        ? styles.alignMiddle
                        : TableCellAlignment.End
                          ? styles.alignRight
                          : styles.alignLeft,
                      ii === 0 ? styles.firstColumn : styles.innerColumns,
                      // didn't port this to stylex yet...
                      // ii + 1 === columns.length ? "pr-4 sm:pr-6" : "pr-3"
                    )}
                    scope="col"
                  >
                    {th.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody {...stylex.props(styles.tbody)}>
              {rows.filter(Boolean).map((tr) => tr)}
            </tbody>
          </table>
        </div>
        <div ref={ref} {...stylex.props(styles.loader)}>
          {loading ? <SBActivityIndicator /> : null}
        </div>
      </>
    );
  },
);

const styles = stylex.create({
  alignLeft: {
    textAlign: "left",
  },
  alignMiddle: {
    textAlign: "center",
  },
  alignRight: {
    textAlign: "right",
  },
  compact: {
    padding: 2,
    // text-xs
  },
  firstColumn: {
    paddingLeft: 4,
  },
  innerColumns: {
    padding: "0 3px",
  },
  loader: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: "6px 0",
  },
  root: {
    boxShadow: "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
  },
  table: {
    borderBottomWidth: 0,
    borderColor: "rgb(209 213 219)",
    borderTopWidth: 1,
    minWidth: "100%",
  },
  tbody: {
    backgroundColor: "rgb(255 255 255)",
    borderBottomWidth: 0,
    borderColor: "rgb(229 231 235)",
    borderTopWidth: 1,
  },
  th: {
    color: "rgb(17 24 39)",
    padding: "3px 0",
  },
  thHidden: {
    display: "table-cell",
  },
  thead: {
    backgroundColor: "rgb(249 250 251)",
  },
});
