/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesArticlesQuery } from "src/types/__generated__/QueriesArticlesQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { ArticlesQuery } from "src/app/router/Queries";

import ArticleView from "./ArticlesView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesArticlesQuery>;
}>;

const ArticlesContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(ArticlesQuery, queryRef);

  return <ArticleView queryKey={queryKey} />;
};

export default ArticlesContent;
