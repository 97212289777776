/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesTermsQuery } from "src/types/__generated__/QueriesTermsQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { TermsQuery } from "src/app/router/Queries";

import TermsView from "./TermsView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesTermsQuery>;
}>;

const TermsContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(TermsQuery, queryRef);

  if (queryKey.terms == null) {
    return null;
  }

  return <TermsView queryKey={queryKey.terms} />;
};

export default TermsContent;
