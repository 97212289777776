/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  Currency as CurrencyType,
  HistoricalSalesTable_product$data,
  SaleFormat as SaleFormatType,
  Source as SourceType,
} from "src/types/__generated__/HistoricalSalesTable_product.graphql";

import * as stylex from "@stylexjs/stylex";
import Highcharts from "highcharts";
import highchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsReact from "highcharts-react-official";
import * as React from "react";
import { useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { useFootnotes } from "src/hooks";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

highchartsAccessibility(Highcharts);

const MINIMUM_SALES_REQUIRED = 5;

const CURRENCY = "USD";

interface Point {
  currency: CurrencyType;
  saleFormat: SaleFormatType;
  source: SourceType;
}

type Props = Readonly<{
  data: HistoricalSalesTable_product$data;
}>;

const HistoricalSalesChart = ({ data }: Props): React.ReactNode | null => {
  const { i18n, t } = useTranslation();

  const shortDateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.languages, {
        month: "numeric",
        year: "numeric",
      }),
    [i18n.languages],
  );

  const longDateFormatter = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.languages, {
        day: "numeric",
        month: "long",
        year: "numeric",
      }),
    [i18n.languages],
  );

  const sales = data.historicalSales?.edges ?? [];

  const { attribution } = useFootnotes(
    sales.map(({ node: { source } }) => source),
  );

  const lineChartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const allSales = sales?.map(({ node }) => ({
    currency: node?.currency,
    saleFormat: node?.saleFormat,
    source: node?.source,
    x: node ? new Date(node.saleDate).getTime() * 1000 : Date.now(),
    y: node?.price,
  }));

  // Always end on today's date.
  allSales.push({
    x: Date.now(),
    // @ts-ignore This does exactly what we want but it's typed strictly as `number`.
    y: null,
  });

  const options = {
    accessibility: {
      description: t("salesChart.description"),
    },

    chart: {
      backgroundColor: colors.chartBackgroundColor,
      style: {
        fontFamily: "'Supreme-Regular', sans-serif",
        letterSpacing: "0.1",
      },
      type: "spline",
    },
    credits: { enabled: false },

    colors: [colors.chartLineColor],

    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        pointStart: new Date(sales[0]?.node?.saleDate * 1000).getFullYear(),
      },
    },

    responsive: {
      rules: [
        {
          chartOptions: {
            legend: {
              align: "center",
              layout: "horizontal",
              verticalAlign: "bottom",
            },
          },
          condition: {
            maxWidth: 600,
          },
        },
      ],
    },

    series: [
      {
        data: allSales.reverse(),
        type: "spline",
      },
    ],

    title: {
      style: {
        color: colors.chartColor,
        fontFamily: "'Supreme-Regular', sans-serif",
        fontSize: 12,
        fontWeight: 400,
      },
      text: t("salesChart.previousSales", {
        count: sales.length,
      }),
    },

    tooltip: {
      formatter(this: Highcharts.TooltipFormatterContextObject) {
        // eslint-disable-next-line react/no-this-in-sfc
        const point = this.point as unknown as Point;
        // eslint-disable-next-line react/no-this-in-sfc
        const price = this.y;
        // eslint-disable-next-line react/no-this-in-sfc
        const date = longDateFormatter.format(new Date(this.x ?? Date.now()));
        const currency = point.currency;

        return attribution[point.source]
          ? t("salesChart.soldOnWithAttribution", {
              date,
              footnote: attribution[point.source],
              format: t(`saleFormat.${point.saleFormat}`),
              price: formatMoney(
                price == null ? 0 : price,
                currency,
                i18n.language,
              ),
              source: t(`sources.${point.source}`),
            })
          : t("salesChart.soldOn", {
              date,
              format: t(`saleFormat.${point.saleFormat}`),
              price: formatMoney(
                price == null ? 0 : price,
                currency,
                i18n.language,
              ),
              source: t(`sources.${point.source}`),
            });
      },
    },

    xAxis: {
      dateTimeLabelFormats: {
        day: "%y",
      },
      labels: {
        formatter: ({ value }: { value: string }) =>
          shortDateFormatter.format(new Date(value)),
        style: {
          color: colors.chartColor,
        },
      },
      lineColor: colors.chartBorderColor,
      tickColor: colors.chartBorderColor,
      type: "datetime",
    },

    yAxis: {
      gridLineColor: colors.chartGridLineColor,
      labels: {
        formatter: ({ value }: { value: string }) =>
          formatMoney(Number.parseFloat(value), CURRENCY, i18n.language),
        style: {
          color: colors.chartColor,
        },
      },
      title: {
        style: {
          color: colors.chartColor,
        },
        text: t("salesChart.salePrice"),
      },
    },
  };

  if (sales.length < MINIMUM_SALES_REQUIRED) {
    return null;
  }

  return (
    <div {...stylex.props(auto, styles.chart)}>
      <HighchartsReact
        ref={lineChartComponentRef}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

const styles = stylex.create({
  chart: {
    backgroundColor: colors.chartBackgroundColor,
    borderBottomWidth: 1,
    borderColor: colors.chartBorderColor,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderStyle: "solid",
    borderTopWidth: 0,
  },
  footer: {
    marginTop: 4,
  },
  footerText: {
    color: colors.tableFooterColor,
    fontSize: 12,
    lineHeight: "16px",
    margin: 0,
  },
  showMore: {
    fontFamily: "Supreme-Regular",
    fontSize: 14,
    lineHeight: "24px",
    margin: 0,
  },
  showMoreLabel: {
    borderBottomWidth: 0,
    borderColor: colors.chartBorderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 1,
    color: {
      ":hover": colors.chartColor,
      default: colors.chartColor,
    },
    display: "flex",
    justifyContent: "space-between",
    paddingBlock: 12,
    paddingInline: 16,
    textDecorationLine: "none",
  },
});

export default HistoricalSalesChart;
