/**
 * @generated SignedSource<<a3fe13842d6a2854e88634574daf1de8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type InvoiceStatus = "CLOSED" | "EXPIRED" | "FAILED" | "OPEN" | "PAID" | "PROCESSING" | "REQUIRES_ACTION" | "TEMP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountInvoiceCard_invoice$data = {
  readonly createdAt: string;
  readonly currency: Currency;
  readonly id: string;
  readonly items: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly images: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly url: string;
            };
          }>;
        } | null | undefined;
      };
    }>;
  };
  readonly status: InvoiceStatus;
  readonly surrogateId: number;
  readonly total: number;
  readonly " $fragmentType": "AccountInvoiceCard_invoice";
};
export type AccountInvoiceCard_invoice$key = {
  readonly " $data"?: AccountInvoiceCard_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountInvoiceCard_invoice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountInvoiceCard_invoice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "surrogateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductsConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "first",
                      "value": 1
                    }
                  ],
                  "concreteType": "ImagesConnection",
                  "kind": "LinkedField",
                  "name": "images",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ImagesEdge",
                      "kind": "LinkedField",
                      "name": "edges",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Image",
                          "kind": "LinkedField",
                          "name": "node",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": [
                                {
                                  "kind": "Literal",
                                  "name": "quality",
                                  "value": 100
                                },
                                {
                                  "kind": "Literal",
                                  "name": "webp",
                                  "value": true
                                },
                                {
                                  "kind": "Literal",
                                  "name": "width",
                                  "value": 500
                                }
                              ],
                              "kind": "ScalarField",
                              "name": "url",
                              "storageKey": "url(quality:100,webp:true,width:500)"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": "images(first:1)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};

(node as any).hash = "9dd9d37414d1f527876f3e6ed13fcbc4";

export default node;
