/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

const CHARACTERS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export default function makeString(length: number): string {
  let result = "";
  const charactersLength = CHARACTERS?.length;
  for (let ii = 0; ii < length; ii++) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
