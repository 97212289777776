/**
 * @generated SignedSource<<4553ec21f4492e8eb050dfe9a56f263e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesSellerAccountStoreSettingsQuery$variables = Record<PropertyKey, never>;
export type QueriesSellerAccountStoreSettingsQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStoreSettingsView_user">;
    } | null | undefined;
  };
};
export type QueriesSellerAccountStoreSettingsQuery = {
  response: QueriesSellerAccountStoreSettingsQuery$data;
  variables: QueriesSellerAccountStoreSettingsQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSellerAccountStoreSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SellerAccountStoreSettingsView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesSellerAccountStoreSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shopName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shopUrl",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shopUrlQrCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shopLogoUrl",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d6c4cb3eb9cf2afe8bd6e7de02b5384",
    "id": null,
    "metadata": {},
    "name": "QueriesSellerAccountStoreSettingsQuery",
    "operationKind": "query",
    "text": "query QueriesSellerAccountStoreSettingsQuery {\n  viewer {\n    user {\n      ...SellerAccountStoreSettingsView_user\n      id\n    }\n  }\n}\n\nfragment SellerAccountStoreLogoField_user on User {\n  shopLogoUrl\n}\n\nfragment SellerAccountStoreNameField_user on User {\n  id\n  shopName\n}\n\nfragment SellerAccountStoreSettingsView_user on User {\n  ...SellerAccountStoreNameField_user\n  ...SellerAccountStoreUrlField_user\n  ...SellerAccountStoreLogoField_user\n  id\n  shopLogoUrl\n  shopUrl\n  shopUrlQrCode\n}\n\nfragment SellerAccountStoreUrlField_user on User {\n  id\n  shopUrl\n  shopUrlQrCode\n}\n"
  }
};

(node as any).hash = "4ad31c0eddad72058e86e46d7971d088";

export default node;
