/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ArticlesRecent_articles$key } from "src/types/__generated__/ArticlesRecent_articles.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading } from "src/sbxui";

import ArticleCard from "../articles/ArticleCard";

const MAX_ARTICLES = 3;

type Props = Readonly<{
  notId: string | null;
  queryKey: ArticlesRecent_articles$key;
}>;

const ArticlesRecent = ({ notId, queryKey }: Props): React.ReactNode | null => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ArticlesRecent_articles on Query {
        articles(
          first: 4
          orderBy: { createdAt: DESC }
          where: { isBlog: true }
        ) {
          edges {
            node {
              id
              slug
              ...ArticleCard_article
            }
          }
        }
      }
    `,
    queryKey,
  );

  const articles = data?.articles?.edges ?? [];

  if (articles.length === 0) {
    return null;
  }

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("blog.recent")}
      </SBHeading>
      <div {...stylex.props(styles.root)}>
        {articles
          .filter(({ node }) => node.id !== notId)
          .slice(0, MAX_ARTICLES)
          .map(({ node }, ii) => (
            <div
              {...stylex.props(styles.item, ii === 0 && styles.itemFirst)}
              key={node.slug}
            >
              <ArticleCard queryKey={node} shouldShowSummary={false} />
            </div>
          ))}
      </div>
    </>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 32,
  },
  item: {
    boxSizing: "border-box",
    maxWidth: "50%",
    paddingLeft: 24,
  },
  itemFirst: {
    paddingLeft: 0,
  },
  root: {
    display: "flex",
    marginBottom: 96,
    marginTop: 20,
  },
});

export default ArticlesRecent;
