/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { SHORTBOXED_CDN_BASE_URL } from "src/app/constants";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const APPLE_LOGO = `${SHORTBOXED_CDN_BASE_URL}/auth_apple.webp`;

export enum AppleButtonTheme {
  Dark = "dark",
  Light = "light",
}

type Props = Readonly<{
  disabled?: boolean;
  loading?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  signup?: boolean;
  style?: StyleXStyles;
  theme?: AppleButtonTheme;
}>;

export const SBAppleButton = React.memo(
  ({
    disabled = false,
    loading = false,
    onClick,
    signup = false,
    style,
    theme = AppleButtonTheme.Dark,
    ...rest
  }: Props): React.ReactNode => {
    const { t } = useTranslation();

    return (
      <button
        {...stylex.props(
          auto,
          styles.button,
          theme === AppleButtonTheme.Light && styles.buttonLight,
          style,
        )}
        disabled={disabled || loading}
        type="button"
        onClick={onClick}
        {...rest}
      >
        <span {...stylex.props(styles.inner)}>
          <img
            {...stylex.props(styles.logo)}
            alt={t("login.apple.logo")}
            height={24}
            src={APPLE_LOGO}
            width={24}
          />
          {t(signup ? "login.apple.continue" : "login.apple.login")}
        </span>
      </button>
    );
  },
);

const styles = stylex.create({
  activitySpinner: {
    fill: colors.ssoAppleColor,
  },
  button: {
    alignItems: "center",
    backgroundColor: {
      ":hover": colors.ssoAppleBackgroundColor,
      default: colors.ssoAppleBackgroundColor,
    },
    borderColor: colors.ssoAppleBackgroundColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    color: colors.ssoAppleColor,
    cursor: "pointer",
    display: "flex",
    height: 46,
    justifyContent: "center",
    opacity: {
      ":disabled": 0.6,
      default: 1,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
    overflow: "hidden",
    paddingInline: 32,
    position: "relative",
    textDecorationLine: "none",
    width: "100%",
  },
  buttonLight: {
    borderColor: colors.ssoAppleBorderColor,
  },
  inner: {
    alignItems: "center",
    boxSizing: "border-box",
    display: "flex",
    fontFamily:
      "system-ui, -apple-system, 'system-ui', '.SFNSText-Regular', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "48px",
    margin: 0,
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  logo: {
    left: 12,
    position: "absolute",
  },
});
