/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  ProductStatus,
  SalesChannel,
} from "src/types/__generated__/PortfolioView_user.graphql";
import type { GradingAuthority } from "src/types/__generated__/SearchResultCard_typesenseSearch.graphql";

import * as React from "react";
import { useCallback, useMemo, useState } from "react";

export enum PortfolioOption {
  All = "all",
  Auction = "auction",
  ForSale = "for-sale",
  Personal = "personal",
}

export enum Sort {
  DateAsc = "createdAt-asc",
  DateDesc = "createdAt-desc",
  FavoritesAsc = "favorites-asc",
  FavoritesDesc = "favorites-desc",
  PriceAsc = "price-asc",
  PriceDesc = "price-desc",
  TitleAsc = "title-asc",
  TitleDesc = "title-desc",
}

export const DEFAULT_SORT = Sort.DateDesc;

export const DEFAULT_STATUS = [
  "FOR_SALE",
  "PENDING_PURCHASE",
  "SUBMITTED",
  "UNAVAILABLE",
] as ProductStatus[];

export const DEFAULT_SALES_CHANNEL = ["AUCTION", "SHOP"] as SalesChannel[];

interface State {
  canListForSale: boolean;
  gradingAuthority: GradingAuthority | null | undefined;
  isSearching: boolean;
  resetSearch: (resetSearchTerm?: boolean) => void;
  salesChannel: readonly SalesChannel[];
  setCanListForSale: (canListForSale: boolean) => void;
  setGradingAuthority: (
    gradingAuthority: GradingAuthority | null | undefined,
  ) => void;
  setIsSearching: (isSearching: boolean) => void;
  setSalesChannel: (status: readonly SalesChannel[]) => void;
  setSort: (sort: Sort) => void;
  setStatus: (status: readonly ProductStatus[]) => void;
  setTitle: (title: string | null | undefined) => void;
  sort: Sort;
  status: readonly ProductStatus[];
  title: string | null | undefined;
}

const INITIAL_STATE: State = {
  canListForSale: false,
  gradingAuthority: null,
  isSearching: false,
  resetSearch: () => {
    return;
  },
  salesChannel: DEFAULT_SALES_CHANNEL,
  setCanListForSale: () => {
    return;
  },
  setGradingAuthority: () => {
    return;
  },
  setIsSearching: () => {
    return;
  },
  setSalesChannel: () => {
    return;
  },
  setSort: () => {
    return;
  },
  setStatus: () => {
    return;
  },
  setTitle: () => {
    return;
  },
  sort: DEFAULT_SORT,
  status: DEFAULT_STATUS,
  title: null,
};

export const PortfolioContext: React.Context<State> =
  React.createContext(INITIAL_STATE);

type Props = Readonly<{
  children: React.ReactNode;
}>;

export const PortfolioProvider = ({ children }: Props): React.ReactNode => {
  // Sort type and direction
  const [sort, setSort] = useState<Sort>(INITIAL_STATE.sort);

  // Filters
  const [gradingAuthority, setGradingAuthority] = useState<
    GradingAuthority | null | undefined
  >(INITIAL_STATE.gradingAuthority);

  // Sales channel
  const [salesChannel, setSalesChannel] = useState<readonly SalesChannel[]>(
    INITIAL_STATE.salesChannel,
  );

  // Book title
  const [title, setTitle] = useState<string | null | undefined>(
    INITIAL_STATE.title,
  );

  // Inventory type
  const [status, setStatus] = useState<readonly ProductStatus[]>(
    INITIAL_STATE.status,
  );

  // Can list products for sale
  const [canListForSale, setCanListForSale] = useState<boolean>(
    INITIAL_STATE.canListForSale,
  );

  // Whether search is open
  const [isSearching, setIsSearching] = useState<boolean>(
    INITIAL_STATE.isSearching,
  );

  // Reset search
  const resetSearch = useCallback((resetSearchTerm = true) => {
    if (resetSearchTerm) {
      setTitle(INITIAL_STATE.title);
    }
    setSort(INITIAL_STATE.sort);
    setGradingAuthority(INITIAL_STATE.gradingAuthority);
  }, []);

  const currentPortfolio = useMemo(
    () => ({
      canListForSale,
      gradingAuthority,
      isSearching,
      resetSearch,
      salesChannel,
      setCanListForSale,
      setGradingAuthority,
      setIsSearching,
      setSalesChannel,
      setSort,
      setStatus,
      setTitle,
      sort,
      status,
      title,
    }),
    [
      canListForSale,
      gradingAuthority,
      isSearching,
      resetSearch,
      salesChannel,
      sort,
      status,
      title,
    ],
  );

  return (
    <PortfolioContext.Provider value={currentPortfolio}>
      {children}
    </PortfolioContext.Provider>
  );
};
