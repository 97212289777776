/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountInvoiceDetailQuery } from "src/types/__generated__/QueriesAccountInvoiceDetailQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePreloadedQuery } from "react-relay";

import { AccountInvoiceDetailQuery } from "src/app/router/Queries";
import { SBErrorPage, SBPageHeader } from "src/sbxui";
import { auto } from "src/themes";

import AccountSideNav from "../account/AccountSideNav";
import AccountInvoiceDetailView from "./AccountInvoiceDetailView";

const MOBILE = "@media (max-width: 767px)";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountInvoiceDetailQuery>;
}>;

const AccountInvoiceDetailContent = ({ queryRef }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const queryKey = usePreloadedQuery(AccountInvoiceDetailQuery, queryRef);

  if (queryKey.invoiceBySurrogateId == null) {
    return (
      <SBErrorPage
        description={t("not-found.description")}
        errorCode={404}
        title={t("not-found.page-title")}
      />
    );
  }

  return (
    <div>
      <SBPageHeader
        title={t("account.invoice-detail.page-title", {
          surrogateId: queryKey.invoiceBySurrogateId.surrogateId,
        })}
      />
      <div {...stylex.props(styles.root)}>
        <AccountSideNav />
        <div {...stylex.props(styles.main)}>
          <div {...stylex.props(auto)}>
            <AccountInvoiceDetailView invoice={queryKey.invoiceBySurrogateId} />
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  main: {
    gridColumn: "span 4 / span 4",
  },
  root: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      default: "repeat(6, 1fr)",
    },
  },
});

export default AccountInvoiceDetailContent;
