/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { INavItem } from "./AccountSideNav";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { HeadingLevel, SBHeading, SBSideNav } from "src/sbxui";

type Props = Readonly<{
  heading: string;
  items: INavItem[];
}>;

const AccountSideNavSection = ({ items, heading }: Props): React.ReactNode => {
  return (
    <div {...stylex.props(styles.section)}>
      <SBHeading level={HeadingLevel.H4} style={styles.heading}>
        {heading}
      </SBHeading>
      <SBSideNav isFixed={false} isSubNav={true} items={items} />
    </div>
  );
};

const styles = stylex.create({
  heading: {
    marginBottom: 10,
  },
  section: {
    paddingBottom: 20,
  },
});

export default AccountSideNavSection;
