/**
 * @generated SignedSource<<ede5ee6ba5e469f9cfb43bcd745ad935>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountStoreLogoField_user$data = {
  readonly shopLogoUrl: string | null | undefined;
  readonly " $fragmentType": "SellerAccountStoreLogoField_user";
};
export type SellerAccountStoreLogoField_user$key = {
  readonly " $data"?: SellerAccountStoreLogoField_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStoreLogoField_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountStoreLogoField_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shopLogoUrl",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "dfdcab10915d18e6e5021d82b1f94b5b";

export default node;
