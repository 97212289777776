/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { dateToLongFormat, dateToShortFormat } from "src/utils";

export enum DateFormatType {
  Short,
  Long,
}

interface Options {
  dateString: string | number;
  format: DateFormatType;
}

export const fixDate = (dateString: string | number): Date => {
  return new Date(
    Number.isNaN(Number(dateString)) ? dateString : Number(dateString),
  );
};

export const SBDate = ({ dateString, format }: Options): string => {
  const fixedDate = fixDate(dateString);

  return format === DateFormatType.Long
    ? dateToLongFormat(fixedDate)
    : dateToShortFormat(fixedDate);
};
