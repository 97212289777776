/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductFeedbackModal_product$key } from "src/types/__generated__/ProductFeedbackModal_product.graphql";
import type {
  CreateProductFeedbackInput,
  ProductFeedbackModalCreateProblemMutation,
} from "src/types/__generated__/ProductFeedbackModalCreateProblemMutation.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import { SBButton, SBModal } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

import ProductFeedbackButtons from "./ProductFeedbackButtons";

type Props = Readonly<{
  isOpen: boolean;
  onSuccess: () => void;
  queryKey: ProductFeedbackModal_product$key;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

export const ProductFeedbackModal = ({
  isOpen,
  setOpen,
  onSuccess,
  queryKey,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [condition, setCondition] = useState<"yes" | "no" | "none">("none");
  const [packing, setPacking] = useState<"yes" | "no" | "none">("none");

  const product = useFragment(
    graphql`
      fragment ProductFeedbackModal_product on Product {
        id
        comicDetails {
          gradingAuthority
        }
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] =
    useMutation<ProductFeedbackModalCreateProblemMutation>(graphql`
      mutation ProductFeedbackModalCreateProblemMutation(
        $input: CreateProductFeedbackInput!
      ) {
        createProductFeedback(createProductFeedbackInput: $input) {
          id
          canLeaveFeedback
        }
      }
    `);

  const productId = product.id;
  const gradingAuthority = product.comicDetails?.gradingAuthority;

  const onPressConditionYes = () => {
    setCondition((prevCondition) => (prevCondition === "yes" ? "none" : "yes"));
  };

  const onPressConditionNo = () => {
    setCondition((prevCondition) => (prevCondition === "no" ? "none" : "no"));
  };

  const onPressShippingYes = () => {
    setPacking((prevPacking) => (prevPacking === "yes" ? "none" : "yes"));
  };

  const onPressShippingNo = () => {
    setPacking((prevPacking) => (prevPacking === "no" ? "none" : "no"));
  };

  const handleOrderFeedbackSubmit = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const input: CreateProductFeedbackInput = {
      isWellPacked: packing === "yes",
      productId,
    };

    if (gradingAuthority === "RAW") {
      input.isConditionAcceptable = condition === "yes";
    }

    commit({
      onCompleted() {
        setIsLoading(false);
        onSuccess();
      },
      onError() {
        setIsLoading(false);
      },
      variables: {
        input,
      },
    });
  };

  const isButtonDisabled =
    gradingAuthority === "RAW"
      ? condition === "none" || packing === "none"
      : packing === "none";

  return (
    <SBModal
      footer={
        <div {...stylex.props(styles.modalFooter)}>
          <SBButton
            disabled={isButtonDisabled || isInFlight}
            loading={isInFlight}
            title={t("product.feedback.submit")}
            onClick={handleOrderFeedbackSubmit}
          />
        </div>
      }
      headerText={t("product.feedback.title")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div {...stylex.props(styles.modal)}>
        <div {...stylex.props(styles.root)}>
          {product?.comicDetails?.gradingAuthority === "RAW" && (
            <ProductFeedbackButtons
              description={t("product.feedback.condition.description")}
              title={t("product.feedback.condition.title")}
              value={condition}
              onPressNo={onPressConditionNo}
              onPressYes={onPressConditionYes}
            />
          )}
          <ProductFeedbackButtons
            description={t("product.feedback.shipping.description")}
            title={t("product.feedback.shipping.title")}
            value={packing}
            onPressNo={onPressShippingNo}
            onPressYes={onPressShippingYes}
          />
        </div>
      </div>
    </SBModal>
  );
};

const styles = stylex.create({
  feedbackButton: {},
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  negative: {
    color: colors.feedbackNegative,
  },
  positive: {
    color: colors.feedbackPositive,
  },
  root: {},
});
