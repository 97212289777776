/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { SHORTBOXED_CDN_BASE_URL } from "src/app/constants";
import {
  HeadingLevel,
  SBHeading,
  SBImage,
  SBLink,
  SBParagraph,
} from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const HYDRA = `${SHORTBOXED_CDN_BASE_URL}/hydra.webp`;

type Props = Readonly<{
  buttonHref?: string;
  buttonOnClick?: (event: React.SyntheticEvent) => void;
  buttonTitle?: string;
  description: string;
  errorCode: number;
  title: string;
}>;

export const SBErrorPage = React.memo((props: Props): React.ReactNode => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    buttonHref = "/",
    buttonOnClick,
    buttonTitle = t("notFound.buttonTitle"),
    errorCode,
    description,
    title,
  } = props;

  const [isPending, startTransition] = useTransition();

  const handleClickButton = (event: React.SyntheticEvent) => {
    event.preventDefault();
    buttonOnClick?.(event);
    if (buttonHref != null && !buttonHref?.startsWith("#")) {
      startTransition(() => {
        navigate(buttonHref);
      });
    }
  };

  return (
    <div {...stylex.props(styles.root)}>
      <div {...stylex.props(styles.heading)}>
        <SBImage
          alt={t("notFound.hydra")}
          aria-hidden="true"
          height={128}
          src={HYDRA}
          style={styles.hydraLeft}
          width={128}
        />
        <SBHeading level={HeadingLevel.H1} style={styles.errorCode}>
          {errorCode}
        </SBHeading>
        <SBImage
          alt={t("notFound.hydra")}
          aria-hidden="true"
          height={128}
          src={HYDRA}
          style={styles.hydraRight}
          width={128}
        />
      </div>
      <SBHeading level={HeadingLevel.H2} style={styles.title}>
        {title}
      </SBHeading>
      <SBParagraph style={styles.description}>{description}</SBParagraph>
      <SBLink
        style={isPending ? styles.isPending : null}
        to={buttonHref}
        onClick={handleClickButton}
      >
        {buttonTitle}
      </SBLink>
    </div>
  );
});

const styles = stylex.create({
  description: {
    textAlign: {
      [MOBILE]: "center",
      [TABLET]: "center",
      default: "left",
    },
  },
  errorCode: {
    fontSize: 256,
    marginInline: 24,
  },
  heading: {
    alignItems: "center",
    display: "flex",
    flexDirection: {
      [MOBILE]: "column",
      [TABLET]: "column",
      default: "row",
    },
    justifyContent: "center",
    marginBottom: 24,
  },
  hydraLeft: {
    display: "block",
  },
  hydraRight: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "block",
    },
  },
  isPending: {
    opacity: 0.4,
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
  },
  title: {
    fontSize: 28,
    marginBottom: 24,
  },
});
