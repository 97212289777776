/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AnchorPropsWithSelection } from "@headlessui/react/dist/internal/floating";
import type { StyleXStyles } from "@stylexjs/stylex";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from "@headlessui/react";
import stylex from "@stylexjs/stylex";
import { Fragment } from "react";
import * as React from "react";

import { SBIcon, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

export interface SelectInputOption {
  disabled?: boolean;
  id: string;
  label: string;
}

type Props = Readonly<{
  anchor?: AnchorPropsWithSelection | undefined;
  block?: boolean;
  center?: boolean;
  disabled?: boolean;
  errorMessage?: string | false | null;
  onChange: (label: string) => void;
  options: SelectInputOption[];
  placeholder?: string;
  small?: boolean;
  style?: StyleXStyles;
  value: SelectInputOption;
}>;

export const SBSelectInput = ({
  anchor,
  block = true,
  center = false,
  disabled: isDisabled = false,
  errorMessage,
  onChange,
  options,
  placeholder,
  small = false,
  value,
  style,
}: Props): React.ReactNode => {
  let allOptions = options;
  if (placeholder) {
    allOptions = [
      {
        id: "placeholder",
        label: placeholder,
      },
      ...options,
    ];
  }

  if (isDisabled) {
    return (
      <div {...stylex.props(styles.listbox, block && styles.block, style)}>
        <div {...stylex.props(styles.container, block && styles.block)}>
          <div
            {...stylex.props(
              auto,
              styles.button,
              small && styles.buttonSmall,
              Boolean(placeholder) &&
                !Boolean(value.id) &&
                styles.buttonPlaceholder,
              Boolean(value.id) && styles.buttonHasValue,
              block && styles.block,
            )}
          >
            <span
              {...stylex.props(
                styles.buttonLabel,
                center && styles.buttonLabelCenter,
              )}
            >
              {value.id === "placeholder" ? placeholder : value.label}
            </span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Listbox
      {...stylex.props(styles.listbox, block && styles.block, style)}
      as="div"
      value={value}
      // @ts-ignore Wrong type; the parameter here is a string.
      onChange={onChange}
    >
      <div {...stylex.props(styles.container, block && styles.block)}>
        <ListboxButton
          {...stylex.props(
            auto,
            styles.button,
            small && styles.buttonSmall,
            Boolean(placeholder) &&
              !Boolean(value.id) &&
              styles.buttonPlaceholder,
            Boolean(value.id) && styles.buttonHasValue,
            block && styles.block,
          )}
        >
          <span
            {...stylex.props(
              styles.buttonLabel,
              center && styles.buttonLabelCenter,
            )}
          >
            {value.id === "placeholder" ? placeholder : value.label}
          </span>
          <SBIcon aria-hidden="true" icon="unfold_more" style={styles.icon} />
        </ListboxButton>
        <Transition
          as={Fragment}
          leave={stylex.props(styles.leave).className}
          leaveFrom={stylex.props(styles.leaveFrom).className}
          leaveTo={stylex.props(styles.leaveTo).className}
        >
          <ListboxOptions
            {...stylex.props(auto, styles.options)}
            anchor={anchor}
          >
            {allOptions.map(({ id, label, disabled = false }, ii) => (
              <ListboxOption
                {...stylex.props(
                  auto,
                  styles.option,
                  ii === 0 && styles.optionFirst,
                )}
                key={id}
                disabled={disabled}
                value={label}
              >
                {({ focus }) => (
                  <div
                    {...stylex.props(
                      styles.optionLabel,
                      id === "placeholder" && styles.optionLabelPlaceholder,
                      focus && styles.optionLabelActive,
                    )}
                  >
                    {label}
                  </div>
                )}
              </ListboxOption>
            ))}
          </ListboxOptions>
        </Transition>
      </div>
      {errorMessage ? (
        <SBParagraph style={styles.errorMessage}>{errorMessage}</SBParagraph>
      ) : null}
    </Listbox>
  );
};

const styles = stylex.create({
  block: {
    width: "100%",
  },
  button: {
    alignItems: "center",
    backgroundColor: colors.inputTextBackgroundColor,
    borderColor: colors.inputTextBorderColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "inline-flex",
    height: 48,
    justifyContent: "space-between",
    outline: {
      ":focus-visible": colors.outline,
    },
    paddingLeft: 16,
    textDecorationLine: "none",
    width: "100%",
  },
  buttonHasValue: {
    borderColor: colors.inputTextColor,
  },
  buttonLabel: {
    overflow: "hidden",
    paddingRight: 8,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  buttonLabelCenter: {
    flexGrow: 1,
    paddingLeft: 8,
    paddingRight: 0,
    textAlign: "center",
  },
  buttonPlaceholder: {
    color: colors.inputTextPlaceholderColor,
  },
  buttonSmall: {
    borderRadius: 16,
    height: 32,
  },
  container: {
    position: "relative",
  },
  errorMessage: {
    color: colors.colorEmphasis,
    fontSize: 14,
    margin: 0,
    marginTop: 4,
  },
  icon: {
    color: colors.inputTextIconColor,
    pointerEvents: "none",
  },
  leave: {
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-in",
  },
  leaveFrom: {
    opacity: 1,
  },
  leaveTo: {
    opacity: 0,
  },
  listbox: {
    position: "relative",
  },
  option: {
    alignItems: "center",
    borderBottomWidth: 0,
    borderColor: colors.inputTextOptionBorderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 1,
    cursor: "default",
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    marginTop: 4,
    padding: 0,
    paddingTop: 4,
    position: "relative",
    userSelect: "none",
  },
  optionFirst: {
    borderTopWidth: 0,
    marginTop: 0,
    paddingTop: 0,
  },
  optionLabel: {
    backgroundColor: "transparent",
    borderRadius: 4,
    color: {
      ":hover": colors.topNavigationTabHoverColor,
      default: colors.topNavigationTabColor,
    },
    cursor: "pointer",
    display: "block",
    marginInline: -8,
    paddingBlock: 4,
    paddingInline: 8,
    textAlign: "left",
    textDecorationLine: "none",
    width: "100%",
  },
  optionLabelActive: {
    backgroundColor: colors.linkHoverColor,
    color: colors.inputTextBackgroundColor,
  },
  optionLabelPlaceholder: {
    color: colors.inputTextPlaceholderColor,
  },
  options: {
    backgroundColor: colors.topNavigationBackgroundColor,
    borderColor: colors.inputTextOptionBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: colors.topNavigationMenuShadow,
    boxSizing: "border-box",
    marginTop: 8,
    maxHeight: 200,
    outline: {
      ":focus-visible": colors.outline,
    },
    overflowY: "auto",
    paddingBlock: 8,
    paddingInline: 16,
    position: "absolute",
    right: 0,
    transformOrigin: "top right",
    width: "var(--button-width)",
    zIndex: 20,
  },
});
