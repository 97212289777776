/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { BidNowTimer_auctionLot$key } from "src/types/__generated__/BidNowTimer_auctionLot.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import { useCountdownTimer } from "src/hooks";
import { SBParagraph } from "src/sbxui";

type Props = Readonly<{
  queryKey: BidNowTimer_auctionLot$key;
}>;

const BidNowTimer = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment BidNowTimer_auctionLot on AuctionLot {
        bidCount
        closesAt
      }
    `,
    queryKey,
  );

  const closesAt = data.closesAt;
  const timestamp = closesAt == null ? null : Number.parseInt(closesAt, 10);

  const bidCount = data.bidCount ?? 0;

  const { color, expiresIn } = useCountdownTimer(timestamp ?? Date.now(), {
    short: true,
  });

  return (
    <SBParagraph
      style={[
        styles.timeRemaining,
        bidCount === 0 && styles.timeRemainingAlone(color),
      ]}
    >
      {t("auction.lot.bidding-modal.time-remaining", {
        timestamp: expiresIn,
      })}
    </SBParagraph>
  );
};

const styles = stylex.create({
  timeRemaining: {
    paddingInline: 16,
    textAlign: "center",
  },
  timeRemainingAlone: (color: string) => ({
    color,
    paddingTop: 16,
  }),
});

export default BidNowTimer;
