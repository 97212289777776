/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountInvoiceDetailQuery } from "src/types/__generated__/QueriesAccountInvoiceDetailQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import AccountInvoiceDetailContent from "./AccountInvoiceDetailContent";

const AccountInvoiceDetail = (): React.ReactNode => {
  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesAccountInvoiceDetailQuery>;

  return (
    <div aria-live="polite">
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <AccountInvoiceDetailContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

export default AccountInvoiceDetail;
