/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountSoldHistoryQuery } from "src/types/__generated__/QueriesSellerAccountSoldHistoryQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { SellerAccountSoldHistoryQuery } from "src/app/router/Queries";

import SellerAccountSoldHistoryView from "./SellerAccountSoldHistoryView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesSellerAccountSoldHistoryQuery>;
}>;

const SellerAccountSoldHistoryContent = ({
  queryRef,
}: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(SellerAccountSoldHistoryQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <SellerAccountSoldHistoryView queryKey={user} />;
};

export default SellerAccountSoldHistoryContent;
