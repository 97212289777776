/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { SBLink } from "./SBLink";

const MOBILE = "@media (max-width: 767px)";

export interface SBSideNavItem {
  id: string;
  label: string;
  path: string;
  selected: boolean;
}

type Props = Readonly<{
  isFixed?: boolean;
  isSubNav?: boolean;
  items: SBSideNavItem[];
  onChange?: (label: string) => void;
  style?: StyleXStyles;
}>;

export const SBSideNav = ({
  items,
  style,
  onChange,
  isFixed = true,
  isSubNav = false,
}: Props): React.ReactNode => {
  return (
    <div {...stylex.props(styles.sidebar, style)}>
      <nav
        {...stylex.props(auto, styles.categoriesNav, isFixed && styles.fixed)}
      >
        {items.map(({ path, id, selected, label }, ii) => (
          <div
            {...stylex.props(
              auto,
              styles.sideNavItem,
              ii === items.length - 1 && styles.sideNavItemLast,
              selected && styles.sideNavItemSelected,
            )}
            key={`nav-${id}`}
          >
            <SBLink
              {...stylex.props(
                auto,
                styles.sideNavLink,
                selected && styles.sideNavLinkSelected,
                isSubNav && styles.subNavLink,
              )}
              to={path}
              onClick={() => onChange && onChange(label)}
            >
              {label}
            </SBLink>
          </div>
        ))}
      </nav>
    </div>
  );
};

const styles = stylex.create({
  categoriesNav: {
    borderLeftColor: colors.navBorderColor,
    borderLeftStyle: "solid",
    borderLeftWidth: 1,
    display: {
      [MOBILE]: "none",
      default: "block",
    },
  },
  fixed: {
    position: "fixed",
  },
  sideNavItem: {
    marginBottom: 16,
    marginLeft: -1,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingTop: 4,
  },
  sideNavItemLast: {
    marginBottom: 8,
  },
  sideNavItemSelected: {
    borderLeftColor: colors.navBorderCurrentColor,
    borderLeftStyle: "solid",
    borderLeftWidth: 5,
    paddingLeft: 12,
  },
  sideNavLink: {
    color: colors.navLinkColor,
    textDecorationLine: "none",
  },
  sideNavLinkSelected: {
    color: colors.navLinkCurrentColor,
    fontWeight: 600,
  },
  sidebar: {
    gridColumn: "span 2 / span 2",
  },
  subNavLink: {
    fontSize: 14,
  },
});
