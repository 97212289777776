/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// @ts-nocheck

import type { Disposable, UseMutationConfig } from "react-relay";
import type { useLoginMutation } from "src/types/__generated__/useLoginMutation.graphql.ts";

import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay";

export const useLogin = (): [
  (config: UseMutationConfig<useLoginMutation>) => Disposable,

  boolean,
] => {
  const [commit, isInFlight] = useMutation<useLoginMutation>(graphql`
    mutation useLoginMutation($input: LogInInput!) {
      login(logInInput: $input) {
        accessToken
        refreshToken
        permissions
        user {
          id
        }
      }
    }
  `);

  return [commit, isInFlight];
};
