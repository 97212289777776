/**
 * @generated SignedSource<<31764508ce3bd6740c1b2b80b1425565>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PasswordResetFinalizeInput = {
  hash: string;
  password: string;
};
export type ResetPasswordResetMutationQuery$variables = {
  input: PasswordResetFinalizeInput;
};
export type ResetPasswordResetMutationQuery$data = {
  readonly passwordResetFinalize: {
    readonly message: string | null | undefined;
    readonly success: boolean;
  };
};
export type ResetPasswordResetMutationQuery = {
  response: ResetPasswordResetMutationQuery$data;
  variables: ResetPasswordResetMutationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "passwordResetFinalizeInput",
        "variableName": "input"
      }
    ],
    "concreteType": "PasswordResetFinalizeOutput",
    "kind": "LinkedField",
    "name": "passwordResetFinalize",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "message",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordResetMutationQuery",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordResetMutationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0cf1cab8cdbbd9c538b5da097bed624c",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordResetMutationQuery",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordResetMutationQuery(\n  $input: PasswordResetFinalizeInput!\n) {\n  passwordResetFinalize(passwordResetFinalizeInput: $input) {\n    message\n    success\n  }\n}\n"
  }
};
})();

(node as any).hash = "7bd0866300f426e9dac4dd62577e9949";

export default node;
