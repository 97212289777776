/**
 * @generated SignedSource<<85c8ddb5b1aa298af1de037497dbde6a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type OfferReason = "COUNTER_FROM_BUYER" | "COUNTER_FROM_SELLER" | "FAVORITE" | "NONE" | "%future added value";
export type OfferStatus = "ACCEPTED" | "DECLINED" | "EXPIRED" | "IN_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsSeller_product$data = {
  readonly canRequestAuctionConsignment: boolean;
  readonly canSubmitToCommunityAuctions: boolean;
  readonly favoritesCount: number;
  readonly id: string;
  readonly isViewerApiUser: boolean;
  readonly minOfferPercentage: number;
  readonly minOfferPrice: number | null | undefined;
  readonly offerCounts: {
    readonly favorite: number;
    readonly pending: number;
    readonly received: number;
  } | null | undefined;
  readonly sellerAcceptedOffers: {
    readonly count: number;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly currency: Currency;
        readonly expiresAt: string;
        readonly id: string;
        readonly price: number;
        readonly reason: OfferReason;
        readonly status: OfferStatus;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "ProductDetailActionsSeller_product";
};
export type ProductDetailActionsSeller_product$key = {
  readonly " $data"?: ProductDetailActionsSeller_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsSeller_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsSeller_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerApiUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "favoritesCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minOfferPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canRequestAuctionConsignment",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canSubmitToCommunityAuctions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductOfferCounts",
      "kind": "LinkedField",
      "name": "offerCounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pending",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "received",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "favorite",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "sellerAcceptedOffers",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "updatedAt": "DESC"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "status": "ACCEPTED"
          }
        }
      ],
      "concreteType": "ProductOffersConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ProductOffersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Offer",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "reason",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "price",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "expiresAt",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "offers(first:1,orderBy:{\"updatedAt\":\"DESC\"},where:{\"status\":\"ACCEPTED\"})"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "01bca10e251fb4a0fa734fed1889bfab";

export default node;
