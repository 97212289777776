/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { FaqsPaginationQuery } from "src/types/__generated__/FaqsPaginationQuery.graphql";
import type { FaqsViewQuery_faqs$key } from "src/types/__generated__/FaqsViewQuery_faqs.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";
import { useLocation, useNavigate } from "react-router-dom";

import { useIntersectionObserver } from "src/hooks";
import {
  SBAnchor,
  SBMarkdownRenderer,
  SBPageHeader,
  SBSideNav,
} from "src/sbxui";
import { HeadingLevel, SBHeading } from "src/sbxui/SBHeading";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";

export enum FaqCategory {
  ABOUT = "ABOUT",
  AUCTIONS = "AUCTIONS",
  BUYING = "BUYING",
  INTERNATIONAL = "INTERNATIONAL",
  PRICING_DATA = "PRICING_DATA",
  PUNCH_CLUB = "PUNCH_CLUB",
  SELLING = "SELLING",
}

export const faqCategorySortOrder = [
  FaqCategory.ABOUT,
  FaqCategory.BUYING,
  FaqCategory.SELLING,
  FaqCategory.AUCTIONS,
  FaqCategory.PRICING_DATA,
  FaqCategory.PUNCH_CLUB,
  FaqCategory.INTERNATIONAL,
];

type FaqByCategoryType = Record<
  string,
  { answer: string; id: string; question: string }[]
>;

type Props = Readonly<{
  queryKey: FaqsViewQuery_faqs$key;
}>;

const FaqsView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment<
    FaqsPaginationQuery,
    FaqsViewQuery_faqs$key
  >(
    graphql`
      fragment FaqsViewQuery_faqs on Query
      @argumentDefinitions(
        cursor: { type: "String", defaultValue: "" }
        count: { type: "Int", defaultValue: 1000 }
        orderBy: { type: FaqOrderByInput, defaultValue: { createdAt: ASC } }
      )
      @refetchable(queryName: "FaqsPaginationQuery") {
        faqs(after: $cursor, first: $count, orderBy: $orderBy)
          @connection(key: "FaqsViewQuery_faqs") {
          __id
          edges {
            node {
              id
              createdAt
              updatedAt
              question
              answer
              category
            }
          }
        }
      }
    `,
    queryKey,
  );
  const location = useLocation();
  const navigate = useNavigate();

  let initialCategory = location.hash.substring(1);
  if (initialCategory === "") {
    initialCategory = FaqCategory.ABOUT;
  }

  const [selectedCategory, setSelectedCategory] = useState<FaqCategory>(
    initialCategory as FaqCategory,
  );

  const navItems = faqCategorySortOrder.map((category) => {
    return {
      id: category,
      label: t(`faqs.categories.${category}`),
      path: `#${category}`,
      selected: selectedCategory === category,
    };
  });

  const handleCategoryChange = (selected: string) => {
    const selectedOption =
      navItems.find(({ label }) => label === selected) ?? null;

    if (selectedOption) {
      setSelectedCategory(selectedOption.id as FaqCategory);
      navigate(`#${selectedOption.id}`);
    }
  };

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  const faqsByCategory = data?.faqs?.edges?.reduce(
    (acc, { node: { category, answer, question, id } }) => {
      if (!(category in acc)) {
        acc[category] = [];
      }

      acc[category].push({ answer, id, question });

      return acc;
    },
    {} as FaqByCategoryType,
  );

  return (
    <>
      <SBPageHeader title={t("faqs.pageTitle")} />
      <div {...stylex.props(styles.root)}>
        <SBSideNav
          items={navItems}
          style={styles.sideNav}
          onChange={handleCategoryChange}
        />
        <article {...stylex.props(styles.main)}>
          {faqCategorySortOrder.map((category) => (
            <article key={category}>
              <SBAnchor id={category as unknown as string}>
                <SBHeading level={HeadingLevel.H2}>
                  {t(`faqs.categories.${category}`)}
                </SBHeading>
              </SBAnchor>
              {faqsByCategory[category as string].map(
                ({ answer, question, id }, ii) => (
                  <div
                    {...stylex.props(
                      auto,
                      styles.question,
                      ii === 0 && styles.questionFirst,
                    )}
                    key={id}
                  >
                    <SBAnchor id={kebabCase(question)}>
                      <SBHeading level={HeadingLevel.H3} style={styles.heading}>
                        {question}
                      </SBHeading>
                    </SBAnchor>
                    <SBMarkdownRenderer markdown={answer} />
                  </div>
                ),
              )}
            </article>
          ))}
        </article>
      </div>
    </>
  );
};

const styles = stylex.create({
  heading: {
    marginBottom: 24,
  },
  main: {
    gridColumn: "span 4 / span 4",
  },
  question: {
    borderBottomColor: colors.horizontalRuleColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    marginBlock: 64,
    paddingBottom: 48,
  },
  questionFirst: {
    marginTop: 20,
  },
  root: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      default: "repeat(6, 1fr)",
    },
  },
  sideNav: {
    marginBottom: 24,
  },
});

export default FaqsView;
