/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesHomeQuery } from "src/types/__generated__/QueriesHomeQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { HomeQuery } from "src/app/router/Queries";

import HomeView from "./HomeView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesHomeQuery>;
}>;

const HomeContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(HomeQuery, queryRef);

  return <HomeView queryKey={queryKey} />;
};

export default HomeContent;
