/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountPersonalInformationView_user$key } from "src/types/__generated__/AccountPersonalInformationView_user.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";

import AccountPersonalInfoForm from "./AccountPersonalInformationForm";

type Props = Readonly<{
  queryKey: AccountPersonalInformationView_user$key;
}>;

const AccountPersonalInformationView = ({
  queryKey,
}: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment AccountPersonalInformationView_user on User {
        ...AccountPersonalInformationForm_user
      }
    `,
    queryKey,
  );

  return <AccountPersonalInfoForm queryKey={data} />;
};

export default AccountPersonalInformationView;
