/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Appearance } from "@stripe/stripe-js";

import { useMemo } from "react";

export default function useStripeTheme(): {
  dark: Appearance;
  light: Appearance;
} {
  const theme = useMemo(
    () => ({
      dark: {
        rules: {
          ".Label": {
            color: "#ffffff",
          },
        },
        variables: {
          borderRadius: "24px",
          colorBackground: "#ffffff",
          colorDanger: "#fd1a1f",
          colorPrimary: "#1b1b1d",
          colorSuccess: "#4cd964",
          colorText: "#1b1b1d",
          colorTextPlaceholder: "#676767",
          colorWarning: "#f37500",
          spacingUnit: "4px",
        },
      },
      light: {
        variables: {
          borderRadius: "24px",
          colorBackground: "#ffffff",
          colorDanger: "#fd1a1f",
          colorPrimary: "#1b1b1d",
          colorSuccess: "#4cd964",
          colorText: "#1b1b1d",
          colorTextPlaceholder: "#808080",
          colorWarning: "#f37500",
          spacingUnit: "4px",
        },
      },
    }),
    [],
  );

  return theme;
}
