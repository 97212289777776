/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTransition } from "react";
import { Link, useNavigate } from "react-router-dom";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<
  React.PropsWithChildren<{
    className?: string;
    disabled?: boolean;
    external?: boolean;
    href?: string;
    onClick?: (event: React.SyntheticEvent) => void;
    style?: StyleXStyles;
    to?: string;
  }>
>;

export const SBLink = React.memo(
  ({
    children,
    disabled = false,
    external = false,
    href,
    onClick,
    style,
    to = "#",
    ...rest
  }: Props): React.ReactNode => {
    const navigate = useNavigate();
    const [isPending, startTransition] = useTransition();

    const handleClickLink = (event: React.SyntheticEvent) => {
      if (onClick != null) {
        event.preventDefault();
        onClick(event);
        startTransition(() => {
          navigate(to);
        });
      }
    };

    if (disabled) {
      return (
        <span
          {...stylex.props(
            auto,
            styles.disabled,
            isPending && styles.isPending,
            style,
          )}
          {...rest}
        >
          {children}
        </span>
      );
    }

    if (Boolean(href)) {
      return (
        <a
          {...stylex.props(
            auto,
            styles.link,
            isPending && styles.isPending,
            style,
          )}
          href={href ?? "#"}
          rel={external ? "noopener noreferrer" : undefined}
          target={external ? "_blank" : undefined}
          onClick={handleClickLink}
          {...rest}
        >
          {children}
        </a>
      );
    }

    return (
      <Link
        {...stylex.props(
          auto,
          styles.link,
          isPending && styles.isPending,
          style,
        )}
        rel={external ? "noopener noreferrer" : undefined}
        target={external ? "_blank" : undefined}
        to={to}
        onClick={handleClickLink}
        {...rest}
      >
        {children}
      </Link>
    );
  },
);

const styles = stylex.create({
  disabled: {
    borderRadius: 2,
    color: colors.linkColor,
    opacity: 0.4,
  },
  isPending: {
    opacity: 0.4,
  },
  link: {
    borderRadius: 2,
    color: {
      ":hover": colors.linkHoverColor,
      default: colors.linkColor,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
  },
});
