/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAuctionLotQuery } from "src/types/__generated__/QueriesAuctionLotQuery.graphql";

import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePreloadedQuery } from "react-relay";

import { AuctionLotQuery } from "src/app/router/Queries";
import { SBErrorPage } from "src/sbxui";

import AuctionLotView from "./AuctionLotView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAuctionLotQuery>;
}>;

const AuctionLotContent = ({ queryRef }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const queryKey = usePreloadedQuery(AuctionLotQuery, queryRef);

  if (queryKey.node == null) {
    return (
      <SBErrorPage
        description={t("notFound.description")}
        errorCode={404}
        title={t("notFound.pageTitle")}
      />
    );
  }

  return queryKey.node && <AuctionLotView queryKey={queryKey.node} />;
};

export default AuctionLotContent;
