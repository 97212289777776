/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { PHONE_RE } from "src/utils/constants";

export const prefixPhoneNumber = (
  phoneNumber: string,
  country: string,
): string => {
  const formatted = phoneNumber.replace(PHONE_RE, "");
  switch (country) {
    case "GB":
      return `+44${formatted}`;
    case "CA":
    case "US":
    default:
      return `+1${formatted}`;
  }
};
