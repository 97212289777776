/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { SBActivityIndicator } from "src/sbxui";
import { auto } from "src/themes";

const ProfileLinkFallback = (): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <div {...stylex.props(styles.root)} aria-label={t("login.fallback")}>
      <div {...stylex.props(auto, styles.activityIndicator)}>
        <SBActivityIndicator small={true} />
      </div>
    </div>
  );
};

const styles = stylex.create({
  activityIndicator: {
    alignItems: "center",
    display: "flex",
    height: 28,
    justifyContent: "center",
    textDecorationLine: "none",
    width: 28,
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
});

export default React.memo(ProfileLinkFallback);
