/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { type SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { LOGIN_PATH } from "src/app/router/Router";
import { ButtonType, ButtonVariation, SBButton } from "src/sbxui";

const ProductDetailActionsBrowser = (): React.ReactNode => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClickLogin = (event: SyntheticEvent) => {
    event.preventDefault();
    navigate(LOGIN_PATH);
  };

  return (
    <SBButton
      block={true}
      style={styles.button}
      title={t("product.header.buttons.login")}
      type={ButtonType.Button}
      variation={ButtonVariation.Default}
      onClick={handleClickLogin}
    />
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },

  inApp: {
    alignSelf: "center",
    fontSize: 14,
    marginTop: 16,
  },
});

export default ProductDetailActionsBrowser;
