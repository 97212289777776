/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  ProblemEnum,
  ReportProblemModalCreateProblemMutation,
} from "src/types/__generated__/ReportProblemModalCreateProblemMutation.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";

import { SBButton, SBModal, SBTextArea } from "src/sbxui";

type Props = Readonly<{
  isOpen: boolean;
  onSuccess: () => void;
  orderId: string;
  problemType: ProblemEnum;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

export const ReportProblemModal = ({
  orderId,
  isOpen,
  setOpen,
  onSuccess,
  problemType,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const [problem, setProblem] = useState<string>("");

  const [commit, isInFlight] =
    useMutation<ReportProblemModalCreateProblemMutation>(graphql`
      mutation ReportProblemModalCreateProblemMutation(
        $input: CreateProblemInput!
      ) {
        createProblem(createProblemInput: $input) {
          context
          problem
        }
      }
    `);

  const handleReportProblemSubmit = (): void => {
    commit({
      onCompleted: () => {
        onSuccess();
      },
      variables: {
        input: {
          context: problem,
          orderId,
          problem: problemType,
        },
      },
    });
  };

  return (
    <SBModal
      footer={
        <div {...stylex.props(styles.modalFooter)}>
          <SBButton
            disabled={isInFlight}
            loading={isInFlight}
            title={t("order-details.report-problem-modal.submit")}
            onClick={handleReportProblemSubmit}
          />
        </div>
      }
      headerText={t("order-details.report-problem-modal.title")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div {...stylex.props(styles.modal)}>
        <SBTextArea
          id="problem"
          label={t("order-details.report-problem-modal.textarea-label")}
          value={problem}
          onChange={(event) => setProblem(event.currentTarget.value)}
        />
      </div>
    </SBModal>
  );
};

const styles = stylex.create({
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
});
