/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

export enum SpecialCopyEnum {
  DOUBLE_COVER = "DOUBLE_COVER",
  INTERNATIONAL = "INTERNATIONAL",
  MARK_JEWELERS = "MARK_JEWELERS",
  NATIONAL_DIAMOND = "NATIONAL_DIAMOND",
  NEWSSTAND = "NEWSSTAND",
  TRIPLE_COVER = "TRIPLE_COVER",
}
