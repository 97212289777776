/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesOrderDetailQuery } from "src/types/__generated__/QueriesOrderDetailQuery.graphql";

import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { loadQuery } from "react-relay";

import { OrderDetailQuery } from "src/app/router/Queries";
import RelayEnvironment from "src/app/router/RelayEnvironment";

interface State {
  orderId: string | null;
  queryRef: PreloadedQuery<QueriesOrderDetailQuery> | null;
  setOrderId: (sellerId: string | null) => void;
}

const INITIAL_STATE: State = {
  orderId: null,
  queryRef: null,
  setOrderId: () => {
    return;
  },
};

export const OrderDetailContext: React.Context<State> =
  React.createContext(INITIAL_STATE);

type Props = React.PropsWithChildren;

export const OrderDetailProvider = ({ children }: Props): React.ReactNode => {
  const [orderIdInternal, setOrderIdInternal] = useState<string | null>(
    INITIAL_STATE.orderId,
  );
  const [queryRef, setQueryRef] =
    useState<PreloadedQuery<QueriesOrderDetailQuery> | null>(
      INITIAL_STATE.queryRef,
    );

  const setOrderId = useCallback((newOrderId: string | null) => {
    setOrderIdInternal(newOrderId);
    if (newOrderId == null) {
      setQueryRef(null);
    } else {
      const queryReference: PreloadedQuery<QueriesOrderDetailQuery> = loadQuery(
        RelayEnvironment,
        OrderDetailQuery,
        {
          id: parseInt(newOrderId, 10),
        },
        {
          fetchPolicy: "network-only",
        },
      );
      setQueryRef(queryReference);
    }
  }, []);

  const current = useMemo(
    () => ({
      orderId: orderIdInternal,
      queryRef,
      setOrderId,
    }),
    [orderIdInternal, queryRef, setOrderId],
  );

  return (
    <OrderDetailContext.Provider value={current}>
      {children}
    </OrderDetailContext.Provider>
  );
};
