/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";

import { config as GraphConfig } from "src/api/constants";
import { StorageKey } from "src/app/constants";
import { CONTACT_US_PATH } from "src/app/router/Router";
import {
  ButtonType,
  ButtonVariation,
  SBButton,
  SBIcon,
  SBLink,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { prefixPhoneNumber, refreshAnonymousToken } from "src/utils";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const PHONE_URL = `${GraphConfig.API_PROTOCOL}://${GraphConfig.API_HOST}/verify-phone/send`;
const CODE_URL = `${GraphConfig.API_PROTOCOL}://${GraphConfig.API_HOST}/verify-phone/check`;

const HTTP_UNAUTHORIZED = 401;
const HTTP_FORBIDDEN = 403;

const CODE_LENGTH = 4;

const SUCCESS_MESSAGE = "Code verification success.";

function isValidCode(code: string) {
  return code.length === CODE_LENGTH;
}

type Props = Readonly<{
  country: string;
  onSubmit: () => void;
  phoneNumber: string;
}>;

const SignupCode = ({
  country,
  onSubmit,
  phoneNumber,
}: Props): React.ReactNode | null => {
  const { t } = useTranslation();

  const controller = useRef(new AbortController());

  const [code, setCode] = useState("");
  const [isResending, setIsResending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resendCode = async (retry: boolean) => {
    setIsResending(true);
    controller.current = new AbortController();
    try {
      const anonymousKey = StorageKey.Anonymous;
      const anonymousData = localStorage.getItem(anonymousKey);
      const anonymous =
        anonymousData == null ? null : JSON.parse(anonymousData);
      const anonymousToken: string = anonymous?.anonymousToken;

      const response = await fetch(PHONE_URL, {
        body: JSON.stringify({
          phoneNumber: prefixPhoneNumber(phoneNumber, country),
        }),
        headers: {
          Authorization: `Bearer ${anonymousToken ?? ""}`,
          "Content-Type": "application/json; charset=utf-8",
        },
        method: "POST",
      });
      const json = await response.json();
      const statusCode = json?.error?.statusCode;
      const isTokenInvalid =
        statusCode === HTTP_UNAUTHORIZED || statusCode === HTTP_FORBIDDEN;
      if (isTokenInvalid && retry) {
        await refreshAnonymousToken();
        resendCode(false);
      } else if (json?.error != null) {
        setIsResending(false);
        const message = json.error ?? t("verify.errors.code");
        setErrorMessage(message);
        return;
      }
      setIsResending(false);
    } catch (error: unknown) {
      setIsResending(false);
      let message = t("verify.errors.default");
      if (error instanceof Error && error?.message) {
        message = error?.message;
      }
      setErrorMessage(message);
    }
  };

  const verifyCode = async (value: string, retry: boolean) => {
    if (isValidCode(value)) {
      setIsLoading(true);
      controller.current = new AbortController();
      try {
        const anonymousKey = StorageKey.Anonymous;
        const anonymousData = localStorage.getItem(anonymousKey);
        const anonymous =
          anonymousData == null ? null : JSON.parse(anonymousData);
        const anonymousToken: string = anonymous?.anonymousToken;

        const response = await fetch(CODE_URL, {
          body: JSON.stringify({
            code: value,
            phoneNumber: prefixPhoneNumber(phoneNumber, country),
          }),
          headers: {
            Authorization: `Bearer ${anonymousToken ?? ""}`,
            "Content-Type": "application/json; charset=utf-8",
          },
          method: "POST",
          signal: controller.current.signal,
        });
        const json = await response.json();
        const statusCode = json?.error?.statusCode;
        const isTokenInvalid =
          statusCode === HTTP_UNAUTHORIZED || statusCode === HTTP_FORBIDDEN;
        if (isTokenInvalid && retry) {
          await refreshAnonymousToken();
          verifyCode(code, false);
        } else if (json?.error != null) {
          setIsLoading(false);
          const message = json.error ?? t("verify.errors.code");
          setErrorMessage(message);
          return;
        }
        if (json?.message !== SUCCESS_MESSAGE) {
          setErrorMessage(t("verify.errors.code"));
          return;
        }
        onSubmit();
      } catch (error) {
        setIsLoading(false);
        setErrorMessage(t("verify.errors.code"));
      }
    }
  };

  const handleResendCode = (event: React.SyntheticEvent) => {
    event.preventDefault();
    resendCode(true);
  };

  const handleSubmitCode = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!isLoading) {
      verifyCode(code, true);
    }
  };

  return (
    <div {...stylex.props(styles.form)}>
      <form method="post">
        <div aria-live="polite">
          {errorMessage ? (
            <div {...stylex.props(auto, styles.error)}>
              <SBIcon icon="error" style={styles.errorIcon} />
              <SBParagraph style={styles.errorMessage}>
                {errorMessage}
              </SBParagraph>
            </div>
          ) : null}
        </div>
        <SBParagraph style={styles.code}>
          {t("verify.instructions")}
        </SBParagraph>
        <fieldset {...stylex.props(styles.fieldset)}>
          <OtpInput
            containerStyle={stylex.props(styles.otp).className}
            inputStyle={stylex.props(styles.otpInput).className}
            numInputs={CODE_LENGTH}
            renderInput={(props) => <input {...props} />}
            value={code}
            onChange={setCode}
          />
        </fieldset>
        <button
          {...stylex.props(auto, styles.resend)}
          type="button"
          onClick={handleResendCode}
        >
          <SBIcon icon="autorenew" style={styles.resendIcon} />
          <span {...stylex.props(auto, styles.resendLabel)}>
            {t("verify.buttons.resend")}
          </span>
        </button>
        <SBButton
          block={true}
          disabled={isResending}
          loading={isLoading}
          style={styles.verifyButton}
          title={t("verify.buttons.phone-number")}
          type={ButtonType.Submit}
          variation={ButtonVariation.Emphasis}
          onClick={handleSubmitCode}
        />
        <SBParagraph style={styles.help}>
          {t("verify.help.start")}
          <SBLink to={CONTACT_US_PATH}>
            {t("verify.help.contact-us-link")}
          </SBLink>
          {t("verify.help.end")}
        </SBParagraph>
      </form>
    </div>
  );
};

const styles = stylex.create({
  code: {
    marginBottom: 16,
    marginTop: 0,
    textAlign: "center",
  },
  disclaimer: {
    marginBottom: 16,
    marginTop: 0,
    textAlign: "center",
  },
  error: {
    alignItems: "flex-start",
    backgroundColor: colors.errorBackgroundColor,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 16,
    paddingBlock: 12,
    paddingInline: 16,
  },
  errorIcon: {
    color: colors.errorColor,
    marginRight: 8,
  },
  errorMessage: {
    color: colors.errorColor,
    fontSize: 16,
    lineHeight: 1.5,
    margin: 0,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  form: {
    padding: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 48,
    },
    position: "relative",
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: 448,
    },
  },
  help: {
    marginBlock: 0,
    textAlign: "center",
  },
  input: {
    marginBottom: 16,
  },
  otp: {
    alignContent: "center",
    alignItems: "center",
    display: "grid",
    gridGap: 16,
    gridTemplateColumns: "repeat(4, 1fr)",
    justifyContent: "center",
    justifyItems: "center",
    marginBottom: 16,
    width: "100%",
  },
  otpInput: {
    backgroundColor: colors.inputTextBackgroundColor,
    borderColor: colors.inputTextBorderColor,
    borderRadius: 48,
    borderStyle: "solid",
    height: 48,
    lineHeight: 24,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: "48px !important",
  },
  resend: {
    alignItems: "center",
    backgroundColor: "transparent",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    marginBottom: 16,
    marginInline: "auto",
    padding: 0,
  },
  resendIcon: {
    color: colors.color,
    marginRight: 4,
  },
  resendLabel: {
    margin: 0,
  },
  verifyButton: {
    marginBottom: 16,
  },
});

export default SignupCode;
