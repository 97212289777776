/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  favorite: boolean;
  loading: boolean;
  number: string;
  onClick: (event: React.SyntheticEvent) => void;
  style?: StyleXStyles;
  title: string;
}>;

export const SBFavoriteButton = React.memo(
  ({
    favorite,
    loading,
    number,
    onClick,
    style,
    title,
  }: Props): React.ReactNode => {
    const { t } = useTranslation();

    return (
      <button
        {...stylex.props(auto, styles.button, style)}
        aria-busy={loading}
        aria-label={t(`product.favorite.${favorite ? "remove" : "add"}`, {
          title: t("product.title", {
            number,
            title,
          }),
        })}
        type="button"
        onClick={onClick}
      >
        <SBIcon
          fill={favorite}
          icon="favorite"
          style={[
            favorite ? styles.selected : styles.unselected,
            loading && styles.loading,
          ]}
        />
      </button>
    );
  },
);

const styles = stylex.create({
  button: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: colors.favoriteIconBackgroundColor,
    borderColor: colors.favoriteIconBorderColor,
    borderRadius: 20,
    borderStyle: "solid",
    borderWidth: 2,
    cursor: "pointer",
    display: "flex",
    height: 40,
    justifyContent: "center",
    width: 40,
  },
  loading: {
    opacity: 0.4,
  },
  selected: {
    color: colors.favoriteIconSelectedColor,
    paddingLeft: 0.5,
    paddingTop: 0.5,
  },
  unselected: {
    color: colors.favoriteIconUnselectedColor,
    paddingLeft: 0.5,
    paddingTop: 0.5,
  },
});
