/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesShopQuery } from "src/types/__generated__/QueriesShopQuery.graphql";

import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { SBErrorBoundary, SBFallback } from "src/sbxui";

import ShopContent from "./ShopContent";

const Shop = (): React.ReactNode => {
  const { t } = useTranslation();

  const pageContext = useContext(PageContext);

  const queryRef = useLoaderData() as PreloadedQuery<QueriesShopQuery>;

  useEffect(() => {
    pageContext?.setTitle(t("shop.documentTitle"));
  }, [t, pageContext]);

  if (queryRef == null) {
    return null;
  }

  return (
    <SBErrorBoundary>
      <Suspense fallback={<SBFallback />}>
        <ShopContent queryRef={queryRef} />
      </Suspense>
    </SBErrorBoundary>
  );
};

export default Shop;
