/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useTranslation } from "react-i18next";

import { AUCTION_PATH } from "src/app/router/Router";
import { ButtonType, ButtonVariation, SBButton } from "src/sbxui";

type Props = Readonly<{
  auctionId: string;
}>;

export const AuctionSearchSeeMore = ({ auctionId }: Props): React.ReactNode => {
  const { t } = useTranslation();
  const destination = AUCTION_PATH.replace(":id", auctionId);

  return (
    <SBButton
      aria-label={t("auctions.see-more-alt", {
        title: "foo",
      })}
      title={t("auctions.see-more")}
      to={destination}
      type={ButtonType.Button}
      variation={ButtonVariation.Emphasis}
    />
  );
};

export default AuctionSearchSeeMore;
