/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountOffersActive_user$key } from "src/types/__generated__/AccountOffersActive_user.graphql";
import type { AccountOffersActivePaginationQuery } from "src/types/__generated__/AccountOffersActivePaginationQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator, SBInfoMessage } from "src/sbxui";

import AccountOfferCard from "../../../components/account/AccountOfferCard";

type Props = Readonly<{
  queryKey: AccountOffersActive_user$key;
}>;

const AccountOffersActive = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment<
    AccountOffersActivePaginationQuery,
    AccountOffersActive_user$key
  >(
    graphql`
      fragment AccountOffersActive_user on User
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 20 }
        orderBy: { type: OfferOrderByInput, defaultValue: { createdAt: DESC } }
      )
      @refetchable(queryName: "AccountOffersActivePaginationQuery") {
        activeOffers: buyerOffers(
          after: $cursor
          first: $count
          orderBy: $orderBy
          where: { situation: ACTIVE }
        ) @connection(key: "AccountOffersActive_activeOffers") {
          __id
          edges {
            node {
              ...AccountOfferCard_offer
              id
            }
          }
        }
      }
    `,
    queryKey,
  );

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  const offers = data?.activeOffers?.edges ?? [];

  if (offers.length === 0) {
    return (
      <SBInfoMessage
        message={t("account.offers.no-offers")}
        style={styles.infoMessage}
      />
    );
  }

  return (
    <>
      {offers?.map(({ node }) => (
        <AccountOfferCard key={node.id} queryKey={node} />
      ))}

      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  infoMessage: {
    marginTop: 16,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
});

export default AccountOffersActive;
