/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountOrdersView_user$key } from "src/types/__generated__/AccountOrdersView_user.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";

import { OrderDetailContext } from "src/app/context/order-detail";
import { ACCOUNT_ORDER_DETAILS_PATH } from "src/app/router/Router";
import {
  DateFormatType,
  HeadingLevel,
  SBDate,
  SBHeading,
  SBImage,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountOrdersView_user$key;
}>;

const AccountOrdersView = ({ queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const [isPending, startTransition] = useTransition();

  const { setOrderId } = useContext(OrderDetailContext);

  const user = useFragment(
    graphql`
      fragment AccountOrdersView_user on User {
        orders(first: 100, orderBy: { createdAt: DESC }) {
          edges {
            node {
              id
              surrogateId
              createdAt
              publicStatus
              total
              currency
              product {
                images(first: 1) {
                  edges {
                    node {
                      url(quality: 100, width: 300, webp: true)
                    }
                  }
                }
                comicDetails {
                  title
                  number
                  gradingAuthority
                  grade
                }
              }
            }
          }
        }
      }
    `,
    queryKey,
  );

  const handleClickLink =
    (surrogateId: number) => (event: React.SyntheticEvent) => {
      event.preventDefault();
      startTransition(() => {
        history.pushState(
          {},
          "",
          ACCOUNT_ORDER_DETAILS_PATH.replace(":id", surrogateId.toString()),
        );
        setOrderId(surrogateId.toString());
      });
    };

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("account.ORDERS")}
      </SBHeading>
      {user?.orders?.edges?.map(({ node }) => {
        const {
          product,
          total,
          currency,
          publicStatus,
          createdAt,
          surrogateId,
        } = node;
        const { comicDetails, images } = product ?? {};
        const { title, gradingAuthority, grade, number } = comicDetails ?? {};

        return (
          <Link
            key={node.id}
            {...stylex.props(
              auto,
              styles.link,
              isPending && styles.linkIsPending,
            )}
            to={ACCOUNT_ORDER_DETAILS_PATH.replace(
              ":id",
              surrogateId.toString(),
            )}
            onClick={handleClickLink(surrogateId)}
          >
            <div {...stylex.props(styles.order)}>
              <div>
                <SBImage
                  alt=""
                  height={200}
                  src={images?.edges[0]?.node?.url ?? ""}
                />
              </div>
              <div>
                <div>
                  {t("account.orders.comicDetails1", { number, title })}
                </div>
                <div>
                  {t("account.orders.comicDetails2", {
                    grade: grade?.toFixed(1),
                    gradingAuthority,
                  })}
                </div>
                <div>{formatMoney(total, currency, i18n.language)}</div>
                <div>{publicStatus}</div>
                <div>
                  <SBDate dateString={createdAt} format={DateFormatType.Long} />
                </div>
              </div>
            </div>
          </Link>
        );
      })}
    </>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
  link: {
    borderRadius: 8,
    display: "block",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  linkIsPending: {
    opacity: 0.4,
  },
  order: {
    backgroundColor: colors.backgroundEmphasisColor,
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(2, 1fr)",
      default: "repeat(4, 1fr)",
    },
    marginBottom: 10,
    padding: 12,
  },
});

export default AccountOrdersView;
