/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { MutableRefObject } from "react";

import { useEffect } from "react";

type Ref = MutableRefObject<HTMLElement | null>;
type Callback = (entries: IntersectionObserverEntry[]) => void;

export const useIntersectionObserver = (ref: Ref, callback: Callback) => {
  useEffect(() => {
    const target = ref.current;

    const observer = new IntersectionObserver(callback);

    if (target != null) {
      observer.observe(target);
    }

    return () => {
      if (target != null) {
        observer.unobserve(target);
      }
    };
  });
};
