/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import {
  getPhoneMaxLengthByCountry,
  getPhoneMinLengthByCountry,
} from "src/utils";
import { PHONE_RE } from "src/utils/constants";

export const isValidPhoneNumber = (phoneNumber: string, country: string) => {
  const length = phoneNumber.replace(PHONE_RE, "").length;
  switch (country) {
    case "CA":
    case "GB":
    case "US":
    default:
      return (
        length <= getPhoneMaxLengthByCountry(country) &&
        length >= getPhoneMinLengthByCountry(country)
      );
  }
};
