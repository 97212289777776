/**
 * @generated SignedSource<<f2fcf49cbd980e52c09df3e4e66eaf49>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type useHotConfigsQuery$variables = Record<PropertyKey, never>;
export type useHotConfigsQuery$data = {
  readonly hotConfigs: {
    readonly data: any | null | undefined;
  };
};
export type useHotConfigsQuery = {
  response: useHotConfigsQuery$data;
  variables: useHotConfigsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "HotConfigForGraphQL",
    "kind": "LinkedField",
    "name": "hotConfigs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useHotConfigsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useHotConfigsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "45b9cd62e261212cdd3d9c1e4b8a610a",
    "id": null,
    "metadata": {},
    "name": "useHotConfigsQuery",
    "operationKind": "query",
    "text": "query useHotConfigsQuery {\n  hotConfigs {\n    data\n  }\n}\n"
  }
};
})();

(node as any).hash = "2c371ded2d20d21174c5b9202227e4d9";

export default node;
