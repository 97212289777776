/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { CounterOfferContentQuery } from "src/types/__generated__/CounterOfferContentQuery.graphql";
import type { CounterOfferFrom } from "./CounterOfferFrom";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import CounterOfferView from "./CounterOfferView";

type Props = Readonly<{
  offerFrom: CounterOfferFrom;
  offerId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const CounterOfferContent = ({
  offerFrom,
  offerId,
  setOpen,
}: Props): React.ReactNode | null => {
  const data = useLazyLoadQuery<CounterOfferContentQuery>(
    graphql`
      query CounterOfferContentQuery($id: ID!) {
        ...CounterOfferView_offer
      }
    `,
    {
      id: offerId ?? "",
    },
  );

  return (
    <CounterOfferView offer={data} offerFrom={offerFrom} setOpen={setOpen} />
  );
};

export default CounterOfferContent;
