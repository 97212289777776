/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";

import { colors } from "src/themes/colors.stylex";

const DARK = "@media (prefers-color-scheme: dark)";

export const auto = stylex.createTheme(colors, {
  /* Main */
  backgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#eeeeee",
  },
  backgroundEmphasisColor: {
    [DARK]: "#222224",
    default: "#ffffff",
  },
  backgroundPlaceholderColor: {
    [DARK]: "#2b2b2e",
    default: "#f3f3f3",
  },
  buttonBackgroundColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  buttonColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  buttonEmphasisBackgroundColor: "#fd1a1f",
  buttonEmphasisColor: "#ffffff",
  color: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  colorEmphasis: "#fd1a1f",
  colorMuted: {
    [DARK]: "#a8a8aa",
    default: "#717171",
  },
  errorBackgroundColor: "#fd1a1f",
  errorColor: "#ffffff",
  favoriteIconBackgroundColor: "#1b1b1d",
  favoriteIconBorderColor: "#ffffff",
  favoriteIconSelectedColor: "#fd1a1f",
  favoriteIconUnselectedColor: "#ffffff",
  headlessImageBackgroundColor: "#1b1b1d",
  horizontalRuleColor: {
    [DARK]: "#ffffff",
    default: "#c7c7c7",
  },
  linkColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  linkHoverColor: "#fd1a1f",
  metaBorderColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  modalBackdropBackgroundColor: {
    [DARK]: "#2b2b2e",
    default: "#1b1b1d",
  },
  modalBackgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  modalBorderColor: {
    [DARK]: "#2f2f31",
    default: "#d8d8d8",
  },
  outline: "2px solid #ff1c22",
  successBackgroundColor: "#4cd964",
  successColor: "#ffffff",

  /* Carousel */
  carouselBackgroundColor: "#1b1b1d",
  carouselColor: "#ffffff",
  carouselDotBorderColor: "#ffffff",
  carouselDotColor: "rgb(0, 0, 0, 0.28)",
  carouselDotCurrentColor: "#1b1b1d",

  /* Charts */
  chartBackgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  chartBorderColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  chartColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  chartGridLineColor: {
    [DARK]: "#4f4f4f",
    default: "#c7c7c7",
  },
  chartLineColor: "#fd1a1f",

  /* FMV ranks */
  fmvRankDefaultBackgroundColor: "#f37500",
  fmvRankDefaultColor: "#ffffff",
  fmvRankGoodBackgroundColor: "#f3bf00",
  fmvRankGoodColor: "#ffffff",
  fmvRankGreatBackgroundColor: "#4cd964",
  fmvRankGreatColor: "#ffffff",
  fmvRankPoorBackgroundColor: "#ff1c22",
  fmvRankPoorColor: "#ffffff",

  /* Footer */
  footerBackgroundColor: "#1b1b1d",
  footerBorderColor: "#2f2f31",
  footerBottomLinkColor: "#ffffff",
  footerColor: "#919192",
  footerEmphasisColor: "#ffffff",

  /* Home carousel */
  homeCarouselBackgroundColor: "#151515",
  homeCarouselColor: "#ffffff",

  /* Inputs */
  inputTextBackgroundColor: "#ffffff",
  inputTextBorderColor: "#d8d8d8",
  inputTextColor: "#1b1b1d",
  inputTextIconColor: "#666666",
  inputTextOptionBorderColor: {
    [DARK]: "#676767",
    default: "#d8d8d8",
  },
  inputTextOutline: "2px solid #ff1c22",
  inputTextPlaceholderColor: {
    [DARK]: "#676767",
    default: "#808080",
  },

  /* Auction Tiles */
  auctionTileDarkColor: "#ffffff",
  auctionTileLightColor: "#1b1b1d",
  auctionTileStatusBackgroundColor: "#1b1b1d",
  auctionTileStatusColor: "#ffffff",
  auctionTileStatusDotClosedColor: "#ff1c22",
  auctionTileStatusDotOpenColor: "#4cd964",
  auctionTileStatusDotPreviewColor: "#979797",

  /* Navigation */
  navBorderColor: {
    [DARK]: "#535353",
    default: "#1b1b1d",
  },
  navBorderCurrentColor: "#fd1a1f",
  navLinkColor: {
    [DARK]: "#414143",
    default: "#959595",
  },
  navLinkCurrentColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  navLinkHoverColor: "#1b1b1d",

  /* Sign up */
  signUpLogo: {
    [DARK]: "url('https://www-cdn.shortboxed.com/sbx-logo-white.webp')",
    default: "url('https://www-cdn.shortboxed.com/sbx-logo-black.webp')",
  },

  /* Social icons */
  socialFacebook: {
    [DARK]: "url('https://www-cdn.shortboxed.com/social-fb-dark.webp')",
    default: "url('https://www-cdn.shortboxed.com/social-fb.webp')",
  },
  socialInstagram: {
    [DARK]: "url('https://www-cdn.shortboxed.com/social-insta-dark.webp')",
    default: "url('https://www-cdn.shortboxed.com/social-insta.webp')",
  },
  socialX: {
    [DARK]: "url('https://www-cdn.shortboxed.com/social-x-dark.webp')",
    default: "url('https://www-cdn.shortboxed.com/social-x.webp')",
  },

  /* SSO buttons */
  ssoAppleBackgroundColor: "#ffffff",
  ssoAppleBorderColor: "#000000",
  ssoAppleColor: "#000000",
  ssoFacebookBackgroundColor: "#3975ea",
  ssoFacebookBorderColor: "#3975ea",
  ssoFacebookColor: "#ffffff",
  ssoGoogleBackgroundColor: "#ffffff",
  ssoGoogleBorderColor: "#747775",
  ssoGoogleColor: "#1f1f1f",

  /* Tables */
  tableBackgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  tableBorderColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  tableColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  tableEvenBackgroundColor: {
    [DARK]: "#222224",
    default: "#ffffff",
  },
  tableFooterColor: {
    [DARK]: "#ffffff",
    default: "#666666",
  },
  tableOddBackgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#ededef",
  },

  /* Takeovers */
  takeoverBackgroundColor: "#1b1b1d",
  takeoverBorderColor: "#ffffff",
  takeoverButtonBackgroundColor: "#ff1c22",
  takeoverButtonColor: "#ffffff",
  takeoverColor: "#ffffff",
  takeoverMutedColor: "#626262",

  /* Top navigation */
  topNavigationBackgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  topNavigationColor: {
    [DARK]: "#fcfcfc",
    default: "#1c1c1e",
  },
  topNavigationIconColor: {
    [DARK]: "#fcfcfc",
    default: "#1c1c1e",
  },

  topNavigationLogo: {
    [DARK]: "url('https://www-cdn.shortboxed.com/logo-dark.webp')",
    default: "url('https://www-cdn.shortboxed.com/logo.webp')",
  },

  topNavigationMenuBackgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  topNavigationMenuBorderColor: {
    [DARK]: "#d7d7d7",
    default: "#d8d8d8",
  },
  topNavigationMenuColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  topNavigationMenuDotColor: {
    [DARK]: "#ff1a20",
    default: "#fa1b20",
  },
  topNavigationMenuProfileBackgroundColor: {
    [DARK]: "#ffffff",
    default: "#1b1b1d",
  },
  topNavigationMenuProfileColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  topNavigationMenuShadow: {
    [DARK]: "0 0 4px rgba(0, 0, 0, 0.16)",
    default: "0 4px 4px rgba(0, 0, 0, 0.16)",
  },

  topNavigationSearchBackgroundColor: "#ffffff",
  topNavigationSearchBorderColor: {
    [DARK]: "#d7d7d7",
    default: "#d8d8d8",
  },
  topNavigationSearchBorderFocusedColor: {
    [DARK]: "#d7d7d7",
    default: "#d8d8d8",
  },
  topNavigationSearchButtonBackgroundColor: "#1b1b1d",
  topNavigationSearchButtonColor: {
    [DARK]: "#fcfcfd",
    default: "#ffffff",
  },
  topNavigationSearchButtonColorMobile: {
    [DARK]: "#fcfcfd",
    default: "#1b1b1d",
  },
  topNavigationSearchClearColor: {
    [DARK]: "#a8a8aa",
    default: "#717171",
  },
  topNavigationSearchColor: "#1b1b1d",
  topNavigationSearchPlaceholderColor: "#656565",
  topNavigationSearchShadow: {
    [DARK]: "0 0 4px rgba(0, 0, 0, 0.04)",
    default: "0 4px 4px rgba(0, 0, 0, 0.04)",
  },

  topNavigationShadow: {
    [DARK]: "0 1px 01px #535355",
    default: "0 0 4px rgba(0, 0, 0, 0.04)",
  },

  topNavigationTabActiveBackgroundColor: {
    [DARK]: "#ff1a20",
    default: "#1b1b1d",
  },
  topNavigationTabActiveColor: "#ffffff",
  topNavigationTabBackgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  topNavigationTabColor: {
    [DARK]: "#959595",
    default: "#676767",
  },
  topNavigationTabHoverBackgroundColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
  topNavigationTabHoverColor: {
    [DARK]: "#fcfcfd",
    default: "#1b1b1d",
  },

  /* Watch icons */
  watchIconSelectedColor: "#fd1a1f",
  watchIconUnselectedColor: {
    [DARK]: "#1b1b1d",
    default: "#ffffff",
  },
});
