/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAuctionSearchQuery } from "src/types/__generated__/QueriesAuctionSearchQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AuctionSearchQuery } from "src/app/router/Queries";

import AuctionSearchView from "./AuctionSearchView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAuctionSearchQuery>;
}>;

const AuctionSearchContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AuctionSearchQuery, queryRef);

  return <AuctionSearchView queryKey={queryKey} />;
};

export default AuctionSearchContent;
