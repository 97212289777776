/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAuctionQuery } from "src/types/__generated__/QueriesAuctionQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import AuctionContent from "./AuctionContent";
import AuctionLotSearch from "./AuctionLotSearch";

const Auction = (): React.ReactNode | null => {
  const queryRef = useLoaderData() as PreloadedQuery<QueriesAuctionQuery>;

  if (queryRef == null) {
    return null;
  }

  return (
    <div aria-live="polite">
      <div {...stylex.props(styles.search)}>
        <AuctionLotSearch />
      </div>
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <AuctionContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

const styles = stylex.create({
  search: {
    marginBottom: 40,
  },
});

export default Auction;
