/**
 * @generated SignedSource<<976d360d08e5fce338cf717efe6c1ae0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductFeedbackModal_product$data = {
  readonly comicDetails: {
    readonly gradingAuthority: GradingAuthority;
  } | null | undefined;
  readonly id: string;
  readonly " $fragmentType": "ProductFeedbackModal_product";
};
export type ProductFeedbackModal_product$key = {
  readonly " $data"?: ProductFeedbackModal_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductFeedbackModal_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductFeedbackModal_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "70e3338423e75ecac85f72c2884b6f55";

export default node;
