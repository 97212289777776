/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountOrdersQuery } from "src/types/__generated__/QueriesAccountOrdersQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AccountOrdersQuery } from "src/app/router/Queries";

import AccountOrdersView from "./AccountOrdersView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountOrdersQuery>;
}>;

const AccountOrdersContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AccountOrdersQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <AccountOrdersView queryKey={user} />;
};

export default AccountOrdersContent;
