/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { SBActivityIndicator, SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  loading: boolean;
}>;

const Login = ({ loading }: Props): React.ReactNode => {
  return loading ? (
    <div {...stylex.props(styles.container)}>
      <SBActivityIndicator
        containerStyle={styles.activityIndicator}
        small={true}
      />
    </div>
  ) : (
    <div {...stylex.props(auto, styles.container, styles.profile)}>
      <SBIcon fill={false} icon="account_circle" style={styles.avatar} />
    </div>
  );
};

const styles = stylex.create({
  activityIndicator: {},
  avatar: {
    color: colors.topNavigationMenuProfileColor,
  },
  container: {
    alignItems: "center",
    display: "flex",
    height: 28,
    justifyContent: "center",
    textDecorationLine: "none",
    width: 28,
  },
  icon: {
    color: colors.topNavigationMenuColor,
  },
  profile: {
    backgroundColor: colors.topNavigationMenuProfileBackgroundColor,
    borderRadius: 28,
  },
});

export default React.memo(Login);
