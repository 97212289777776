/**
 * @generated SignedSource<<a3fb9cb4eba122760f36a53017f2638e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SellerStatus = "APPROVED" | "DEACTIVATED" | "IN_REVIEW" | "SUSPENDED" | "VACATION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProfileLinkView_user$data = {
  readonly firstName: string | null | undefined;
  readonly id: string;
  readonly lastName: string | null | undefined;
  readonly sellerStatus: SellerStatus | null | undefined;
  readonly " $fragmentType": "ProfileLinkView_user";
};
export type ProfileLinkView_user$key = {
  readonly " $data"?: ProfileLinkView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProfileLinkView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProfileLinkView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sellerStatus",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e1412c8e310a78632e85cc3c04d23ed0";

export default node;
