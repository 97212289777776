/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { useHotConfigs } from "./useHotConfigs";

export const useOfferExpiryHours = (): number => {
  const data = useHotConfigs();

  const offerExpiryDays = data?.OFFER_EXPIRY_DAYS;

  return offerExpiryDays == null ? 0 : Number.parseFloat(offerExpiryDays) * 24;
};
