/**
 * @generated SignedSource<<c9b8664df9322a9e3e5d51315c0d321b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountOffersView_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountOffersActive_user" | "AccountOffersEnded_user">;
  readonly " $fragmentType": "AccountOffersView_user";
};
export type AccountOffersView_user$key = {
  readonly " $data"?: AccountOffersView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountOffersView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountOffersView_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountOffersActive_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountOffersEnded_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "569c53f64437710b1dd8c4226688c6b4";

export default node;
