/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { CounterOfferView_offer$key } from "src/types/__generated__/CounterOfferView_offer.graphql";
import type { CounterOfferFrom } from "./CounterOfferFrom";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";

import {
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBLink,
  SBParagraph,
  SBTextInput,
} from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

const RAW_SHIPPING_COST = 8;
const GRADED_SHIPPING_COST = 15;

enum Step {
  Offer,
  Confirm,
  Success,
}

type Props = Readonly<{
  offer: CounterOfferView_offer$key;
  offerFrom: CounterOfferFrom;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const CounterOfferView = ({ offer, setOpen }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const [commit, isInFlight] = useMutation(graphql`
    mutation CounterOfferViewMutation($input: CounterOfferInput!) {
      counterOffer(counterOfferInput: $input) {
        ... on Offer {
          id
          product {
            ...ProductDetailView_product
          }
        }
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment CounterOfferView_offer on Query {
        node(id: $id) {
          ... on Offer {
            id
            comicDetails {
              grade
              gradingAuthority
              title
              variant
              publisher
              number
            }
            price
            currency
            product {
              id
              price
              minOfferPrice
              minOfferPercentage
            }
          }
        }
      }
    `,
    offer,
  );

  const offerId = data.node?.id;
  const offerPrice = data.node?.price ?? 0;
  const minOfferPrice = data.node?.product?.minOfferPrice;
  const minOfferPercentage = data.node?.product?.minOfferPercentage;
  const comicDetails = data.node?.comicDetails;

  const askingPrice = data.node?.product?.price ?? 0;
  const currency = data.node?.currency ?? "USD";

  const gradingAuthority = comicDetails?.gradingAuthority;

  const shippingCost =
    gradingAuthority === "RAW" ? RAW_SHIPPING_COST : GRADED_SHIPPING_COST;

  const [newOfferPrice, setNewOfferPrice] = useState("");
  const [step, setStep] = useState(Step.Offer);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleOfferChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOfferPrice(event.currentTarget.value);
    setErrorMessage(null);
  };

  const onPressReview = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (Number(newOfferPrice) >= Number(askingPrice)) {
      setErrorMessage(t("make-offer.errors.too-high"));
      return;
    }
    if (Number(newOfferPrice) >= Number(offerPrice)) {
      setErrorMessage(t("make-offer.errors.too-high-previous"));
      return;
    }
    if (minOfferPrice != null) {
      if (Number(newOfferPrice) < Number(minOfferPrice)) {
        setErrorMessage(
          t("make-offer.errors.too-low.price", {
            value: formatMoney(minOfferPrice, currency, i18n.language),
          }),
        );
        return;
      }
    } else if (minOfferPercentage != null) {
      if (
        Number(newOfferPrice) <
        (Number(minOfferPercentage) / 100) * Number(askingPrice)
      ) {
        setErrorMessage(
          t("make-offer.errors.too-low.percent", {
            value: minOfferPercentage,
          }),
        );
        return;
      }
    }
    setStep(Step.Confirm);
  };

  const onPressClose = () => {
    setOpen(false);
  };

  const onPressConfirm = () => {
    toast.info(t("make-offer.offer.sent"), {
      position: "top-center",
    });
    commit({
      onCompleted() {
        setStep(Step.Success);
      },
      onError(error) {
        setStep(Step.Offer);
        setErrorMessage(
          // @ts-ignore This is the localized message from the error response.
          error?.res?.errors?.[0].message ?? t("offers.submit.errors.default"),
        );
      },
      variables: {
        input: {
          offerId,
          price: Number(newOfferPrice),
        },
      },
    });
  };

  let content = null;
  if (step === Step.Offer) {
    content = (
      <>
        {" "}
        <div {...stylex.props(styles.askingPriceContainer)}>
          <SBParagraph style={styles.askingPriceTitle}>
            {t("modal.offer.title.counter-offer")}
          </SBParagraph>
          <SBParagraph style={styles.askingPrice}>
            {formatMoney(offerPrice, currency, i18n.language)}
          </SBParagraph>
        </div>
        <SBTextInput
          errorMessage={errorMessage}
          id="offer"
          inputStyle={styles.inputStyle}
          label={t("product.make-offer")}
          maxLength={7}
          placeholder={t("forms.fields.price")}
          type="number"
          value={newOfferPrice}
          onChange={handleOfferChange}
        />
        <SBButton
          block={true}
          loading={isInFlight}
          style={styles.button}
          title={t("make-offer.buttons.review")}
          variation={ButtonVariation.Emphasis}
          onClick={onPressReview}
        />
      </>
    );
  }

  if (step === Step.Confirm) {
    content = (
      <>
        {" "}
        <div {...stylex.props(styles.confirmationDetailsContainer)}>
          <div {...stylex.props(styles.detailsRow)}>
            <SBParagraph style={styles.details}>
              {t("make-offer.buyer-offer")}
            </SBParagraph>
            <SBParagraph style={styles.details}>
              {formatMoney(Number(newOfferPrice), currency, i18n.language)}
            </SBParagraph>
          </div>
          <div {...stylex.props(styles.detailsRow)}>
            <SBParagraph style={styles.details}>
              {t("make-offer.confirm.shipping")}
            </SBParagraph>
            <SBParagraph style={styles.details}>
              {formatMoney(Number(shippingCost), currency, i18n.language)}
            </SBParagraph>
          </div>
          <div {...stylex.props(styles.detailsRow)}>
            <SBParagraph style={styles.details}>
              {t("model.offer.title.taxes")}
            </SBParagraph>
            <SBParagraph style={styles.details}>
              {t("model.offer.title.taxes-subtext")}
            </SBParagraph>
          </div>
          <div {...stylex.props(styles.detailsRow)}>
            <SBParagraph style={styles.details}>
              {t("make-offer.confirm.total")}
            </SBParagraph>
            <SBParagraph style={styles.details}>
              {t("make-offer.confirm.total-approx", {
                total: formatMoney(
                  Number(newOfferPrice) + shippingCost,
                  currency,
                  i18n.language,
                ),
              })}
            </SBParagraph>
          </div>
          <div {...stylex.props(styles.detailsRow)}>
            <SBParagraph style={styles.detailsAgreement}>
              {t("make-offer.confirm.agreement")}
            </SBParagraph>
          </div>
        </div>
        <SBButton
          block={true}
          loading={isInFlight}
          style={styles.button}
          title={t("common.buttons.confirm")}
          variation={ButtonVariation.Emphasis}
          onClick={onPressConfirm}
        />
      </>
    );
  }

  if (step === Step.Success) {
    content = (
      <>
        {" "}
        <SBHeading level={HeadingLevel.H5} style={styles.submitted}>
          {t("make-offer.offer.sent")}
        </SBHeading>
        <SBParagraph style={styles.submittedSubtext}>
          {t("make-offer.offer.sent-subtext")}
        </SBParagraph>
        <SBButton
          block={true}
          loading={isInFlight}
          style={styles.button}
          title={t("common.buttons.ok")}
          variation={ButtonVariation.Emphasis}
          onClick={onPressClose}
        />
      </>
    );
  }

  return (
    <div {...stylex.props(styles.container)}>
      {content}

      <SBLink style={styles.close} onClick={onPressClose}>
        {t("common.buttons.close")}
      </SBLink>
    </div>
  );
};

const styles = stylex.create({
  askingPrice: {
    color: colors.colorEmphasis,
  },
  askingPriceContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    justifyContent: "center",
  },
  askingPriceTitle: {
    color: colors.color,
    paddingRight: 8,
  },
  button: {
    marginTop: 16,
  },
  close: {
    color: colors.color,
    fontSize: 16,
    marginTop: 16,
  },
  confirm: {
    fontWeight: 600,
  },
  confirmationDetailsContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 16,
    width: "100%",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  details: {
    fontWeight: 400,
  },
  detailsAgreement: {
    color: colors.colorMuted,
    fontSize: 14,
  },
  detailsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  errorMessage: {
    paddingTop: 16,
  },
  inputStyle: {
    textAlign: "center",
  },
  submitted: {
    color: colors.color,
    fontWeight: 600,
    marginTop: 16,
  },
  submittedSubtext: {
    color: colors.colorMuted,
    fontSize: 14,
    marginTop: 16,
  },
  titleDetails: {
    fontWeight: 600,
  },
});

export default CounterOfferView;
