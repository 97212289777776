/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<
  React.PropsWithChildren<{
    style?: StyleXStyles;
  }>
>;

export const SBParagraph = ({
  children,
  style,
  ...rest
}: Props): React.ReactNode => {
  return (
    <p {...stylex.props(auto, styles.root, style)} {...rest}>
      {children}
    </p>
  );
};

const styles = stylex.create({
  root: {
    color: colors.color,
    fontSize: 16,
    margin: 0,
    marginBottom: 16,
  },
});
