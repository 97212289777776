/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductPublicStatus } from "src/types/__generated__/ProductDetailActions_product.graphql";

import * as stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HeadingLevel, SBHeading } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  publicStatus: ProductPublicStatus;
}>;

const ProductDetailActionsBuyerUnavailable = ({
  publicStatus,
}: Props): React.ReactNode => {
  const { t } = useTranslation();
  return (
    <SBHeading level={HeadingLevel.H3} style={styles.unavailable}>
      {t(`product.status.${kebabCase(publicStatus)}`)}
    </SBHeading>
  );
};

const styles = stylex.create({
  unavailable: {
    color: colors.colorMuted,
  },
});

export default ProductDetailActionsBuyerUnavailable;
