/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  Currency,
  SendFavoritesOfferView_product$key,
} from "src/types/__generated__/SendFavoritesOfferView_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import { useOfferExpiryHours } from "src/hooks";
import {
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBIcon,
  SBParagraph,
  SBTextInput,
  TextInputType,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const PRICE_RE = /\D/gu;
const PRICE_MAX_LENGTH = 6;

enum Step {
  Edit,
  Confirm,
}

type Props = Readonly<{
  queryKey: SendFavoritesOfferView_product$key | null | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: (title: string) => void;
}>;

const SendFavoritesOfferView = ({
  queryKey,
  setOpen,
  setTitle,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const [commit, isInFlight] = useMutation(graphql`
    mutation SendFavoritesOfferViewMutation(
      $input: CreateFavoritesOffersInput!
    ) {
      createFavoritesOffers(createFavoritesOffersInput: $input) {
        ... on Product {
          id
          ...ProductDetailView_product
        }
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment SendFavoritesOfferView_product on Product {
        id
        comicDetails {
          grade
          gradingAuthority
          title
          variant
          publisher
          number
        }
        price
        currency
        favoritesCount
      }
    `,
    queryKey,
  );

  const productId = data?.id;

  const askingPrice = data?.price ?? 0;
  const currency: Currency = data?.currency ?? "USD";

  const count = data?.favoritesCount ?? 0;

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.languages),
    [i18n.languages],
  );

  const [step, setStep] = useState(Step.Edit);
  const [newOfferPrice, setNewOfferPrice] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const offerExpiryHours = useOfferExpiryHours();

  const handleChangeOffer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewOfferPrice(event.currentTarget.value.replace(PRICE_RE, ""));
    setErrorMessage(null);
  };

  const handleClickReview = (_event: React.SyntheticEvent) => {
    if (Number(newOfferPrice) >= Number(askingPrice)) {
      setErrorMessage(t("send-favorites-offer.errors.too-high"));
      return;
    }
    setTitle(t("send-favorites-offer.confirm.title"));
    setStep(Step.Confirm);
  };

  const handleClickConfirm = (_event: React.SyntheticEvent) => {
    if (productId == null) {
      setErrorMessage(t("make-offer.errors.default"));
      setStep(Step.Edit);
      return;
    }
    commit({
      onCompleted() {
        setOpen(false);
      },
      onError(error) {
        setStep(Step.Edit);
        setErrorMessage(error.message);
      },
      variables: {
        input: {
          currency,
          price: Number.parseInt(newOfferPrice, 10),
          productId,
        },
      },
    });
  };

  let placeholder = "$";
  switch (currency) {
    case "USD":
      placeholder = "$";
      break;
    case "AUD":
    case "CAD":
    case "EUR":
    case "GBP":
    case "%future added value":
      placeholder = "USD";
      break;
  }

  let content = null;
  switch (step) {
    case Step.Edit:
      content = (
        <>
          <SBHeading level={HeadingLevel.H2} style={styles.yourOffer}>
            {t("send-favorites-offer.your-offer")}
          </SBHeading>
          <SBTextInput
            errorMessage={errorMessage}
            id="newOfferPrice"
            inputStyle={styles.inputStyle}
            label={t("send-favorites-offer.your-offer")}
            maxLength={PRICE_MAX_LENGTH}
            placeholder={placeholder}
            type={TextInputType.Number}
            value={newOfferPrice}
            onChange={handleChangeOffer}
          />
          <div {...stylex.props(styles.summary)}>
            <div {...stylex.props(styles.favorites)}>
              <SBIcon
                containerStyle={styles.iconContainerStyle}
                icon="favorite"
                size={20}
                style={styles.favoriteIcon}
              />
              <SBParagraph style={styles.paragraph}>
                {numberFormatter.format(count)}
              </SBParagraph>
            </div>
            <SBParagraph style={styles.currency}>
              {t("send-favorites-offer.price", {
                price: formatMoney(askingPrice, currency, i18n.language),
              })}
            </SBParagraph>
          </div>
          <SBButton
            block={true}
            disabled={newOfferPrice === ""}
            title={t("send-favorites-offer.buttons.review")}
            variation={ButtonVariation.Emphasis}
            onClick={handleClickReview}
          />
        </>
      );
      break;

    case Step.Confirm:
      content = (
        <>
          <SBHeading level={HeadingLevel.H2} style={styles.confirmYourOffer}>
            {t("send-favorites-offer.your-offer")}
          </SBHeading>
          <SBHeading level={HeadingLevel.H3} style={styles.confirmAmount}>
            {formatMoney(Number(newOfferPrice), currency, i18n.language)}
          </SBHeading>
          <div {...stylex.props(styles.potentialBuyers)}>
            <SBParagraph style={styles.paragraph}>
              {t("send-favorites-offer.summary.potential-buyers")}
            </SBParagraph>
            <SBIcon icon="favorite" style={styles.favoriteIcon} />
            <SBParagraph style={styles.paragraph}>
              {numberFormatter.format(count)}
            </SBParagraph>
          </div>
          <SBParagraph style={styles.expiresIn}>
            {t("send-favorites-offer.summary.expires-in", {
              hours: offerExpiryHours,
            })}
          </SBParagraph>
          <SBParagraph style={styles.disclaimer}>
            {t("send-favorites-offer.summary.disclaimer", {
              hours: offerExpiryHours,
            })}
          </SBParagraph>
          <SBButton
            block={true}
            loading={isInFlight}
            title={t("send-favorites-offer.buttons.submit")}
            variation={ButtonVariation.Emphasis}
            onClick={handleClickConfirm}
          />
        </>
      );
      break;
  }

  return <div {...stylex.props(auto, styles.root)}>{content}</div>;
};

const styles = stylex.create({
  confirmAmount: {
    fontSize: 64,
    marginBottom: 8,
    textAlign: "center",
  },
  confirmYourOffer: {
    fontSize: 24,
    marginBottom: 8,
    textAlign: "center",
  },
  currency: {
    fontVariant: "tabular-nums",
    marginBottom: 0,
  },
  disclaimer: {
    color: colors.colorMuted,
    fontSize: 12,
    marginBlock: 16,
  },
  expiresIn: {
    color: colors.colorEmphasis,
    marginBottom: 0,
    textAlign: "center",
  },
  favoriteIcon: {
    color: colors.favoriteIconSelectedColor,
  },
  favorites: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  iconContainerStyle: {
    marginRight: 4,
  },
  inputStyle: {
    textAlign: "center",
  },
  paragraph: {
    marginBottom: 0,
  },
  potentialBuyers: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingTop: 8,
  },
  potentialFavorites: {
    height: 16,
    marginInline: 2,
    width: 16,
  },
  root: {
    color: colors.color,
    display: {
      [MOBILE]: "block",
      [TABLET]: "block",
      default: "block",
    },
  },
  summary: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingBlock: 8,
  },
  yourOffer: {
    fontSize: 24,
    marginBottom: 8,
    textAlign: "center",
  },
});

export default SendFavoritesOfferView;
