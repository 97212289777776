/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  message: string;
}>;

const SignupError = ({ message }: Props): React.ReactNode | null => {
  const { t } = useTranslation();

  return (
    <div {...stylex.props(styles.root)}>
      <SBHeading level={HeadingLevel.H1} style={styles.unable}>
        {t("signup.unable")}
      </SBHeading>
      <SBParagraph style={styles.message}>
        {message === "" ? t("signup.errors.default") : message}
      </SBParagraph>
    </div>
  );
};

const styles = stylex.create({
  message: {
    marginTop: 0,
    textAlign: "center",
  },
  root: {
    padding: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 48,
    },
    position: "relative",
    width: {
      [MOBILE]: "100%",
      [TABLET]: "100%",
      default: 448,
    },
  },
  unable: {
    fontSize: 24,
    marginBottom: 16,
    marginTop: 0,
    textAlign: "center",
  },
});

export default SignupError;
