/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { lazy, useContext, useEffect } from "react";
import { loadQuery } from "react-relay";
import {
  createBrowserRouter,
  createRoutesFromElements,
  NavigationType,
  Route,
  RouterProvider,
} from "react-router-dom";

import { OrderDetailContext } from "src/app/context/order-detail";
import { PageContext } from "src/app/context/page";
import Page from "src/app/layouts/Page";
import HomeCarousel from "src/app/pages/home/HomeCarousel";
import {
  AccountQuery,
  ArticleDetailQuery,
  ArticlesQuery,
  AuctionLotQuery,
  AuctionQuery,
  AuctionSearchQuery,
  CareersQuery,
  ContactUsQuery,
  FaqsQuery,
  HomeQuery,
  PrivacyQuery,
  ProductDetailQuery,
  ShopQuery,
  TermsQuery,
  WhyUseShortboxedQuery,
} from "src/app/router/Queries";

import RelayEnvironment from "./RelayEnvironment";

const Signup = lazy(() => import("src/app/pages/signup/Signup"));
const SignupKiosk = lazy(() => import("src/app/pages/signup/SignupKiosk"));
const Home = lazy(() => import("src/app/pages/home/Home"));
const Account = lazy(() => import("src/app/pages/account/Account"));
const ComicIndexRoot = lazy(
  () => import("src/app/pages/comic-index/ComicIndexRoot"),
);
const ComicIndexIssue = lazy(
  () => import("src/app/pages/comic-index/ComicIndexIssue"),
);
const ComicIndexTitle = lazy(
  () => import("src/app/pages/comic-index/ComicIndexTitle"),
);
const ResetPassword = lazy(
  () => import("src/app/pages/reset-password/ResetPassword"),
);
const Shop = lazy(() => import("src/app/pages/shop/Shop"));
const ProductDetail = lazy(
  () => import("src/app/pages/product-detail/ProductDetail"),
);
const Seller = lazy(() => import("src/app/pages/seller/Seller"));
const Articles = lazy(() => import("src/app/pages/articles/Articles"));
const WhyUseShortboxed = lazy(
  () => import("src/app/pages/why-use-shortboxed/WhyUseShortboxed"),
);
const ContactUs = lazy(() => import("src/app/pages/contact-us/ContactUs"));
const Careers = lazy(() => import("src/app/pages/careers/Careers"));
const Terms = lazy(() => import("src/app/pages/terms/Terms"));
const Privacy = lazy(() => import("src/app/pages/privacy/Privacy"));
const AuctionSearch = lazy(
  () => import("src/app/pages/auction-search/AuctionSearch"),
);
const Auction = lazy(() => import("src/app/pages/auction/Auction"));
const AuctionLot = lazy(() => import("src/app/pages/auction-lot/AuctionLot"));
const Faqs = lazy(() => import("src/app/pages/faqs/Faqs"));
const ArticleDetail = lazy(
  () => import("src/app/pages/article-detail/ArticleDetail"),
);
const NotFound = lazy(() => import("src/app/pages/not-found/NotFound"));
const Login = lazy(() => import("src/app/pages/login/Login"));
const Download = lazy(() => import("src/app/pages/download/Download"));
const Deletion = lazy(() => import("src/app/pages/deletion/Deletion"));

export const ARTICLES_PATH = "/blog";
export const ARTICLES_DETAIL_PATH = "/blog/:slug";
export const SHOP_PATH = "/shop";
export const FAQS_PATH = "/faq";
export const AUCTIONS_PATH = "/auctions";
export const DOWNLOAD_PATH = "/download";
export const TERMS_PATH = "/terms";
export const PRIVACY_PATH = "/privacy";
export const PRODUCT_DETAIL_PATH = "/product/:id";
export const SELLER_PATH = "/u/:shopUrl";
export const HOME_PATH = "/";
export const ACCOUNT_PATH = "/account";
export const ACCOUNT_PASSWORD_PATH = "/account/password";
export const ACCOUNT_ORDERS_PATH = "/account/orders";
export const ACCOUNT_ORDER_DETAILS_PATH = "/account/orders/:id";
export const ACCOUNT_FAVORITES_PATH = "/account/favorites";
export const ACCOUNT_OFFERS_PATH = "/account/offers";
export const ACCOUNT_INVOICES_PATH = "/account/invoices";
export const ACCOUNT_ADDRESSES_PATH = "/account/addresses";
export const RESET_PASSWORD_PATH = "/reset-password";
export const COMIC_INDEX_BASE_PATH = "/comic-index";
export const COMIC_INDEX_TITLE_PATH = "/comic-index/title/:id";
export const COMIC_INDEX_ISSUE_PATH = "/comic-index/issue/:id";
export const WHY_USE_SHORTBOXED_PATH = "/why-use-shortboxed";
export const CONTACT_US_PATH = "/contact-us";
export const CAREERS_PATH = "/careers";
export const AUCTION_PATH = "/auction/:id";
export const AUCTION_LOT_PATH = "/auction-lot/:id";
export const SIGNUP_PATH = "/signup";
export const SIGNUP_KIOSK_PATH = "/signup/kiosk";
export const LOGIN_PATH = "/login";
export const DELETION_PATH = "/deletion";

enum Vertical {
  COMIC = "COMIC",
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route
        element={
          <Page carousel={<HomeCarousel />}>
            <Home />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            HomeQuery,
            {
              vertical: Vertical.COMIC,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={HOME_PATH}
      />
      <Route
        element={
          <Page>
            <ComicIndexRoot />
          </Page>
        }
        path={COMIC_INDEX_BASE_PATH}
      />
      <Route
        element={
          <Page>
            <ComicIndexTitle />
          </Page>
        }
        path={COMIC_INDEX_TITLE_PATH}
      />
      <Route
        element={
          <Page>
            <ComicIndexIssue />
          </Page>
        }
        path={COMIC_INDEX_ISSUE_PATH}
      />
      <Route
        element={
          <Page>
            <Auction />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AuctionQuery,
            {
              id: params.id,
            },
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={AUCTION_PATH}
      />
      <Route
        element={
          <Page>
            <AuctionLot />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AuctionLotQuery,
            {
              id: params.id,
            },
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={AUCTION_LOT_PATH}
      />
      <Route
        element={
          <Page headless={true}>
            <Signup />
          </Page>
        }
        path={SIGNUP_PATH}
      />
      <Route
        element={
          <Page headless={true}>
            <SignupKiosk />
          </Page>
        }
        path={SIGNUP_KIOSK_PATH}
      />
      <Route
        element={
          <Page>
            <Account />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_PATH}
      />
      <Route
        element={
          <Page>
            <Account />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_PASSWORD_PATH}
      />
      <Route
        element={
          <Page>
            <Account />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_FAVORITES_PATH}
      />
      <Route
        element={
          <Page>
            <Account />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_ORDERS_PATH}
      />
      <Route
        element={
          <Page>
            <Account />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountQuery,
            {},
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_ORDER_DETAILS_PATH}
      />
      <Route
        element={
          <Page>
            <Account />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountQuery,
            {},
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_ADDRESSES_PATH}
      />
      <Route
        element={
          <Page>
            <Account />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_OFFERS_PATH}
      />
      <Route
        element={
          <Page>
            <Account />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AccountQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ACCOUNT_INVOICES_PATH}
      />
      <Route
        element={
          <Page>
            <ResetPassword />
          </Page>
        }
        path={RESET_PASSWORD_PATH}
      />
      <Route
        element={
          <Page headless={true}>
            <Login />
          </Page>
        }
        path={LOGIN_PATH}
      />
      <Route
        element={
          <Page>
            <Download />
          </Page>
        }
        path={DOWNLOAD_PATH}
      />
      <Route
        element={
          <Page>
            <Seller />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            HomeQuery,
            {
              vertical: Vertical.COMIC,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SELLER_PATH}
      />
      <Route
        element={
          <Page>
            <Shop />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ShopQuery,
            {
              vertical: Vertical.COMIC,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={SHOP_PATH}
      />
      <Route
        element={
          <Page>
            <Shop />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ShopQuery,
            {
              vertical: Vertical.COMIC,
            },
            {
              fetchPolicy: "network-only",
            },
          );
          return queryReference;
        }}
        path={SHOP_PATH}
      />
      <Route
        element={
          <Page>
            <ProductDetail />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ProductDetailQuery,
            {
              id: params.id,
              productId: params.id,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={PRODUCT_DETAIL_PATH}
      />
      <Route
        element={
          <Page>
            <Articles />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ArticlesQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ARTICLES_PATH}
      />
      <Route
        element={
          <Page>
            <ArticleDetail />
          </Page>
        }
        loader={({ params }) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ArticleDetailQuery,
            {
              slug: params.slug,
            },
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={ARTICLES_DETAIL_PATH}
      />
      <Route
        element={
          <Page>
            <AuctionSearch />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            AuctionSearchQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={AUCTIONS_PATH}
      />
      <Route
        element={
          <Page>
            <Faqs />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            FaqsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={FAQS_PATH}
      />
      <Route
        element={
          <Page>
            <Privacy />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            PrivacyQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={PRIVACY_PATH}
      />
      <Route
        element={
          <Page>
            <Terms />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            TermsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={TERMS_PATH}
      />
      <Route
        element={
          <Page>
            <Careers />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            CareersQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={CAREERS_PATH}
      />
      <Route
        element={
          <Page>
            <WhyUseShortboxed />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            WhyUseShortboxedQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={WHY_USE_SHORTBOXED_PATH}
      />
      <Route
        element={
          <Page>
            <ContactUs />
          </Page>
        }
        loader={(_) => {
          const queryReference = loadQuery(
            RelayEnvironment,
            ContactUsQuery,
            {},
            {
              fetchPolicy: "store-or-network",
            },
          );
          return queryReference;
        }}
        path={CONTACT_US_PATH}
      />
      <Route
        element={
          <Page>
            <Deletion />
          </Page>
        }
        path={DELETION_PATH}
      />
      <Route
        element={
          <Page>
            <NotFound />
          </Page>
        }
        path="*"
      />
    </>,
  ),
);

const styles = stylex.create({
  root: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    minHeight: "100%",
  },
});

const Router = (): React.ReactNode => {
  const pageContext = useContext(PageContext);
  const { setOrderId } = useContext(OrderDetailContext);

  const title = pageContext?.title;

  useEffect(() => {
    if (title && title !== document.title) {
      document.title = title;
      const ogTitle = document.querySelector("meta[property='og:title']");
      if (ogTitle) {
        ogTitle.setAttribute("content", title);
      }
    }
  }, [title]);

  useEffect(() => {
    router.subscribe((state) => {
      if (state.historyAction === NavigationType.Pop) {
        if (
          !state.location.pathname.startsWith(
            ACCOUNT_ORDER_DETAILS_PATH.replace(":id", ""),
          )
        ) {
          setOrderId(null);
        }
      }
    });
  }, [setOrderId]);

  return (
    <div {...stylex.props(styles.root)}>
      <RouterProvider
        future={{
          // eslint-disable-next-line camelcase
          v7_startTransition: true,
        }}
        router={router}
      />
    </div>
  );
};

export default Router;
