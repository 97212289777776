/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { colors } from "src/themes/colors.stylex";

export enum FmvBadgeSize {
  Small,
  Large,
}

type Props = Readonly<{
  fmvRank: number | null | undefined;
  size?: FmvBadgeSize;
  style?: StyleXStyles;
}>;

export const SBFmvBadge = React.memo(
  ({ fmvRank, size = FmvBadgeSize.Small, style }: Props) => {
    const { t } = useTranslation();

    let fmvRankLabel = null;
    let fmvRankAltLabel = null;
    let fmvRankStyle = null;
    switch (fmvRank) {
      case 1:
        fmvRankLabel = t("fmv.fmv-rank.great");
        fmvRankAltLabel = t("fmv.fmv-rank-alt.great");
        fmvRankStyle = styles.fmvRankGreat;
        break;
      case 2:
        fmvRankLabel = t("fmv.fmv-rank.good");
        fmvRankAltLabel = t("fmv.fmv-rank-alt.good");
        fmvRankStyle = styles.fmvRankGood;
        break;
      case 4:
        fmvRankLabel = t("fmv.fmv-rank.poor");
        fmvRankAltLabel = t("fmv.fmv-rank-alt.poor");
        fmvRankStyle = styles.fmvRankPoor;
        break;
      case 3:
      default:
        break;
    }

    const fmvSizeStyle =
      size === FmvBadgeSize.Large ? styles.fmvRankLarge : styles.fmvRankSmall;

    return (
      fmvRankLabel != null &&
      fmvRankAltLabel != null && (
        <span
          {...stylex.props(styles.fmvRank, fmvRankStyle, fmvSizeStyle, style)}
          aria-label={fmvRankAltLabel}
        >
          {fmvRankLabel}
        </span>
      )
    );
  },
);

const styles = stylex.create({
  fmvRank: {
    alignItems: "center",
    backgroundColor: colors.fmvRankDefaultBackgroundColor,
    color: colors.fmvRankDefaultColor,
    display: "inline-flex",
    lineHeight: "24px",
    textAlign: "center",
  },
  fmvRankGood: {
    backgroundColor: colors.fmvRankGoodBackgroundColor,
    color: colors.fmvRankGoodColor,
  },
  fmvRankGreat: {
    backgroundColor: colors.fmvRankGreatBackgroundColor,
    color: colors.fmvRankGreatColor,
  },
  fmvRankLarge: {
    borderRadius: 16,
    fontSize: 16,
    fontWeight: 600,
    height: 32,
    paddingInline: 16,
  },
  fmvRankPoor: {
    backgroundColor: colors.fmvRankPoorBackgroundColor,
    color: colors.fmvRankPoorColor,
  },
  fmvRankSmall: {
    borderRadius: 12,
    fontSize: 12,
    height: 24,
    paddingInline: 12,
  },
});
