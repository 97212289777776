/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { SBIcon } from "src/sbxui/SBIcon";
import { colors } from "src/themes/colors.stylex";

import { SBLink } from "./SBLink";

const MOBILE = "@media (max-width: 767px)";

type Props = Readonly<
  React.PropsWithChildren<{
    id: string;
    link?: boolean;
  }>
>;

export const SBAnchor = ({ children, id, link = false }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const [isHovering, setIsHovering] = useState(false);

  const { hash } = useLocation();

  const handleMouseEnter = (_event: React.SyntheticEvent) => {
    setIsHovering(true);
  };

  const handleMouseLeave = (_event: React.SyntheticEvent) => {
    setIsHovering(false);
  };

  useEffect(() => {
    if (hash.substring(1) === id && anchorRef.current) {
      anchorRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [id, hash, anchorRef]);

  return (
    <div
      {...stylex.props(styles.container)}
      ref={containerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div {...stylex.props(styles.anchor)} ref={anchorRef} id={id}></div>
      {link ? (
        <SBLink
          style={[styles.link, isHovering && styles.linkHover]}
          to={`#${id}`}
        >
          <SBIcon icon="link" style={[styles.icon]} />
        </SBLink>
      ) : null}
      {children}
    </div>
  );
};

const styles = stylex.create({
  anchor: {
    position: "relative",
  },
  container: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
  },
  icon: {
    color: colors.topNavigationMenuColor,
  },
  link: {
    display: {
      [MOBILE]: "none",
      default: "block",
    },
    marginLeft: -32,
    opacity: 0,
    paddingRight: 8,
  },
  linkHover: {
    opacity: 1,
  },
});
