/**
 * @generated SignedSource<<49b1e38a2ab9ec9f4b4f8f5a1e3fc2cc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesAccountWatchedLotsQuery$variables = Record<PropertyKey, never>;
export type QueriesAccountWatchedLotsQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"AccountWatchedLotsView_user">;
    } | null | undefined;
  };
};
export type QueriesAccountWatchedLotsQuery = {
  response: QueriesAccountWatchedLotsQuery$data;
  variables: QueriesAccountWatchedLotsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v1 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "closesAt": "ASC"
  }
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "auctionStatus": "OPEN"
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuctionLotsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuctionLot",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortDescription",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isClosed",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "closesAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBidAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentBidCurrency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isViewerBidding",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isViewerHighBidder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isViewerOwner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isViewerWatching",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "startingAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Auction",
            "kind": "LinkedField",
            "name": "auction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "biddingStartsAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ComicDetail",
                "kind": "LinkedField",
                "name": "comicDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "FairMarketValueScore",
                "kind": "LinkedField",
                "name": "fmvScore",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImagesConnection",
                "kind": "LinkedField",
                "name": "images",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "quality",
                                "value": 100
                              },
                              {
                                "kind": "Literal",
                                "name": "webp",
                                "value": true
                              },
                              {
                                "kind": "Literal",
                                "name": "width",
                                "value": 1000
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": "url(quality:100,webp:true,width:1000)"
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v5 = [
  "orderBy",
  "where"
],
v6 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "auctionStatus": "CLOSED"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesAccountWatchedLotsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AccountWatchedLotsView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesAccountWatchedLotsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "activeWatchedLots",
                "args": (v2/*: any*/),
                "concreteType": "AuctionLotsConnection",
                "kind": "LinkedField",
                "name": "watchedAuctionLots",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "watchedAuctionLots(first:20,orderBy:{\"closesAt\":\"ASC\"},where:{\"auctionStatus\":\"OPEN\"})"
              },
              {
                "alias": "activeWatchedLots",
                "args": (v2/*: any*/),
                "filters": (v5/*: any*/),
                "handle": "connection",
                "key": "AccountWatchedLotsActive_activeWatchedLots",
                "kind": "LinkedHandle",
                "name": "watchedAuctionLots"
              },
              (v3/*: any*/),
              {
                "alias": "endedWatchedLots",
                "args": (v6/*: any*/),
                "concreteType": "AuctionLotsConnection",
                "kind": "LinkedField",
                "name": "watchedAuctionLots",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "watchedAuctionLots(first:20,orderBy:{\"closesAt\":\"ASC\"},where:{\"auctionStatus\":\"CLOSED\"})"
              },
              {
                "alias": "endedWatchedLots",
                "args": (v6/*: any*/),
                "filters": (v5/*: any*/),
                "handle": "connection",
                "key": "AccountWatchedLotsEnded_endedWatchedLots",
                "kind": "LinkedHandle",
                "name": "watchedAuctionLots"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7b855817e51b8c8fa0ff946a77879e64",
    "id": null,
    "metadata": {},
    "name": "QueriesAccountWatchedLotsQuery",
    "operationKind": "query",
    "text": "query QueriesAccountWatchedLotsQuery {\n  viewer {\n    user {\n      ...AccountWatchedLotsView_user\n      id\n    }\n  }\n}\n\nfragment AccountWatchedLotsActive_user on User {\n  activeWatchedLots: watchedAuctionLots(first: 20, orderBy: {closesAt: ASC}, where: {auctionStatus: OPEN}) {\n    edges {\n      node {\n        ...AuctionItem_auctionLot\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment AccountWatchedLotsEnded_user on User {\n  endedWatchedLots: watchedAuctionLots(first: 20, orderBy: {closesAt: ASC}, where: {auctionStatus: CLOSED}) {\n    edges {\n      node {\n        ...AuctionItem_auctionLot\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment AccountWatchedLotsView_user on User {\n  ...AccountWatchedLotsActive_user\n  ...AccountWatchedLotsEnded_user\n}\n\nfragment AuctionItem_auctionLot on AuctionLot {\n  id\n  shortDescription\n  isClosed\n  closesAt\n  currentBidAmount\n  currentBidCurrency\n  isViewerBidding\n  isViewerHighBidder\n  isViewerOwner\n  isViewerWatching\n  startingAmount\n  auction {\n    biddingStartsAt\n    type\n    id\n  }\n  product {\n    comicDetails {\n      title\n      number\n      grade\n      gradingAuthority\n      id\n    }\n    fmvScore {\n      maxValue\n      minValue\n      currency\n    }\n    images {\n      edges {\n        node {\n          url(quality: 100, webp: true, width: 1000)\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "78d9314ae319610e0f23fd7f0a828ec5";

export default node;
