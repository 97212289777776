/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { PageContext } from "src/app/context/page";
import { HOME_PATH } from "src/app/router/Router";
import { SBErrorPage } from "src/sbxui";

const NotFound = (): React.ReactNode => {
  const { t } = useTranslation();
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext?.setTitle(t("notFound.documentTitle"));
  }, [t, pageContext]);

  return (
    <SBErrorPage
      buttonHref={HOME_PATH}
      buttonTitle={t("notFound.buttonTitle")}
      description={t("notFound.description")}
      errorCode={404}
      title={t("notFound.pageTitle")}
    />
  );
};

export default React.memo(NotFound);
