/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountInvoiceCard_invoice$key } from "src/types/__generated__/AccountInvoiceCard_invoice.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { DateFormatType, SBDate, SBImage } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountInvoiceCard_invoice$key;
}>;

const AccountInvoiceCard = ({ queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const invoice = useFragment(
    graphql`
      fragment AccountInvoiceCard_invoice on Invoice {
        id
        createdAt
        total
        currency
        status
        surrogateId
        items {
          count
          edges {
            node {
              images(first: 1) {
                edges {
                  node {
                    url(quality: 100, width: 500, webp: true)
                  }
                }
              }
            }
          }
        }
      }
    `,
    queryKey,
  );

  const { id, items, createdAt, status, total, currency, surrogateId } =
    invoice;

  return (
    <div key={id} {...stylex.props(styles.invoice)}>
      <div>
        {items.count === 1 && (
          <SBImage
            alt=""
            height={200}
            src={items?.edges[0]?.node?.images?.edges[0]?.node?.url ?? ""}
          />
        )}
      </div>
      <div>
        <div>{t("account.invoices.cardHeader", { surrogateId })}</div>
        <div>{formatMoney(total, currency, i18n.language, true)}</div>
        <div>{t("account.invoices.items", { count: items?.count ?? 0 })}</div>
        <div>{status}</div>
        <div>
          <SBDate dateString={createdAt} format={DateFormatType.Long} />
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  invoice: {
    backgroundColor: colors.backgroundEmphasisColor,
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(2, 1fr)",
      default: "repeat(4, 1fr)",
    },
    marginBottom: 10,
    padding: 12,
  },
});

export default AccountInvoiceCard;
