/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { InvoiceSummary_invoice$key } from "src/types/__generated__/InvoiceSummary_invoice.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import {
  HeadingLevel,
  SBActivityIndicator,
  SBHeading,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

import InvoiceItems from "./InvoiceItems";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  loading: boolean;
  queryKey: InvoiceSummary_invoice$key;
}>;

const InvoiceSummary = ({ loading, queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment InvoiceSummary_invoice on Invoice {
        ...InvoiceItems_invoice
        currency
        fees
        promoDiscount
        shipping
        subtotal
        tax
        total
      }
    `,
    queryKey,
  );

  const currency = data.currency;
  const subtotal = data.subtotal;
  const promoDiscount = data.promoDiscount;
  const tax = data.tax;
  const shipping = data.shipping;
  const fees = data.fees;
  const total = data.total;

  return (
    <div {...stylex.props(auto, styles.summary)}>
      <InvoiceItems queryKey={data} />
      {loading ? (
        <div {...stylex.props(auto, styles.loading)}>
          <SBActivityIndicator small={true} />
        </div>
      ) : (
        <>
          <div {...stylex.props(styles.invoiceRow)}>
            <SBParagraph style={styles.label}>
              {t("invoice.subtotal.title")}
            </SBParagraph>
            <SBParagraph style={styles.value}>
              {formatMoney(subtotal, currency, i18n.language, true)}
            </SBParagraph>
          </div>
          {promoDiscount > 0 && (
            <div {...stylex.props(styles.invoiceRow)}>
              <SBParagraph style={styles.label}>
                {t("invoice.promo.title")}
              </SBParagraph>
              <SBParagraph style={styles.value}>
                {t("invoice.promo.value", {
                  discount: formatMoney(
                    promoDiscount,
                    currency,
                    i18n.language,
                    true,
                  ),
                })}
              </SBParagraph>
            </div>
          )}
          {tax > 0 && (
            <div {...stylex.props(styles.invoiceRow)}>
              <SBParagraph style={styles.label}>
                {t("invoice.tax.title")}
              </SBParagraph>
              <SBParagraph style={styles.value}>
                {formatMoney(tax, currency, i18n.language, true)}
              </SBParagraph>
            </div>
          )}
          {shipping > 0 && (
            <div {...stylex.props(styles.invoiceRow)}>
              <SBParagraph style={styles.label}>
                {t("invoice.shipping.title")}
              </SBParagraph>
              <SBParagraph style={styles.value}>
                {formatMoney(shipping, currency, i18n.language, true)}
              </SBParagraph>
            </div>
          )}
          {fees > 0 && (
            <div {...stylex.props(styles.invoiceRow)}>
              <SBParagraph style={styles.label}>
                {t("invoice.fees.title")}
              </SBParagraph>
              <SBParagraph style={styles.value}>
                {formatMoney(fees, currency, i18n.language, true)}
              </SBParagraph>
            </div>
          )}
          <div
            {...stylex.props(auto, styles.invoiceRow, styles.invoiceRowTotal)}
          >
            <SBHeading level={HeadingLevel.H3} style={styles.labelTotal}>
              {t("invoice.total.title")}
            </SBHeading>
            <SBHeading level={HeadingLevel.H3} style={styles.valueTotal}>
              {formatMoney(total, currency, i18n.language, true)}
            </SBHeading>
          </div>
        </>
      )}
    </div>
  );
};

const styles = stylex.create({
  invoiceRow: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  invoiceRowTotal: {
    borderColor: colors.borderColor,
    borderTopStyle: "solid",
    borderTopWidth: 1,
    marginTop: 8,
    paddingTop: 16,
  },
  label: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  labelTotal: {
    marginBottom: 0,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    paddingTop: 8,
  },
  summary: {
    borderColor: colors.tableBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    padding: 16,
  },
  title: {
    fontSize: {
      [MOBILE]: 32,
      [TABLET]: 48,
      default: 48,
    },
    marginBottom: 8,
  },
  value: {
    fontVariant: "tabular-nums",
    marginBottom: 8,
  },
  valueTotal: {
    fontVariant: "tabular-nums",
    marginBottom: 0,
  },
});

export default InvoiceSummary;
