/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ShippingLabelDeliveryStatus } from "src/types/__generated__/OrderDetailView_order.graphql";

import { colors } from "src/themes/colors.stylex";

export const getShippingLabelStatusColor = (
  status?: ShippingLabelDeliveryStatus,
): string => {
  switch (status) {
    case "DELIVERED":
      return colors.orderStatusDelivered;
    case "IN_TRANSIT":
    case "CREATED":
    default:
      return "";
  }
};
