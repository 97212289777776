/**
 * @generated SignedSource<<3b42f80a6da082eaf9589c8e019f1b65>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountStatsSummary_sellerStats$data = {
  readonly numOrders: number;
  readonly salesTotal: number;
  readonly " $fragmentType": "SellerAccountStatsSummary_sellerStats";
};
export type SellerAccountStatsSummary_sellerStats$key = {
  readonly " $data"?: SellerAccountStatsSummary_sellerStats$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStatsSummary_sellerStats">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountStatsSummary_sellerStats",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numOrders",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesTotal",
      "storageKey": null
    }
  ],
  "type": "UserSellerStats",
  "abstractKey": null
};

(node as any).hash = "50fb5e3453072283f6efc27c43c1bd32";

export default node;
