/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { BraintreeTokenizePayload } from "@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes";
import type { PaymentIntent } from "@stripe/stripe-js";
import type {
  Country,
  InvoicePaymentMethod,
  InvoiceView_invoice$key,
} from "src/types/__generated__/InvoiceView_invoice.graphql";
import type { InvoiceView_user$key } from "src/types/__generated__/InvoiceView_user.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import { PageContext } from "src/app/context/page";
import { SBErrorMessage } from "src/sbxui";

import InvoiceAddress from "./InvoiceAddress";
import InvoiceHeader from "./InvoiceHeader";
import InvoicePayment from "./InvoicePayment";
import InvoiceSummary from "./InvoiceSummary";

const UNPAID_STATUSES = ["TEMP", "OPEN"];

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  achMinimumValue: number;
  braintreeToken: string;
  invoicePaymentDays: number;
  paypalClientId: string;
  queryKey: InvoiceView_invoice$key;
  queryKeyUser: InvoiceView_user$key;
}>;

const InvoiceView = ({
  achMinimumValue,
  braintreeToken,
  invoicePaymentDays,
  paypalClientId,
  queryKey,
  queryKeyUser,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const [commitOwnInvoice, isInFlightOwnInvoice] = useMutation(graphql`
    mutation InvoiceViewOwnInvoiceMutation($input: UpdateOwnInvoiceInput!) {
      updateOwnInvoice(updateOwnInvoiceInput: $input) {
        ...InvoiceView_invoice
      }
    }
  `);

  const [commitCreateInvoiceOrder, isInFlightCreateInvoiceOrder] = useMutation(
    graphql`
      mutation InvoiceViewCreateInvoiceMutation(
        $input: CreateInvoiceOrderInput!
      ) {
        createInvoiceOrder(createInvoiceOrderInput: $input) {
          __id
          ...InvoiceView_invoice
        }
      }
    `,
  );

  const userData = useFragment(
    graphql`
      fragment InvoiceView_user on User {
        ...InvoiceAddress_user
        ...InvoicePayment_user
      }
    `,
    queryKeyUser,
  );

  const data = useFragment(
    graphql`
      fragment InvoiceView_invoice on Invoice {
        ...InvoiceHeader_invoice
        ...InvoiceSummary_invoice
        ...InvoicePayment_invoice
        ...InvoiceAddress_invoice
        address2
        country
        createdAt
        errorMessage
        id
        items {
          edges {
            node {
              comicDetails {
                gradingAuthority
              }
            }
          }
        }
        paymentMethod
        paymentSheet {
          customer
          ephemeralKey
          paymentIntent
          publishableKey
        }
        promoCode {
          code
          publicDescription
        }
        region
        requiresVerification
        shipFromCountry
        shipFromLocality
        shipFromRegion
        shipping
        status
        surrogateId
        type
      }
    `,
    queryKey,
  );

  const invoiceId = data.id;
  const invoiceType = data.type;
  const surrogateId = data.surrogateId;
  const status = data.status;

  const createdAt = data.createdAt;

  const items = data.items.edges;

  const paymentMethod = data.paymentMethod;

  const shouldShowRawBookCheckbox =
    invoiceType !== "AUCTION" &&
    items.find(({ node }) => node.comicDetails?.gradingAuthority === "RAW") !=
      null;

  const timestamp = createdAt == null ? null : Number.parseInt(createdAt, 10);
  const expiresDate = new Date(timestamp ?? Date.now());
  expiresDate.setDate(expiresDate.getDate() + invoicePaymentDays);

  const isPayable = UNPAID_STATUSES.includes(status);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [payWith, setPayWith] = useState<InvoicePaymentMethod>(paymentMethod);

  const [isSelectingAddress, setIsSelectingAddress] = useState(false);
  const [isSelectingPaymentMethod, setIsSelectingPaymentMethod] =
    useState(false);
  const [shipToCountry, setShipToCountry] = useState<Country>("US");
  const [shipToId, setShipToId] = useState<string | null | undefined>(null);

  const pageContext = useContext(PageContext);

  const handleSelectingAddress = (selecting: boolean) => {
    setIsSelectingAddress(selecting);
  };

  const handleChangeAddress = useCallback(
    (newAddressId: string | null | undefined, newCountry: Country) => {
      setShipToCountry(newCountry);
      setShipToId(newAddressId);
    },
    [],
  );

  const handleSelectingPaymentMethod = (selecting: boolean) => {
    setIsSelectingPaymentMethod(selecting);
  };

  const handleChangePaymentMethod = useCallback(
    (newPaymentMethod: InvoicePaymentMethod) => {
      setPayWith(newPaymentMethod);
    },
    [],
  );

  const handleVerifyStripePaymentComplete = (
    _paymentIntentStatus: PaymentIntent.Status,
  ) => {
    // TODO Handle success.
    window.location.reload();
  };

  const handleVerifyPaypalPaymentComplete = (
    payload: BraintreeTokenizePayload,
  ) => {
    commitCreateInvoiceOrder({
      onCompleted() {
        // TODO Handle success.
        window.location.reload();
      },
      onError(error) {
        setErrorMessage(
          // @ts-ignore This is a Relay response error
          error.res?.json?.errors?.[0]?.message ?? t("invoice.errors.default"),
        );
      },
      variables: {
        input: {
          invoiceId,
          nonce: payload.nonce,
        },
      },
    });
  };

  useEffect(() => {
    pageContext?.setTitle(
      t("invoice.title-number", {
        number: surrogateId,
      }),
    );
  }, [t, pageContext, surrogateId]);

  useEffect(() => {
    if (!isPayable) {
      return;
    }
    commitOwnInvoice({
      onError(error) {
        setErrorMessage(
          // @ts-ignore This is a Relay response error
          error.res?.json?.errors?.[0]?.message ?? t("invoice.errors.default"),
        );
      },
      variables: {
        input: {
          addressId: shipToId,
          invoiceId,
          paymentMethod: payWith,
        },
      },
    });
  }, [commitOwnInvoice, invoiceId, isPayable, payWith, shipToId, t]);

  return (
    <div {...stylex.props(styles.columns)}>
      <div {...stylex.props(styles.columnRight)} aria-colindex={2}>
        <InvoiceSummary loading={isInFlightOwnInvoice} queryKey={data} />
      </div>
      <div {...stylex.props(styles.columnLeft)} aria-colindex={1}>
        <SBErrorMessage message={errorMessage} style={styles.error} />
        <InvoiceHeader
          invoicePaymentDays={invoicePaymentDays}
          payable={isPayable}
          queryKey={data}
        />
        <InvoiceAddress
          disabled={
            isSelectingPaymentMethod ||
            isInFlightOwnInvoice ||
            isInFlightCreateInvoiceOrder
          }
          payable={isPayable}
          queryKey={data}
          queryKeyUser={userData}
          shipToCountry={shipToCountry}
          shipToId={shipToId}
          onChangeAddress={handleChangeAddress}
          onSelectingAddress={handleSelectingAddress}
        />
        {invoiceId != null ? (
          <InvoicePayment
            key={paymentMethod}
            achMinimumValue={achMinimumValue}
            braintreeToken={braintreeToken}
            disabled={
              isSelectingAddress ||
              isInFlightOwnInvoice ||
              isInFlightCreateInvoiceOrder
            }
            loading={isInFlightOwnInvoice || isInFlightCreateInvoiceOrder}
            payable={isPayable}
            paymentMethod={payWith}
            paypalClientId={paypalClientId}
            queryKey={data}
            queryKeyUser={userData}
            raw={shouldShowRawBookCheckbox}
            shipToCountry={shipToCountry}
            shipToId={shipToId}
            onChangePaymentMethod={handleChangePaymentMethod}
            onCompletePaypal={handleVerifyPaypalPaymentComplete}
            onCompleteStripe={handleVerifyStripePaymentComplete}
            onSelectingPaymentMethod={handleSelectingPaymentMethod}
          />
        ) : null}
      </div>
    </div>
  );
};

const styles = stylex.create({
  columnLeft: {
    gridArea: {
      default: "1 / 1 / 2 / 6",
    },
    marginBottom: 24,
  },
  columnRight: {
    gridArea: "1 / 6 / 2 / 9",
    marginBottom: 24,
    order: 1,
  },
  columns: {
    display: {
      [MOBILE]: "block",
      default: "grid",
    },
    gap: 24,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(8, 1fr)",
      default: "repeat(8, 1fr)",
    },
  },
  confirmation: {
    marginBottom: 0,
  },
  error: {
    marginBottom: 16,
  },
  help: {
    marginTop: 8,
  },
  title: {
    fontSize: {
      [MOBILE]: 32,
      [TABLET]: 48,
      default: 48,
    },
    marginBottom: 8,
  },
  verification: {
    marginBottom: 0,
    marginTop: 8,
  },
});

export default InvoiceView;
