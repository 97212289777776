/**
 * @generated SignedSource<<7bfef54b464af8d6ab746ad76cdf578a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProblemEnum = "APP_BUG" | "HISTORICAL_SALE" | "IMAGE_QUALITY" | "INACCURATE_GRADE" | "INVOICE_EXPIRED" | "LABEL_NOT_FOUND" | "OFFENSIVE_CONTENT" | "ORDER_ISSUE" | "PRODUCT_MISMATCH" | "%future added value";
export type CreateProblemInput = {
  context?: string | null | undefined;
  gradedCopyId?: string | null | undefined;
  orderId?: string | null | undefined;
  problem: ProblemEnum;
  productId?: string | null | undefined;
  userId?: string | null | undefined;
};
export type ReportProblemModalCreateProblemMutation$variables = {
  input: CreateProblemInput;
};
export type ReportProblemModalCreateProblemMutation$data = {
  readonly createProblem: {
    readonly context: string | null | undefined;
    readonly problem: ProblemEnum;
  };
};
export type ReportProblemModalCreateProblemMutation = {
  response: ReportProblemModalCreateProblemMutation$data;
  variables: ReportProblemModalCreateProblemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "createProblemInput",
        "variableName": "input"
      }
    ],
    "concreteType": "Problem",
    "kind": "LinkedField",
    "name": "createProblem",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "context",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "problem",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportProblemModalCreateProblemMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReportProblemModalCreateProblemMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "11a639f7982e41724484322360102cdc",
    "id": null,
    "metadata": {},
    "name": "ReportProblemModalCreateProblemMutation",
    "operationKind": "mutation",
    "text": "mutation ReportProblemModalCreateProblemMutation(\n  $input: CreateProblemInput!\n) {\n  createProblem(createProblemInput: $input) {\n    context\n    problem\n  }\n}\n"
  }
};
})();

(node as any).hash = "20839e05cebf88b5ba38cd9df495208e";

export default node;
