/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { DeletionViewDeleteUserMutation } from "src/types/__generated__/DeletionViewDeleteUserMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";

import { UserContext } from "src/app/context/user";
import {
  HeadingLevel,
  SBButton,
  SBHeading,
  SBModal,
  SBParagraph,
} from "src/sbxui";

const DeletionView = (): React.ReactNode => {
  const { logOut } = useContext(UserContext);
  const { t } = useTranslation();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [commit, isInFlight] = useMutation<DeletionViewDeleteUserMutation>(
    graphql`
      mutation DeletionViewDeleteUserMutation {
        deleteUser {
          deletedAt
          userId
        }
      }
    `,
  );

  const handleConfirm = () => {
    commit({
      onCompleted() {
        setIsConfirmModalOpen(false);
        logOut();
      },
      onError(_error) {
        // not sure what to do here
      },
      updater(proxyStore) {
        proxyStore.invalidateStore();
      },
      variables: {},
    });
  };

  return (
    <div {...stylex.props(styles.deletion)}>
      <SBHeading level={HeadingLevel.H1} style={styles.heading}>
        {t("accountDeletion.pageTitle")}
      </SBHeading>
      <div {...stylex.props(styles.outer)}>
        <div {...stylex.props(styles.inner)}>
          <SBParagraph>
            {t("accountDeletion.disclaimer", {
              button: t("accountDeletion.deleteButton"),
            })}
          </SBParagraph>
          <SBButton
            style={styles.button}
            title={t("accountDeletion.deleteButton")}
            onClick={() => setIsConfirmModalOpen(true)}
          />
          <SBModal
            footer={
              <div {...stylex.props(styles.modalFooter)}>
                <SBButton
                  disabled={isInFlight}
                  title={t("accountDeletion.confirmButton")}
                  onClick={handleConfirm}
                />
              </div>
            }
            headerText={t("accountDeletion.confirmTitle")}
            isOpen={isConfirmModalOpen}
            setOpen={setIsConfirmModalOpen}
          >
            <div {...stylex.props(styles.modal)}>
              {t("accountDeletion.confirmText")}
            </div>
          </SBModal>
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 96,
  },
  deletion: {
    marginBottom: 96,
  },
  heading: {
    marginBottom: 96,
    marginTop: 96,
    textAlign: "center",
  },
  inner: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "50%",
  },
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  outer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});

export default DeletionView;
