/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  containerStyle?: StyleXStyles;
  small?: boolean;
  style?: StyleXStyles;
}>;

export const SBActivityIndicator = React.memo(
  ({ containerStyle, small = false, style }: Props): React.ReactNode => {
    const { t } = useTranslation();

    const size = small ? 24 : 48;
    return (
      <svg
        {...stylex.props(auto, containerStyle)}
        aria-live="assertive"
        aria-valuetext={t("common.loading")}
        height={size}
        role="progressbar"
        viewBox="0 0 24 24"
        width={size}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          {...stylex.props(auto, styles.path, style)}
          d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"
        />
      </svg>
    );
  },
);

const spinner = stylex.keyframes({
  "100%": {
    transform: "rotate(360deg)",
  },
});

const styles = stylex.create({
  path: {
    animationDuration: ".75s",
    animationIterationCount: "infinite",
    animationName: spinner,
    animationTimingFunction: "linear",
    fill: colors.color,
    transformOrigin: "center",
  },
  // @keyframes spinner_svv2{100%{transform:rotate(360deg)}
});
