/**
 * @generated SignedSource<<869c934d2018f88e2643c2a50832f957>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesWhyUseShortboxedQuery$variables = Record<PropertyKey, never>;
export type QueriesWhyUseShortboxedQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"DocumentView_document">;
  } | null | undefined;
};
export type QueriesWhyUseShortboxedQuery = {
  response: QueriesWhyUseShortboxedQuery$data;
  variables: QueriesWhyUseShortboxedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "id",
    "value": "RG9jdW1lbnQ6MWYxN2Q5ZGUtMmY0OS00NDFjLThmYWItNjUzNDBlODM5MzVh"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesWhyUseShortboxedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "DocumentView_document"
              }
            ],
            "type": "Document",
            "abstractKey": null
          }
        ],
        "storageKey": "node(id:\"RG9jdW1lbnQ6MWYxN2Q5ZGUtMmY0OS00NDFjLThmYWItNjUzNDBlODM5MzVh\")"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesWhyUseShortboxedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
              }
            ],
            "type": "Document",
            "abstractKey": null
          }
        ],
        "storageKey": "node(id:\"RG9jdW1lbnQ6MWYxN2Q5ZGUtMmY0OS00NDFjLThmYWItNjUzNDBlODM5MzVh\")"
      }
    ]
  },
  "params": {
    "cacheID": "6ab131b6505b6f2dc4eeb70a77003641",
    "id": null,
    "metadata": {},
    "name": "QueriesWhyUseShortboxedQuery",
    "operationKind": "query",
    "text": "query QueriesWhyUseShortboxedQuery {\n  node(id: \"RG9jdW1lbnQ6MWYxN2Q5ZGUtMmY0OS00NDFjLThmYWItNjUzNDBlODM5MzVh\") {\n    __typename\n    ... on Document {\n      ...DocumentView_document\n    }\n    id\n  }\n}\n\nfragment DocumentView_document on Document {\n  id\n  title\n  body\n}\n"
  }
};
})();

(node as any).hash = "dd852ca954c59024513b9ff2a5c02974";

export default node;
