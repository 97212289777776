/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { InvoicePaymentMethod } from "src/types/__generated__/InvoiceView_invoice.graphql";
import type { ProductDetailActionsBuyerDefault_product$key } from "src/types/__generated__/ProductDetailActionsBuyerDefault_product.graphql";
import type { ProductDetailActionsBuyerDefaultMutation } from "src/types/__generated__/ProductDetailActionsBuyerDefaultMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";

import MakeOffer from "src/app/components/make-offer/MakeOffer";
import { INVOICE_PATH } from "src/app/router/Router";
import { ButtonType, ButtonVariation, SBButton, SBParagraph } from "src/sbxui";
import { formatMoney } from "src/utils";

enum Modal {
  None,
  Offer,
}

type Props = Readonly<{
  achMinimumValue: number;
  queryKey: ProductDetailActionsBuyerDefault_product$key;
}>;

const ProductDetailActionsBuyerDefault = ({
  achMinimumValue,
  queryKey,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [commit, isInFlight] =
    useMutation<ProductDetailActionsBuyerDefaultMutation>(graphql`
      mutation ProductDetailActionsBuyerDefaultMutation(
        $input: CreateBuyNowInvoiceInput!
      ) {
        createBuyNowInvoice(createBuyNowInvoiceInput: $input) {
          ... on Invoice {
            id
          }
        }
      }
    `);

  const data = useFragment(
    graphql`
      fragment ProductDetailActionsBuyerDefault_product on Product {
        id
        price
        currency
        minOfferPrice
        minOfferPercentage
      }
    `,
    queryKey,
  );

  const productId = data.id;
  const price = data.price;
  const currency = data.currency;

  const paymentMethod: InvoicePaymentMethod =
    price >= achMinimumValue ? "ACH" : "CREDIT_CARD";

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClickBuy = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlight) {
      return;
    }

    commit({
      onCompleted(response) {
        navigate(
          INVOICE_PATH.replace(":id", response.createBuyNowInvoice?.id ?? ""),
        );
      },
      onError(error) {
        setErrorMessage(
          // @ts-ignore This is the localized message from the error response.
          error?.res?.errors?.[0].message ?? t("products.buy.errors.default"),
        );
      },
      variables: {
        input: {
          paymentMethod,
          productId,
        },
      },
    });
  };

  const [modal, setModal] = useState(Modal.None);
  const handleCloseModal = () => {
    setModal(Modal.None);
  };

  const handleClickMakeOffer = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setModal(Modal.Offer);
  };

  return (
    <>
      <SBButton
        block={true}
        loading={isInFlight}
        style={styles.button}
        title={t("product.header.buttons.buy-now-for", {
          price: formatMoney(price, currency, i18n.language),
        })}
        type={ButtonType.Button}
        variation={ButtonVariation.Emphasis}
        onClick={handleClickBuy}
      />
      <SBButton
        block={true}
        style={styles.button}
        title={t("make-offer.title")}
        type={ButtonType.Button}
        variation={ButtonVariation.Default}
        onClick={handleClickMakeOffer}
      />
      {errorMessage ? (
        <SBParagraph style={styles.error}>
          {t("common.unexpected.error")}
        </SBParagraph>
      ) : null}
      <MakeOffer
        isOpen={modal === Modal.Offer}
        productId={productId}
        setOpen={handleCloseModal}
      />
    </>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },

  error: {
    alignSelf: "center",
    fontSize: 14,
    marginTop: 16,
  },
});

export default ProductDetailActionsBuyerDefault;
