/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesFaqsQuery } from "src/types/__generated__/QueriesFaqsQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { FaqsQuery } from "src/app/router/Queries";

import FaqsView from "./FaqsView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesFaqsQuery>;
}>;

const FaqsContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(FaqsQuery, queryRef);

  return <FaqsView queryKey={queryKey} />;
};

export default FaqsContent;
