/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// @ts-nocheck

import type { Disposable, UseMutationConfig } from "react-relay";
import type { useFavoriteMutation } from "src/types/__generated__/useFavoriteMutation.graphql.ts";

import { graphql, useMutation } from "react-relay";

export const useFavorite = (): [
  (config: UseMutationConfig<useFavoriteMutation>) => Disposable,

  boolean,
] => {
  const [commit, isInFlight] = useMutation(graphql`
    mutation useFavoriteMutation($input: ProductFavoriteInput!) {
      favorite(favoriteInput: $input) {
        ... on Product {
          ...SearchResultCard_product
          ...ProductDetailView_product
        }
      }
    }
  `);

  return [commit, isInFlight];
};
