/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountStoreSettingsQuery } from "src/types/__generated__/QueriesSellerAccountStoreSettingsQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import SellerAccountPage from "../SellerAccountPage";
import SellerAccountStoreSettingsContent from "./SellerAccountStoreSettingsContent";

const SellerAccountStoreSettings = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesSellerAccountStoreSettingsQuery>;

  return (
    <SellerAccountPage
      docTitle={t("seller-account.store-settings.title")}
      innerHeading={t("seller-account.nav.store-settings")}
    >
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <SellerAccountStoreSettingsContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </SellerAccountPage>
  );
};

export default SellerAccountStoreSettings;
