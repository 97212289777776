/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useCallback, useMemo, useState } from "react";

interface State {
  interval: string;
  setInterval: (interval: string) => void;
}

const INITIAL_STATE: State = {
  interval: "ONE_MONTH",
  setInterval: () => {
    return;
  },
};

export const SellerStatsContext: React.Context<State> =
  React.createContext(INITIAL_STATE);

type Props = React.PropsWithChildren;

export const SellerStatsProvider = ({ children }: Props): React.ReactNode => {
  const [intervalInternal, setIntervalInternal] = useState<string>(
    INITIAL_STATE.interval,
  );
  const setInterval = useCallback((newInterval: string) => {
    setIntervalInternal(newInterval);
  }, []);

  const current = useMemo(
    () => ({
      interval: intervalInternal,
      setInterval,
    }),
    [intervalInternal, setInterval],
  );

  return (
    <SellerStatsContext.Provider value={current}>
      {children}
    </SellerStatsContext.Provider>
  );
};
