/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Middleware, RelayRequestAny } from "react-relay-network-modern";
import type { ValkyrieError } from "src/utils/constants";

import { StorageKey } from "src/app/constants";

const HTTP_UNAUTHORIZED = 401;
const API_E0015 = "E0015"; // The refresh token supplied is invalid or expired.

interface AuthMiddlewareOpts {
  allowEmptyToken?: boolean;
  header?: string;
  prefix?: string;
  token: (req: RelayRequestAny) => Promise<string>;
  tokenRefreshPromise: (req: RelayRequestAny) => Promise<string>;
}

export default function authMiddleware(opts: AuthMiddlewareOpts): Middleware {
  const {
    allowEmptyToken = false,
    header = "Authorization",
    prefix = "Bearer ",
    token: tokenPromise,
    tokenRefreshPromise,
  } = opts;

  return (next) => async (req: RelayRequestAny) => {
    const token = await tokenPromise(req);

    if (token) {
      req.fetchOpts.headers[header] = `${prefix}${token}`;
    }

    const res = await next(req);

    const error = res?.errors?.[0] as unknown as ValkyrieError;

    if (__DEV__ && error != null) {
      // eslint-disable-next-line no-console
      console.log({
        jwt: error,
      });
    }

    const code = error?.extensions?.code;
    const statusCode = error?.extensions?.statusCode;

    if (code === API_E0015 || statusCode === HTTP_UNAUTHORIZED) {
      const accessToken = await tokenRefreshPromise(req);

      if (!accessToken && !allowEmptyToken) {
        const userKey = StorageKey.User;
        localStorage.removeItem(userKey);
        throw new Error("Unauthorized.");
      }

      const newReq = req.clone();
      newReq.fetchOpts.headers[header] = `${prefix}${accessToken}`;

      return next(newReq);
    }

    return res;
  };
}
