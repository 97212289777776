/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { OrderPublicStatusType } from "src/types/__generated__/OrderStatusTracking_order.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useEffect, useState } from "react";

import { HeadingLevel, SBHeading } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

export type TStep =
  | OrderPublicStatusType
  | "PAYOUT_PENDING"
  | "PAYOUT_PROCESSING"
  | "PAYOUT_UNPAID"
  | "PAYOUT_PAID"
  | "PAYOUT_FAILED";

function isDone(
  step: TStep,
  status: TStep,
  steps: TStep[] = [
    "QUEUED",
    "PROCESSING",
    "IN_TRANSIT",
    "DELIVERED",
    "DELIVERY_EXCEPTION",
    "UNAVAILABLE",
    "REFUNDED",
    "CANCELED",
    "FAILED",
    "PAYOUT_PENDING",
    "PAYOUT_PROCESSING",
    "PAYOUT_UNPAID",
    "PAYOUT_PAID",
    "PAYOUT_FAILED",
  ],
): boolean {
  const check = steps.shift();
  if (check === step && steps.includes(status)) {
    return true;
  }
  if (steps.length === 0) {
    return false;
  }
  return isDone(step, status, steps);
}

type Progress = Readonly<{
  id: TStep;
  label: string;
}>;

type Props = Readonly<{
  progress: readonly Progress[];
  status: TStep;
}>;

const OrderStatusTrackingProgressBar = ({
  progress,
  status,
}: Props): React.ReactNode => {
  const [inverted, setInverted] = useState(
    window.matchMedia("(prefers-color-scheme: dark)").matches,
  );

  useEffect(() => {
    const matchMode = (event: MediaQueryListEvent) => {
      setInverted(event.matches);
    };
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", matchMode);
    return () => {
      window
        .matchMedia("(prefers-color-scheme: dark)")
        .removeEventListener("change", matchMode);
    };
  }, []);

  let color = colors.orderStatus;
  let backgroundColor = colors.orderStatus;
  switch (status) {
    case "PROCESSING":
    case "QUEUED":
      color = colors.orderStatusProcessing;
      break;
    case "IN_TRANSIT":
      color = colors.orderStatusInTransit;
      break;
    case "DELIVERED":
      color =
        progress.length > 3
          ? colors.orderStatusDeliveredSeller
          : colors.orderStatusDelivered;
      backgroundColor =
        progress.length > 3 ? colors.orderStatus : colors.orderStatusDelivered;
      break;
    case "CANCELED":
      color = colors.orderStatusCanceled;
      backgroundColor = colors.orderStatusCanceled;
      break;
    case "FAILED":
      color = colors.orderStatusFailed;
      backgroundColor = colors.orderStatusFailed;
      break;
    case "DELIVERY_EXCEPTION":
      color = colors.orderStatusDeliveryException;
      backgroundColor = colors.orderStatusDeliveryException;
      break;
    case "UNAVAILABLE":
      color = colors.orderStatusUnavailable;
      backgroundColor = colors.orderStatusUnavailable;
      break;
    case "REFUNDED":
      color = colors.orderStatusRefunded;
      backgroundColor = colors.orderStatusRefunded;
      break;
    case "PAYOUT_PENDING":
      color = colors.orderPayoutStatusPending;
      break;
    case "PAYOUT_PROCESSING":
      color = colors.orderPayoutStatusProcessing;
      break;
    case "PAYOUT_PAID":
      color = colors.orderPayoutStatusPaid;
      backgroundColor = colors.orderPayoutStatusPaid;
      break;
    case "PAYOUT_UNPAID":
      color = colors.orderPayoutStatusUnpaid;
      break;
    case "PAYOUT_FAILED":
      color = colors.orderPayoutStatusFailed;
      break;
    default:
      break;
  }

  return (
    <div {...stylex.props(styles.progress)}>
      <div
        {...stylex.props(
          styles.line,
          styles.orderStatusBackground(backgroundColor),
        )}
      />
      {progress.map((step, index) => {
        let icon = "inactive";
        let isActive = false;
        if (step.id === status) {
          isActive = true;
          icon = "active";
          switch (status) {
            case "QUEUED":
              break;
            case "PROCESSING":
              break;
            case "IN_TRANSIT":
              break;
            case "DELIVERED":
              icon = progress.length > 3 ? "active" : "delivered";
              break;
            case "CANCELED":
              icon = "canceled";
              break;
            case "FAILED":
              icon = "failed";
              break;
            case "DELIVERY_EXCEPTION":
              icon = "deliveryException";
              break;
            case "UNAVAILABLE":
              icon = "unavailable";
              break;
            case "REFUNDED":
              icon = "refunded";
              break;
            case "PAYOUT_PENDING":
            case "PAYOUT_PROCESSING":
            case "PAYOUT_PAID":
            case "PAYOUT_UNPAID":
            case "PAYOUT_FAILED":
              icon = "paid";
              break;
            default:
              break;
          }
        } else if (!isDone(step.id, status)) {
          color = colors.orderStatus;
        }
        return (
          <div key={step.id} {...stylex.props(styles.step)}>
            {index === 0 && (
              <div {...stylex.props(styles.spacer, styles.spacerStart)} />
            )}
            {index === progress.length - 1 && (
              <div {...stylex.props(styles.spacer, styles.spacerEnd)} />
            )}
            <div
              {...stylex.props(
                styles.orderStatusColor(color),
                styles.statusIcon(icon, inverted),
                styles.icon,
                isActive ? styles.progressIconActive : styles.progressIcon,
              )}
            />
            <SBHeading
              level={HeadingLevel.H4}
              {...stylex.props(auto, styles.stepText)}
            >
              {step.label}
            </SBHeading>
          </div>
        );
      })}
    </div>
  );
};

const styles = stylex.create({
  icon: {
    backgroundColor: colors.backgroundColor,
    zIndex: 10,
  },
  line: {
    height: 1,
    position: "absolute",
    top: 24,
    width: "100%",
  },
  orderStatusBackground: (backgroundColor: string) => ({
    backgroundColor,
  }),
  orderStatusColor: (color: string) => ({
    color,
  }),
  progress: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
  },
  progressIcon: {
    borderRadius: 16,
    height: 16,
    margin: 16,
    width: 16,
  },
  progressIconActive: {
    borderRadius: 32,
    height: 32,
    margin: 8,
    width: 32,
  },
  spacer: {
    height: 32,
    position: "absolute",
    top: 8,
    width: "50%",
  },
  spacerEnd: {
    right: 0,
  },
  spacerStart: {
    left: 0,
  },
  statusIcon: (status: string, inverted: boolean) => ({
    backgroundImage: `url('https://www-cdn.shortboxed.com/order-status/${status}${inverted ? "-inverted" : ""}.webp')`,
    backgroundSize: "cover",
  }),
  step: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  stepText: {
    color: colors.color,
    textAlign: "center",
  },
});

export default OrderStatusTrackingProgressBar;
