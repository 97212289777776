/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountInvoicesView_user$key } from "src/types/__generated__/AccountInvoicesView_user.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useFragment } from "react-relay";

import { SBTabs } from "src/sbxui";

import AccountInvoicesActive from "./AccountInvoicesActive";
import AccountInvoicesClosed from "./AccountInvoicesClosed";

type Props = Readonly<{
  queryKey: AccountInvoicesView_user$key;
}>;

const AccountInvoicesView = ({ queryKey }: Props): React.ReactNode => {
  const [selectedTab, setSelectedTab] = useState<string>("ACTIVE");

  const data = useFragment(
    graphql`
      fragment AccountInvoicesView_user on User {
        ...AccountInvoicesActive_user
        ...AccountInvoicesClosed_user
      }
    `,
    queryKey,
  );

  const TABS = [
    { id: "ACTIVE", name: "Active", selected: selectedTab === "ACTIVE" },
    { id: "CLOSED", name: "Closed", selected: selectedTab === "CLOSED" },
  ];

  const getTabBody = (): React.ReactNode => {
    switch (selectedTab) {
      case "CLOSED":
        return <AccountInvoicesClosed queryKey={data} />;
      case "ACTIVE":
      default:
        return <AccountInvoicesActive queryKey={data} />;
    }
  };

  return (
    <>
      <SBTabs tabs={TABS} onTabSelected={(tabId) => setSelectedTab(tabId)} />
      {getTabBody()}
    </>
  );
};

export default AccountInvoicesView;
