/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountOffersQuery } from "src/types/__generated__/QueriesAccountOffersQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AccountOffersQuery } from "src/app/router/Queries";

import AccountOffersView from "./AccountOffersView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountOffersQuery>;
}>;

const AccountOffersContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AccountOffersQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <AccountOffersView queryKey={user} />;
};

export default AccountOffersContent;
