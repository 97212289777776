/**
 * @generated SignedSource<<994f29552f3da45a5ff8f75da88bfb76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvoiceSummary_invoice$data = {
  readonly currency: Currency;
  readonly fees: number;
  readonly promoDiscount: number;
  readonly shipping: number;
  readonly subtotal: number;
  readonly tax: number;
  readonly total: number;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceItems_invoice">;
  readonly " $fragmentType": "InvoiceSummary_invoice";
};
export type InvoiceSummary_invoice$key = {
  readonly " $data"?: InvoiceSummary_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceSummary_invoice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceSummary_invoice",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceItems_invoice"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fees",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "promoDiscount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shipping",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subtotal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tax",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};

(node as any).hash = "d3cd52741dc4d74d3beabc97208d2911";

export default node;
