/**
 * @generated SignedSource<<ed0a6e37e0cd291303a2172baedf25e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type AuctionBidStatus = "APPROVED" | "INCOMPLETE" | "PENDING" | "SUSPENDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuctionLotHeader_user$data = {
  readonly auctionBidStatus: AuctionBidStatus;
  readonly " $fragmentType": "AuctionLotHeader_user";
};
export type AuctionLotHeader_user$key = {
  readonly " $data"?: AuctionLotHeader_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionLotHeader_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionLotHeader_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auctionBidStatus",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d5c24d257dc299cf00e70bbd330966c6";

export default node;
