/**
 * @generated SignedSource<<e87896dd7c02425d979bd1febfd9bb7d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesAccountOffersQuery$variables = Record<PropertyKey, never>;
export type QueriesAccountOffersQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"AccountOffersView_user">;
    } | null | undefined;
  };
};
export type QueriesAccountOffersQuery = {
  response: QueriesAccountOffersQuery$data;
  variables: QueriesAccountOffersQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v1 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "createdAt": "DESC"
  }
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "situation": "ACTIVE"
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OffersEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Offer",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "toUserId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Product",
            "kind": "LinkedField",
            "name": "product",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 1
                  }
                ],
                "concreteType": "ImagesConnection",
                "kind": "LinkedField",
                "name": "images",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImagesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Image",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": [
                              {
                                "kind": "Literal",
                                "name": "quality",
                                "value": 100
                              },
                              {
                                "kind": "Literal",
                                "name": "webp",
                                "value": true
                              },
                              {
                                "kind": "Literal",
                                "name": "width",
                                "value": 300
                              }
                            ],
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": "url(quality:100,webp:true,width:300)"
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "images(first:1)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ComicDetail",
                "kind": "LinkedField",
                "name": "comicDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v5 = [
  "orderBy",
  "where"
],
v6 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "situation": "ENDED"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesAccountOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AccountOffersView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesAccountOffersQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "activeOffers",
                "args": (v2/*: any*/),
                "concreteType": "BuyerOffersConnection",
                "kind": "LinkedField",
                "name": "buyerOffers",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "buyerOffers(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"situation\":\"ACTIVE\"})"
              },
              {
                "alias": "activeOffers",
                "args": (v2/*: any*/),
                "filters": (v5/*: any*/),
                "handle": "connection",
                "key": "AccountOffersActive_activeOffers",
                "kind": "LinkedHandle",
                "name": "buyerOffers"
              },
              (v3/*: any*/),
              {
                "alias": "endedOffers",
                "args": (v6/*: any*/),
                "concreteType": "BuyerOffersConnection",
                "kind": "LinkedField",
                "name": "buyerOffers",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "buyerOffers(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"situation\":\"ENDED\"})"
              },
              {
                "alias": "endedOffers",
                "args": (v6/*: any*/),
                "filters": (v5/*: any*/),
                "handle": "connection",
                "key": "AccountOffersEnded_endedOffers",
                "kind": "LinkedHandle",
                "name": "buyerOffers"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e8cd73ec8490b1bd91b194b58e80a9f3",
    "id": null,
    "metadata": {},
    "name": "QueriesAccountOffersQuery",
    "operationKind": "query",
    "text": "query QueriesAccountOffersQuery {\n  viewer {\n    user {\n      ...AccountOffersView_user\n      id\n    }\n  }\n}\n\nfragment AccountOfferCard_offer on Offer {\n  id\n  price\n  currency\n  status\n  toUserId\n  product {\n    id\n    images(first: 1) {\n      edges {\n        node {\n          url(quality: 100, width: 300, webp: true)\n          id\n        }\n      }\n    }\n    comicDetails {\n      title\n      number\n      gradingAuthority\n      grade\n      id\n    }\n  }\n}\n\nfragment AccountOffersActive_user on User {\n  activeOffers: buyerOffers(first: 20, orderBy: {createdAt: DESC}, where: {situation: ACTIVE}) {\n    edges {\n      node {\n        ...AccountOfferCard_offer\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment AccountOffersEnded_user on User {\n  endedOffers: buyerOffers(first: 20, orderBy: {createdAt: DESC}, where: {situation: ENDED}) {\n    edges {\n      node {\n        ...AccountOfferCard_offer\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment AccountOffersView_user on User {\n  ...AccountOffersActive_user\n  ...AccountOffersEnded_user\n}\n"
  }
};
})();

(node as any).hash = "02be88c0e58c57f94b55c7f6eaccb2ec";

export default node;
