/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesInvoiceQuery } from "src/types/__generated__/QueriesInvoiceQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { InvoiceQuery } from "src/app/router/Queries";

import InvoiceView from "./InvoiceView";

const DEFAULT_ACH_SHOP_MINIMUM_VALUE = "1";
const DEFAULT_INVOICE_PAYMENT_DAYS = "1";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesInvoiceQuery>;
}>;

const InvoiceContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(InvoiceQuery, queryRef);

  const invoicePaymentDays = Number.parseInt(
    queryKey.hotConfigs.data.INVOICE_PAYMENT_DAYS ??
      DEFAULT_INVOICE_PAYMENT_DAYS,
    10,
  );

  const achMinimumValue = Number.parseInt(
    queryKey.hotConfigs.data.ACH_SHOP_MINIMUM_VALUE ??
      DEFAULT_ACH_SHOP_MINIMUM_VALUE,
    10,
  );

  const paypalClientId = String(
    queryKey.hotConfigs.data.PAYPAL_CLIENT_ID ?? "",
  );

  const braintreeToken = queryKey.braintreeToken;

  const queryKeyNode = queryKey.node;
  const queryKeyUser = queryKey.viewer.user;

  if (queryKeyNode == null || queryKeyUser == null) {
    // TODO Show error page here.
    return null;
  }

  return (
    <InvoiceView
      achMinimumValue={achMinimumValue}
      braintreeToken={braintreeToken}
      invoicePaymentDays={invoicePaymentDays}
      paypalClientId={paypalClientId}
      queryKey={queryKeyNode}
      queryKeyUser={queryKeyUser}
    />
  );
};

export default InvoiceContent;
