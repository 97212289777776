/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountVacationModeView_user$key } from "src/types/__generated__/SellerAccountVacationModeView_user.graphql";
import type { SellerAccountVacationModeViewMutation } from "src/types/__generated__/SellerAccountVacationModeViewMutation.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import {
  ButtonType,
  fixDate,
  SBButton,
  SBCheckboxInput,
  SBParagraph,
} from "src/sbxui";
import { dateLong } from "src/utils";

type Props = Readonly<{
  queryKey: SellerAccountVacationModeView_user$key;
}>;

const SellerAccountVacationModeView = ({
  queryKey,
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountVacationModeView_user on User {
        id
        sellerStatus
        vacationSince
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] =
    useMutation<SellerAccountVacationModeViewMutation>(graphql`
      mutation SellerAccountVacationModeViewMutation(
        $input: UpdateSellerVacationModeInput!
      ) {
        updateSellerVacationMode(updateSellerVacationModeInput: $input) {
          ... on User {
            sellerStatus
            vacationSince
            ...SellerAccountVacationModeView_user
          }
        }
      }
    `);

  const { id: userId, sellerStatus, vacationSince } = data;

  const [isVacationMode, setIsVacationMode] = useState<boolean>(
    sellerStatus === "VACATION",
  );

  const handleSubmit = () => {
    if (userId == null) {
      return;
    }
    commit({
      variables: {
        input: {
          userId,
          vacationMode: true,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <SBCheckboxInput
        checked={isVacationMode}
        id="isVacationMode"
        onChange={() => setIsVacationMode(!isVacationMode)}
      >
        {t("seller-account.vacation-mode.checkbox-text")}{" "}
        {vacationSince != null &&
          t("seller-account.vacation-mode.since", {
            since: dateLong(fixDate(vacationSince ?? ""), i18n.language),
          })}
      </SBCheckboxInput>
      <SBParagraph style={styles.subtext}>
        {t("seller-account.vacation-mode.checkbox-subtext")}
      </SBParagraph>
      <div {...stylex.props(styles.button)}>
        <SBButton
          disabled={isInFlight}
          loading={isInFlight}
          title={t("seller-account.buttons.vacation-mode")}
          type={ButtonType.Submit}
        />
      </div>
    </form>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
  root: {},
  subtext: {
    fontSize: 12,
    marginTop: 8,
  },
});

export default SellerAccountVacationModeView;
