/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { MenuItemConfig } from "src/sbxui";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Login from "src/app/components/login/Login";
import ProfileLink from "src/app/components/profile-link/ProfileLink";
import { UserContext } from "src/app/context/user";
import { ACCOUNT_PATH, HOME_PATH, SIGNUP_PATH } from "src/app/router/Router";
import { MenuPosition, SBIcon, SBMenu, SBTakeover } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import LoginForm from "../login/LoginForm";

const Auth = (): React.ReactNode => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { logOut, user } = useContext(UserContext);

  const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const handleClickLogin = (_e: React.SyntheticEvent) => {
    setIsLoginModalOpen(true);
  };

  const handleClickSignup = (_e: React.SyntheticEvent) => {
    navigate(SIGNUP_PATH);
  };

  const handleClickLogout = (_e: React.SyntheticEvent) => {
    setIsLoggingIn(false);
    logOut();
    navigate(HOME_PATH);
  };

  const items: MenuItemConfig[] =
    user == null
      ? [
          {
            id: "signup",
            label: t("menu.signup"),
            onClick: handleClickSignup,
          },
          {
            id: "login",
            label: t("menu.login"),
            onClick: handleClickLogin,
          },
        ]
      : [
          {
            id: "account",
            label: t("menu.account"),
            to: ACCOUNT_PATH,
          },
          {
            id: "logout",
            label: t("menu.logout"),
            onClick: handleClickLogout,
          },
        ];

  return (
    <>
      <SBMenu
        button={
          <>
            <div {...stylex.props(auto, styles.menu)}>
              <SBIcon icon="menu" style={styles.icon} />
            </div>
            {user == null ? <Login loading={isLoggingIn} /> : <ProfileLink />}
          </>
        }
        buttonLabel={t("menu.label")}
        buttonStyle={styles.buttonStyle}
        items={items}
        position={MenuPosition.LEFT}
      />
      <SBTakeover isOpen={isLoginModalOpen} setOpen={setIsLoginModalOpen}>
        <LoginForm
          onLoginStart={() => setIsLoggingIn(true)}
          onLoginSuccess={() => setIsLoginModalOpen(false)}
        />
      </SBTakeover>
    </>
  );
};

const styles = stylex.create({
  buttonStyle: {
    alignItems: "center",
    backgroundColor: colors.topNavigationBackgroundColor,
    borderColor: colors.topNavigationMenuBorderColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    height: 40,
    justifyContent: "center",
    padding: 4,
  },
  error: {
    alignItems: "flex-start",
    backgroundColor: colors.errorBackgroundColor,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: 16,
    paddingBlock: 12,
    paddingInline: 16,
  },
  errorIcon: {
    color: colors.errorColor,
    marginRight: 8,
  },
  errorMessage: {
    color: colors.errorColor,
    fontSize: 16,
    lineHeight: 1.5,
    margin: 0,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  form: {
    padding: 96,
    width: 448,
  },
  formWrap: {
    backgroundColor: colors.backgroundEmphasisColor,
  },
  hr: {
    borderBottomWidth: 1,
    borderColor: colors.takeoverBorderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 0,
    marginBlock: 32,
  },
  icon: {
    color: colors.topNavigationMenuColor,
  },
  input: {
    marginBottom: 16,
  },
  loginButton: {
    marginBottom: 16,
  },
  menu: {
    alignItems: "center",
    borderRadius: 40,
    display: "flex",
    height: 40,
    justifyContent: "center",
    textDecorationLine: "none",
    width: 40,
  },
});

export default React.memo(Auth);
