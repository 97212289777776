/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";
import type { LoginResponseType } from "src/app/context/user";
import type { GoogleSSOButtonGoogleLoginMutation } from "src/types/__generated__/GoogleSSOButtonGoogleLoginMutation.graphql";

import { useGoogleLogin } from "@react-oauth/google";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMutation } from "react-relay";

import { API_CLIENT_ID } from "src/app/constants";
import { SBGoogleButton } from "src/sbxui";

type Props = Readonly<{
  onLoginSuccess: (
    loginResponse: LoginResponseType,
    needsTermsFixUp: boolean,
  ) => void;
  signup?: boolean;
  style?: StyleXStyles;
}>;

export const GoogleSSOButton = ({
  signup,
  style,
  onLoginSuccess,
}: Props): React.ReactNode => {
  const [commit] = useMutation<GoogleSSOButtonGoogleLoginMutation>(graphql`
    mutation GoogleSSOButtonGoogleLoginMutation(
      $input: SocialOAuthTokenVerificationInput!
    ) {
      googleLogin(socialOAuthTokenVerificationInput: $input) {
        accessToken
        refreshToken
        user {
          id
          hasAcceptedTermsOfService
          hasAcceptedPrivacyPolicy
        }
        permissions
      }
    }
  `);

  const login = useGoogleLogin({
    flow: "implicit",
    onSuccess: (tokenResponse) => {
      commit({
        onCompleted(data) {
          const needsTermsFixUp =
            data.googleLogin.user?.hasAcceptedPrivacyPolicy === false ||
            data.googleLogin.user?.hasAcceptedTermsOfService === false;

          onLoginSuccess(data.googleLogin, needsTermsFixUp);
        },
        variables: {
          input: {
            clientId: API_CLIENT_ID,
            needsTokenExchange: true,
            token: tokenResponse.access_token,
          },
        },
      });
    },
  });

  const handleLogin = () => {
    login();
  };

  return <SBGoogleButton signup={signup} style={style} onClick={handleLogin} />;
};
