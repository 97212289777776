/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TFunction } from "i18next";

const WHITESPACE_RE = /[\s]+/gu;
const ELLIPSIS_RE = /\.\.\./gu;
const EN_DASH_RE = / - /gu;
const APOSTROPHE_RE = /'/gu;

export const formatTitle = (
  t: TFunction<"translation", undefined>,
  name: string | null | undefined,
  issue?: string | null | undefined,
): string => {
  if (name == null) {
    return t("product.book.title-missing");
  }

  const title = name
    .replace(WHITESPACE_RE, " ")
    .replace(ELLIPSIS_RE, "\u2026")
    .replace(APOSTROPHE_RE, "\u2019")
    .replace(EN_DASH_RE, " \u2013 ");

  if (issue == null) {
    return title;
  }

  if (issue != null) {
    return t("product.book.title", {
      issue,
      title,
    });
  }

  return title;
};
