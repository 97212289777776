/**
 * @generated SignedSource<<2ed280b784a5d372a009a940c1b6f29c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type SalesChannel = "AUCTION" | "SHOP" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SearchResultCard_typesenseSearch$data = {
  readonly auctionLotId: string | null | undefined;
  readonly createdAt: string;
  readonly currency: Currency | null | undefined;
  readonly currentBidAmount: number;
  readonly fmvMaxValue: number | null | undefined;
  readonly fmvMinValue: number | null | undefined;
  readonly fmvRank: number | null | undefined;
  readonly grade: number;
  readonly gradingAuthority: GradingAuthority;
  readonly id: string;
  readonly images: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      };
    }>;
  };
  readonly number: string;
  readonly price: number | null | undefined;
  readonly product: {
    readonly " $fragmentSpreads": FragmentRefs<"SearchResultCard_product">;
  } | null | undefined;
  readonly productId: string;
  readonly salesChannel: SalesChannel | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "SearchResultCard_typesenseSearch";
};
export type SearchResultCard_typesenseSearch$key = {
  readonly " $data"?: SearchResultCard_typesenseSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SearchResultCard_typesenseSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SearchResultCard_typesenseSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SearchResultCard_product"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImagesConnection",
      "kind": "LinkedField",
      "name": "images",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "quality",
                      "value": 85
                    },
                    {
                      "kind": "Literal",
                      "name": "webp",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 500
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": "url(quality:85,webp:true,width:500)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "grade",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gradingAuthority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "auctionLotId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fmvRank",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fmvMinValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fmvMaxValue",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentBidAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "price",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salesChannel",
      "storageKey": null
    }
  ],
  "type": "TypesenseSearch",
  "abstractKey": null
};

(node as any).hash = "bb4c4e5a00bc96d3e2a7e9c43d5642bf";

export default node;
