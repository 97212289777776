/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useMemo, useState } from "react";

interface PageContextInterface {
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  title: string;
}

export const PageContext = React.createContext<PageContextInterface | null>(
  null,
);

type Props = React.PropsWithChildren;

export const PageProvider = (props: Props): React.ReactNode => {
  const { children } = props;

  const [title, setTitle] = useState("");

  const current = useMemo(
    () => ({
      setTitle,
      title,
    }),
    [title],
  );

  return (
    <PageContext.Provider value={current}>{children}</PageContext.Provider>
  );
};
