/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AcceptOfferView_offer$key } from "src/types/__generated__/AcceptOfferView_offer.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";

import { ButtonVariation, SBButton, SBLink, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

const RAW_SHIPPING_COST = 8;
const GRADED_SHIPPING_COST = 15;

type Props = Readonly<{
  offer: AcceptOfferView_offer$key;
  offerId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const AcceptOfferView = ({
  offerId,
  offer,
  setOpen,
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const [commit, isInFlight] = useMutation(graphql`
    mutation AcceptOfferViewMutation($input: AcceptOfferInput!) {
      acceptOffer(acceptOfferInput: $input) {
        ... on Offer {
          id
          product {
            ...ProductDetailView_product
          }
        }
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment AcceptOfferView_offer on Query {
        node(id: $id) {
          ... on Offer {
            id
            comicDetails {
              grade
              gradingAuthority
              title
              variant
              publisher
              number
            }
            price
            currency
            product {
              id
              price
            }
          }
        }
      }
    `,
    offer,
  );

  const offerPrice = data.node?.price ?? 0;

  const comicDetails = data.node?.comicDetails;

  const askingPrice = data.node?.product?.price ?? 0;
  const currency = data.node?.currency ?? "USD";

  const gradingAuthority = comicDetails?.gradingAuthority;

  const shippingCost =
    gradingAuthority === "RAW" ? RAW_SHIPPING_COST : GRADED_SHIPPING_COST;

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onPressClose = () => {
    setOpen(false);
  };

  const onPressConfirm = () => {
    toast.info(t("make-offer.offer.accepted"), {
      position: "top-center",
    });
    commit({
      onCompleted() {
        setOpen(false);
      },
      onError(error) {
        setErrorMessage(
          // @ts-ignore This is the localized message from the error response.
          error?.res?.errors?.[0].message ?? t("offers.submit.errors.default"),
        );
      },
      variables: {
        input: {
          offerId,
        },
      },
    });
  };

  const content = (
    <>
      {" "}
      <div {...stylex.props(styles.confirmationDetailsContainer)}>
        <div {...stylex.props(styles.detailsRow)}>
          <SBParagraph style={styles.details}>
            {t("model.offer.title.accepted-offer")}
          </SBParagraph>
          <div {...stylex.props(styles.detailsRowPrices)}>
            <SBParagraph style={styles.originalOffer}>
              {formatMoney(Number(askingPrice), currency, i18n.language)}
            </SBParagraph>
            <SBParagraph style={styles.details}>
              {formatMoney(Number(offerPrice), currency, i18n.language)}
            </SBParagraph>
          </div>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <SBParagraph style={styles.details}>
            {t("make-offer.confirm.shipping")}
          </SBParagraph>
          <SBParagraph style={styles.details}>
            {formatMoney(Number(shippingCost), currency, i18n.language)}
          </SBParagraph>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <SBParagraph style={styles.details}>
            {t("model.offer.title.taxes")}
          </SBParagraph>
          <SBParagraph style={styles.details}>
            {t("model.offer.title.taxes-subtext")}
          </SBParagraph>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <SBParagraph style={styles.details}>
            {t("make-offer.confirm.total")}
          </SBParagraph>
          <SBParagraph style={styles.details}>
            {t("make-offer.confirm.total-approx", {
              total: formatMoney(
                Number(offerPrice) + shippingCost,
                currency,
                i18n.language,
              ),
            })}
          </SBParagraph>
        </div>
        <div {...stylex.props(styles.detailsRow)}>
          <SBParagraph style={styles.detailsAgreement}>
            {t("modal.offer.accept-agreement")}
          </SBParagraph>
        </div>
      </div>
      <SBButton
        block={true}
        loading={isInFlight}
        style={styles.button}
        title={t("common.buttons.confirm")}
        variation={ButtonVariation.Emphasis}
        onClick={onPressConfirm}
      />
      {errorMessage ? (
        <SBParagraph style={styles.errorMessage}>
          {t("common.unexpected.error")}
        </SBParagraph>
      ) : null}
    </>
  );

  return (
    <div {...stylex.props(styles.container)}>
      {content}

      <SBLink style={styles.close} onClick={onPressClose}>
        {t("common.buttons.close")}
      </SBLink>
    </div>
  );
};

const styles = stylex.create({
  askingPrice: {
    color: colors.colorEmphasis,
  },
  askingPriceContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    justifyContent: "center",
  },
  askingPriceTitle: {
    color: colors.color,
    paddingRight: 8,
  },
  button: {
    marginTop: 16,
  },
  close: {
    color: colors.color,
    fontSize: 16,
    marginTop: 16,
  },
  confirm: {
    fontWeight: 600,
  },
  confirmationDetailsContainer: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: 16,
    width: "100%",
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  details: {
    fontWeight: 400,
  },
  detailsAgreement: {
    color: colors.colorMuted,
    fontSize: 14,
  },
  detailsRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  detailsRowPrices: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  errorMessage: {
    paddingTop: 16,
  },
  inputStyle: {
    textAlign: "center",
  },
  originalOffer: {
    color: colors.colorMuted,
    paddingRight: 8,
    textDecoration: "line-through",
  },
  submitted: {
    color: colors.color,
    fontWeight: 600,
    marginTop: 16,
  },
  submittedSubtext: {
    color: colors.colorMuted,
    fontSize: 14,
    marginTop: 16,
  },
  titleDetails: {
    fontWeight: 600,
  },
});

export default AcceptOfferView;
