/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountInvoicesQuery } from "src/types/__generated__/QueriesAccountInvoicesQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AccountInvoicesQuery } from "src/app/router/Queries";

import AccountInvoicesView from "./AccountInvoicesView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountInvoicesQuery>;
}>;

const AccountInvoicesContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AccountInvoicesQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <AccountInvoicesView queryKey={user} />;
};

export default AccountInvoicesContent;
