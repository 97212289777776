/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { UserContext } from "src/app/context/user";
import { LOGIN_PATH } from "src/app/router/Router";
import { HeadingLevel, SBHeading, SBPageHeader } from "src/sbxui";
import { auto } from "src/themes";

import AccountSideNav from "./AccountSideNav";

const MOBILE = "@media (max-width: 767px)";

type Props = Readonly<{
  children: React.ReactNode;
  docTitle?: string;
  innerHeading?: string | null;
}>;

const AccountPage = ({
  docTitle = "",
  innerHeading = null,
  children,
}: Props): React.ReactNode => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pageContext = useContext(PageContext);

  const { user } = useContext(UserContext);

  if (user == null) {
    navigate(LOGIN_PATH);
  }

  useEffect(() => {
    pageContext?.setTitle(docTitle);
  }, [docTitle, pageContext]);

  return (
    <div>
      <SBPageHeader title={t("account.page-title")} />
      <div {...stylex.props(styles.root)}>
        <AccountSideNav />
        <div {...stylex.props(styles.main)}>
          <div {...stylex.props(auto, styles.section)}>
            {innerHeading != null && (
              <SBHeading level={HeadingLevel.H2} style={styles.innerHeading}>
                {innerHeading}
              </SBHeading>
            )}
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  innerHeading: {
    fontSize: 24,
    marginBottom: 16,
  },
  main: {
    gridColumn: "span 5 / span 5",
    minHeight: {
      [MOBILE]: 0,
      default: 512,
    },
  },
  root: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      default: "repeat(6, 1fr)",
    },
  },
  section: {
    marginBottom: 48,
    paddingBottom: 48,
  },
});

export default AccountPage;
