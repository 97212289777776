/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useEffect, useState } from "react";

import { SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  chevrons?: boolean;
  imageUrls: string[];
}>;

export const SBImageCarousel = ({
  chevrons = true,
  imageUrls,
}: Props): React.ReactNode | null => {
  const [opacity, setOpacity] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClickNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 === imageUrls.length ? 0 : prevIndex + 1,
    );
  };

  const handleClickPrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - 1 < 0 ? imageUrls.length - 1 : prevIndex - 1,
    );
  };

  const handleClickDot = (index: number) => () => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    if (imageUrls.length > 0) {
      for (const imageUrl of imageUrls) {
        const img = new Image();
        img.onload = () => {
          setOpacity(1);
        };
        img.src = imageUrl;
      }
    }
  }, [imageUrls]);

  if (imageUrls.length === 0) {
    return null;
  }

  return (
    <div {...stylex.props(styles.carousel)} aria-hidden="true">
      {imageUrls.map((imageUrl, ii) => (
        <div
          {...stylex.props(
            styles.carouselImage,
            currentIndex === ii && styles.carouselImageCurrent,
          )}
          key={imageUrl}
        >
          <div {...stylex.props(auto, styles.image(opacity))}>
            <img {...stylex.props(styles.imageFile)} src={imageUrl} />
          </div>
        </div>
      ))}
      <div {...stylex.props(styles.imageSpacer)}></div>
      {chevrons ? (
        <>
          <div
            {...stylex.props(
              styles.chevronContainer,
              styles.chevronContainerLeft,
            )}
          >
            <button
              {...stylex.props(auto, styles.chevron, styles.chevronLeft)}
              type="button"
              onClick={handleClickPrevious}
            >
              <SBIcon icon="chevron_left" style={styles.chevronIcon} />
            </button>
          </div>
          <div
            {...stylex.props(
              styles.chevronContainer,
              styles.chevronContainerRight,
            )}
          >
            <button
              {...stylex.props(auto, styles.chevron, styles.chevronRight)}
              type="button"
              onClick={handleClickNext}
            >
              <SBIcon icon="chevron_right" style={styles.chevronIcon} />
            </button>
          </div>
        </>
      ) : null}
      <div {...stylex.props(styles.dots)}>
        {imageUrls.map((imageUrl, ii) => (
          <div {...stylex.props(styles.dot)} key={imageUrl}>
            <button
              {...stylex.props(
                auto,
                styles.dotButton,
                ii === 0 && styles.dotButtonFirst,
                currentIndex === ii && styles.dotButtonCurrent,
              )}
              type="button"
              onClick={handleClickDot(ii)}
            ></button>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = stylex.create({
  carousel: {
    overflow: "hidden",
    position: "relative",
  },
  carouselImage: {
    bottom: 0,
    left: -9999999999,
    opacity: 0,
    position: "absolute",
    right: 0,
    top: 0,
    transition: "opacity 250ms",
  },
  carouselImageCurrent: {
    left: 0,
    opacity: 1,
  },
  chevron: {
    alignItems: "center",
    backgroundColor: colors.carouselBackgroundColor,
    borderColor: colors.carouselDotBorderColor,
    borderRadius: 20,
    borderStyle: "solid",
    borderWidth: 2,
    cursor: "pointer",
    display: "flex",
    height: 40,
    justifyContent: "center",
    position: "relative",
    width: 40,
    zIndex: 1,
  },
  chevronContainer: {
    alignItems: "center",
    bottom: "50%",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: "50%",
  },
  chevronContainerLeft: {
    left: 0,
  },
  chevronContainerRight: {
    right: 0,
  },
  chevronIcon: {
    color: colors.carouselColor,
  },
  chevronLeft: {
    marginLeft: 16,
  },
  chevronRight: {
    marginRight: 16,
  },
  dot: {
    alignItems: "center",
    display: "flex",
    height: 20,
    justifyContent: "center",
    marginInline: 4,
    width: 20,
  },
  dotButton: {
    backgroundColor: colors.carouselDotColor,
    borderColor: colors.carouselDotBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 2,
    cursor: "pointer",
    height: 16,
    width: 16,
  },
  dotButtonCurrent: {
    backgroundColor: colors.carouselDotCurrentColor,
    borderRadius: 10,
    height: 20,
    width: 20,
  },
  dotButtonFirst: {
    marginLeft: 0,
  },
  dots: {
    alignItems: "center",
    bottom: 16,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
  },
  image: (opacity: number) => ({
    alignItems: "center",
    backgroundColor: colors.backgroundPlaceholderColor,
    borderRadius: 8,
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    opacity,
    overflow: "hidden",
    position: "absolute",
    right: 0,
    top: 0,
    transition: "opacity 250ms",
  }),
  imageFile: {
    display: "block",
    height: "100%",
    position: "absolute",
  },
  imageSpacer: {
    paddingBottom: "100%",
  },
});
