/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ArticlesListQuery_articles$key } from "src/types/__generated__/ArticlesListQuery_articles.graphql";
import type { ArticlesPaginationQuery } from "src/types/__generated__/ArticlesPaginationQuery.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as React from "react";
import { useRef } from "react";
import { usePaginationFragment } from "react-relay";

import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import ArticleCard from "./ArticleCard";

dayjs.extend(localizedFormat);

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  notId: string | null;
  queryKey: ArticlesListQuery_articles$key;
}>;

const ArticlesList = ({ queryKey, notId }: Props): React.ReactNode => {
  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment<
    ArticlesPaginationQuery,
    ArticlesListQuery_articles$key
  >(
    graphql`
      fragment ArticlesListQuery_articles on Query
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 20 }
        orderBy: {
          type: ArticleOrderByInput
          defaultValue: { createdAt: DESC }
        }
      )
      @refetchable(queryName: "ArticlesPaginationQuery") {
        articles(
          after: $cursor
          first: $count
          orderBy: $orderBy
          where: { isBlog: true }
        ) @connection(key: "ArticlesList_articles") {
          __id
          edges {
            node {
              ...ArticleCard_article
              id
              slug
            }
          }
        }
      }
    `,
    queryKey,
  );

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  return (
    <>
      <div {...stylex.props(styles.root)}>
        {data?.articles?.edges
          ?.filter(({ node }) => node.id !== notId)
          ?.map(({ node }, ii) => (
            <div
              {...stylex.props(
                auto,
                styles.item,
                ii % 2 === 0 ? styles.itemOdd : styles.itemEven,
              )}
              key={node.slug}
            >
              <ArticleCard queryKey={node} />
            </div>
          ))}
      </div>
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  item: {
    borderBottomColor: colors.horizontalRuleColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    boxSizing: "border-box",
    marginBottom: 48,
    paddingBottom: 48,
  },
  itemEven: {
    paddingLeft: 12,
  },
  itemOdd: {
    paddingRight: 12,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  root: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(2, 1fr)",
      default: "repeat(2, 1fr)",
    },
  },
});

export default ArticlesList;
