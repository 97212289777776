/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// https://www.twilio.com/docs/glossary/what-e164
export const getPhoneMaxLengthByCountry = (country: string): number => {
  switch (country) {
    case "GB":
      return 12;
    case "CA":
    case "US":
    default:
      return 10;
  }
};
