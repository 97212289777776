/**
 * @generated SignedSource<<9c6bdfb2d9255853788c71e40eb262ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CensusTable_comicDetail$data = {
  readonly census: any;
  readonly " $fragmentType": "CensusTable_comicDetail";
};
export type CensusTable_comicDetail$key = {
  readonly " $data"?: CensusTable_comicDetail$data;
  readonly " $fragmentSpreads": FragmentRefs<"CensusTable_comicDetail">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CensusTable_comicDetail",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "census",
      "storageKey": null
    }
  ],
  "type": "ComicDetail",
  "abstractKey": null
};

(node as any).hash = "60c0265750e7232547c2d8f1c0167e03";

export default node;
