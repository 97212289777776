/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";

type Props = Readonly<{
  alt: string;
  height?: number | string;
  src: string;
  style?: StyleXStyles;
  width?: number | string;
}>;

export const SBImage = React.memo(
  ({ src, width = "auto", alt, style, ...rest }: Props): React.ReactNode => {
    const [opacity, setOpacity] = useState(0);

    const onLoad = () => {
      setOpacity(1);
    };

    return (
      <img
        {...stylex.props(
          styles.root,
          styles.width(width),
          styles.opacity(opacity),
          style,
        )}
        alt={alt}
        src={src}
        {...rest}
        onLoad={onLoad}
      />
    );
  },
);

const styles = stylex.create({
  opacity: (opacity: number) => ({
    opacity,
    transition: "opacity 250ms",
  }),
  root: {
    maxWidth: "100%",
  },
  width: (width: number | string) => ({
    width,
  }),
});
