/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { SBIcon } from "./SBIcon";
import { SBParagraph } from "./SBParagraph";

type Props = Readonly<{
  message: string | null | undefined;
  style?: StyleXStyles;
}>;

export const SBInfoMessage = ({ message, style }: Props): React.ReactNode => {
  return (
    <div aria-live="polite">
      {message ? (
        <div {...stylex.props(auto, styles.root, style)}>
          <SBIcon icon="info" style={styles.infoIcon} />
          <SBParagraph style={styles.message}>{message}</SBParagraph>
        </div>
      ) : null}
    </div>
  );
};

const styles = stylex.create({
  infoIcon: {
    color: colors.errorColor,
  },
  message: {
    color: colors.errorColor,
    fontSize: 16,
    lineHeight: 1.5,
    margin: 0,
    marginLeft: 8,
  },
  root: {
    alignItems: "flex-start",
    backgroundColor: colors.infoBackgroundColor,
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    paddingBlock: 12,
    paddingInline: 16,
  },
});
