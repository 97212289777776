/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import { Parser } from "html-to-react";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatHtml } from "src/utils";

const htmlParser = new Parser();

type Props = Readonly<{
  args?: Record<string, string | null | undefined>;
  translationKey: string;
  value: React.ReactNode;
  valueAsHTML?: boolean;
}>;

export const SBProductDetailMeta = ({
  translationKey,
  value,
  args,
  valueAsHTML = false,
}: Props) => {
  const { i18n, t } = useTranslation();

  let itemValue = valueAsHTML
    ? htmlParser.parse(formatHtml(value?.toString() ?? ""))
    : value;

  if (Array.isArray(value)) {
    const formatter = new Intl.ListFormat(i18n.language, {
      style: "long",
      type: "conjunction",
    });
    itemValue = formatter.format(value);
  }

  return (
    <div {...stylex.props(styles.root)}>
      <SBHeading level={HeadingLevel.H2} style={styles.label}>
        {t(translationKey, args)}
      </SBHeading>
      <SBParagraph {...stylex.props(auto, styles.value)}>
        {itemValue}
      </SBParagraph>
    </div>
  );
};

const styles = stylex.create({
  label: {
    fontSize: 24,
  },
  root: {
    borderBottomColor: colors.metaBorderColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    display: "flex",
    flexBasis: "50%",
    flexDirection: "column",
    marginBottom: 24,
    paddingBottom: 12,
  },
  value: {
    color: colors.colorMuted,
    marginBottom: 0,
    marginTop: 10,
  },
});
