/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesPortfolioQuery } from "src/types/__generated__/QueriesPortfolioQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { SBErrorBoundary, SBFallback } from "src/sbxui";

import PortfolioContent from "./PortfolioContent";
import PortfolioSearch from "./PortfolioSearch";

const Portfolio = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef = useLoaderData() as PreloadedQuery<QueriesPortfolioQuery>;

  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext?.setTitle(t("portfolio.title"));
  }, [t, pageContext]);

  return (
    <>
      <div {...stylex.props(styles.search)}>
        <PortfolioSearch />
      </div>
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <PortfolioContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </>
  );
};

const styles = stylex.create({
  search: {
    marginBottom: 40,
  },
});

export default Portfolio;
