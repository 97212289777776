/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// import i18n (needs to be bundled ;))
import "./i18n/i18n";

import { datadogRum } from "@datadog/browser-rum";
import { GoogleOAuthProvider } from "@react-oauth/google";
import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { StrictMode, Suspense, useEffect } from "react";

import { PageProvider } from "src/app/context/page";
import { UserProvider } from "src/app/context/user";
import ErrorBoundary from "src/app/pages/error-boundary/ErrorBoundary";
import RelayEnvironment from "src/app/router/RelayEnvironment";
import Router from "src/app/router/Router";
import { SBFallback } from "src/sbxui";

// cSpell:disable
const GOOGLE_CLIENT_ID =
  "1087580832466-tidf59h0dgi4igkcutuou0jpssgslcqs.apps.googleusercontent.com";
// cSpell:enable
import { RelayEnvironmentProvider } from "react-relay";

import { OrderDetailProvider } from "./app/context/order-detail";
import { VerticalSearchProvider } from "./app/context/vertical-search";

const App = (): React.ReactNode => {
  useEffect(() => {
    datadogRum.init({
      applicationId: "098528b4-b8f4-43a0-97c3-7185006d48e9",
      clientToken: "pub024287ccedf302ba7f4f9c0e99e11eeb",
      defaultPrivacyLevel: "mask-user-input",
      env: process.env.NODE_ENV,
      service: "shortboxed.com",
      sessionReplaySampleRate: 20,
      sessionSampleRate: 100,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: "datadoghq.com",
      trackLongTasks: true,
      trackResources: true,
      trackUserInteractions: true,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: process.env.REACT_APP_VERSION ?? "none",
    });
  }, []);

  return (
    <StrictMode>
      <ErrorBoundary>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <VerticalSearchProvider>
            <OrderDetailProvider>
              <PageProvider>
                <UserProvider>
                  <RelayEnvironmentProvider environment={RelayEnvironment}>
                    <Suspense fallback={<SBFallback />}>
                      <main {...stylex.props(styles.main)} id="#main">
                        <Router />
                      </main>
                    </Suspense>
                  </RelayEnvironmentProvider>
                </UserProvider>
              </PageProvider>
            </OrderDetailProvider>
          </VerticalSearchProvider>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </StrictMode>
  );
};

const styles = stylex.create({
  main: {
    fontSize: 16,
    height: "100%",
    textSizeAdjust: "100%",
  },
});

export default App;
