/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SelectInputOption } from "src/sbxui";
import type {
  CategoryRollup,
  GradingAuthority,
  SpecialCopy,
} from "src/types/__generated__/ShopPaginationQuery.graphql";

import stylex from "@stylexjs/stylex";
import { t } from "i18next";
import * as React from "react";
import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { VerticalSearchContext } from "src/app/context/vertical-search";
import { SHOP_PATH } from "src/app/router/Router";
import { CategoryRollupEnum } from "src/enums/category-rollup.enum";
import { GradingAuthorityEnum } from "src/enums/grading-authority.enum";
import { SpecialCopyEnum } from "src/enums/special-copy.enum";
import {
  ButtonType,
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBIcon,
  SBModal,
  SBSelectInput,
  SBTextInput,
  SBToggleButton,
  TextInputType,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const EARLIEST_YEAR = 1933;
const LATEST_YEAR = new Date(Date.now()).getFullYear();

const YEAR_MIN_DEFAULT = {
  id: "",
  label: t("modal.filter.low"),
};

const YEAR_MAX_DEFAULT = {
  id: "",
  label: t("modal.filter.high"),
};

const getYearOptions = (start = EARLIEST_YEAR) =>
  Array(LATEST_YEAR - start + 1)
    .fill(start)
    .map((year, index) => year + index)
    .map(
      (value) =>
        ({
          id: value.toString(),
          label: value.toString(),
        }) as SelectInputOption,
    );

const PRICE_MAX_LENGTH = 6;

const ERAS = ["GOLDEN", "SILVER", "BRONZE", "COPPER", "MODERN"];

const GRADES = [
  "0.0",
  "0.5",
  "1.0",
  "1.5",
  "1.8",
  "2.0",
  "2.5",
  "3.0",
  "3.5",
  "4.0",
  "4.5",
  "5.0",
  "5.5",
  "6.0",
  "6.5",
  "7.0",
  "7.5",
  "8.0",
  "8.5",
  "9.0",
  "9.2",
  "9.4",
  "9.6",
  "9.8",
  "9.9",
  "10.0",
].map(
  (value) =>
    ({
      id: value,
      label: value === "0.0" ? t("rawGrades.0-0") : value,
    }) as SelectInputOption,
);

const GRADE_MIN_DEFAULT = {
  id: "",
  label: t("modal.filter.low"),
};

const GRADE_MAX_DEFAULT = {
  id: "",
  label: t("modal.filter.high"),
};

const orderedGradingAuthorities = [
  GradingAuthorityEnum.CGC,
  GradingAuthorityEnum.CBCS,
  GradingAuthorityEnum.RAW,
];

const orderedCategoryRollups = [
  CategoryRollupEnum.UNIVERSAL,
  CategoryRollupEnum.SIGNATURE,
  CategoryRollupEnum.RESTORED,
  CategoryRollupEnum.QUALIFIED,
];

const orderedSpecialCopies = [
  SpecialCopyEnum.DOUBLE_COVER,
  SpecialCopyEnum.TRIPLE_COVER,
  SpecialCopyEnum.NEWSSTAND,
  SpecialCopyEnum.MARK_JEWELERS,
  SpecialCopyEnum.INTERNATIONAL,
  SpecialCopyEnum.NATIONAL_DIAMOND,
];

type Props = Readonly<{
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

export const FilterModal = ({ isOpen, setOpen }: Props): React.ReactNode => {
  const navigate = useNavigate();
  const { shopUrl } = useParams();
  const {
    categoryRollups,
    gradeMax,
    gradeMin,
    gradingAuthorities,
    priceMax,
    priceMin,
    specialCopies,
    yearMax,
    yearMin,
    setCategoryRollups,
    setGradeMax,
    setGradeMin,
    setGradingAuthorities,
    setPriceMax,
    setPriceMin,
    setSpecialCopies,
    setYearMax,
    setYearMin,
  } = useContext(VerticalSearchContext);

  const [newPriceMin, setNewPriceMin] = useState(`${priceMin ?? ""}`);
  const [newPriceMax, setNewPriceMax] = useState(`${priceMax ?? ""}`);
  const [newYearMin, setNewYearMin] = useState(`${yearMin ?? ""}`);
  const [newYearMax, setNewYearMax] = useState(`${yearMax ?? ""}`);
  const [newGradeMin, setNewGradeMin] = useState(`${gradeMin ?? ""}`);
  const [newGradeMax, setNewGradeMax] = useState(`${gradeMax ?? ""}`);

  const [newEra, setNewEra] = useState<string | null>(() => {
    switch (yearMin) {
      case EARLIEST_YEAR:
        if (yearMax === 1956) {
          return "GOLDEN";
        }
        break;
      case 1957:
        if (yearMax === 1970) {
          return "SILVER";
        }
        break;
      case 1971:
        if (yearMax === 1985) {
          return "BRONZE";
        }
        break;
      case 1986:
        if (yearMax === 2000) {
          return "COPPER";
        }
        break;
      case 2001:
        if (yearMax == null) {
          return "MODERN";
        }
        break;
      default:
        break;
    }
    return null;
  });

  const [newGradingAuthorities, setNewGradingAuthorities] = useState<
    GradingAuthority[]
  >(() => {
    const results = [];
    for (const authority of orderedGradingAuthorities) {
      if (gradingAuthorities.includes(authority)) {
        results.push(authority);
      }
    }
    return results;
  });
  const [newCategoryRollups, setNewCategoryRollups] = useState<
    CategoryRollup[]
  >(() => {
    const results = [];
    for (const category of orderedCategoryRollups) {
      if (categoryRollups.includes(category)) {
        results.push(category);
      }
    }
    return results;
  });
  const [newSpecialCopies, setNewSpecialCopies] = useState<SpecialCopy[]>(
    () => {
      const results = [];
      for (const copy of orderedSpecialCopies) {
        if (specialCopies.includes(copy)) {
          results.push(copy);
        }
      }
      return results;
    },
  );

  const onApplyFilters = (_event: React.SyntheticEvent) => {
    setGradingAuthorities(newGradingAuthorities);
    setCategoryRollups(newCategoryRollups);
    setSpecialCopies(newSpecialCopies);
    setGradeMax(newGradeMax === "" ? null : Number.parseFloat(newGradeMax));
    setGradeMin(newGradeMin === "" ? null : Number.parseFloat(newGradeMin));
    setPriceMax(newPriceMax === "" ? null : Number.parseInt(newPriceMax, 10));
    setPriceMin(newPriceMin === "" ? null : Number.parseInt(newPriceMin, 10));
    setYearMax(newYearMax === "" ? null : Number.parseInt(newYearMax, 10));
    setYearMin(newYearMin === "" ? null : Number.parseInt(newYearMin, 10));

    if (shopUrl == null) {
      navigate(SHOP_PATH);
    }

    setOpen(false);
  };

  const onReset = (_event: React.SyntheticEvent) => {
    setNewCategoryRollups([]);
    setNewGradeMax("");
    setNewGradeMin("");
    setNewGradingAuthorities([]);
    setNewPriceMax("");
    setNewPriceMin("");
    setNewSpecialCopies([]);
    setNewYearMax("");
    setNewYearMin("");
    setNewEra(null);
  };

  const handlePriceMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPriceMin(event.currentTarget.value);
  };

  const handlePriceMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPriceMax(event.currentTarget.value);
  };

  const handleGradeMinChange = (selected: string) => {
    const selectedOption =
      GRADES.find(({ label }) => label === selected) ?? GRADE_MIN_DEFAULT;

    if (selectedOption) {
      setNewGradeMin(selectedOption.id);
      if (
        Number.parseFloat(selectedOption.id) >= Number.parseFloat(newGradeMax)
      ) {
        setNewGradeMax("");
      }
    }
  };

  const handleGradeMaxChange = (selected: string) => {
    const selectedOption =
      GRADES.find(({ label }) => label === selected) ?? GRADE_MAX_DEFAULT;

    if (selectedOption) {
      setNewGradeMax(selectedOption.id);
    }
  };

  const handleYearMinChange = (selected: string) => {
    const selectedOption =
      getYearOptions().find(({ label }) => label === selected) ??
      YEAR_MIN_DEFAULT;

    if (selectedOption) {
      setNewYearMin(selectedOption.id);
      if (
        Number.parseInt(selectedOption.id, 10) >=
        Number.parseInt(newYearMax, 10)
      ) {
        setNewYearMax("");
      }
      setNewEra(null);
      switch (selectedOption.id) {
        case `${EARLIEST_YEAR}`:
          if (newYearMax === "1956") {
            setNewEra("GOLDEN");
          }
          break;
        case "1957":
          if (newYearMax === "1970") {
            setNewEra("SILVER");
          }
          break;
        case "1971":
          if (newYearMax === "1985") {
            setNewEra("BRONZE");
          }
          break;
        case "1986":
          if (newYearMax === "2000") {
            setNewEra("COPPER");
          }
          break;
        case "2001":
          if (newYearMax == null) {
            setNewEra("MODERN");
          }
          break;
      }
    }
  };

  const handleYearMaxChange = (selected: string) => {
    const selectedOption =
      getYearOptions().find(({ label }) => label === selected) ??
      YEAR_MAX_DEFAULT;

    if (selectedOption) {
      setNewYearMax(selectedOption.id);
      switch (selectedOption.id) {
        case "1956":
          if (newYearMin === `${EARLIEST_YEAR}`) {
            setNewEra("GOLDEN");
          }
          break;
        case "1970":
          if (newYearMin === "1957") {
            setNewEra("SILVER");
          }
          break;
        case "1985":
          if (newYearMin === "1971") {
            setNewEra("BRONZE");
          }
          break;
        case "2000":
          if (newYearMin === "1986") {
            setNewEra("COPPER");
          }
          break;
        case "":
          if (newYearMin === "2001") {
            setNewEra("MODERN");
          }
          break;
      }
    }
  };

  const handleClickEra = (era: string) => (_event: React.SyntheticEvent) => {
    if (newEra === era) {
      setNewEra(null);
      setNewYearMin("");
      setNewYearMax("");
      return;
    }
    setNewEra(era);
    switch (era) {
      case "GOLDEN":
        setNewYearMin(`${EARLIEST_YEAR}`);
        setNewYearMax("1956");
        break;
      case "SILVER":
        setNewYearMin("1957");
        setNewYearMax("1970");
        break;
      case "BRONZE":
        setNewYearMin("1971");
        setNewYearMax("1985");
        break;
      case "COPPER":
        setNewYearMin("1986");
        setNewYearMax("2000");
        break;
      case "MODERN":
        setNewYearMin("2001");
        setNewYearMax("");
        break;
    }
  };

  const handleCategoryRollupChange =
    (nextCategory: CategoryRollup) =>
    (_event: React.ChangeEvent<HTMLInputElement>) => {
      setNewCategoryRollups((prevCategories) => {
        if (prevCategories.includes(nextCategory)) {
          return prevCategories.filter((el) => el !== nextCategory);
        }
        return [...prevCategories, nextCategory];
      });
    };

  const handleGradingAuthorityChange =
    (nextAuthority: GradingAuthority) =>
    (_event: React.ChangeEvent<HTMLInputElement>) => {
      setNewGradingAuthorities((prevAuthorities) => {
        if (prevAuthorities.includes(nextAuthority)) {
          return prevAuthorities.filter((el) => el !== nextAuthority);
        }
        return [...prevAuthorities, nextAuthority];
      });
    };

  const handleSpecialCopyChange =
    (nextSpecialCopy: SpecialCopy) =>
    (_event: React.ChangeEvent<HTMLInputElement>) => {
      setNewSpecialCopies((prevSpecialCopies) => {
        if (prevSpecialCopies.includes(nextSpecialCopy)) {
          return prevSpecialCopies.filter((el) => el !== nextSpecialCopy);
        }
        return [...prevSpecialCopies, nextSpecialCopy];
      });
    };

  return (
    <SBModal
      footer={
        <div {...stylex.props(styles.footer)}>
          <div {...stylex.props(styles.reset)}>
            <button
              {...stylex.props(auto, styles.resetButton)}
              type="button"
              onClick={onReset}
            >
              <SBIcon
                fill={false}
                icon="restart_alt"
                style={styles.resetIcon}
              />
              {t("modal.filter.reset")}
            </button>
          </div>
          <SBButton
            style={styles.applyButton}
            title={t("modal.filter.apply")}
            type={ButtonType.Button}
            variation={ButtonVariation.Emphasis}
            onClick={onApplyFilters}
          />
        </div>
      }
      headerText={t("modal.filter.header")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <fieldset {...stylex.props(styles.fieldset)}>
        <div {...stylex.props(styles.row, styles.rowFirst)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {t("modal.filter.priceRange")}
          </SBHeading>
          <div {...stylex.props(styles.range)}>
            <SBTextInput
              id="newPriceMin"
              label=""
              maxLength={PRICE_MAX_LENGTH}
              placeholder={t("modal.filter.low")}
              small={true}
              type={TextInputType.Number}
              value={newPriceMin?.toString() ?? ""}
              onChange={handlePriceMinChange}
            />
            <span {...stylex.props(styles.rangeIndicator)}>
              {t("modal.filter.range")}
            </span>
            <SBTextInput
              id="newPriceMax"
              label=""
              maxLength={PRICE_MAX_LENGTH}
              placeholder={t("modal.filter.high")}
              small={true}
              type={TextInputType.Number}
              value={newPriceMax?.toString() ?? ""}
              onChange={handlePriceMaxChange}
            />
          </div>
        </div>
        <div {...stylex.props(styles.row)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {t("modal.filter.gradingAuthority")}
          </SBHeading>
          <div {...stylex.props(styles.toggles)}>
            {orderedGradingAuthorities.map((ga) => (
              <SBToggleButton
                key={ga}
                block={true}
                id={ga}
                selected={newGradingAuthorities.includes(
                  ga as GradingAuthority,
                )}
                title={t(`gradingAuthority.${ga}`)}
                onChange={handleGradingAuthorityChange(ga)}
              />
            ))}
          </div>
        </div>
        <div {...stylex.props(styles.row)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {t("modal.filter.grade")}
          </SBHeading>
          <div {...stylex.props(styles.range)}>
            <SBSelectInput
              center={true}
              options={GRADES}
              placeholder={t("modal.filter.low")}
              value={
                GRADES.find(({ id }) => id === newGradeMin?.toString()) ??
                GRADE_MIN_DEFAULT
              }
              onChange={handleGradeMinChange}
            />
            <span {...stylex.props(styles.rangeIndicator)}>
              {t("modal.filter.range")}
            </span>
            <SBSelectInput
              center={true}
              options={
                newGradeMin === ""
                  ? GRADES
                  : GRADES.filter(
                      ({ id }) =>
                        Number.parseFloat(id) >= Number.parseFloat(newGradeMin),
                    )
              }
              placeholder={t("modal.filter.high")}
              value={
                GRADES.find(({ id }) => id === newGradeMax?.toString()) ??
                GRADE_MAX_DEFAULT
              }
              onChange={handleGradeMaxChange}
            />
          </div>
        </div>
        <div {...stylex.props(styles.row)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {t("modal.filter.year")}
          </SBHeading>
          <div {...stylex.props(styles.range)}>
            <SBSelectInput
              center={true}
              options={getYearOptions()}
              placeholder={t("modal.filter.low")}
              value={
                getYearOptions().find(
                  ({ id }) => id === newYearMin?.toString(),
                ) ?? YEAR_MIN_DEFAULT
              }
              onChange={handleYearMinChange}
            />
            <span {...stylex.props(styles.rangeIndicator)}>
              {t("modal.filter.range")}
            </span>
            <SBSelectInput
              center={true}
              options={getYearOptions(
                newYearMin === "" ? EARLIEST_YEAR : parseInt(newYearMin, 10),
              )}
              placeholder={t("modal.filter.high")}
              value={
                getYearOptions().find(
                  ({ id }) => id === newYearMax?.toString(),
                ) ?? YEAR_MAX_DEFAULT
              }
              onChange={handleYearMaxChange}
            />
          </div>
          <div {...stylex.props(styles.toggles, styles.togglesEras)}>
            {ERAS.map((era) => (
              <SBToggleButton
                key={era}
                block={true}
                id={era}
                selected={newEra === era}
                title={t(`era.${era}.title`)}
                onChange={handleClickEra(era)}
              />
            ))}
          </div>
        </div>
        <div {...stylex.props(styles.row)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {t("modal.filter.label")}
          </SBHeading>
          <div {...stylex.props(styles.toggles)}>
            {orderedCategoryRollups.map((cr) => (
              <SBToggleButton
                key={cr}
                block={true}
                id={cr}
                selected={newCategoryRollups.includes(cr as CategoryRollup)}
                title={t(`categoryRollup.${cr}`)}
                onChange={handleCategoryRollupChange(cr)}
              />
            ))}
          </div>
        </div>
        <div {...stylex.props(styles.row)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {t("modal.filter.unique")}
          </SBHeading>
          <div {...stylex.props(styles.toggles)}>
            {orderedSpecialCopies.map((sc) => (
              <SBToggleButton
                key={sc}
                block={true}
                id={sc}
                selected={newSpecialCopies.includes(sc as SpecialCopy)}
                title={t(`specialCopy.${sc}`)}
                onChange={handleSpecialCopyChange(sc)}
              />
            ))}
          </div>
        </div>
      </fieldset>
    </SBModal>
  );
};

const styles = stylex.create({
  applyButton: {
    flexGrow: 0,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 16,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: 20,
    marginBottom: 12,
  },
  range: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rangeIndicator: {
    marginInline: 8,
  },
  reset: {
    alignItems: "center",
    display: "flex",
  },
  resetButton: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginRight: 16,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  resetIcon: {
    color: colors.color,
    marginRight: 8,
  },
  row: {
    marginTop: 24,
  },
  rowFirst: {
    marginTop: 0,
  },
  toggles: {
    display: "grid",
    gridColumnGap: 24,
    gridRowGap: 12,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  togglesEras: {
    marginTop: 12,
  },
});
