/**
 * @generated SignedSource<<eca54d01b2e07fc2546bd0ae6a8514ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountStoreNameField_user$data = {
  readonly id: string;
  readonly shopName: string | null | undefined;
  readonly " $fragmentType": "SellerAccountStoreNameField_user";
};
export type SellerAccountStoreNameField_user$key = {
  readonly " $data"?: SellerAccountStoreNameField_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStoreNameField_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountStoreNameField_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shopName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0635e79404ea397a2b8d038e1f1d24d5";

export default node;
