/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SearchResultCard_product$key } from "src/types/__generated__/SearchResultCard_product.graphql";
import type { SearchResultCard_typesenseSearch$key } from "src/types/__generated__/SearchResultCard_typesenseSearch.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useCallback } from "react";
import { useFragment } from "react-relay";

import { useFavorite, useUnfavorite } from "src/hooks";
import { SBProductTile } from "src/sbxui";

type Props = Readonly<{
  queryKey: SearchResultCard_typesenseSearch$key;
}>;

const SearchResultCard = ({ queryKey }: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment SearchResultCard_typesenseSearch on TypesenseSearch {
        product {
          ...SearchResultCard_product
        }
        id
        createdAt
        title
        images {
          edges {
            node {
              url(quality: 85, webp: true, width: 500)
            }
          }
        }
        number
        grade
        gradingAuthority
        productId
        auctionLotId
        fmvRank
        fmvMinValue
        fmvMaxValue
        currentBidAmount
        price
        currency
        salesChannel
      }
    `,
    queryKey,
  );

  const product = useFragment<SearchResultCard_product$key>(
    graphql`
      fragment SearchResultCard_product on Product {
        hasViewerFavorited
      }
    `,
    data.product,
  );

  const [commitFavorite, isInFlightFavorite] = useFavorite();
  const [commitUnfavorite, isInFlightUnfavorite] = useUnfavorite();

  const {
    auctionLotId,
    currentBidAmount,
    currency,
    fmvMaxValue,
    fmvMinValue,
    fmvRank,
    grade,
    gradingAuthority,
    images,
    number,
    price,
    productId,
    salesChannel,
    title,
  } = data;

  const imageUrl = images?.edges?.[0]?.node?.url ?? "";
  const hasViewerFavorited = product?.hasViewerFavorited ?? false;

  const handleClickFavorite = useCallback(() => {
    if (isInFlightFavorite || isInFlightUnfavorite) {
      return;
    }
    const variables = {
      input: {
        productId,
      },
    };
    hasViewerFavorited
      ? commitUnfavorite({
          optimisticResponse: {
            unfavorite: {
              hasViewerFavorited: false,
              id: productId,
            },
          },
          variables,
        })
      : commitFavorite({
          optimisticResponse: {
            favorite: {
              hasViewerFavorited: true,
              id: productId,
            },
          },
          variables,
        });
  }, [
    commitFavorite,
    commitUnfavorite,
    hasViewerFavorited,
    isInFlightFavorite,
    isInFlightUnfavorite,
    productId,
  ]);

  return (
    <SBProductTile
      auctionLotId={auctionLotId}
      currency={currency}
      currentBidAmount={currentBidAmount}
      favorite={hasViewerFavorited}
      favoriteLoading={isInFlightFavorite || isInFlightUnfavorite}
      fmvMaxValue={fmvMaxValue}
      fmvMinValue={fmvMinValue}
      fmvRank={fmvRank}
      grade={grade}
      gradingAuthority={gradingAuthority}
      imageUrl={imageUrl}
      number={number}
      price={price}
      productId={productId}
      salesChannel={salesChannel}
      title={title}
      onClickFavorite={handleClickFavorite}
    />
  );
};

export default SearchResultCard;
