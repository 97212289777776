/**
 * @generated SignedSource<<1c0f8743a471c3bc0f4f83f0cf6ec26a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountInvoicesView_user$data = {
  readonly activeInvoices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"AccountInvoiceCard_invoice">;
      };
    }>;
  };
  readonly closedInvoices: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"AccountInvoiceCard_invoice">;
      };
    }>;
  };
  readonly " $fragmentType": "AccountInvoicesView_user";
};
export type AccountInvoicesView_user$key = {
  readonly " $data"?: AccountInvoicesView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountInvoicesView_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v1 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "createdAt": "DESC"
  }
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InvoicesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Invoice",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountInvoiceCard_invoice"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountInvoicesView_user",
  "selections": [
    {
      "alias": "activeInvoices",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "status": [
              "OPEN",
              "TEMP",
              "PROCESSING",
              "REQUIRES_ACTION"
            ]
          }
        }
      ],
      "concreteType": "InvoicesConnection",
      "kind": "LinkedField",
      "name": "invoices",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "invoices(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"status\":[\"OPEN\",\"TEMP\",\"PROCESSING\",\"REQUIRES_ACTION\"]})"
    },
    {
      "alias": "closedInvoices",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "status": [
              "EXPIRED",
              "CLOSED",
              "PAID"
            ]
          }
        }
      ],
      "concreteType": "InvoicesConnection",
      "kind": "LinkedField",
      "name": "invoices",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "invoices(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"status\":[\"EXPIRED\",\"CLOSED\",\"PAID\"]})"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "815bb83417a4034b2e590762ceb431ca";

export default node;
