/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountFavoritesQuery } from "src/types/__generated__/QueriesAccountFavoritesQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import AccountPage from "../AccountPage";
import AccountFavoritesContent from "./AccountFavoritesContent";

const AccountFavorites = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesAccountFavoritesQuery>;

  return (
    <AccountPage docTitle={t("account.addresses.title")}>
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <AccountFavoritesContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </AccountPage>
  );
};

export default AccountFavorites;
