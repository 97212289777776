/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountQuery } from "src/types/__generated__/QueriesAccountQuery.graphql";

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePreloadedQuery } from "react-relay";
import { useLocation } from "react-router-dom";

import { AccountQuery } from "src/app/router/Queries";
import {
  ACCOUNT_ADDRESSES_PATH,
  ACCOUNT_FAVORITES_PATH,
  ACCOUNT_INVOICES_PATH,
  ACCOUNT_OFFERS_PATH,
  ACCOUNT_ORDERS_PATH,
  ACCOUNT_PASSWORD_PATH,
  ACCOUNT_PATH,
} from "src/app/router/Router";
import { SBPageHeader } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import AccountAddressesView from "./AccountAddressesView";
import AccountFavoritesView from "./AccountFavoritesView";
import AccountInvoicesView from "./AccountInvoicesView";
import AccountOffersView from "./AccountOffersView";
import AccountOrdersView from "./AccountOrdersView";
import AccountPasswordChangeView from "./AccountPasswordChangeView";
import AccountPersonalInfoView from "./AccountPersonalInfoView";
import AccountSideNav from "./AccountSideNav";

const MOBILE = "@media (max-width: 767px)";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountQuery>;
}>;

const AccountContent = ({
  queryRef,
}: Props): React.ReactNode | null | undefined => {
  const queryKey = usePreloadedQuery(AccountQuery, queryRef);
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const user = queryKey?.viewer.user;

  if (user == null) {
    return null;
  }

  const getSection = (): React.ReactNode => {
    switch (true) {
      case pathname === ACCOUNT_PASSWORD_PATH:
        return <AccountPasswordChangeView />;
      case pathname.startsWith(ACCOUNT_ORDERS_PATH):
        return <AccountOrdersView queryKey={user} />;
      case pathname === ACCOUNT_FAVORITES_PATH:
        return <AccountFavoritesView queryKey={user} />;
      case pathname === ACCOUNT_OFFERS_PATH:
        return <AccountOffersView queryKey={user} />;
      case pathname === ACCOUNT_INVOICES_PATH:
        return <AccountInvoicesView queryKey={user} />;
      case pathname === ACCOUNT_ADDRESSES_PATH:
        return <AccountAddressesView queryKey={user} />;
      case pathname === ACCOUNT_PATH:
      default:
        return <AccountPersonalInfoView queryKey={user} />;
    }
  };

  return (
    <div>
      <SBPageHeader title={t("account.pageTitle")} />
      <div {...stylex.props(styles.root)}>
        <AccountSideNav />
        <div {...stylex.props(styles.main)}>
          <div {...stylex.props(auto, styles.section)}>{getSection()}</div>
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  main: {
    gridColumn: "span 4 / span 4",
  },
  root: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      default: "repeat(6, 1fr)",
    },
  },
  section: {
    borderBottomColor: colors.horizontalRuleColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    marginBottom: 48,
    marginTop: 20,
    paddingBottom: 48,
  },
});

export default AccountContent;
