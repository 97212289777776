/**
 * @generated SignedSource<<c6d3fc3aec094639675573407727a6b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuctionLotView_auctionLot$data = {
  readonly currentBidAmount: number;
  readonly currentBidCurrency: Currency;
  readonly id: string;
  readonly isViewerWatching: boolean;
  readonly product: {
    readonly comicDetails: {
      readonly artComments: string | null | undefined;
      readonly grade: number | null | undefined;
      readonly gradingAuthority: GradingAuthority;
      readonly keyComments: string | null | undefined;
      readonly number: string;
      readonly publisher: string;
      readonly title: string;
      readonly year: string | null | undefined;
      readonly " $fragmentSpreads": FragmentRefs<"CensusTable_comicDetail">;
    } | null | undefined;
    readonly images: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly url: string;
        };
      }>;
    } | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"AuctionLotHeader_product" | "AuctionLotMeta_product" | "HistoricalSalesTable_product" | "ReportProductIssueModal_product">;
  } | null | undefined;
  readonly shareUrl: string;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionLotDescription_auctionLot">;
  readonly " $fragmentType": "AuctionLotView_auctionLot";
};
export type AuctionLotView_auctionLot$key = {
  readonly " $data"?: AuctionLotView_auctionLot$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionLotView_auctionLot">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionLotView_auctionLot",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerWatching",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentBidAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentBidCurrency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shareUrl",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuctionLotDescription_auctionLot"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AuctionLotHeader_product"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AuctionLotMeta_product"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HistoricalSalesTable_product"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ReportProductIssueModal_product"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ImagesConnection",
          "kind": "LinkedField",
          "name": "images",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImagesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "quality",
                          "value": 100
                        },
                        {
                          "kind": "Literal",
                          "name": "webp",
                          "value": true
                        },
                        {
                          "kind": "Literal",
                          "name": "width",
                          "value": 1000
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": "url(quality:100,webp:true,width:1000)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ComicDetail",
          "kind": "LinkedField",
          "name": "comicDetails",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CensusTable_comicDetail"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "publisher",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "year",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gradingAuthority",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grade",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "keyComments",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "artComments",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AuctionLot",
  "abstractKey": null
};

(node as any).hash = "5081e59c6c91c87e6b1ae52d771de686";

export default node;
