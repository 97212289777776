/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailActionsSeller_product$key } from "src/types/__generated__/ProductDetailActionsSeller_product.graphql";

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import EditProduct from "src/app/components/edit-product/EditProduct";
import SendFavoritesOffer from "src/app/components/send-favorites-offer/SendFavoritesOffer";
import { SELLER_ACCOUNT_PATH } from "src/app/router/Router";
import { useCountdownTimer } from "src/hooks";
import { SBButton, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

enum Modal {
  None,
  FavoritesOffer,
  Edit,
}

type Props = Readonly<{
  employee: boolean;
  queryKey: ProductDetailActionsSeller_product$key;
}>;

const ProductDetailActionsSeller = ({ queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProductDetailActionsSeller_product on Product {
        id
        isViewerApiUser
        favoritesCount
        minOfferPrice
        minOfferPercentage
        canRequestAuctionConsignment
        canSubmitToCommunityAuctions
        offerCounts {
          pending
          received
          favorite
        }
        sellerAcceptedOffers: offers(
          first: 1
          orderBy: { updatedAt: DESC }
          where: { status: ACCEPTED }
        ) {
          count
          edges {
            node {
              id
              status
              reason
              price
              currency
              expiresAt
            }
          }
        }
      }
    `,
    queryKey,
  );

  const productId = data?.id;

  const isViewerApiUser = data?.isViewerApiUser ?? false;

  const sellerAcceptedOffers = data?.sellerAcceptedOffers?.edges ?? [];
  const sellerAcceptedOffersCount = data?.sellerAcceptedOffers?.count ?? 0;

  const favoriteOffersCount = data?.offerCounts?.favorite ?? 0;
  const receivedOffersCount = data?.offerCounts?.received ?? 0;
  const pendingOffersCount = data?.offerCounts?.pending ?? 0;

  const favoritesCount = data.favoritesCount ?? 0;
  const minOfferPrice = data.minOfferPrice;
  const minOfferPercentage = data.minOfferPercentage ?? 100;

  const canRequestAuctionConsignment =
    data?.canRequestAuctionConsignment ?? false;

  const canSubmitToCommunityAuctions =
    data?.canSubmitToCommunityAuctions ?? false;

  const acceptedOfferPrice = sellerAcceptedOffers[0]?.node.price;
  const acceptedOfferCurrency = sellerAcceptedOffers[0]?.node.currency;
  const acceptedOfferExpiresAt = sellerAcceptedOffers[0]?.node.expiresAt;

  const acceptedOfferExpiry = Number(acceptedOfferExpiresAt);

  const { expiresIn } = useCountdownTimer(acceptedOfferExpiry);

  const wasOfferAccepted = sellerAcceptedOffersCount > 0;

  const allOffersCount = pendingOffersCount + receivedOffersCount;

  const hasOpenOffers = allOffersCount > 0;

  const isSendToAuctionButtonEnabled =
    canRequestAuctionConsignment || canSubmitToCommunityAuctions;

  const [modal, setModal] = useState(Modal.None);

  const iseSendOfferToFavoritesButtonEnabled =
    modal !== Modal.FavoritesOffer && favoritesCount > 0 && !hasOpenOffers;

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.languages),
    [i18n.languages],
  );

  const handleCloseModal = () => {
    setModal(Modal.None);
  };

  const handleClickFavoritesOffer = (event: React.SyntheticEvent) => {
    if (!iseSendOfferToFavoritesButtonEnabled) {
      event.preventDefault();
    } else if (favoriteOffersCount < 1) {
      event.preventDefault();
      setModal(Modal.FavoritesOffer);
    }
  };

  const handleClickEdit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setModal(Modal.Edit);
  };

  if (process.env.REACT_APP_SHOW_SELLER_ACTIONS !== "true") {
    return null;
  }

  if (productId == null) {
    return null;
  }

  if (isViewerApiUser) {
    return null;
  }

  return (
    <>
      <div {...stylex.props(styles.container)}>
        {wasOfferAccepted ? (
          <div>
            <SBParagraph style={styles.paragraph}>
              {t("product.offer.seller.accepted.title", {
                price: formatMoney(
                  acceptedOfferPrice,
                  acceptedOfferCurrency,
                  i18n.language,
                ),
              })}
            </SBParagraph>
            <SBParagraph style={styles.paragraph}>{expiresIn}</SBParagraph>
          </div>
        ) : (
          <>
            {(minOfferPrice != null || minOfferPercentage < 100) && (
              <>
                <SBButton
                  block={true}
                  style={styles.button}
                  title={t("product.review-offers", {
                    count: allOffersCount,
                    offers: numberFormatter.format(allOffersCount),
                  })}
                  to={SELLER_ACCOUNT_PATH}
                />
                <SBButton
                  block={true}
                  disabled={!iseSendOfferToFavoritesButtonEnabled}
                  icon="favorite"
                  iconColor={colors.favoriteIconSelectedColor}
                  style={styles.button}
                  title={
                    favoriteOffersCount > 0
                      ? t("product.favorites-offer-review.title")
                      : t("product.favorites-offer.title")
                  }
                  to={SELLER_ACCOUNT_PATH}
                  onClick={handleClickFavoritesOffer}
                />
              </>
            )}
            <SBButton
              block={true}
              disabled={!isSendToAuctionButtonEnabled}
              style={styles.button}
              title={t("product.buttons.send-to-auction")}
            />
            <SBButton
              block={true}
              disabled={modal === Modal.Edit}
              icon="edit"
              style={styles.button}
              title={t("product.buttons.edit")}
              onClick={handleClickEdit}
            />
          </>
        )}
      </div>
      <SendFavoritesOffer
        isOpen={modal === Modal.FavoritesOffer}
        productId={productId}
        setOpen={handleCloseModal}
      />
      <EditProduct
        isOpen={modal === Modal.Edit}
        productId={productId}
        setOpen={handleCloseModal}
      />
    </>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },
  container: {
    width: "100%",
  },
  paragraph: {
    marginBottom: 0,
  },
});

export default ProductDetailActionsSeller;
