/**
 * @generated SignedSource<<f8bb5d4b4ddd97e575d67ed802180160>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailView_product$data = {
  readonly comicDetails: {
    readonly grade: number | null | undefined;
    readonly gradingAuthority: GradingAuthority;
    readonly number: string;
    readonly title: string;
    readonly " $fragmentSpreads": FragmentRefs<"CensusTable_comicDetail">;
  } | null | undefined;
  readonly createdAt: string;
  readonly id: string;
  readonly updatedAt: string;
  readonly " $fragmentSpreads": FragmentRefs<"HistoricalSalesTable_product" | "ProductDetailCarousel_product" | "ProductDetailHeader_product" | "ProductDetailMeta_product">;
  readonly " $fragmentType": "ProductDetailView_product";
};
export type ProductDetailView_product$key = {
  readonly " $data"?: ProductDetailView_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailView_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailView_product",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailHeader_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistoricalSalesTable_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailMeta_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailCarousel_product"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CensusTable_comicDetail"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "9d85ecaaa22c8f84a0e7407bfd930faf";

export default node;
