/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { InvoicePaymentMethod } from "src/types/__generated__/InvoiceView_invoice.graphql";
import type { ProductDetailActionsBuyerInReview_product$key } from "src/types/__generated__/ProductDetailActionsBuyerInReview_product.graphql";
import type { ProductDetailActionsBuyerInReviewMutation } from "src/types/__generated__/ProductDetailActionsBuyerInReviewMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";

import { INVOICE_PATH } from "src/app/router/Router";
import { useCountdownTimer } from "src/hooks";
import { ButtonType, ButtonVariation, SBButton, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

type Props = Readonly<{
  achMinimumValue: number;
  queryKey: ProductDetailActionsBuyerInReview_product$key;
}>;

const ProductDetailActionsBuyerInReview = ({
  achMinimumValue,
  queryKey,
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [commit, isInFlight] =
    useMutation<ProductDetailActionsBuyerInReviewMutation>(graphql`
      mutation ProductDetailActionsBuyerInReviewMutation(
        $input: CreateBuyNowInvoiceInput!
      ) {
        createBuyNowInvoice(createBuyNowInvoiceInput: $input) {
          ... on Invoice {
            id
          }
        }
      }
    `);

  const data = useFragment(
    graphql`
      fragment ProductDetailActionsBuyerInReview_product on Product {
        id
        price
        currency
        minOfferPrice
        minOfferPercentage
        inReviewOffers: offers(
          first: 1
          orderBy: { expiresAt: DESC }
          where: { status: IN_REVIEW }
        ) {
          edges {
            node {
              id
              price
              currency
              expiresAt
              state
            }
          }
        }
      }
    `,
    queryKey,
  );

  const productId = data.id;
  const price = data.price;

  const offer = data.inReviewOffers?.edges?.[0]?.node;
  const offerPrice = offer?.price ?? 0;
  const currency = offer?.currency ?? "USD";
  const expiresAt = offer?.expiresAt;
  const expiry = Number(expiresAt);
  const { expiresIn } = useCountdownTimer(expiry);

  const paymentMethod: InvoicePaymentMethod =
    price >= achMinimumValue ? "ACH" : "CREDIT_CARD";

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleClickBuy = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlight) {
      return;
    }

    commit({
      onCompleted(response) {
        navigate(
          INVOICE_PATH.replace(":id", response.createBuyNowInvoice?.id ?? ""),
        );
      },
      onError(error) {
        setErrorMessage(
          // @ts-ignore This is the localized message from the error response.
          error?.res?.errors?.[0].message ?? t("products.buy.errors.default"),
        );
      },
      variables: {
        input: {
          paymentMethod,
          productId,
        },
      },
    });
  };

  return (
    <>
      <div {...stylex.props(styles.offerInfo)}>
        <SBParagraph style={styles.title}>
          {t("product.status.review.title")}
        </SBParagraph>
        <SBParagraph style={styles.offerPrice}>
          {formatMoney(offerPrice, currency, i18n.language)}
        </SBParagraph>{" "}
        <SBParagraph style={styles.expiry}>{expiresIn}</SBParagraph>
        <SBParagraph>{t("product.status.review.subtext")}</SBParagraph>
      </div>
      <SBButton
        block={true}
        loading={isInFlight}
        style={styles.button}
        title={t("product.header.buttons.buy-now-for", {
          price: formatMoney(price, currency, i18n.language),
        })}
        type={ButtonType.Button}
        variation={ButtonVariation.Emphasis}
        onClick={handleClickBuy}
      />
      {errorMessage ? (
        <SBParagraph style={styles.error}>
          {t("common.unexpected.error")}
        </SBParagraph>
      ) : null}
    </>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },
  error: {
    fontSize: 14,
    marginTop: 16,
  },
  expiry: {
    color: colors.colorEmphasis,
  },
  offerInfo: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 16,
  },
  offerPrice: {
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 72,
  },
  title: {
    marginBottom: 0,
  },
});

export default ProductDetailActionsBuyerInReview;
