/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";
import type { LoginResponseType } from "src/app/context/user";
import type { AppleSSOButtonAppleLoginMutation } from "src/types/__generated__/AppleSSOButtonAppleLoginMutation.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import AppleSignIn from "react-apple-signin-auth";
import { useMutation } from "react-relay";

import { API_CLIENT_ID } from "src/app/constants";
import { AppleButtonTheme, SBAppleButton } from "src/sbxui";

const CLIENT_ID = "com.shortboxed.apple.login.www";

// FIXME This is an invalid redirect URL.
const SHORTBOXED_REDIRECT_URL = "https://shortboxed.com";

type TokenResponse = Readonly<{
  authorization: {
    id_token: string;
  };
}>;

type ButtonProps = Readonly<
  React.PropsWithChildren<{
    onClick: (event: React.SyntheticEvent) => void;
  }>
>;

type Props = Readonly<{
  onLoginSuccess: (
    loginResponse: LoginResponseType,
    needsTermsFixUp: boolean,
  ) => void;
  signup?: boolean;
  style?: StyleXStyles;
  theme?: AppleButtonTheme;
}>;

export const AppleSSOButton = ({
  signup,
  style,
  theme = AppleButtonTheme.Dark,
  onLoginSuccess,
}: Props): React.ReactNode => {
  const [commit] = useMutation<AppleSSOButtonAppleLoginMutation>(graphql`
    mutation AppleSSOButtonAppleLoginMutation(
      $input: SocialOAuthTokenVerificationInput!
    ) {
      appleLogin(socialOAuthTokenVerificationInput: $input) {
        accessToken
        refreshToken
        user {
          id
          sellerStatus
          hasAcceptedTermsOfService
          hasAcceptedPrivacyPolicy
        }
        permissions
      }
    }
  `);

  return (
    <AppleSignIn
      authOptions={{
        clientId: CLIENT_ID,
        redirectURI:
          process.env.REACT_APP_APPLE_REDIRECT_URI ?? SHORTBOXED_REDIRECT_URL,
        scope: "email name",
        usePopup: true,
      }}
      render={({ onClick }: ButtonProps) => {
        return (
          <SBAppleButton
            signup={signup}
            style={style}
            theme={theme}
            onClick={onClick}
          />
        );
      }}
      uiType="light"
      // FIXME Need error handling here.
      // eslint-disable-next-line no-console
      onError={(error: Error) => console.error(error)} // default = undefined
      onSuccess={(tokenResponse: TokenResponse) => {
        const token = tokenResponse.authorization.id_token;
        if (token == null) {
          // FIXME Need error handling here.
          // eslint-disable-next-line no-console
          console.error("No token received");
        }
        commit({
          onCompleted(data) {
            const needsTermsFixUp =
              data.appleLogin.user?.hasAcceptedPrivacyPolicy === false ||
              data.appleLogin.user?.hasAcceptedTermsOfService === false;

            onLoginSuccess(data.appleLogin, needsTermsFixUp);
          },
          variables: {
            input: {
              clientId: API_CLIENT_ID,
              token,
            },
          },
        });
      }}
    />
  );
};
