/**
 * @generated SignedSource<<830d883d9434b272a38667c1fdc9eef2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerAccountStatsTurnaroundTimesTable_sellerStats$data = {
  readonly averageTurnaroundTime: number;
  readonly " $fragmentType": "SellerAccountStatsTurnaroundTimesTable_sellerStats";
};
export type SellerAccountStatsTurnaroundTimesTable_sellerStats$key = {
  readonly " $data"?: SellerAccountStatsTurnaroundTimesTable_sellerStats$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStatsTurnaroundTimesTable_sellerStats">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountStatsTurnaroundTimesTable_sellerStats",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "averageTurnaroundTime",
      "storageKey": null
    }
  ],
  "type": "UserSellerStats",
  "abstractKey": null
};

(node as any).hash = "bc7f95d1de290aa0aa9e7db355f9636c";

export default node;
