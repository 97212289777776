/**
 * @generated SignedSource<<ac4da2ce8c4f3532167d739b638d9f12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Vertical = "COMIC" | "ORIGINAL_ART" | "SPORTS_CARD" | "TCG" | "VIDEO_GAME" | "%future added value";
export type QueriesShopQuery$variables = {
  vertical: Vertical;
};
export type QueriesShopQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ShopView_verticalSearch">;
};
export type QueriesShopQuery = {
  response: QueriesShopQuery$data;
  variables: QueriesShopQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vertical"
  }
],
v1 = {
  "kind": "Variable",
  "name": "vertical",
  "variableName": "vertical"
},
v2 = [
  {
    "kind": "Literal",
    "name": "after",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 24
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "createdAt": "DESC"
    }
  },
  (v1/*: any*/),
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "categoryRollups",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "gradeMax",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "gradeMin",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "gradingAuthorities",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "priceMax",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "priceMin",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "searchTerm",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "shopUrl",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "specialCopies",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "yearMax",
        "value": null
      },
      {
        "kind": "Literal",
        "name": "yearMin",
        "value": null
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesShopQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ShopView_verticalSearch"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesShopQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "VerticalSearchConnection",
        "kind": "LinkedField",
        "name": "verticalSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VerticalSearchEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TypesenseSearch",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "product",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasViewerFavorited",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImagesConnection",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImagesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "quality",
                                    "value": 85
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "webp",
                                    "value": true
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "width",
                                    "value": 500
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": "url(quality:85,webp:true,width:500)"
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "auctionLotId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvRank",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvMinValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvMaxValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentBidAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salesChannel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "vertical",
          "orderBy",
          "where"
        ],
        "handle": "connection",
        "key": "ShopView_verticalSearch",
        "kind": "LinkedHandle",
        "name": "verticalSearch"
      }
    ]
  },
  "params": {
    "cacheID": "1bc49bd0e03f152247ea376c2a9b226f",
    "id": null,
    "metadata": {},
    "name": "QueriesShopQuery",
    "operationKind": "query",
    "text": "query QueriesShopQuery(\n  $vertical: Vertical!\n) {\n  ...ShopView_verticalSearch_2qYbiK\n}\n\nfragment SearchResultCard_product on Product {\n  hasViewerFavorited\n}\n\nfragment SearchResultCard_typesenseSearch on TypesenseSearch {\n  product {\n    ...SearchResultCard_product\n    id\n  }\n  id\n  createdAt\n  title\n  images {\n    edges {\n      node {\n        url(quality: 85, webp: true, width: 500)\n        id\n      }\n    }\n  }\n  number\n  grade\n  gradingAuthority\n  productId\n  auctionLotId\n  fmvRank\n  fmvMinValue\n  fmvMaxValue\n  currentBidAmount\n  price\n  currency\n  salesChannel\n}\n\nfragment ShopView_verticalSearch_2qYbiK on Query {\n  verticalSearch(vertical: $vertical, after: \"\", first: 24, orderBy: {createdAt: DESC}, where: {}) {\n    edges {\n      node {\n        id\n        ...SearchResultCard_typesenseSearch\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d9730988cf4054d636859508d38137e0";

export default node;
