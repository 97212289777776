/**
 * @generated SignedSource<<8c8af65b6c894ccfb5a201b8314e89f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type SignUpInput = {
  allowMarketingNotifs?: boolean | null | undefined;
  countryCode?: number | null | undefined;
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
  telephone?: number | null | undefined;
};
export type SignupFormMutation$variables = {
  input: SignUpInput;
};
export type SignupFormMutation$data = {
  readonly signup: {
    readonly emailAddress: string;
    readonly firstName: string | null | undefined;
    readonly lastName: string | null | undefined;
  };
};
export type SignupFormMutation = {
  response: SignupFormMutation$data;
  variables: SignupFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "signupInput",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailAddress",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "signup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignupFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "signup",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "15d5ea8257f7b6a2350da6cdc04d6ce0",
    "id": null,
    "metadata": {},
    "name": "SignupFormMutation",
    "operationKind": "mutation",
    "text": "mutation SignupFormMutation(\n  $input: SignUpInput!\n) {\n  signup(signupInput: $input) {\n    firstName\n    lastName\n    emailAddress\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a2772b0f43b725f479c798d6ef0491b";

export default node;
