/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SignupForm from "src/app/components/signup/SignupForm";
import { SHORTBOXED_CDN_BASE_URL } from "src/app/constants";
import { PageContext } from "src/app/context/page";
import { UserContext } from "src/app/context/user";
import { HOME_PATH } from "src/app/router/Router";
import { SBBackButton } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";

const SIGNIN_IMG = `${SHORTBOXED_CDN_BASE_URL}/signin.webp`;

const Signup = (): React.ReactNode => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  const userId = user?.userId;

  const [opacity, setOpacity] = useState(0);

  const pageContext = useContext(PageContext);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setOpacity(1);
    };
    img.src = SIGNIN_IMG;
  }, []);

  useEffect(() => {
    if (userId != null) {
      navigate(HOME_PATH, { replace: true });
    }
  }, [navigate, userId]);

  useEffect(() => {
    pageContext?.setTitle(t("signup.document-title"));
  }, [t, pageContext]);

  return (
    <div {...stylex.props(auto, styles.root)}>
      <div {...stylex.props(styles.image(SIGNIN_IMG, opacity))}></div>
      <div {...stylex.props(auto, styles.form)}>
        <SBBackButton
          label={t("common.buttons.home")}
          style={styles.backButton}
          to={HOME_PATH}
        />
        <SignupForm />
      </div>
    </div>
  );
};

const styles = stylex.create({
  backButton: {
    left: 32,
    position: "absolute",
    top: 32,
  },
  form: {
    alignItems: "center",
    backgroundColor: colors.backgroundEmphasisColor,
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  image: (imageUrl: string, opacity: number) => ({
    backgroundImage: `url("${imageUrl}")`,
    backgroundPositionX: "center",
    backgroundPositionY: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    borderRadius: 8,
    display: {
      [MOBILE]: "none",
      default: "block",
    },
    opacity,
    transition: "opacity 250ms",
  }),
  root: {
    backgroundColor: colors.headlessImageBackgroundColor,
    display: "grid",
    gridGap: 0,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      default: "repeat(2, 1fr)",
    },
    height: "100vh",
  },
});

export default React.memo(Signup);
