/**
 * @generated SignedSource<<063cf33a536907a77a1751b2ffa1a9ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountOffersView_user$data = {
  readonly activeOffers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"AccountOfferCard_offer">;
      };
    }>;
  } | null | undefined;
  readonly endedOffers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"AccountOfferCard_offer">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "AccountOffersView_user";
};
export type AccountOffersView_user$key = {
  readonly " $data"?: AccountOffersView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountOffersView_user">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v1 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "createdAt": "DESC"
  }
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OffersEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Offer",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountOfferCard_offer"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountOffersView_user",
  "selections": [
    {
      "alias": "activeOffers",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "situation": "ACTIVE"
          }
        }
      ],
      "concreteType": "BuyerOffersConnection",
      "kind": "LinkedField",
      "name": "buyerOffers",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "buyerOffers(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"situation\":\"ACTIVE\"})"
    },
    {
      "alias": "endedOffers",
      "args": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "situation": "ENDED"
          }
        }
      ],
      "concreteType": "BuyerOffersConnection",
      "kind": "LinkedField",
      "name": "buyerOffers",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": "buyerOffers(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"situation\":\"ENDED\"})"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "7ec92dc34499fe07b56d7156e160698f";

export default node;
