/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountOffersView_user$key } from "src/types/__generated__/AccountOffersView_user.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading, SBTabs } from "src/sbxui";

import AccountOfferCard from "./AccountOfferCard";

type Props = Readonly<{
  queryKey: AccountOffersView_user$key;
}>;

const AccountOffersView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>("ACTIVE");

  const user = useFragment(
    graphql`
      fragment AccountOffersView_user on User {
        activeOffers: buyerOffers(
          first: 20
          where: { situation: ACTIVE }
          orderBy: { createdAt: DESC }
        ) {
          edges {
            node {
              id
              ...AccountOfferCard_offer
            }
          }
        }
        endedOffers: buyerOffers(
          first: 20
          where: { situation: ENDED }
          orderBy: { createdAt: DESC }
        ) {
          edges {
            node {
              id
              ...AccountOfferCard_offer
            }
          }
        }
      }
    `,
    queryKey,
  );

  const getTabBody = (): React.ReactNode => {
    switch (selectedTab) {
      case "ENDED":
        return (
          <div>
            {user?.endedOffers?.edges?.map(({ node }) => (
              <AccountOfferCard key={node.id} queryKey={node} />
            ))}
          </div>
        );
      case "ACTIVE":
      default:
        return (
          <div>
            {user?.activeOffers?.edges?.map(({ node }) => (
              <AccountOfferCard key={node.id} queryKey={node} />
            ))}
          </div>
        );
    }
  };

  const TABS = [
    { id: "ACTIVE", name: "Active", selected: selectedTab === "ACTIVE" },
    { id: "ENDED", name: "Ended", selected: selectedTab === "ENDED" },
  ];

  return (
    <>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("account.OFFERS")}
      </SBHeading>
      <SBTabs tabs={TABS} onTabSelected={(tabId) => setSelectedTab(tabId)} />
      {getTabBody()}
    </>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
  offers: {},
});

export default AccountOffersView;
