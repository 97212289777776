/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type {
  ProductDetailActionsBuyer_product$key,
  ProductPublicStatus,
} from "src/types/__generated__/ProductDetailActionsBuyer_product.graphql";

import * as React from "react";
import { graphql, useFragment } from "react-relay";

import ProductDetailsActionsBuyerAccepted from "./ProductDetailActionsBuyerAccepted";
import ProductDetailActionsBuyerCounter from "./ProductDetailActionsBuyerCounter";
import ProductDetailActionsBuyerDefault from "./ProductDetailActionsBuyerDefault";
import ProductDetailActionsBuyerFavorite from "./ProductDetailActionsBuyerFavorite";
import ProductDetailActionsBuyerInReview from "./ProductDetailActionsBuyerInReview";
import ProductDetailActionsBuyerProcessingPurchase from "./ProductDetailActionsBuyerProcessingPurchase";
import ProductDetailActionsBuyerSold from "./ProductDetailActionsBuyerSold";
import ProductDetailActionsBuyerUnavailable from "./ProductDetailActionsBuyerUnavailable";

type Props = Readonly<{
  achMinimumValue: number;
  queryKey: ProductDetailActionsBuyer_product$key;
}>;

const ProductDetailActionsBuyer = ({
  achMinimumValue,
  queryKey,
}: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment ProductDetailActionsBuyer_product on Product {
        id
        isViewerBuyer
        publicStatus
        inReviewOffersCount: offers(
          where: { status: IN_REVIEW, reason: [NONE, COUNTER_FROM_BUYER] }
        ) {
          count
        }
        counteredOffersCount: offers(
          where: { status: IN_REVIEW, reason: [COUNTER_FROM_SELLER] }
        ) {
          count
        }
        acceptedOffersCount: offers(where: { status: ACCEPTED }) {
          count
        }
        favoriteOffersCount: offers(
          where: { status: IN_REVIEW, reason: [FAVORITE] }
        ) {
          count
        }
        ...ProductDetailActionsBuyerDefault_product
        ...ProductDetailActionsBuyerFavorite_product
        ...ProductDetailActionsBuyerInReview_product
        ...ProductDetailActionsBuyerCounter_product
        ...ProductDetailActionsBuyerAccepted_product
      }
    `,
    queryKey,
  );

  const productId = data.id;
  const isViewerBuyer = data.isViewerBuyer ?? false;
  const publicStatus: ProductPublicStatus = data.publicStatus ?? "UNAVAILABLE";
  const inReviewOffersCount = data.inReviewOffersCount?.count ?? 0;
  const favoriteOffersCount = data.favoriteOffersCount?.count ?? 0;
  const counteredOffersCount = data.counteredOffersCount?.count ?? 0;
  const acceptedOffersCount = data.acceptedOffersCount?.count ?? 0;

  if (productId == null) {
    return null;
  }

  if (publicStatus === "SOLD") {
    return <ProductDetailActionsBuyerSold />;
  }

  if (publicStatus === "PENDING_PURCHASE" && !isViewerBuyer) {
    return <ProductDetailActionsBuyerUnavailable publicStatus={publicStatus} />;
  }

  if (publicStatus === "PROCESSING_PURCHASE" && !isViewerBuyer) {
    return <ProductDetailActionsBuyerUnavailable publicStatus={publicStatus} />;
  }

  if (publicStatus === "PROCESSING_PURCHASE" && isViewerBuyer) {
    return <ProductDetailActionsBuyerProcessingPurchase />;
  }

  if (acceptedOffersCount > 0) {
    return (
      <ProductDetailsActionsBuyerAccepted
        achMinimumValue={achMinimumValue}
        queryKey={data}
      />
    );
  }

  if (favoriteOffersCount > 0) {
    return <ProductDetailActionsBuyerFavorite queryKey={data} />;
  }

  if (counteredOffersCount > 0) {
    return <ProductDetailActionsBuyerCounter queryKey={data} />;
  }

  if (inReviewOffersCount > 0) {
    return (
      <ProductDetailActionsBuyerInReview
        achMinimumValue={achMinimumValue}
        queryKey={data}
      />
    );
  }

  return (
    <ProductDetailActionsBuyerDefault
      achMinimumValue={achMinimumValue}
      queryKey={data}
    />
  );
};

export default ProductDetailActionsBuyer;
