/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SelectInputOption } from "src/sbxui";
import type { AccountAddressesView_user$key } from "src/types/__generated__/AccountAddressesView_user.graphql";
import type {
  AccountAddressesViewCreateAddressMutation,
  Country,
} from "src/types/__generated__/AccountAddressesViewCreateAddressMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import { UserContext } from "src/app/context/user";
import {
  HeadingLevel,
  SBButton,
  SBHeading,
  SBModal,
  SBSelectInput,
  SBTextInput,
} from "src/sbxui";

import AccountAddressCard from "./AccountAddressCard";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const ALLOWED_ADDRESS_COUNTRIES: SelectInputOption[] = [
  { id: "US", label: "United States" },
  { id: "CA", label: "Canada" },
  { id: "UK", label: "United Kingdom" },
];

type Props = Readonly<{
  queryKey: AccountAddressesView_user$key;
}>;

const AccountAddressesView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();
  const userContext = useContext(UserContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressName, setAddressName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [givenName, setGivenName] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [locality, setLocality] = useState("");
  const [region, setRegion] = useState("");
  const [country, setCountry] = useState<SelectInputOption>(
    ALLOWED_ADDRESS_COUNTRIES[0],
  );
  const [postalCode, setPostalCode] = useState("");

  const user = useFragment(
    graphql`
      fragment AccountAddressesView_user on User {
        addresses(
          first: 20
          orderBy: { isDefault: DESC }
          where: { type: SHIPPING }
        ) {
          __id
          edges {
            node {
              id
              ...AccountAddressCard_address
            }
          }
        }
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] =
    useMutation<AccountAddressesViewCreateAddressMutation>(graphql`
      mutation AccountAddressesViewCreateAddressMutation(
        $input: CreateAddressInput!
        $connections: [ID!]!
      ) {
        createAddress(createAddressInput: $input)
          @appendNode(
            connections: $connections
            edgeTypeName: "AddressesEdge"
          ) {
          ...AccountAddressCard_address
        }
      }
    `);

  const reset = () => {
    setAddressName("");
    setGivenName("");
    setFamilyName("");
    setAddress1("");
    setAddress2("");
    setRegion("");
    setLocality("");
    setPostalCode("");
    setCountry(ALLOWED_ADDRESS_COUNTRIES[0]);
  };

  const handleCreateAddress = () => {
    commit({
      onCompleted() {
        setIsModalOpen(false);
        reset();
      },
      onError(_error) {
        // not sure what to do here
      },
      variables: {
        connections: [user?.addresses?.__id ?? ""],
        input: {
          address1,
          address2: address2 === "" ? null : address2,
          country: country.id as Country,
          familyName,
          givenName,
          locality,
          name: addressName,
          postalCode,
          region,
          type: "SHIPPING",
          userId: userContext?.user?.userId ?? "",
        },
      },
    });
  };

  return (
    <>
      <div {...stylex.props(styles.header)}>
        <SBHeading level={HeadingLevel.H2} style={styles.heading}>
          {t("account.ADDRESSES")}
        </SBHeading>
        <SBButton
          disabled={isInFlight}
          title={t("account.addresses.createAddressButton")}
          onClick={() => setIsModalOpen(true)}
        />
      </div>

      {user.addresses?.edges
        ?.filter(({ node }) => node != null)
        .map(({ node }) => (
          <AccountAddressCard key={node.id} queryKey={node} />
        ))}
      <SBModal
        footer={
          <div {...stylex.props(styles.modalFooter)}>
            <SBButton
              disabled={isInFlight}
              loading={isInFlight}
              title={t("account.addresses.saveButton")}
              onClick={handleCreateAddress}
            />
          </div>
        }
        headerText={t("account.addresses.modalHeader")}
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
      >
        <div {...stylex.props(styles.modal)}>
          <form method="POST">
            <SBTextInput
              id="addressName"
              label=""
              placeholder={t("account.addresses.fields.addressName")}
              style={styles.formField}
              value={addressName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAddressName(event.currentTarget.value)
              }
            />
            <SBTextInput
              id="givenName"
              label=""
              placeholder={t("account.addresses.fields.givenName")}
              style={styles.formField}
              value={givenName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setGivenName(event.currentTarget.value)
              }
            />
            <SBTextInput
              id="familyName"
              label=""
              placeholder={t("account.addresses.fields.familyName")}
              style={styles.formField}
              value={familyName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setFamilyName(event.currentTarget.value)
              }
            />
            <SBSelectInput
              options={ALLOWED_ADDRESS_COUNTRIES}
              style={styles.formField}
              value={country}
              onChange={(selected: string) => {
                const selectedOption = ALLOWED_ADDRESS_COUNTRIES.find(
                  ({ label }) => label === selected,
                );

                if (selectedOption) {
                  setCountry(selectedOption);
                }
              }}
            />
            <SBTextInput
              id="address1"
              label=""
              placeholder={t("account.addresses.fields.address1")}
              style={styles.formField}
              value={address1}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAddress1(event.currentTarget.value)
              }
            />
            <SBTextInput
              id="address2"
              label=""
              placeholder={t("account.addresses.fields.address2")}
              style={styles.formField}
              value={address2}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setAddress2(event.currentTarget.value)
              }
            />
            <SBTextInput
              id="locality"
              label=""
              placeholder={t("account.addresses.fields.locality")}
              style={styles.formField}
              value={locality}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setLocality(event.currentTarget.value)
              }
            />
            <div {...stylex.props(styles.lastRow)}>
              <SBTextInput
                id="region"
                label=""
                placeholder={t("account.addresses.fields.region")}
                style={styles.formField}
                value={region}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setRegion(event.currentTarget.value)
                }
              />
              <SBTextInput
                id="postalCode"
                label=""
                placeholder={t("account.addresses.fields.postalCode")}
                style={styles.formField}
                value={postalCode}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPostalCode(event.currentTarget.value)
                }
              />
            </div>
          </form>
        </div>
      </SBModal>
    </>
  );
};

const styles = stylex.create({
  formField: { marginBottom: 8 },
  header: {
    alignItems: "center",
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(2, 1fr)",
      default: "repeat(4, 1fr)",
    },
    justifyContent: "space-between",
    marginBottom: 12,
  },
  heading: {
    fontSize: 24,
    gridColumn: "span 3 / span 3",
  },
  lastRow: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyContent: "space-between",
  },
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default AccountAddressesView;
