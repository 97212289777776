/**
 * @generated SignedSource<<77337a0c870c563b709bc5a6c37e32c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReportProductIssueModal_product$data = {
  readonly id: string;
  readonly " $fragmentType": "ReportProductIssueModal_product";
};
export type ReportProductIssueModal_product$key = {
  readonly " $data"?: ReportProductIssueModal_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReportProductIssueModal_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportProductIssueModal_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "e656b1a23f0734e7c2364b63c79ae475";

export default node;
