/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PaymentIntent } from "@stripe/stripe-js";
import type { InvoicePaymentMethod } from "src/types/__generated__/InvoiceView_invoice.graphql";

import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { config as GraphConfig } from "src/api/constants";
import { SHORTBOXED_INC } from "src/app/constants";
import useStripeTheme from "src/hooks/_hooks/useStripeTheme";
import {
  ButtonType,
  ButtonVariation,
  SBButton,
  SBErrorMessage,
} from "src/sbxui";

type Props = Readonly<{
  disabled: boolean;
  emailAddress: string;
  firstName: string | null | undefined;
  invoiceId: string;
  lastName: string | null | undefined;
  onComplete: (status: PaymentIntent.Status) => void;
  paymentMethod: InvoicePaymentMethod;
}>;

const InvoicePaymentForm = ({
  disabled,
  emailAddress,
  firstName,
  invoiceId,
  lastName,
  onComplete,
  paymentMethod,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null | undefined>(
    null,
  );

  const { dark, light } = useStripeTheme();

  const stripe = useStripe();
  const elements = useElements();

  const handleReady = () => {
    setIsStripeLoading(false);
  };

  const handleSubmitPayment = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const result = await stripe.confirmPayment({
      confirmParams: {
        // eslint-disable-next-line camelcase
        return_url:
          invoiceId == null
            ? `${GraphConfig.WEB_URL}/`
            : `${GraphConfig.WEB_URL}/invoice/${encodeURIComponent(invoiceId)}`,
      },
      elements,
      redirect: "if_required",
    });

    if (result.error) {
      setIsLoading(false);
      setErrorMessage(result.error.message);
    } else {
      onComplete(result.paymentIntent.status);
    }
  };

  useEffect(() => {
    const fetchUpdates = async () => {
      await elements?.fetchUpdates();
    };
    fetchUpdates();
  }, [elements, paymentMethod]);

  useEffect(() => {
    const colorTheme = window.matchMedia?.("(prefers-color-scheme: dark)");
    colorTheme.onchange = (event) => {
      if (event.matches) {
        elements?.update({ appearance: dark });
      } else {
        elements?.update({ appearance: light });
      }
    };
  }, [dark, elements, light]);

  const options = {
    business: {
      name: SHORTBOXED_INC,
    },
    defaultValues: {
      billingDetails: {
        email: emailAddress,
        name: t("name.format.full", {
          familyName: lastName,
          givenName: firstName,
        }),
      },
    },
  };

  return (
    <div {...stylex.props(styles.root)}>
      <SBErrorMessage message={errorMessage} style={styles.error} />
      <PaymentElement options={options} onReady={handleReady} />
      <div {...stylex.props(isStripeLoading && styles.stripeLoading)}>
        <SBButton
          block={true}
          disabled={
            disabled || stripe == null || elements == null || isStripeLoading
          }
          loading={isLoading}
          style={styles.button}
          title={t("invoice.buttons.complete-purchase")}
          type={ButtonType.Submit}
          variation={ButtonVariation.Emphasis}
          onClick={handleSubmitPayment}
        />
      </div>
    </div>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 24,
  },
  error: {
    marginBottom: 24,
  },
  root: {
    width: "100%",
  },
  stripeLoading: {
    display: "none",
  },
});

export default InvoicePaymentForm;
