/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountOffersQuery } from "src/types/__generated__/QueriesSellerAccountOffersQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { SellerAccountOffersQuery } from "src/app/router/Queries";

import SellerAccountOffersView from "./SellerAccountOffersView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesSellerAccountOffersQuery>;
}>;

const SellerAccountOffersContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(SellerAccountOffersQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <SellerAccountOffersView queryKey={user} />;
};

export default SellerAccountOffersContent;
