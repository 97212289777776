/**
 * @generated SignedSource<<34e2588f675be9ba56259ed83549674c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductPublicStatus = "FOR_SALE" | "HIDDEN" | "PENDING_AUCTION_CONSIGNMENT_REVIEW" | "PENDING_AUCTION_CONSIGNMENT_SHIPPING" | "PENDING_AUCTION_END" | "PENDING_AUCTION_START" | "PENDING_PURCHASE" | "PROCESSING_PURCHASE" | "SOLD" | "UNAVAILABLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActions_product$data = {
  readonly id: string;
  readonly isViewerOwner: boolean;
  readonly publicStatus: ProductPublicStatus | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsBuyer_product" | "ProductDetailActionsOwner_product" | "ProductDetailActionsSeller_product">;
  readonly " $fragmentType": "ProductDetailActions_product";
};
export type ProductDetailActions_product$key = {
  readonly " $data"?: ProductDetailActions_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActions_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActions_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerOwner",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsOwner_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsSeller_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsBuyer_product"
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "4533b53431085ebedfeacb40e7dcb2d4";

export default node;
