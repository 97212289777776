/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountLabelSizeQuery } from "src/types/__generated__/QueriesSellerAccountLabelSizeQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { SellerAccountLabelSizeQuery } from "src/app/router/Queries";

import SellerAccountLabelSizeView from "./SellerAccountLabelSizeView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesSellerAccountLabelSizeQuery>;
}>;

const SellerAccountLabelSizeContent = ({
  queryRef,
}: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(SellerAccountLabelSizeQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <SellerAccountLabelSizeView queryKey={user} />;
};

export default SellerAccountLabelSizeContent;
