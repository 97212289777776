/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountAddressesView_user$key } from "src/types/__generated__/AccountAddressesView_user.graphql";
import type { AccountAddressesViewCreateAddressMutation } from "src/types/__generated__/AccountAddressesViewCreateAddressMutation.graphql";
import type { Address } from "src/types/Address";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectionHandler, useFragment, useMutation } from "react-relay";

import AccountAddressCard from "src/app/components/account/AccountAddressCard";
import AddressForm from "src/app/components/address-form/AddressForm";
import { UserContext } from "src/app/context/user";
import { HeadingLevel, SBButton, SBHeading, SBModal } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
// const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountAddressesView_user$key;
}>;

const AccountAddressesView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const data = useFragment(
    graphql`
      fragment AccountAddressesView_user on User {
        __id
        addresses(
          first: 20
          orderBy: { isDefault: DESC }
          where: { type: SHIPPING }
        ) @connection(key: "AccountAddressesView_addresses") {
          __id
          edges {
            node {
              id
              ...AccountAddressCard_address
            }
          }
        }
      }
    `,
    queryKey,
  );

  const userConnectionId = data?.__id ?? "";
  const connectionId = data?.addresses?.__id;
  const addresses = data?.addresses?.edges ?? [];

  const [commit, isInFlight] =
    useMutation<AccountAddressesViewCreateAddressMutation>(graphql`
      mutation AccountAddressesViewCreateAddressMutation(
        $input: CreateAddressInput!
        $connections: [ID!]!
      ) {
        createAddress(createAddressInput: $input)
          @prependNode(
            connections: $connections
            edgeTypeName: "AddressesEdge"
          ) {
          id
          isDefault
          ...AccountAddressCard_address
        }
      }
    `);

  const userContext = useContext(UserContext);

  const handleClickCreate = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsModalOpen(true);
  };

  const handleSubmitCreate = (address: Address) => {
    if (isInFlight) {
      return;
    }
    commit({
      onCompleted() {
        setIsModalOpen(false);
      },
      onError(_error) {
        // not sure what to do here
      },
      updater(proxyStore, response) {
        const record = proxyStore.get(userConnectionId);
        if (record == null) {
          return;
        }

        const isNewAddressDefault = response?.createAddress.isDefault ?? false;

        if (isNewAddressDefault) {
          const addressBookConnection = ConnectionHandler.getConnection(
            record,
            "AccountAddressesView_addresses",
            {
              orderBy: {
                isDefault: "DESC",
              },
              where: {
                type: "SHIPPING",
              },
            },
          );
          if (addressBookConnection != null) {
            const sectionsEdge =
              addressBookConnection.getLinkedRecords("edges");
            let hasFoundMatch = false;
            for (const edge of sectionsEdge ?? []) {
              const nodeRecord = edge?.getLinkedRecord("node");
              const previousId = nodeRecord?.getValue("id");
              const isSameAddress = response?.createAddress.id === previousId;
              if (!isSameAddress) {
                const isPreviousDefault =
                  nodeRecord?.getValue("isDefault") ?? false;
                if (isPreviousDefault) {
                  nodeRecord?.setValue(false, "isDefault");
                  hasFoundMatch = true;
                }
              }
              if (hasFoundMatch) {
                break;
              }
            }
          }
        }
      },
      variables: {
        connections: [connectionId ?? ""],
        input: {
          address1: address.address1,
          address2: address.address2 === "" ? null : address.address2,
          country: address.country,
          familyName: address.familyName,
          givenName: address.givenName,
          isDefault: address.isDefault,
          locality: address.locality,
          name: address.addressName,
          postalCode: address.postalCode,
          region: address.region,
          type: address.type,
          userId: userContext?.user?.userId ?? "",
        },
      },
    });
  };

  return (
    <>
      <div {...stylex.props(styles.header)}>
        <SBHeading level={HeadingLevel.H2} style={styles.heading}>
          {t("account.nav.addresses")}
        </SBHeading>
        <SBButton
          disabled={isInFlight}
          title={t("account.addresses.buttons.create")}
          onClick={handleClickCreate}
        />
      </div>
      {addresses
        .filter(({ node }) => node != null)
        .map(({ node }) => (
          <AccountAddressCard
            key={node.id}
            queryKey={node}
            userConnectionId={userConnectionId}
          />
        ))}
      <SBModal
        headerText={t("account.addresses.modal-header")}
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
      >
        <div {...stylex.props(styles.modal)}>
          <AddressForm
            anchor="top start"
            initialDefault={addresses.length < 1}
            loading={isInFlight}
            onSubmit={handleSubmitCreate}
          />
        </div>
      </SBModal>
    </>
  );
};

const styles = stylex.create({
  checkbox: {
    marginTop: 4,
  },
  formField: {
    marginBottom: 8,
  },
  header: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      default: "repeat(4, 1fr)",
    },
    justifyContent: "space-between",
    marginBottom: 12,
  },
  heading: {
    fontSize: 24,
    gridColumn: "span 3 / span 3",
    marginBottom: 24,
  },
  lastRow: {
    display: "grid",
    gridGap: 10,
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyContent: "space-between",
  },
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
});

export default AccountAddressesView;
