/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionSearchHeading_auction$key } from "src/types/__generated__/AuctionSearchHeading_auction.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useSubscription } from "react-relay";
import { Link } from "react-router-dom";

import { AUCTION_PATH } from "src/app/router/Router";
import { useCountdownTimer } from "src/hooks";
import { HeadingLevel, SBAuctionStatusTag, SBHeading, SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const POLL_TIME_MS = 500;

const AuctionSearchHeadingExpirySubscription = graphql`
  subscription AuctionSearchHeadingExpirySubscription($input: AuctionIdInput!) {
    auctionExpirySubscription(auctionExpirySubscriptionInput: $input) {
      ...AuctionSearchHeading_auction
    }
  }
`;

type Props = Readonly<{
  queryKey: AuctionSearchHeading_auction$key;
}>;

export const AuctionSearchHeading = ({ queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment AuctionSearchHeading_auction on Auction {
        id
        biddingStartsAt
        biddingClosesAt
        lastLotClosesAt
        type
        title
      }
    `,
    queryKey,
  );

  const {
    id: auctionId,
    biddingStartsAt,
    lastLotClosesAt,
    biddingClosesAt,
    type: auctionType,
    title: auctionTitle,
  } = data;

  const expiryConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionSearchHeadingExpirySubscription,
      variables: {
        input: {
          auctionId,
        },
      },
    }),
    [auctionId],
  );

  useSubscription(expiryConfig);

  const startingTimestamp =
    biddingStartsAt == null ? Date.now() : Number.parseInt(biddingStartsAt, 10);
  const closingTimestamp =
    lastLotClosesAt == null ? Date.now() : Number.parseInt(lastLotClosesAt, 10);
  const firstItemClosesAtTimestamp =
    biddingClosesAt == null ? Date.now() : Number.parseInt(biddingClosesAt, 10);

  const { hasExpired } = useCountdownTimer(closingTimestamp, {
    expired: t("auctions.tile.status.closed"),
    short: true,
  });

  const isPreview =
    biddingStartsAt == null
      ? true
      : Date.now() - Number.parseInt(biddingStartsAt, 10) <= 0;

  const shortDateFormatter = useMemo(
    () =>
      !isPreview && !hasExpired
        ? new Intl.DateTimeFormat(i18n.languages, {
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            month: "numeric",
          })
        : new Intl.DateTimeFormat(i18n.languages, {
            day: "numeric",
            month: "numeric",
          }),
    [hasExpired, i18n.languages, isPreview],
  );

  let subtitle = isPreview ? (
    <div {...stylex.props(styles.subtitle)}>
      {t("auctions.tile.subtitle.preview", {
        timestamp: shortDateFormatter.format(startingTimestamp),
      })}
    </div>
  ) : (
    <>
      <div {...stylex.props(styles.subtitle)}>
        {t("auctions.tile.subtitle.first-lots", {
          timestamp: shortDateFormatter.format(firstItemClosesAtTimestamp),
        })}
      </div>
      <div {...stylex.props(styles.subtitle)}>
        {t("auctions.tile.subtitle.open-lots", {
          timestamp: shortDateFormatter.format(closingTimestamp),
        })}
      </div>
    </>
  );
  if (!isPreview && hasExpired) {
    subtitle = (
      <div {...stylex.props(styles.subtitle)}>
        {t("auctions.tile.subtitle.closed", {
          timestamp: shortDateFormatter.format(closingTimestamp),
        })}
      </div>
    );
  }

  const destination = AUCTION_PATH.replace(":id", auctionId);

  const shipsFrom = (
    <>
      <div>
        <SBIcon
          containerStyle={styles.iconContainer}
          icon="local_shipping"
          size={20}
          style={styles.icon}
        />
      </div>
      {auctionType === "COMMUNITY" ? (
        <div>{t("auctions.tile.ships-from.community")}</div>
      ) : (
        <div>{t("auctions.tile.ships-from.curated")}</div>
      )}
    </>
  );

  return (
    <li {...stylex.props(styles.item)}>
      <Link
        {...stylex.props(auto, styles.link)}
        rel="noopener noreferrer"
        to={destination}
      >
        <div {...stylex.props(styles.titleBanner)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {auctionTitle}
          </SBHeading>
          <div {...stylex.props(styles.statusTag)}>
            <SBAuctionStatusTag
              biddingStartsAt={biddingStartsAt}
              lastLotClosesAt={lastLotClosesAt}
            />
          </div>
        </div>
        <div {...stylex.props(styles.subtitleBanner)}>
          <div {...stylex.props(styles.shipsFrom)}>{shipsFrom}</div>
        </div>
        <div {...stylex.props(styles.times)}>{subtitle}</div>
      </Link>
    </li>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 48,
  },
  headingLeft: {
    fontSize: 96,
  },
  headingTop: {
    fontSize: 48,
  },
  icon: {
    color: colors.color,
  },
  iconContainer: {
    marginRight: 8,
  },
  item: {
    listStyleType: "none",
    margin: 0,
    marginBottom: 16,
    padding: 0,
  },
  link: {
    color: colors.color,
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  shipsFrom: {
    display: "flex",
  },
  status: {
    margin: 0,
    marginLeft: 8,
  },
  statusTag: {
    alignItems: "center",
    alignSelf: "center",
    marginLeft: 8,
  },
  subtitle: {
    fontSize: 14,
  },
  subtitleBanner: {
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    marginTop: 8,
    width: "100%",
  },
  textLeft: {
    padding: 16,
    width: "50%",
  },
  textTop: {
    padding: 16,
  },
  times: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 16,
    marginTop: 16,
  },
  titleBanner: {
    display: "flex",
  },
});

export default AuctionSearchHeading;
