/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountPersonalInformationQuery } from "src/types/__generated__/QueriesAccountPersonalInformationQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AccountPersonalInformationQuery } from "src/app/router/Queries";

import AccountPersonalInformationView from "./AccountPersonalInformationView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountPersonalInformationQuery>;
}>;

const AccountPersonalInformationContent = ({
  queryRef,
}: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AccountPersonalInformationQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <AccountPersonalInformationView queryKey={user} />;
};

export default AccountPersonalInformationContent;
