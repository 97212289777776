/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountNeedToShipQuery } from "src/types/__generated__/QueriesSellerAccountNeedToShipQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { SellerAccountNeedToShipQuery } from "src/app/router/Queries";

import SellerAccountNeedToShipView from "./SellerAccountNeedToShipView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesSellerAccountNeedToShipQuery>;
}>;

const SellerAccountNeedToShipContent = ({
  queryRef,
}: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(SellerAccountNeedToShipQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <SellerAccountNeedToShipView queryKey={user} />;
};

export default SellerAccountNeedToShipContent;
