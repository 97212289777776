/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionType } from "src/types/__generated__/AuctionSearchView_auctionSearch.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AUCTION_PATH } from "src/app/router/Router";
import { useCountdownTimer } from "src/hooks";
import { HeadingLevel, SBAuctionStatusTag, SBHeading } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  auctionId: string;
  auctionTitle: string;
  auctionType: AuctionType;
  biddingStartsAt: string;
  lastLotClosesAt: string | null | undefined;
}>;

export const AuctionSearchHeading = ({
  auctionId,
  auctionTitle,
  auctionType,
  biddingStartsAt,
  lastLotClosesAt,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const startingTimestamp =
    biddingStartsAt == null ? Date.now() : Number.parseInt(biddingStartsAt, 10);
  const closingTimestamp =
    lastLotClosesAt == null ? Date.now() : Number.parseInt(lastLotClosesAt, 10);

  const { hasExpired } = useCountdownTimer(closingTimestamp, {
    expired: t("auctions.tile.status.closed"),
    short: true,
  });

  const isPreview =
    biddingStartsAt == null
      ? true
      : Date.now() - Number.parseInt(biddingStartsAt, 10) <= 0;

  const shortDateFormatter = useMemo(
    () =>
      !isPreview && !hasExpired
        ? new Intl.DateTimeFormat(i18n.languages, {
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            month: "numeric",
          })
        : new Intl.DateTimeFormat(i18n.languages, {
            day: "numeric",
            month: "numeric",
          }),
    [hasExpired, i18n.languages, isPreview],
  );

  let subtitle = isPreview
    ? t("auctions.tile.subtitle.preview", {
        timestamp: shortDateFormatter.format(startingTimestamp),
      })
    : t("auctions.tile.subtitle.open-lots", {
        timestamp: shortDateFormatter.format(closingTimestamp),
      });
  if (!isPreview && hasExpired) {
    subtitle = t("auctions.tile.subtitle.closed", {
      timestamp: shortDateFormatter.format(closingTimestamp),
    });
  }

  const destination = AUCTION_PATH.replace(":id", auctionId);

  const shipsFrom =
    auctionType === "COMMUNITY"
      ? t("auctions.tile.ships-from.community")
      : t("auctions.tile.ships-from.curated");

  return (
    <li {...stylex.props(styles.item)}>
      <Link
        {...stylex.props(auto, styles.link)}
        rel="noopener noreferrer"
        target="_blank"
        to={destination}
      >
        <div {...stylex.props(styles.statusTag)}>
          <SBAuctionStatusTag
            biddingStartsAt={biddingStartsAt}
            lastLotClosesAt={lastLotClosesAt}
          />
          <p {...stylex.props(auto, styles.status)}>{subtitle}</p>
        </div>
        <SBHeading level={HeadingLevel.H2} style={styles.heading}>
          {auctionTitle}
        </SBHeading>
        <SBHeading level={HeadingLevel.H3} style={styles.shipsFrom}>
          {shipsFrom}
        </SBHeading>
      </Link>
    </li>
  );
};

const styles = stylex.create({
  heading: {
    fontSize: 48,
  },
  headingLeft: {
    fontSize: 96,
  },
  headingTop: {
    fontSize: 48,
  },
  item: {
    listStyleType: "none",
    margin: 0,
    marginBottom: 16,
    padding: 0,
  },
  link: {
    color: colors.color,
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  shipsFrom: {
    margin: 0,
    marginTop: 8,
  },
  status: {
    margin: 0,
    marginLeft: 8,
    textTransform: "uppercase",
  },
  statusTag: {
    alignItems: "center",
    display: "flex",
    marginTop: 16,
  },
  textLeft: {
    padding: 16,
    width: "50%",
  },
  textTop: {
    padding: 16,
  },
});

export default AuctionSearchHeading;
