/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { VerifyBidderContentQuery } from "src/types/__generated__/VerifyBidderContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import VerifyBidderView from "./VerifyBidderView";

type Props = Readonly<{
  auctionLotId: string | null | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const VerifyBidderContent = ({
  setOpen,
  auctionLotId,
}: Props): React.ReactNode | null => {
  const data = useLazyLoadQuery<VerifyBidderContentQuery>(
    graphql`
      query VerifyBidderContentQuery {
        viewer {
          user {
            ...VerifyBidderView_user
          }
        }
      }
    `,
    {},
  );

  const user = data?.viewer.user;
  if (user == null) {
    return null;
  }

  return (
    <VerifyBidderView
      auctionLotId={auctionLotId}
      queryKey={user}
      setOpen={setOpen}
    />
  );
};

export default VerifyBidderContent;
