/**
 * @generated SignedSource<<39508d58309763793ff210469b88ced1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountAddressesView_user$data = {
  readonly addresses: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"AccountAddressCard_address">;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "AccountAddressesView_user";
};
export type AccountAddressesView_user$key = {
  readonly " $data"?: AccountAddressesView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountAddressesView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountAddressesView_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 20
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "isDefault": "DESC"
          }
        },
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "type": "SHIPPING"
          }
        }
      ],
      "concreteType": "AddressesConnection",
      "kind": "LinkedField",
      "name": "addresses",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AddressesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Address",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AccountAddressCard_address"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": "addresses(first:20,orderBy:{\"isDefault\":\"DESC\"},where:{\"type\":\"SHIPPING\"})"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b23634e46a7a82bb3de898354b2892a9";

export default node;
