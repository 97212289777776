/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AppleSSOButtonAppleLoginMutation$data } from "src/types/__generated__/AppleSSOButtonAppleLoginMutation.graphql";
import type { FacebookSSOButtonFacebookLoginMutation$data } from "src/types/__generated__/FacebookSSOButtonFacebookLoginMutation.graphql";
import type { GoogleSSOButtonGoogleLoginMutation$data } from "src/types/__generated__/GoogleSSOButtonGoogleLoginMutation.graphql";
import type { useLoginMutation$data } from "src/types/__generated__/useLoginMutation.graphql";

import * as React from "react";
import { useCallback, useEffect, useMemo, useReducer } from "react";

import { StorageKey } from "src/app/constants";

export interface User {
  accessToken: string;
  permissions: readonly string[];
  sellerStatus: string | null;
  userId: string;
}

type LoginResponse = useLoginMutation$data["login"];
type GoogleSSOLoginResponse =
  GoogleSSOButtonGoogleLoginMutation$data["googleLogin"];
type AppleSSOLoginResponse =
  AppleSSOButtonAppleLoginMutation$data["appleLogin"];
type FacebookSSOLoginResponse =
  FacebookSSOButtonFacebookLoginMutation$data["facebookLogin"];

export type LoginResponseType =
  | LoginResponse
  | GoogleSSOLoginResponse
  | AppleSSOLoginResponse
  | FacebookSSOLoginResponse;

interface IUserContext {
  isLoading: boolean;
  logIn: (loginResponse: LoginResponseType) => void;
  logOut: () => void;
  user: User | null;
}

const DEFAULT_STATE = {
  isLoading: true,
  user: null,
};

const INITIAL_CONTEXT_STATE: IUserContext = {
  ...DEFAULT_STATE,
  logIn: async () => {
    // Do nothing
  },
  logOut: async () => {
    // Do nothing
  },
};

interface State {
  isLoading: boolean;
  user: User | null;
}

type Action =
  | { type: "restore"; user: User | null }
  | { type: "logIn"; user: User | null }
  | { type: "logOut"; user: null };

const reducer = (prevState: State, action: Action) => {
  switch (action.type) {
    case "restore":
      return {
        ...prevState,
        isLoading: false,
        user: action.user,
      };
    case "logIn":
      return {
        ...prevState,
        user: action.user,
      };
    case "logOut":
      return {
        ...prevState,
        user: null,
      };
    default:
      return DEFAULT_STATE;
  }
};

export const UserContext = React.createContext<IUserContext>(
  INITIAL_CONTEXT_STATE,
);

type Props = React.PropsWithChildren;

export const UserProvider = ({ children }: Props): React.ReactNode => {
  const [{ isLoading, user }, dispatch] = useReducer(reducer, DEFAULT_STATE);

  useEffect(() => {
    const getUser = () => {
      try {
        const response = localStorage.getItem(StorageKey.User);
        const userData = response == null ? null : response;

        dispatch({
          type: "restore",
          user: userData == null ? null : JSON.parse(userData),
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    };
    getUser();
  }, []);

  const logIn = useCallback((loginResponse: LoginResponse) => {
    const obj = Object.freeze({
      accessToken: loginResponse?.accessToken,
      permissions: loginResponse?.permissions,
      refreshToken: loginResponse?.refreshToken,
      sellerStatus: loginResponse?.user?.sellerStatus ?? null,
      userId: loginResponse?.user?.id ?? "",
    });
    try {
      localStorage.setItem(StorageKey.User, JSON.stringify(obj));
      dispatch({ type: "logIn", user: obj });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  const logOut = useCallback(() => {
    try {
      localStorage.removeItem(StorageKey.User);
      dispatch({
        type: "logOut",
        user: null,
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  const currentUser = useMemo(
    () => ({ isLoading, logIn, logOut, user }),
    [isLoading, logIn, logOut, user],
  );

  return (
    <UserContext.Provider value={currentUser}>{children}</UserContext.Provider>
  );
};
