/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionSearchItem_auctionLot$key } from "src/types/__generated__/AuctionSearchItem_auctionLot.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useSubscription } from "react-relay";

import { SBAuctionLotTile } from "src/sbxui";

const POLL_TIME_MS = 500;

const AuctionSearchItemBidSubscription = graphql`
  subscription AuctionSearchItemBidSubscription(
    $input: BidAcceptedSubscriptionInput!
  ) {
    bidAcceptedSubscription(bidAcceptedSubscriptionInput: $input) {
      ...AuctionSearchItem_auctionLot
    }
  }
`;

const AuctionSearchItemExpirySubscription = graphql`
  subscription AuctionSearchItemExpirySubscription($input: AuctionLotIdInput!) {
    auctionLotExpirySubscription(auctionLotExpirySubscription: $input) {
      ...AuctionSearchItem_auctionLot
    }
  }
`;

type Props = Readonly<{
  queryKey: AuctionSearchItem_auctionLot$key;
}>;

const AuctionSearchItem = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment AuctionSearchItem_auctionLot on AuctionLot {
        id
        shortDescription
        isClosed
        closesAt
        currentBidAmount
        currentBidCurrency
        isViewerBidding
        isViewerHighBidder
        isViewerOwner
        isViewerWatching
        startingAmount
        auction {
          biddingStartsAt
          type
        }
        product {
          comicDetails {
            title
            number
            grade
            gradingAuthority
          }
          fmvScore {
            maxValue
            minValue
            currency
          }
          images {
            edges {
              node {
                url(quality: 100, webp: true, width: 1000)
              }
            }
          }
        }
      }
    `,
    queryKey,
  );

  const auctionLotId = data.id;

  const currentBidAmount = data.currentBidAmount;
  const currentBidCurrency = data.currentBidCurrency;

  const title =
    data.product?.comicDetails?.title ?? t("product.book.title-missing");
  const number =
    data.product?.comicDetails?.number ?? t("product.book.noNumber");
  const grade = data.product?.comicDetails?.grade;
  const gradingAuthority = data.product?.comicDetails?.gradingAuthority;

  const imageUrl = (data.product?.images?.edges ?? [])[0]?.node?.url;

  const bidConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionSearchItemBidSubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  const expiryConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionSearchItemExpirySubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  useSubscription(bidConfig);
  useSubscription(expiryConfig);

  return (
    <SBAuctionLotTile
      auctionLotId={auctionLotId}
      currentBidAmount={currentBidAmount}
      currentBidCurrency={currentBidCurrency}
      grade={grade}
      gradingAuthority={gradingAuthority}
      imageUrl={imageUrl}
      number={number}
      title={title}
    />
  );
};

export default AuctionSearchItem;
