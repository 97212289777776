/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PortfolioFmv_portfolioFmv$key } from "src/types/__generated__/PortfolioFmv_portfolioFmv.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

type Props = Readonly<{
  queryKey: PortfolioFmv_portfolioFmv$key;
}>;

const PortfolioFmv = ({ queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const numberFormatter = useMemo(
    () => new Intl.NumberFormat(i18n.languages),
    [i18n.languages],
  );

  const data = useFragment(
    graphql`
      fragment PortfolioFmv_portfolioFmv on PortfolioFmv {
        portfolioCount
        portfolioFmv
        portfolioFmvCount
      }
    `,
    queryKey,
  );

  const value = data?.portfolioFmv ?? 0;
  const count = data?.portfolioFmvCount ?? 0;
  const total = data?.portfolioCount ?? 0;

  const countValue = numberFormatter.format(count);
  const totalValue = numberFormatter.format(total);

  return (
    <div {...stylex.props(auto, styles.container)}>
      <div>
        <div {...stylex.props(auto, styles.fmv)}>
          <SBParagraph style={styles.total}>
            {t("portfolio.header.total")}
          </SBParagraph>
          <div {...stylex.props(auto, styles.line)} />
        </div>
        <SBParagraph style={styles.count}>
          {total === 0
            ? t("portfolio.header.count-empty")
            : t("portfolio.header.count", {
                books: countValue,
                total: totalValue,
              })}
        </SBParagraph>
      </div>
      <SBHeading level={HeadingLevel.H1} style={styles.value}>
        {formatMoney(value, "USD", i18n.language)}
      </SBHeading>
    </div>
  );
};

const styles = stylex.create({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    paddingLeft: 12,
  },
  count: {
    paddingRight: 12,
  },
  fmv: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    minWidth: 20,
  },
  line: {
    backgroundColor: colors.colorEmphasis,
    flexGrow: 1,
    height: 1,
    marginLeft: 8,
  },
  total: {
    marginBottom: 0,
  },
  value: {
    marginLeft: 12,
    textAlign: "right",
  },
});

export default PortfolioFmv;
