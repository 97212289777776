/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  disabled?: boolean;
  errorMessage?: string | false | null;
  id: string;
  inputStyle?: StyleXStyles;
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  showLabel?: boolean;
  small?: boolean;
  style?: StyleXStyles;
}>;

export const SBFileInput = React.memo(
  ({
    disabled = false,
    errorMessage,
    id,
    inputStyle,
    label,
    onChange,
    placeholder,
    readOnly = false,
    showLabel = false,
    small = false,
    style,
  }: Props): React.ReactNode => {
    return (
      <div {...stylex.props(styles.root, style)}>
        <label {...stylex.props(auto)} aria-label={label} htmlFor={id}>
          <div
            {...stylex.props(
              styles.labelText,
              showLabel ? styles.labelShow : null,
            )}
          >
            {label}
          </div>
          <div
            {...stylex.props(
              styles.container,
              small && styles.containerSmall,
              disabled && styles.containerDisabled,
            )}
          >
            <input
              {...stylex.props(
                styles.input,
                small && styles.inputSmall,
                inputStyle,
              )}
              disabled={disabled}
              id={id}
              name={id}
              placeholder={placeholder}
              readOnly={readOnly}
              type="file"
              onChange={onChange}
            />
          </div>
        </label>
        {errorMessage ? (
          <SBParagraph style={styles.errorMessage}>{errorMessage}</SBParagraph>
        ) : null}
      </div>
    );
  },
);

const styles = stylex.create({
  container: {
    alignItems: "center",
    backgroundColor: colors.inputTextBackgroundColor,
    borderColor: colors.buttonBackgroundColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    height: 48,
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
  },
  containerDisabled: {
    opacity: 0.4,
  },
  containerSmall: {
    borderRadius: 16,
    height: 32,
  },
  containerWithValue: {
    borderColor: colors.inputTextColor,
  },
  errorMessage: {
    color: colors.colorEmphasis,
    fontSize: 14,
    margin: 0,
    marginTop: 4,
  },
  input: {
    cursor: "pointer",
    lineHeight: "42px",
    marginLeft: -1,
    outline: {
      ":focus-visible": colors.outline,
    },
    whiteSpace: "nowrap",
  },
  inputSmall: {
    borderRadius: 16,
    height: 32,
    textAlign: "center",
  },
  labelShow: {
    fontWeight: 600,
    marginBottom: 8,
    textIndent: 0,
  },
  labelText: {
    textIndent: -99999,
    whiteSpace: "nowrap",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
});
