/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesOrderDetailQuery } from "src/types/__generated__/QueriesOrderDetailQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense } from "react";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import OrderDetailContent from "./OrderDetailContent";

type Props = Readonly<{
  onClose: () => void;
  queryRef: PreloadedQuery<QueriesOrderDetailQuery> | null;
}>;

const OrderDetail = ({ onClose, queryRef }: Props): React.ReactNode | null => {
  if (queryRef == null) {
    return null;
  }

  return (
    <div {...stylex.props(styles.root)}>
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <OrderDetailContent queryRef={queryRef} onClose={onClose} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

const styles = stylex.create({
  root: {
    minHeight: "50vh",
  },
});

export default OrderDetail;
