/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAuctionQuery } from "src/types/__generated__/QueriesAuctionQuery.graphql";

import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePreloadedQuery } from "react-relay";

import { AuctionQuery } from "src/app/router/Queries";
import { SBErrorPage } from "src/sbxui";

import AuctionView from "./AuctionView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAuctionQuery>;
}>;

const AuctionContent = ({ queryRef }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const queryKey = usePreloadedQuery(AuctionQuery, queryRef);

  if (queryKey.node == null) {
    return (
      <SBErrorPage
        description={t("notFound.description")}
        errorCode={404}
        title={t("notFound.pageTitle")}
      />
    );
  }

  return queryKey.node && <AuctionView queryKey={queryKey.node} />;
};

export default AuctionContent;
