/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { HeadingLevel, SBHeading } from "./SBHeading";
import { SBIcon } from "./SBIcon";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<
  React.PropsWithChildren<{
    afterLeave?: () => void;
    footer?: React.ReactNode;
    headerText?: string;
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }>
>;

export const SBModal = ({
  afterLeave,
  children,
  setOpen,
  isOpen,
  footer,
  headerText,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const handleCloseClick = (_event: React.SyntheticEvent) => {
    setOpen(false);
  };

  return (
    <Transition
      afterLeave={afterLeave}
      appear={true}
      as={Fragment}
      show={isOpen}
    >
      <Dialog
        {...stylex.props(styles.dialog)}
        as="div"
        static={true}
        onClose={setOpen}
      >
        <TransitionChild
          as={Fragment}
          enter={stylex.props(styles.backdropEnter).className}
          enterFrom={stylex.props(styles.backdropEnterFrom).className}
          enterTo={stylex.props(styles.backdropEnterTo).className}
          leave={stylex.props(styles.backdropLeave).className}
          leaveFrom={stylex.props(styles.backdropLeaveFrom).className}
          leaveTo={stylex.props(styles.backdropLeaveTo).className}
        >
          <div {...stylex.props(auto, styles.backdrop)} aria-hidden={true} />
        </TransitionChild>
        <div {...stylex.props(styles.container)}>
          <TransitionChild
            as={Fragment}
            enter={stylex.props(styles.containerEnter).className}
            enterFrom={stylex.props(styles.containerEnterFrom).className}
            enterTo={stylex.props(styles.containerEnterTo).className}
            leave={stylex.props(styles.containerLeave).className}
            leaveFrom={stylex.props(styles.containerLeaveFrom).className}
            leaveTo={stylex.props(styles.containerLeaveTo).className}
          >
            <DialogPanel {...stylex.props(auto, styles.panel)}>
              <div {...stylex.props(auto, styles.header)}>
                <div {...stylex.props(auto, styles.close)} />

                {headerText ? (
                  <SBHeading level={HeadingLevel.H1} style={styles.headerText}>
                    {headerText}
                  </SBHeading>
                ) : null}
                <button
                  {...stylex.props(auto, styles.close)}
                  aria-label={t("modal.close-label")}
                  type="button"
                  onClick={handleCloseClick}
                >
                  <SBIcon
                    aria-hidden={true}
                    fill={false}
                    icon="close"
                    style={styles.icon}
                  />
                </button>
              </div>
              {children}
              {footer ? (
                <div {...stylex.props(auto, styles.footer)}>{footer}</div>
              ) : null}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

const styles = stylex.create({
  backdrop: {
    backgroundColor: colors.modalBackdropBackgroundColor,
    inset: 0,
    opacity: 0.75,
    position: "fixed",
  },
  backdropEnter: {
    transitionDuration: "300ms",
    transitionTimingFunction: "ease-out",
  },
  backdropEnterFrom: {
    opacity: 0,
  },
  backdropEnterTo: {
    opacity: 0.75,
  },
  backdropLeave: {
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-in",
  },
  backdropLeaveFrom: {
    opacity: 0.75,
  },
  backdropLeaveTo: {
    opacity: 0,
  },
  close: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    marginRight: {
      [MOBILE]: 8,
      default: 0,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  container: {
    alignItems: "center",
    display: "flex",
    inset: 0,
    justifyContent: "center",
    overflowY: "auto",
    position: "fixed",
    width: "100vw",
  },
  containerEnter: {
    transitionDelay: "200ms",
    transitionDuration: "300ms",
    transitionProperty: "opacity, transform",
    transitionTimingFunction: "ease-out",
  },
  containerEnterFrom: {
    opacity: 0,
    transform: "scale(0.95)",
  },
  containerEnterTo: {
    opacity: 1,
    transform: "scale(1)",
  },
  containerLeave: {
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-in",
  },
  containerLeaveFrom: {
    opacity: 1,
    transform: "scale(1)",
  },
  containerLeaveTo: {
    opacity: 0,
    transform: "scale(0.95)",
  },
  dialog: {
    position: "relative",
    zIndex: 10,
  },
  footer: {
    borderTopColor: colors.modalBorderColor,
    borderTopStyle: "solid",
    borderTopWidth: 1,
    paddingBlock: 12,
    paddingInline: 16,
  },
  header: {
    alignItems: "center",
    borderBottomColor: colors.modalBorderColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  headerText: {
    fontSize: 24,
    textAlign: "center",
  },
  icon: {
    color: colors.color,
  },
  modal: {
    backgroundColor: colors.modalBackgroundColor,
    borderColor: colors.modalBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: colors.topNavigationMenuShadow,
    boxSizing: "border-box",
    maxHeight: 800,
    minHeight: 128,
    overflowY: "auto",
    width: 500,
  },
  modalWrap: {
    display: "flex",
    inset: 0,
    justifyContent: "center",
    padding: 20,
    position: "fixed",
    zIndex: 10,
  },
  panel: {
    backgroundColor: colors.modalBackgroundColor,
    borderColor: colors.modalBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: colors.topNavigationMenuShadow,
    boxSizing: "border-box",
    marginInline: {
      [MOBILE]: 8,
      [TABLET]: 16,
      default: "auto",
    },
    maxHeight: "90vh",
    minHeight: 128,
    overflowY: "auto",
    width: {
      [MOBILE]: "100%",
      default: 500,
    },
  },
});
