/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProfileLinkContentQuery } from "src/types/__generated__/ProfileLinkContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import ProfileLinkView from "./ProfileLinkView";

const ProfileLinkContent = (): React.ReactNode | null => {
  const data = useLazyLoadQuery<ProfileLinkContentQuery>(
    graphql`
      query ProfileLinkContentQuery {
        viewer {
          user {
            ...ProfileLinkView_user
          }
        }
      }
    `,
    {},
  );

  const user = data?.viewer.user;
  if (user == null) {
    return null;
  }

  return <ProfileLinkView queryKey={user} />;
};

export default ProfileLinkContent;
