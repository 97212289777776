/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountOffersQuery } from "src/types/__generated__/QueriesAccountOffersQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import AccountPage from "../AccountPage";
import AccountOffersContent from "./AccountOffersContent";

const AccountOffers = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef = useLoaderData() as PreloadedQuery<QueriesAccountOffersQuery>;

  return (
    <AccountPage docTitle={t("account.offers.title")}>
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <AccountOffersContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </AccountPage>
  );
};

export default AccountOffers;
