/**
 * @generated SignedSource<<906c87a71e0701871f7714fe5ae067d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeclineOfferContentQuery$variables = {
  id: string;
};
export type DeclineOfferContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DeclineOfferView_offer">;
};
export type DeclineOfferContentQuery = {
  response: DeclineOfferContentQuery$data;
  variables: DeclineOfferContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeclineOfferContentQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "DeclineOfferView_offer"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeclineOfferContentQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              }
            ],
            "type": "Offer",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af0c1b4f7784c8e85956e18188a7bf9b",
    "id": null,
    "metadata": {},
    "name": "DeclineOfferContentQuery",
    "operationKind": "query",
    "text": "query DeclineOfferContentQuery(\n  $id: ID!\n) {\n  ...DeclineOfferView_offer\n}\n\nfragment DeclineOfferView_offer on Query {\n  node(id: $id) {\n    __typename\n    ... on Offer {\n      id\n      price\n      currency\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3897eca3e6467fe61820f6a3a37f2e2d";

export default node;
