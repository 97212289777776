/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountNeedToShipQuery } from "src/types/__generated__/QueriesSellerAccountNeedToShipQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import SellerAccountPage from "../SellerAccountPage";
import SellerAccountNeedToShipContent from "./SellerAccountNeedToShipContent";

const SellerAccountNeedToShip = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesSellerAccountNeedToShipQuery>;

  return (
    <SellerAccountPage
      docTitle={t("seller-account.need-to-ship.title")}
      innerHeading={t("seller-account.nav.need-to-ship")}
    >
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <SellerAccountNeedToShipContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </SellerAccountPage>
  );
};

export default SellerAccountNeedToShip;
