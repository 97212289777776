/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { config as GraphConfig } from "src/api/constants";
import { StorageKey } from "src/app/constants";

export const refreshAnonymousToken = async () => {
  const anonymousKey = StorageKey.Anonymous;

  const result = await fetch(
    `${GraphConfig.API_PROTOCOL}://${GraphConfig.API_HOST}/auth/login/anonymous`,
    {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      method: "POST",
    },
  );
  const json = await result.json();

  if (json.error != null) {
    throw new Error(json.error);
  }

  const anonymousToken = json.accessToken ?? "";

  if (anonymousToken === "") {
    throw new Error("Empty anonymous token");
  }

  localStorage.setItem(anonymousKey, JSON.stringify({ anonymousToken }));
};
