/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountPasswordView_user$key } from "src/types/__generated__/AccountPasswordView_user.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";

import AccountPasswordChangeForm from "./AccountPasswordChangeForm";

type Props = Readonly<{
  queryKey: AccountPasswordView_user$key;
}>;

const AccountPasswordView = ({ queryKey }: Props): React.ReactNode => {
  const _data = useFragment(
    graphql`
      fragment AccountPasswordView_user on User {
        id
      }
    `,
    queryKey,
  );

  return <AccountPasswordChangeForm />;
};

export default AccountPasswordView;
