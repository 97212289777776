/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SearchResultCard_typesenseSearch$key } from "src/types/__generated__/SearchResultCard_typesenseSearch.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useCallback, useContext } from "react";
import { useFragment } from "react-relay";

import { VerticalSearchContext } from "src/app/context/vertical-search";
import {
  useFavorite,
  useUnfavorite,
  useUnwatchAuctionLot,
  useWatchAuctionLot,
} from "src/hooks";
import { SBProductTile } from "src/sbxui";

import favoriteUpdater from "./favoriteUpdater";
import watchUpdater from "./watchUpdater";

type Props = Readonly<{
  connectionId: string;
  queryKey: SearchResultCard_typesenseSearch$key;
}>;

const SearchResultCard = ({
  connectionId,
  queryKey,
}: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment SearchResultCard_typesenseSearch on TypesenseSearch {
        id
        createdAt
        title
        images {
          edges {
            node {
              url(quality: 85, webp: true, width: 500)
            }
          }
        }
        number
        grade
        gradingAuthority
        productId
        auctionLotId
        fmvRank
        fmvMinValue
        fmvMaxValue
        currentBidAmount
        lotClosingTime
        isAuctionLotClosed
        price
        currency
        salesChannel
        hasViewerFavorited
        isViewerWatching
      }
    `,
    queryKey,
  );

  const [commitFavorite, isInFlightFavorite] = useFavorite();
  const [commitUnfavorite, isInFlightUnfavorite] = useUnfavorite();

  const [commitWatch, isInFlightWatch] = useWatchAuctionLot();
  const [commitUnwatch, isInFlightUnwatch] = useUnwatchAuctionLot();

  const {
    auctionLotId,
    currency,
    currentBidAmount,
    fmvMaxValue,
    fmvMinValue,
    fmvRank,
    grade,
    gradingAuthority,
    hasViewerFavorited,
    images,
    isAuctionLotClosed,
    isViewerWatching,
    lotClosingTime,
    number,
    price,
    productId,
    salesChannel,
    title,
  } = data;

  const imageUrl = images?.edges?.[0]?.node?.url ?? "";

  const {
    categoryRollups,
    gradeMax,
    gradeMin,
    gradingAuthorities,
    priceMax,
    priceMin,
    salesChannel: salesChannelContext,
    searchTerm,
    shopUrl,
    sort,
    specialCopies,
    vertical,
    yearMax,
    yearMin,
  } = useContext(VerticalSearchContext);

  const handleClickFavorite = useCallback(() => {
    if (isInFlightFavorite || isInFlightUnfavorite) {
      return;
    }
    const splitSort = sort.split("-");
    const orderBy = { [splitSort[0]]: splitSort[1].toUpperCase() };
    const variables = {
      input: {
        productId,
      },
    };
    hasViewerFavorited
      ? commitUnfavorite({
          updater(proxyStore) {
            favoriteUpdater({
              categoryRollups,
              connectionId,
              gradeMax,
              gradeMin,
              gradingAuthorities,
              hasViewerFavorited: false,
              orderBy,
              priceMax,
              priceMin,
              productId,
              proxyStore,
              salesChannel: salesChannelContext,
              searchTerm,
              shopUrl,
              specialCopies,
              vertical,
              yearMax,
              yearMin,
            });
          },
          variables,
        })
      : commitFavorite({
          updater(proxyStore) {
            favoriteUpdater({
              categoryRollups,
              connectionId,
              gradeMax,
              gradeMin,
              gradingAuthorities,
              hasViewerFavorited: true,
              orderBy,
              priceMax,
              priceMin,
              productId,
              proxyStore,
              salesChannel: salesChannelContext,
              searchTerm,
              shopUrl,
              specialCopies,
              vertical,
              yearMax,
              yearMin,
            });
          },
          variables,
        });
  }, [
    categoryRollups,
    commitFavorite,
    commitUnfavorite,
    connectionId,
    gradeMax,
    gradeMin,
    gradingAuthorities,
    hasViewerFavorited,
    isInFlightFavorite,
    isInFlightUnfavorite,
    priceMax,
    priceMin,
    productId,
    salesChannelContext,
    searchTerm,
    shopUrl,
    sort,
    specialCopies,
    vertical,
    yearMax,
    yearMin,
  ]);

  const handleClickWatch = useCallback(() => {
    if (isInFlightWatch || isInFlightUnwatch || auctionLotId == null) {
      return;
    }
    const splitSort = sort.split("-");
    const orderBy = { [splitSort[0]]: splitSort[1].toUpperCase() };
    const variables = {
      input: {
        auctionLotId,
      },
    };
    isViewerWatching
      ? commitUnwatch({
          updater(proxyStore) {
            watchUpdater({
              categoryRollups,
              connectionId,
              gradeMax,
              gradeMin,
              gradingAuthorities,
              isViewerWatching: false,
              orderBy,
              priceMax,
              priceMin,
              productId,
              proxyStore,
              salesChannel: salesChannelContext,
              searchTerm,
              shopUrl,
              specialCopies,
              vertical,
              yearMax,
              yearMin,
            });
          },
          variables,
        })
      : commitWatch({
          updater(proxyStore) {
            watchUpdater({
              categoryRollups,
              connectionId,
              gradeMax,
              gradeMin,
              gradingAuthorities,
              isViewerWatching: true,
              orderBy,
              priceMax,
              priceMin,
              productId,
              proxyStore,
              salesChannel: salesChannelContext,
              searchTerm,
              shopUrl,
              specialCopies,
              vertical,
              yearMax,
              yearMin,
            });
          },
          variables,
        });
  }, [
    auctionLotId,
    categoryRollups,
    commitUnwatch,
    commitWatch,
    connectionId,
    gradeMax,
    gradeMin,
    gradingAuthorities,
    isInFlightUnwatch,
    isInFlightWatch,
    isViewerWatching,
    priceMax,
    priceMin,
    productId,
    salesChannelContext,
    searchTerm,
    shopUrl,
    sort,
    specialCopies,
    vertical,
    yearMax,
    yearMin,
  ]);

  return (
    <SBProductTile
      auctionLotId={auctionLotId}
      currency={currency}
      currentBidAmount={currentBidAmount}
      favorite={hasViewerFavorited}
      favoriteLoading={isInFlightFavorite || isInFlightUnfavorite}
      fmvMaxValue={fmvMaxValue}
      fmvMinValue={fmvMinValue}
      fmvRank={fmvRank}
      grade={grade}
      gradingAuthority={gradingAuthority}
      imageUrl={imageUrl}
      isAuctionLotClosed={isAuctionLotClosed}
      lotClosingTime={lotClosingTime}
      number={number}
      price={price}
      productId={productId}
      salesChannel={salesChannel}
      title={title}
      watching={isViewerWatching}
      watchingLoading={isInFlightWatch || isInFlightUnwatch}
      onClickFavorite={handleClickFavorite}
      onClickWatch={handleClickWatch}
    />
  );
};

export default SearchResultCard;
