/**
 * @generated SignedSource<<e631a7640bc4a48089912db1630c71ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesAccountInvoiceDetailQuery$variables = {
  id: number;
};
export type QueriesAccountInvoiceDetailQuery$data = {
  readonly invoiceBySurrogateId: {
    readonly surrogateId: number;
    readonly " $fragmentSpreads": FragmentRefs<"AccountInvoiceDetailView_invoice">;
  } | null | undefined;
};
export type QueriesAccountInvoiceDetailQuery = {
  response: QueriesAccountInvoiceDetailQuery$data;
  variables: QueriesAccountInvoiceDetailQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "surrogateId",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surrogateId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesAccountInvoiceDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Invoice",
        "kind": "LinkedField",
        "name": "invoiceBySurrogateId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountInvoiceDetailView_invoice"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesAccountInvoiceDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Invoice",
        "kind": "LinkedField",
        "name": "invoiceBySurrogateId",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c9b3fa91316bb8284dd74b3c63ca18c1",
    "id": null,
    "metadata": {},
    "name": "QueriesAccountInvoiceDetailQuery",
    "operationKind": "query",
    "text": "query QueriesAccountInvoiceDetailQuery(\n  $id: Int!\n) {\n  invoiceBySurrogateId(surrogateId: $id) {\n    surrogateId\n    ...AccountInvoiceDetailView_invoice\n    id\n  }\n}\n\nfragment AccountInvoiceDetailView_invoice on Invoice {\n  id\n  createdAt\n  updatedAt\n  surrogateId\n}\n"
  }
};
})();

(node as any).hash = "68764d8e5522e8f1ebe97d473a0944e1";

export default node;
