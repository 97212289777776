/**
 * @generated SignedSource<<43549e75f6b2384cef85f15f390a76e6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type CategoryRollup = "QUALIFIED" | "REJECT" | "RESTORED" | "SIGNATURE" | "UNIVERSAL" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type SlabDefect = "CRACKED" | "NEWTON_RINGS" | "SCRATCHED" | "%future added value";
export type SpecialCopy = "DOUBLE_COVER" | "INTERNATIONAL" | "MARK_JEWELERS" | "NATIONAL_DIAMOND" | "NEWSSTAND" | "TRIPLE_COVER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuctionLotMeta_product$data = {
  readonly comicDetails: {
    readonly artComments: string | null | undefined;
    readonly barcode: string | null | undefined;
    readonly categoryRollup: CategoryRollup;
    readonly grade: number | null | undefined;
    readonly gradeText: string | null | undefined;
    readonly gradingAuthority: GradingAuthority;
    readonly keyComments: string | null | undefined;
    readonly pageQuality: string | null | undefined;
    readonly pedigree: string | null | undefined;
    readonly publicGraderNotes: string | null | undefined;
    readonly publisher: string;
    readonly signatures: string | null | undefined;
    readonly specialCopy: SpecialCopy | null | undefined;
    readonly variant: string | null | undefined;
    readonly year: string | null | undefined;
  } | null | undefined;
  readonly gradedCopy: {
    readonly slabDefects: ReadonlyArray<SlabDefect> | null | undefined;
  };
  readonly seller: {
    readonly shopName: string | null | undefined;
    readonly shopUrl: string | null | undefined;
  } | null | undefined;
  readonly submissionNotes: string | null | undefined;
  readonly " $fragmentType": "AuctionLotMeta_product";
};
export type AuctionLotMeta_product$key = {
  readonly " $data"?: AuctionLotMeta_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionLotMeta_product">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionLotMeta_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grade",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradingAuthority",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "year",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publisher",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "keyComments",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "artComments",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "barcode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "categoryRollup",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "gradeText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicGraderNotes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pedigree",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "variant",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pageQuality",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "signatures",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "specialCopy",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GradedCopy",
      "kind": "LinkedField",
      "name": "gradedCopy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slabDefects",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserPublic",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shopName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shopUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};

(node as any).hash = "788d1705b7fbfa0601df7bb059d3b3c0";

export default node;
