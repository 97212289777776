/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useMarkdownComponents } from "src/hooks";

type Props = Readonly<{
  markdown: string;
}>;

export const SBMarkdownRenderer = React.memo(
  ({ markdown }: Props): React.ReactNode => {
    const markdownComponents = useMarkdownComponents();

    return (
      <Markdown components={markdownComponents} remarkPlugins={[remarkGfm]}>
        {markdown}
      </Markdown>
    );
  },
);
