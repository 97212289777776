/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { HeadingLevel, SBHeading } from "./SBHeading";

type Props = Readonly<{
  subtitle?: string;
  title: string;
}>;

export const SBPageHeader = React.memo(
  ({ title, subtitle }: Props): React.ReactNode => {
    return (
      <div {...stylex.props(auto, styles.root)}>
        <SBHeading level={HeadingLevel.H1}>{title}</SBHeading>
        {subtitle ? (
          <SBHeading level={HeadingLevel.H2} style={styles.subtitle}>
            {subtitle}
          </SBHeading>
        ) : null}
      </div>
    );
  },
);

const styles = stylex.create({
  root: {
    borderBottomColor: colors.horizontalRuleColor,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    marginBottom: 32,
    paddingBottom: 16,
  },
  subtitle: {
    fontSize: 24,
  },
});
