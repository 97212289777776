/**
 * @generated SignedSource<<89a9b2d6d1aab1a36594195be2296714>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedArticle_article$data = {
  readonly featuredArticle: {
    readonly " $fragmentSpreads": FragmentRefs<"ArticleCard_article">;
  } | null | undefined;
  readonly " $fragmentType": "FeaturedArticle_article";
};
export type FeaturedArticle_article$key = {
  readonly " $data"?: FeaturedArticle_article$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedArticle_article">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeaturedArticle_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "featuredArticle",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ArticleCard_article"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "b4bb02526ff5342d0f275bd75311193e";

export default node;
