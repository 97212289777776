/**
 * @generated SignedSource<<c2ddefc7c250ee45852b8684b7851851>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerStatsIntervals = "ALL_TIME" | "ONE_MONTH" | "YTD" | "%future added value";
export type SellerAccountStatsViewRefetchQuery$variables = {
  id: string;
  interval: SellerStatsIntervals;
};
export type SellerAccountStatsViewRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStatsView_user">;
  } | null | undefined;
};
export type SellerAccountStatsViewRefetchQuery = {
  response: SellerAccountStatsViewRefetchQuery$data;
  variables: SellerAccountStatsViewRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "interval",
    "variableName": "interval"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SellerAccountStatsViewRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "kind": "FragmentSpread",
            "name": "SellerAccountStatsView_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SellerAccountStatsViewRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "UserSellerStats",
                "kind": "LinkedField",
                "name": "sellerStats",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "numOrders",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salesTotal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradedShippingScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rawShippingScore",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "averageTurnaroundTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b79231589783d47aff5fa02390e44e4",
    "id": null,
    "metadata": {},
    "name": "SellerAccountStatsViewRefetchQuery",
    "operationKind": "query",
    "text": "query SellerAccountStatsViewRefetchQuery(\n  $interval: SellerStatsIntervals!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...SellerAccountStatsView_user_1WNHMa\n    id\n  }\n}\n\nfragment SellerAccountStatsFeedbackTable_sellerStats on UserSellerStats {\n  gradedShippingScore\n  gradingScore\n  rawShippingScore\n}\n\nfragment SellerAccountStatsSummary_sellerStats on UserSellerStats {\n  numOrders\n  salesTotal\n}\n\nfragment SellerAccountStatsTurnaroundTimesTable_sellerStats on UserSellerStats {\n  averageTurnaroundTime\n}\n\nfragment SellerAccountStatsView_user_1WNHMa on User {\n  sellerStats(interval: $interval) {\n    ...SellerAccountStatsSummary_sellerStats\n    ...SellerAccountStatsFeedbackTable_sellerStats\n    ...SellerAccountStatsTurnaroundTimesTable_sellerStats\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "9ca2442836c3228b608590b43a9b0b09";

export default node;
