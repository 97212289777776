/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountPayoutPreferencesQuery } from "src/types/__generated__/QueriesSellerAccountPayoutPreferencesQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import SellerAccountPage from "../SellerAccountPage";
import SellerAccountPayoutPreferencesContent from "./SellerAccountPayoutPreferencesContent";

const SellerAccountPayoutPreferences = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesSellerAccountPayoutPreferencesQuery>;

  return (
    <SellerAccountPage
      docTitle={t("seller-account.payout-preferences.title")}
      innerHeading={t("seller-account.nav.payout-preferences")}
    >
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <SellerAccountPayoutPreferencesContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </SellerAccountPage>
  );
};

export default SellerAccountPayoutPreferences;
