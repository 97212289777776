/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { RecordSourceSelectorProxy } from "relay-runtime";
import type {
  CategoryRollup,
  GradingAuthority,
  SalesChannel,
  SpecialCopy,
  Vertical,
  VerticalSearchOrderByInput,
} from "src/types/__generated__/ShopPaginationQuery.graphql";

import { ConnectionHandler } from "relay-runtime";

interface Options {
  categoryRollups: readonly CategoryRollup[];
  connectionId: string | null | undefined;
  gradeMax: number | null | undefined;
  gradeMin: number | null | undefined;
  gradingAuthorities: readonly GradingAuthority[];
  isViewerWatching: boolean;
  orderBy: VerticalSearchOrderByInput;
  priceMax: number | null | undefined;
  priceMin: number | null | undefined;
  productId: string;
  proxyStore: RecordSourceSelectorProxy;
  salesChannel: readonly SalesChannel[];
  searchTerm: string;
  shopUrl: string | null | undefined;
  specialCopies: readonly SpecialCopy[];
  vertical: Vertical;
  yearMax: number | null | undefined;
  yearMin: number | null | undefined;
}

export default function watchUpdater({
  proxyStore,
  connectionId,
  productId,
  isViewerWatching,
  orderBy,
  searchTerm,
  vertical,
  salesChannel,
  categoryRollups,
  gradeMax,
  gradeMin,
  gradingAuthorities,
  priceMax,
  priceMin,
  specialCopies,
  yearMax,
  yearMin,
  shopUrl,
}: Options) {
  if (connectionId == null) {
    return;
  }

  const record = proxyStore.get(connectionId);
  if (record == null) {
    return;
  }

  const verticalSearchConnection = ConnectionHandler.getConnection(
    record,
    "ShopView_verticalSearch",
    {
      orderBy,
      vertical,
      where: {
        categoryRollups: categoryRollups.length === 0 ? null : categoryRollups,
        gradeMax,
        gradeMin,
        gradingAuthorities:
          gradingAuthorities.length === 0 ? null : gradingAuthorities,
        priceMax,
        priceMin,
        salesChannel,
        searchTerm,
        shopUrl,
        specialCopies: specialCopies.length === 0 ? null : specialCopies,
        yearMax,
        yearMin,
      },
    },
  );
  if (verticalSearchConnection != null) {
    const verticalSearchEdges =
      verticalSearchConnection.getLinkedRecords("edges");
    let hasFoundMatch = false;
    for (const edge of verticalSearchEdges ?? []) {
      const nodeRecord = edge?.getLinkedRecord("node");
      const currentProductId = nodeRecord?.getValue("productId");
      if (currentProductId === productId) {
        hasFoundMatch = true;
        nodeRecord?.setValue(isViewerWatching, "isViewerWatching");
        break;
      }
      if (hasFoundMatch) {
        break;
      }
    }
  }
}
