/**
 * @generated SignedSource<<98da81f5568bbe2f2aa07b8fd7059783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesFaqsQuery$variables = Record<PropertyKey, never>;
export type QueriesFaqsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"FaqsViewQuery_faqs">;
};
export type QueriesFaqsQuery = {
  response: QueriesFaqsQuery$data;
  variables: QueriesFaqsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "after",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1000
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "createdAt": "ASC"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesFaqsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "FaqsViewQuery_faqs"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesFaqsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "FaqsConnection",
        "kind": "LinkedField",
        "name": "faqs",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FaqsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Faq",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "updatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "question",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "answer",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "category",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": "faqs(after:\"\",first:1000,orderBy:{\"createdAt\":\"ASC\"})"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "orderBy"
        ],
        "handle": "connection",
        "key": "FaqsViewQuery_faqs",
        "kind": "LinkedHandle",
        "name": "faqs"
      }
    ]
  },
  "params": {
    "cacheID": "04340a0322eb91d63a515748cca1657d",
    "id": null,
    "metadata": {},
    "name": "QueriesFaqsQuery",
    "operationKind": "query",
    "text": "query QueriesFaqsQuery {\n  ...FaqsViewQuery_faqs\n}\n\nfragment FaqsViewQuery_faqs on Query {\n  faqs(after: \"\", first: 1000, orderBy: {createdAt: ASC}) {\n    edges {\n      node {\n        id\n        createdAt\n        updatedAt\n        question\n        answer\n        category\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "19906e65175dd20553f8168095e43532";

export default node;
