/**
 * @generated SignedSource<<cbce501f481e7b6c51c7453707e00918>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PersonalInfoForm_user$data = {
  readonly emailAddress: string;
  readonly firstName: string | null | undefined;
  readonly lastName: string | null | undefined;
  readonly " $fragmentType": "PersonalInfoForm_user";
};
export type PersonalInfoForm_user$key = {
  readonly " $data"?: PersonalInfoForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PersonalInfoForm_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonalInfoForm_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "58721f97691aacd72e813edc6bfdd082";

export default node;
