/**
 * @generated SignedSource<<e307971423f5815999b34761c65efce4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesSellerAccountVacationModeQuery$variables = Record<PropertyKey, never>;
export type QueriesSellerAccountVacationModeQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"SellerAccountVacationModeView_user">;
    } | null | undefined;
  };
};
export type QueriesSellerAccountVacationModeQuery = {
  response: QueriesSellerAccountVacationModeQuery$data;
  variables: QueriesSellerAccountVacationModeQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesSellerAccountVacationModeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SellerAccountVacationModeView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesSellerAccountVacationModeQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sellerStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "vacationSince",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1d646541a4d0c629cfa7dffedbe96d2d",
    "id": null,
    "metadata": {},
    "name": "QueriesSellerAccountVacationModeQuery",
    "operationKind": "query",
    "text": "query QueriesSellerAccountVacationModeQuery {\n  viewer {\n    user {\n      ...SellerAccountVacationModeView_user\n      id\n    }\n  }\n}\n\nfragment SellerAccountVacationModeView_user on User {\n  id\n  sellerStatus\n  vacationSince\n}\n"
  }
};

(node as any).hash = "92b8e81fa23efa2ed47c94c62bc90eec";

export default node;
