/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { useTranslation } from "react-i18next";

export const useDefaultValidationErrorMessages = (): {
  date: string;
  email: string;
  equalPassword: string;
  hasLowerCase: string;
  hasNumber: string;
  hasSpecialCharacter: string;
  hasUpperCase: string;
  maxlength: string;
  minlength: string;
  numbers: string;
  required: string;
} => {
  const { t } = useTranslation();

  return {
    date: t("forms.validation.date"),
    email: t("forms.validation.email"),
    equalPassword: t("forms.validation.passwords"),
    hasLowerCase: t("forms.validation.lowercase"),
    hasNumber: t("forms.validation.number"),
    hasSpecialCharacter: t("forms.validation.special"),
    hasUpperCase: t("forms.validation.uppercase"),
    maxlength: t("forms.validation.max"),
    minlength: t("forms.validation.min"),
    numbers: t("forms.validation.numbers"),
    required: t("forms.validation.required"),
  };
};
