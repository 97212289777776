/**
 * @generated SignedSource<<82d543d9116d0e4975ec4b9c93a839db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type OrderPublicStatusType = "CANCELED" | "DELIVERED" | "DELIVERY_EXCEPTION" | "FAILED" | "IN_TRANSIT" | "PROCESSING" | "QUEUED" | "REFUNDED" | "UNAVAILABLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountOrdersView_user$data = {
  readonly orders: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly createdAt: string;
        readonly currency: Currency;
        readonly id: string;
        readonly product: {
          readonly comicDetails: {
            readonly grade: number | null | undefined;
            readonly gradingAuthority: GradingAuthority;
            readonly number: string;
            readonly title: string;
          } | null | undefined;
          readonly images: {
            readonly edges: ReadonlyArray<{
              readonly node: {
                readonly url: string;
              };
            }>;
          } | null | undefined;
        };
        readonly publicStatus: OrderPublicStatusType;
        readonly surrogateId: number;
        readonly total: number;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "AccountOrdersView_user";
};
export type AccountOrdersView_user$key = {
  readonly " $data"?: AccountOrdersView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountOrdersView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountOrdersView_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 100
        },
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": {
            "createdAt": "DESC"
          }
        }
      ],
      "concreteType": "SellerOrdersConnection",
      "kind": "LinkedField",
      "name": "orders",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SellerOrdersEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Order",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "surrogateId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publicStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "total",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "currency",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Product",
                  "kind": "LinkedField",
                  "name": "product",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "first",
                          "value": 1
                        }
                      ],
                      "concreteType": "ImagesConnection",
                      "kind": "LinkedField",
                      "name": "images",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ImagesEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Image",
                              "kind": "LinkedField",
                              "name": "node",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": [
                                    {
                                      "kind": "Literal",
                                      "name": "quality",
                                      "value": 100
                                    },
                                    {
                                      "kind": "Literal",
                                      "name": "webp",
                                      "value": true
                                    },
                                    {
                                      "kind": "Literal",
                                      "name": "width",
                                      "value": 300
                                    }
                                  ],
                                  "kind": "ScalarField",
                                  "name": "url",
                                  "storageKey": "url(quality:100,webp:true,width:300)"
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": "images(first:1)"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "ComicDetail",
                      "kind": "LinkedField",
                      "name": "comicDetails",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "title",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "number",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "gradingAuthority",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "grade",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "orders(first:100,orderBy:{\"createdAt\":\"DESC\"})"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ba40fd8e8750e14aa67ba581b0bc26cb";

export default node;
