/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { injectStyle } from "react-toastify/dist/inject-style";

import Footer from "src/app/components/footer/Footer";
import Header from "src/app/components/header/Header";
import { PageContext } from "src/app/context/page";
import ErrorBoundary from "src/app/pages/error-boundary/ErrorBoundary";
import { SBFallback } from "src/sbxui";
import { SBOrderDetailTakeover } from "src/sbxui/SBOrderDetailTakeover";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { OrderDetailContext } from "../context/order-detail";

injectStyle();

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const ORDER_RE = /\/account\/orders\/([0-9]{8})/iu;

type Props = Readonly<
  React.PropsWithChildren<{
    carousel?: React.ReactNode;
    headless?: boolean;
  }>
>;

const Page = ({ carousel, children, headless }: Props) => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const pageContext = useContext(PageContext);

  const {
    orderId,
    queryRef: orderQueryRef,
    setOrderId,
  } = useContext(OrderDetailContext);

  const setIsOrderModalOpen = () => {
    setOrderId(null);
    pageContext?.setTitle(t("shop.document-title"));
    history.pushState({}, "", pathname);
  };

  useEffect(() => {
    const newOrderId = pathname.match(ORDER_RE)?.[1];

    if (newOrderId != null) {
      setOrderId(newOrderId);
    }
  }, [pathname, setOrderId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (headless) {
    return <Suspense fallback={<SBFallback />}>{children}</Suspense>;
  }

  return (
    <ErrorBoundary>
      <Header />
      {carousel}
      <div {...stylex.props(auto, styles.root)}>
        <div {...stylex.props(styles.content)} aria-live="polite">
          <Suspense fallback={<SBFallback />}>{children}</Suspense>
        </div>
      </div>
      <Footer />
      <CookieConsent
        buttonClasses={stylex.props(auto, styles.cookieConsentButton).className}
        buttonText={t("cookieConsent.accept")}
        containerClasses={
          stylex.props(auto, styles.cookieConsentContainer).className
        }
        cookieName="cookie-consent"
        disableStyles={true}
        expires={150}
        location="bottom"
      >
        {t("cookieConsent.message")}
      </CookieConsent>
      <SBOrderDetailTakeover
        isOpen={orderId != null}
        queryRef={orderQueryRef}
        setOpen={setIsOrderModalOpen}
      />
      <ToastContainer
        autoClose={1000}
        closeOnClick
        draggable
        hideProgressBar={false}
        icon={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="bottom-right"
        rtl={false}
        theme="dark"
      />
    </ErrorBoundary>
  );
};

const styles = stylex.create({
  content: {
    marginBlock: 40,
    marginInline: {
      [MOBILE]: "auto",
      [TABLET]: "auto",
      default: 20,
    },
    width: {
      [MOBILE]: "95%",
      [TABLET]: "95%",
      default: 1200,
    },
  },
  cookieConsentButton: {
    alignItems: "center",
    backgroundColor: colors.buttonEmphasisBackgroundColor,
    borderRadius: 24,
    borderWidth: 0,
    boxSizing: "border-box",
    color: colors.buttonEmphasisColor,
    cursor: "pointer",
    display: "inline-flex",
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 20,
    height: 48,
    justifyContent: "center",
    lineHeight: "48px",
    marginLeft: {
      [MOBILE]: 0,
      [TABLET]: 16,
      default: 16,
    },
    marginTop: {
      [MOBILE]: 16,
      [TABLET]: 0,
      default: 0,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
    paddingInline: 32,
    textDecorationLine: "none",
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
  cookieConsentContainer: {
    alignItems: "baseline",
    backgroundColor: colors.footerBackgroundColor,
    borderBottomWidth: 0,
    borderColor: colors.footerBorderColor,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderStyle: "solid",
    borderTopWidth: 1,
    bottom: 0,
    boxSizing: "border-box",
    color: colors.footerColor,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    left: 0,
    paddingBlock: 16,
    paddingInline: 32,
    position: "fixed",
    width: "100%",
    zIndex: 999,
  },
  root: {
    backgroundColor: colors.backgroundColor,
    display: "flex",
    justifyContent: "center",
  },
});

export default Page;
