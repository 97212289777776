/**
 * @generated SignedSource<<2f20781ff7b09df3c1b782e0158ce1f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AuctionLotHeader_product$data = {
  readonly auctionLot: {
    readonly auction: {
      readonly biddingStartsAt: string;
      readonly lastLotClosesAt: string | null | undefined;
    };
    readonly bidCount: number;
    readonly closesAt: string | null | undefined;
    readonly currentBidAmount: number;
    readonly currentBidCurrency: Currency;
    readonly id: string;
    readonly isClosed: boolean;
    readonly isViewerBidding: boolean;
    readonly isViewerHighBidder: boolean;
    readonly maximumBidAmount: number | null | undefined;
    readonly startingAmount: number;
    readonly uniqueBiddersCount: number;
    readonly " $fragmentSpreads": FragmentRefs<"AuctionLotBidHistoryTableQuery_auctionLot">;
  } | null | undefined;
  readonly comicDetails: {
    readonly number: string;
    readonly title: string;
  } | null | undefined;
  readonly fmvScore: {
    readonly currency: Currency | null | undefined;
    readonly maxValue: number | null | undefined;
    readonly minValue: number | null | undefined;
    readonly rank: number | null | undefined;
  } | null | undefined;
  readonly id: string;
  readonly images: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly url: string;
      };
    }>;
  } | null | undefined;
  readonly " $fragmentType": "AuctionLotHeader_product";
};
export type AuctionLotHeader_product$key = {
  readonly " $data"?: AuctionLotHeader_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"AuctionLotHeader_product">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionLotHeader_product",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ComicDetail",
      "kind": "LinkedField",
      "name": "comicDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImagesConnection",
      "kind": "LinkedField",
      "name": "images",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ImagesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Image",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "quality",
                      "value": 100
                    },
                    {
                      "kind": "Literal",
                      "name": "webp",
                      "value": true
                    },
                    {
                      "kind": "Literal",
                      "name": "width",
                      "value": 1000
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": "url(quality:100,webp:true,width:1000)"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AuctionLot",
      "kind": "LinkedField",
      "name": "auctionLot",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AuctionLotBidHistoryTableQuery_auctionLot"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isClosed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maximumBidAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentBidAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currentBidCurrency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startingAmount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bidCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isViewerBidding",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isViewerHighBidder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "closesAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uniqueBiddersCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Auction",
          "kind": "LinkedField",
          "name": "auction",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "biddingStartsAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lastLotClosesAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "FairMarketValueScore",
      "kind": "LinkedField",
      "name": "fmvScore",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxValue",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rank",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "94f76ec3fcd018c0781bf7efbd640a95";

export default node;
