/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

import { SBActivityIndicator, SBModal } from "src/sbxui";

import DeclineOfferContent from "./DeclineOfferContent";

type Props = Readonly<{
  isOpen: boolean;
  offerId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const DeclineOffer = ({ isOpen, offerId, setOpen }: Props): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <SBModal
      headerText={t("modal.offer.title.decline")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div {...stylex.props(styles.modal)}>
        <Suspense
          fallback={
            <div {...stylex.props(styles.fallback)}>
              <SBActivityIndicator />
            </div>
          }
        >
          <DeclineOfferContent offerId={offerId} setOpen={setOpen} />
        </Suspense>
      </div>
    </SBModal>
  );
};

const styles = stylex.create({
  fallback: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  modal: {
    padding: 16,
  },
});

export default React.memo(DeclineOffer);
