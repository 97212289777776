/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// import i18n (needs to be bundled ;))
import "./i18n/i18n";

import ReactDOM from "react-dom/client";

import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(<App />);
