/**
 * @generated SignedSource<<f495b3f5d8a083c45e60a0adeb9e2dec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountPersonalInformationForm_user$data = {
  readonly emailAddress: string;
  readonly firstName: string | null | undefined;
  readonly id: string;
  readonly lastName: string | null | undefined;
  readonly " $fragmentType": "AccountPersonalInformationForm_user";
};
export type AccountPersonalInformationForm_user$key = {
  readonly " $data"?: AccountPersonalInformationForm_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountPersonalInformationForm_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountPersonalInformationForm_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "3a583860d95db56f51790ea6aefd32cc";

export default node;
