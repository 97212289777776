/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

import { SellerStatsContext } from "src/app/context/seller-stats";
import { SBTabs } from "src/sbxui";

const INTERVALS = Object.freeze(["ONE_MONTH", "YTD", "ALL_TIME"]);

const SellerAccountStatsTabs = (): React.ReactNode => {
  const { t } = useTranslation();

  const { interval: selectedInterval, setInterval } =
    useContext(SellerStatsContext);

  const TABS = INTERVALS.map((interval) => ({
    id: interval,
    name: t(`seller-account.stats.interval.${interval}`),
    selected: selectedInterval === interval,
  }));

  return <SBTabs tabs={TABS} onTabSelected={(tabId) => setInterval(tabId)} />;
};

export default SellerAccountStatsTabs;
