/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// TODO Re-enable this rule once the invoice page is complete.

import type {
  CreateOrderBraintreeActions,
  OnApproveBraintreeActions,
  OnApproveBraintreeData,
} from "@paypal/react-paypal-js";
import type { BraintreeTokenizePayload } from "@paypal/react-paypal-js/dist/types/types/braintree/commonsTypes";
import type { Appearance, PaymentIntent } from "@stripe/stripe-js";
import type { InvoicePayment_invoice$key } from "src/types/__generated__/InvoicePayment_invoice.graphql";
import type { InvoicePayment_user$key } from "src/types/__generated__/InvoicePayment_user.graphql";
import type {
  Country,
  InvoicePaymentMethod,
} from "src/types/__generated__/InvoiceView_invoice.graphql";

import {
  BraintreePayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { STRIPE_KEY } from "src/api/constants";
import { SHORTBOXED_INC } from "src/app/constants";
import useStripeTheme from "src/hooks/_hooks/useStripeTheme";
import {
  HeadingLevel,
  SBActivityIndicator,
  SBButton,
  SBCheckboxInput,
  SBHeading,
  SBIcon,
  SBLink,
  SBParagraph,
  SBRadioButtons,
} from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

import InvoicePaymentForm from "./InvoicePaymentForm";

const TIMEOUT_MS = 4000;

// https://www.paypal.com/us/cshelp/article/what-is-pay-in-4-help463
const MIN_PRICE_FOR_PAY_IN_4_USD = 30;
const MAX_PRICE_FOR_PAY_IN_4_USD = 1500;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY);

type Props = Readonly<{
  achMinimumValue: number;
  braintreeToken: string;
  disabled: boolean;
  loading: boolean;
  onChangePaymentMethod: (paymentMethod: InvoicePaymentMethod) => void;
  onCompletePaypal: (payload: BraintreeTokenizePayload) => void;
  onCompleteStripe: (status: PaymentIntent.Status) => void;
  onSelectingPaymentMethod: (selecting: boolean) => void;
  payable: boolean;
  paymentMethod: InvoicePaymentMethod;
  paypalClientId: string;
  queryKey: InvoicePayment_invoice$key;
  queryKeyUser: InvoicePayment_user$key;
  raw: boolean;
  shipToCountry: Country;
  shipToId: string | null | undefined;
}>;

const InvoicePayment = ({
  achMinimumValue,
  braintreeToken,
  disabled,
  loading,
  onChangePaymentMethod,
  onCompletePaypal,
  onCompleteStripe,
  onSelectingPaymentMethod,
  payable,
  paymentMethod,
  queryKey,
  queryKeyUser,
  raw,
  shipToCountry,
  shipToId,
  paypalClientId,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const timeoutId = useRef<ReturnType<typeof setInterval> | null>(null);

  const userData = useFragment(
    graphql`
      fragment InvoicePayment_user on User {
        emailAddress
        firstName
        lastName
      }
    `,
    queryKeyUser,
  );

  const emailAddress = userData.emailAddress;
  const firstName = userData.firstName;
  const lastName = userData.lastName;

  const data = useFragment(
    graphql`
      fragment InvoicePayment_invoice on Invoice {
        currency
        id
        paymentSheet {
          customer
          paymentIntent
        }
        requiresVerification
        total
      }
    `,
    queryKey,
  );

  const invoiceId = data.id;

  const currency = data.currency;
  const total = data.total;

  const clientSecret = data.paymentSheet?.paymentIntent;
  const customerSessionClientSecret = data.paymentSheet?.customer;

  const requiresVerification = data.requiresVerification;

  const isPayIn4Disabled =
    total > MAX_PRICE_FOR_PAY_IN_4_USD || total < MIN_PRICE_FOR_PAY_IN_4_USD;

  const [isLoading, setIsLoading] = useState(loading);

  const [shouldShowPaymentMethods, setShouldShowPaymentMethods] =
    useState(false);

  const [payWith, setPayWith] = useState<InvoicePaymentMethod>(paymentMethod);

  const [rawBookCheckbox, setRawBookCheckbox] = useState(false);
  const [verificationCheckbox, setVerificationCheckbox] = useState(false);
  const [achMandate, setAchMandate] = useState(false);

  const { light, dark } = useStripeTheme();

  const appearance = useMemo<Appearance>(() => {
    if (window.matchMedia?.("(prefers-color-scheme: dark)").matches) {
      return dark;
    }
    return light;
  }, [dark, light]);

  const handleClickSelect = () => {
    if (disabled) {
      return;
    }
    setShouldShowPaymentMethods(true);
    onSelectingPaymentMethod(true);
  };

  const handleClickConfirm = () => {
    setIsLoading(true);
    setShouldShowPaymentMethods(false);
    onSelectingPaymentMethod(false);
    onChangePaymentMethod(payWith);
    timeoutId.current = setTimeout(() => {
      setIsLoading(false);
      if (timeoutId.current != null) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    }, TIMEOUT_MS);
  };

  const handleCreateOrder = (
    _paymentData: Record<string, unknown>,
    actions: CreateOrderBraintreeActions,
  ) =>
    actions.braintree.createPayment({
      amount: total,
      currency,
      flow: "checkout",
      intent: "capture",
    });

  const handleApprove = (
    paymentData: OnApproveBraintreeData,
    actions: OnApproveBraintreeActions,
  ) =>
    actions.braintree.tokenizePayment(paymentData).then((payload) => {
      onCompletePaypal(payload);
    });

  const handleChange = (
    value: string,
    _event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    switch (value) {
      case "ACH":
      case "CREDIT_CARD":
      case "PAYPAL":
        setPayWith(value);
        break;
    }
  };

  const handleChangeAchMandateCheckbox = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAchMandate(!achMandate);
  };

  const handleChangeVerificationCheckbox = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setVerificationCheckbox(!verificationCheckbox);
  };

  const handleChangeRawBookCheckbox = (
    _event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRawBookCheckbox(!rawBookCheckbox);
  };

  useEffect(() => {
    return () => {
      if (timeoutId.current != null) {
        clearTimeout(timeoutId.current);
        timeoutId.current = null;
      }
    };
  }, []);

  let isButtonDisabled = false;
  if (payWith === "ACH" && !isButtonDisabled) {
    isButtonDisabled = !achMandate;
  }
  if (raw && !isButtonDisabled) {
    isButtonDisabled = !rawBookCheckbox;
  }
  if (requiresVerification && !isButtonDisabled) {
    isButtonDisabled = !verificationCheckbox;
  }
  if (shipToId == null) {
    isButtonDisabled = true;
  }

  const stripeOptions = {
    appearance,
    clientSecret: clientSecret ?? "",
    customerSessionClientSecret,
  };

  const paypalOptions = {
    clientId: paypalClientId,
    dataClientToken: braintreeToken,
  };

  const paymentMethods = [
    {
      label: (
        <>
          <SBParagraph style={styles.title}>
            {t(`invoice.pay-with.ach.title`)}
          </SBParagraph>
          <SBParagraph style={styles.description}>
            {t(`invoice.pay-with.ach.description`)}
          </SBParagraph>
          <SBParagraph style={styles.description}>
            {t("invoice.pay-with.ach.disclaimer")}
          </SBParagraph>
        </>
      ),
      value: "ACH",
    },
    {
      label: (
        <>
          <SBParagraph style={styles.title}>
            {t(`invoice.pay-with.credit-card.title`)}
          </SBParagraph>
          <SBParagraph style={styles.description}>
            {t(`invoice.pay-with.credit-card.description`)}
          </SBParagraph>
        </>
      ),
      value: "CREDIT_CARD",
    },
    {
      label: (
        <>
          <SBParagraph style={styles.title}>
            {t(
              `invoice.pay-with.paypal${isPayIn4Disabled ? "" : "-pay-in-4"}.title`,
            )}
          </SBParagraph>
          <SBParagraph style={styles.description}>
            {t(`invoice.pay-with.paypal.description`)}
          </SBParagraph>
        </>
      ),
      value: "PAYPAL",
    },
  ];
  if (total < achMinimumValue) {
    paymentMethods.shift();
  }

  let content = (
    <>
      <div {...stylex.props(styles.selected)}>
        <SBParagraph style={styles.title}>
          {t(
            `invoice.pay-with.${kebabCase(payWith)}${payWith === "PAYPAL" && !isPayIn4Disabled ? "-pay-in-4" : ""}.title`,
          )}
        </SBParagraph>
        <SBParagraph style={styles.description}>
          {t(`invoice.pay-with.${kebabCase(payWith)}.description`)}
        </SBParagraph>
        {payWith === "ACH" && (
          <SBParagraph style={styles.description}>
            {t("invoice.pay-with.ach.disclaimer")}
          </SBParagraph>
        )}
      </div>
      {payable ? (
        <SBParagraph style={styles.link}>
          <SBLink
            disabled={disabled}
            style={styles.linkText}
            onClick={handleClickSelect}
          >
            {t("invoice.payment.edit")}
            <SBIcon
              containerStyle={styles.iconContainer}
              icon="edit"
              style={styles.icon}
            />
          </SBLink>
        </SBParagraph>
      ) : null}
    </>
  );

  if (shouldShowPaymentMethods) {
    content = (
      <div {...stylex.props(styles.radioButtons)}>
        <SBRadioButtons
          id="payment"
          options={paymentMethods}
          value={payWith}
          onChange={handleChange}
        />
        <SBButton
          style={styles.paymentButton}
          title={t("invoice.payment.confirm")}
          onClick={handleClickConfirm}
        ></SBButton>
      </div>
    );
  }

  let payment: React.ReactNode =
    clientSecret == null ? null : (
      <div
        {...stylex.props(styles.payment, isLoading && styles.paymentLoading)}
      >
        <Elements options={stripeOptions} stripe={stripePromise}>
          <InvoicePaymentForm
            disabled={isButtonDisabled || isLoading || loading || disabled}
            emailAddress={emailAddress}
            firstName={firstName}
            invoiceId={invoiceId}
            lastName={lastName}
            paymentMethod={paymentMethod}
            onComplete={onCompleteStripe}
          />
        </Elements>
      </div>
    );
  if (paymentMethod === "PAYPAL") {
    payment = (
      <div
        {...stylex.props(styles.payment, isLoading && styles.paymentLoading)}
      >
        <PayPalScriptProvider options={paypalOptions}>
          <BraintreePayPalButtons
            createOrder={handleCreateOrder}
            disabled={isButtonDisabled || isLoading || loading || disabled}
            fundingSource="paypal"
            style={{
              color: "blue",
              label: "checkout",
              layout: "horizontal",
              shape: "pill",
            }}
            onApprove={handleApprove}
          />
        </PayPalScriptProvider>
      </div>
    );
  }
  if (!payable) {
    payment = null;
  }

  let checkboxes: React.ReactNode = null;
  if (payable) {
    checkboxes = (
      <div>
        {raw ? (
          <SBCheckboxInput
            checked={rawBookCheckbox}
            disabled={isLoading}
            id="raw"
            style={styles.checkbox}
            onChange={handleChangeRawBookCheckbox}
          >
            {t("invoice.raw.disclaimer")}
          </SBCheckboxInput>
        ) : null}
        {requiresVerification ? (
          <SBCheckboxInput
            checked={verificationCheckbox}
            disabled={isLoading}
            id="verification"
            style={styles.checkbox}
            onChange={handleChangeVerificationCheckbox}
          >
            {t(
              `invoice.${shipToCountry === "US" ? "verification" : "international"}`,
            )}
          </SBCheckboxInput>
        ) : null}
        {payWith === "ACH" && (
          <SBCheckboxInput
            checked={achMandate}
            disabled={isLoading}
            id="ach"
            style={styles.checkbox}
            onChange={handleChangeAchMandateCheckbox}
          >
            {t("invoice.ach-mandate", {
              company: SHORTBOXED_INC,
            })}
          </SBCheckboxInput>
        )}
      </div>
    );
  }

  return (
    <div {...stylex.props(styles.root)}>
      <SBHeading level={HeadingLevel.H2} style={styles.heading}>
        {t("invoice.pay-with.title")}
      </SBHeading>
      <div {...stylex.props(styles.contentContainer)}>
        <div {...stylex.props(styles.content)}>{content}</div>
      </div>
      {payable && !shouldShowPaymentMethods ? (
        <div {...stylex.props(styles.contentContainer)}>
          <div {...stylex.props(styles.contentPayment)}>
            {checkboxes}
            {isLoading ? <SBActivityIndicator small={true} /> : null}
            {payment}
          </div>
        </div>
      ) : null}
    </div>
  );
};

const styles = stylex.create({
  checkbox: {
    marginBottom: 8,
    paddingBottom: 8,
  },
  content: {
    alignItems: "flex-start",
    borderWidth: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  contentContainer: {
    borderColor: colors.tableBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    marginBottom: 16,
    padding: 16,
  },
  contentPayment: {
    alignItems: "flex-start",
    borderWidth: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  description: {
    marginBottom: 0,
  },
  heading: {
    fontSize: 28,
    marginBottom: 8,
  },
  icon: {
    color: colors.color,
  },
  iconContainer: {
    marginLeft: 8,
  },
  link: {
    alignSelf: "flex-start",
    display: "flex",
    height: 24,
    marginBottom: 0,
  },
  linkText: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    textDecoration: "none",
  },
  payment: {
    width: "100%",
  },
  paymentButton: {
    marginTop: 16,
  },
  paymentLoading: {
    display: "none",
  },
  radioButtons: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginRight: 16,
  },
  review: {
    alignItems: "flex-start",
    borderWidth: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  root: {
    marginBottom: 24,
    marginTop: 24,
  },
  selected: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  title: {
    fontWeight: 600,
    marginBottom: 0,
  },
});

export default InvoicePayment;
