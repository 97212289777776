/**
 * @generated SignedSource<<73d65dd2bc50774ceab3e3549ea2eb99>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Vertical = "COMIC" | "GENERIC" | "ORIGINAL_ART" | "SPORTS_CARD" | "TCG" | "VIDEO_GAME" | "%future added value";
export type QueriesHomeQuery$variables = {
  vertical: Vertical;
};
export type QueriesHomeQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HomeView_verticalSearch">;
};
export type QueriesHomeQuery = {
  response: QueriesHomeQuery$data;
  variables: QueriesHomeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "vertical"
  }
],
v1 = {
  "kind": "Variable",
  "name": "vertical",
  "variableName": "vertical"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesHomeQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "HomeView_verticalSearch"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QueriesHomeQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "after",
            "value": ""
          },
          {
            "kind": "Literal",
            "name": "first",
            "value": 24
          },
          {
            "kind": "Literal",
            "name": "orderBy",
            "value": {
              "createdAt": "DESC"
            }
          },
          (v1/*: any*/),
          {
            "fields": [
              {
                "kind": "Literal",
                "name": "categoryRollups",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "gradeMax",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "gradeMin",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "gradingAuthorities",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "priceMax",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "priceMin",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "salesChannel",
                "value": [
                  "AUCTION",
                  "SHOP"
                ]
              },
              {
                "kind": "Literal",
                "name": "searchTerm",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "shopUrl",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "specialCopies",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "yearMax",
                "value": null
              },
              {
                "kind": "Literal",
                "name": "yearMin",
                "value": null
              }
            ],
            "kind": "ObjectValue",
            "name": "where"
          }
        ],
        "concreteType": "VerticalSearchConnection",
        "kind": "LinkedField",
        "name": "verticalSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "VerticalSearchEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TypesenseSearch",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImagesConnection",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImagesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "quality",
                                    "value": 85
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "webp",
                                    "value": true
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "width",
                                    "value": 500
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": "url(quality:85,webp:true,width:500)"
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "auctionLotId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvRank",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvMinValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fmvMaxValue",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currentBidAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lotClosingTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isAuctionLotClosed",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "currency",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "salesChannel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasViewerFavorited",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isViewerWatching",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "kind": "ClientExtension",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__id",
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "415cd72b4a8e4109083ef7ae78aba8ba",
    "id": null,
    "metadata": {},
    "name": "QueriesHomeQuery",
    "operationKind": "query",
    "text": "query QueriesHomeQuery(\n  $vertical: Vertical!\n) {\n  ...HomeView_verticalSearch_2qYbiK\n}\n\nfragment HomeView_verticalSearch_2qYbiK on Query {\n  verticalSearch(vertical: $vertical, after: \"\", first: 24, orderBy: {createdAt: DESC}, where: {salesChannel: [AUCTION, SHOP]}) {\n    edges {\n      node {\n        id\n        ...SearchResultCard_typesenseSearch\n      }\n    }\n  }\n}\n\nfragment SearchResultCard_typesenseSearch on TypesenseSearch {\n  id\n  createdAt\n  title\n  images {\n    edges {\n      node {\n        url(quality: 85, webp: true, width: 500)\n        id\n      }\n    }\n  }\n  number\n  grade\n  gradingAuthority\n  productId\n  auctionLotId\n  fmvRank\n  fmvMinValue\n  fmvMaxValue\n  currentBidAmount\n  lotClosingTime\n  isAuctionLotClosed\n  price\n  currency\n  salesChannel\n  hasViewerFavorited\n  isViewerWatching\n}\n"
  }
};
})();

(node as any).hash = "fc6c6588e0faa2f7feeef4b083a1f7f3";

export default node;
