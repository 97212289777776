/**
 * @generated SignedSource<<203b13dfb9db7c208b290a788d398cd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesAccountAuctionBidsQuery$variables = Record<PropertyKey, never>;
export type QueriesAccountAuctionBidsQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"AccountAuctionBidsView_user">;
    } | null | undefined;
  };
};
export type QueriesAccountAuctionBidsQuery = {
  response: QueriesAccountAuctionBidsQuery$data;
  variables: QueriesAccountAuctionBidsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v1 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "createdAt": "DESC"
  }
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "auctionStatus": "OPEN"
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuctionBidsEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuctionBid",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuctionLot",
            "kind": "LinkedField",
            "name": "lot",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isClosed",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "closesAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentBidAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currentBidCurrency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isViewerBidding",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isViewerHighBidder",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isViewerOwner",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isViewerWatching",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startingAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Auction",
                "kind": "LinkedField",
                "name": "auction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "biddingStartsAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "product",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ComicDetail",
                    "kind": "LinkedField",
                    "name": "comicDetails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grade",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "gradingAuthority",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FairMarketValueScore",
                    "kind": "LinkedField",
                    "name": "fmvScore",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "maxValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "minValue",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImagesConnection",
                    "kind": "LinkedField",
                    "name": "images",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ImagesEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Image",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "quality",
                                    "value": 100
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "webp",
                                    "value": true
                                  },
                                  {
                                    "kind": "Literal",
                                    "name": "width",
                                    "value": 1000
                                  }
                                ],
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": "url(quality:100,webp:true,width:1000)"
                              },
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v5 = [
  "orderBy",
  "where"
],
v6 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "auctionStatus": "CLOSED"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesAccountAuctionBidsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AccountAuctionBidsView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesAccountAuctionBidsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "activeAuctionBids",
                "args": (v2/*: any*/),
                "concreteType": "AuctionBidsConnection",
                "kind": "LinkedField",
                "name": "auctionBids",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "auctionBids(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"auctionStatus\":\"OPEN\"})"
              },
              {
                "alias": "activeAuctionBids",
                "args": (v2/*: any*/),
                "filters": (v5/*: any*/),
                "handle": "connection",
                "key": "AccountAuctionBidsActive_activeAuctionBids",
                "kind": "LinkedHandle",
                "name": "auctionBids"
              },
              (v3/*: any*/),
              {
                "alias": "endedAuctionBids",
                "args": (v6/*: any*/),
                "concreteType": "AuctionBidsConnection",
                "kind": "LinkedField",
                "name": "auctionBids",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "auctionBids(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"auctionStatus\":\"CLOSED\"})"
              },
              {
                "alias": "endedAuctionBids",
                "args": (v6/*: any*/),
                "filters": (v5/*: any*/),
                "handle": "connection",
                "key": "AccountAuctionBidsEnded_endedAuctionBids",
                "kind": "LinkedHandle",
                "name": "auctionBids"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5aef00bae8804b940f6ceba15fc93ab6",
    "id": null,
    "metadata": {},
    "name": "QueriesAccountAuctionBidsQuery",
    "operationKind": "query",
    "text": "query QueriesAccountAuctionBidsQuery {\n  viewer {\n    user {\n      ...AccountAuctionBidsView_user\n      id\n    }\n  }\n}\n\nfragment AccountAuctionBidsActive_user on User {\n  activeAuctionBids: auctionBids(first: 20, orderBy: {createdAt: DESC}, where: {auctionStatus: OPEN}) {\n    edges {\n      node {\n        lot {\n          ...AuctionItem_auctionLot\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment AccountAuctionBidsEnded_user on User {\n  endedAuctionBids: auctionBids(first: 20, orderBy: {createdAt: DESC}, where: {auctionStatus: CLOSED}) {\n    edges {\n      node {\n        lot {\n          ...AuctionItem_auctionLot\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment AccountAuctionBidsView_user on User {\n  ...AccountAuctionBidsActive_user\n  ...AccountAuctionBidsEnded_user\n}\n\nfragment AuctionItem_auctionLot on AuctionLot {\n  id\n  shortDescription\n  isClosed\n  closesAt\n  currentBidAmount\n  currentBidCurrency\n  isViewerBidding\n  isViewerHighBidder\n  isViewerOwner\n  isViewerWatching\n  startingAmount\n  auction {\n    biddingStartsAt\n    type\n    id\n  }\n  product {\n    comicDetails {\n      title\n      number\n      grade\n      gradingAuthority\n      id\n    }\n    fmvScore {\n      maxValue\n      minValue\n      currency\n    }\n    images {\n      edges {\n        node {\n          url(quality: 100, webp: true, width: 1000)\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "99d9ec209f4a8beab23989cadfc76b31";

export default node;
