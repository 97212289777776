/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ComicIndexTitleQuery } from "src/types/__generated__/ComicIndexTitleQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { COMIC_INDEX_ISSUE_PATH } from "src/app/router/Router";
import { SBLink, SBPageHeader } from "src/sbxui";

const ComicIndexTitle = (): React.ReactNode | null => {
  const { t } = useTranslation();
  const { id } = useParams();
  const pageContext = useContext(PageContext);

  const { node: data } = useLazyLoadQuery<ComicIndexTitleQuery>(
    graphql`
      query ComicIndexTitleQuery($id: ID!) {
        node(id: $id) {
          ... on Title {
            id
            name
            publisher {
              name
            }
            issues(first: 100, orderBy: { number: ASC }) {
              count
              edges {
                node {
                  id
                  number
                  publishedDate
                }
              }
            }
          }
        }
      }
    `,
    { id: id ?? "" },
  );

  useEffect(() => {
    pageContext?.setTitle(
      t("comicIndex.title.documentTitle", {
        title: data?.name,
      }),
    );
  }, [t, pageContext, data]);

  if (data == null) {
    return null;
  }

  return (
    <div>
      <SBPageHeader
        subtitle={data.publisher?.name ?? ""}
        title={data.name ?? ""}
      />
      {data.issues?.edges
        ?.filter(({ node }) => node != null)
        .map(({ node }) => {
          const { id: issueId, number, publishedDate } = node;
          return (
            <div key={issueId}>
              <SBLink to={`${COMIC_INDEX_ISSUE_PATH.replace(":id", issueId)}`}>
                {number} {publishedDate}
              </SBLink>
            </div>
          );
        })}
    </div>
  );
};

const _styles = stylex.create({
  char: {
    backgroundColor: {
      ":hover": "#FFF",
    },
    textAlign: "center",
  },
  chars: {
    display: "grid",
    gridGap: 5,
    gridTemplateColumns: "repeat(26, 1fr)",
    marginTop: 40,
  },
  popularTitles: {
    display: "flex",
    flexFlow: "column",
    flexWrap: "wrap",
    marginTop: 20,
    maxHeight: 300,
  },
  selected: {
    backgroundColor: "#CDCDCD",
  },
});

export default React.memo(ComicIndexTitle);
