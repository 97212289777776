/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AcceptOfferContentQuery } from "src/types/__generated__/AcceptOfferContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import AcceptOfferView from "./AcceptOfferView";

type Props = Readonly<{
  offerId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const AcceptOfferContent = ({ offerId, setOpen }: Props): React.ReactNode => {
  const data = useLazyLoadQuery<AcceptOfferContentQuery>(
    graphql`
      query AcceptOfferContentQuery($id: ID!) {
        ...AcceptOfferView_offer
      }
    `,
    {
      id: offerId ?? "",
    },
  );

  return <AcceptOfferView offer={data} offerId={offerId} setOpen={setOpen} />;
};

export default AcceptOfferContent;
