/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-relay";

import { SBButton, SBModal, SBParagraph } from "src/sbxui";

type Props = Readonly<{
  isOpen: boolean;
  onSuccess: () => void;
  productId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const DeleteInventoryItem = ({
  productId,
  isOpen,
  setOpen,
  onSuccess,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const [commit, isInFlight] = useMutation(graphql`
    mutation DeleteInventoryItemMutation($input: DeleteProductInput!) {
      deleteProduct(deleteProductInput: $input) {
        id
      }
    }
  `);

  const handleClick = (): void => {
    commit({
      onCompleted: () => {
        onSuccess();
      },
      variables: {
        input: {
          productId,
        },
      },
    });
  };

  return (
    <SBModal
      footer={
        <div {...stylex.props(styles.footer)}>
          <SBButton
            loading={isInFlight}
            title={t("portfolio.inventory-item.buttons.delete")}
            onClick={handleClick}
          />
        </div>
      }
      headerText={t("portfolio.inventory-item.delete-modal.title")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div {...stylex.props(styles.modal)}>
        <SBParagraph style={styles.description}>
          {t("portfolio.inventory-item.delete-modal.description")}
        </SBParagraph>
      </div>
    </SBModal>
  );
};

const styles = stylex.create({
  description: {
    marginBottom: 0,
  },
  footer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  modal: {
    padding: 16,
  },
});

export default DeleteInventoryItem;
