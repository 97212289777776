/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { Suspense } from "react";

import ProfileLinkContent from "./ProfileLinkContent";
import ProfileLinkErrorBoundary from "./ProfileLinkErrorBoundary";
import ProfileLinkFallback from "./ProfileLinkFallback";

const ProfileLink = (): React.ReactNode | null => {
  return (
    <ProfileLinkErrorBoundary>
      <Suspense fallback={<ProfileLinkFallback />}>
        <ProfileLinkContent />
      </Suspense>
    </ProfileLinkErrorBoundary>
  );
};

export default React.memo(ProfileLink);
