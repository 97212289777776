/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountPasswordQuery } from "src/types/__generated__/QueriesAccountPasswordQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import AccountPage from "../AccountPage";
import AccountPasswordContent from "./AccountPasswordContent";

const AccountPassword = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesAccountPasswordQuery>;

  return (
    <AccountPage
      docTitle={t("account.password.title")}
      innerHeading={t("account.nav.password")}
    >
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <AccountPasswordContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </AccountPage>
  );
};

export default AccountPassword;
