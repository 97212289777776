/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountStoreSettingsQuery } from "src/types/__generated__/QueriesSellerAccountStoreSettingsQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { SellerAccountStoreSettingsQuery } from "src/app/router/Queries";

import SellerAccountStoreSettingsView from "./SellerAccountStoreSettingsView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesSellerAccountStoreSettingsQuery>;
}>;

const SellerAccountStoreSettingsContent = ({
  queryRef,
}: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(SellerAccountStoreSettingsQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <SellerAccountStoreSettingsView queryKey={user} />;
};

export default SellerAccountStoreSettingsContent;
