/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountAuctionBidsActive_user$key } from "src/types/__generated__/AccountAuctionBidsActive_user.graphql";
import type { AccountAuctionBidsActivePaginationQuery } from "src/types/__generated__/AccountAuctionBidsActivePaginationQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import AuctionItem from "src/app/pages/auction/AuctionItem";
import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator, SBInfoMessage } from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountAuctionBidsActive_user$key;
}>;

const AccountAuctionBidsActive = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment<
    AccountAuctionBidsActivePaginationQuery,
    AccountAuctionBidsActive_user$key
  >(
    graphql`
      fragment AccountAuctionBidsActive_user on User
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 20 }
        orderBy: {
          type: AuctionBidOrderByInput
          defaultValue: { createdAt: DESC }
        }
      )
      @refetchable(queryName: "AccountAuctionBidsActivePaginationQuery") {
        activeAuctionBids: auctionBids(
          after: $cursor
          first: $count
          orderBy: $orderBy
          where: { auctionStatus: OPEN }
        ) @connection(key: "AccountAuctionBidsActive_activeAuctionBids") {
          __id
          edges {
            node {
              lot {
                ...AuctionItem_auctionLot
              }
              id
            }
          }
        }
      }
    `,
    queryKey,
  );

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  const auctionBids = data?.activeAuctionBids?.edges ?? [];

  if (auctionBids.length === 0) {
    return (
      <SBInfoMessage
        message={t("account.auction-bids.no-auction-bids")}
        style={styles.infoMessage}
      />
    );
  }

  return (
    <>
      <ul {...stylex.props(styles.lots)}>
        {auctionBids?.map(({ node }) => (
          <AuctionItem key={node.id} queryKey={node.lot} />
        ))}
      </ul>

      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  infoMessage: {
    marginTop: 16,
  },
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
  lots: {
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    marginTop: 20,
    padding: 0,
  },
});

export default AccountAuctionBidsActive;
