/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Country } from "src/types/__generated__/InvoiceView_invoice.graphql";

export function countryFromLanguage(language: string): Country {
  const test = language.split("-")[1];
  switch (test) {
    case "CA":
    case "GB":
      return test;
    default:
      return "US";
  }
}
