/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { InvoicePaymentMethod } from "src/types/__generated__/InvoiceView_invoice.graphql";
import type { ProductDetailActionsBuyerAccepted_product$key } from "src/types/__generated__/ProductDetailActionsBuyerAccepted_product.graphql";
import type { ProductDetailActionsBuyerAcceptedMutation } from "src/types/__generated__/ProductDetailActionsBuyerAcceptedMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";

import { INVOICE_PATH } from "src/app/router/Router";
import { useCountdownTimer } from "src/hooks";
import { ButtonType, ButtonVariation, SBButton, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

type Props = Readonly<{
  achMinimumValue: number;
  queryKey: ProductDetailActionsBuyerAccepted_product$key;
}>;

const ProductDetailActionsBuyerAccepted = ({
  queryKey,
  achMinimumValue,
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [commit, isInFlight] =
    useMutation<ProductDetailActionsBuyerAcceptedMutation>(graphql`
      mutation ProductDetailActionsBuyerAcceptedMutation(
        $input: CreateBuyNowInvoiceInput!
      ) {
        createBuyNowInvoice(createBuyNowInvoiceInput: $input) {
          ... on Invoice {
            id
          }
        }
      }
    `);

  const data = useFragment(
    graphql`
      fragment ProductDetailActionsBuyerAccepted_product on Product {
        id
        acceptedOffer: offers(
          first: 1
          orderBy: { expiresAt: DESC }
          where: { status: ACCEPTED }
        ) {
          edges {
            node {
              id
              price
              currency
              expiresAt
              state
            }
          }
        }
      }
    `,
    queryKey,
  );

  const productId = data.id;
  const offer = data.acceptedOffer?.edges?.[0]?.node;
  const offerPrice = offer?.price ?? 0;
  const currency = offer?.currency ?? "USD";
  const expiresAt = offer?.expiresAt;
  const expiry = Number(expiresAt);
  const { expiresIn } = useCountdownTimer(expiry);

  const paymentMethod: InvoicePaymentMethod =
    offerPrice >= achMinimumValue ? "ACH" : "CREDIT_CARD";

  const handleClickBuy = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlight) {
      return;
    }

    commit({
      onCompleted(response) {
        navigate(
          INVOICE_PATH.replace(":id", response.createBuyNowInvoice?.id ?? ""),
        );
      },

      variables: {
        input: {
          paymentMethod,
          productId,
        },
      },
    });
  };

  return (
    <>
      <div {...stylex.props(styles.offerInfo)}>
        <SBParagraph style={styles.title}>
          {t("product.status.accepted.title")}
        </SBParagraph>
        <SBParagraph style={styles.offerPrice}>
          {formatMoney(offerPrice, currency, i18n.language)}
        </SBParagraph>{" "}
        <SBParagraph style={styles.expiry}>{expiresIn}</SBParagraph>{" "}
        <SBParagraph>{t("product.status.accepted.subtext")}</SBParagraph>{" "}
      </div>
      <SBButton
        block={true}
        loading={isInFlight}
        style={styles.button}
        title={t("product.header.buttons.checkout-now")}
        type={ButtonType.Button}
        variation={ButtonVariation.Emphasis}
        onClick={handleClickBuy}
      />
    </>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },
  expiry: {
    color: colors.colorEmphasis,
  },
  inApp: {
    alignSelf: "center",
    fontSize: 14,
    marginTop: 16,
  },
  offerInfo: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 16,
  },
  offerPrice: {
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 72,
  },
  title: {
    marginBottom: 0,
  },
});

export default ProductDetailActionsBuyerAccepted;
