/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionLotView_auctionLot$key } from "src/types/__generated__/AuctionLotView_auctionLot.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useSubscription } from "react-relay";

import CensusTable from "src/app/components/census/CensusTable";
import HistoricalSalesTable from "src/app/components/historical-sales/HistoricalSalesTable";
import { PageContext } from "src/app/context/page";
import { getProductGrade, getProductTitle } from "src/app/utils/product.util";
import { SBImageCarousel } from "src/sbxui";

import AuctionLotHeader from "./AuctionLotHeader";
import AuctionLotMeta from "./AuctionLotMeta";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

const POLL_TIME_MS = 500;

const AuctionLotViewBidSubscription = graphql`
  subscription AuctionLotViewBidSubscription(
    $input: BidAcceptedSubscriptionInput!
  ) {
    bidAcceptedSubscription(bidAcceptedSubscriptionInput: $input) {
      ...AuctionLotView_auctionLot
    }
  }
`;

const AuctionLotViewExpirySubscription = graphql`
  subscription AuctionLotViewExpirySubscription($input: AuctionLotIdInput!) {
    auctionLotExpirySubscription(auctionLotExpirySubscription: $input) {
      ...AuctionLotView_auctionLot
    }
  }
`;

type Props = Readonly<{
  queryKey: AuctionLotView_auctionLot$key;
}>;

const AuctionLotView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment AuctionLotView_auctionLot on AuctionLot {
        id
        currentBidAmount
        currentBidCurrency
        product {
          ...AuctionLotHeader_product
          ...AuctionLotMeta_product
          ...HistoricalSalesTable_product
          images {
            edges {
              node {
                url(quality: 100, webp: true, width: 1000)
              }
            }
          }
          comicDetails {
            ...CensusTable_comicDetail
            title
            publisher
            year
            number
            gradingAuthority
            grade
            keyComments
            artComments
          }
        }
      }
    `,
    queryKey,
  );

  const auctionLotId = data.id;

  const comicDetails = data.product?.comicDetails;
  const images = data.product?.images;
  const { title, grade, gradingAuthority, number } = comicDetails ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrls = imageEdges.map(({ node }) => node.url);

  const productTitle = `${getProductTitle({
    number: number ?? "",
    title: title ?? "",
  })} ${getProductGrade({ grade: grade ?? 0, gradingAuthority: t(`gradingAuthority.${gradingAuthority ?? "UNKNOWN"}`) })}`;

  const pageContext = useContext(PageContext);

  const bidConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionLotViewBidSubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  const expiryConfig = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: AuctionLotViewExpirySubscription,
      variables: {
        input: {
          auctionLotId,
        },
      },
    }),
    [auctionLotId],
  );

  useSubscription(bidConfig);
  useSubscription(expiryConfig);

  useEffect(() => {
    pageContext?.setTitle(
      t("auctions.lot.documentTitle", {
        product: productTitle,
      }),
    );
  }, [productTitle, pageContext, t]);

  return (
    <div {...stylex.props(styles.product)}>
      <div {...stylex.props(styles.columns)}>
        <div {...stylex.props(styles.columnLeftMobile)}>
          {data.product ? <AuctionLotHeader queryKey={data.product} /> : null}
          <div {...stylex.props(styles.carousel)}>
            <SBImageCarousel imageUrls={imageUrls} />
          </div>
          {data.product ? <AuctionLotMeta queryKey={data.product} /> : null}
        </div>
        <div {...stylex.props(styles.columnRightMobile)}>
          {data.product ? (
            <HistoricalSalesTable queryKey={data.product} />
          ) : null}
          {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
        </div>
        <div {...stylex.props(styles.columnLeft)}>
          <div {...stylex.props(styles.carousel)}>
            <SBImageCarousel imageUrls={imageUrls} />
          </div>
          {data.product ? <AuctionLotMeta queryKey={data.product} /> : null}
        </div>
        <div {...stylex.props(styles.columnRight)}>
          {data.product ? <AuctionLotHeader queryKey={data.product} /> : null}
          {data.product ? (
            <HistoricalSalesTable queryKey={data.product} />
          ) : null}
          {comicDetails ? <CensusTable queryKey={comicDetails} /> : null}
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  backButton: {
    marginBottom: 40,
  },
  carousel: {
    marginBottom: 24,
    position: "relative",
  },
  census: {
    marginTop: 40,
  },
  censusTable: {
    marginTop: 10,
  },
  columnLeft: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "grid",
      default: "block",
    },
    gridArea: {
      default: "1 / 1 / 2 / 6",
    },
    marginBottom: 24,
  },
  columnLeftMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columnRight: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "block",
      default: "block",
    },
    gridArea: "1 / 7 / 2 / 11",
    marginBottom: 24,
  },
  columnRightMobile: {
    display: {
      [MOBILE]: "block",
      [TABLET]: "none",
      default: "none",
    },
  },
  columns: {
    display: "grid",
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(10, 1fr)",
      default: "repeat(10, 1fr)",
    },
    margin: 0,
  },
  favorite: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  product: {
    marginBottom: 96,
  },
});

export default AuctionLotView;
