/**
 * @generated SignedSource<<e6524fe1f8680758ba9bff56eeb9b147>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesAccountInvoicesQuery$variables = Record<PropertyKey, never>;
export type QueriesAccountInvoicesQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"AccountInvoicesView_user">;
    } | null | undefined;
  };
};
export type QueriesAccountInvoicesQuery = {
  response: QueriesAccountInvoicesQuery$data;
  variables: QueriesAccountInvoicesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "kind": "Literal",
  "name": "first",
  "value": 20
},
v1 = {
  "kind": "Literal",
  "name": "orderBy",
  "value": {
    "createdAt": "DESC"
  }
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "status": [
        "OPEN",
        "TEMP",
        "PROCESSING",
        "REQUIRES_ACTION"
      ]
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "InvoicesEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Invoice",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currency",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "surrogateId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductsConnection",
            "kind": "LinkedField",
            "name": "items",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "count",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ProductsEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Product",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "first",
                            "value": 1
                          }
                        ],
                        "concreteType": "ImagesConnection",
                        "kind": "LinkedField",
                        "name": "images",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImagesEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Image",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": [
                                      {
                                        "kind": "Literal",
                                        "name": "quality",
                                        "value": 100
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "webp",
                                        "value": true
                                      },
                                      {
                                        "kind": "Literal",
                                        "name": "width",
                                        "value": 500
                                      }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": "url(quality:100,webp:true,width:500)"
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": "images(first:1)"
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "PageInfo",
    "kind": "LinkedField",
    "name": "pageInfo",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endCursor",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hasNextPage",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "kind": "ClientExtension",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__id",
        "storageKey": null
      }
    ]
  }
],
v5 = [
  "orderBy",
  "where"
],
v6 = [
  (v0/*: any*/),
  (v1/*: any*/),
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "status": [
        "EXPIRED",
        "CLOSED",
        "PAID"
      ]
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesAccountInvoicesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AccountInvoicesView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesAccountInvoicesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "activeInvoices",
                "args": (v2/*: any*/),
                "concreteType": "InvoicesConnection",
                "kind": "LinkedField",
                "name": "invoices",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "invoices(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"status\":[\"OPEN\",\"TEMP\",\"PROCESSING\",\"REQUIRES_ACTION\"]})"
              },
              {
                "alias": "activeInvoices",
                "args": (v2/*: any*/),
                "filters": (v5/*: any*/),
                "handle": "connection",
                "key": "AccountInvoicesActive_activeInvoices",
                "kind": "LinkedHandle",
                "name": "invoices"
              },
              (v3/*: any*/),
              {
                "alias": "closedInvoices",
                "args": (v6/*: any*/),
                "concreteType": "InvoicesConnection",
                "kind": "LinkedField",
                "name": "invoices",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": "invoices(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"status\":[\"EXPIRED\",\"CLOSED\",\"PAID\"]})"
              },
              {
                "alias": "closedInvoices",
                "args": (v6/*: any*/),
                "filters": (v5/*: any*/),
                "handle": "connection",
                "key": "AccountInvoicesClosed_closedInvoices",
                "kind": "LinkedHandle",
                "name": "invoices"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bf93a873975f3b12e7bb8d9ff9dc1c49",
    "id": null,
    "metadata": {},
    "name": "QueriesAccountInvoicesQuery",
    "operationKind": "query",
    "text": "query QueriesAccountInvoicesQuery {\n  viewer {\n    user {\n      ...AccountInvoicesView_user\n      id\n    }\n  }\n}\n\nfragment AccountInvoiceCard_invoice on Invoice {\n  id\n  createdAt\n  total\n  currency\n  status\n  surrogateId\n  items {\n    count\n    edges {\n      node {\n        images(first: 1) {\n          edges {\n            node {\n              url(quality: 100, width: 500, webp: true)\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n\nfragment AccountInvoicesActive_user on User {\n  activeInvoices: invoices(first: 20, orderBy: {createdAt: DESC}, where: {status: [OPEN, TEMP, PROCESSING, REQUIRES_ACTION]}) {\n    edges {\n      node {\n        ...AccountInvoiceCard_invoice\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment AccountInvoicesClosed_user on User {\n  closedInvoices: invoices(first: 20, orderBy: {createdAt: DESC}, where: {status: [EXPIRED, CLOSED, PAID]}) {\n    edges {\n      node {\n        ...AccountInvoiceCard_invoice\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n\nfragment AccountInvoicesView_user on User {\n  ...AccountInvoicesActive_user\n  ...AccountInvoicesClosed_user\n}\n"
  }
};
})();

(node as any).hash = "914cc4d985bc36b810817b61547134e5";

export default node;
