/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { MakeOfferContentQuery } from "src/types/__generated__/MakeOfferContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import MakeOfferView from "./MakeOfferView";

type Props = Readonly<{
  productId: string | null | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const MakeOfferContent = ({
  productId,
  setOpen,
}: Props): React.ReactNode | null => {
  const data = useLazyLoadQuery<MakeOfferContentQuery>(
    graphql`
      query MakeOfferContentQuery($id: ID!) {
        ...MakeOfferView_product
      }
    `,
    {
      id: productId ?? "",
    },
  );

  return <MakeOfferView product={data} setOpen={setOpen} />;
};

export default MakeOfferContent;
