/**
 * @generated SignedSource<<3fc1f98164c415f7ba3dbbc60528fa09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesPrivacyQuery$variables = Record<PropertyKey, never>;
export type QueriesPrivacyQuery$data = {
  readonly privacy: {
    readonly " $fragmentSpreads": FragmentRefs<"PrivacyView_document">;
  } | null | undefined;
};
export type QueriesPrivacyQuery = {
  response: QueriesPrivacyQuery$data;
  variables: QueriesPrivacyQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesPrivacyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "privacy",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PrivacyView_document"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesPrivacyQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "privacy",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "body",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "20cfcda841691b7e007a291f8e17f667",
    "id": null,
    "metadata": {},
    "name": "QueriesPrivacyQuery",
    "operationKind": "query",
    "text": "query QueriesPrivacyQuery {\n  privacy {\n    ...PrivacyView_document\n    id\n  }\n}\n\nfragment PrivacyView_document on Document {\n  id\n  title\n  body\n  publishedAt\n}\n"
  }
};

(node as any).hash = "99134dad24707efdbfa1c90af8aac5ec";

export default node;
