/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AuctionLotDescription_auctionLot$key } from "src/types/__generated__/AuctionLotDescription_auctionLot.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SBCollapsibleText } from "src/sbxui/SBCollapsibleText";

type Props = Readonly<{
  queryKey: AuctionLotDescription_auctionLot$key;
}>;

const AuctionLotDescription = ({ queryKey }: Props): React.ReactNode | null => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment AuctionLotDescription_auctionLot on AuctionLot {
        description
      }
    `,
    queryKey,
  );

  const description = data.description ?? null;

  if (description == null) {
    return null;
  }

  return (
    <div {...stylex.props(styles.container)}>
      <SBCollapsibleText
        content={description}
        title={t("auction.lot.description.title")}
      />
    </div>
  );
};

export default AuctionLotDescription;

const styles = stylex.create({
  container: {
    marginBottom: 24,
    marginTop: 24,
  },
});
