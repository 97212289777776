/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { EditProductContentQuery } from "src/types/__generated__/EditProductContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import EditProductView from "./EditProductView";

type Props = Readonly<{
  productId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: (title: string) => void;
}>;

const EditProductContent = ({
  productId,
  setOpen,
  setTitle,
}: Props): React.ReactNode => {
  const data = useLazyLoadQuery<EditProductContentQuery>(
    graphql`
      query EditProductContentQuery($id: ID!) {
        hotConfigs {
          data
        }
        node(id: $id) {
          ...EditProductView_product
        }
      }
    `,
    {
      id: productId ?? "",
    },
  );

  const gradedBookMinimumValue = Number.parseInt(
    data.hotConfigs.data?.GRADED_BOOK_MINIMUM_VALUE ?? "0",
    10,
  );

  const rawBookMinimumValue = Number.parseInt(
    data.hotConfigs.data?.RAW_BOOK_MINIMUM_VALUE ?? "0",
    10,
  );

  if (data.node == null) {
    return null;
  }

  return (
    <EditProductView
      gradedBookMinimumValue={gradedBookMinimumValue}
      queryKey={data.node}
      rawBookMinimumValue={rawBookMinimumValue}
      setOpen={setOpen}
      setTitle={setTitle}
    />
  );
};

export default EditProductContent;
