/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import {
  useCallback,
  useContext,
  useEffect,
  useState,
  useTransition,
} from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { VerticalSearchContext } from "src/app/context/vertical-search";
import {
  ARTICLES_DETAIL_PATH,
  ARTICLES_PATH,
  AUCTION_LOT_PATH,
  AUCTION_PATH,
  AUCTIONS_PATH,
  FAQS_PATH,
  PRODUCT_DETAIL_PATH,
  SELLER_PATH,
  SHOP_PATH,
} from "src/app/router/Router";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

enum Tabs {
  Articles = "articles",
  Auctions = "auctions",
  Faqs = "faqs",
  None = "none",
  Shop = "shop",
}

const TopNavigationDesktop = (): React.ReactNode => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isPending, startTransition] = useTransition();

  const { resetSearch } = useContext(VerticalSearchContext);

  const getCurrentTab = useCallback(() => {
    if (
      pathname.startsWith(SHOP_PATH) ||
      pathname.startsWith(PRODUCT_DETAIL_PATH.replace(":id", "")) ||
      pathname.startsWith(SELLER_PATH.replace(":shopUrl", ""))
    ) {
      return Tabs.Shop;
    }

    if (pathname.startsWith(FAQS_PATH)) {
      return Tabs.Faqs;
    }

    if (
      pathname.startsWith(AUCTIONS_PATH) ||
      pathname.startsWith(AUCTION_PATH.replace(":id", "")) ||
      pathname.startsWith(AUCTION_LOT_PATH.replace(":id", ""))
    ) {
      return Tabs.Auctions;
    }

    if (pathname.startsWith(FAQS_PATH)) {
      return Tabs.Faqs;
    }

    if (
      pathname.startsWith(ARTICLES_PATH) ||
      pathname.startsWith(ARTICLES_DETAIL_PATH.replace(":slug", ""))
    ) {
      return Tabs.Articles;
    }

    return Tabs.None;
  }, [pathname]);

  const [current, setCurrent] = useState(() => getCurrentTab());

  const handleClickTab = (path: string) => (event: React.SyntheticEvent) => {
    event.preventDefault();
    startTransition(() => {
      resetSearch();
      navigate(path);
    });
  };

  useEffect(() => {
    const currentTab = getCurrentTab();
    if (currentTab !== current) {
      setCurrent(currentTab);
    }
  }, [current, getCurrentTab]);

  const tabs = [
    {
      id: Tabs.Auctions,
      isVisible: "true",
      label: t("auctions.nav-title"),
      path: AUCTIONS_PATH,
    },
    {
      id: Tabs.Shop,
      isVisible: true,
      label: t("shop.nav-title"),
      path: SHOP_PATH,
    },
    {
      id: Tabs.Articles,
      isVisible: true,
      label: t("blog.nav-title"),
      path: ARTICLES_PATH,
    },
    {
      id: Tabs.Faqs,
      isVisible: true,
      label: t("faqs.nav-title"),
      path: FAQS_PATH,
    },
  ];

  return (
    <nav {...stylex.props(styles.root)} aria-label={t("home.nav-label")}>
      {tabs
        .filter(({ isVisible }) => isVisible)
        .map(({ id, label, path }, ii) => (
          <a
            {...stylex.props(
              auto,
              styles.item,
              ii === 0 && styles.itemFirst,
              current === id && styles.current,
              current === id && isPending && styles.isPending,
            )}
            key={id}
            aria-current={current ? "page" : undefined}
            href={path}
            onClick={handleClickTab(path)}
          >
            {label}
          </a>
        ))}
    </nav>
  );
};

const styles = stylex.create({
  current: {
    backgroundColor: {
      ":hover": colors.topNavigationTabActiveBackgroundColor,
      default: colors.topNavigationTabActiveBackgroundColor,
    },
    color: {
      ":hover": colors.topNavigationTabActiveColor,
      default: colors.topNavigationTabActiveColor,
    },
  },
  isPending: {
    opacity: 0.4,
  },
  item: {
    backgroundColor: {
      ":hover": colors.topNavigationTabHoverBackgroundColor,
      default: colors.topNavigationTabBackgroundColor,
    },
    borderRadius: 32,
    color: {
      ":hover": colors.topNavigationTabHoverColor,
      default: colors.topNavigationTabColor,
    },
    fontSize: 16,
    marginLeft: 8,
    outline: {
      ":focus-visible": colors.outline,
    },
    paddingBlock: 8,
    paddingInline: 16,
    textAlign: "center",
    textDecorationLine: "none",
  },
  itemFirst: {
    marginLeft: 0,
  },
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
});

export default React.memo(TopNavigationDesktop);
