/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ClientId } from "src/types/__generated__/useLoginMutation.graphql";

export enum StorageKey {
  Anonymous = "sbx.anonymous",
  FirstLaunch = "sbx.first-launch",
  ListingNotesPreference = "sbx.listing-notes-preference",
  PortfolioNotesPreference = "sbx.portfolio-notes-preference",
  SubmissionPreference = "sbx.submission-preference",
  User = "sbx.user",
}

export const DEFAULT_CURRENCY = "USD";
export const SHORTBOXED_CDN_BASE_URL = "https://www-cdn.shortboxed.com";

export const API_CLIENT_ID = (process.env.REACT_APP_API_CLIENT_ID ??
  "WEB") as ClientId;

export const SHORTBOXED = "Shortboxed";
export const CARBON = "Carbón Agency";

export const GOOGLE_PLAY_URL =
  "https://play.google.com/store/apps/details?id=com.shortboxed&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1";
export const APP_STORE_URL =
  "https://apps.apple.com/us/app/shortboxed/id1497091986?itsct=apps_box_link&itscg=30200";
export const GOOGLE_PLAY_IMG = `${SHORTBOXED_CDN_BASE_URL}/google.webp`;
export const APP_STORE_IMG = `${SHORTBOXED_CDN_BASE_URL}/appstore.webp`;

export const MOBILE = "@media (max-width: 767px)";
export const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";
