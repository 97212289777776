/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Currency } from "src/types/__generated__/SearchResultCard_typesenseSearch.graphql";

import { countryFromLanguage } from "src/utils";

function doesCurrencyMatchCountry(
  currency: Currency,
  country: string,
): boolean {
  switch (currency) {
    case "AUD":
      return country === "AU";
    case "CAD":
      return country === "CA";
    case "EUR":
      // https://european-union.europa.eu/institutions-law-budget/euro/countries-using-euro_en
      return [
        "AT",
        "BE",
        "HR",
        "CY",
        "EE",
        "FI",
        "FR",
        "DE",
        "GR",
        "IE",
        "IT",
        "LV",
        "LT",
        "LU",
        "MT",
        "NL",
        "PT",
        "SK",
        "SI",
        "ES",
        "BG",
        "CZ",
        "HU",
        "PL",
        "RO",
        "SE",
      ].includes(country);
    case "GBP":
      return country === "GB";
    case "USD":
      return country === "US";
    default:
      return false;
  }
}

export function formatMoney(
  price: number,
  currency: Currency,
  language: string,
  showDecimal = false,
): string {
  const country = countryFromLanguage(language);

  let format = "%u%n";
  let negativeFormat = "\u2212%u%n";
  let unit = "";

  switch (currency) {
    case "AUD":
    case "USD":
    case "CAD":
      unit = "$";
      break;
    case "EUR":
      unit = "€";
      break;
    case "GBP":
      unit = "£";
      break;
    case "%future added value":
      unit = "X";
  }

  if (unit === "" || !doesCurrencyMatchCountry(currency, country)) {
    format = "%n\u00a0%u";
    negativeFormat = "\u2212%n\u00a0%u";
    unit = currency;
  }

  const number = new Intl.NumberFormat(language, {
    currency,
    ...(showDecimal
      ? {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }
      : {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }),
    style: "decimal",
  }).format(price);

  const result = (price < 0 ? negativeFormat : format)
    .replace("%n", number)
    .replace("%u", unit);

  return result;
}
