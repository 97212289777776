/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountStatsQuery } from "src/types/__generated__/QueriesSellerAccountStatsQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { SellerAccountStatsQuery } from "src/app/router/Queries";

import SellerAccountStatsView from "./SellerAccountStatsView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesSellerAccountStatsQuery>;
}>;

const SellerAccountStatsContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(SellerAccountStatsQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <SellerAccountStatsView queryKey={user} />;
};

export default SellerAccountStatsContent;
