/**
 * @generated SignedSource<<001aa0c5d298f19139089178d8de6e44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type SellerStatus = "APPROVED" | "DEACTIVATED" | "IN_REVIEW" | "SUSPENDED" | "VACATION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SellerAccountVacationModeView_user$data = {
  readonly id: string;
  readonly sellerStatus: SellerStatus | null | undefined;
  readonly vacationSince: string | null | undefined;
  readonly " $fragmentType": "SellerAccountVacationModeView_user";
};
export type SellerAccountVacationModeView_user$key = {
  readonly " $data"?: SellerAccountVacationModeView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SellerAccountVacationModeView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SellerAccountVacationModeView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sellerStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vacationSince",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "e8af9caf1db4d90494c9e5ba509324e7";

export default node;
