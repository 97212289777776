/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import * as stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  fmvRank: number | null | undefined;
  style?: StyleXStyles;
}>;

export const SBFmvBadge = React.memo(({ fmvRank, style }: Props) => {
  const { t } = useTranslation();

  let fmvRankLabel = null;
  let fmvRankAltLabel = null;
  let fmvRankStyle = null;
  switch (fmvRank) {
    case 1:
      fmvRankLabel = t("fmv.fmvRank.great");
      fmvRankAltLabel = t("fmv.fmvRankAlt.great");
      fmvRankStyle = styles.fmvRankGreat;
      break;
    case 2:
      fmvRankLabel = t("fmv.fmvRank.good");
      fmvRankAltLabel = t("fmv.fmvRankAlt.good");
      fmvRankStyle = styles.fmvRankGood;
      break;
    case 4:
      fmvRankLabel = t("fmv.fmvRank.poor");
      fmvRankAltLabel = t("fmv.fmvRankAlt.poor");
      fmvRankStyle = styles.fmvRankPoor;
      break;
    case 3:
    default:
      break;
  }

  return (
    fmvRankLabel != null &&
    fmvRankAltLabel != null && (
      <span
        {...stylex.props(styles.fmvRank, fmvRankStyle, style)}
        aria-label={fmvRankAltLabel}
      >
        {fmvRankLabel}
      </span>
    )
  );
});

const styles = stylex.create({
  fmvRank: {
    backgroundColor: colors.fmvRankDefaultBackgroundColor,
    borderRadius: 12,
    color: colors.fmvRankDefaultColor,
    display: "inline-flex",
    fontSize: 12,
    height: 24,
    lineHeight: "24px",
    paddingInline: 12,
    textAlign: "center",
  },
  fmvRankGood: {
    backgroundColor: colors.fmvRankGoodBackgroundColor,
    color: colors.fmvRankGoodColor,
  },
  fmvRankGreat: {
    backgroundColor: colors.fmvRankGreatBackgroundColor,
    color: colors.fmvRankGreatColor,
  },
  fmvRankPoor: {
    backgroundColor: colors.fmvRankPoorBackgroundColor,
    color: colors.fmvRankPoorColor,
  },
});
