/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountStoreSettingsView_user$key } from "src/types/__generated__/SellerAccountStoreSettingsView_user.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useFragment } from "react-relay";

import SellerAccountStoreLogoField from "./SellerAccountStoreLogoField";
import SellerAccountStoreNameField from "./SellerAccountStoreNameField";
import SellerAccountStoreUrlField from "./SellerAccountStoreUrlField";

type Props = Readonly<{
  queryKey: SellerAccountStoreSettingsView_user$key;
}>;

const SellerAccountStoreSettingsView = ({
  queryKey,
}: Props): React.ReactNode => {
  const data = useFragment(
    graphql`
      fragment SellerAccountStoreSettingsView_user on User {
        ...SellerAccountStoreNameField_user
        ...SellerAccountStoreUrlField_user
        ...SellerAccountStoreLogoField_user
        id
        shopLogoUrl
        shopUrl
        shopUrlQrCode
      }
    `,
    queryKey,
  );

  return (
    <div {...stylex.props(styles.root)}>
      <div {...stylex.props(styles.field)}>
        <SellerAccountStoreNameField queryKey={data} />
      </div>
      <div {...stylex.props(styles.field)}>
        <SellerAccountStoreUrlField queryKey={data} />
      </div>
      <div {...stylex.props(styles.field)}>
        <SellerAccountStoreLogoField queryKey={data} />
      </div>
    </div>
  );
};

const styles = stylex.create({
  field: {
    marginBottom: 24,
  },
  root: {
    marginTop: 48,
  },
});

export default SellerAccountStoreSettingsView;
