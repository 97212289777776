/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type * as React from "react";
import type { MenuItemConfig } from "src/sbxui";

import stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import { useContext, useEffect, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";

import {
  DEFAULT_SALES_CHANNEL,
  DEFAULT_SORT,
  DEFAULT_STATUS,
  PortfolioContext,
  PortfolioOption,
} from "src/app/context/portfolio";
import { MenuPosition, SBActivityIndicator, SBIcon, SBMenu } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import PortfolioFilterModal from "./PortfolioFilterModal";
import PortfolioSortModal from "./PortfolioSortModal";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

enum Modal {
  Filter,
  Sort,
  None,
}

const PortfolioSearch = (): React.ReactNode => {
  const { t } = useTranslation();

  const [term, setTerm] = useState("");
  const [selected, setSelected] = useState<PortfolioOption>(
    PortfolioOption.All,
  );
  const [modal, setModal] = useState<Modal>(Modal.None);

  const {
    gradingAuthority,
    isSearching,
    resetSearch,
    salesChannel,
    setSalesChannel,
    setStatus,
    setTitle,
    status,
  } = useContext(PortfolioContext);

  const [isPending, startTransition] = useTransition();

  const handleClickOption =
    (option: PortfolioOption) => (_event: React.SyntheticEvent) => {
      switch (option) {
        case PortfolioOption.All:
          setStatus(DEFAULT_STATUS);
          setSalesChannel(DEFAULT_SALES_CHANNEL);
          break;
        case PortfolioOption.Auction:
          setStatus(["FOR_SALE", "PENDING_PURCHASE"]);
          setSalesChannel(["AUCTION"]);
          break;
        case PortfolioOption.ForSale:
          setStatus(["FOR_SALE", "PENDING_PURCHASE"]);
          setSalesChannel(["SHOP"]);
          break;
        case PortfolioOption.Personal:
          setStatus(["SUBMITTED", "UNAVAILABLE"]);
          setSalesChannel(["AUCTION", "SHOP"]);
          break;
      }
    };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTerm(event.currentTarget.value);
  };

  const handleSearchSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    startTransition(() => {
      setTitle(term);
    });
  };

  const handleClickClear = (_event: React.SyntheticEvent) => {
    setTerm("");
    startTransition(() => {
      setTitle("");
    });
  };

  const handleSortClick = (_event: React.SyntheticEvent) => {
    setModal(Modal.Sort);
  };

  const handleSetSortModalOpen = () => {
    setModal(modal === Modal.Sort ? Modal.None : Modal.Sort);
  };

  const handleFilterClick = (_event: React.SyntheticEvent) => {
    setModal(Modal.Filter);
  };

  const handleSetFilterModalOpen = () => {
    setModal(modal === Modal.Filter ? Modal.None : Modal.Filter);
  };

  const handleResetClick = (_event: React.SyntheticEvent) => {
    resetSearch(false);
  };

  useEffect(() => {
    if (
      status.includes("FOR_SALE") &&
      status.includes("PENDING_PURCHASE") &&
      status.includes("SUBMITTED") &&
      status.includes("UNAVAILABLE") &&
      salesChannel.includes("AUCTION") &&
      salesChannel.includes("SHOP")
    ) {
      setSelected(PortfolioOption.All);
    } else if (
      status.includes("FOR_SALE") &&
      status.includes("PENDING_PURCHASE") &&
      salesChannel.includes("AUCTION") &&
      !salesChannel.includes("SHOP")
    ) {
      setSelected(PortfolioOption.Auction);
    } else if (
      status.includes("FOR_SALE") &&
      status.includes("PENDING_PURCHASE") &&
      !salesChannel.includes("AUCTION") &&
      salesChannel.includes("SHOP")
    ) {
      setSelected(PortfolioOption.ForSale);
    } else if (
      status.includes("SUBMITTED") &&
      status.includes("UNAVAILABLE") &&
      salesChannel.includes("AUCTION") &&
      salesChannel.includes("SHOP")
    ) {
      setSelected(PortfolioOption.Personal);
    }
  }, [salesChannel, status]);

  const sort = DEFAULT_SORT;
  const count = gradingAuthority == null ? 0 : 1;
  const filterKey = `${gradingAuthority == null ? "" : gradingAuthority}`;

  const items: MenuItemConfig[] = [
    {
      id: "all",
      label: t("portfolio.header.all"),
      onClick: handleClickOption(PortfolioOption.All),
    },
    {
      id: "shop",
      label: t("portfolio.header.for-sale"),
      onClick: handleClickOption(PortfolioOption.ForSale),
    },
    {
      id: "auction",
      label: t("portfolio.header.auction"),
      onClick: handleClickOption(PortfolioOption.Auction),
    },
    {
      id: "personal",
      label: t("portfolio.header.personal"),
      onClick: handleClickOption(PortfolioOption.Personal),
    },
  ];

  return (
    <>
      <div {...stylex.props(styles.root)}>
        <SBMenu
          button={
            <div {...stylex.props(styles.salesChannel)}>
              <SBIcon
                aria-hidden="true"
                containerStyle={styles.salesChannelIconContainer}
                fill={false}
                icon="inventory_2"
                style={styles.salesChannelIcon}
              />
              <span {...stylex.props(auto, styles.salesChannelLabel)}>
                {t(`portfolio.header.${kebabCase(selected)}`)}
              </span>
            </div>
          }
          buttonStyle={styles.buttonStyle}
          items={items}
          position={MenuPosition.RIGHT}
        />
        <form
          {...stylex.props(auto, styles.form)}
          role="search"
          onSubmit={handleSearchSubmit}
        >
          <input
            {...stylex.props(auto, styles.input)}
            id="search"
            placeholder={t("portfolio.fields.search")}
            type="text"
            value={term}
            onChange={handleSearchChange}
          />
          {term !== "" && (
            <button
              {...stylex.props(auto, styles.clear)}
              aria-label={t("search.clear-label")}
              type="button"
              onClick={handleClickClear}
            >
              <SBIcon fill={false} icon="cancel" style={styles.cancel} />
            </button>
          )}
          <button
            {...stylex.props(auto, styles.button)}
            aria-label={t("search.button-label")}
            disabled={isSearching || isPending}
            type="submit"
          >
            {isSearching || isPending ? (
              <SBActivityIndicator small={true} style={styles.spinner} />
            ) : (
              <SBIcon icon="search" style={styles.icon} />
            )}
          </button>
        </form>
        <div {...stylex.props(auto, styles.filterSort)}>
          <button
            {...stylex.props(auto, styles.sort)}
            aria-label={t("search.sort")}
            type="button"
            onClick={handleSortClick}
          >
            <span {...stylex.props(styles.sortLabel)}>{t("search.sort")}</span>
            <SBIcon
              aria-hidden="true"
              fill={false}
              icon="sort"
              style={styles.sortIcon}
            />
          </button>
          <button
            {...stylex.props(auto, styles.filter)}
            aria-label={t("search.filter", {
              count,
            })}
            type="button"
            onClick={handleFilterClick}
          >
            <span {...stylex.props(styles.filterLabel)}>
              {t("search.filter", {
                count,
              })}
            </span>
            <SBIcon
              aria-hidden="true"
              containerStyle={styles.filterIconContainer}
              fill={false}
              icon="filter_list"
              style={styles.filterIcon}
            />
          </button>
          {(count > 0 || sort !== DEFAULT_SORT) && (
            <button
              {...stylex.props(auto, styles.reset)}
              aria-label={t("search.reset")}
              type="button"
              onClick={handleResetClick}
            >
              <span {...stylex.props(styles.resetLabel)}>
                {t("search.reset")}
              </span>
              <SBIcon
                aria-hidden="true"
                containerStyle={styles.resetIconContainer}
                fill={false}
                icon="restart_alt"
                style={styles.resetIcon}
              />
            </button>
          )}
        </div>
      </div>
      <PortfolioSortModal
        isOpen={modal === Modal.Sort}
        setOpen={handleSetSortModalOpen}
      />
      <PortfolioFilterModal
        key={filterKey}
        isOpen={modal === Modal.Filter}
        setOpen={handleSetFilterModalOpen}
      />
    </>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: colors.topNavigationSearchButtonBackgroundColor,
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  buttonStyle: {
    whiteSpace: "nowrap",
  },
  cancel: {
    color: colors.topNavigationSearchClearColor,
  },
  clear: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
    width: 40,
  },
  filter: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: {
      [MOBILE]: 0,
      default: 4,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  filterIcon: {
    color: colors.topNavigationSearchClearColor,
  },
  filterIconContainer: {
    marginLeft: 8,
  },
  filterLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  filterSort: {
    alignItems: "center",
    display: "flex",
  },
  form: {
    alignItems: "center",
    backgroundColor: colors.topNavigationSearchBackgroundColor,
    borderColor: colors.topNavigationSearchBorderColor,
    borderRadius: 48,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: colors.topNavigationSearchShadow,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: {
      [MOBILE]: 0,
      default: 16,
    },
    padding: 4,
    paddingLeft: 16,
    width: "100%",
  },
  icon: {
    color: colors.topNavigationSearchButtonColor,
  },
  input: {
    borderWidth: 0,
    height: 40,
    outline: {
      ":hover": "none",
      default: "none",
    },
    width: "100%",
  },
  reset: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: 4,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  resetIcon: {
    color: colors.topNavigationSearchClearColor,
  },
  resetIconContainer: {
    marginLeft: 8,
  },
  resetLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  root: {
    alignItems: "center",
    display: "flex",
    width: "100%",
  },
  salesChannel: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    marginRight: {
      [MOBILE]: 0,
      default: 16,
    },
  },
  salesChannelIcon: {
    color: colors.topNavigationSearchClearColor,
  },
  salesChannelIconContainer: {
    marginRight: 8,
  },
  salesChannelLabel: {
    color: colors.color,
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  sort: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexShrink: 0,
    height: 40,
    justifyContent: "center",
    marginLeft: {
      [MOBILE]: 0,
      default: 4,
    },
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  sortIcon: {
    color: colors.topNavigationSearchClearColor,
    marginLeft: 8,
  },
  sortLabel: {
    display: {
      [MOBILE]: "none",
      [TABLET]: "none",
      default: "inline",
    },
  },
  spinner: {
    fill: colors.topNavigationSearchButtonColor,
  },
});

export default PortfolioSearch;
