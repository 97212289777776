/**
 * @generated SignedSource<<14b27fe8557c56d58116f9c6ad3b671c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateSellerShopNameInput = {
  shopName: string;
  userId: string;
};
export type SellerAccountStoreNameFieldMutation$variables = {
  input: UpdateSellerShopNameInput;
};
export type SellerAccountStoreNameFieldMutation$data = {
  readonly updateSellerShopName: {
    readonly " $fragmentSpreads": FragmentRefs<"SellerAccountStoreNameField_user">;
  };
};
export type SellerAccountStoreNameFieldMutation = {
  response: SellerAccountStoreNameFieldMutation$data;
  variables: SellerAccountStoreNameFieldMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "updateSellerShopNameInput",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SellerAccountStoreNameFieldMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateSellerShopName",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SellerAccountStoreNameField_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SellerAccountStoreNameFieldMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateSellerShopName",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shopName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "af1f7d5e91803da35c8df738860ecda6",
    "id": null,
    "metadata": {},
    "name": "SellerAccountStoreNameFieldMutation",
    "operationKind": "mutation",
    "text": "mutation SellerAccountStoreNameFieldMutation(\n  $input: UpdateSellerShopNameInput!\n) {\n  updateSellerShopName(updateSellerShopNameInput: $input) {\n    ...SellerAccountStoreNameField_user\n    id\n  }\n}\n\nfragment SellerAccountStoreNameField_user on User {\n  id\n  shopName\n}\n"
  }
};
})();

(node as any).hash = "8a96dcb491baddd13df76a7cc6f9ec55";

export default node;
