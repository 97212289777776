/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTransition } from "react";
import { Link, useNavigate } from "react-router-dom";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import { SBActivityIndicator } from "./SBActivityIndicator";

export enum ButtonType {
  Button = "button",
  Submit = "submit",
}

export enum ButtonVariation {
  Default,
  Emphasis,
  Icon,
}

type Props = Readonly<{
  block?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  onClick?: (event: React.SyntheticEvent) => void;
  style?: StyleXStyles;
  title: string;
  to?: string;
  type?: ButtonType;
  variation?: ButtonVariation;
}>;

export const SBButton = React.memo(
  ({
    block = false,
    disabled = false,
    loading = false,
    icon: Icon,
    onClick,
    title,
    to,
    type = ButtonType.Button,
    variation = ButtonVariation.Default,
    style,
    ...rest
  }: Props): React.ReactNode => {
    const navigate = useNavigate();

    const [isPending, startTransition] = useTransition();

    const inner = (
      <>
        <span
          {...stylex.props(
            styles.inner,
            (isPending || loading) && styles.innerIsPending,
          )}
          aria-hidden={disabled || loading}
        >
          {Icon ? <span {...stylex.props(styles.icon)}>{Icon}</span> : null}
          {title}
        </span>
        <span
          {...stylex.props(
            styles.spinner,
            (isPending || loading) && styles.spinnerIsPending,
          )}
        >
          <SBActivityIndicator
            small={true}
            style={getLoadingStyleForVariation(variation)}
          />
        </span>
      </>
    );

    const handleClickLink = (event: React.SyntheticEvent) => {
      event.preventDefault();
      onClick?.(event);
      if (to != null && !to?.startsWith("#")) {
        startTransition(() => {
          navigate(to);
        });
      }
    };

    return to == null ? (
      <button
        {...stylex.props(
          auto,
          styles.root,
          getStyleForVariation(variation),
          block && styles.block,
          disabled && styles.disabled,
          style,
        )}
        disabled={disabled || loading}
        type={type}
        onClick={onClick}
        {...rest}
      >
        {inner}
      </button>
    ) : (
      <Link
        {...stylex.props(
          auto,
          styles.root,
          getStyleForVariation(variation),
          block && styles.block,
          style,
        )}
        to={to}
        onClick={handleClickLink}
        {...rest}
      >
        {inner}
      </Link>
    );
  },
);

const styles = stylex.create({
  block: {
    display: "flex",
    width: "100%",
  },
  default: {
    backgroundColor: {
      ":hover": colors.buttonBackgroundColor,
      default: colors.buttonBackgroundColor,
    },
    color: colors.buttonColor,
    opacity: {
      ":disabled": 0.6,
      default: 1,
    },
  },
  defaultLoading: {
    fill: colors.buttonColor,
  },
  disabled: {
    opacity: 0.4,
  },
  emphasis: {
    backgroundColor: {
      ":hover": colors.buttonEmphasisBackgroundColor,
      default: colors.buttonEmphasisBackgroundColor,
    },
    color: colors.buttonEmphasisColor,
    outlineColor: colors.buttonBackgroundColor,
  },
  emphasisLoading: {
    fill: colors.buttonEmphasisColor,
  },
  icon: {
    marginRight: 2,
  },
  inner: {
    MozOsxFontSmoothing: "grayscale",
    WebkitFontSmoothing: "antialiased",
    alignItems: "center",
    boxSizing: "border-box",
    display: "inline-flex",
    fontFamily: "'drukmedium', sans-serif",
    fontFeatureSettings: "liga",
    fontSize: 20,
    fontWeight: 400,
    letterSpacing: 0.6,
    lineHeight: "48px",
    margin: 0,
    opacity: 1,
    textTransform: "uppercase",
    whiteSpace: "nowrap",
  },
  innerIsPending: {
    opacity: 0,
  },
  root: {
    alignItems: "center",
    borderRadius: 24,
    borderWidth: 0,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "inline-flex",
    height: 48,
    justifyContent: "center",
    outline: {
      ":focus-visible": colors.outline,
    },
    paddingInline: 32,
    position: "relative",
    textDecorationLine: "none",
  },
  rootIcon: {
    backgroundColor: "transparent",
  },
  spinner: {
    fill: colors.buttonColor,
    left: "50%",
    marginTop: 2,
    opacity: 0,
    position: "absolute",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
  spinnerIsPending: {
    opacity: 1,
  },
});

const getStyleForVariation = (variation: ButtonVariation): StyleXStyles => {
  switch (variation) {
    case ButtonVariation.Default:
      return styles.default;
    case ButtonVariation.Emphasis:
      return styles.emphasis;
    case ButtonVariation.Icon:
      return styles.icon;
    default:
      return null;
  }
};

const getLoadingStyleForVariation = (
  variation: ButtonVariation,
): StyleXStyles => {
  switch (variation) {
    case ButtonVariation.Default:
      return styles.defaultLoading;
    case ButtonVariation.Emphasis:
      return styles.emphasisLoading;
    case ButtonVariation.Icon:
    default:
      return null;
  }
};
