/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { OrderStatusTracking_order$key } from "src/types/__generated__/OrderStatusTracking_order.graphql";
import type { TStep } from "./OrderStatusTrackingProgressBar";

import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import OrderStatusTrackingProgressBar from "./OrderStatusTrackingProgressBar";

type Props = Readonly<{
  queryKey: OrderStatusTracking_order$key;
}>;

const OrderStatusTracking = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment OrderStatusTracking_order on Order {
        publicStatus
        payoutStatus
        trackingId
      }
    `,
    queryKey,
  );

  const { publicStatus, payoutStatus, trackingId } = data;

  const progress: { id: TStep; label: string }[] = [
    {
      id: "PROCESSING",
      label: t("account.orders.status.processing"),
    },
    {
      id: "IN_TRANSIT",
      label: t("account.orders.status.in-transit"),
    },
    {
      id: "DELIVERED",
      label: t("account.orders.status.delivered"),
    },
    {
      id: `PAYOUT_${payoutStatus}` as TStep,
      label: t(`account.orders.payoutStatus.${kebabCase(payoutStatus)}`),
    },
  ];

  if (publicStatus === "QUEUED") {
    progress.shift();
    progress.unshift({
      id: "QUEUED",
      label: t("account.orders.status.queued"),
    });
  }

  if (publicStatus === "CANCELED") {
    progress.pop();
    progress.pop();
    progress.pop();
    progress.push({
      id: "CANCELED",
      label: t("account.orders.status.canceled"),
    });
  }

  if (publicStatus === "FAILED") {
    progress.pop();
    progress.pop();
    progress.pop();
    progress.push({
      id: "FAILED",
      label: t("account.orders.status.failed"),
    });
  }

  if (publicStatus === "REFUNDED") {
    progress.pop();
    progress.pop();
    progress.pop();
    progress.push({
      id: "REFUNDED",
      label: t("account.orders.status.refunded"),
    });
  }

  if (publicStatus === "DELIVERY_EXCEPTION") {
    progress.pop();
    progress.pop();
    progress.push({
      id: "DELIVERY_EXCEPTION",
      label: t("account.orders.status.delivery-exception"),
    });
  }

  if (publicStatus === "UNAVAILABLE") {
    progress.pop();
    progress.pop();
    progress.pop();
    progress.push({
      id: "UNAVAILABLE",
      label: t("account.orders.status.unavailable"),
    });
  }

  const trackingStatus =
    publicStatus === "DELIVERED" ? `PAYOUT_${payoutStatus}` : publicStatus;

  const hasTrackingId = trackingId != null && trackingId !== "";

  if (!hasTrackingId) {
    return null;
  }

  return (
    <OrderStatusTrackingProgressBar
      progress={progress}
      status={trackingStatus as TStep}
    />
  );
};

export default OrderStatusTracking;
