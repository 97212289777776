/**
 * @generated SignedSource<<308a33c0db742cd65a6ca60d8815f827>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TermsView_document$data = {
  readonly body: string;
  readonly id: string;
  readonly publishedAt: string | null | undefined;
  readonly title: string;
  readonly " $fragmentType": "TermsView_document";
};
export type TermsView_document$key = {
  readonly " $data"?: TermsView_document$data;
  readonly " $fragmentSpreads": FragmentRefs<"TermsView_document">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TermsView_document",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "body",
      "storageKey": null
    }
  ],
  "type": "Document",
  "abstractKey": null
};

(node as any).hash = "47973f6cc5ede91afbbe4d5de6cbe5b3";

export default node;
