/**
 * @generated SignedSource<<c5dcbf4825da4da30fa7a3f431a387ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuctionType = "COMMUNITY" | "CURATED" | "%future added value";
export type AuctionSearchViewPaginationQuery$variables = {
  auctionType?: ReadonlyArray<AuctionType> | null | undefined;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
};
export type AuctionSearchViewPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AuctionSearchView_auctionSearch">;
};
export type AuctionSearchViewPaginationQuery = {
  response: AuctionSearchViewPaginationQuery$data;
  variables: AuctionSearchViewPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "auctionType"
  },
  {
    "defaultValue": 10,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "auctionType"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "biddingStartsAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuctionSearchViewPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "auctionType",
            "variableName": "auctionType"
          },
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          }
        ],
        "kind": "FragmentSpread",
        "name": "AuctionSearchView_auctionSearch"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuctionSearchViewPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuctionSearchConnection",
        "kind": "LinkedField",
        "name": "auctionSearch",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuctionSearchEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Auction",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "biddingClosesAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastLotClosesAt",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 4
                      },
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": {
                          "currentBidAmount": "DESC"
                        }
                      }
                    ],
                    "concreteType": "AuctionLotsConnection",
                    "kind": "LinkedField",
                    "name": "lots",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuctionLotsEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuctionLot",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "shortDescription",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isClosed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "closesAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currentBidAmount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currentBidCurrency",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isViewerBidding",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isViewerHighBidder",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isViewerOwner",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isViewerWatching",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startingAmount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Auction",
                                "kind": "LinkedField",
                                "name": "auction",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Product",
                                "kind": "LinkedField",
                                "name": "product",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ComicDetail",
                                    "kind": "LinkedField",
                                    "name": "comicDetails",
                                    "plural": false,
                                    "selections": [
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "number",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "grade",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "gradingAuthority",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FairMarketValueScore",
                                    "kind": "LinkedField",
                                    "name": "fmvScore",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "maxValue",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "minValue",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "currency",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "ImagesConnection",
                                    "kind": "LinkedField",
                                    "name": "images",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ImagesEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Image",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": [
                                                  {
                                                    "kind": "Literal",
                                                    "name": "quality",
                                                    "value": 100
                                                  },
                                                  {
                                                    "kind": "Literal",
                                                    "name": "webp",
                                                    "value": true
                                                  },
                                                  {
                                                    "kind": "Literal",
                                                    "name": "width",
                                                    "value": 1000
                                                  }
                                                ],
                                                "kind": "ScalarField",
                                                "name": "url",
                                                "storageKey": "url(quality:100,webp:true,width:1000)"
                                              },
                                              (v2/*: any*/)
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "lots(first:4,orderBy:{\"currentBidAmount\":\"DESC\"})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "where"
        ],
        "handle": "connection",
        "key": "AuctionSearchView_auctionSearch",
        "kind": "LinkedHandle",
        "name": "auctionSearch"
      }
    ]
  },
  "params": {
    "cacheID": "5e8ad5cad4f168148529caa79e45f2a0",
    "id": null,
    "metadata": {},
    "name": "AuctionSearchViewPaginationQuery",
    "operationKind": "query",
    "text": "query AuctionSearchViewPaginationQuery(\n  $auctionType: [AuctionType!] = null\n  $count: Int = 10\n  $cursor: String\n) {\n  ...AuctionSearchView_auctionSearch_2dn9BB\n}\n\nfragment AuctionSearchHeading_auction on Auction {\n  id\n  biddingStartsAt\n  biddingClosesAt\n  lastLotClosesAt\n  type\n  title\n}\n\nfragment AuctionSearchItem_auctionLot on AuctionLot {\n  id\n  shortDescription\n  isClosed\n  closesAt\n  currentBidAmount\n  currentBidCurrency\n  isViewerBidding\n  isViewerHighBidder\n  isViewerOwner\n  isViewerWatching\n  startingAmount\n  auction {\n    biddingStartsAt\n    type\n    id\n  }\n  product {\n    comicDetails {\n      title\n      number\n      grade\n      gradingAuthority\n      id\n    }\n    fmvScore {\n      maxValue\n      minValue\n      currency\n    }\n    images {\n      edges {\n        node {\n          url(quality: 100, webp: true, width: 1000)\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment AuctionSearchView_auctionSearch_2dn9BB on Query {\n  auctionSearch(first: $count, after: $cursor, where: {type: $auctionType}) {\n    edges {\n      node {\n        ...AuctionSearchHeading_auction\n        id\n        title\n        type\n        biddingStartsAt\n        lastLotClosesAt\n        lots(first: 4, orderBy: {currentBidAmount: DESC}) {\n          edges {\n            node {\n              id\n              ...AuctionSearchItem_auctionLot\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29deb7256b06d5fc021e34308ec0d97c";

export default node;
