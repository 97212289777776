/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ArticlesViewQuery_articles$key } from "src/types/__generated__/ArticlesViewQuery_articles.graphql";

import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { SBPageHeader } from "src/sbxui";

import ArticlesList from "./ArticlesList";
import FeaturedArticle from "./FeaturedArticle";

dayjs.extend(localizedFormat);

type Props = Readonly<{
  queryKey: ArticlesViewQuery_articles$key;
}>;

const ArticlesView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ArticlesViewQuery_articles on Query {
        ...FeaturedArticle_article
        featuredArticle {
          id
        }
        ...ArticlesListQuery_articles
      }
    `,
    queryKey,
  );

  return (
    <>
      <SBPageHeader title={t("blog.pageTitle")} />
      <FeaturedArticle queryKey={data} />
      <ArticlesList notId={data.featuredArticle?.id ?? null} queryKey={data} />
    </>
  );
};

export default ArticlesView;
