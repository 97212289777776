/**
 * @generated SignedSource<<4b76a311d2e77f80286bc43d92e8c554>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountInvoiceDetailView_invoice$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly surrogateId: number;
  readonly updatedAt: string;
  readonly " $fragmentType": "AccountInvoiceDetailView_invoice";
};
export type AccountInvoiceDetailView_invoice$key = {
  readonly " $data"?: AccountInvoiceDetailView_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountInvoiceDetailView_invoice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountInvoiceDetailView_invoice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "surrogateId",
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};

(node as any).hash = "a468633d32ee2aea5e3fb1313090351a";

export default node;
