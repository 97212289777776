/**
 * @generated SignedSource<<3bff47bc39b3b3f9b97373a6e2453b40>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoicePayment_user$data = {
  readonly emailAddress: string;
  readonly firstName: string | null | undefined;
  readonly lastName: string | null | undefined;
  readonly " $fragmentType": "InvoicePayment_user";
};
export type InvoicePayment_user$key = {
  readonly " $data"?: InvoicePayment_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoicePayment_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoicePayment_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "82459305bffaefe4578af9fb335c0cb1";

export default node;
