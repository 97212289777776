/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountOfferCard_offer$key } from "src/types/__generated__/AccountOfferCard_offer.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";

import { UserContext } from "src/app/context/user";
import { PRODUCT_DETAIL_PATH } from "src/app/router/Router";
import { SBImage } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

import AccountInfoCard from "./AccountInfoCard";

type Props = Readonly<{
  queryKey: AccountOfferCard_offer$key;
}>;

const AccountOfferCard = ({ queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const { user } = React.useContext(UserContext);
  const userId = user?.userId;

  const offer = useFragment(
    graphql`
      fragment AccountOfferCard_offer on Offer {
        id
        price
        currency
        status
        toUserId
        product {
          id
          images(first: 1) {
            edges {
              node {
                url(quality: 100, width: 300, webp: true)
              }
            }
          }
          comicDetails {
            title
            number
            gradingAuthority
            grade
          }
        }
      }
    `,
    queryKey,
  );

  const { product, status, price, currency, toUserId } = offer;
  const { images, comicDetails } = product ?? {};
  const { title, number, gradingAuthority, grade } = comicDetails ?? {};

  let additionalSenderContext = null;
  if (toUserId === userId && status === "IN_REVIEW") {
    additionalSenderContext = t(
      `account.offers.status.review.awaiting-buyer-response`,
    );
  } else if (status === "IN_REVIEW") {
    additionalSenderContext = t(
      `account.offers.status.review.awaiting-seller-response`,
    );
  }

  const gradeValue =
    grade == null || grade === 0 ? t("raw-grades.0-0") : grade.toFixed(1);

  return (
    <Link
      {...stylex.props(auto, styles.link)}
      to={PRODUCT_DETAIL_PATH.replace(":id", product.id)}
    >
      <AccountInfoCard
        content={
          <>
            <div>{formatMoney(price, currency, i18n.language)}</div>
            <div>
              {t(`account.offers.status.${kebabCase(status)}`)}{" "}
              {additionalSenderContext}
            </div>
          </>
        }
        heading={t("account.offers.comic-details1", { number, title })}
        image={
          <SBImage
            alt=""
            src={images?.edges[0]?.node?.url ?? ""}
            width="100%"
          />
        }
        subHeading={t("account.offers.comic-details2", {
          grade:
            gradingAuthority === "RAW"
              ? t(`raw-grades.${kebabCase(gradeValue)}`)
              : gradeValue,
          gradingAuthority,
        })}
      />
    </Link>
  );
};

const styles = stylex.create({
  link: {
    borderRadius: 8,
    display: "block",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
});

export default AccountOfferCard;
