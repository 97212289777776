/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { InvoiceItem_product$key } from "src/types/__generated__/InvoiceItem_product.graphql";
import type { InvoiceType } from "src/types/__generated__/InvoiceItems_invoice.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { HeadingLevel, SBHeading, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney, formatTitle } from "src/utils";

type Props = Readonly<{
  invoiceType: InvoiceType;
  queryKey: InvoiceItem_product$key;
}>;

const InvoiceItem = ({ invoiceType, queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment InvoiceItem_product on Product {
        auctionLot {
          currentBidAmount
          currentBidCurrency
        }
        comicDetails {
          grade
          gradingAuthority
          number
          publisher
          title
          variant
        }
        images(first: 1) {
          edges {
            node {
              url(quality: 100, webp: true, width: 256)
            }
          }
        }
        price
      }
    `,
    queryKey,
  );

  const auctionLot = data.auctionLot;
  const currentBidAmount = auctionLot?.currentBidAmount;
  const currentBidCurrency = auctionLot?.currentBidCurrency;

  const comicDetails = data.comicDetails;
  const grade = comicDetails?.grade;
  const gradingAuthority = comicDetails?.gradingAuthority;
  const number = comicDetails?.number;
  const title = comicDetails?.title;

  const imageSource = data.images?.edges[0].node.url;

  return (
    <li {...stylex.props(styles.item)}>
      <div {...stylex.props(auto, styles.itemImage)}>
        <img
          {...stylex.props(styles.itemImageFile)}
          aria-hidden={true}
          src={imageSource}
        />
      </div>
      <div {...stylex.props(styles.itemMeta)}>
        <SBHeading level={HeadingLevel.H3}>
          {formatTitle({ number, t, title })}
        </SBHeading>
        <SBParagraph style={styles.itemGrade}>
          {t("product.grade-number", {
            gradingAuthority: t(
              `grading-authority.${kebabCase(gradingAuthority ?? "UNKNOWN")}`,
            ),
            value:
              gradingAuthority === "RAW"
                ? t(`raw-grades.${kebabCase(`${grade?.toFixed(1) ?? 0.0}`)}`)
                : (grade?.toFixed(1) ?? t("raw-grades.0-0")),
          })}
        </SBParagraph>
        {invoiceType === "AUCTION" && (
          <SBParagraph style={styles.itemBid}>
            {t("invoice.items.winning-bid", {
              value: formatMoney(
                currentBidAmount ?? 0,
                currentBidCurrency ?? "USD",
                i18n.language,
              ),
            })}
          </SBParagraph>
        )}
      </div>
    </li>
  );
};

const styles = stylex.create({
  item: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginTop: 16,
  },
  itemBid: {
    fontSize: 12,
    marginBottom: 0,
  },
  itemGrade: {
    color: colors.colorMuted,
    fontSize: 12,
    marginBottom: 4,
  },
  itemImage: {
    backgroundColor: colors.backgroundPlaceholderColor,
    borderRadius: 4,
    display: "flex",
    height: 56,
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    width: 56,
  },
  itemImageFile: {
    alignItems: "center",
    display: "block",
    height: "100%",
    position: "absolute",
  },
  itemList: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderColor: colors.borderColor,
    listStyle: "none",
    margin: 0,
    marginBottom: 8,
    padding: 0,
    paddingBottom: 16,
  },
  itemMeta: {
    marginLeft: 16,
  },
});

export default InvoiceItem;
