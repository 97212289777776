/**
 * @generated SignedSource<<297f92a3a036a864f47b2b9b4d677f69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ProductPublicStatus = "FOR_SALE" | "HIDDEN" | "PENDING_AUCTION_CONSIGNMENT_REVIEW" | "PENDING_AUCTION_CONSIGNMENT_SHIPPING" | "PENDING_AUCTION_END" | "PENDING_AUCTION_START" | "PENDING_PURCHASE" | "PROCESSING_PURCHASE" | "SOLD" | "UNAVAILABLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ProductDetailActionsBuyer_product$data = {
  readonly acceptedOffersCount: {
    readonly count: number;
  } | null | undefined;
  readonly counteredOffersCount: {
    readonly count: number;
  } | null | undefined;
  readonly favoriteOffersCount: {
    readonly count: number;
  } | null | undefined;
  readonly id: string;
  readonly inReviewOffersCount: {
    readonly count: number;
  } | null | undefined;
  readonly isViewerBuyer: boolean;
  readonly publicStatus: ProductPublicStatus | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsBuyerAccepted_product" | "ProductDetailActionsBuyerCounter_product" | "ProductDetailActionsBuyerDefault_product" | "ProductDetailActionsBuyerFavorite_product" | "ProductDetailActionsBuyerInReview_product">;
  readonly " $fragmentType": "ProductDetailActionsBuyer_product";
};
export type ProductDetailActionsBuyer_product$key = {
  readonly " $data"?: ProductDetailActionsBuyer_product$data;
  readonly " $fragmentSpreads": FragmentRefs<"ProductDetailActionsBuyer_product">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "count",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ProductDetailActionsBuyer_product",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isViewerBuyer",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "alias": "inReviewOffersCount",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "reason": [
              "NONE",
              "COUNTER_FROM_BUYER"
            ],
            "status": "IN_REVIEW"
          }
        }
      ],
      "concreteType": "ProductOffersConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "offers(where:{\"reason\":[\"NONE\",\"COUNTER_FROM_BUYER\"],\"status\":\"IN_REVIEW\"})"
    },
    {
      "alias": "counteredOffersCount",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "reason": [
              "COUNTER_FROM_SELLER"
            ],
            "status": "IN_REVIEW"
          }
        }
      ],
      "concreteType": "ProductOffersConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "offers(where:{\"reason\":[\"COUNTER_FROM_SELLER\"],\"status\":\"IN_REVIEW\"})"
    },
    {
      "alias": "acceptedOffersCount",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "status": "ACCEPTED"
          }
        }
      ],
      "concreteType": "ProductOffersConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "offers(where:{\"status\":\"ACCEPTED\"})"
    },
    {
      "alias": "favoriteOffersCount",
      "args": [
        {
          "kind": "Literal",
          "name": "where",
          "value": {
            "reason": [
              "FAVORITE"
            ],
            "status": "IN_REVIEW"
          }
        }
      ],
      "concreteType": "ProductOffersConnection",
      "kind": "LinkedField",
      "name": "offers",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": "offers(where:{\"reason\":[\"FAVORITE\"],\"status\":\"IN_REVIEW\"})"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsBuyerDefault_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsBuyerFavorite_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsBuyerInReview_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsBuyerCounter_product"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProductDetailActionsBuyerAccepted_product"
    }
  ],
  "type": "Product",
  "abstractKey": null
};
})();

(node as any).hash = "57580bec4053bd04243b539bb160c02d";

export default node;
