/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountVacationModeQuery } from "src/types/__generated__/QueriesSellerAccountVacationModeQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { SellerAccountVacationModeQuery } from "src/app/router/Queries";

import SellerAccountVacationModeView from "./SellerAccountVacationModeView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesSellerAccountVacationModeQuery>;
}>;

const SellerAccountVacationModeContent = ({
  queryRef,
}: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(SellerAccountVacationModeQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <SellerAccountVacationModeView queryKey={user} />;
};

export default SellerAccountVacationModeContent;
