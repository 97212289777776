/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAuctionQuery } from "src/types/__generated__/QueriesAuctionQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import AuctionContent from "./AuctionContent";

const Auction = (): React.ReactNode | null => {
  const queryRef = useLoaderData() as PreloadedQuery<QueriesAuctionQuery>;

  if (queryRef == null) {
    return null;
  }

  return (
    <div aria-live="polite">
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <AuctionContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

export default Auction;
