/**
 * @generated SignedSource<<920b6a5ae24c509b67f71514ef36948e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type NuxStatus = "DISMISSED" | "READ" | "UNREAD" | "%future added value";
export type ProductPublicStatus = "FOR_SALE" | "HIDDEN" | "PENDING_AUCTION_CONSIGNMENT_REVIEW" | "PENDING_AUCTION_CONSIGNMENT_SHIPPING" | "PENDING_AUCTION_END" | "PENDING_AUCTION_START" | "PENDING_PURCHASE" | "PROCESSING_PURCHASE" | "SOLD" | "UNAVAILABLE" | "%future added value";
export type ProductStatus = "DELETED" | "FOR_SALE" | "PENDING_PURCHASE" | "SOLD" | "SUBMITTED" | "UNAVAILABLE" | "%future added value";
export type SalesChannel = "AUCTION" | "SHOP" | "%future added value";
export type SellerStatus = "APPROVED" | "DEACTIVATED" | "IN_REVIEW" | "SUSPENDED" | "VACATION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PortfolioView_user$data = {
  readonly __id: string;
  readonly bulkActions: {
    readonly canCreateAuctionConsignmentRequests: boolean;
    readonly canSendFavoritesOffers: boolean;
  };
  readonly id: string;
  readonly isApiUser: boolean;
  readonly portfolioNux: NuxStatus | null | undefined;
  readonly products: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly publicStatus: ProductPublicStatus | null | undefined;
        readonly salesChannel: SalesChannel;
        readonly status: ProductStatus;
        readonly " $fragmentSpreads": FragmentRefs<"PortfolioItem_product">;
      };
    }>;
    readonly portfolioFmv: {
      readonly __id: string;
      readonly " $fragmentSpreads": FragmentRefs<"PortfolioFmv_portfolioFmv">;
    };
  } | null | undefined;
  readonly sellerStatus: SellerStatus | null | undefined;
  readonly submissionNux: NuxStatus | null | undefined;
  readonly " $fragmentType": "PortfolioView_user";
};
export type PortfolioView_user$key = {
  readonly " $data"?: PortfolioView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioView_user">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "products"
],
v1 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gradingAuthority"
    },
    {
      "defaultValue": {
        "createdAt": "DESC"
      },
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": [
        "AUCTION",
        "SHOP"
      ],
      "kind": "LocalArgument",
      "name": "salesChannel"
    },
    {
      "defaultValue": [
        "FOR_SALE",
        "PENDING_PURCHASE",
        "SUBMITTED",
        "UNAVAILABLE"
      ],
      "kind": "LocalArgument",
      "name": "status"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "title"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./PortfolioViewPaginationQuery.graphql'),
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "PortfolioView_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portfolioNux",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submissionNux",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sellerStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isApiUser",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserBulkActions",
      "kind": "LinkedField",
      "name": "bulkActions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canSendFavoritesOffers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canCreateAuctionConsignmentRequests",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "products",
      "args": [
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "gradingAuthority",
              "variableName": "gradingAuthority"
            },
            {
              "kind": "Variable",
              "name": "salesChannel",
              "variableName": "salesChannel"
            },
            {
              "kind": "Variable",
              "name": "status",
              "variableName": "status"
            },
            {
              "kind": "Variable",
              "name": "title",
              "variableName": "title"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "SellerProductsConnection",
      "kind": "LinkedField",
      "name": "__PortfolioView_products_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PortfolioFmv",
          "kind": "LinkedField",
          "name": "portfolioFmv",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PortfolioFmv_portfolioFmv"
            },
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SellerProductsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Product",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PortfolioItem_product"
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publicStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "salesChannel",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v2/*: any*/),
    (v1/*: any*/)
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "91c5da7bb03e1365ff23e5e1ffe808f3";

export default node;
