/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountLabelSizeView_user$key } from "src/types/__generated__/SellerAccountLabelSizeView_user.graphql";
import type { SellerAccountLabelSizeViewMutation } from "src/types/__generated__/SellerAccountLabelSizeViewMutation.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import { ButtonType, SBButton, SBRadioButtons } from "src/sbxui";

const LABEL_SIZES = ["PDF_4x6", "PDF_W_PSLIP_8x11"];

type Props = Readonly<{
  queryKey: SellerAccountLabelSizeView_user$key;
}>;

const SellerAccountLabelSizeView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountLabelSizeView_user on User {
        id
        labelSize
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] = useMutation<SellerAccountLabelSizeViewMutation>(
    graphql`
      mutation SellerAccountLabelSizeViewMutation(
        $input: UpdateViewerShippingLabelInput!
      ) {
        updateUserShippingLabel(updateViewerShippingLabelInput: $input) {
          ... on User {
            labelSize
            ...SellerAccountLabelSizeView_user
          }
        }
      }
    `,
  );

  const { id: userId, labelSize } = data;

  const [newLabelSize, setNewLabelSize] = useState<string>(labelSize);

  const radioOptions = LABEL_SIZES.map((size) => ({
    caption: t(`seller-account.label-size.subtext.${kebabCase(size)}`),
    label: t(`seller-account.label-size.option.${kebabCase(size)}`),
    value: size,
  }));

  const handleSubmit = () => {
    if (userId == null) {
      return;
    }
    commit({
      variables: {
        input: {
          labelSize,
        },
      },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <SBRadioButtons
          id="labelSizes"
          options={radioOptions}
          value={newLabelSize}
          onChange={(val) => setNewLabelSize(val)}
        />
      </div>
      <div {...stylex.props(styles.button)}>
        <SBButton
          disabled={isInFlight}
          loading={isInFlight}
          title={t("seller-account.buttons.label-size")}
          type={ButtonType.Submit}
        />
      </div>
    </form>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 16,
  },
});

export default SellerAccountLabelSizeView;
