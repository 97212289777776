/**
 * @generated SignedSource<<d455c094d9484cf3747d0ddc39347bd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateUserProfileInfoInput = {
  firstName: string;
  lastName: string;
};
export type AccountPersonalInformationFormMutation$variables = {
  input: UpdateUserProfileInfoInput;
};
export type AccountPersonalInformationFormMutation$data = {
  readonly updateUserProfileInfo: {
    readonly " $fragmentSpreads": FragmentRefs<"AccountPersonalInformationForm_user" | "ProfileLinkView_user">;
  };
};
export type AccountPersonalInformationFormMutation = {
  response: AccountPersonalInformationFormMutation$data;
  variables: AccountPersonalInformationFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "updateUserProfileInfoInput",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountPersonalInformationFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateUserProfileInfo",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AccountPersonalInformationForm_user"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ProfileLinkView_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountPersonalInformationFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateUserProfileInfo",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sellerStatus",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2b75e6304120124a77f23e41a2a79171",
    "id": null,
    "metadata": {},
    "name": "AccountPersonalInformationFormMutation",
    "operationKind": "mutation",
    "text": "mutation AccountPersonalInformationFormMutation(\n  $input: UpdateUserProfileInfoInput!\n) {\n  updateUserProfileInfo(updateUserProfileInfoInput: $input) {\n    ...AccountPersonalInformationForm_user\n    ...ProfileLinkView_user\n    id\n  }\n}\n\nfragment AccountPersonalInformationForm_user on User {\n  id\n  firstName\n  lastName\n  emailAddress\n}\n\nfragment ProfileLinkView_user on User {\n  id\n  firstName\n  lastName\n  sellerStatus\n}\n"
  }
};
})();

(node as any).hash = "73cd4e7281616aee74814056e71b5d0c";

export default node;
