/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { InvoiceHeader_invoice$key } from "src/types/__generated__/InvoiceHeader_invoice.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { FAQS_PATH } from "src/app/router/Router";
import { useCountdownTimer } from "src/hooks";
import {
  HeadingLevel,
  SBErrorMessage,
  SBHeading,
  SBLink,
  SBParagraph,
} from "src/sbxui";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  invoicePaymentDays: number;
  payable: boolean;
  queryKey: InvoiceHeader_invoice$key;
}>;

const InvoiceHeader = ({
  invoicePaymentDays,
  payable,
  queryKey,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment InvoiceHeader_invoice on Invoice {
        createdAt
        errorMessage
        hostedVerificationUrl
        status
        surrogateId
        type
      }
    `,
    queryKey,
  );

  const invoiceType = data.type;
  const surrogateId = data.surrogateId;
  const status = data.status;

  const errorMessage = data.errorMessage;

  const createdAt = data.createdAt;

  const hostedVerificationUrl = data.hostedVerificationUrl;

  const timestamp = createdAt == null ? null : Number.parseInt(createdAt, 10);
  const expiresDate = new Date(timestamp ?? Date.now());
  expiresDate.setDate(expiresDate.getDate() + invoicePaymentDays);

  const { expiresIn } = useCountdownTimer(expiresDate.getTime(), {
    expired: t("invoice.status.expiring"),
    short: true,
  });

  let invoiceTitle = t("invoice.title-number-status", {
    number: surrogateId,
    status:
      status === "PROCESSING"
        ? t(`invoice.status.${kebabCase(status)}-short`)
        : t(`invoice.status.${kebabCase(status)}`),
  });
  if (timestamp != null && payable) {
    invoiceTitle = t("invoice.title-number-status", {
      number: surrogateId,
      status: expiresIn,
    });
  }
  if (invoiceType !== "AUCTION" && payable) {
    invoiceTitle = t("invoice.title-number", {
      number: surrogateId,
    });
  }

  return (
    <>
      <SBErrorMessage message={errorMessage} style={styles.errorMessage} />
      <SBHeading level={HeadingLevel.H1} style={styles.title}>
        {invoiceTitle}
      </SBHeading>
      {payable ? (
        <SBParagraph style={styles.confirmation}>
          {t("invoice.confirmation")}
        </SBParagraph>
      ) : null}
      {hostedVerificationUrl != null && (
        <SBParagraph style={styles.verification}>
          {t("invoice.bank-verification.start")}
          <SBLink external={true} href={hostedVerificationUrl}>
            {t("invoice.bank-verification.link")}
          </SBLink>
          {t("invoice.bank-verification.end")}
        </SBParagraph>
      )}
      <SBParagraph style={styles.help}>
        {t("invoice.help.start")}
        <SBLink external={true} to={FAQS_PATH}>
          {t("invoice.help.link")}
        </SBLink>
        {t("invoice.help.end")}
      </SBParagraph>
    </>
  );
};

const styles = stylex.create({
  confirmation: {
    marginBottom: 0,
  },
  errorMessage: {
    marginBottom: 8,
  },
  help: {
    marginTop: 16,
  },
  title: {
    fontSize: {
      [MOBILE]: 32,
      [TABLET]: 48,
      default: 48,
    },
    marginBottom: 8,
  },
  verification: {
    marginBottom: 0,
    marginTop: 8,
  },
});

export default InvoiceHeader;
