/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

export const formatPhoneNumber = (
  phoneNumber: string,
  country: string,
): string => {
  let formatted = phoneNumber;
  if (country === "CA") {
    if (phoneNumber.length > 6) {
      formatted = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(
        3,
        6,
      )}-${phoneNumber.substring(6)}`;
    } else if (phoneNumber.length > 3) {
      formatted = `${phoneNumber.substring(0, 3)}-${phoneNumber.substring(3)}`;
    }
  } else if (country === "GB") {
    if (phoneNumber.length > 4) {
      formatted = `${phoneNumber.substring(0, 4)} ${phoneNumber.substring(4)}`;
    }
  } else if (country === "US") {
    if (phoneNumber.length > 6) {
      formatted = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
        6,
      )}-${phoneNumber.substring(6)}`;
    } else if (phoneNumber.length > 3) {
      formatted = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
        3,
      )}`;
    } else if (phoneNumber.length > 0) {
      formatted = `(${phoneNumber.substring(0, 3)}`;
    }
  }
  return formatted;
};
