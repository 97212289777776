/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountAddressCard_address$key } from "src/types/__generated__/AccountAddressCard_address.graphql";
import type { AccountAddressCardDeleteAddressMutation } from "src/types/__generated__/AccountAddressCardDeleteAddressMutation.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import { HeadingLevel, SBHeading, SBIcon } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: AccountAddressCard_address$key;
}>;

const AccountAddressCard = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const address = useFragment(
    graphql`
      fragment AccountAddressCard_address on Address {
        id
        address1
        address2
        locality
        region
        country
        postalCode
        familyName
        givenName
        isDefault
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] =
    useMutation<AccountAddressCardDeleteAddressMutation>(graphql`
      mutation AccountAddressCardDeleteAddressMutation(
        $input: DeleteAddressInput!
      ) {
        deleteAddress(deleteAddressInput: $input) {
          id @deleteRecord
        }
      }
    `);

  const {
    id,
    address1,
    address2,
    locality,
    region,
    country,
    postalCode,
    familyName,
    givenName,
    isDefault,
  } = address;

  const handleDeleteClick = () => {
    commit({
      variables: {
        input: {
          addressId: id,
        },
      },
    });
  };

  return (
    <div key={id} {...stylex.props(styles.address)}>
      {isDefault ? (
        <SBHeading level={HeadingLevel.H2} style={styles.heading}>
          {t("account.addresses.default")}
        </SBHeading>
      ) : null}
      <div {...stylex.props(styles.cols)}>
        <div>
          <div>
            {givenName} {familyName}
          </div>
          <div>{address1}</div>
          {address2 ? <div>{address2}</div> : null}
          <div>
            {t("orderDetails.addressFormatted", {
              locality,
              postalCode,
              region,
            })}
          </div>
          <div>{country}</div>
        </div>
        {!isDefault && (
          <button
            disabled={isInFlight}
            type="button"
            onClick={handleDeleteClick}
            {...stylex.props(styles.deleteButton)}
          >
            <SBIcon icon="delete" style={styles.icon} />
          </button>
        )}{" "}
      </div>
    </div>
  );
};

const styles = stylex.create({
  address: {
    backgroundColor: colors.backgroundEmphasisColor,
    marginBottom: 10,
    padding: 12,
  },
  cols: {
    display: "flex",
    justifyContent: "space-between",
  },
  deleteButton: {
    cursor: "pointer",
  },
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
  icon: {
    color: colors.color,
  },
});

export default AccountAddressCard;
