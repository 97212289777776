/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountStatsSummary_sellerStats$key } from "src/types/__generated__/SellerAccountStatsSummary_sellerStats.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import { HeadingLevel, SBHeading } from "src/sbxui";
import { formatMoney } from "src/utils";

type Props = Readonly<{
  queryKey: SellerAccountStatsSummary_sellerStats$key;
}>;

const SellerAccountStatsSummary = ({ queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountStatsSummary_sellerStats on UserSellerStats {
        numOrders
        salesTotal
      }
    `,
    queryKey,
  );

  const numOrders = data?.numOrders ?? 0;
  const salesTotal = data?.salesTotal ?? 0;

  return (
    <div {...stylex.props(styles.summary)}>
      <div {...stylex.props(styles.summaryBooks)}>
        <SBHeading level={HeadingLevel.H1} style={styles.summaryValue}>
          {numOrders}
        </SBHeading>
        <SBHeading
          level={HeadingLevel.H4}
          {...stylex.props(styles.summaryLabel)}
        >
          {t("seller-account.stats.total.books")}
        </SBHeading>
      </div>
      <div style={styles.summarySales}>
        <SBHeading level={HeadingLevel.H1} style={styles.summaryValue}>
          {formatMoney(salesTotal, "USD", i18n.language)}
        </SBHeading>
        <SBHeading
          level={HeadingLevel.H4}
          {...stylex.props(styles.summaryLabel)}
        >
          {t("seller-account.stats.total.sales")}
        </SBHeading>
      </div>
    </div>
  );
};

const styles = stylex.create({
  summary: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  summaryBooks: {
    borderRightWidth: 1,
    paddingLeft: 16,
    paddingRight: 16,
  },
  summaryLabel: {
    textAlign: "center",
  },
  summarySales: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  summaryValue: {
    textAlign: "center",
  },
});

export default SellerAccountStatsSummary;
