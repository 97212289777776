/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountInvoicesClosed_user$key } from "src/types/__generated__/AccountInvoicesClosed_user.graphql";
import type { AccountInvoicesClosedPaginationQuery } from "src/types/__generated__/AccountInvoicesClosedPaginationQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRef } from "react";
import { usePaginationFragment } from "react-relay";

import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator } from "src/sbxui";

import AccountInvoiceCard from "./AccountInvoiceCard";

type Props = Readonly<{
  queryKey: AccountInvoicesClosed_user$key;
}>;

const AccountInvoicesClosed = ({ queryKey }: Props): React.ReactNode => {
  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment<
    AccountInvoicesClosedPaginationQuery,
    AccountInvoicesClosed_user$key
  >(
    graphql`
      fragment AccountInvoicesClosed_user on User
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 20 }
        orderBy: {
          type: InvoiceOrderByInput
          defaultValue: { createdAt: DESC }
        }
      )
      @refetchable(queryName: "AccountInvoicesClosedPaginationQuery") {
        closedInvoices: invoices(
          after: $cursor
          first: $count
          orderBy: $orderBy
          where: { status: [EXPIRED, CLOSED, PAID] }
        ) @connection(key: "AccountInvoicesClosed_closedInvoices") {
          __id
          edges {
            node {
              ...AccountInvoiceCard_invoice
              id
            }
          }
        }
      }
    `,
    queryKey,
  );

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  return (
    <>
      {data?.closedInvoices?.edges?.map(({ node }) => (
        <AccountInvoiceCard key={node.id} queryKey={node} />
      ))}

      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
});

export default AccountInvoicesClosed;
