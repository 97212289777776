/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TableConfig } from "src/sbxui";
import type { SellerAccountStatsTurnaroundTimesTable_sellerStats$key } from "src/types/__generated__/SellerAccountStatsTurnaroundTimesTable_sellerStats.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay";

import { SBTable, SBTableRow } from "src/sbxui";
import { calculateTimestamp } from "src/utils";

type Props = Readonly<{
  queryKey: SellerAccountStatsTurnaroundTimesTable_sellerStats$key;
}>;

const SellerAccountStatsTurnaroundTimesTable = ({
  queryKey,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountStatsTurnaroundTimesTable_sellerStats on UserSellerStats {
        averageTurnaroundTime
      }
    `,
    queryKey,
  );

  const averageTurnaroundTime = data?.averageTurnaroundTime;

  const averageTurnaroundTimestamp =
    averageTurnaroundTime == null
      ? null
      : calculateTimestamp(averageTurnaroundTime * 1000);

  let averageTurnaroundTimeValue = null;
  if (averageTurnaroundTimestamp != null) {
    const { days, hours, minutes, seconds } = averageTurnaroundTimestamp;
    if (days > 0) {
      averageTurnaroundTimeValue =
        hours > 0
          ? t("timestamp-short.days-hours", {
              days,
              hours,
            })
          : t("timestamp-short.days", {
              days,
            });
    } else if (hours > 0) {
      averageTurnaroundTimeValue =
        minutes > 0
          ? t("timestamp-short.hours-minutes", {
              hours,
              minutes,
            })
          : t("timestamp-short.hours", {
              hours,
            });
    } else if (minutes > 0) {
      averageTurnaroundTimeValue = t("timestamp-short.minutes", {
        minutes,
      });
    } else if (seconds > 0) {
      averageTurnaroundTimeValue = t("timestamp-short.minutes", {
        minutes: 1,
      });
    }
  }

  const columns = [
    {
      id: "time",
      label: t("seller-account.stats.feedback-table.column.turnaround-times"),
    },
    {
      id: "duration",
      label: t("seller-account.stats.feedback-table.column.duration"),
    },
  ];

  const rows = [
    <SBTableRow
      key="OFFERS"
      id="OFFERS"
      td={[
        {
          id: "time",
          label: (
            <span {...stylex.props(styles.datum)}>
              {t("seller-account.stats.turnaround-table.row.offers")}
            </span>
          ),
        },
        {
          id: "duration",
          label:
            averageTurnaroundTimeValue == null
              ? t("seller-account.stats.feedback-table.row.not-applicable")
              : averageTurnaroundTimeValue,
        },
      ]}
    />,
  ];

  const tableConfig: TableConfig = {
    columns,
  };

  return (
    <div {...stylex.props(styles.root)}>
      <SBTable config={tableConfig} rows={rows} />
    </div>
  );
};

const styles = stylex.create({
  datum: {
    whiteSpace: "nowrap",
  },
  root: {
    marginTop: 24,
  },
});

export default SellerAccountStatsTurnaroundTimesTable;
