/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountLabelSizeQuery } from "src/types/__generated__/QueriesSellerAccountLabelSizeQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import SellerAccountPage from "../SellerAccountPage";
import SellerAccountLabelSizeContent from "./SellerAccountLabelSizeContent";

const SellerAccountLabelSize = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesSellerAccountLabelSizeQuery>;

  return (
    <SellerAccountPage
      docTitle={t("seller-account.label-size.title")}
      innerHeading={t("seller-account.nav.label-size")}
    >
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <SellerAccountLabelSizeContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </SellerAccountPage>
  );
};

export default SellerAccountLabelSize;
