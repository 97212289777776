/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { SBIcon, SBParagraph } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

export enum TextInputType {
  Email = "email",
  Number = "number",
  Password = "password",
  Text = "text",
}

export enum TextAutoCompleteType {
  CurrentPassword = "current-password",
  Email = "email",
  NewPassword = "new-password",
  TelCountryCode = "tel-country-code",
  TelNational = "tel-national",
}

type Props = Readonly<{
  autoComplete?: TextAutoCompleteType;
  disabled?: boolean;
  errorMessage?: string | false | null;
  icon?: string;
  iconFill?: boolean;
  id: string;
  inputStyle?: StyleXStyles;
  label: string;
  maxLength?: number;
  minLength?: number;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickIcon?: (event: React.SyntheticEvent) => void;
  placeholder?: string;
  readOnly?: boolean;
  showLabel?: boolean;
  small?: boolean;
  style?: StyleXStyles;
  type?: string;
  value: string | number;
}>;

export const SBTextInput = React.memo(
  ({
    autoComplete,
    disabled = false,
    errorMessage,
    icon,
    iconFill = true,
    id,
    inputStyle,
    label,
    maxLength,
    minLength,
    onBlur,
    onChange,
    onClickIcon,
    placeholder,
    readOnly = false,
    showLabel = false,
    small = false,
    style,
    type = TextInputType.Text,
    value,
  }: Props): React.ReactNode => {
    const { t } = useTranslation();

    let inputIcon = icon && (
      <SBIcon fill={iconFill} icon={icon} style={styles.icon} />
    );

    if (icon && Boolean(onClickIcon)) {
      inputIcon = (
        <button
          {...stylex.props(styles.iconButton)}
          aria-label={t(`icon-alt.${kebabCase(icon)}`)}
          type="button"
          onClick={onClickIcon}
        >
          {inputIcon}
        </button>
      );
    }

    return (
      <div {...stylex.props(styles.root, style)}>
        <label
          {...stylex.props(auto, showLabel ? null : styles.label)}
          aria-label={label}
          htmlFor={id}
        >
          <div
            {...stylex.props(
              styles.labelText,
              showLabel ? styles.labelShow : null,
            )}
          >
            {label}
          </div>
          <div
            {...stylex.props(
              styles.container,
              small && styles.containerSmall,
              disabled && styles.containerDisabled,
              value !== "" && styles.containerWithValue,
            )}
          >
            <input
              {...stylex.props(
                styles.input,
                small && styles.inputSmall,
                inputStyle,
              )}
              autoComplete={autoComplete}
              disabled={disabled}
              id={id}
              maxLength={maxLength}
              minLength={minLength}
              name={id}
              placeholder={placeholder}
              readOnly={readOnly}
              type={type}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
            {inputIcon}
          </div>
        </label>
        {errorMessage ? (
          <SBParagraph style={styles.errorMessage}>{errorMessage}</SBParagraph>
        ) : null}
      </div>
    );
  },
);

const styles = stylex.create({
  container: {
    alignItems: "center",
    backgroundColor: colors.inputTextBackgroundColor,
    borderColor: colors.inputTextBorderColor,
    borderRadius: 24,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    height: 48,
    justifyContent: "space-between",
    position: "relative",
    width: "100%",
  },
  containerDisabled: {
    opacity: 0.4,
  },
  containerSmall: {
    borderRadius: 16,
    height: 32,
  },
  containerWithValue: {
    borderColor: colors.inputTextColor,
  },
  errorMessage: {
    color: colors.colorEmphasis,
    fontSize: 14,
    margin: 0,
    marginTop: 4,
  },
  icon: {
    color: colors.inputTextIconColor,
  },
  iconButton: {
    alignItems: "center",
    background: "transparent",
    borderRadius: 24,
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    outline: {
      ":focus-visible": colors.outline,
    },
    padding: 0,
    position: "absolute",
    right: 16,
    top: 12,
  },
  input: {
    "::-webkit-inner-spin-button": {
      appearance: "none",
    },
    "::-webkit-outer-spin-button": {
      appearance: "none",
    },
    "::placeholder": {
      color: colors.inputTextPlaceholderColor,
    },
    appearance: "textfield",
    background: "transparent",
    borderRadius: 24,
    borderWidth: 0,
    color: colors.inputTextColor,
    height: 48,
    lineHeight: 24,
    outline: {
      ":focus-visible": colors.inputTextOutline,
    },
    paddingInline: 24,
    width: "100%",
  },
  inputSmall: {
    borderRadius: 16,
    height: 32,
    textAlign: "center",
  },
  label: {
    color: colors.color,
    display: "flex",
  },
  labelShow: {
    fontWeight: 600,
    marginBottom: 8,
    textIndent: 0,
  },
  labelText: {
    textIndent: -99999,
    whiteSpace: "nowrap",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
});
