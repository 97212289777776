/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesSellerAccountStatsQuery } from "src/types/__generated__/QueriesSellerAccountStatsQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import SellerAccountPage from "../SellerAccountPage";
import SellerAccountStatsContent from "./SellerAccountStatsContent";
import SellerAccountStatsTabs from "./SellerAccountStatsTabs";

const SellerAccountStats = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesSellerAccountStatsQuery>;

  return (
    <SellerAccountPage
      docTitle={t("seller-account.seller-stats.title")}
      innerHeading={t("seller-account.nav.seller-stats")}
    >
      <SellerAccountStatsTabs />
      <div {...stylex.props(styles.stats)}>
        <SBErrorBoundary>
          <Suspense fallback={<SBFallback />}>
            <SellerAccountStatsContent queryRef={queryRef} />
          </Suspense>
        </SBErrorBoundary>
      </div>
    </SellerAccountPage>
  );
};

const styles = stylex.create({
  stats: {
    marginTop: 24,
  },
});

export default SellerAccountStats;
