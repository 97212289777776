/**
 * @generated SignedSource<<9a804e652bb71e6959017d91c57a4ae9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type OrderPublicStatusType = "CANCELED" | "DELIVERED" | "DELIVERY_EXCEPTION" | "FAILED" | "IN_TRANSIT" | "PROCESSING" | "QUEUED" | "REFUNDED" | "UNAVAILABLE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AccountOrderCard_order$data = {
  readonly createdAt: string;
  readonly currency: Currency;
  readonly id: string;
  readonly product: {
    readonly canLeaveFeedback: boolean;
    readonly comicDetails: {
      readonly grade: number | null | undefined;
      readonly gradingAuthority: GradingAuthority;
      readonly number: string;
      readonly title: string;
    } | null | undefined;
    readonly images: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly url: string;
        };
      }>;
    } | null | undefined;
    readonly isViewerOwner: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"ProductFeedbackModal_product">;
  };
  readonly publicStatus: OrderPublicStatusType;
  readonly surrogateId: number;
  readonly total: number;
  readonly " $fragmentType": "AccountOrderCard_order";
};
export type AccountOrderCard_order$key = {
  readonly " $data"?: AccountOrderCard_order$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountOrderCard_order">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountOrderCard_order",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "surrogateId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publicStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Product",
      "kind": "LinkedField",
      "name": "product",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ProductFeedbackModal_product"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isViewerOwner",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canLeaveFeedback",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "ImagesConnection",
          "kind": "LinkedField",
          "name": "images",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ImagesEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Image",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "quality",
                          "value": 100
                        },
                        {
                          "kind": "Literal",
                          "name": "webp",
                          "value": true
                        },
                        {
                          "kind": "Literal",
                          "name": "width",
                          "value": 300
                        }
                      ],
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": "url(quality:100,webp:true,width:300)"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "images(first:1)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ComicDetail",
          "kind": "LinkedField",
          "name": "comicDetails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "number",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "gradingAuthority",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "grade",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "ffc6ea95a63123b1d9ceb9ab686673fa";

export default node;
