/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SellerAccountNeedToShipView_user$key } from "src/types/__generated__/SellerAccountNeedToShipView_user.graphql";
import type { SellerAccountNeedToShipViewPaginationQuery } from "src/types/__generated__/SellerAccountNeedToShipViewPaginationQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePaginationFragment } from "react-relay";

import AccountOrderCard from "src/app/components/account/AccountOrderCard";
import { useIntersectionObserver } from "src/hooks";
import { SBActivityIndicator, SBInfoMessage } from "src/sbxui";

type Props = Readonly<{
  queryKey: SellerAccountNeedToShipView_user$key;
}>;

const SellerAccountNeedToShipView = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment<
    SellerAccountNeedToShipViewPaginationQuery,
    SellerAccountNeedToShipView_user$key
  >(
    graphql`
      fragment SellerAccountNeedToShipView_user on User
      @argumentDefinitions(
        cursor: { type: "String" }
        count: { type: "Int", defaultValue: 20 }
        orderBy: {
          type: SellerOrdersOrderByInput
          defaultValue: { createdAt: DESC }
        }
      )
      @refetchable(queryName: "SellerAccountNeedToShipViewPaginationQuery") {
        sellerOrders(
          after: $cursor
          first: $count
          orderBy: $orderBy
          where: { salesChannel: [SHOP, AUCTION], status: PROCESSING }
        ) @connection(key: "SellerAccountNeedToShipView_sellerOrders") {
          __id
          edges {
            node {
              ...AccountOrderCard_order
              id
            }
          }
        }
      }
    `,
    queryKey,
  );

  const endRef = useRef<HTMLDivElement>(null);

  const handleInfiniteScroll = (entries: IntersectionObserverEntry[]) => {
    const entry = entries[0];
    const { isIntersecting } = entry;
    if (isIntersecting && hasNext && !isLoadingNext) {
      loadNext(20);
    }
  };

  useIntersectionObserver(endRef, handleInfiniteScroll);

  const orders = data?.sellerOrders?.edges ?? [];

  if (orders.length === 0) {
    return <SBInfoMessage message={t("seller-account.need-to-ship.empty")} />;
  }

  return (
    <>
      {orders
        ?.filter(({ node }) => node != null)
        .map(({ node }) => <AccountOrderCard key={node.id} queryKey={node} />)}
      <div ref={endRef} {...stylex.props(styles.loading)}>
        {isLoadingNext ? <SBActivityIndicator /> : null}
      </div>
    </>
  );
};

const styles = stylex.create({
  loading: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: 20,
  },
});

export default SellerAccountNeedToShipView;
