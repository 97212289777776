/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { SBIcon } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  icon?: string;
  textToCopy: string;
}>;

const CopyButton = ({
  textToCopy,
  icon = "content_copy",
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const handleClickCopyToClipboard = (event: React.SyntheticEvent) => {
    event.preventDefault();
    toast.info(t("copy-to-clipboard.copied"), {
      position: "top-center",
    });
    navigator.clipboard.writeText(textToCopy);
  };

  return (
    <button
      title={t("copy-to-clipboard.title")}
      type="button"
      {...stylex.props(styles.iconButton)}
      onClick={handleClickCopyToClipboard}
    >
      <SBIcon icon={icon} style={styles.icon} />
    </button>
  );
};

const styles = stylex.create({
  icon: {
    color: colors.color,
  },
  iconButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    marginLeft: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 32,
    },
    padding: 0,
  },
});

export default CopyButton;
