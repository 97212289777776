/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesFaqsQuery } from "src/types/__generated__/QueriesFaqsQuery.graphql";

import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { SBErrorBoundary, SBFallback } from "src/sbxui";

import FaqsContent from "./FaqsContent";

const Faqs = (): React.ReactNode => {
  const { t } = useTranslation();

  const pageContext = useContext(PageContext);

  const queryRef = useLoaderData() as PreloadedQuery<QueriesFaqsQuery>;

  useEffect(() => {
    pageContext?.setTitle(t("faqs.documentTitle"));
  }, [t, pageContext]);

  if (queryRef == null) {
    return null;
  }

  return (
    <div aria-live="polite">
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <FaqsContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

export default Faqs;
