/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

export enum HeadingLevel {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

type Props = Readonly<
  React.PropsWithChildren<{
    level: HeadingLevel;
    style?: StyleXStyles;
  }>
>;

export const SBHeading = ({
  level,
  children,
  style,
  ...rest
}: Props): React.ReactNode => {
  const Tag = level;

  return (
    <Tag
      {...stylex.props(auto, styles.heading, styles[level], style)}
      {...rest}
    >
      {children}
    </Tag>
  );
};

const styles = stylex.create({
  h1: {
    fontSize: {
      [MOBILE]: 56,
      [TABLET]: 72,
      default: 96,
    },
    textTransform: "uppercase",
  },
  h2: {
    fontSize: {
      [MOBILE]: 32,
      [TABLET]: 48,
      default: 48,
    },
    textTransform: "uppercase",
  },
  h3: {
    fontSize: 28,
    textTransform: "uppercase",
  },
  h4: {
    fontFamily: "'Supreme-Regular', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1,
    margin: 0,
  },
  h5: {
    fontFamily: "'Supreme-Regular', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1,
    margin: 0,
  },
  h6: {
    fontFamily: "'Supreme-Regular', sans-serif",
    fontSize: 16,
    fontWeight: 600,
    lineHeight: 1,
    margin: 0,
  },
  heading: {
    MozOsxFontSmoothing: "grayscale",
    WebkitFontSmoothing: "antialiased",
    color: colors.color,
    fontFamily: "'drukmedium', sans-serif",
    fontFeatureSettings: "liga",
    fontWeight: 400,
    letterSpacing: "0.6px",
    lineHeight: 0.875,
    margin: 0,
    textRendering: "optimizeLegibility",
  },
});
