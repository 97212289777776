/**
 * @generated SignedSource<<6a40955605d36f440120b9743222473c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type OrderByDirection = "ASC" | "DESC" | "%future added value";
export type ProductStatus = "DELETED" | "FOR_SALE" | "PENDING_PURCHASE" | "SOLD" | "SUBMITTED" | "UNAVAILABLE" | "%future added value";
export type SalesChannel = "AUCTION" | "SHOP" | "%future added value";
export type SellerProductsOrderByInput = {
  createdAt?: OrderByDirection | null | undefined;
  favorites?: OrderByDirection | null | undefined;
  price?: OrderByDirection | null | undefined;
  title?: OrderByDirection | null | undefined;
  updatedAt?: OrderByDirection | null | undefined;
};
export type PortfolioViewPaginationQuery$variables = {
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  gradingAuthority?: GradingAuthority | null | undefined;
  id: string;
  orderBy?: SellerProductsOrderByInput | null | undefined;
  salesChannel?: ReadonlyArray<SalesChannel> | null | undefined;
  status?: ReadonlyArray<ProductStatus> | null | undefined;
  title?: string | null | undefined;
};
export type PortfolioViewPaginationQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"PortfolioView_user">;
  } | null | undefined;
};
export type PortfolioViewPaginationQuery = {
  response: PortfolioViewPaginationQuery$data;
  variables: PortfolioViewPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": 20,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gradingAuthority"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v4 = {
  "defaultValue": {
    "createdAt": "DESC"
  },
  "kind": "LocalArgument",
  "name": "orderBy"
},
v5 = {
  "defaultValue": [
    "AUCTION",
    "SHOP"
  ],
  "kind": "LocalArgument",
  "name": "salesChannel"
},
v6 = {
  "defaultValue": [
    "FOR_SALE",
    "PENDING_PURCHASE",
    "SUBMITTED",
    "UNAVAILABLE"
  ],
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v8 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v9 = {
  "kind": "Variable",
  "name": "gradingAuthority",
  "variableName": "gradingAuthority"
},
v10 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v11 = {
  "kind": "Variable",
  "name": "salesChannel",
  "variableName": "salesChannel"
},
v12 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v13 = {
  "kind": "Variable",
  "name": "title",
  "variableName": "title"
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v10/*: any*/),
  {
    "fields": [
      (v9/*: any*/),
      (v11/*: any*/),
      (v12/*: any*/),
      (v13/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v17 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PortfolioViewPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "PortfolioView_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "PortfolioViewPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v8/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "portfolioNux",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submissionNux",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sellerStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isApiUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserBulkActions",
                "kind": "LinkedField",
                "name": "bulkActions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canSendFavoritesOffers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canCreateAuctionConsignmentRequests",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v16/*: any*/),
                "concreteType": "SellerProductsConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioFmv",
                    "kind": "LinkedField",
                    "name": "portfolioFmv",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioFmv",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioFmvCount",
                        "storageKey": null
                      },
                      (v17/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "publicStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "salesChannel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuctionLot",
                            "kind": "LinkedField",
                            "name": "auctionLot",
                            "plural": false,
                            "selections": [
                              (v15/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bidCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currentBidAmount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currentBidCurrency",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isClosed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Auction",
                                "kind": "LinkedField",
                                "name": "auction",
                                "plural": false,
                                "selections": [
                                  (v15/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "biddingStartsAt",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImagesConnection",
                            "kind": "LinkedField",
                            "name": "images",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ImagesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "quality",
                                            "value": 100
                                          },
                                          {
                                            "kind": "Literal",
                                            "name": "webp",
                                            "value": true
                                          },
                                          {
                                            "kind": "Literal",
                                            "name": "width",
                                            "value": 500
                                          }
                                        ],
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": "url(quality:100,webp:true,width:500)"
                                      },
                                      (v15/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ComicDetail",
                            "kind": "LinkedField",
                            "name": "comicDetails",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "number",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "grade",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "gradingAuthority",
                                "storageKey": null
                              },
                              (v15/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FairMarketValueScore",
                            "kind": "LinkedField",
                            "name": "fmvScore",
                            "plural": false,
                            "selections": [
                              (v18/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxValue",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minValue",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rank",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                          },
                          (v18/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "favoritesCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canSell",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          (v14/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v16/*: any*/),
                "filters": [
                  "orderBy",
                  "where"
                ],
                "handle": "connection",
                "key": "PortfolioView_products",
                "kind": "LinkedHandle",
                "name": "products"
              },
              (v17/*: any*/)
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "617f4da25839b7ec420029df9ccd2ac9",
    "id": null,
    "metadata": {},
    "name": "PortfolioViewPaginationQuery",
    "operationKind": "query",
    "text": "query PortfolioViewPaginationQuery(\n  $count: Int = 20\n  $cursor: String\n  $gradingAuthority: GradingAuthority\n  $orderBy: SellerProductsOrderByInput = {createdAt: DESC}\n  $salesChannel: [SalesChannel!] = [AUCTION, SHOP]\n  $status: [ProductStatus!] = [FOR_SALE, PENDING_PURCHASE, SUBMITTED, UNAVAILABLE]\n  $title: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...PortfolioView_user_2RDrBB\n    id\n  }\n}\n\nfragment PortfolioFmv_portfolioFmv on PortfolioFmv {\n  portfolioCount\n  portfolioFmv\n  portfolioFmvCount\n}\n\nfragment PortfolioItem_product on Product {\n  id\n  publicStatus\n  salesChannel\n  auctionLot {\n    id\n    bidCount\n    currentBidAmount\n    currentBidCurrency\n    isClosed\n    auction {\n      id\n      biddingStartsAt\n    }\n  }\n  images {\n    edges {\n      node {\n        url(quality: 100, webp: true, width: 500)\n        id\n      }\n    }\n  }\n  comicDetails {\n    title\n    number\n    grade\n    gradingAuthority\n    id\n  }\n  fmvScore {\n    currency\n    maxValue\n    minValue\n    rank\n  }\n  price\n  currency\n  favoritesCount\n  canSell\n}\n\nfragment PortfolioView_user_2RDrBB on User {\n  portfolioNux\n  submissionNux\n  sellerStatus\n  isApiUser\n  bulkActions {\n    canSendFavoritesOffers\n    canCreateAuctionConsignmentRequests\n  }\n  products(first: $count, after: $cursor, orderBy: $orderBy, where: {status: $status, title: $title, gradingAuthority: $gradingAuthority, salesChannel: $salesChannel}) {\n    portfolioFmv {\n      ...PortfolioFmv_portfolioFmv\n    }\n    edges {\n      node {\n        ...PortfolioItem_product\n        id\n        publicStatus\n        salesChannel\n        status\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "91c5da7bb03e1365ff23e5e1ffe808f3";

export default node;
