/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesProductDetailQuery } from "src/types/__generated__/QueriesProductDetailQuery.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import ProductDetailContent from "./ProductDetailContent";

const ProductDetail = (): React.ReactNode | null => {
  const queryRef = useLoaderData() as PreloadedQuery<QueriesProductDetailQuery>;

  return (
    <div {...stylex.props(styles.root)} aria-live="polite">
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <ProductDetailContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

const styles = stylex.create({
  root: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    minHeight: "100%",
  },
});

export default ProductDetail;
