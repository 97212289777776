/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { BidNowContentQuery } from "src/types/__generated__/BidNowContentQuery.graphql";

import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";

import BidNowView from "./BidNowView";

type Props = Readonly<{
  auctionLotId: string | null | undefined;
}>;

const BidNowContent = ({ auctionLotId }: Props): React.ReactNode | null => {
  const data = useLazyLoadQuery<BidNowContentQuery>(
    graphql`
      query BidNowContentQuery($id: ID!) {
        node(id: $id) {
          ... on AuctionLot {
            ...BidNowView_auctionLot
          }
        }
        sentinels(names: ["IsEmployee"]) {
          value
        }
      }
    `,
    {
      id: auctionLotId ?? "",
    },
  );

  const isEmployee = data.sentinels?.[0]?.value ?? false;

  if (data.node == null) {
    return null;
  }

  return <BidNowView employee={isEmployee} queryKey={data.node} />;
};

export default BidNowContent;
