/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import { COMIC_INDEX_BASE_PATH } from "src/app/router/Router";
import {
  HeadingLevel,
  SBActivityIndicator,
  SBHeading,
  SBLink,
  SBPageHeader,
} from "src/sbxui";

import { TitlesForLetter } from "./TitlesForLetter";

const ComicIndexRoot = (): React.ReactNode => {
  const { t } = useTranslation();
  const pageContext = useContext(PageContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const letter = queryParams.get("letter") ?? "A";

  useEffect(() => {
    pageContext?.setTitle(t("comicIndex.documentTitle"));
  }, [t, pageContext]);

  return (
    <div>
      <SBPageHeader title={t("comicIndex.pageTitle")} />
      <div>
        <SBHeading level={HeadingLevel.H2}>
          {t("comicIndex.popularTitles")}
        </SBHeading>
        <div {...stylex.props(styles.popularTitles)}>
          {/* eslint-disable react/jsx-no-literals */}
          <SBLink to="">Action Comics</SBLink>
          <SBLink to="">Action Comics</SBLink>
          <SBLink to="">Action Comics</SBLink>
          <SBLink to="">Action Comics</SBLink>
          <SBLink to="">Action Comics</SBLink>
          <SBLink to="">Action Comics</SBLink>
          <SBLink to="">Action Comics</SBLink>
          {/* eslint-enable react/jsx-no-literals */}
        </div>
      </div>
      <div {...stylex.props(styles.chars)}>
        {new Array(26)
          .fill(1)
          .map((_, ii) => String.fromCharCode(65 + ii))
          .map((char) => (
            <div
              {...stylex.props(styles.char, letter === char && styles.selected)}
              key={char}
            >
              <SBLink to={`${COMIC_INDEX_BASE_PATH}?letter=${char}`}>
                {char}
              </SBLink>
            </div>
          ))}
      </div>
      <div>
        <Suspense fallback={<SBActivityIndicator />}>
          <TitlesForLetter letter={letter} />
        </Suspense>
      </div>
    </div>
  );
};

const styles = stylex.create({
  char: {
    backgroundColor: {
      ":hover": "#FFF",
    },
    textAlign: "center",
  },
  chars: {
    display: "grid",
    gridGap: 5,
    gridTemplateColumns: "repeat(26, 1fr)",
    marginTop: 40,
  },
  popularTitles: {
    display: "flex",
    flexFlow: "column",
    flexWrap: "wrap",
    marginTop: 20,
    maxHeight: 300,
  },
  selected: {
    backgroundColor: "#CDCDCD",
  },
});

export default React.memo(ComicIndexRoot);
