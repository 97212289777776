/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TFunction } from "i18next";

import { kebabCase } from "lodash";

export const formatGrade = ({
  grade,
  gradingAuthority,
  t,
}: {
  grade: number | null | undefined;
  gradingAuthority: string | null | undefined;
  t: TFunction;
}): string => {
  const gradeValue =
    (grade ?? 0) === 0 ? t("raw-grades.0-0") : grade?.toFixed(1);
  return t("account.offers.comic-details2", {
    grade:
      gradingAuthority === "RAW"
        ? t(`raw-grades.${kebabCase(gradeValue)}`)
        : gradeValue,
    gradingAuthority: t(
      `grading-authority.${kebabCase(gradingAuthority ?? "UNKNOWN")}`,
    ),
  });
};
