/**
 * @generated SignedSource<<c08fc02b146c2f8c8b2565322f111666>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MakeOfferContentQuery$variables = {
  id: string;
};
export type MakeOfferContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MakeOfferView_product">;
};
export type MakeOfferContentQuery = {
  response: MakeOfferContentQuery$data;
  variables: MakeOfferContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MakeOfferContentQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "MakeOfferView_product"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MakeOfferContentQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ComicDetail",
                "kind": "LinkedField",
                "name": "comicDetails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "grade",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "gradingAuthority",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "variant",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "publisher",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "number",
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minOfferPrice",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "minOfferPercentage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              }
            ],
            "type": "Product",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "534be3bda07ac7eb57792ecf283b6433",
    "id": null,
    "metadata": {},
    "name": "MakeOfferContentQuery",
    "operationKind": "query",
    "text": "query MakeOfferContentQuery(\n  $id: ID!\n) {\n  ...MakeOfferView_product\n}\n\nfragment MakeOfferView_product on Query {\n  node(id: $id) {\n    __typename\n    ... on Product {\n      id\n      comicDetails {\n        grade\n        gradingAuthority\n        title\n        variant\n        publisher\n        number\n        id\n      }\n      minOfferPrice\n      minOfferPercentage\n      price\n      currency\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc24e1a35f7c6b43b31fd5d6b5234a70";

export default node;
