/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountOrderCard_order$key } from "src/types/__generated__/AccountOrderCard_order.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";
import { Link } from "react-router-dom";

import AccountInfoCard from "src/app/components/account/AccountInfoCard";
import { ReportProblemModal } from "src/app/components/problem/ReportProblemModal";
import { OrderDetailContext } from "src/app/context/order-detail";
import { ProductFeedbackModal } from "src/app/pages/account/orders/ProductFeedbackModal";
import {
  ACCOUNT_ORDER_DETAILS_PATH,
  SELLER_ACCOUNT_SOLD_HISTORY_DETAILS_PATH,
} from "src/app/router/Router";
import { SBDate, SBIcon, SBImage } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { DateFormatType, formatMoney } from "src/utils";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountOrderCard_order$key;
  source?: "SELLER" | "BUYER";
}>;

const AccountOrderCard = ({
  queryKey,
  source = "BUYER",
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();
  const [isPending, startTransition] = useTransition();
  const { setOrderId } = useContext(OrderDetailContext);
  const [isReportProblemModalOpen, setIsReportProblemModalOpen] =
    useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  const order = useFragment(
    graphql`
      fragment AccountOrderCard_order on Order {
        id
        surrogateId
        createdAt
        publicStatus
        total
        currency
        product {
          ...ProductFeedbackModal_product
          isViewerOwner
          canLeaveFeedback
          images(first: 1) {
            edges {
              node {
                url(quality: 100, width: 300, webp: true)
              }
            }
          }
          comicDetails {
            title
            number
            gradingAuthority
            grade
          }
        }
      }
    `,
    queryKey,
  );

  const handleLinkClick =
    (surrogateId: number) => (event: React.SyntheticEvent) => {
      event.preventDefault();
      startTransition(() => {
        history.pushState(
          {},
          "",
          ACCOUNT_ORDER_DETAILS_PATH.replace(":id", surrogateId.toString()),
        );
        setOrderId(surrogateId.toString());
      });
    };

  const handleProblemReportClick = () => {
    setIsReportProblemModalOpen(true);
  };

  const handleFeedbackClick = () => {
    setIsFeedbackModalOpen(true);
  };

  const { id, product, total, currency, publicStatus, createdAt, surrogateId } =
    order;
  const { comicDetails, images, canLeaveFeedback, isViewerOwner } =
    product ?? {};
  const { title, gradingAuthority, grade, number } = comicDetails ?? {};

  const dotColor =
    publicStatus === "DELIVERED"
      ? colors.orderStatusDelivered
      : colors.orderStatusInTransit;

  const gradeValue = grade === 0 ? t("raw-grades.0-0") : grade?.toFixed(1);

  return (
    <div {...stylex.props(styles.root)}>
      <Link
        key={id}
        {...stylex.props(auto, styles.link, isPending && styles.linkIsPending)}
        to={(source === "SELLER"
          ? SELLER_ACCOUNT_SOLD_HISTORY_DETAILS_PATH
          : ACCOUNT_ORDER_DETAILS_PATH
        ).replace(":id", surrogateId.toString())}
        onClick={handleLinkClick(surrogateId)}
      >
        <AccountInfoCard
          content={
            <>
              <div>{formatMoney(total, currency, i18n.language, true)}</div>
              <div {...stylex.props(styles.status)}>
                {t(
                  `account.orders.status.${publicStatus.toLowerCase().replace(/_/gu, "-")}`,
                )}
                <div
                  {...stylex.props(styles.dot, styles.dotBackground(dotColor))}
                />
              </div>
              <div {...stylex.props(styles.date)}>
                <SBDate
                  dateString={createdAt}
                  format={DateFormatType.Short}
                  language={i18n.language}
                />
              </div>
            </>
          }
          heading={t("account.orders.comic-details1", { number, title })}
          image={
            <SBImage
              alt=""
              src={images?.edges[0]?.node?.url ?? ""}
              width="100%"
            />
          }
          subHeading={t("account.orders.comic-details2", {
            grade:
              gradingAuthority === "RAW"
                ? t(`raw-grades.${kebabCase(gradeValue)}`)
                : gradeValue,
            gradingAuthority: t(
              `grading-authority.${kebabCase(gradingAuthority)}`,
            ),
          })}
        />
      </Link>
      {!isViewerOwner && (
        <div {...stylex.props(styles.actions)}>
          {canLeaveFeedback ? (
            <button
              type="button"
              onClick={handleFeedbackClick}
              {...stylex.props(styles.feedbackButton)}
            >
              <SBIcon icon="feedback" style={styles.icon} />
            </button>
          ) : null}
          <button
            type="button"
            onClick={handleProblemReportClick}
            {...stylex.props(styles.reportButton)}
          >
            <SBIcon icon="feedback" style={styles.icon} />
          </button>
        </div>
      )}

      <ReportProblemModal
        isOpen={isReportProblemModalOpen}
        orderId={id}
        problemType="ORDER_ISSUE"
        setOpen={setIsReportProblemModalOpen}
        onSuccess={() => {
          setIsReportProblemModalOpen(false);
        }}
      />
      <ProductFeedbackModal
        isOpen={isFeedbackModalOpen}
        queryKey={order.product}
        setOpen={setIsFeedbackModalOpen}
        onSuccess={() => {
          setIsFeedbackModalOpen(false);
        }}
      />
    </div>
  );
};

const styles = stylex.create({
  actions: {
    alignItems: "flex-end",
    bottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    position: "absolute",
    right: 10,
    zIndex: 10,
  },
  date: {
    fontSize: 13,
    marginTop: 8,
  },
  dot: {
    borderRadius: 10,
    height: 10,
    marginLeft: 6,
    width: 10,
  },
  dotBackground: (backgroundColor: string) => ({
    backgroundColor,
  }),
  feedbackButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    marginRight: 16,
    padding: 0,
  },
  icon: {
    color: colors.color,
  },
  link: {
    borderRadius: 8,
    display: "block",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  linkIsPending: {
    opacity: 0.4,
  },
  order: {
    backgroundColor: colors.backgroundEmphasisColor,
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    marginBottom: 10,
    padding: 12,
  },
  reportButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    padding: 0,
  },
  root: {
    position: "relative",
  },
  status: {
    alignItems: "center",
    display: "flex",
    marginTop: 8,
  },
  viewButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    marginRight: 16,
    padding: 0,
  },
});

export default AccountOrderCard;
