/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { colors } from "src/themes/colors.stylex";
import { calculateTimestamp } from "src/utils";

const ONE_HOUR_MS = 60 * 60 * 1000;
const TIMER_INCREMENT_MS = 500;

type Options = Readonly<{
  expired?: string;
  short?: boolean;
}>;

export const useCountdownTimer = (
  expiry: number,
  options?: Options,
): Readonly<{
  color: string;
  expiresIn: string;
  hasExpired: boolean;
}> => {
  const { t } = useTranslation();

  const expired = options?.expired ?? t("countdown.expired");
  const short = options?.short ?? false;

  const intervalId = useRef<
    ReturnType<typeof setInterval> | string | number | undefined
  >();

  const now = Date.now();
  const difference = expiry - now;
  const timestamp = useMemo(() => calculateTimestamp(difference), [difference]);
  const { days, hours, minutes, seconds } = timestamp;

  let initialHasExpired = false;
  let initialExpiresIn = t(
    `countdown.expires.timestamp${short ? "-short" : ""}.days-hours`,
    {
      days,
      hours,
    },
  );
  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    initialExpiresIn = expired;
    initialHasExpired = true;
  } else if (days <= 0 && hours <= 0 && minutes <= 0) {
    initialExpiresIn = t(
      `countdown.expires.timestamp${short ? "-short" : ""}.seconds`,
      {
        seconds,
      },
    );
  } else if (days <= 0 && hours <= 0) {
    initialExpiresIn = t(
      `countdown.expires.timestamp${short ? "-short" : ""}.minutes-seconds`,
      {
        minutes,
        seconds,
      },
    );
  } else if (days <= 0) {
    initialExpiresIn = t(
      `countdown.expires.timestamp${short ? "-short" : ""}.hours-minutes`,
      {
        hours,
        minutes,
      },
    );
  }

  const [hasExpired, setHasExpired] = useState(initialHasExpired);
  const [expiresIn, setExpiresIn] = useState(initialExpiresIn);
  const [color, setColor] = useState(colors.color);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      const nw = Date.now();
      const diff = expiry - nw;
      const ts = calculateTimestamp(diff);
      if (
        diff <= 0 ||
        Number.isNaN(diff) ||
        (ts.days === 0 &&
          ts.hours === 0 &&
          ts.minutes === 0 &&
          ts.seconds === 0)
      ) {
        clearInterval(intervalId.current);
        intervalId.current = undefined;
        setHasExpired(true);
        setExpiresIn(expired);
        setColor(colors.colorEmphasis);
      } else {
        let newExpiresIn = t(
          `countdown.expires.timestamp${short ? "-short" : ""}.days-hours`,
          {
            days: ts.days,
            hours: ts.hours,
          },
        );
        if (ts.days === 0 && ts.hours === 0 && ts.minutes === 0) {
          newExpiresIn = t(
            `countdown.expires.timestamp${short ? "-short" : ""}.seconds`,
            {
              seconds: ts.seconds,
            },
          );
        } else if (ts.days === 0 && ts.hours === 0) {
          newExpiresIn = t(
            `countdown.expires.timestamp${
              short ? "-short" : ""
            }.minutes-seconds`,
            {
              minutes: ts.minutes,
              seconds: ts.seconds,
            },
          );
        } else if (ts.days === 0) {
          newExpiresIn = t(
            `countdown.expires.timestamp${short ? "-short" : ""}.hours-minutes`,
            {
              hours: ts.hours,
              minutes: ts.minutes,
            },
          );
        }
        setHasExpired(false);
        setExpiresIn(newExpiresIn);
        if (diff <= ONE_HOUR_MS) {
          setColor(colors.colorEmphasis);
        } else {
          setColor(colors.color);
        }
      }
    }, TIMER_INCREMENT_MS);
    return () => {
      clearInterval(intervalId.current);
      intervalId.current = undefined;
    };
  }, [expired, expiry, short, t]);

  return { color, expiresIn, hasExpired };
};
