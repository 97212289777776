/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import stylex from "@stylexjs/stylex";
import * as React from "react";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { SBIcon } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<
  React.PropsWithChildren<{
    afterLeave?: () => void;
    isOpen: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  }>
>;

export const SBTakeover = ({
  afterLeave,
  children,
  setOpen,
  isOpen,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const handleCloseClick = () => {
    setOpen(false);
  };

  return (
    <Transition
      afterLeave={afterLeave}
      appear={true}
      as={Fragment}
      show={isOpen}
    >
      <Dialog
        {...stylex.props(styles.dialog)}
        as="div"
        static={true}
        onClose={handleCloseClick}
      >
        <TransitionChild
          enter={stylex.props(styles.backdropEnter).className}
          enterFrom={stylex.props(styles.backdropEnterFrom).className}
          enterTo={stylex.props(styles.backdropEnterTo).className}
          leave={stylex.props(styles.backdropLeave).className}
          leaveFrom={stylex.props(styles.backdropLeaveFrom).className}
          leaveTo={stylex.props(styles.backdropLeaveTo).className}
        >
          <div {...stylex.props(auto, styles.backdrop)} aria-hidden="true" />
        </TransitionChild>
        <div {...stylex.props(styles.container)}>
          <button
            {...stylex.props(auto, styles.back)}
            type="button"
            onClick={handleCloseClick}
          >
            <SBIcon icon="arrow_back" style={styles.backIcon} />
            <span {...stylex.props(auto, styles.backLabel)}>
              {t("common.buttons.back")}
            </span>
          </button>
          <TransitionChild
            enter={stylex.props(styles.containerEnter).className}
            enterFrom={stylex.props(styles.containerEnterFrom).className}
            enterTo={stylex.props(styles.containerEnterTo).className}
            leave={stylex.props(styles.containerLeave).className}
            leaveFrom={stylex.props(styles.containerLeaveFrom).className}
            leaveTo={stylex.props(styles.containerLeaveTo).className}
          >
            <DialogPanel {...stylex.props(styles.panel)}>
              {children}
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

const styles = stylex.create({
  back: {
    alignItems: "center",
    backgroundColor: "transparent",
    borderWidth: 0,
    color: colors.takeoverColor,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 24,
    justifyContent: "flex-start",
    left: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 64,
    },
    lineHeight: 0.875,
    margin: 0,
    outline: {
      ":focus-visible": colors.outline,
    },
    position: "absolute",
    textDecorationLine: "none",
    top: {
      [MOBILE]: 24,
      [TABLET]: 24,
      default: 64,
    },
    zIndex: 11,
  },
  backIcon: {
    color: colors.takeoverColor,
  },
  backLabel: {
    marginLeft: 8,
    textTransform: "uppercase",
  },
  backdrop: {
    backgroundColor: colors.takeoverBackgroundColor,
    inset: 0,
    position: "fixed",
  },
  backdropEnter: {
    transitionDuration: "300ms",
    transitionProperty: "opacity",
    transitionTimingFunction: "ease-out",
  },
  backdropEnterFrom: {
    opacity: 0,
  },
  backdropEnterTo: {
    opacity: 1,
  },
  backdropLeave: {
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-in",
  },
  backdropLeaveFrom: {
    opacity: 1,
  },
  backdropLeaveTo: {
    opacity: 0,
  },
  container: {
    alignItems: "center",
    display: {
      [MOBILE]: "grid",
      [TABLET]: "grid",
      default: "flex",
    },
    gridTemplateColumns: "repeat(1, 1fr)",
    inset: 0,
    justifyContent: "center",
    overflowY: "auto",
    position: "fixed",
  },
  containerEnter: {
    transitionDelay: "200ms",
    transitionDuration: "300ms",
    transitionProperty: "opacity, transform",
    transitionTimingFunction: "ease-out",
  },
  containerEnterFrom: {
    opacity: 0,
    transform: "scale(0.95)",
  },
  containerEnterTo: {
    opacity: 1,
    transform: "scale(1)",
  },
  containerLeave: {
    transitionDuration: "200ms",
    transitionTimingFunction: "ease-in",
  },
  containerLeaveFrom: {
    opacity: 1,
    transform: "scale(1)",
  },
  containerLeaveTo: {
    opacity: 0,
    transform: "scale(0.95)",
  },
  dialog: {
    position: "relative",
    zIndex: 10,
  },
  panel: {
    marginInline: "auto",
  },
});
