/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ErrorResponse } from "src/app/types/RelayTypes";
import type { SellerAccountStoreUrlField_user$key } from "src/types/__generated__/SellerAccountStoreUrlField_user.graphql";
import type { SellerAccountStoreUrlFieldMutation } from "src/types/__generated__/SellerAccountStoreUrlFieldMutation.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import { SELLER_PATH } from "src/app/router/Router";
import {
  ButtonType,
  SBButton,
  SBErrorMessage,
  SBIcon,
  SBLink,
  SBModal,
  SBSuccessMessage,
  SBTextInput,
  TextInputType,
} from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  queryKey: SellerAccountStoreUrlField_user$key;
}>;

const SellerAccountStoreUrlField = ({ queryKey }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment SellerAccountStoreUrlField_user on User {
        id
        shopUrl
        shopUrlQrCode
      }
    `,
    queryKey,
  );

  const { shopUrl, id: userId, shopUrlQrCode } = data;

  const [newShopUrl, setNewShopUrl] = useState<string>(shopUrl ?? "");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [commit, isInFlight] = useMutation<SellerAccountStoreUrlFieldMutation>(
    graphql`
      mutation SellerAccountStoreUrlFieldMutation(
        $input: UpdateSellerShopUrlInput!
      ) {
        updateSellerShopUrl(updateSellerShopUrlInput: $input) {
          ...SellerAccountStoreUrlField_user
        }
      }
    `,
  );

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isInFlight) {
      return;
    }
    commit({
      onCompleted() {
        setSuccessMessage(t("seller-account.success.shop-name"));
      },
      onError(error: unknown) {
        const errorResponse = error as ErrorResponse;
        let message =
          errorResponse?.res?.errors?.[0].message ?? t("signup.errors.default");
        if (
          errorResponse?.res?.errors?.[0]?.extensions?.exception?.status === 429
        ) {
          message = t("signup.errors.http429");
        }
        setErrorMessage(message);
      },
      variables: {
        input: {
          shopUrl: newShopUrl,
          userId,
        },
      },
    });
  };

  const handleChangeShopUrl = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewShopUrl(event.currentTarget.value);
  };

  const handleQRClick = () => {
    setIsModalOpen(true);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SBErrorMessage message={errorMessage} style={styles.message} />
        <SBSuccessMessage message={successMessage} style={styles.message} />
        <fieldset {...stylex.props(styles.formFields)}>
          <div {...stylex.props(styles.nameFields)}>
            <SBTextInput
              disabled={isInFlight}
              id="newShopUrl"
              label={t("seller-account.field.store-url")}
              showLabel={true}
              style={[styles.input, styles.inputFirst]}
              type={TextInputType.Text}
              value={newShopUrl}
              onChange={handleChangeShopUrl}
            />
            <div {...stylex.props(styles.icons)}>
              {newShopUrl !== "" && (
                <>
                  <button
                    type="button"
                    onClick={handleQRClick}
                    {...stylex.props(styles.qrButton)}
                  >
                    <SBIcon icon="qr_code" style={styles.icon} />
                  </button>
                  <SBLink
                    external={true}
                    href={SELLER_PATH.replace(":shopUrl", shopUrl ?? "")}
                  >
                    <SBIcon icon="open_in_new" style={styles.icon} />
                  </SBLink>
                </>
              )}
            </div>
          </div>
          <div {...stylex.props(styles.button)}>
            <SBButton
              disabled={isInFlight}
              loading={isInFlight}
              title={t("seller-account.buttons.store-url")}
              type={ButtonType.Submit}
            />
          </div>
        </fieldset>
      </form>
      <SBModal
        headerText={t("seller-account.modal.shop-url-qr-code.title", {
          shopUrl,
        })}
        isOpen={isModalOpen}
        setOpen={setIsModalOpen}
      >
        <div {...stylex.props(styles.modal)}>
          <img src={shopUrlQrCode ?? ""} />
        </div>
      </SBModal>
    </div>
  );
};

const styles = stylex.create({
  button: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  emailField: {
    marginBottom: 16,
  },
  formFields: {
    borderWidth: 0,
    margin: 0,
    padding: 0,
  },
  icon: {
    color: colors.color,
  },
  icons: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    marginBottom: 16,
  },
  inputFirst: {
    marginRight: 16,
  },
  message: {
    marginBottom: 16,
  },
  modal: {
    display: "flex",
    justifyContent: "center",
  },
  nameFields: {
    display: "flex",
    justifyContent: "space-between",
  },
  qrButton: {
    alignItems: "center",
    background: "none",
    borderWidth: 0,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    margin: 0,
    marginRight: 16,
    padding: 0,
  },
});

export default SellerAccountStoreUrlField;
