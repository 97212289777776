/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}>;

const OrderDetailSummaryCard = ({
  leftContent,
  rightContent,
}: Props): React.ReactNode => {
  return (
    <div {...stylex.props(styles.detailsRow)}>
      <div {...stylex.props(auto, styles.leftColumn)}>{leftContent}</div>
      <div {...stylex.props(auto, styles.rightColumn)}>{rightContent}</div>
    </div>
  );
};

const styles = stylex.create({
  detailsRow: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    justifyContent: "space-between",
  },
  leftColumn: {
    color: colors.color,
    fontWeight: 600,
  },
  rightColumn: {
    color: colors.color,
    display: "flex",
    justifyContent: "end",
  },
});

export default OrderDetailSummaryCard;
