/**
 * @generated SignedSource<<6b9d4db6741e9952f1274bbf2dbd4252>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PortfolioFmv_portfolioFmv$data = {
  readonly portfolioCount: number | null | undefined;
  readonly portfolioFmv: number | null | undefined;
  readonly portfolioFmvCount: number | null | undefined;
  readonly " $fragmentType": "PortfolioFmv_portfolioFmv";
};
export type PortfolioFmv_portfolioFmv$key = {
  readonly " $data"?: PortfolioFmv_portfolioFmv$data;
  readonly " $fragmentSpreads": FragmentRefs<"PortfolioFmv_portfolioFmv">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PortfolioFmv_portfolioFmv",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portfolioCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portfolioFmv",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "portfolioFmvCount",
      "storageKey": null
    }
  ],
  "type": "PortfolioFmv",
  "abstractKey": null
};

(node as any).hash = "0de86523a666ef0b739276d237e7cce9";

export default node;
