/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { GradingAuthority } from "src/types/__generated__/ShopPaginationQuery.graphql";

import stylex from "@stylexjs/stylex";
import { t } from "i18next";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useState } from "react";

import { PortfolioContext } from "src/app/context/portfolio";
import { GradingAuthorityEnum } from "src/enums/grading-authority.enum";
import {
  ButtonType,
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBHeading,
  SBIcon,
  SBModal,
  SBToggleButton,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

const orderedGradingAuthorities = [
  GradingAuthorityEnum.CGC,
  GradingAuthorityEnum.CBCS,
  GradingAuthorityEnum.RAW,
];

type Props = Readonly<{
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const PortfolioFilterModal = ({ isOpen, setOpen }: Props): React.ReactNode => {
  const { gradingAuthority, setGradingAuthority } =
    useContext(PortfolioContext);

  const [newGradingAuthority, setNewGradingAuthority] = useState<
    GradingAuthority | string
  >(() => {
    let result = "";
    for (const authority of orderedGradingAuthorities) {
      if (gradingAuthority === authority) {
        result = authority;
      }
    }
    return result;
  });

  const onApplyFilters = (_event: React.SyntheticEvent) => {
    setGradingAuthority(
      newGradingAuthority === ""
        ? null
        : (newGradingAuthority as GradingAuthority),
    );
    setOpen(false);
  };

  const onReset = (_event: React.SyntheticEvent) => {
    setNewGradingAuthority("");
  };

  const handleGradingAuthorityChange =
    (nextAuthority: GradingAuthority) =>
    (_event: React.ChangeEvent<HTMLInputElement>) => {
      setNewGradingAuthority((prevAuthority) =>
        prevAuthority === nextAuthority ? "" : nextAuthority,
      );
    };

  return (
    <SBModal
      footer={
        <div {...stylex.props(styles.footer)}>
          <div {...stylex.props(styles.reset)}>
            <button
              {...stylex.props(auto, styles.resetButton)}
              type="button"
              onClick={onReset}
            >
              <SBIcon
                fill={false}
                icon="restart_alt"
                style={styles.resetIcon}
              />
              {t("modal.filter.reset")}
            </button>
          </div>
          <SBButton
            style={styles.applyButton}
            title={t("modal.filter.apply")}
            type={ButtonType.Button}
            variation={ButtonVariation.Emphasis}
            onClick={onApplyFilters}
          />
        </div>
      }
      headerText={t("modal.filter.header")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <fieldset {...stylex.props(styles.fieldset)}>
        <div {...stylex.props(styles.row, styles.rowFirst)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {t("modal.filter.grading-authority")}
          </SBHeading>
          <div {...stylex.props(styles.toggles)}>
            {orderedGradingAuthorities.map((ga) => (
              <SBToggleButton
                key={ga}
                block={true}
                id={ga}
                selected={newGradingAuthority === ga}
                title={t(`grading-authority.${kebabCase(ga)}`)}
                onChange={handleGradingAuthorityChange(ga)}
              />
            ))}
          </div>
        </div>
      </fieldset>
    </SBModal>
  );
};

const styles = stylex.create({
  applyButton: {
    flexGrow: 0,
  },
  fieldset: {
    borderWidth: 0,
    margin: 0,
    padding: 16,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: 20,
    marginBottom: 12,
  },
  range: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rangeIndicator: {
    marginInline: 8,
  },
  reset: {
    alignItems: "center",
    display: "flex",
  },
  resetButton: {
    alignItems: "center",
    appearance: "none",
    backgroundColor: "transparent",
    borderRadius: 40,
    borderWidth: 0,
    color: colors.color,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginRight: 16,
    outline: {
      ":focus-visible": colors.outline,
    },
  },
  resetIcon: {
    color: colors.color,
    marginRight: 8,
  },
  row: {
    marginTop: 24,
  },
  rowFirst: {
    marginTop: 0,
  },
  toggles: {
    display: "grid",
    gridColumnGap: 24,
    gridRowGap: 12,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  togglesEras: {
    marginTop: 12,
  },
});

export default PortfolioFilterModal;
