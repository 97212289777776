/**
 * @generated SignedSource<<d0e82a101f0ccef97d279d970c4a377e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GradingAuthority = "CBCS" | "CGC" | "RAW" | "%future added value";
export type OrderByDirection = "ASC" | "DESC" | "%future added value";
export type ProductStatus = "DELETED" | "FOR_SALE" | "PENDING_PURCHASE" | "SOLD" | "SUBMITTED" | "UNAVAILABLE" | "%future added value";
export type SalesChannel = "AUCTION" | "SHOP" | "%future added value";
export type SellerProductsOrderByInput = {
  createdAt?: OrderByDirection | null | undefined;
  favorites?: OrderByDirection | null | undefined;
  price?: OrderByDirection | null | undefined;
  title?: OrderByDirection | null | undefined;
  updatedAt?: OrderByDirection | null | undefined;
};
export type QueriesPortfolioQuery$variables = {
  gradingAuthority?: GradingAuthority | null | undefined;
  orderBy?: SellerProductsOrderByInput | null | undefined;
  salesChannel?: ReadonlyArray<SalesChannel> | null | undefined;
  status?: ReadonlyArray<ProductStatus> | null | undefined;
  title?: string | null | undefined;
};
export type QueriesPortfolioQuery$data = {
  readonly viewer: {
    readonly user: {
      readonly " $fragmentSpreads": FragmentRefs<"PortfolioView_user">;
    } | null | undefined;
  };
};
export type QueriesPortfolioQuery = {
  response: QueriesPortfolioQuery$data;
  variables: QueriesPortfolioQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "gradingAuthority"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "salesChannel"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "title"
},
v5 = {
  "kind": "Variable",
  "name": "gradingAuthority",
  "variableName": "gradingAuthority"
},
v6 = {
  "kind": "Variable",
  "name": "orderBy",
  "variableName": "orderBy"
},
v7 = {
  "kind": "Variable",
  "name": "salesChannel",
  "variableName": "salesChannel"
},
v8 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v9 = {
  "kind": "Variable",
  "name": "title",
  "variableName": "title"
},
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  (v6/*: any*/),
  {
    "fields": [
      (v5/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v11 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__id",
      "storageKey": null
    }
  ]
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesPortfolioQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "args": [
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "PortfolioView_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "QueriesPortfolioQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "portfolioNux",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "submissionNux",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "sellerStatus",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isApiUser",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserBulkActions",
                "kind": "LinkedField",
                "name": "bulkActions",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canSendFavoritesOffers",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "canCreateAuctionConsignmentRequests",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "concreteType": "SellerProductsConnection",
                "kind": "LinkedField",
                "name": "products",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PortfolioFmv",
                    "kind": "LinkedField",
                    "name": "portfolioFmv",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioCount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioFmv",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "portfolioFmvCount",
                        "storageKey": null
                      },
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SellerProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Product",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v12/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "publicStatus",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "salesChannel",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AuctionLot",
                            "kind": "LinkedField",
                            "name": "auctionLot",
                            "plural": false,
                            "selections": [
                              (v12/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "bidCount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currentBidAmount",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "currentBidCurrency",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isClosed",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Auction",
                                "kind": "LinkedField",
                                "name": "auction",
                                "plural": false,
                                "selections": [
                                  (v12/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "biddingStartsAt",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ImagesConnection",
                            "kind": "LinkedField",
                            "name": "images",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ImagesEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Image",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "quality",
                                            "value": 100
                                          },
                                          {
                                            "kind": "Literal",
                                            "name": "webp",
                                            "value": true
                                          },
                                          {
                                            "kind": "Literal",
                                            "name": "width",
                                            "value": 500
                                          }
                                        ],
                                        "kind": "ScalarField",
                                        "name": "url",
                                        "storageKey": "url(quality:100,webp:true,width:500)"
                                      },
                                      (v12/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ComicDetail",
                            "kind": "LinkedField",
                            "name": "comicDetails",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "number",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "grade",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "gradingAuthority",
                                "storageKey": null
                              },
                              (v12/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "FairMarketValueScore",
                            "kind": "LinkedField",
                            "name": "fmvScore",
                            "plural": false,
                            "selections": [
                              (v13/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "maxValue",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "minValue",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "rank",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "price",
                            "storageKey": null
                          },
                          (v13/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "favoritesCount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canSell",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v10/*: any*/),
                "filters": [
                  "orderBy",
                  "where"
                ],
                "handle": "connection",
                "key": "PortfolioView_products",
                "kind": "LinkedHandle",
                "name": "products"
              },
              (v12/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4609022390f0e71e3bc2f0bc1f025437",
    "id": null,
    "metadata": {},
    "name": "QueriesPortfolioQuery",
    "operationKind": "query",
    "text": "query QueriesPortfolioQuery(\n  $status: [ProductStatus!]\n  $title: String\n  $gradingAuthority: GradingAuthority\n  $salesChannel: [SalesChannel!]\n  $orderBy: SellerProductsOrderByInput\n) {\n  viewer {\n    user {\n      ...PortfolioView_user_2G91ra\n      id\n    }\n  }\n}\n\nfragment PortfolioFmv_portfolioFmv on PortfolioFmv {\n  portfolioCount\n  portfolioFmv\n  portfolioFmvCount\n}\n\nfragment PortfolioItem_product on Product {\n  id\n  publicStatus\n  salesChannel\n  auctionLot {\n    id\n    bidCount\n    currentBidAmount\n    currentBidCurrency\n    isClosed\n    auction {\n      id\n      biddingStartsAt\n    }\n  }\n  images {\n    edges {\n      node {\n        url(quality: 100, webp: true, width: 500)\n        id\n      }\n    }\n  }\n  comicDetails {\n    title\n    number\n    grade\n    gradingAuthority\n    id\n  }\n  fmvScore {\n    currency\n    maxValue\n    minValue\n    rank\n  }\n  price\n  currency\n  favoritesCount\n  canSell\n}\n\nfragment PortfolioView_user_2G91ra on User {\n  portfolioNux\n  submissionNux\n  sellerStatus\n  isApiUser\n  bulkActions {\n    canSendFavoritesOffers\n    canCreateAuctionConsignmentRequests\n  }\n  products(first: 20, orderBy: $orderBy, where: {status: $status, title: $title, gradingAuthority: $gradingAuthority, salesChannel: $salesChannel}) {\n    portfolioFmv {\n      ...PortfolioFmv_portfolioFmv\n    }\n    edges {\n      node {\n        ...PortfolioItem_product\n        id\n        publicStatus\n        salesChannel\n        status\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
  }
};
})();

(node as any).hash = "19a33c5ccafcc26cddfe06d1f4245776";

export default node;
