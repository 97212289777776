/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";
import { Translation } from "react-i18next";

import { SBErrorPage } from "src/sbxui";

type Props = React.PropsWithChildren;

interface State {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error == null) {
      return children;
    }

    // @ts-ignore This is fine; it’s a Relay error response.
    const text = error?.res?.text;
    const response = text == null ? null : JSON.parse(text);

    const message =
      // Relay error
      response?.errors?.[0]?.message ??
      // JS exception;
      error?.message;
    const code = response?.errors?.[0]?.extensions?.code ?? "E9999";

    return (
      <Translation>
        {(t) => (
          <SBErrorPage
            description={message ?? t("not-found.description")}
            errorCode={code}
            title={t("not-found.page-title")}
          />
        )}
      </Translation>
    );
  }
}
