/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";

import { SBFmvDot } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  block?: boolean;
  fmvRank?: 1 | 2 | 3 | 4 | null;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selected: boolean;
  style?: StyleXStyles;
  title: string;
}>;

export const SBToggleButton = ({
  block = false,
  fmvRank,
  selected,
  title,
  id,
  onChange,
  style,
}: Props): React.ReactNode => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (_event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
  };

  const handleBlur = (_event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
  };

  return (
    <label
      {...stylex.props(
        auto,
        styles.label,
        block && styles.block,
        isFocused && styles.labelFocused,
        style,
      )}
      htmlFor={id}
    >
      <input
        {...stylex.props(styles.checkbox)}
        checked={selected}
        id={id}
        type="checkbox"
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
      />
      <div
        {...stylex.props(
          auto,
          styles.button,
          block && styles.block,
          selected ? styles.selected : styles.unselected,
        )}
      >
        <span {...stylex.props(styles.title)}>{title}</span>
        {fmvRank ? <SBFmvDot rank={fmvRank} style={styles.fmvRank} /> : null}
      </div>
    </label>
  );
};

const styles = stylex.create({
  block: {
    width: "100%",
  },
  button: {
    alignItems: "center",
    borderColor: colors.buttonBackgroundColor,
    borderRadius: 16,
    borderStyle: "solid",
    borderWidth: 1,
    boxSizing: "border-box",
    cursor: "pointer",
    display: "inline-flex",
    height: 32,
    justifyContent: "center",
    outline: {
      ":focus-visible": colors.outline,
    },
    paddingInline: 16,
    textDecorationLine: "none",
  },
  checkbox: {
    display: "block",
    height: 0,
    overflow: "hidden",
    width: 0,
  },
  fmvRank: {
    marginLeft: 8,
  },
  label: {
    alignItems: "flex-start",
    borderRadius: 16,
    display: "inline-flex",
    justifyContent: "flex-start",
  },
  labelFocused: {
    outline: colors.outline,
  },
  selected: {
    backgroundColor: {
      ":hover": colors.buttonBackgroundColor,
      default: colors.buttonBackgroundColor,
    },
    color: colors.buttonColor,
  },
  title: {
    alignItems: "center",
    boxSizing: "border-box",
    display: "inline-flex",
    lineHeight: "32px",
    margin: 0,
    whiteSpace: "nowrap",
  },
  unselected: {
    backgroundColor: {
      ":hover": "transparent",
      default: "transparent",
    },
    color: colors.color,
  },
});
