/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import * as React from "react";

const Download = (): React.ReactNode => {
  // TODO
  // eslint-disable-next-line react/jsx-no-literals
  return <div aria-live="polite">DOWNLOAD</div>;
};

export default React.memo(Download);
