/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { DeclineOfferView_offer$key } from "src/types/__generated__/DeclineOfferView_offer.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";

import { ButtonVariation, SBButton, SBLink, SBParagraph } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";
import { formatMoney } from "src/utils";

type Props = Readonly<{
  offer: DeclineOfferView_offer$key;
  offerId: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const DeclineOfferView = ({
  offer,
  offerId,
  setOpen,
}: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const [commit, isInFlight] = useMutation(graphql`
    mutation DeclineOfferViewMutation($input: DeclineOfferInput!) {
      declineOffer(declineOfferInput: $input) {
        ... on Offer {
          id
          product {
            ...ProductDetailView_product
          }
        }
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment DeclineOfferView_offer on Query {
        node(id: $id) {
          ... on Offer {
            id
            price
            currency
          }
        }
      }
    `,
    offer,
  );

  const offerPrice = data.node?.price ?? 0;
  const currency = data.node?.currency ?? "USD";

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onPressClose = () => {
    setOpen(false);
  };

  const onPressConfirm = () => {
    toast.info(t("make-offer.offer.declined"), {
      position: "top-center",
    });
    commit({
      onCompleted() {
        setOpen(false);
      },
      onError(error) {
        setErrorMessage(
          // @ts-ignore This is the localized message from the error response.
          error?.res?.errors?.[0].message ?? t("offers.submit.errors.default"),
        );
      },
      variables: {
        input: {
          offerId,
        },
      },
    });
  };

  const content = (
    <>
      {" "}
      <div {...stylex.props(styles.headingContainer)}>
        <SBParagraph style={styles.headingTitle}>
          {t("model.offer.title.decline-offer", {
            price: formatMoney(Number(offerPrice), currency, i18n.language),
          })}
        </SBParagraph>
      </div>
      <SBButton
        block={true}
        loading={isInFlight}
        style={styles.button}
        title={t("common.buttons.confirm")}
        variation={ButtonVariation.Emphasis}
        onClick={onPressConfirm}
      />
      {errorMessage ? (
        <SBParagraph style={styles.errorMessage}>
          {t("common.unexpected.error")}
        </SBParagraph>
      ) : null}
    </>
  );

  return (
    <div {...stylex.props(styles.container)}>
      {content}

      <SBLink style={styles.close} onClick={onPressClose}>
        {t("common.buttons.close")}
      </SBLink>
    </div>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },
  close: {
    color: colors.color,
    fontSize: 16,
    marginTop: 16,
  },
  confirm: {
    fontWeight: 600,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  errorMessage: {
    color: colors.colorEmphasis,
    paddingTop: 16,
  },
  headingContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    fontWeight: 600,
    justifyContent: "center",
  },
  headingTitle: {
    color: colors.color,
    fontSize: 16,
  },
});

export default DeclineOfferView;
