/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

const ANDROID_RE = /android/iu;
const IPHONE_RE = /iphone/iu;
const MACOS_RE = /Mac OS X/iu;

export type Platform = "android" | "ios" | "macos" | "windows";

export const usePlatform = (): Platform => {
  const { userAgent } = navigator;

  const isAndroid = ANDROID_RE.test(userAgent);
  const isPhone = IPHONE_RE.test(userAgent);
  const isMacOs = MACOS_RE.test(userAgent);

  if (isAndroid) {
    return "android";
  }

  if (isPhone) {
    return "ios";
  }

  if (isMacOs) {
    return "macos";
  }

  return "windows";
};
