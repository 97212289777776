/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

const US_POSTAL_CODE_RE = /^\d{5}$|^\d{5}-\d{4}$/u;
const GB_POSTAL_CODE_RE = /^(?:[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}|GIR ?0A{2})$/u;
// cSpell:disable
const CA_POSTAL_CODE_RE =
  /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/u;
// cSpell:enable

/**
 * Country param is first due to how custom validators work for
 * react-simple-form-validator.
 */
export function isValidPostalCode(
  country: string,
  postalCode: string,
): boolean {
  switch (country) {
    case "CA":
      return CA_POSTAL_CODE_RE.test(postalCode);
    case "GB":
      return GB_POSTAL_CODE_RE.test(postalCode);
    case "US":
      return US_POSTAL_CODE_RE.test(postalCode);
  }
  // We can't validate but shouldn't block entry.
  return true;
}
