/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { AccountInvoiceCard_invoice$key } from "src/types/__generated__/AccountInvoiceCard_invoice.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import { INVOICE_PATH } from "src/app/router/Router";
import { SBDate, SBImage, SBLink } from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { DateFormatType, formatMoney } from "src/utils";

import AccountInfoCard from "../../../components/account/AccountInfoCard";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

type Props = Readonly<{
  queryKey: AccountInvoiceCard_invoice$key;
}>;

const AccountInvoiceCard = ({ queryKey }: Props): React.ReactNode => {
  const { t, i18n } = useTranslation();

  const invoice = useFragment(
    graphql`
      fragment AccountInvoiceCard_invoice on Invoice {
        id
        createdAt
        total
        currency
        status
        surrogateId
        items {
          count
          edges {
            node {
              images(first: 1) {
                edges {
                  node {
                    url(quality: 100, width: 500, webp: true)
                  }
                }
              }
            }
          }
        }
      }
    `,
    queryKey,
  );

  const { id, items, createdAt, status, total, currency, surrogateId } =
    invoice;

  return (
    <SBLink
      {...stylex.props(auto, styles.link)}
      external={true}
      href={INVOICE_PATH.replace(":id", id)}
    >
      <AccountInfoCard
        content={
          <>
            <div>{formatMoney(total, currency, i18n.language, true)}</div>
            <div {...stylex.props(styles.date)}>
              <SBDate
                dateString={createdAt}
                format={DateFormatType.Short}
                language={i18n.language}
              />
            </div>
            <div {...stylex.props(styles.items)}>
              {t(`account.invoices.item${items?.count === 1 ? "" : "s"}`, {
                count: items?.count ?? 0,
              })}
            </div>
            <div {...stylex.props(styles.status)}>
              {t(`account.invoices.status.${status.toLowerCase()}`)}
            </div>
          </>
        }
        heading={t("account.invoices.card-header", { surrogateId })}
        image={
          items.count === 1 && (
            <SBImage
              alt=""
              src={items?.edges[0]?.node?.images?.edges[0]?.node?.url ?? ""}
              width="100%"
            />
          )
        }
      />
    </SBLink>
  );
};

const styles = stylex.create({
  date: {
    fontSize: 13,
    marginTop: 8,
  },
  heading: {
    fontSize: 24,
    marginBottom: 16,
  },
  invoice: {
    backgroundColor: colors.backgroundEmphasisColor,
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: {
      [MOBILE]: "repeat(1, 1fr)",
      [TABLET]: "repeat(3, 1fr)",
      default: "repeat(4, 1fr)",
    },
    marginBottom: 10,
    padding: 12,
  },
  items: {
    marginTop: 8,
  },
  link: {
    borderRadius: 8,
    display: "block",
    outline: {
      ":focus-visible": colors.outline,
    },
    textDecorationLine: "none",
  },
  linkIsPending: {
    opacity: 0.4,
  },
  status: {
    marginTop: 8,
  },
});

export default AccountInvoiceCard;
