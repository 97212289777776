/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { StyleXStyles } from "@stylexjs/stylex";

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  rank: number | null | undefined;
  style?: StyleXStyles;
}>;

export const SBFmvDot = React.memo(
  ({ rank, style }: Props): React.ReactNode | null => {
    return (
      <span
        {...stylex.props(
          auto,
          styles.root,
          rank === 1 && styles.great,
          rank === 2 && styles.good,
          rank === 4 && styles.poor,
          style,
        )}
      ></span>
    );
  },
);

const styles = stylex.create({
  good: {
    backgroundColor: colors.fmvRankGoodBackgroundColor,
    height: 7,
    transform: "rotate(45deg)",
    width: 7,
  },
  great: {
    backgroundColor: colors.fmvRankGreatBackgroundColor,
    borderRadius: 16,
  },
  poor: {
    backgroundColor: colors.fmvRankPoorBackgroundColor,
  },
  root: {
    backgroundColor: colors.fmvRankDefaultBackgroundColor,
    display: "inline-flex",
    height: 8,
    width: 8,
  },
});
