/**
 * @generated SignedSource<<b171bb9ac9af1f0a74e3601412a24332>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SellerStatus = "APPROVED" | "DEACTIVATED" | "IN_REVIEW" | "SUSPENDED" | "VACATION" | "%future added value";
export type UpdateSellerVacationModeInput = {
  userId: string;
  vacationMode: boolean;
};
export type SellerAccountVacationModeViewMutation$variables = {
  input: UpdateSellerVacationModeInput;
};
export type SellerAccountVacationModeViewMutation$data = {
  readonly updateSellerVacationMode: {
    readonly sellerStatus: SellerStatus | null | undefined;
    readonly vacationSince: string | null | undefined;
    readonly " $fragmentSpreads": FragmentRefs<"SellerAccountVacationModeView_user">;
  };
};
export type SellerAccountVacationModeViewMutation = {
  response: SellerAccountVacationModeViewMutation$data;
  variables: SellerAccountVacationModeViewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "updateSellerVacationModeInput",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sellerStatus",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "vacationSince",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SellerAccountVacationModeViewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateSellerVacationMode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SellerAccountVacationModeView_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SellerAccountVacationModeViewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "updateSellerVacationMode",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b77cf08bf17a927dba8135f150ab52ae",
    "id": null,
    "metadata": {},
    "name": "SellerAccountVacationModeViewMutation",
    "operationKind": "mutation",
    "text": "mutation SellerAccountVacationModeViewMutation(\n  $input: UpdateSellerVacationModeInput!\n) {\n  updateSellerVacationMode(updateSellerVacationModeInput: $input) {\n    sellerStatus\n    vacationSince\n    ...SellerAccountVacationModeView_user\n    id\n  }\n}\n\nfragment SellerAccountVacationModeView_user on User {\n  id\n  sellerStatus\n  vacationSince\n}\n"
  }
};
})();

(node as any).hash = "1942d6181eda8da2d723cf8ccb5595e7";

export default node;
