/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { EditProductView_product$key } from "src/types/__generated__/EditProductView_product.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation } from "react-relay";

import {
  ButtonVariation,
  SBButton,
  SBErrorMessage,
  SBParagraph,
  SBTextArea,
  SBTextInput,
} from "src/sbxui";
import { formatMoney } from "src/utils";

const NOTES_MAX_LENGTH = 200;

const PRICE_MAX_LENGTH = 6;

enum Step {
  Edit,
  Confirm,
}

type Props = Readonly<{
  gradedBookMinimumValue: number;
  queryKey: EditProductView_product$key;
  rawBookMinimumValue: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: (title: string) => void;
}>;

const EditProductView = ({
  gradedBookMinimumValue,
  queryKey,
  rawBookMinimumValue,
  setOpen,
  setTitle,
}: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const [commitUpdate, isInFlightUpdate] = useMutation(graphql`
    mutation EditProductViewUpdateMutation($input: UpdateOwnProductInput!) {
      updateOwnProduct(updateOwnProductInput: $input) {
        ...ProductDetailView_product
      }
    }
  `);

  const [commitDelete, isInFlightDelete] = useMutation(graphql`
    mutation EditProductViewDeleteMutation($input: UnpublishProductInput!) {
      unpublishProduct(unpublishProductInput: $input) {
        ...ProductDetailView_product
      }
    }
  `);

  const data = useFragment(
    graphql`
      fragment EditProductView_product on Product {
        id
        price
        currency
        sellerPrivateNotes
        submissionNotes
        comicDetails {
          barcode
          grade
          gradingAuthority
          label {
            id
          }
        }
      }
    `,
    queryKey,
  );

  const productId = data.id;

  const price = data.price ?? 0;
  const currency = data.currency;

  const gradingAuthority = data.comicDetails?.gradingAuthority;

  const sellerPrivateNotes = data.sellerPrivateNotes ?? "";
  const submissionNotes = data.submissionNotes ?? "";

  const [step, setStep] = useState(Step.Edit);

  const [newPrice, setNewPrice] = useState(`${price}`);
  const [purchaseNotes, setPurchaseNotes] = useState(sellerPrivateNotes);
  const [additionalNotes, setAdditionalNotes] = useState(submissionNotes);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChangePurchaseNotes = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setPurchaseNotes(event.currentTarget.value);
  };

  const handleChangeAdditionalNotes = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setAdditionalNotes(event.currentTarget.value);
  };

  const handleChangePrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPrice(event.currentTarget.value);
  };

  const handleClickUpdate = (_event: React.SyntheticEvent) => {
    if (price > 0) {
      if (
        gradingAuthority !== "RAW" &&
        Number.parseInt(newPrice, 10) < gradedBookMinimumValue
      ) {
        setErrorMessage(
          t("forms.validation.graded-book-minimum-value", {
            minimum: formatMoney(
              gradedBookMinimumValue,
              currency,
              i18n.language,
            ),
          }),
        );
        return;
      }
      if (
        gradingAuthority === "RAW" &&
        Number.parseInt(newPrice, 10) < rawBookMinimumValue
      ) {
        setErrorMessage(
          t("forms.validation.raw-book-minimum-value", {
            minimum: formatMoney(rawBookMinimumValue, currency, i18n.language),
          }),
        );
        return;
      }
    }
    commitUpdate({
      onCompleted() {
        setOpen(false);
      },
      onError(error) {
        setErrorMessage(error.message);
      },
      variables: {
        input: {
          price: Number.parseInt(newPrice, 10),
          productId,
          sellerPrivateNotes: purchaseNotes,
          submissionNotes: additionalNotes,
        },
      },
    });
  };

  const handleClickDelete = (_event: React.SyntheticEvent) => {
    setTitle(t("product.edit.confirm-delete.title"));
    setStep(Step.Confirm);
  };

  const handleClickConfirm = (_event: React.SyntheticEvent) => {
    commitDelete({
      onCompleted() {
        setOpen(false);
      },
      onError(error) {
        setErrorMessage(error.message);
      },
      variables: {
        input: {
          productId,
        },
      },
    });
  };

  let placeholder = "$";
  switch (currency) {
    case "USD":
      placeholder = "$";
      break;
    case "AUD":
    case "CAD":
    case "EUR":
    case "GBP":
    case "%future added value":
      placeholder = "USD";
      break;
  }

  let content = null;
  switch (step) {
    case Step.Edit:
      content = (
        <>
          <SBTextArea
            disabled={isInFlightUpdate}
            id="sellerPrivateNotes"
            label={t("product.edit.edit-private-notes")}
            maxLength={NOTES_MAX_LENGTH}
            showLabel={true}
            style={styles.input}
            value={purchaseNotes}
            onChange={handleChangePurchaseNotes}
          />
          {price > 0 && (
            <>
              <SBTextArea
                disabled={isInFlightUpdate}
                id="submissionNotes"
                label={t("product.edit.edit-seller-notes")}
                maxLength={NOTES_MAX_LENGTH}
                showLabel={true}
                style={styles.input}
                value={additionalNotes}
                onChange={handleChangeAdditionalNotes}
              />
              <SBTextInput
                disabled={isInFlightUpdate}
                id="price"
                inputStyle={styles.inputStyle}
                label={t("product.edit.edit-price")}
                maxLength={PRICE_MAX_LENGTH}
                placeholder={placeholder}
                showLabel={true}
                type="number"
                value={newPrice}
                onChange={handleChangePrice}
              />
              <SBParagraph style={styles.current}>
                {t("product.edit.current-price", {
                  price: formatMoney(Number(price), currency, i18n.language),
                })}
              </SBParagraph>
            </>
          )}
          <SBButton
            block={true}
            loading={isInFlightUpdate}
            style={styles.button}
            title={t("product.edit.buttons.update")}
            variation={ButtonVariation.Emphasis}
            onClick={handleClickUpdate}
          />
          {price > 0 && (
            <SBButton
              block={true}
              disabled={isInFlightUpdate}
              style={styles.button}
              title={t("product.edit.buttons.delete")}
              onClick={handleClickDelete}
            />
          )}
        </>
      );
      break;
    case Step.Confirm:
      content = (
        <>
          <SBParagraph style={styles.confirm}>
            {t("product.edit.confirm-delete.description")}
          </SBParagraph>
          <SBButton
            block={true}
            loading={isInFlightDelete}
            style={styles.button}
            title={t("product.edit.buttons.delete")}
            variation={ButtonVariation.Emphasis}
            onClick={handleClickConfirm}
          />
        </>
      );
      break;
  }

  return (
    <div {...stylex.props(styles.container)}>
      <div {...stylex.props(errorMessage != null && styles.errorMessage)}>
        <SBErrorMessage message={errorMessage} />
      </div>
      {content}
    </div>
  );
};

const styles = stylex.create({
  button: {
    marginTop: 16,
  },
  confirm: {
    marginBottom: 0,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  current: {
    marginBottom: 0,
    marginTop: 4,
    textAlign: "center",
  },
  errorMessage: {
    marginBottom: 16,
    width: "100%",
  },
  input: {
    marginBottom: 8,
  },
  inputStyle: {
    textAlign: "center",
  },
});

export default EditProductView;
