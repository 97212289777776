/**
 * @generated SignedSource<<e141ece333f4af84685128513851604e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountWatchedLotsView_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AccountWatchedLotsActive_user" | "AccountWatchedLotsEnded_user">;
  readonly " $fragmentType": "AccountWatchedLotsView_user";
};
export type AccountWatchedLotsView_user$key = {
  readonly " $data"?: AccountWatchedLotsView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"AccountWatchedLotsView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountWatchedLotsView_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountWatchedLotsActive_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AccountWatchedLotsEnded_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "0a7efd743ee43a0144c980ab0c233192";

export default node;
