/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProblemEnum } from "src/types/__generated__/ReportProblemModalCreateProblemMutation.graphql";
import type { ReportProductIssueModal_product$key } from "src/types/__generated__/ReportProductIssueModal_product.graphql";

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment, useMutation } from "react-relay";

import { SBButton, SBModal, SBParagraph, SBRadioButtons } from "src/sbxui";

type Props = Readonly<{
  isOpen: boolean;
  onSuccess: () => void;
  queryKey: ReportProductIssueModal_product$key;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const ReportProductIssueModal = ({
  isOpen,
  setOpen,
  queryKey,
  onSuccess,
}: Props): React.ReactNode => {
  const { t } = useTranslation();
  const [problem, setProblem] = useState<ProblemEnum | "">("");

  const product = useFragment(
    graphql`
      fragment ReportProductIssueModal_product on Product {
        id
      }
    `,
    queryKey,
  );

  const [commit, isInFlight] = useMutation(graphql`
    mutation ReportProductIssueModalMutation($input: CreateProblemInput!) {
      createProblem(createProblemInput: $input) {
        problem
        productId
      }
    }
  `);

  const onSubmit = () => {
    if (problem === "") {
      return;
    }
    commit({
      onCompleted() {
        onSuccess();
      },
      onError() {
        onSuccess();
      },
      variables: {
        input: {
          problem,
          productId: product.id,
        },
      },
    });
  };

  const onSelectProblem = (option: string) => {
    switch (option) {
      case "HISTORICAL_SALE":
      case "IMAGE_QUALITY":
      case "OFFENSIVE_CONTENT":
      case "PRODUCT_MISMATCH":
      case "INACCURATE_GRADE":
        setProblem(option);
        break;
    }
  };

  const problemOptions = [
    {
      label: t("product-report.options.image-quality"),
      value: "IMAGE_QUALITY",
    },
    {
      label: t("product-report.options.inaccurate-grade"),
      value: "INACCURATE_GRADE",
    },
    {
      label: t("product-report.options.product-mismatch"),
      value: "PRODUCT_MISMATCH",
    },
    {
      label: t("product-report.options.historical-sale"),
      value: "HISTORICAL_SALE",
    },
    {
      label: t("product-report.options.offensive-content"),
      value: "OFFENSIVE_CONTENT",
    },
  ];

  const isButtonDisabled = problem === "";

  return (
    <SBModal
      footer={
        <div {...stylex.props(styles.modalFooter)}>
          <SBButton
            disabled={isButtonDisabled || isInFlight}
            loading={isInFlight}
            title={t("product-report.modal.submit")}
            onClick={onSubmit}
          />
        </div>
      }
      headerText={t("product-report.modal.title")}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <div {...stylex.props(styles.modal)}>
        <SBParagraph>{t("product-report.modal.description")}</SBParagraph>
        <div {...stylex.props(styles.radioButtons)}>
          <SBRadioButtons
            id="problems"
            options={problemOptions}
            value={problem ?? ""}
            onChange={onSelectProblem}
          />
        </div>
      </div>
    </SBModal>
  );
};

const styles = stylex.create({
  modal: {
    padding: 16,
  },
  modalFooter: {
    alignItems: "center",
    display: "flex",
    justifyContent: "flex-end",
  },
  radioButtons: {
    paddingTop: 16,
  },
  root: {},
});

export default ReportProductIssueModal;
