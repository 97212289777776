/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ProductDetailHeader_product$key } from "src/types/__generated__/ProductDetailHeader_product.graphql";

import * as stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useFragment } from "react-relay";

import {
  ButtonType,
  ButtonVariation,
  HeadingLevel,
  SBButton,
  SBFmvBadge,
  SBHeading,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";
import { formatMoney, formatTitle } from "src/utils";

const MOBILE = "@media (max-width: 767px)";
const TABLET = "@media (min-width: 768px) and (max-width: 1439px)";

interface ProductUrlOptions {
  id: string;
  imageUrl: string | null;
  title: string;
}

const formatProductUrl = ({ id, imageUrl, title }: ProductUrlOptions) =>
  id == null
    ? "https://shortboxed.com/"
    : `https://shortboxed.page.link/?link=https://shortboxed.com/product/${encodeURIComponent(id)}&st=${encodeURIComponent(title)}&apn=com.shortboxed&ibi=com.shortboxed&isi=1497091986${imageUrl == null ? "" : `&si=${encodeURIComponent(imageUrl)}`}`;

type Props = Readonly<{
  queryKey: ProductDetailHeader_product$key;
}>;

const ProductDetailHeader = ({ queryKey }: Props): React.ReactNode => {
  const { i18n, t } = useTranslation();

  const data = useFragment(
    graphql`
      fragment ProductDetailHeader_product on Product {
        id
        price
        currency
        comicDetails {
          title
          number
        }
        images {
          edges {
            node {
              url(quality: 100, webp: true, width: 1000)
            }
          }
        }
        fmvScore {
          minValue
          maxValue
          currency
          rank
        }
      }
    `,
    queryKey,
  );

  const { comicDetails, id, images, price, currency, fmvScore } = data;
  const { title, number } = comicDetails ?? {};

  const imageEdges = images?.edges ?? [];
  const imageUrl = imageEdges.map(({ node }) => node.url)[0];

  const fmvMinValue = fmvScore?.minValue ?? 0;
  const fmvMaxValue = fmvScore?.maxValue ?? 0;
  const fmvCurrency = fmvScore?.currency ?? "USD";
  const fmvRank = fmvScore?.rank;

  const handleClickViewInApp = (event: React.SyntheticEvent) => {
    event.preventDefault();
    window.open(
      formatProductUrl({
        id,
        imageUrl,
        title: productTitle,
      }),
      "_blank",
      "noopener,noreferrer",
    );
  };

  const productTitle = formatTitle(t, title, number);

  return (
    <>
      <SBHeading level={HeadingLevel.H1} style={styles.productTitle}>
        {productTitle}
      </SBHeading>
      <div {...stylex.props(auto, styles.container)}>
        <div {...stylex.props(styles.columnLeft)}>
          <SBParagraph style={styles.askingPrice}>
            {t("product.header.askingPrice")}
          </SBParagraph>
          <SBParagraph style={styles.productCost}>
            {formatMoney(price, currency, i18n.language)}
          </SBParagraph>
          {fmvMinValue > 0 && fmvMaxValue > 0 && (
            <SBParagraph style={styles.fmvRange}>
              {t("product.fmvRange", {
                max: formatMoney(fmvMaxValue, fmvCurrency, i18n.language),
                min: formatMoney(fmvMinValue, fmvCurrency, i18n.language),
              })}
            </SBParagraph>
          )}
          <SBFmvBadge fmvRank={fmvRank} style={styles.fmvRank} />
        </div>
        <div {...stylex.props(styles.columnRight)}>
          <SBParagraph style={styles.inApp}>
            {t("product.header.inApp")}
          </SBParagraph>
          <SBButton
            block={true}
            title={t("product.header.buttons.inApp")}
            type={ButtonType.Button}
            variation={ButtonVariation.Default}
            onClick={handleClickViewInApp}
          />
        </div>
      </div>
    </>
  );
};

const styles = stylex.create({
  askingPrice: {
    fontSize: 12,
    margin: 0,
  },
  columnLeft: {
    alignSelf: "flex-start",
  },
  columnRight: {
    alignSelf: "flex-end",
  },
  container: {
    borderColor: colors.tableBorderColor,
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    marginBlock: 24,
    padding: 16,
  },
  fmvRange: {
    fontSize: 12,
    margin: 0,
  },
  fmvRank: {
    marginTop: 8,
  },
  inApp: {
    fontSize: 14,
    marginBottom: 16,
  },
  productCost: {
    color: colors.color,
    fontFamily: "'drukmedium', sans-serif",
    fontSize: 48,
    lineHeight: 0.875,
    marginBlock: 8,
  },
  productTitle: {
    fontSize: {
      [MOBILE]: 48,
      [TABLET]: 72,
      default: 96,
    },
  },
});

export default ProductDetailHeader;
