/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

// @ts-nocheck

import type { Disposable, UseMutationConfig } from "react-relay";
import type { useUnwatchAuctionLotMutation } from "src/types/__generated__/useUnwatchAuctionLotMutation.graphql.ts";

import { graphql, useMutation } from "react-relay";

export const useUnwatchAuctionLot = (): [
  (config: UseMutationConfig<useUnwatchAuctionLotMutation>) => Disposable,

  boolean,
] => {
  const [commit, isInFlight] = useMutation(graphql`
    mutation useUnwatchAuctionLotMutation($input: AuctionLotIdInput!) {
      unwatchAuctionLot(unwatchAuctionLotInput: $input) {
        ... on AuctionLot {
          id
          ...AuctionLotView_auctionLot
        }
      }
    }
  `);

  return [commit, isInFlight];
};
