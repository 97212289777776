/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";

import { HeadingLevel, SBHeading } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  content: React.ReactNode;
  heading: string;
  image?: React.ReactNode;
  subHeading?: string;
}>;

const AccountInfoCard = ({
  heading,
  content,
  image,
  subHeading,
}: Props): React.ReactNode => {
  return (
    <div {...stylex.props(styles.root)}>
      {image ? <div {...stylex.props(styles.image)}>{image}</div> : null}
      <div {...stylex.props(styles.main)}>
        <div {...stylex.props(styles.headingWrap)}>
          <SBHeading level={HeadingLevel.H2} style={styles.heading}>
            {heading}
          </SBHeading>
          {subHeading ? (
            <div {...stylex.props(styles.subHeading)}>{subHeading}</div>
          ) : null}
        </div>
        <div {...stylex.props(styles.columns)}>
          <div>{content}</div>
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  columns: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: 24,
    marginBottom: 4,
  },
  headingWrap: {
    marginBottom: 16,
  },
  image: {
    flexBasis: "25%",
    marginRight: 20,
  },
  main: {
    alignItems: "stretch",
    color: colors.color,
    display: "flex",
    flexBasis: "100%",
    flexDirection: "column",
    textDecoration: "none",
  },
  root: {
    backgroundColor: colors.backgroundEmphasisColor,
    display: "flex",
    marginBottom: 10,
    padding: 12,
  },
  subHeading: {
    fontSize: 13,
  },
});

export default AccountInfoCard;
