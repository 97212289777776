/**
 * @generated SignedSource<<df48bfb6cc2b7b1b9153c6a4fa90bed6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type QueriesArticlesQuery$variables = Record<PropertyKey, never>;
export type QueriesArticlesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ArticlesViewQuery_articles">;
};
export type QueriesArticlesQuery = {
  response: QueriesArticlesQuery$data;
  variables: QueriesArticlesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "publishedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "summary",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "body",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageAlt",
  "storageKey": null
},
v10 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 20
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": {
      "createdAt": "DESC"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "isBlog": true
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QueriesArticlesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ArticlesViewQuery_articles"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QueriesArticlesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "featuredArticle",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "concreteType": "ArticlesConnection",
        "kind": "LinkedField",
        "name": "articles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ArticlesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Article",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": "articles(first:20,orderBy:{\"createdAt\":\"DESC\"},where:{\"isBlog\":true})"
      },
      {
        "alias": null,
        "args": (v10/*: any*/),
        "filters": [
          "orderBy",
          "where"
        ],
        "handle": "connection",
        "key": "ArticlesList_articles",
        "kind": "LinkedHandle",
        "name": "articles"
      }
    ]
  },
  "params": {
    "cacheID": "846f37a8a389aa5742aee1c197e1687f",
    "id": null,
    "metadata": {},
    "name": "QueriesArticlesQuery",
    "operationKind": "query",
    "text": "query QueriesArticlesQuery {\n  ...ArticlesViewQuery_articles\n}\n\nfragment ArticleCard_article on Article {\n  id\n  createdAt\n  updatedAt\n  publishedAt\n  summary\n  image\n  title\n  body\n  slug\n  imageAlt\n}\n\nfragment ArticlesListQuery_articles on Query {\n  articles(first: 20, orderBy: {createdAt: DESC}, where: {isBlog: true}) {\n    edges {\n      node {\n        ...ArticleCard_article\n        id\n        slug\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ArticlesViewQuery_articles on Query {\n  ...FeaturedArticle_article\n  featuredArticle {\n    id\n  }\n  ...ArticlesListQuery_articles\n}\n\nfragment FeaturedArticle_article on Query {\n  featuredArticle {\n    ...ArticleCard_article\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "26fbaaa26b5d949d5c74cdc82451df17";

export default node;
