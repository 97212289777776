/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

const GRAPH_API_HOST =
  process.env.REACT_APP_GRAPH_API_HOST ?? "graph.shortboxed.com";
const GRAPH_API_PROTOCOL = process.env.REACT_APP_GRAPH_API_PROTOCOL ?? "https";
const WS_PROTOCOL = process.env.REACT_APP_WS_PROTOCOL ?? "wss";

export const ERROR_RE = /^(?!E9999)E[0-9]{4}$/gu;
export const ANONYMOUS_USER_ID = "";

export const config = {
  API_HOST: GRAPH_API_HOST,
  API_PROTOCOL: GRAPH_API_PROTOCOL,
  API_URL: `${GRAPH_API_PROTOCOL}://${GRAPH_API_HOST}/graphql`,
  WS_URL: `${WS_PROTOCOL}://${GRAPH_API_HOST}/graphql`,
};
