/**
 * @generated SignedSource<<2d1c598fa789b162dbaafe134787a56a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type Currency = "AUD" | "CAD" | "EUR" | "GBP" | "USD" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DeclineOfferView_offer$data = {
  readonly node: {
    readonly currency?: Currency;
    readonly id?: string;
    readonly price?: number;
  } | null | undefined;
  readonly " $fragmentType": "DeclineOfferView_offer";
};
export type DeclineOfferView_offer$key = {
  readonly " $data"?: DeclineOfferView_offer$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeclineOfferView_offer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeclineOfferView_offer",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "price",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "currency",
              "storageKey": null
            }
          ],
          "type": "Offer",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "2a2e0c952d3b3292e33e525f9e0d243d";

export default node;
