/**
 * @generated SignedSource<<16db2d8e761f41cf2d220631296b03b4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceView_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceAddress_user" | "InvoicePayment_user">;
  readonly " $fragmentType": "InvoiceView_user";
};
export type InvoiceView_user$key = {
  readonly " $data"?: InvoiceView_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceView_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceView_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceAddress_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoicePayment_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "febacdaa35f3807e29e38eefd3d9a954";

export default node;
