/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

type Timestamp = Readonly<{
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}>;

export function calculateTimestamp(milliseconds: number): Timestamp {
  const secs = Math.floor(milliseconds / 1000);
  const mins = Math.floor(secs / 60);
  const hours = Math.floor(mins / 60);
  const days = Math.floor(hours / 24);

  return {
    days,
    hours: hours % 24,
    minutes: mins % 60,
    seconds: secs % 60,
  };
}
