/**
 * @generated SignedSource<<8de84651ee16fb13b01f8d280bb6b966>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ShopView_verticalSearch$data = {
  readonly verticalSearch: {
    readonly __id: string;
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"SearchResultCard_typesenseSearch">;
      };
    }>;
  };
  readonly " $fragmentType": "ShopView_verticalSearch";
};
export type ShopView_verticalSearch$key = {
  readonly " $data"?: ShopView_verticalSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"ShopView_verticalSearch">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "verticalSearch"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "categoryRollups"
    },
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gradeMax"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gradeMin"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gradingAuthorities"
    },
    {
      "defaultValue": {
        "createdAt": "DESC"
      },
      "kind": "LocalArgument",
      "name": "orderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceMax"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "priceMin"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "searchTerm"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "shopUrl"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "specialCopies"
    },
    {
      "defaultValue": "COMIC",
      "kind": "LocalArgument",
      "name": "vertical"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "yearMax"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "yearMin"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./ShopPaginationQuery.graphql')
    }
  },
  "name": "ShopView_verticalSearch",
  "selections": [
    {
      "alias": "verticalSearch",
      "args": [
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "orderBy"
        },
        {
          "kind": "Variable",
          "name": "vertical",
          "variableName": "vertical"
        },
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "categoryRollups",
              "variableName": "categoryRollups"
            },
            {
              "kind": "Variable",
              "name": "gradeMax",
              "variableName": "gradeMax"
            },
            {
              "kind": "Variable",
              "name": "gradeMin",
              "variableName": "gradeMin"
            },
            {
              "kind": "Variable",
              "name": "gradingAuthorities",
              "variableName": "gradingAuthorities"
            },
            {
              "kind": "Variable",
              "name": "priceMax",
              "variableName": "priceMax"
            },
            {
              "kind": "Variable",
              "name": "priceMin",
              "variableName": "priceMin"
            },
            {
              "kind": "Variable",
              "name": "searchTerm",
              "variableName": "searchTerm"
            },
            {
              "kind": "Variable",
              "name": "shopUrl",
              "variableName": "shopUrl"
            },
            {
              "kind": "Variable",
              "name": "specialCopies",
              "variableName": "specialCopies"
            },
            {
              "kind": "Variable",
              "name": "yearMax",
              "variableName": "yearMax"
            },
            {
              "kind": "Variable",
              "name": "yearMin",
              "variableName": "yearMin"
            }
          ],
          "kind": "ObjectValue",
          "name": "where"
        }
      ],
      "concreteType": "VerticalSearchConnection",
      "kind": "LinkedField",
      "name": "__ShopView_verticalSearch_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VerticalSearchEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TypesenseSearch",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SearchResultCard_typesenseSearch"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "fe98ddb6d13b14dc38156bde9de2e415";

export default node;
