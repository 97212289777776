/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ComicIndexIssueQuery } from "src/types/__generated__/ComicIndexIssueQuery.graphql";

import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { Parser } from "html-to-react";
import * as React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";

import CensusTable from "src/app/components/census/CensusTable";
import { PageContext } from "src/app/context/page";
import { COMIC_INDEX_TITLE_PATH } from "src/app/router/Router";
import {
  HeadingLevel,
  SBHeading,
  SBImage,
  SBLink,
  SBPageHeader,
} from "src/sbxui";

const htmlParser = new Parser();

const ComicIndexIssue = (): React.ReactNode | null => {
  const { t } = useTranslation();
  const { id } = useParams();
  const pageContext = useContext(PageContext);

  const { node: data } = useLazyLoadQuery<ComicIndexIssueQuery>(
    graphql`
      query ComicIndexIssueQuery($id: ID!) {
        node(id: $id) {
          ... on Issue {
            id
            title {
              id
            }
            labels(first: 1, where: { categoryRollup: UNIVERSAL }) {
              edges {
                node {
                  census
                  comicDetails {
                    id
                    title
                    number
                    publisher
                    year
                    artComments
                    keyComments
                    obverseImage
                    reverseImage
                    ...CensusTable_comicDetail
                  }
                }
              }
            }
          }
        }
      }
    `,
    { id: id ?? "" },
  );

  const comicDetails = data?.labels?.edges[0]?.node?.comicDetails;

  if (comicDetails == null) {
    return null;
  }

  const {
    title,
    number,
    artComments,
    keyComments,
    publisher,
    year,
    obverseImage,
  } = comicDetails;
  const docTitle = `${title} #${number}`;

  pageContext?.setTitle(
    t("comic-index.issue.document-title", {
      title: docTitle,
    }),
  );

  if (data == null) {
    return null;
  }

  return (
    <div>
      <SBPageHeader subtitle={`${publisher} (${year})`} title={docTitle} />
      <div>
        <div>
          <SBLink
            to={COMIC_INDEX_TITLE_PATH.replace(":id", data?.title?.id ?? "")}
          >
            {t("comic-index.issue.back-to-title", { title })}
          </SBLink>
        </div>
        <div>
          <SBImage
            alt={t("comic-index.imageAlt", {
              title,
            })}
            src={obverseImage ?? ""}
            width={200}
          />
        </div>
        <SBHeading level={HeadingLevel.H5}>
          {t("comic-index.issue.art-comments")}
        </SBHeading>
        <div>{htmlParser.parse(artComments ?? "")}</div>
        <SBHeading level={HeadingLevel.H5}>
          {t("comic-index.issue.key-comments")}
        </SBHeading>
        <div>{htmlParser.parse(keyComments ?? "")}</div>
        <CensusTable queryKey={comicDetails} />
      </div>
    </div>
  );
};

const _styles = stylex.create({
  char: {
    backgroundColor: {
      ":hover": "#FFF",
    },
    textAlign: "center",
  },
  chars: {
    display: "grid",
    gridGap: 5,
    gridTemplateColumns: "repeat(26, 1fr)",
    marginTop: 40,
  },
  popularTitles: {
    display: "flex",
    flexFlow: "column",
    flexWrap: "wrap",
    marginTop: 20,
    maxHeight: 300,
  },
  selected: {
    backgroundColor: "#CDCDCD",
  },
});

export default React.memo(ComicIndexIssue);
