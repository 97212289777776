/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesArticleDetailQuery } from "src/types/__generated__/QueriesArticleDetailQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import ArticleDetailContent from "./ArticleDetailContent";

const ArticleDetail = (): React.ReactNode => {
  const queryRef = useLoaderData() as PreloadedQuery<QueriesArticleDetailQuery>;

  return (
    <div aria-live="polite">
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <ArticleDetailContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </div>
  );
};

export default ArticleDetail;
