/**
 * @generated SignedSource<<dd4a8160ec0fd6dab62f6ea84766e113>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type PasswordResetInitiateInput = {
  email: string;
};
export type ResetPasswordInitializeMutationQuery$variables = {
  input: PasswordResetInitiateInput;
};
export type ResetPasswordInitializeMutationQuery$data = {
  readonly passwordResetInitiate: boolean;
};
export type ResetPasswordInitializeMutationQuery = {
  response: ResetPasswordInitializeMutationQuery$data;
  variables: ResetPasswordInitializeMutationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "passwordResetInitiateInput",
        "variableName": "input"
      }
    ],
    "kind": "ScalarField",
    "name": "passwordResetInitiate",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ResetPasswordInitializeMutationQuery",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ResetPasswordInitializeMutationQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8fd1c6bada99e9cbf2c6cbba7da82a18",
    "id": null,
    "metadata": {},
    "name": "ResetPasswordInitializeMutationQuery",
    "operationKind": "mutation",
    "text": "mutation ResetPasswordInitializeMutationQuery(\n  $input: PasswordResetInitiateInput!\n) {\n  passwordResetInitiate(passwordResetInitiateInput: $input)\n}\n"
  }
};
})();

(node as any).hash = "8d331940d62138a891a89025860b59e3";

export default node;
