/**
 * @generated SignedSource<<84f3921c19754a0f76ab7f07eb9f3003>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ClientId = "API" | "APP" | "APP_LOCAL" | "SERVICE" | "WEB" | "WEB_LOCAL" | "WEB_STAGE" | "%future added value";
export type SellerStatus = "APPROVED" | "DEACTIVATED" | "IN_REVIEW" | "SUSPENDED" | "VACATION" | "%future added value";
export type SocialOAuthTokenVerificationInput = {
  allowMarketingNotifs?: boolean | null | undefined;
  clientId?: ClientId | null | undefined;
  needsTokenExchange?: boolean | null | undefined;
  token: string;
};
export type AppleSSOButtonAppleLoginMutation$variables = {
  input: SocialOAuthTokenVerificationInput;
};
export type AppleSSOButtonAppleLoginMutation$data = {
  readonly appleLogin: {
    readonly accessToken: string;
    readonly permissions: ReadonlyArray<string>;
    readonly refreshToken: string | null | undefined;
    readonly user: {
      readonly hasAcceptedPrivacyPolicy: boolean;
      readonly hasAcceptedTermsOfService: boolean;
      readonly id: string;
      readonly sellerStatus: SellerStatus | null | undefined;
    } | null | undefined;
  };
};
export type AppleSSOButtonAppleLoginMutation = {
  response: AppleSSOButtonAppleLoginMutation$data;
  variables: AppleSSOButtonAppleLoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "socialOAuthTokenVerificationInput",
        "variableName": "input"
      }
    ],
    "concreteType": "LoggedInResponse",
    "kind": "LinkedField",
    "name": "appleLogin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sellerStatus",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasAcceptedTermsOfService",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasAcceptedPrivacyPolicy",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppleSSOButtonAppleLoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppleSSOButtonAppleLoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9c74a028f4b32efc3faa2940e5a35bf4",
    "id": null,
    "metadata": {},
    "name": "AppleSSOButtonAppleLoginMutation",
    "operationKind": "mutation",
    "text": "mutation AppleSSOButtonAppleLoginMutation(\n  $input: SocialOAuthTokenVerificationInput!\n) {\n  appleLogin(socialOAuthTokenVerificationInput: $input) {\n    accessToken\n    refreshToken\n    user {\n      id\n      sellerStatus\n      hasAcceptedTermsOfService\n      hasAcceptedPrivacyPolicy\n    }\n    permissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "9704c389d41d059e238bedb3421c5c43";

export default node;
