/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountPasswordQuery } from "src/types/__generated__/QueriesAccountPasswordQuery.graphql";

import * as React from "react";
import { usePreloadedQuery } from "react-relay";

import { AccountPasswordQuery } from "src/app/router/Queries";

import AccountPasswordView from "./AccountPasswordView";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesAccountPasswordQuery>;
}>;

const AccountPasswordContent = ({ queryRef }: Props): React.ReactNode => {
  const queryKey = usePreloadedQuery(AccountPasswordQuery, queryRef);

  const user = queryKey.viewer?.user;

  if (user == null) {
    return null;
  }

  return <AccountPasswordView queryKey={user} />;
};

export default AccountPasswordContent;
