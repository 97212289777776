/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import stylex from "@stylexjs/stylex";
import * as React from "react";
import { useTranslation } from "react-i18next";

import { HeadingLevel, SBHeading } from "src/sbxui";
import { colors } from "src/themes/colors.stylex";

type Props = Readonly<{
  description: string;
  onPressNo: () => void;
  onPressYes: () => void;
  title: string;
  value: "yes" | "no" | "none";
}>;

const ProductFeedbackButtons = ({
  description,
  onPressNo,
  onPressYes,
  title,
  value,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  return (
    <div {...stylex.props(styles.feedbackContainer)}>
      <SBHeading level={HeadingLevel.H3} style={styles.feedbackTitle}>
        {title}
      </SBHeading>
      <p {...stylex.props(styles.feedbackDescription)}>{description}</p>
      <div {...stylex.props(styles.feedbackButtons)}>
        <div>
          <button
            {...stylex.props(styles.feedbackButton)}
            type="button"
            onClick={onPressYes}
          >
            <div
              {...stylex.props(
                styles.feedbackIcon(
                  value === "yes" ? "thumbsUpFilled" : "thumbsUpOutline",
                ),
              )}
            />
            <SBHeading
              level={HeadingLevel.H5}
              {...stylex.props(styles.feedbackLabel)}
            >
              {t("product.feedback.buttons.yes")}
            </SBHeading>
          </button>
          <button
            {...stylex.props(styles.feedbackButton)}
            type="button"
            onClick={onPressNo}
          >
            <div
              {...stylex.props(
                styles.feedbackIcon(
                  value === "no" ? "thumbsDownFilled" : "thumbsDownOutline",
                ),
              )}
            />
            <SBHeading
              level={HeadingLevel.H5}
              {...stylex.props(styles.feedbackLabel)}
            >
              {t("product.feedback.buttons.no")}
            </SBHeading>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = stylex.create({
  feedbackButton: {
    alignItems: "center",
    backgroundColor: colors.backgroundEmphasisColor,
    borderColor: "none",
    borderStyle: "none",
    justifyContent: "center",
    marginLeft: 16,
    marginRight: 16,
  },
  feedbackButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: 16,
    marginTop: 16,
  },
  feedbackContainer: {},
  feedbackDescription: {
    color: colors.color,
    marginTop: 4,
    paddingLeft: 32,
    paddingRight: 32,
    textAlign: "center",
  },
  feedbackIcon: (icon: string) => ({
    backgroundImage: `url('https://www-cdn.shortboxed.com/feedback/${icon}.webp')`,
    backgroundSize: "cover",
    height: 64,
    width: 64,
  }),
  feedbackLabel: {
    color: colors.color,
    marginTop: 4,
  },
  feedbackTitle: {
    color: colors.color,
    textAlign: "center",
  },
});

export default ProductFeedbackButtons;
