/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesShopQuery } from "src/types/__generated__/QueriesShopQuery.graphql";

import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData, useParams } from "react-router-dom";

import { PageContext } from "src/app/context/page";
import {
  SBErrorBoundary,
  SBErrorPage,
  SBFallback,
  SBPageHeader,
} from "src/sbxui";

import ShopContent from "../shop/ShopContent";

const URL_RE = /_/gu;

const Seller = (): React.ReactNode | null => {
  const { t } = useTranslation();

  const pageContext = useContext(PageContext);

  const { shopUrl } = useParams();

  const queryRef = useLoaderData() as PreloadedQuery<QueriesShopQuery>;

  const shopUrlRemoveUnderscore = shopUrl?.replace(URL_RE, " ");

  useEffect(() => {
    pageContext?.setTitle(
      t("shop.documentTitleWithShopUrl", {
        shopUrl: shopUrlRemoveUnderscore?.toLocaleUpperCase(),
      }),
    );
  }, [pageContext, shopUrlRemoveUnderscore, t]);

  if (shopUrl == null) {
    return (
      <SBErrorPage
        description={t("notFound.description")}
        errorCode={404}
        title={t("notFound.pageTitle")}
      />
    );
  }

  if (queryRef == null) {
    return null;
  }

  return (
    <SBErrorBoundary>
      <SBPageHeader
        title={t("shop.pageTitleWithShopUrl", {
          shopUrl: shopUrlRemoveUnderscore,
        })}
      />
      <Suspense fallback={<SBFallback />}>
        <ShopContent queryRef={queryRef} />
      </Suspense>
    </SBErrorBoundary>
  );
};

export default Seller;
