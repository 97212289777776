/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { ShippingLabelCarrier } from "src/types/__generated__/OrderDetailView_order.graphql";

export const getTrackingLinkByCarrier = (
  carrier: ShippingLabelCarrier,
  trackingId: string,
): string => {
  switch (carrier) {
    case "FEDEX":
      return `https://www.fedex.com/wtrk/track/?tracknumbers=${trackingId}`;
    case "USPS":
      return `https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${trackingId}`;
    case "UPS":
      return `https://www.ups.com/track?track=yes&trackNums=${trackingId}&loc=en_US&requester=ST/`;
    default:
      return "";
  }
};
