/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { TFunction } from "i18next";

const WHITESPACE_RE = /[\s]+/gu;
const ELLIPSIS_RE = /\.\.\./gu;
const EN_DASH_RE = / - /gu;
const APOSTROPHE_RE = /'/gu;

interface Options {
  number?: string | null | undefined;
  t: TFunction;
  title: string | null | undefined;
}

export const formatTitle = ({ title, number, t }: Options): string => {
  if (title == null) {
    return t("product.book.title-missing");
  }

  const name = title
    .replace(WHITESPACE_RE, " ")
    .replace(ELLIPSIS_RE, "\u2026")
    .replace(APOSTROPHE_RE, "\u2019")
    .replace(EN_DASH_RE, " \u2013 ");

  if (number == null) {
    return name;
  }

  if (number != null) {
    return t("product.book.title", {
      name,
      number,
    });
  }

  return name;
};
