/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import graphql from "babel-plugin-relay/macro";

export const HomeQuery = graphql`
  query QueriesHomeQuery($vertical: Vertical!) {
    ...HomeView_verticalSearch @arguments(vertical: $vertical)
  }
`;

export const SellerAccountPayoutPreferencesQuery = graphql`
  query QueriesSellerAccountPayoutPreferencesQuery {
    viewer {
      user {
        ...SellerAccountPayoutPreferencesView_user
      }
    }
  }
`;

export const SellerAccountNeedToShipQuery = graphql`
  query QueriesSellerAccountNeedToShipQuery {
    viewer {
      user {
        ...SellerAccountNeedToShipView_user
      }
    }
  }
`;

export const SellerAccountLabelSizeQuery = graphql`
  query QueriesSellerAccountLabelSizeQuery {
    viewer {
      user {
        ...SellerAccountLabelSizeView_user
      }
    }
  }
`;

export const SellerAccountShipFromAddressQuery = graphql`
  query QueriesSellerAccountShipFromAddressQuery {
    viewer {
      user {
        ...SellerAccountShipFromAddressView_user
      }
    }
  }
`;

export const SellerAccountVacationModeQuery = graphql`
  query QueriesSellerAccountVacationModeQuery {
    viewer {
      user {
        ...SellerAccountVacationModeView_user
      }
    }
  }
`;

export const SellerAccountOfferSettingsQuery = graphql`
  query QueriesSellerAccountOfferSettingsQuery {
    viewer {
      user {
        ...SellerAccountOfferSettingsView_user
      }
    }
  }
`;

export const SellerAccountStoreSettingsQuery = graphql`
  query QueriesSellerAccountStoreSettingsQuery {
    viewer {
      user {
        ...SellerAccountStoreSettingsView_user
      }
    }
  }
`;

export const SellerAccountStatsQuery = graphql`
  query QueriesSellerAccountStatsQuery {
    viewer {
      user {
        ...SellerAccountStatsView_user @arguments(interval: ONE_MONTH)
      }
    }
  }
`;

export const SellerAccountOffersQuery = graphql`
  query QueriesSellerAccountOffersQuery {
    viewer {
      user {
        ...SellerAccountOffersView_user
      }
    }
  }
`;

export const SellerAccountSoldHistoryQuery = graphql`
  query QueriesSellerAccountSoldHistoryQuery {
    viewer {
      user {
        ...SellerAccountSoldHistoryView_user
      }
    }
  }
`;

export const AccountWatchedLotsQuery = graphql`
  query QueriesAccountWatchedLotsQuery {
    viewer {
      user {
        ...AccountWatchedLotsView_user
      }
    }
  }
`;

export const AccountAuctionBidsQuery = graphql`
  query QueriesAccountAuctionBidsQuery {
    viewer {
      user {
        ...AccountAuctionBidsView_user
      }
    }
  }
`;

export const AccountFavoritesQuery = graphql`
  query QueriesAccountFavoritesQuery {
    viewer {
      user {
        ...AccountFavoritesView_user
      }
    }
  }
`;

export const AccountInvoicesQuery = graphql`
  query QueriesAccountInvoicesQuery {
    viewer {
      user {
        ...AccountInvoicesView_user
      }
    }
  }
`;

export const AccountOffersQuery = graphql`
  query QueriesAccountOffersQuery {
    viewer {
      user {
        ...AccountOffersView_user
      }
    }
  }
`;

export const AccountPersonalInformationQuery = graphql`
  query QueriesAccountPersonalInformationQuery {
    viewer {
      user {
        ...AccountPersonalInformationView_user
      }
    }
  }
`;

export const AccountPasswordQuery = graphql`
  query QueriesAccountPasswordQuery {
    viewer {
      user {
        ...AccountPasswordView_user
      }
    }
  }
`;

export const AccountAddressesQuery = graphql`
  query QueriesAccountAddressesQuery {
    viewer {
      user {
        ...AccountAddressesView_user
      }
    }
  }
`;

export const AccountOrdersQuery = graphql`
  query QueriesAccountOrdersQuery {
    viewer {
      user {
        ...AccountOrdersView_user
      }
    }
  }
`;

export const AccountInvoiceDetailQuery = graphql`
  query QueriesAccountInvoiceDetailQuery($id: Int!) {
    invoiceBySurrogateId(surrogateId: $id) {
      ... on Invoice {
        surrogateId
        ...AccountInvoiceDetailView_invoice
      }
    }
  }
`;

export const AuctionSearchQuery = graphql`
  query QueriesAuctionSearchQuery {
    ...AuctionSearchView_auctionSearch
  }
`;

export const AuctionQuery = graphql`
  query QueriesAuctionQuery($id: ID!) {
    node(id: $id) {
      ... on Auction {
        ...AuctionView_auction
      }
    }
  }
`;

export const AuctionLotQuery = graphql`
  query QueriesAuctionLotQuery($id: ID!) {
    viewer {
      user {
        ...AuctionLotView_user
      }
    }
    node(id: $id) {
      ... on AuctionLot {
        ...AuctionLotView_auctionLot
      }
    }
  }
`;

export const PortfolioQuery = graphql`
  query QueriesPortfolioQuery(
    $status: [ProductStatus!]
    $title: String
    $gradingAuthority: GradingAuthority
    $salesChannel: [SalesChannel!]
    $orderBy: SellerProductsOrderByInput
  ) {
    viewer {
      user {
        ...PortfolioView_user
          @arguments(
            status: $status
            title: $title
            gradingAuthority: $gradingAuthority
            salesChannel: $salesChannel
            orderBy: $orderBy
          )
      }
    }
  }
`;

export const ShopQuery = graphql`
  query QueriesShopQuery($vertical: Vertical!) {
    ...ShopView_verticalSearch @arguments(vertical: $vertical)
  }
`;

export const ProductDetailQuery = graphql`
  query QueriesProductDetailQuery($id: ID!, $productId: String!) {
    hotConfigs {
      data
    }
    sentinels(names: ["IsEmployee"]) {
      value
    }
    viewer {
      user {
        sellerStatus
      }
    }
    node(id: $id) {
      ... on Product {
        ...ProductDetailView_product
      }
    }
    ...SimilarProducts_similarProducts
    ...RelatedProducts_relatedProducts
  }
`;

export const InvoiceQuery = graphql`
  query QueriesInvoiceQuery($id: ID!) {
    viewer {
      user {
        ...InvoiceView_user
      }
    }
    node(id: $id) {
      ... on Invoice {
        ...InvoiceView_invoice
      }
    }
    hotConfigs {
      data
    }
    braintreeToken
  }
`;

export const OrderDetailQuery = graphql`
  query QueriesOrderDetailQuery($id: Int!) {
    orderBySurrogateId(surrogateId: $id) {
      ... on Order {
        ...OrderDetailView_order
      }
    }
  }
`;

export const ArticlesQuery = graphql`
  query QueriesArticlesQuery {
    ...ArticlesViewQuery_articles
  }
`;

export const ArticleDetailQuery = graphql`
  query QueriesArticleDetailQuery($slug: String!) {
    articleBySlug(slug: $slug) {
      ...ArticleDetailView_article
    }
    ...ArticlesRecent_articles
  }
`;

export const FaqsQuery = graphql`
  query QueriesFaqsQuery {
    ...FaqsViewQuery_faqs
  }
`;

export const PrivacyQuery = graphql`
  query QueriesPrivacyQuery {
    privacy {
      ...PrivacyView_document
    }
  }
`;

export const TermsQuery = graphql`
  query QueriesTermsQuery {
    terms {
      ...TermsView_document
    }
  }
`;

export const WhyUseShortboxedQuery = graphql`
  query QueriesWhyUseShortboxedQuery {
    node(id: "RG9jdW1lbnQ6MWYxN2Q5ZGUtMmY0OS00NDFjLThmYWItNjUzNDBlODM5MzVh") {
      ... on Document {
        ...DocumentView_document
      }
    }
  }
`;

export const ContactUsQuery = graphql`
  query QueriesContactUsQuery {
    node(id: "RG9jdW1lbnQ6MjFhODNlMTUtYjczZi00ZDAyLTlkZWYtMzg0ZDg1Y2QwNDE0") {
      ... on Document {
        ...DocumentView_document
      }
    }
  }
`;

export const CareersQuery = graphql`
  query QueriesCareersQuery {
    node(id: "RG9jdW1lbnQ6ODQ4MGIxYzAtZjAyMC00NDdmLThiMmItNDE5MTQ4ZjI0Y2Zh") {
      ... on Document {
        ...DocumentView_document
      }
    }
  }
`;
