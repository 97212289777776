/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesAccountWatchedLotsQuery } from "src/types/__generated__/QueriesAccountWatchedLotsQuery.graphql";

import * as React from "react";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

import { SBErrorBoundary, SBFallback } from "src/sbxui";

import AccountPage from "../AccountPage";
import AccountWatchedLotsContent from "./AccountWatchedLotsContent";

const AccountWatchedLots = (): React.ReactNode => {
  const { t } = useTranslation();

  const queryRef =
    useLoaderData() as PreloadedQuery<QueriesAccountWatchedLotsQuery>;

  return (
    <AccountPage docTitle={t("account.watched-lots.title")}>
      <SBErrorBoundary>
        <Suspense fallback={<SBFallback />}>
          <AccountWatchedLotsContent queryRef={queryRef} />
        </Suspense>
      </SBErrorBoundary>
    </AccountPage>
  );
};

export default AccountWatchedLots;
