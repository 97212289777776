/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { PreloadedQuery } from "react-relay";
import type { QueriesProductDetailQuery } from "src/types/__generated__/QueriesProductDetailQuery.graphql";

import * as React from "react";
import { useTranslation } from "react-i18next";
import { usePreloadedQuery } from "react-relay";
import { useLocation } from "react-router-dom";

import { ProductDetailQuery } from "src/app/router/Queries";
import { SBErrorPage } from "src/sbxui";

import ProductDetailView from "./ProductDetailView";

const DEFAULT_ACH_SHOP_MINIMUM_VALUE = "1";

type Props = Readonly<{
  queryRef: PreloadedQuery<QueriesProductDetailQuery>;
}>;

const ProductDetailContent = ({ queryRef }: Props): React.ReactNode => {
  const { t } = useTranslation();

  const { pathname } = useLocation();

  const queryKey = usePreloadedQuery(ProductDetailQuery, queryRef);

  const achMinimumValue = Number.parseInt(
    queryKey.hotConfigs.data.ACH_SHOP_MINIMUM_VALUE ??
      DEFAULT_ACH_SHOP_MINIMUM_VALUE,
    10,
  );

  const isEmployee = queryKey.sentinels?.[0]?.value ?? false;

  const sellerStatus = queryKey.viewer.user?.sellerStatus;

  if (queryKey.node == null) {
    return (
      <SBErrorPage
        buttonHref={pathname}
        buttonTitle={t("product.back-to-marketplace")}
        description={t("not-found.description")}
        errorCode={404}
        title={t("not-found.page-title")}
      />
    );
  }

  return (
    <ProductDetailView
      achMinimumValue={achMinimumValue}
      employee={isEmployee}
      product={queryKey.node}
      queryKey={queryKey}
      sellerStatus={sellerStatus}
    />
  );
};

export default ProductDetailContent;
