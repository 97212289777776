/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Appearance, SetupIntent } from "@stripe/stripe-js";
import type { VerifyBidderView_address$key } from "src/types/__generated__/VerifyBidderView_address.graphql";
import type { VerifyBidderView_user$key } from "src/types/__generated__/VerifyBidderView_user.graphql";
import type { Address } from "src/types/Address";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import stylex from "@stylexjs/stylex";
import graphql from "babel-plugin-relay/macro";
import { kebabCase } from "lodash";
import * as React from "react";
import { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFragment, useMutation, useSubscription } from "react-relay";

import { STRIPE_KEY } from "src/api/constants";
import AddressForm from "src/app/components/address-form/AddressForm";
import { UserContext } from "src/app/context/user";
import useStripeTheme from "src/hooks/_hooks/useStripeTheme";
import {
  ButtonVariation,
  SBButton,
  SBErrorMessage,
  SBIcon,
  SBParagraph,
} from "src/sbxui";
import { auto } from "src/themes";
import { colors } from "src/themes/colors.stylex";

import VerifyBidderCreditCard from "./VerifyBidderCreditCard";

const POLL_TIME_MS = 500;

const VerifyBidderViewSubscription = graphql`
  subscription VerifyBidderViewSubscription {
    userUpdateSubscription {
      ...VerifyBidderView_user
      ...AuctionLotView_user
    }
  }
`;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(STRIPE_KEY);

enum Step {
  None,
  CreditCard,
  ShippingAddress,
}

enum PaymentMethodStatus {
  Incomplete,
  Pending,
  Complete,
}

type Props = Readonly<{
  auctionLotId: string | null | undefined;
  queryKey: VerifyBidderView_user$key;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}>;

const VerifyBidderView = ({
  auctionLotId,
  queryKey,
  setOpen,
}: Props): React.ReactNode => {
  const { t } = useTranslation();

  const [commitCreateAddress, isInFlightCreateAddress] = useMutation(graphql`
    mutation VerifyBidderViewCreateAddressMutation(
      $connections: [ID!]!
      $input: CreateAddressInput!
    ) {
      createAddress(createAddressInput: $input)
        @prependNode(connections: $connections, edgeTypeName: "AddressesEdge") {
        id
        ...VerifyBidderView_address
      }
    }
  `);

  const config = useMemo(
    () => ({
      cacheConfig: {
        poll: POLL_TIME_MS,
      },
      subscription: VerifyBidderViewSubscription,
      variables: {},
    }),
    [],
  );

  useSubscription(config);

  const data = useFragment(
    graphql`
      fragment VerifyBidderView_user on User {
        auctionBidStatus
        paymentSheetSetupIntent {
          setupIntent
        }
        paymentMethods {
          id
        }
        addresses(
          first: 1
          orderBy: { isDefault: DESC }
          where: { type: SHIPPING, isDefault: true }
        ) {
          __id
          edges {
            node {
              ...VerifyBidderView_address
            }
          }
        }
      }
    `,
    queryKey,
  );

  const addressData = useFragment<VerifyBidderView_address$key>(
    graphql`
      fragment VerifyBidderView_address on Address {
        id
        name
        givenName
        familyName
        address1
        address2
        locality
        region
        postalCode
        country
        isDefault
        type
      }
    `,
    data.addresses?.edges?.[0]?.node,
  );

  const connectionId = data.addresses?.__id;

  const auctionBidStatus = data.auctionBidStatus ?? "INCOMPLETE";

  const paymentMethods = data.paymentMethods ?? [];
  const hasPaymentMethod = paymentMethods.length > 0;

  const clientSecret = data.paymentSheetSetupIntent.setupIntent;

  const addressId = addressData?.id;
  const isAddressVerified = addressId != null;

  const [step, setStep] = useState(() => {
    if (isAddressVerified) {
      return hasPaymentMethod ? Step.None : Step.CreditCard;
    }
    return Step.ShippingAddress;
  });

  const [addressErrorMessage, setAddressErrorMessage] = useState<string | null>(
    null,
  );

  const userContext = useContext(UserContext);

  const [isCreditCardVerified, setIsCreditCardVerified] =
    useState(hasPaymentMethod);

  const { light, dark } = useStripeTheme();

  const appearance = useMemo<Appearance>(() => {
    if (window.matchMedia?.("(prefers-color-scheme: dark)").matches) {
      return dark;
    }
    return light;
  }, [dark, light]);

  const handleCreateAddress = (address: Address) => {
    if (connectionId == null) {
      return;
    }
    commitCreateAddress({
      onCompleted() {
        setStep(isCreditCardVerified ? Step.None : Step.CreditCard);
      },
      onError(error) {
        setAddressErrorMessage(error.message);
      },
      variables: {
        connections: [connectionId],
        input: {
          address1: address.address1,
          address2: address.address2 === "" ? null : address.address2,
          country: address.country,
          familyName: address.familyName,
          givenName: address.givenName,
          isDefault: address.isDefault,
          locality: address.locality,
          name: address.addressName,
          postalCode: address.postalCode,
          region: address.region,
          type: address.type,
          userId: userContext?.user?.userId ?? "",
        },
      },
    });
  };

  const handleClickCreditCard = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isCreditCardVerified) {
      return;
    }
    let nextStep =
      step === Step.CreditCard ? Step.ShippingAddress : Step.CreditCard;
    if (isAddressVerified) {
      nextStep = Step.CreditCard;
    }
    setStep(nextStep);
  };

  const handleClickAddress = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (isAddressVerified) {
      return;
    }
    let nextStep =
      step === Step.ShippingAddress ? Step.CreditCard : Step.ShippingAddress;
    if (isCreditCardVerified) {
      nextStep = Step.CreditCard;
    }
    setStep(nextStep);
  };

  const handleVerifyCreditCardComplete = (result: SetupIntent.Status) => {
    if (result === "succeeded") {
      setIsCreditCardVerified(true);
      setStep(isAddressVerified ? Step.None : Step.ShippingAddress);
    } else {
      setIsCreditCardVerified(false);
    }
  };

  const handleCompleteClick = (_event: React.SyntheticEvent) => {
    setOpen(false);
  };

  const options = {
    appearance,
    clientSecret,
  };

  const isButtonDisabled = !hasPaymentMethod || !isAddressVerified;

  let paymentMethodStatus = PaymentMethodStatus.Incomplete;
  let paymentMethodIcon = "chevron_right";
  let paymentMethodIconFill = false;
  if (isCreditCardVerified && !hasPaymentMethod) {
    paymentMethodStatus = PaymentMethodStatus.Pending;
    paymentMethodIcon = "pending";
  } else if (isCreditCardVerified && hasPaymentMethod) {
    paymentMethodStatus = PaymentMethodStatus.Complete;
    paymentMethodIcon = "check_circle";
    paymentMethodIconFill = true;
  }

  let addressStatusIcon = "chevron_right";
  let addressStatusIconFill = false;
  if (isAddressVerified) {
    addressStatusIcon = "check_circle";
    addressStatusIconFill = true;
  }

  return (
    <div {...stylex.props(styles.root)}>
      {(auctionBidStatus === "SUSPENDED" || auctionBidStatus === "PENDING") && (
        <div
          {...stylex.props(
            auto,
            styles.banner(
              auctionBidStatus === "PENDING"
                ? colors.bannerSuccess
                : colors.bannerWarning,
            ),
          )}
        >
          <SBParagraph style={styles.bannerText}>
            {t(
              `auction.lot.verify-bidder-modal.banners.${kebabCase(auctionBidStatus)}`,
            )}
          </SBParagraph>
        </div>
      )}
      <SBParagraph style={styles.description}>
        {t("auction.lot.verify-bidder-modal.description")}
      </SBParagraph>
      <ul {...stylex.props(styles.todo)}>
        <li {...stylex.props(auto, styles.todoItem, styles.todoItemFirst)}>
          <div
            {...stylex.props(
              styles.todoLabel,
              isAddressVerified && styles.todoLabelComplete,
            )}
            aria-pressed={step === Step.ShippingAddress}
            role="button"
            onClick={handleClickAddress}
          >
            <SBParagraph style={styles.todoDescription}>
              {t("auction.lot.verify-bidder-modal.todo.shipping-address")}
            </SBParagraph>
            <SBIcon
              fill={addressStatusIconFill}
              icon={addressStatusIcon}
              style={[
                styles.todoIcon,
                step === Step.ShippingAddress && styles.todoIconActive,
                isAddressVerified && styles.todoIconComplete,
              ]}
            />
          </div>
          <div
            {...stylex.props(
              styles.todoDetails,
              step === Step.ShippingAddress && styles.todoDetailsExpanded,
            )}
          >
            <SBErrorMessage
              message={addressErrorMessage}
              style={styles.error}
            />
            <AddressForm
              anchor="bottom start"
              buttonVariation={ButtonVariation.Emphasis}
              initialDefault={true}
              loading={isInFlightCreateAddress}
              onSubmit={handleCreateAddress}
            />
          </div>
        </li>
        <li {...stylex.props(styles.todoItem, styles.todoItemFirst)}>
          <div
            {...stylex.props(
              styles.todoLabel,
              isCreditCardVerified && styles.todoLabelComplete,
            )}
            aria-pressed={step === Step.CreditCard}
            role="button"
            onClick={handleClickCreditCard}
          >
            <SBParagraph style={styles.todoDescription}>
              {t("auction.lot.verify-bidder-modal.todo.credit-card")}
            </SBParagraph>
            <SBIcon
              fill={paymentMethodIconFill}
              icon={paymentMethodIcon}
              style={[
                styles.todoIcon,
                step === Step.CreditCard && styles.todoIconActive,
                paymentMethodStatus === PaymentMethodStatus.Pending &&
                  styles.todoIconPending,
                paymentMethodStatus === PaymentMethodStatus.Complete &&
                  styles.todoIconComplete,
              ]}
            />
          </div>
          <div
            {...stylex.props(
              styles.todoDetails,
              step === Step.CreditCard && styles.todoDetailsExpanded,
            )}
          >
            <Elements options={options} stripe={stripePromise}>
              <VerifyBidderCreditCard
                auctionLotId={auctionLotId}
                onComplete={handleVerifyCreditCardComplete}
              />
            </Elements>
          </div>
        </li>
      </ul>
      <SBButton
        disabled={isButtonDisabled}
        title={t("common.buttons.continue")}
        onClick={handleCompleteClick}
      />
    </div>
  );
};

const styles = stylex.create({
  banner: (backgroundColor: string) => ({
    backgroundColor,
    marginBottom: 16,
    marginInline: -16,
    marginTop: -16,
    paddingBlock: 8,
    paddingInline: 16,
  }),
  bannerText: {
    color: colors.bannerText,
    fontWeight: "bold",
    marginBottom: 0,
    textAlign: "center",
  },
  description: {
    marginBottom: 0,
  },
  error: {
    marginBottom: 24,
  },
  root: {
    display: "flex",
    flexDirection: "column",
  },
  todo: {
    listStyle: "none",
    margin: 0,
    marginBottom: 16,
    padding: 0,
  },
  todoDescription: {
    marginBottom: 0,
  },
  todoDetails: {
    maxHeight: 0,
    overflow: "hidden",
  },
  todoDetailsExpanded: {
    marginBottom: 16,
    maxHeight: 999999,
  },
  todoIcon: {
    color: colors.topNavigationMenuColor,
    transition: "transform 250ms ease-out",
  },
  todoIconActive: {
    transform: "rotate(90deg)",
    transition: "transform 250ms ease-in",
  },
  todoIconComplete: {
    color: colors.successBackgroundColor,
  },
  todoIconPending: {
    color: colors.colorMuted,
  },
  todoItem: {
    cursor: "pointer",
    margin: 0,
    padding: 0,
  },
  todoItemFirst: {
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderColor: colors.modalBorderColor,
  },
  todoLabel: {
    alignItems: "center",
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    margin: 0,
    padding: 0,
    paddingBlock: 12,
  },
  todoLabelComplete: {
    cursor: "default",
  },
});

export default VerifyBidderView;
