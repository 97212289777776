/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { SelectInputOption } from "src/sbxui";

import stylex from "@stylexjs/stylex";
import { kebabCase } from "lodash";
import * as React from "react";
import { useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import {
  SELLER_ACCOUNT_LABEL_SIZE_PATH,
  SELLER_ACCOUNT_NEED_TO_SHIP_PATH,
  SELLER_ACCOUNT_OFFER_SETTINGS_PATH,
  SELLER_ACCOUNT_PATH,
  SELLER_ACCOUNT_PAYOUT_PREFERENCES_PATH,
  SELLER_ACCOUNT_SHIP_FROM_ADDRESS_PATH,
  SELLER_ACCOUNT_SOLD_HISTORY_PATH,
  SELLER_ACCOUNT_STATS_PATH,
  SELLER_ACCOUNT_STORE_SETTINGS_PATH,
  SELLER_ACCOUNT_VACATION_MODE_PATH,
} from "src/app/router/Router";
import { SBSelectInput } from "src/sbxui";
import { auto } from "src/themes";

import AccountSideNavSection from "../account/AccountSideNavSection";

const MOBILE = "@media (max-width: 767px)";

export interface INavItem {
  id: string;
  label: string;
  path: string;
  selected: boolean;
}

const SellerAccountSideNav = (): React.ReactNode => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [_isPending, startTransition] = useTransition();

  const navItemsTransactions: INavItem[] = [
    {
      id: "OFFERS",
      label: t(`seller-account.nav.offers`),
      path: SELLER_ACCOUNT_PATH,
      selected: pathname === SELLER_ACCOUNT_PATH,
    },
    {
      id: "NEED_TO_SHIP",
      label: t(`seller-account.nav.need-to-ship`),
      path: SELLER_ACCOUNT_NEED_TO_SHIP_PATH,
      selected: pathname === SELLER_ACCOUNT_NEED_TO_SHIP_PATH,
    },
    {
      id: "SOLD_HISTORY",
      label: t(`seller-account.nav.sold-history`),
      path: SELLER_ACCOUNT_SOLD_HISTORY_PATH,
      selected: pathname === SELLER_ACCOUNT_SOLD_HISTORY_PATH,
    },
  ];

  const navItemsStore: INavItem[] = [
    {
      id: "SELLER_STATS",
      label: t(`seller-account.nav.seller-stats`),
      path: SELLER_ACCOUNT_STATS_PATH,
      selected: pathname === SELLER_ACCOUNT_STATS_PATH,
    },
    {
      id: "STORE_SETTINGS",
      label: t(`seller-account.nav.store-settings`),
      path: SELLER_ACCOUNT_STORE_SETTINGS_PATH,
      selected: pathname === SELLER_ACCOUNT_STORE_SETTINGS_PATH,
    },
    {
      id: "OFFER_SETTINGS",
      label: t(`seller-account.nav.offer-settings`),
      path: SELLER_ACCOUNT_OFFER_SETTINGS_PATH,
      selected: pathname === SELLER_ACCOUNT_OFFER_SETTINGS_PATH,
    },
    {
      id: "VACATION_MODE",
      label: t(`seller-account.nav.vacation-mode`),
      path: SELLER_ACCOUNT_VACATION_MODE_PATH,
      selected: pathname === SELLER_ACCOUNT_VACATION_MODE_PATH,
    },
  ];

  const navItemsSettings: INavItem[] = [
    {
      id: "PAYOUT_PREFERENCES",
      label: t(`seller-account.nav.payout-preferences`),
      path: SELLER_ACCOUNT_PAYOUT_PREFERENCES_PATH,
      selected: pathname === SELLER_ACCOUNT_PAYOUT_PREFERENCES_PATH,
    },
    {
      id: "SHIP_FROM_ADDRESS",
      label: t(`seller-account.nav.ship-from-address`),
      path: SELLER_ACCOUNT_SHIP_FROM_ADDRESS_PATH,
      selected: pathname === SELLER_ACCOUNT_SHIP_FROM_ADDRESS_PATH,
    },
    {
      id: "LABEL_SIZE",
      label: t(`seller-account.nav.label-size`),
      path: SELLER_ACCOUNT_LABEL_SIZE_PATH,
      selected: pathname === SELLER_ACCOUNT_LABEL_SIZE_PATH,
    },
  ];

  const allItems = [
    ...navItemsTransactions,
    ...navItemsStore,
    ...navItemsSettings,
  ];

  const handleOptionChange = (selected: string) => {
    const selectedOption =
      allItems.find(({ label }) => label === selected) ?? null;

    if (selectedOption) {
      startTransition(() => {
        navigate(selectedOption.path);
      });
    }
  };

  return (
    <div>
      <div {...stylex.props(auto, styles.select)}>
        <SBSelectInput
          options={allItems.map(
            ({ id }) =>
              ({
                id: `nav-${id}`,
                label: t(`seller-account.nav.${kebabCase(id)}`),
              }) as SelectInputOption,
          )}
          value={
            allItems.find(({ path }) => path === pathname) ?? {
              id: "",
              label: "",
            }
          }
          onChange={handleOptionChange}
        />
      </div>
      <div {...stylex.props(styles.nav)}>
        <AccountSideNavSection
          heading={t("seller-account.nav-section.transactions")}
          items={navItemsTransactions}
        />
        <AccountSideNavSection
          heading={t("seller-account.nav-section.store")}
          items={navItemsStore}
        />
        <AccountSideNavSection
          heading={t("seller-account.nav-section.settings")}
          items={navItemsSettings}
        />
      </div>
    </div>
  );
};

const styles = stylex.create({
  nav: {
    display: {
      [MOBILE]: "none",
      default: "block",
    },
    position: "absolute",
  },
  select: {
    display: {
      [MOBILE]: "block",
      default: "none",
    },
    marginBottom: 20,
  },
});

export default SellerAccountSideNav;
