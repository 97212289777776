/**
 * @generated SignedSource<<c7517cf77d337683ad7738ca457cac56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArticlesViewQuery_articles$data = {
  readonly featuredArticle: {
    readonly id: string;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"ArticlesListQuery_articles" | "FeaturedArticle_article">;
  readonly " $fragmentType": "ArticlesViewQuery_articles";
};
export type ArticlesViewQuery_articles$key = {
  readonly " $data"?: ArticlesViewQuery_articles$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArticlesViewQuery_articles">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticlesViewQuery_articles",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeaturedArticle_article"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Article",
      "kind": "LinkedField",
      "name": "featuredArticle",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ArticlesListQuery_articles"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "30d0b7e885be89e5b0fa02d577391ecb";

export default node;
