/**
 * (c) Shortboxed Inc. and its affiliates. Confidential and proprietary.
 */

import type { Source } from "src/types/__generated__/HistoricalSalesTable_product.graphql";

import { useTranslation } from "react-i18next";

type Attribution = {
  [K in Source]: string | null;
};

export const useFootnotes = (
  sources: Source[],
): {
  attribution: Attribution;
  footnotes: string[];
} => {
  const { t } = useTranslation();

  const heritageIndex = sources.indexOf("HERITAGE");

  const hasHeritage = heritageIndex >= 0;

  const footer: {
    index: number;
    source: Source;
  }[] = [];
  if (hasHeritage) {
    footer.push({
      index: heritageIndex,
      source: "HERITAGE",
    });
  }

  footer.sort((aa, bb) => {
    if (aa.index < bb.index) {
      return -1;
    } else if (aa.index > bb.index) {
      return 1;
    }
    return 0;
  });

  const attribution = {} as Attribution;

  const footnotes: string[] = [];
  let ii = 0;
  for (const node of footer) {
    attribution[node.source] = `${ii + 1}`;
    footnotes.push(
      t("attribution.footnote", {
        attribution: t(`attribution.${node.source}`, {
          year: new Date(Date.now()).getFullYear(),
        }),
        footnote: `${ii + 1}`,
      }),
    );
    ii++;
  }

  return {
    attribution,
    footnotes,
  };
};
